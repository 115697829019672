import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { SearchService } from "../search/search.service";
import { HttpApiService } from "../http-api/http-api.service";
import { ConfigService } from "../config/config.service";
import { LoadingController } from "@ionic/angular";
import { AuthenticationService } from "../authentication/authentication.service";
import * as moment from "moment";

interface PartyAndCount {
  [key: string]: number;
}

interface DetailData {
  [key: string]: number[];
}

interface TransformedDetail {
  id: number;
  [key: number]: {
    partyCount: PartyAndCount;
    pandL: DetailData;
    ageing: DetailData;
  };
}

export interface Conditions {
  "Last 1 Month": boolean;
  "Last 3 Months": boolean;
  "Last 6 Months": boolean;
  "Last 1 Year": boolean;
  All: boolean;
  "Party with max count": boolean;
  "P & L (%)": boolean;
  "Ageing wise": boolean;
  duration: string;
}

@Injectable({
  providedIn: "root",
})
export class PotentialBuyersService {
  private stoneNames = new BehaviorSubject([]);
  private selectedStone = new BehaviorSubject("");
  private stoneFeatures = new BehaviorSubject("");
  private selections = new BehaviorSubject({});
  private potentialBuyerDetails = new BehaviorSubject([]);
  private selectedConditions = new BehaviorSubject({
    "Last 1 Month": false,
    "Last 3 Months": false,
    "Last 6 Months": false,
    "Last 1 Year": false,
    All: false,
    "Party with max count": false,
    "P & L (%)": false,
    "Ageing wise": false,
    duration: "",
  });

  public currStoneNames = this.stoneNames.asObservable();
  public currSelectedStone = this.selectedStone.asObservable();
  public currStoneFeatures = this.stoneFeatures.asObservable();
  public currSelections = this.selections.asObservable();
  public currPotentialBuyerDetails = this.potentialBuyerDetails.asObservable();
  public currSelectedConditions = this.selectedConditions.asObservable();

  public filtered = [];
  public stonelist: string = "";
  public transformedDetails = [];
  public dateIds = [];
  public listOfFromToDates = [];
  public sizeGroup = [
    {
      fromCts: 0.0,
      toCts: 0.69,
    },
    {
      fromCts: 0.7,
      toCts: 0.99,
    },
    {
      fromCts: 1.0,
      toCts: 1.49,
    },
    {
      fromCts: 1.5,
      toCts: 1.99,
    },
    {
      fromCts: 2.0,
      toCts: 2.99,
    },
    {
      fromCts: 3.0,
      toCts: 100,
    },
  ];

  constructor(
    private es: SearchService,
    private httpApiService: HttpApiService,
    private configService: ConfigService,
    private loadingController: LoadingController,
    private authService: AuthenticationService
  ) {
    this.listOfFromToDates = [
      {
        dateId: 1,
        fromDate: moment().subtract(30, "days").format("DD-MMM-YYYY"),
        toDate: moment().format("DD-MMM-YYYY"),
        label: "Last 1 month",
      },
      {
        dateId: 2,
        fromDate: moment().subtract(90, "days").format("DD-MMM-YYYY"),
        toDate: moment().format("DD-MMM-YYYY"),
        label: "Last 3 months",
      },
      {
        dateId: 3,
        fromDate: moment().subtract(180, "days").format("DD-MMM-YYYY"),
        toDate: moment().format("DD-MMM-YYYY"),
        label: "Last 6 months",
      },
      {
        dateId: 4,
        fromDate: moment().subtract(365, "days").format("DD-MMM-YYYY"),
        toDate: moment().format("DD-MMM-YYYY"),
        label: "Last 1 year",
      },
      {
        dateId: 5,
        fromDate: "1-JAN-2017",
        toDate: moment().format("DD-MMM-YYYY"),
        label: "All",
      },
    ];
  }

  updateStoneNames(stoneNameArr: string[]) {
    this.stoneNames.next(stoneNameArr);
  }

  updateSelectedCondition(conditions: {
    "Last 1 Month": boolean;
    "Last 3 Months": boolean;
    "Last 6 Months": boolean;
    "Last 1 Year": boolean;
    All: boolean;
    "Party with max count": boolean;
    "P & L (%)": boolean;
    "Ageing wise": boolean;
    duration: string;
  }) {
    this.selectedConditions.next(conditions);
  }

  updateSelectedStone(stoneName: string) {
    this.selectedStone.next(stoneName);
  }

  updateStoneFeatures(features: string) {
    this.stoneFeatures.next(features);
  }

  updatePotentialBuyerDetails(potentialBuyerData: []) {
    this.potentialBuyerDetails.next(potentialBuyerData);
  }

  getQuery() {
    const qry = {
      query: {
        bool: {
          should: [
            {
              simple_query_string: {
                query: "",
              },
            },
            {
              bool: {
                must: [],
              },
            },
          ],
        },
      },
    };

    const boolShouldMultiple = {
      bool: {
        should: [],
      },
    };

    const location = {
      terms: { location: ["mumbai"] },
    };

    qry.query.bool.should[1].bool.must.push(location);

    let stoneName = "";
    this.currSelectedStone.subscribe((stone) => {
      stoneName = stone;
    });

    const sData = {
      terms: {
        stoneName: [stoneName],
      },
    };

    boolShouldMultiple.bool.should.push(sData);

    qry.query.bool.should[1].bool.must.push(boolShouldMultiple);

    return qry;
  }

  modifyData(data) {
    return data.hits.hits
      .map((d) => d._source)
      .map((res) => {
        let stoneId = res.stoneName;
        if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
          let index = stoneId.split("/", 3).join("/").length;
          res["stoneName"] = String(
            stoneId
              .split(stoneId.substring(index + 1, stoneId.length))
              .join(
                stoneId
                  .substring(index + 1, stoneId.length)
                  .slice(
                    0,
                    stoneId.substring(index + 1, stoneId.length).indexOf("_")
                  )
              )
          );
        } else {
          res["stoneName"] = String(res["stoneName"]);
        }
        return res;
      });
  }

  async fetchStoneDetail() {
    this.filtered = [];
    const qry = this.getQuery();

    let data = await this.es.getPaginatedDocuments(
      qry,
      0,
      environment.INDEX,
      "",
      1000
    );

    if (!data.hits.total.value) {
      await this.configService.showToast("error", "Stone not found");
      return;
    }

    data = this.modifyData(data);

    // let d = await this.sortResult(data, tmp)
    for await (let el of data) {
      let ctsRange = this.sizeGroup.find(
        (item) => item.fromCts <= el.cts && item.toCts >= el.cts
      );

      const newStoneFeatures = `${el.ColorCode} - ${el.ShapeCode} - ${ctsRange.fromCts} - ${ctsRange.toCts} - ${el.CutCode} - ${el.ClarityCode} - ${el.PolishCode} - ${el.SymmetryCode} - ${el.FluorescenceCode}`;

      this.updateStoneFeatures(newStoneFeatures);

      this.filtered.push({
        color: el.ColorCode,
        shape: el.ShapeCode,
        clarity: el.ClarityCode,
        fromCts: ctsRange.fromCts,
        toCts: ctsRange.toCts,
        cut: el.CutCode,
        polish: el.PolishCode,
        symmetry: el.SymmetryCode,
        fluorescence: el.FluorescenceCode,
        id: el.id,
        stoneName: el.stoneName,
      });
    }
  }

  async fetchPotentialBuyerDetails() {
    await this.configService.showLoader();
    let res: any;
    let payload = {
      listOfCriteria: this.filtered,
      listOfFromToDates: this.listOfFromToDates,
    };
    res = await this.httpApiService.getBuyerByCriteria(payload, 5);
    let data = res.json();
    await this.loadingController.dismiss();

    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.transformData(data.data);
    }
  }

  transformDataLevel_2(data: any, header: string) {
    const newObj = { header, data: [] };

    for (let key in data) {
      const obj: any = {};

      switch (header) {
        case "Party with max count":
          obj.name = key;
          obj.value = data[key];
          break;
        case "P & L (%)":
          obj.name = key;
          obj.value = Math.max(...data[key]);
          break;
        default:
          obj.name = key;
          obj.value =
            Math.trunc(
              (data[key].reduce((acc: number, curr: number) => acc + curr, 0) /
                data[key].length) *
                1000
            ) / 1000;
      }

      newObj.data.push(obj);
    }

    return newObj;
  }

  transformData(potentialBuyerlist): void {
    this.transformedDetails = [];

    const mainHeadings = {
      1: "Last 1 Month",
      2: "Last 3 Months",
      3: "Last 6 Months",
      4: "Last 1 Year",
      5: "All",
    };
    const subHeadings = {
      partyCount: "Party with max count",
      pandL: "P & L (%)",
      ageing: "Ageing wise",
    };

    potentialBuyerlist.forEach((item: any) => {
      if (!item.dateId) return;

      const transformedItem = { header: mainHeadings[item.dateId], data: [] };

      for (const key in subHeadings) {
        if (item[key]) {
          transformedItem.data.push(
            this.transformDataLevel_2(item[key], subHeadings[key])
          );
        }
      }

      this.transformedDetails.push(transformedItem);
    });

    this.potentialBuyerDetails.next(this.transformedDetails);
  }
}
