import { Component, OnInit, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";


@Component({
  selector: 'app-upload-assortment',
  templateUrl: './upload-assortment.page.html',
  styleUrls: ['./upload-assortment.page.scss'],
})
export class UploadAssortmentPage implements OnInit {

  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 50;
  public getHistoryData = [];
  public getHistoryDataSource: any = {};
  public getReAssortmentHistoryDataSource: any = {};
  public count = 0;
  public totalPages = 0;
  public columns = []
  public selectedFile: any;
  public page = "upload for assortment";
  public clearFile = ""

  public tab = 'assortment';

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public alertCtrl: AlertController,
    public loadingController: LoadingController,
    public event: Events
  ) {
  }

  ngOnInit() {
    this.columns = [
      { name: "File Name", prop: "fileName" },
      { name: "Status", prop: "status" },
      { name: "By", prop: "username" },
      { name: "Date & Time", prop: "createdDate" }
    ];
    this.getAllUploadAssortmentHistory()
    this.event.subscribe('Lab Expenses', (item) => {
      this.columns = []
      this.columns = [
        { name: "File Name", prop: "fileName" },
        { name: "Status", prop: "status" },
        { name: "By", prop: "username" },
        { name: "Date & Time", prop: "createdDate" }
      ];
      this.getAllUploadAssortmentHistory()
    })
  }

  changeSegment(event) {
    this.tab = event.detail.value;
    if (this.tab == 'reassortment') {
      this.reassortmentHistory()
    } else {
      this.getAllUploadAssortmentHistory()
    }
  }

  ngOnDestroy() {
    this.event.unsubscribe('Lab Expenses');
  }

  async getAllUploadAssortmentHistory() {
    this.getHistoryDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getUploadHistory('Assortment Bulk Upload', this.pageNumber, this.noOfRecords)
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.getHistoryData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.getHistoryData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }



  async reassortmentHistory() {
    this.getReAssortmentHistoryDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getUploadHistory('Reassortment', this.pageNumber, this.noOfRecords)
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.getHistoryData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.getHistoryData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }
  handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {

    }
  }

  fileObject(event) {
    this.clearFile = event.target.value
    this.selectedFile = <File>event.target.files[0];
  }

  async uploadForAssortment() {
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.uploadCsvForAssortment(formData);
      console.log(res.json());
      await this.configService.dismiss();
      let uploadData = res.json();
      if (uploadData.status == 400) {
        await this.configService.showToast("error", uploadData.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", uploadData.error);
      } else {
        await this.configService.showToast("success", uploadData.message);
        this.selectedFile = null;
        this.clearFile = null;
        await this.getAllUploadAssortmentHistory();
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  async uploadReassortForAssortment() {
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.uploadCsvForReAssortment(formData);
      console.log(res.json());
      await this.configService.dismiss();
      let uploadData = res.json();
      if (uploadData.status == 400) {
        await this.configService.showToast("error", uploadData.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", uploadData.error);
      } else {
        await this.configService.showToast("success", uploadData.message);
        this.selectedFile = null;
        this.clearFile = null;
        await this.getAllUploadAssortmentHistory();
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }
  async downloadCsv(file) {
    let res: any;
    res = await this.httpApiService.downloadActualLabExpenseFile(file.id, 'Assortment Bulk Upload');
  }

}
