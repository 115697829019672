import { ProcessActionComponent } from '../../processAction';
import { Component, OnInit, Input, HostListener, ElementRef } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
@Component({
  selector: 'app-planandsalesstones',
  templateUrl: './planandsalesstones.component.html',
  styleUrls: ['./planandsalesstones.component.scss']
})
export class PlanandsalesstonesComponent implements ProcessActionComponent {
  @Input() data: any;
  selectedStoneType: string = 'Plan'; // Default to 'Plan' or 'Sales'
  stoneDescription: string = '';
  filtered = []

  constructor(public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public alertController: AlertController,
    public configService: ConfigService,
    public event: Events) { }

  ngOnInit() {

  }
  async fetchPlanAndSalesStones() {
    // console.log(this.selectedStoneType, 'check type here')
    // console.log(this.stoneDescription, 'check text area here')
    if (!!this.stoneDescription) {
      this.filtered = this.stoneDescription.split("\n");
      let issueList = []
      this.filtered.forEach(el => {
        if (el.indexOf(",") > 0) {
          let data1 = el.split(',')
          let data2 = []
          data1.forEach(el => {
            data2.push(el.trim())
          })
          Array.prototype.push.apply(issueList, data2);
        } else {
          issueList.push(el)
        }
      })
      this.filtered = issueList.filter(function (el) {
        return el != "";
      });
    }
    let res: any;
    let payload = {
      stoneIds: this.filtered,
      type: this.selectedStoneType
    }
    // console.log(payload, 'payload check')
    this.configService.showLoader()
    res = await this.httpApiService.generatePlandAndSalesStonesTextFile(payload);
    let data = res.json();
    this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (payload.type === 'Plan Stones') {
        let d: any = await this.httpApiService.downloadPlanStoneFile()
        let sss = d.json();
        const text = await new Response(sss).text();
        let split = text.split("\n");
        await this.downloadSingleFileForPlanAndSalesStones(split);

      } else {
        let d: any = await this.httpApiService.downloadSalesStoneFile()
        let sss = d.json();
        const text = await new Response(sss).text();
        let split = text.split("\n");
        await this.downloadSingleFileForPlanAndSalesStones(split);
      }
    }
  }

  async downloadSingleFileForPlanAndSalesStones(filteredData: any) {
    if (this.filtered.length === 0) {
      return;
    }
    const combinedData = filteredData.join('\n');
    console.log(combinedData, 'combined data here')
    const blob = new Blob([combinedData], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${this.selectedStoneType}.txt`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
