import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-purchase-search',
  templateUrl: './purchase-search.component.html',
  styleUrls: ['./purchase-search.component.scss']
})
export class PurchaseSearchComponent implements ProcessActionComponent {
  @Input() data: any;
  public query: any;
  public type = "SS";
  public disable: any = [];
  public cart = true;
  public blackListedStones = true;
  public rapnetSearch = true;
  public elasticIndex = environment.INDEX + ',' + environment.INDEX1;
  // public elasticIndex = environment.INDEX1;
  public default = {
    criteria: true,
    search: true,
    shape: true,
    carat: true,
    color: true,
    colorOverride: false,
    clarity: true,
    clarityOverride: false,
    description: true,
    finishing: true,
    fluorescence: true,
    lab: true,
    size: false,
    internalStatus: false,
    externalStatus: false,
    transType: false,
    users: false,
    location: false,
    box: true,
    department: true,
    saveSearch: true,
    download: true,
    fm: true,
    party: true,
    availableForSale: false,
    standardShapeCode: true
  };

  constructor() { }

  ngOnInit() {
    let name = [];
    name.push(this.type);
    this.disable.push("transType");

    this.query = {
      disjunctiveFacetsRefinements: {
        transType: name,
        internalStatus: ['In Stock']
      }
    };
  }

}
