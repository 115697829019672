export default {
  reportNo: 'R-20',
  url: "master-service/api/report/assortment-report-mix",
  method: "GET",
  size: 3,
  fields: [
  ],
  columns: [
  ],
  dataStudioLink: 'https://lookerstudio.google.com/embed/reporting/db2571b8-d8bc-4f55-99a6-c1946bea28b8/page/9GoLD'
};