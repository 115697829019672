import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: "app-transfer-to-sales-single",
  templateUrl: "./transfer-to-sales-single.component.html",
  styleUrls: ["./transfer-to-sales-single.component.scss"]
})
export class TransferToSalesSingleComponent implements ProcessActionComponent {
  @Input() data: any;

  public actionId: any;
  public transType = "SS";
  public process = "issueWithRole";
  public issueWithTransType ='YES'

  constructor() {}

  ngOnInit() {
    this.actionId = this.data.actionId;
  }
}
