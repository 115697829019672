import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { DecimalPipe } from "@angular/common";

import { Events } from "@ionic/angular";

import * as moment from "moment";
import { exportDataGrid } from "devextreme/excel_exporter";
import * as ExcelJS from "exceljs/dist/exceljs.js";
import saveAs from "file-saver";
import DataGrid from "devextreme/ui/data_grid";

@Component({
  selector: "app-dev-extreme-data-grid",
  templateUrl: "./dev-extreme-data-grid.component.html",
  styleUrls: ["./dev-extreme-data-grid.component.scss"],
})
export class DevExtremeDataGridComponent implements OnInit {
  @Output() callFunction: EventEmitter<any> = new EventEmitter();
  @Output() onClickToOpenAssortPdResult: EventEmitter<any> = new EventEmitter();
  @Output() deleteFunction: EventEmitter<any> = new EventEmitter();
  @Output() applyFunction: EventEmitter<any> = new EventEmitter();
  @Output() editFunction: EventEmitter<any> = new EventEmitter();
  @Output() resetchainFunction: EventEmitter<any> = new EventEmitter();
  @Output() offerValueUpdate: EventEmitter<any> = new EventEmitter();
  @Output() ctsValueUpdate: EventEmitter<any> = new EventEmitter();
  @Output() offerValuesUpdated: EventEmitter<any> = new EventEmitter();
  @Output() termsOrBankRateUpdated: EventEmitter<any> = new EventEmitter();
  @Output() showResultOfAssortPD: EventEmitter<any> = new EventEmitter();
  @Output() showData: EventEmitter<any> = new EventEmitter();
  @Output() downloadFileFunction: EventEmitter<any> = new EventEmitter();
  @Output() showHistoryFunction: EventEmitter<any> = new EventEmitter();
  @Output() publishPolicyFunction: EventEmitter<any> = new EventEmitter();
  @Output() findStonesListOfNotAppliedPolicyFunction: EventEmitter<any> =
    new EventEmitter();
  @Output() gotoManagePolicyPageFunction: EventEmitter<any> =
    new EventEmitter();
  @Output() deletePolicyFunction: EventEmitter<any> = new EventEmitter();
  @Output() discountError: EventEmitter<any> = new EventEmitter();
  @Output() validateOffer: EventEmitter<any> = new EventEmitter();
  @Output() copyFunction: EventEmitter<any> = new EventEmitter();

  @Input() columns: any;
  @Input() tableId: string = "gridContainer";
  @Input() showHorizontalVirtualScroll: any;
  @Input() filteredRow: boolean = false;
  @Input() mode: string;
  @Input() rowData: any;
  @Input() pageNumber: any;
  @Input() noOfRecords: any;
  @Input() count: any;
  @Input() page: any;
  @Input() noPagination: any;
  @Input() searchPanelEnable: any = false;
  @Input() exportEnable: any;
  @Input() noRemoteOperations: any;
  @Input() showPdResultIconColumn: any = false;
  @Input() pageSize: any = 50;
  @Input() deleteBtn: boolean;
  @Input() copyBtn: boolean;
  @Input() editButton: boolean;
  @Input() editBtn: boolean;
  @Input() selectedRows: any = [];
  @Input() isPagingDisable = true;
  @Input() ledOn: boolean;
  @Input() updatePermissionFlag: boolean;
  @Input() deletePermissionFlag: boolean;
  @Input() rejectColumn: boolean;
  @Input() refreshGrid: boolean = false;
  @Input() recalculteSummary: boolean = false;
  @Input() flag: any;
  @Input() applyBtn: any;
  @Input() showApply: boolean = false;
  @Input() groupTotalSummary: any;
  @Input() totalSummary: any;
  @Input() listOfferApplied: boolean = false;
  @Input() editCts: boolean = false;
  @Input() remoteOperations = true;
  @Input() qcStatusList: [];
  @Input() statusList: [];
  @Input() transactionStatusList: [];
  @Input() cvdStatusList: [];
  @Input() prefixList: [];

  public dataGridInstance: DataGrid;
  public columnName: any;
  public isNext: boolean = true;

  constructor(
    public event: Events,
    private el: ElementRef,
    public _decimalPipe: DecimalPipe
  ) {
    this.deleteItem = this.deleteItem.bind(this);
    this.copyItem = this.copyItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.displayResultIconClick = this.displayResultIconClick.bind(this);
    this.customCt = this.customCt.bind(this);
    this.customCtsFormat = this.customCtsFormat.bind(this);
    this.customExgFormat = this.customExgFormat.bind(this);
    this.customAmount = this.customAmount.bind(this);
    this.customAmt = this.customAmt.bind(this);
    this.offerPriceFormat = this.offerPriceFormat.bind(this);
    this.calculateOfferAmt = this.calculateOfferAmt.bind(this);
    this.calculateCustomSummary = this.calculateCustomSummary.bind(this);
  }

  ngOnChanges(changes) {
    if (!!changes.ledOn && !!changes.ledOn.currentValue) {
      if (this.selectedRows.length > 0) {
        this.dataGridInstance.selectRows(this.selectedRows, true);
        // this.dataGridInstance.selectRowsByIndexes(this.selectedRows)
      }
    }

    if (!!changes.refreshGrid && !!changes.refreshGrid.currentValue) {
      this.clear();
    }

    if (
      !!changes.recalculteSummary &&
      !!changes.recalculteSummary.currentValue
    ) {
      this.refresh();
    }

    if (!!changes.totalSummary && !!changes.totalSummary.currentValue) {
      if (this.totalSummary.length != 0) {
        this.remoteOperations = false;
      }
    }
  }

  saveGridInstance(e) {
    this.dataGridInstance = e.component;
  }

  clear() {
    this.dataGridInstance.clearSelection();
  }

  refresh() {
    this.dataGridInstance.refresh();
  }

  customPktsOut(data) {
    return data.pendingPackets + "/ " + data.totalPackets;
  }

  customCt(data) {
    let pendingCarat: any;
    let totalCarat: any;

    pendingCarat = this._decimalPipe.transform(data.pendingCarat, ".3-3");
    totalCarat = this._decimalPipe.transform(data.totalCarat, ".3-3");
    return pendingCarat + "/" + totalCarat;
  }

  offerPriceFormat(data) {
    return this._decimalPipe.transform(data.offerPrice, ".2-2");
  }

  calculateOfferAmt(data) {
    let val: any;

    val = data.cts * data.offerPrice;
    val = this._decimalPipe.transform(val, ".2-2");

    return data.kgOfferAmount;
  }

  customCtsFormat(data) {
    let cts: any;
    if (data.cts) {
      cts = this._decimalPipe.transform(data.cts, ".3-3");
    }
    if (data.stoneCts) {
      cts = this._decimalPipe.transform(data.stoneCts, ".3-3");
    }
    return cts;
  }

  customExgFormat(data) {
    let exgRate: any;
    if (data.exgRate) {
      exgRate = this._decimalPipe.transform(data.exgRate, ".3-3");
    }

    return exgRate;
  }

  customVoucherDateTime(data) {
    if (!!data.voucherDate) {
      return moment(data.voucherDate).format("DD-MMM-YYYY");
    }
  }

  customizeSum(data) {
    console.log(typeof data.value);
    if (!!data.value && typeof data.value == "number")
      return "Total :" + data.value.toFixed(3);
    else return "Total :" + data.value;
  }

  customizeAvg(data) {
    console.log(typeof data.value);
    if (!!data.value && typeof data.value == "number")
      return "Avg :" + data.value.toFixed(3);
    else return "Avg :" + data.value;
  }

  ngOnInit() { }

  onCellClick(ev) {
    this.columnName = ev.column.caption;
  }

  onSelectionChanged(event) {
    console.log("onSelectionChanged event ", event);
    event.row = event.selectedRowsData[0];
    event.columnName = this.columnName;
    this.call(event);
  }

  call(event) {
    console.log(event);
    if (event.type == "click") {
      if (event.column.name != "Barcode" || event.column.name != "Terms") {
        this.callFunction.emit(event);
      }
    } else {
      this.callFunction.emit(event);
    }
  }

  showResult(row) {
    this.showResultOfAssortPD.emit(row);
  }

  showParams(row) {
    console.log(row);
    this.showData.emit(row);
  }

  showStoneId(data) {
    let stoneId = data.value;
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split("/", 3).join("/").length;
      return stoneId
        .split(stoneId.substring(index + 1, stoneId.length))
        .join(
          stoneId
            .substring(index + 1, stoneId.length)
            .slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))
        );
    } else {
      return stoneId;
    }
  }

  displayStoneId(data) {
    let stoneId = data;
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split("/", 3).join("/").length;
      return stoneId
        .split(stoneId.substring(index + 1, stoneId.length))
        .join(
          stoneId
            .substring(index + 1, stoneId.length)
            .slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))
        );
    } else {
      return stoneId;
    }
  }

  showPurchasePartyWithoutVersionNumber(data) {
    let stoneId = data.value;
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.toString().indexOf("_"));
    } else {
      return stoneId;
    }
  }

  customRate(data) {
    return data.offerPrice;
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("StoneDetail");

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
    }).then(function () {
      // https://github.com/exceljs/exceljs#writing-xlsx
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "DataGrid.xlsx"
        );
      });
    });
    e.cancel = true;
  }

  displayResultIconClick(event) {
    this.onClickToOpenAssortPdResult.emit(event);
  }

  offerValuesUpdatedFn(event) {
    if (event.value > 0) {
      event.data.errorPrice = false;
    } else {
      event.data.errorPrice = true;
    }
    this.offerValuesUpdated.emit(event);
    return event;
  }

  updateStoneValuesFn(event) {
    console.log(event.column.caption);

    if (event.value > 0) {
      event.data.errorPrice = false;
      if (event.column.dataField == "terms") {
        event.row.data["terms"] = event.value;
      }
      if (event.column.dataField == "BankRate") {
        event.row.data["BankRate"] = event.value;
      }

      if (event.column.dataField == "accusoftInvoiceNo") {
        event.row.data["accusoftInvoiceNo"] = event.value;
      }
      if (event.column.dataField == "accusoftsubSerialNo") {
        event.row.data["accusoftsubSerialNo"] = event.value;
      }
    } else {
      event.data.errorPrice = true;
    }
    //this.termsOrBankRateUpdated.emit(event);
    return event;
  }

  onFocusedCellChanged(event) {
    console.log(event.row);
    console.log(event.column.dataField);
    if (event.row) {
      if ("KG Counter %" == event.column.dataField) {
        event.row = this.updateValue(event.value, "Disc", event.data);
      } else if ("KG Counter Price" == event.column.dataField) {
        event.row = this.updateValue(event.value, "Price", event.data);
      } else if ("KG Counter Amt" == event.column.dataField) {
        event.row = this.updateValue(event.value, "Amt", event.data);
      } else if ("offerCts" == event.column.dataField) {
        let issuedCts = event.row.data.stoneCts;
        console.log(issuedCts);
        if (event.value > 0) {
          if (event.value > issuedCts) {
            event.data.errorCts = true;
          } else {
            event.data.errorCts = false;
          }
          this.ctsValueUpdate.emit(event);
        } else {
          event.data.errorCts = true;
        }
        return event;
      } else {
        if (event.value > 0) {
          event.data.errorPrice = false;
        } else {
          event.data.errorPrice = true;
        }
        this.offerValueUpdate.emit(event);
        return event;
      }
    }
  }

  updateValue(value, type, product) {
    this.isNext = true;
    //    this.listOffer = false;
    this.offerValueUpdate.emit(false);
    console.log(value);
    console.log(product);

    // let product = this.cartData.data.products[rowIndex];
    // let cts = parseFloat(product["cts"]);
    // let rap = parseFloat(product["RAPAPORTpercarat"]);
    // let listDisc = parseFloat(product["SPMDiscountPercent"]);
    // let listPrice = parseFloat(product["SPMpercarat"]);
    // let listAmt = parseFloat(product["SPM"]);
    let disc = parseFloat(product["KG Counter %"]);
    let price = parseFloat(product["KG Counter Price"]);
    let amt = parseFloat(product["KG Counter Amt"]);

    if (type == "Disc") {
      if (product["oldKgDiscountPercent"] != value) {
        product["isChanged"] = true;
      } else {
        product["isChanged"] = false;
      }
      product.kgPrice = (
        product.rapnet *
        ((100 - product.kgDiscountPercent) / 100)
      ).toFixed(2);
      product.kgOfferAmount = (product.kgPrice * product.cts).toFixed(2);
    } else if (type == "Price") {
      if (product["oldkgPrice"] != value) {
        product["isChanged"] = true;
      } else {
        product["isChanged"] = false;
      }
      let offerPer =
        ((product.rapnet - product["kgPrice"]) * 100) / product.rapnet;
      product["kgDiscountPercent"] = offerPer.toFixed(2);
      product.kgOfferAmount = (product.kgPrice * product.cts).toFixed(2);
    } else if (type == "Amt") {
      if (product["oldKgOfferAmount"] != value) {
        product["isChanged"] = true;
      } else {
        product["isChanged"] = false;
      }
      product.kgPrice = product.kgOfferAmount / product.cts;
      let offerPer =
        ((product.rapnet - product["kgPrice"]) * 100) / product.rapnet;
      product["kgDiscountPercent"] = offerPer.toFixed(2);
    } else if (type == "Cts") {
      product.cts = value;
    }

    product.forEach((item) => {
      if (
        parseFloat(item.kgDiscountPercent) <=
        parseFloat(item.discountPercent) ||
        parseFloat(item.kgDiscountPercent) >=
        parseFloat(item.offerDiscountPercent)
      ) {
        this.isNext = false;
        item.error = true;
      } else {
        item.error = false;
      }
      if (item.kgDiscountPercent == item.offerDiscountPercent) {
        item.isMatched = true;
      } else {
        item.isMatched = false;
      }
    });
    this.validateOffer.emit(this.isNext);
    // if (disc <= listDisc + 5) {
    //     product["Req Discount"] = disc.toFixed(2);
    //     product["Req Price"] = price.toFixed(2);
    //     product["Amount"] = amt.toFixed(2);
    // } else {
    //     product["Req Discount"] = listDisc.toFixed(2);
    //     product["Req Price"] = listPrice.toFixed(2);
    //     product["Amount"] = listAmt.toFixed(2);
    //     console.log('disct exceeded');
    //     this.discountError.emit(true)
    // }
    return product;
  }

  // onFocusedCellChanged(event) {
  //   if (event.value > 0) {
  //     event.data.errorPrice = false;
  //   } else {
  //     event.data.errorPrice = true;
  //   }
  //   this.offerValueUpdate.emit(event);
  //   return event
  // }

  // updateValue(value, type, product) {
  //   this.offerValueUpdate.emit(false);
  // }

  customVoucher(data) {
    return data.shortCode + "-" + data.bookSerialNumber;
  }

  customVoucherForBlockForSale(data) {
    return data.shortCode + "-" + data.bookSerialNo;
  }

  deleteItem(event) {
    this.deleteFunction.emit(event);
  }

  copyItem(event) {
    this.copyFunction.emit(event);
  }

  apply(event) {
    this.applyFunction.emit(event);
  }

  editItem(event) {
    this.editFunction.emit(event);
  }

  reset(event) {
    this.resetchainFunction.emit(event);
  }

  customAmt(row) {
    let val: any;
    if (row.cts && row.price) {
      val = row.cts * row.price;
      val = this._decimalPipe.transform(val, ".2-2");
    }
    return val;
  }

  customeTrasDate(data) {
    if (!!data.voucherDate) {
      return moment.utc(data.voucherDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  customAmount(data) {
    let amount;
    if (data.amount) {
      amount = this._decimalPipe.transform(data.amount, ".2-2");
    }
    return amount;
  }

  customAmountData(data) {
    if (data.requestPrice) {
      return (data.requestPrice * data.stoneCts).toFixed(2);
    }
  }

  customeVoucherLocalDate(data) {
    if (!!data.voucherDate) {
      return moment.utc(data.voucherDate).format("DD-MMM-YYYY");
    }
  }

  downloadFile(data) {
    this.downloadFileFunction.emit(data);
  }

  publishPolicy(data) {
    this.publishPolicyFunction.emit(data);
  }

  findStonesListOfNotAppliedPolicy(data) {
    this.findStonesListOfNotAppliedPolicyFunction.emit(data);
  }

  gotoManagePolicyPage(data) {
    this.gotoManagePolicyPageFunction.emit(data);
  }

  deletePolicy(data) {
    this.deletePolicyFunction.emit(data);
  }

  showProgressStatus(data) {
    console.log(data);
    this.showHistoryFunction.emit(data);
  }

  customCreatedAt(data) {
    if (!!data.createdDate) {
      return moment.utc(data.createdDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  customDateTime(data) {
    if (!!data.modifiedDate) {
      return moment
        .utc(data.modifiedDate)
        .local()
        .format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data.createdDate) {
      return moment.utc(data.createdDate).local().format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data.priceListDate) {
      return moment
        .utc(data.priceListDate)
        .local()
        .format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data.fromDate) {
      return moment.utc(data.fromDate).local().format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data.invDate) {
      return moment.utc(data.invDate).local().format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data.voucherDate) {
      return moment.utc(data.voucherDate).local().format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data.created_date) {
      return moment
        .utc(data.created_date)
        .local()
        .format("DD-MMM-YYYY hh:mm a");
    }
  }

  lastLoggedInDateTime(data) {
    if (!!data.lastLoggedIn) {
      return moment
        .utc(data.lastLoggedIn)
        .local()
        .format("DD-MMM-YYYY hh:mm a");
    } else if (!data.lastLoggedIn) {
      return "-";
    }
  }

  transactionDate(data) {
    if (!!data.voucherDate) {
      return moment.utc(data.voucherDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  inwDateFun(data) {
    if (!!data.inwardDate) {
      return moment.utc(data.inwardDate).local().format("DD-MMM-YYYY");
    }
  }

  transactionDateFun(data) {
    if (!!data.voucherDate) {
      return moment.utc(data.voucherDate).local().format("DD-MMM-YYYY");
    }
  }

  invPurchaseDate(data) {
    return moment.utc(data.invDate).local().format("DD-MM-YYYY");
  }

  customVoucherDate(data) {
    if (!!data.voucherDate) {
      return moment.utc(data.voucherDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  customPktsValue(data) {
    return data.receivedPackets + "/ " + data.totalPackets;
  }

  momentAppliedTime(data) {
    if (data.appliedDate) {
      return moment.utc(data.appliedDate).local().format("DD-MM-YYYY hh:mm a");
    }
  }

  momentTime(data) {
    if (data.modifiedDate) {
      return moment.utc(data.modifiedDate).local().format("DD-MM-YYYY hh:mm a");
    }

    if (data.lastRun) {
      return moment.utc(data.lastRun).local().format("DD-MM-YYYY hh:mm a");
    }
    if (data.created_on) {
      return moment.utc(data.created_on).local().format("DD-MM-YYYY hh:mm a");
    }
  }

  localTime(data) {
    if (data.upcomingRun) {
      return moment.utc(data.upcomingRun).local().format("DD-MM-YYYY hh:mm a");
    }
  }

  factoryOutWDate(data) {
    if (data.factoryOutWDate) {
      return data.factoryOutWDate.substring(
        data.factoryOutWDate,
        data.factoryOutWDate.indexOf(" ")
      );
    }
  }

  customizeSumAvg(data) {
    if (!!data.value) {
      if (
        !!data.valueText &&
        data.valueText.length > 0 &&
        data.valueText.split(":").length > 0
      ) {
        let label = data.valueText.split(":")[0];
        return !!this._decimalPipe
          ? this._decimalPipe.transform(data.value, ".3-3")
          : data.value;
      } else {
        return !!this._decimalPipe
          ? this._decimalPipe.transform(data.value, ".3-3")
          : data.value;
      }
    } else {
      return data.value;
    }
  }

  calculateCustomSummary(options) {
    if (options.name == "requestPrice") {
      if (options.summaryProcess === "start") {
        options.price = 0;
        options.cts = 0;
        options.amount = 0;
      }
      if (options.summaryProcess === "calculate") {
        options.price += parseFloat(options.value["requestPrice"]);
        options.cts += parseFloat(options.value["stoneCts"]);
        options.amount += parseFloat(options.value["memoAmt"]);
      }
      if (options.summaryProcess === "finalize") {
        options.totalValue = (options.amount / options.cts).toFixed(3);
      }
    }

    if (options.name == "stoneCts") {
      if (options.summaryProcess === "start") {
        options.cts = 0;
      }
      if (options.summaryProcess === "calculate") {
        options.cts += parseFloat(options.value["stoneCts"]);
      }
      if (options.summaryProcess === "finalize") {
        options.totalValue = options.cts.toFixed(3);
      }
    }

    if (options.name == "requestAmt") {
      if (options.summaryProcess === "start") {
        options.requestAmt = 0;
      }
      if (options.summaryProcess === "calculate") {
        options.requestAmt += parseFloat(options.value["requestAmt"]);
      }
      if (options.summaryProcess === "finalize") {
        options.totalValue = options.requestAmt.toFixed(3);
      }
    }

    if (options.name == "memoAmt") {
      if (options.summaryProcess === "start") {
        options.memoAmt = 0;
      }
      if (options.summaryProcess === "calculate") {
        options.memoAmt += parseFloat(options.value["memoAmt"]);
      }
      if (options.summaryProcess === "finalize") {
        options.totalValue = options.memoAmt.toFixed(3);
      }
    }

    if (options.name == "amount") {
      if (options.summaryProcess === "start") {
        options.amount = 0;
      }
      if (options.summaryProcess === "calculate") {
        options.amount += parseFloat(options.value["amount"]);
        options.amount = Number(options.amount.toFixed(3));
      }
      if (options.summaryProcess === "finalize") {
        options.totalValue = options.amount.toFixed(3);
      }
    }
  }

  onCVDSatusValueChanged(data, rowData) {
    if (data.value) {
      rowData.cvdStatus = data.value;
    }

    return rowData;
  }

  onQCStatusValueChanged(data, rowData) {
    if (data.value) {
      rowData.qcCheck = data.value;
    }

    return rowData;
  }

  onStatusValueChanged(data, rowData) {
    if (data.value) {
      rowData.status = data.value;
    }

    return rowData;
  }

  onTransactionTypeValueChanged(data, rowData) {
    if (data.value) {
      rowData.transactionType = data.value;
    }

    return rowData;
  }

  onStatusChanged(data, rowData) {
    if (data.value) {
      rowData.status = data.value;
    }

    return rowData;
  }

  onPrefixValueChanged(data, rowData) {
    if (data.value) {
      rowData.prefix = data.value;
    }
  }
}
