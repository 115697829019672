import { Component, OnInit, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  Events,
  ToastController
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { IonicSelectableComponent } from "ionic-selectable";
import * as moment from "moment";

@Component({
  selector: 'app-select-scheduler-time',
  templateUrl: './select-scheduler-time.page.html',
  styleUrls: ['./select-scheduler-time.page.scss'],
})
export class SelectSchedulerTimePage implements OnInit {

  public frequency = [
    {
      id: 1,
      name: 'Daily'
    },
    {
      id: 2,
      name: 'Weekly'
    },
    {
      id: 3,
      name: 'Monthly'
    }
  ];
  public hoursIds = [];
  public hours = [
    {
      id: 1,
      name: "1"
    },
    {
      id: 2,
      name: "2"
    },
    {
      id: 3,
      name: "3"
    },
    {
      id: 4,
      name: "4"
    },
    {
      id: 5,
      name: "5"
    },
    {
      id: 6,
      name: "6"
    },
    {
      id: 7,
      name: "7"
    },
    {
      id: 8,
      name: "8"
    },
    {
      id: 9,
      name: "9"
    },
    {
      id: 10,
      name: "10"
    },
    {
      id: 11,
      name: "11"
    },
    {
      id: 12,
      name: "12"
    },
    {
      id: 13,
      name: "13"
    },
    {
      id: 14,
      name: "14"
    },
    {
      id: 15,
      name: "15"
    },
    {
      id: 16,
      name: "16"
    },
    {
      id: 17,
      name: "17"
    }, {
      id: 18,
      name: "18"
    },
    {
      id: 19,
      name: "19"
    },
    {
      id: 20,
      name: "20"
    },
    {
      id: 21,
      name: "21"
    },
    {
      id: 22,
      name: "22"
    },
    {
      id: 23,
      name: "23"
    },
    {
      id: 24,
      name: "24"
    }
  ];
  public weekdays = [
    {
      id: 1,
      name: 'Sunday'
    },
    {
      id: 2,
      name: 'Monday'
    },
    {
      id: 3,
      name: 'Tuesday'
    },
    {
      id: 4,
      name: 'Wednesday'
    },
    {
      id: 5,
      name: 'Thursday'
    },
    {
      id: 6,
      name: 'Friday'
    },
    {
      id: 7,
      name: 'Saturday'
    }
  ]
  public weekIds = [];
  public frequencyIds = [];
  public selectedWeeks = [];
  public selectedFrequency: any;
  public monthSettings = {
    bigBanner: true,
    timePicker: false,
    format: 'yyyy-MM-dd',
    defaultOpen: false,
    minDate: new Date()
  }
  public timeSettings = {
    bigBanner: true,
    timePicker: true,
    format: 'h:mm a',
    defaultOpen: false,
    closeOnSelect: true
  }
  public schedulerMonthlyDate: any;
  public selectedOccurEvery: any;
  public startTime: any;
  public endTime: any;
  public schedulerData: any;

  constructor(
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public toastCtrl: ToastController,
    public authService: AuthenticationService,
    public configService: ConfigService,
    public navParams: NavParams,
    public event: Events
  ) {
  }

  async ngOnInit() {
    this.schedulerData = this.navParams.get('schedulerData');
    if (!!this.schedulerData) {
      //{"frequency":"Monthly","schedulerDate":"yyyy-09-17","startTime":"08:00","endTime":"22:30"}
      if (this.schedulerData.frequency) {
        this.selectedFrequency = this.schedulerData.frequency
        this.frequencyIds = this.frequency.filter(a => a.name == this.schedulerData.frequency);
      }

      if (this.schedulerData.occursEvery) {
        this.selectedOccurEvery = this.schedulerData.occursEvery
        this.hoursIds = this.hours.filter(a => a.name == this.schedulerData.occursEvery);
      }

      if (this.schedulerData.weekdays) {
        await this.weekdays.filter(a => {
          if (this.schedulerData.weekdays.includes(a.name)) {
            this.weekIds.push(a)
          }
        });
        console.log(this.weekIds);
      }

      if (this.schedulerData.schedulerDate) {
        this.schedulerMonthlyDate = moment(this.schedulerData.schedulerDate).format('YYYY-MM-DD')
      }

      if (this.schedulerData.startTime) {
        let st = this.schedulerData.startTime.split(":")
        this.startTime = await moment.utc(new Date()).set({ hour: st[0], minute: st[1] }).local().format("HH:mm")
      }

      if (this.schedulerData.endTime) {
        let et = this.schedulerData.endTime.split(":")
        this.endTime = await moment.utc(new Date()).set({ hour: et[0], minute: et[1] }).local().format("HH:mm")
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss()
  }

  dropDownSelection(ev) {
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;

    if (property == 'frequency') {
      if (idList.length > 0) {
        this.selectedFrequency = obj[0].name;

        // if (obj[0].name == "Daily") {
        //   this.weekIds = this.weekdays;
        // }
      } else {
        this.selectedFrequency = "";
      }
    }

    if (property == 'weekdays') {
      if (idList.length > 0) {
        this.selectedWeeks = []
        this.selectedWeeks = obj.map(a => a.name);
        console.log(this.selectedWeeks)
      } else {
        this.selectedWeeks = []
      }
    }

    if (property == 'hours') {
      if (idList.length > 0) {
        this.selectedOccurEvery = obj[0].name;
      } else {
        this.selectedOccurEvery = null;
      }
    }
  }

  async done() {
    let obj = {}
    if (this.startTime && this.endTime) {
      let st = this.startTime.split(":")
      let et = this.endTime.split(":")

      let checkTime = new Date();
      checkTime.setHours(5);
      checkTime.setMinutes(30);

      let stDate = new Date();
      stDate.setHours(st[0]);
      stDate.setMinutes(st[1]);

      // if(stDate.getTime() < checkTime.getTime()){
      //   await this.configService.showToast("error", "Please set time above 5:30");
      //   return
      // }

      obj['startTime'] = await moment.utc(moment(new Date()).set({ hour: st[0], minute: st[1] })).format("HH:mm")
      obj['endTime'] = await moment.utc(moment(new Date()).set({ hour: et[0], minute: et[1] })).format("HH:mm")
      // obj['startTime'] = this.startTime;
      // obj['endTime'] = this.endTime;
    }
    //   obj['endTime'] = moment(this.endTime).format("HH:MM");
    if (this.selectedFrequency == 'Daily') {
      obj['frequency'] = this.selectedFrequency;

      // if (this.startTime && this.endTime) {
      //   console.log(this.startTime)
      //   obj['startTime'] = this.startTime;
      //   obj['endTime'] = this.endTime;
      // }

      if (this.selectedOccurEvery) {
        obj['occursEvery'] = this.selectedOccurEvery
      }

    } else if (this.selectedFrequency == 'Weekly') {
      obj['frequency'] = this.selectedFrequency;

      // if (this.startTime && this.endTime) {
      //   obj['startTime'] = this.startTime;
      //   obj['endTime'] = this.endTime;
      // }

      if (this.selectedOccurEvery) {
        obj['occursEvery'] = this.selectedOccurEvery
      }

      obj['weekdays'] = this.selectedWeeks;
    } else if (this.selectedFrequency == 'Monthly') {
      obj['frequency'] = this.selectedFrequency;

      if (this.schedulerMonthlyDate) {
        obj['schedulerDate'] = moment(this.schedulerMonthlyDate).format('YYYY-MM-DD')
      }

      // if (this.startTime && this.endTime) {
      //   obj['startTime'] = this.startTime;
      //   obj['endTime'] = this.endTime;
      // }

      if (this.selectedOccurEvery) {
        obj['occursEvery'] = this.selectedOccurEvery
      }
    }
    console.log(obj)
    this.modalCtrl.dismiss(obj)
  }
}
