import { Component, Input } from "@angular/core";

import {
  ModalController,
  AlertController,
  LoadingController,
  Events,
} from "@ionic/angular";

import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-queued-action",
  templateUrl: "./queued-action.component.html",
  styleUrls: ["./queued-action.component.scss"],
})
export class QueuedActionComponent implements ProcessActionComponent {
  @Input() data: any;

  public listOfQueuedStoneDataSource: any = {};
  public columns = [];
  public page = "queuedAction";
  public listOfMappingParams = [];
  public pageNumber = 0;
  public noOfRecords = 500;
  public pageSize = 500;
  public items = [];
  public count = 0;
  public totalPages = 0;
  public readPermissionFlag = false;
  public addPermissionFlag = false;
  public deletePermissionFlag = false;
  public updatePermissionFlag = false;
  public manageSelectedMapping: any;
  public refreshGrid = false;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) {
    this.deleteItem = this.deleteItem.bind(this);
  }

  ngOnInit() {
    console.log(this.data.listOfActionPermissions);
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter((el) => {
        if (el.permissionName == "Read") {
          this.readPermissionFlag = true;
        }
        if (el.permissionName == "Add") {
          this.addPermissionFlag = true;
        }
        if (el.permissionName == "Update") {
          this.updatePermissionFlag = true;
        }
        if (el.permissionName == "Delete") {
          this.deletePermissionFlag = true;
        }
      });
      console.log(
        this.readPermissionFlag,
        this.updatePermissionFlag,
        this.addPermissionFlag,
        this.deletePermissionFlag
      );
    }
    this.columns = [
      {
        name: "Sr No",
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        },
      },
      { name: "Stone Name", prop: "stoneName" },
      { name: "Location", prop: "companyName" },
      { name: "Queued Action", prop: "actionName" },
      { name: "Status", prop: "status" },
      { name: "Party Name", prop: "partyName" },
      { name: "Cts", prop: "size" },
      { name: "RAPAPORT", prop: "rapaportPerCts" },
      { name: "Request Price", prop: "requestPartyPrice" },
      { name: "Stone Remarks", prop: "stoneRemarks" },
      { name: "Created By", prop: "createdBy" },
      { name: "Created At", prop: "createdDate" },
      { name: "Updated At", prop: "modifiedDate" },
    ];
    if (this.deletePermissionFlag == true) {
      this.columns.push({ name: "Delete", prop: "deleteRequest" });
    }
    this.getAllQueuedStoneList();
    this.event.subscribe("Queued Action", (item) => {
      this.getAllQueuedStoneList();
    });
  }

  ngOnDestroy() {
    this.event.unsubscribe("RAPAPORT Mapping");
  }

  async getAllQueuedStoneList() {
    this.listOfQueuedStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await await this.httpApiService.getQueuedAction(
            "",
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfMappingParams = data.data.content;
            this.setItems();
            return {
              data: Query(data.data.content).toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements,
            };
          }
        } else {
          return {
            data: this.listOfMappingParams,
            totalCount: this.count,
            groupCount: this.count,
          };
        }
      },
    });
  }

  async setItems() {
    this.items = this.listOfMappingParams;
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.listOfQueuedStoneDataSource = new CustomStore({
        key: "id",
        load: async (loadOptions: any) => {
          console.log("loadOptions ", loadOptions);
          if (!!loadOptions.take) {
            loadOptions.take = this.noOfRecords;
            this.pageNumber = loadOptions.skip / loadOptions.take;
            let res: any;
            res = await await this.httpApiService.getQueuedAction(
              val,
              this.pageNumber,
              this.noOfRecords
            );
            let data = res.json();
            if (res.status == 401) {
              this.authService.logout();
            } else if (res.status == 500) {
              await this.configService.showToast("error", data.error);
            } else if (res.status == 400) {
              await this.configService.showToast("error", data.error);
            } else {
              this.listOfMappingParams = data.data.content;
              this.setItems();
              return {
                data: Query(data.data.content).toArray(),
                totalCount: data.data.totalElements,
                groupCount: data.data.totalElements,
              };
            }
          } else {
            return {
              data: this.listOfMappingParams,
              totalCount: this.count,
              groupCount: this.count,
            };
          }
        },
      });
      // }
    } else {
      await this.getAllQueuedStoneList();
    }
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    if (d.data != "doNotRefresh") {
      await this.getAllQueuedStoneList();
    }
  }

  async deleteConfirm(item) {
    console.log(item);
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this queued action?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay" + item);
            this.deleteQueuedAction(item);
          },
        },
      ],
    });

    await alert.present();
  }

  async deleteQueuedAction(item) {
    let res: any;
    res = await this.httpApiService.deleteQueuedAction(item.id);
    let data = res.json();
    if (res.status === 401) {
      this.authService.logout();
    } else if (res.status === 500) {
      if (!!data.error) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("error", data.message);
      }
    } else if (res.status === 400) {
      if (!!data.error) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("error", data.message);
      }
    } else {
      await this.configService.showToast("success", data.message);
      await this.getAllQueuedStoneList();
    }
  }

  async dispaySrNo(row) {
    console.log(row);

    return row.rowIndex + 1;
  }

  async deleteItem(ev) {
    this.deleteConfirm(ev);
  }
}
