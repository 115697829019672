import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AlertController, Events, LoadingController, ModalController } from "@ionic/angular";
import { IonicSelectableComponent } from 'ionic-selectable';
import * as moment from "moment";
import { ExportAsExcelService } from "src/app/services/export-as-excel.service";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { ConfigService } from "../../services/config/config.service";
import { HttpApiService } from "../../services/http-api/http-api.service";

@Component({
  selector: 'app-svc-actions',
  templateUrl: './svc-actions.page.html',
  styleUrls: ['./svc-actions.page.scss'],
})
export class SvcActionsPage implements OnInit {
  @Input() requestFor: any;
  @Input() actionId: any;
  @Input() selectedStone: any = [];
  @Input() actionName: string = '';
  @Input() convertToShip: boolean = false;

  defaultDetail: any;
  voucherBookId: any;
  defaultPartyMasterId: any = null;
  formDetailList = []
  public formFields = []
  formData = {}
  selectedCustomer = null;
  selectedAddressIdList = [];
  listOfAddress = [];
  location: string = ''
  getAllContacts = []
  selectedContactList = []
  getAllCustomers = [];
  selectedParty = ''
  customerContactId = null;
  customerDetails = null;
  holdVoucherBookId = null;
  unHoldVoucherBookId = null;
  blockForSaleVoucherBookId = null;
  toRole = null;
  requestList = []
  pageNumber = 0
  noOfRecords = 20
  SVCKAMMappingEmail = null;
  defaultEmailList = null;
  requestCustomerDetail = null;
  loggedInUserEmail = null;
  userDetail = null;
  requestContactDetail = null;
  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public loadingController: LoadingController,
    public exportAsExcelService: ExportAsExcelService,
    public configService: ConfigService,
    public event: Events) { }

  async ngOnInit() {
    console.log(this.convertToShip, this.actionId)
    this.formData = {}
    console.log(this.selectedStone)
    this.userDetail = await this.authService.userData()
    this.loggedInUserEmail = this.userDetail.email;
    await this.getFormDetail()
    await this.getDefaultSetting()
  }

  async getFormDetail() {
    this.formDetailList = []
    let cartActionDetail = await this.configService.getCartActionList();
    this.formFields = cartActionDetail.formFields;
    let partyTypeList = cartActionDetail.partyTypeList;
    partyTypeList.forEach(el => {
      if (el.name == 'Client') {
        el.actionList.forEach(item => {
          if (item.actionName == this.requestFor) {
            if (!!item.allowPermission && item.allowPermission.length > 0) {
              item.allowPermission.forEach(el => {
                let indexOfArr = this.formFields.findIndex(item => item.id == el)
                if (indexOfArr > -1) {
                  if (this.formFields[indexOfArr].keyName == 'shippingDate') {
                    this.formData['shippingDate'] = moment().format("YYYY-MM-DD HH:mm:ss")
                  }
                  if (this.formFields[indexOfArr].keyName == 'shipmentType') {
                    this.formData['shipmentType'] = 'Next Shipment'
                  }
                  if (this.formFields[indexOfArr].keyName == 'purpose') {
                    this.formData['purpose'] = 'Client Order'
                  }
                  this.formDetailList.push(this.formFields[indexOfArr])
                }
              })
            }
          }
        }
        )
      }
    })
    console.log(this.formDetailList)

  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "customer") {
      console.log(this.formData)
      if (idList.length > 0) {
        this.selectedCustomer = idList[0];
        this.formData['partyMasterId'] = idList[0];
        this.getAddressesByPartyId(idList[0])
        this.getAllCustomerContact();
        // this.getCustomerDetails(idList[0]);
      } else {
        delete this.formData['partyMasterId']
        delete this.formData['customerContactId']
        this.selectedContactList = []
        this.selectedCustomer = null;
        this.customerContactId = null;
      }
    } else if (property == "contacts") {
      if (idList.length > 0) {
        this.formData['customerContactId'] = idList[0];
        this.customerContactId = idList[0];
        this.selectCustomerContactDetail(this.customerContactId)
      } else {
        delete this.formData['customerContactId']
        this.customerContactId = null;
      }
    } else if (property == "address") {
      if (idList.length > 0) {
        this.formData['location'] = obj[0].address;
        this.formData['address'] = obj[0].address;
        this.listOfAddress.filter(el => {
          if (el.id == idList[0]) {
            this.formData['addSrNo'] = !!el.addSrNo ? el.addSrNo : null;
          }
        })
      } else {
        delete this.formData['location']
        delete this.formData['address']
        delete this.formData['addSrNo']
      }
    }



  }

  selectCustomerContactDetail(customerContactId) {
    this.getAllContacts.filter(el => {
      if (el.id == customerContactId) {
        this.requestContactDetail = {
          "name": el.name,
          "contactNo": el.contactNo,
          "email": el.email,
          "customerMasterId": el.customerMasterId
        }
      }
    })
  }


  closeModal() {
    this.modalCtrl.dismiss();
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.actionId
    );
    let resp = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", resp.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", resp.error);
    } else {
      let dataValue = resp.data;
      this.defaultDetail = dataValue.map;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
        if (!!dataValue.map.SVCKAMMapping) {
          this.SVCKAMMappingEmail = dataValue.map.SVCKAMMapping
        }
        if (!!dataValue.map.defaultEmailList) {
          this.defaultEmailList = dataValue.map.defaultEmailList
        }

        if (!!dataValue.map['Hold Customer']) {
          let dataJson = JSON.parse(dataValue.map['Hold Customer'])
          this.holdVoucherBookId = dataJson.voucherBookId
        }
        if (!!dataValue.map['Unhold Customer']) {
          let dataJson = JSON.parse(dataValue.map['Unhold Customer'])
          this.unHoldVoucherBookId = dataJson.voucherBookId
        }
        if (!!dataValue.map['Block For Sale']) {
          let dataJson = JSON.parse(dataValue.map['Block For Sale'])
          this.blockForSaleVoucherBookId = dataJson.voucherBookId
        }
        if (!!dataValue.map.defaultPartyMasterId) {

          this.defaultPartyMasterId = dataValue.map.defaultPartyMasterId;
          res = await this.httpApiService.getCustomerDetails(this.defaultPartyMasterId);
          await this.configService.dismiss();
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            let dataValueList = [data.data]
            if (!!dataValueList && dataValueList.length > 0) {
              dataValueList[0].partyNameWithType = `${dataValueList[0].partyName} ( ${dataValueList[0].type} )`
              this.getAllCustomers = dataValueList;
              this.formData['partyMasterId'] = this.getAllCustomers[0].id
              this.selectedCustomer = this.getAllCustomers[0].id;
              this.selectedParty = this.getAllCustomers[0];
              this.getAddressesByPartyId(this.getAllCustomers[0].id)
              this.getAllCustomerContact();
            }
          }
        }
      }
    }
  }

  onChangeParty(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (!!event.value) {
      this.formData['partyMasterId'] = event.value.id
      this.selectedCustomer = event.value.id;
      this.getAddressesByPartyId(event.value.id)
      this.getAllCustomerContact();

      // this.getCustomerDetails(event.value.id);
    } else {
      delete this.formData['partyMasterId'];
      this.selectedCustomer = null;
    }
  }

  async getAddressesByPartyId(id) {
    let res: any;
    res = await this.httpApiService.getAddressesByPartyId(id);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      // this.listOfAddress = data.data;
      this.selectedAddressIdList = []
      this.listOfAddress = data.data.filter(el => !!el.isActive)
      console.log(this.listOfAddress)
      if (this.listOfAddress.length == 0) {
        delete this.formData['location']
        delete this.formData['address']
        delete this.formData['addSrNo']
      }
      if (this.listOfAddress.length == 1) {
        this.selectedAddressIdList.push({ id: this.listOfAddress[0].id, address: this.listOfAddress[0].address })
        this.location = this.listOfAddress[0].address;
        this.formData['address'] = this.listOfAddress[0].address;
        this.formData['location'] = this.listOfAddress[0].address;
        this.formData['addSrNo'] = this.listOfAddress[0].addSrNo ? this.listOfAddress[0].addSrNo : null;
      }
    }
  }

  async getAllCustomerContact() {
    // await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getCustomerContactList(
      this.selectedCustomer
    );
    let data = res.json();
    // await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllContacts = data.data;
      if (this.getAllContacts.length == 1) {
        this.selectedContactList = []
        this.selectedContactList.push({ id: this.getAllContacts[0].id, name: this.getAllContacts[0].name });
        this.customerContactId = this.getAllContacts[0].id;
        this.formData['customerContactId'] = this.getAllContacts[0].id;
        this.requestContactDetail = {
          "name": this.getAllContacts[0].name,
          "contactNo": !!this.getAllContacts[0].contactNo ? this.getAllContacts[0].contactNo : '919000000000',
          "email": this.getAllContacts[0].email,
          "customerMasterId": this.getAllContacts[0].customerMasterId
        }
      } else {
        this.selectedContactList = []
        delete this.formData['customerContactId']
      }
      // this.items = this.listOfParameter;
    }
  }


  async searchParty(event: { component: IonicSelectableComponent; text: string }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    if (!this.defaultPartyMasterId) {
      // await this.getAllCustomer(this.pageNumber, this.noOfRecords, searchText);
    }
  }



  // Request Stone

  async requestStone() {
    if (this.convertToShip && this.requestFor == 'Ship') {
      let unholdFinished = await this.doUnHoldCustomer();
      if (unholdFinished) {
        this.doBFS()
      }
    }
    else if (this.requestFor == 'Unhold Customer') {
      this.requestList = []
      this.selectedStone.forEach((el, i) => {
        this.requestList.push({
          "stoneId": el.stoneId,
          "size": el.size,
          "requestDiscount": el.requestDisc ? Number(el.requestDisc) : 0,
          "requestPrice": !!el.requestPrice ? Number(el.requestPrice) : 0,
          "requestAmt": Number((el.requestPrice * el.size).toFixed(3)),
          "stoneRemarks": !!this.formData['remarks'] ? this.formData['remarks'] : null,
        })
      })

      console.log(this.requestList)
      let validate = await this.validateControls();
      if (validate) {
        await this.configService.showLoader();
        let res: any;
        let basicObj, concatedObj, jsonObj = {};
        let dataJson, externalStatus;
        if (this.defaultDetail[this.requestFor]) {
          dataJson = JSON.parse(this.defaultDetail[this.requestFor])
          externalStatus = dataJson.externalStatus;
        }
        basicObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          voucherBookId: this.voucherBookId,
          setOfStoneParameter: this.requestList,
          fromRole: this.toRole,
          requestFor: this.requestFor,
          externalStatus: externalStatus ? externalStatus : null,
        };
        concatedObj = Object.assign(basicObj, this.formData)
        if (this.requestFor == "Unhold Customer") {
          let obj = {
            voucherBookId: this.unHoldVoucherBookId,
          };
          jsonObj = Object.assign(concatedObj, obj)
        }
        console.log(jsonObj)
        res = await this.httpApiService.stoneRequest(jsonObj);
        await this.configService.dismiss();
        let data = res.json();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          if (!!data.data && data.data.length > 0) {
            await this.configService.showToast("error", data.message);
            await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
          } else {
            await this.configService.showToast("error", data.message);
          }
        } else {
          this.formData = {}
          await this.configService.showToast("success", data.message);
          await this.modalCtrl.dismiss(this.requestFor);
        }
      }
    } else if (this.requestFor == 'Hold Customer') {
      this.requestList = []
      this.selectedStone.forEach((el, i) => {
        this.requestList.push({
          "stoneId": el.stoneId,
          "size": el.size,
          "requestDiscount": el.requestDisc ? Number(el.requestDisc) : 0,
          "requestPrice": !!el.requestPrice ? Number(el.requestPrice) : 0,
          "requestAmt": Number((el.requestPrice * el.size).toFixed(3)),
          "stoneRemarks": !!this.formData['remarks'] ? this.formData['remarks'] : null,
        })
      })
      // let isDiscountNotPresent = this.requestList.find(el => !el.requestDiscount)
      // if (!!isDiscountNotPresent) {
      //   await this.configService.showToast("error", 'One or more stones have 0 discount');
      //   await this.configService.dismiss();
      //   return;
      // }
      let isPriceNotExist = this.requestList.find(el => !el.requestPrice)
      if (!!isPriceNotExist) {
        await this.configService.showToast("error", 'One or more stones have 0 price');
        await this.configService.dismiss();
        return
      }
      console.log(this.requestList)
      let validate = await this.validateControls();
      if (validate) {
        await this.configService.showLoader();
        let res: any;
        let basicObj, concatedObj, jsonObj = {};
        if (this.formData['shippingDate']) {
          this.formData['shippingDate'] = moment(new Date(this.formData['shippingDate'])).format("YYYY-MM-DD")
        }
        let dataJson, externalStatus;
        if (this.defaultDetail[this.requestFor]) {
          dataJson = JSON.parse(this.defaultDetail[this.requestFor])
          externalStatus = dataJson.externalStatus;
        }
        basicObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          voucherBookId: this.voucherBookId,
          setOfStoneParameter: this.requestList,
          fromRole: this.toRole,
          requestFor: this.requestFor,
          kamEmail: this.loggedInUserEmail,
          requestPlatform: "SVC",
          emailTemplate: {
            isMailSend: true,
            loggedInUserEmail: this.loggedInUserEmail,
            kamEmail: this.SVCKAMMappingEmail + !!this.defaultEmailList ? ',' + this.defaultEmailList : '',
          },
          externalStatus: externalStatus ? externalStatus : null,

        };
        concatedObj = Object.assign(basicObj, this.formData)
        if (this.requestFor == "Hold Customer") {
          let obj = {
            voucherBookId: this.holdVoucherBookId,
          };
          jsonObj = Object.assign(concatedObj, obj)
        }
        console.log(jsonObj)
        res = await this.httpApiService.stoneRequest(jsonObj);
        await this.configService.dismiss();
        let data = res.json();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          if (!!data.data && data.data.length > 0) {
            await this.configService.showToast("error", data.message);
            await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
          } else {
            await this.configService.showToast("error", data.message);
          }
        } else {
          this.formData = {}
          await this.configService.showToast("success", data.message);
          await this.modalCtrl.dismiss(this.requestFor);
        }
      }
    } else {
      await this.configService.showLoader();
      this.requestList = []
      this.selectedStone.forEach((el, i) => {
        this.requestList.push({
          "stoneId": el.stoneId,
          "size": el.size,
          "requestDiscount": el.requestDisc ? Number(el.requestDisc) : 0,
          "requestPrice": !!el.requestPrice ? Number(el.requestPrice) : 0,
          "requestAmt": Number((el.requestPrice * el.size).toFixed(3)),
          "stoneRemarks": !!this.formData['remarks'] ? this.formData['remarks'] : '',
        })
      })

      // let isDiscountNotPresent = this.requestList.find(el => !el.requestDiscount)
      // if (!!isDiscountNotPresent) {
      //   await this.configService.showToast("error", 'One or more stones have 0 discount');
      //   await this.configService.dismiss();
      //   return;
      // }

      let isPriceNotExist = this.requestList.find(el => !el.requestPrice)
      if (!!isPriceNotExist) {
        await this.configService.showToast("error", 'One or more stones have 0 price');
        await this.configService.dismiss();
        return;
      }
      console.log(this.requestList)
      let basicObj, concatedObj, jsonObj = {};
      let dataJson, externalStatus;
      if (this.defaultDetail[this.requestFor]) {
        dataJson = JSON.parse(this.defaultDetail[this.requestFor])
        externalStatus = dataJson.externalStatus;
      }

      basicObj = {
        voucherDate: moment().format("YYYY-MM-DD"),
        voucherBookId: this.blockForSaleVoucherBookId,
        setOfStoneParameter: this.requestList,
        fromRole: this.toRole,
        kamEmail: this.loggedInUserEmail,
        requestPlatform: "SVC",
        emailTemplate: {
          isMailSend: true,
          loggedInUserEmail: this.loggedInUserEmail,
          kamEmail: this.SVCKAMMappingEmail + !!this.defaultEmailList ? ',' + this.defaultEmailList : '',
        },
        requestFor: 'Block For Sale',
        externalStatus: externalStatus ? externalStatus : null
      };
      concatedObj = Object.assign(basicObj, this.formData)
      console.log(concatedObj)
      let res = await this.httpApiService.stoneRequest(concatedObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (!!data.data && data.data.length > 0) {
          await this.configService.showToast("error", data.message);
          await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        } else {
          await this.configService.showToast("error", data.message);
        }
      } else {
        this.formData = {}
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss(this.requestFor);
      }
    }
  }


  async doUnHoldCustomer() {
    await this.configService.showLoader();
    this.requestList = []
    this.selectedStone.forEach((el, i) => {
      this.requestList.push({
        "stoneId": el.stoneId,
        "size": el.size,
        "requestDiscount": el.requestDisc ? Number(el.requestDisc) : 0,
        "requestPrice": !!el.requestPrice ? Number(el.requestPrice) : 0,
        "requestAmt": Number((el.requestPrice * el.size).toFixed(3)),
        "stoneRemarks": !!this.formData['remarks'] ? this.formData['remarks'] : null,
      })
    })

    let res: any;
    let basicObj, concatedObj, jsonObj = {};
    let dataJson, externalStatus;
    basicObj = {
      voucherDate: moment().format("YYYY-MM-DD"),
      voucherBookId: this.voucherBookId,
      setOfStoneParameter: this.requestList,
      fromRole: this.toRole,
      requestFor: 'Unhold Customer',
      externalStatus: externalStatus ? externalStatus : null,
    };
    concatedObj = Object.assign(basicObj, this.formData)
    let obj = {
      voucherBookId: this.unHoldVoucherBookId,
    };
    jsonObj = Object.assign(concatedObj, obj)
    res = await this.httpApiService.stoneRequest(jsonObj);
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
      await this.configService.dismiss();
    } else if (res.status == 500) {
      await this.configService.dismiss();
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.dismiss();
      if (!!data.data && data.data.length > 0) {
        await this.configService.showToast("error", data.message);
        await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
      } else {
        await this.configService.showToast("error", data.message);
      }
    } else {
      return true
    }
  }

  async doBFS() {
    this.requestList = []
    this.selectedStone.forEach((el, i) => {
      this.requestList.push({
        "stoneId": el.stoneId,
        "size": el.size,
        "requestDiscount": el.requestDisc ? Number(el.requestDisc) : 0,
        "requestPrice": !!el.requestPrice ? Number(el.requestPrice) : 0,
        "requestAmt": Number((el.requestPrice * el.size).toFixed(3)),
        "stoneRemarks": !!this.formData['remarks'] ? this.formData['remarks'] : '',
      })
    })

    this.selectedStone.forEach((el, i) => {
      const size = el.size || el.stoneCts;
      const rapPrice = el.rapPrice || null;
      const requestPrice = Number(el.requestPrice) || 0;
      const requestDiscount = rapPrice
        ? parseFloat((((rapPrice - requestPrice) * 100) / rapPrice).toFixed(2))
        : parseFloat((el.requestDisc || 0).toFixed(2));
      const requestAmt = parseFloat((requestPrice * size).toFixed(2));
      const stoneRemarks = !!this.formData['remarks'] ? this.formData['remarks'] : '';
      this.requestList.push({
        stoneId: el.id.toString(),
        size: size,
        rapPrice: rapPrice,
        requestPrice: requestPrice,
        requestDiscount: requestDiscount,
        requestAmt: requestAmt,
        srNo: i + 1,
        stoneRemarks: stoneRemarks,
      });
    });

    // let isDiscountNotPresent = this.requestList.find(el => !el.requestDiscount)
    // if (!!isDiscountNotPresent) {
    //   await this.configService.showToast("error", 'One or more stones have 0 discount');
    //   await this.configService.dismiss();
    //   return;
    // }

    let isPriceNotExist = this.requestList.find(el => !el.requestPrice)
    if (!!isPriceNotExist) {
      await this.configService.showToast("error", 'One or more stones have 0 price');
      await this.configService.dismiss();
      return;
    }
    console.log(this.requestList)
    let basicObj, concatedObj, jsonObj = {};
    let dataJson, externalStatus;
    if (this.defaultDetail[this.requestFor]) {
      dataJson = JSON.parse(this.defaultDetail[this.requestFor])
      externalStatus = dataJson.externalStatus;
    }

    basicObj = {
      voucherDate: moment().format("YYYY-MM-DD"),
      voucherBookId: this.blockForSaleVoucherBookId,
      setOfStoneParameter: this.requestList,
      fromRole: this.toRole,
      kamEmail: this.loggedInUserEmail,
      requestPlatform: "SVC",
      emailTemplate: {
        isMailSend: true,
        loggedInUserEmail: this.loggedInUserEmail,
        kamEmail: this.SVCKAMMappingEmail + !!this.defaultEmailList ? ',' + this.defaultEmailList : '',
      },
      requestFor: 'Block For Sale',
      externalStatus: externalStatus ? externalStatus : null
    };
    concatedObj = Object.assign(basicObj, this.formData)
    console.log(concatedObj)
    let res = await this.httpApiService.stoneRequest(concatedObj);
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      if (!!data.data && data.data.length > 0) {
        await this.configService.showToast("error", data.message);
        await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
      } else {
        await this.configService.showToast("error", data.message);
      }
    } else {
      this.formData = {}
      await this.configService.showToast("success", data.message);
      await this.modalCtrl.dismiss(this.requestFor);
    }
  }

  async validateControls() {
    let error: Boolean = false;
    console.log(this.formData)
    for (let i = 0; i < this.formDetailList.length; i++) {
      if (this.formDetailList[i].isRequired) {
        if (!this.formData[this.formDetailList[i].keyName] && this.formDetailList[i].keyName != 'spreadRate' && this.formDetailList[i].keyName != 'currencyRate') {
          if ((this.formDetailList[i].keyName == 'shippingCost' && this.formData[this.formDetailList[i].keyName] == 0)) {

          } else {
            await this.configService.showToast('error', this.formDetailList[i].name + ' is required.')
            error = true
            break;
          }
        }
      }
    }
    if (error) {
      return false;
    } else {
      return true;
    }
  }





}
