import { Component, Input, OnInit } from '@angular/core';
import { ProcessActionComponent } from '../../processAction';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-polish-purchase-inward-report',
  templateUrl: './polish-purchase-inward-report.component.html',
  styleUrls: ['./polish-purchase-inward-report.component.scss']
})
export class PolishPurchaseInwardReportComponent implements ProcessActionComponent {
  @Input() data: any;

  constructor(public sanitizer: DomSanitizer) { }
  dataStudioLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://lookerstudio.google.com/embed/reporting/980a40fc-5501-4b2d-9223-e03975a2513b/page/xFGcD');
  ngOnInit() {
  }

}
