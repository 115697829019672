import { Component, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events,
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-update-parameter",
  templateUrl: "./update-parameter.component.html",
  styleUrls: ["./update-parameter.component.scss"],
})
export class UpdateParameterComponent implements ProcessActionComponent {
  @Input() data: any;
  companyId: number;
  public stoneId: any;
  userId: number;
  allLocations = [];
  toLocationIdList = [];
  toLocation = "surat";

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events
  ) {}

  ngOnInit() {
    this.getAllLocations();
  }

  async getAllLocations() {
    let res: any;
    let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allLocations = data.data;
      if (data.data) {
        await this.allLocations.forEach((element) => {
          if (element.companyName == this.toLocation) {
            this.companyId = element.id;
            let data = { id: element.id, companyName: element.companyName };
            this.toLocationIdList = [];
            this.toLocationIdList.push(data);
            console.log(this.toLocationIdList, "-->> this.toLocationIdList");
          }
        });
      }

      console.log(this.allLocations);
      //await this.getDefaultSetting();
    }
  }

  async updateStoneParameterFromKG() {
    if (!!this.stoneId) {
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.updateStoneParameterFromKG(this.stoneId, this.companyId);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
      }
    } else {
      await this.configService.showToast("error", "Please enter StoneId");
    }
  }
  async getUserData() {
    let userData = await this.authService.userData();
    this.userId = userData.userId;
  }

  // async getDefaultSetting() {
  //   let res: any;
  //   let userData = await this.authService.userData();
  //   this.userId = userData.userId;
  //   res = await this.httpApiService.getDefaultSetting(
  //     this.userId,
  //     this.data.actionId
  //   );
  //   let data1 = res.json();
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data1.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data1.error);
  //   } else {

  //     let dataValue = data1.data;
  //     console.log("DATa", dataValue);
  //     if (!!dataValue.map) {

  //       if (!!dataValue.map.toLocation) {
  //         this.toLocation = dataValue.map.toLocation;
  //         this.toLocationIdList = [];
  //         this.allLocations.forEach(element => {
  //           if (element.id == this.toLocation) {
  //             let data = { id: element.id, companyName: element.companyName };
  //             this.toLocationIdList.push(data);
  //           }
  //         });
  //       }

  //     }
  //   }
  // }

  dropDownSelection(ev) {
    console.log(ev);
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "toLocationFile") {
      if (idList.length > 0) {
        this.companyId = idList[0];
      } else {
        this.companyId = null;
      }
    }
  }
}
