import { Component, Input, HostListener } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { LoadingController, ModalController, AlertController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManageProcessRuleConfigComponent } from "../manage-process-rule-config/manage-process-rule-config.component";

@Component({
  selector: 'app-process-rule-config',
  templateUrl: './process-rule-config.component.html',
  styleUrls: ['./process-rule-config.component.scss']
})
export class ProcessRuleConfigComponent implements ProcessActionComponent {
  @Input() data: any;

  public columns = [];
  public departmentList = [];
  public page = 'Process Rule Config';
  public listOfStatus = [];
  public listOfStatusDataSource: any = {};
  public count = 0;
  public items = [];
  public manageSelectedStatus = '';
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public refreshGrid: Boolean = false;
  public depertment =''

  constructor(
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) {
    this.deleteItem = this.deleteItem.bind(this);
  }


  async deleteItem(ev) {
    this.confirmDelete(ev.row.data);
  }


  async confirmDelete(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Status ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteConfig(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteConfig(id) {
    console.log(id)
    let res: any;
    res = await this.httpApiService.deleteDapartmentConfig(id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.getAllConfig();
    }
  }

  ngOnInit() {
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, { name: "Department", prop: "dept" }, { name: "Date", prop: "createdDate" }, { name: "State", prop: "state" }];

    this.getAllConfig()
    this.event.subscribe('Status Master', (item) => {
      this.getAllConfig()
    })
  }

  async filterItems(ev: any) {
    let val = ev.target.value;
    console.log(val)
    await this.setItems();
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        if (item.dept && item.dept.toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (item.state && item.state.toLowerCase().includes(val.toLowerCase())) {
          return true;
        }
      });
      this.count = this.items.length;
    } else {
      await this.setItems();
    }
  }

  setItems() {
    this.items = this.departmentList;
    this.count = this.departmentList.length;
  }

  async getAllConfig() {
    let res: any;
    res = await this.httpApiService.getAllConfig();
    console.log(res)
    let jsonData = res.json();
    console.log(jsonData)
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", jsonData.message);
    } else if (res.status == 400) {
      await this.configService.showToast("error", jsonData.message);
    } else {
      this.departmentList = jsonData.data;
      this.items = this.departmentList;
    }
  }


  async manageDept(item, flag) {
    this.refreshGrid = false;
    if ((item.type == "mouseenter") || (item.column && item.column.name == 'Action')) {
      item.event.stopPropagation();
    } else {
      if (flag == 'update' && this.updatePermissionFlag == true) {
        let modal = await this.modalCtrl.create({
          component: ManageProcessRuleConfigComponent,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: { item: item, flag: flag, }
        })
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
      if (flag == 'add' && this.addPermissionFlag == true) {
        let modal = await this.modalCtrl.create({
          component: ManageProcessRuleConfigComponent,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: { item: item, flag: flag, }
        })
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
    }
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    if (d.data != 'doNotRefresh') {
      await this.getAllConfig();
    }
  }




}
