import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import {
  ModalController,
  ToastController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManageExternalInventoryStonesPage } from "../../actions/manage-external-inventory-stones/manage-external-inventory-stones.page";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: 'app-pending-approval',
  templateUrl: './pending-approval.component.html',
  styleUrls: ['./pending-approval.component.scss']
})
export class PendingApprovalComponent implements ProcessActionComponent {
  @Input() data: any;

  public columns = [];
  public page = 'pendingTransferApproval';
  public listOfAllUnApproveVouchers = [];
  public listOfAllUnApproveVouchersDataSource: any;
  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 50;
  public items = []
  public count = 0;
  public totalPages: Number = 0;
  public voucherBookList = []
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;

  public selectedAction: any;
  public partyMasterId: any;
  public partyIdList = [];
  public actionIdList = []
  public actionList = []
  public listOfCustomers = []
  public refreshGrid = false;

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events) { }

  async ngOnInit() {
    await this.getDefaultSetting()
    // await this.getAllActions();
    console.log(this.data.listOfActionPermissions)
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
    }
    this.columns = [
      { name: "Voucher No", prop: "" },
      { name: "Request For", prop: "requestFor" },
      { name: "Party Name", prop: "partyName" },
      { name: "Created By", prop: "createdBy" },
      { name: "Voucher Date", prop: "voucherDate" },
      { name: "Created Date", prop: "modifiedDate" },
    ]
    // if (this.deletePermissionFlag == true) {
    //   this.columns.push({ name: "Reject", prop: "" })
    // }
    this.getAllOutgoingIssueVoucher(this.selectedAction, this.partyMasterId, this.pageNumber, this.noOfRecords)
    this.event.subscribe('Pending Transfer Approval', (item) => {
      this.columns = [
        { name: "Voucher No", prop: "" },
        { name: "Request For", prop: "requestFor" },
        { name: "Party Name", prop: "partyName" },
        { name: "Created By", prop: "createdBy" },
        { name: "Voucher Date", prop: "voucherDate" },
        { name: "Created Date", prop: "modifiedDate" },
      ]
      if (this.deletePermissionFlag == true) {
        this.columns.push({ name: "Reject", prop: "" })
      }
      this.getAllOutgoingIssueVoucher(this.selectedAction, this.partyMasterId, this.pageNumber, this.noOfRecords)
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Pending Transfer Approval');
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBookList) {
          this.voucherBookList = JSON.parse(dataValue.map.voucherBookList)
        }
      }
      console.log(this.voucherBookList)
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "selectedParty") {
      if (idList.length > 0) {
        this.partyMasterId = idList[0];
        this.getAllOutgoingIssueVoucher(this.selectedAction, this.partyMasterId, this.pageNumber, this.noOfRecords)
      } else {
        this.partyMasterId = null;
        this.partyIdList = []
      }
    } else if (property == "selectedAction") {
      if (idList.length > 0) {
        this.selectedAction = obj[0].actionName;
        this.partyMasterId = null;
        this.partyIdList = []
        // this.getAllCustomer(this.selectedAction)
        this.getAllOutgoingIssueVoucher(this.selectedAction, this.partyMasterId, this.pageNumber, this.noOfRecords)
      } else {
        this.actionIdList = []
        this.partyMasterId = null;
      }
    }
  }

  // async getAllCustomer(action) {
  //   let res: any;
  //   res = await this.httpApiService.findPartyByAction(action, false, null);
  //   let data = res.json();
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     this.listOfCustomers = data.data;
  //   }
  // }

  // async getAllActions() {
  //   await this.configService.showLoader();
  //   let res: any;
  //   res = await this.httpApiService.findAllTransferAction(false);
  //   let resp = res.json();
  //   await this.configService.dismiss();
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", resp.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", resp.error);
  //   } else {
  //     this.actionList = resp.data;
  //   }
  // }

  async getAllOutgoingIssueVoucher(actionName, partyMasterId, pageNumber, noOfRecords) {
    console.log(actionName, partyMasterId, pageNumber, noOfRecords)
    let res: any;
    let jsonData = {};
    jsonData = {
      approved: false,
      setOfVoucherBooks: this.voucherBookList,//list of voucher book in case of limited voucher to show
    }
    if (actionName) {
      jsonData['actionName'] = actionName;
      console.log(jsonData)
    }
    if (partyMasterId) {
      jsonData['partyMasterId'] = partyMasterId;

    }
    console.log(jsonData)
    // res = await this.httpApiService.getApprovalList(jsonData, pageNumber, noOfRecords);
    // let data = res.json();
    // if (data.status == 401) {
    //   await this.authService.logout()
    // } else if (data.status == 500) {
    //   await this.configService.showToast('error', data.error)
    // } else if (data.status == 400) {
    //   await this.configService.showToast('error', data.error)
    // } else {
    //   this.listOfAllUnApproveVouchers = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    // }
    this.listOfAllUnApproveVouchersDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100,500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getApprovalList(jsonData, this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfAllUnApproveVouchers = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfAllUnApproveVouchers,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async getStoneDetailByVoucherId(item) {
    this.refreshGrid = false;
    if (this.updatePermissionFlag == true) {
      console.log("getStoneDetailByVoucherModal ", item.row);
      let modalPage = await this.modalCtrl.create({
        component: ManageExternalInventoryStonesPage,
        backdropDismiss: false,
        componentProps: { data: item.row, actionName: item.row.requestFor, fromPage: 'pendingApproval' },
        cssClass: "requestPage"
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return modalPage.present();
    }
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    await this.getAllOutgoingIssueVoucher(this.selectedAction, this.partyMasterId, this.pageNumber, this.noOfRecords)
  }

  //Delete Request

  async deleteConfirm(item) {
    console.log(item)
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Request ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteRequest(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteRequest(item) {
    console.log(item)
    let res: any;
    res = await this.httpApiService.deleteTransferRequest(item.row.data.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.getAllOutgoingIssueVoucher(this.selectedAction, this.partyMasterId, this.pageNumber, this.noOfRecords)
    }
  }

  //END

  setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getAllOutgoingIssueVoucher(this.selectedAction, this.partyMasterId, this.pageNumber, this.noOfRecords)
  }
}
