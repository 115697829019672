import { Component, Input } from "@angular/core";
import {
  NavController,
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
  Events
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: 'app-pricing-history',
  templateUrl: './pricing-history.component.html',
  styleUrls: ['./pricing-history.component.scss']
})
export class PricingHistoryComponent implements ProcessActionComponent {
  @Input() data: any;

  stoneId = '';
  fromDate: any;
  toDate: any;
  columns = []
  listOfPrices = [];
  page = 'pricingHistory'
  refreshGrid = false;

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public loadingController: LoadingController,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService) { }

  ngOnInit() {
    this.columns = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Price Name", prop: "priceListName" },
      { name: "Disc % ", prop: "discountPercent" },
      { name: "Rate/Cts ", prop: "price" },
      // { name: "Policy Name", prop: "pricePolicyName" },
      { name: "Date", prop: "modifiedDate" },
    ];
  }

  async fetch() {
    let formData = {
      stoneName: this.stoneId,
      fromDate: this.fromDate,
      toDate: this.toDate
    }
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.stoneWisePricingHistory(formData);
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfPrices = data.data;
    }
  }

}
