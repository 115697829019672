import { Component, Input, HostListener, ViewChild, ElementRef } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { LoadingController, ModalController, AlertController, Events, IonInput } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { SearchService } from "src/app/services/search/search.service";
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-update-available-for-sales',
  templateUrl: './update-available-for-sales.component.html',
  styleUrls: ['./update-available-for-sales.component.scss']
})
export class UpdateAvailableForSalesComponent implements ProcessActionComponent {
  @Input() data: any;
  stonesList = []
  public columns: any = [];
  public selected: any = [];
  public refreshGrid: boolean = false;
  public inputList: any;
  public allPriceNameList = [];
  public pricelistName = '';
  public companyId: any;

  constructor(private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public configService: ConfigService,
    public event: Events,
    private es: SearchService,) {
    this.event.subscribe('Update Available For Sale', (item) => {
    })
  }

  async ngOnInit() {
    this.companyId = await this.authService.getCompanyId()
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, {
      name: 'Stone Id', prop: 'stoneName'
    },
    { name: 'Color', prop: 'ColorCode' },
    { name: 'Clarity', prop: 'ClarityCode' },
    { name: 'Shape', prop: 'ShapeCode' },
    { name: 'Size', prop: 'cts' },
    { name: 'Lab', prop: 'lab' },
    { name: 'Internal Status', prop: 'internalStatus' },
    { name: 'External Status', prop: 'externalStatus' },
    { name: 'Available For Sale', prop: 'availableForSale' },
    ]
  }

  ngOnDestroy() {
    this.event.unsubscribe('Update Available For Sale')
  }

  async fetch() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, {
      name: 'Stone Id', prop: 'stoneName'
    },
    { name: 'Color', prop: 'ColorCode' },
    { name: 'Clarity', prop: 'ClarityCode' },
    { name: 'Shape', prop: 'ShapeCode' },
    { name: 'Size', prop: 'cts' },
    { name: 'Lab', prop: 'lab' },
    { name: 'Internal Status', prop: 'internalStatus' },
    { name: 'External Status', prop: 'externalStatus' },
    { name: 'Available For Sale', prop: 'availableForSale' },
    ]
    let qry: any =
    {
      "query": {
        "bool": {
          "should": [
            {
              "simple_query_string": {
                "query": ""
              }
            },
            {
              "bool": {
                "must": []
              }
            }
          ]
        }
      }
    }

    let tmp = this.inputList.split("\n");
    let boolShouldMultiple = {
      "bool": {
        "should": []
      }
    };
    let location = {
      "terms": { location: ["mumbai"] }
    }
    qry.query.bool.should[1].bool.must.push(location);
    // for (let i = 0; i < tmp.length; i++) {
    //   if (tmp[i] != '') {
    //     let sData = {
    //       terms: {
    //         "stoneName": tmp[i].trim()
    //       }
    //     }
    //     // let prefixObj: any = {};
    //     // prefixObj =
    //     // {
    //     //   "prefix": {
    //     //     "stoneName": tmp[i].trim()
    //     //   }
    //     // }
    //     boolShouldMultiple.bool.should.push(sData);
    //   }
    // }
    let sData = {
      terms: {
        "stoneName": tmp.filter(el => !!el).map(el => el.trim())
      }
    }
    boolShouldMultiple.bool.should.push(sData);
    qry.query.bool.should[1].bool.must.push(boolShouldMultiple);

    let data = await this.es.getPaginatedDocuments(qry, 0, environment.INDEX, '', 1000)
    data = data.hits.hits.map(d => d._source).map(res => {
      let stoneId = res.stoneName;
      if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
        let index = stoneId.split('/', 3).join('/').length;
        res['stoneName'] = String(stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))));
      } else {
        res['stoneName'] = String(res['stoneName']);
      }
      return res;
    })

    this.stonesList = data;

    // let dObj ={
    //   'stoneData':data,
    //   'stickerType':this.stickerType
    // }
    // this.modalCtrl.dismiss(dObj);
  }

  async OnSelectRows(event) {
    this.selected = event.selectedRowsData
  }


  async updateAvailableForSaleStatus(availableForSale) {
    this.selected
    let formData: any = {
      setOfValues: this.selected.map(el => el.stoneName),
      companyId: this.companyId,
      availableForSale: availableForSale
    };
    this.configService.showLoader()

    let res: any;
    res = await this.httpApiService.updateAvailableForSaleStatus(
      formData
    );
    let data = res.json();
    this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.selected = [];
      this.stonesList = [];
      this.inputList = ''
    }
  }

}
