export default {
  "reportNo": "R-6",
  "url": "master-service/api/report/csspStock",
  "method": "GET",
  "size": 3,
  "fields": [
    {
      "title": " Department Name ",
      "input": "select",
      "key": "departmentId",
      "placeholder": "Select Department Type",
      "property": "departmentId",
      "keyName": "id",
      "name": "name",
      "required": true,
      "singleSelection": true,
      "function": "getAllDepartment",
      "default": [],
      "size": 3
    },
    {
      title: ' Consignment ',
      input: 'select',
      key: 'consignment',
      placeholder: 'Select Consignment',
      property: 'consignment',
      keyName: 'id',
      name: 'name',
      singleSelection: true,
      itemList: [{
        id: true,
        name: 'True'
      }, {
        id: false,
        name: 'False'
      }],
      default: [],
      size: 3
    }
  ],
  "columns": [
    { name: "Stone Id", prop: "stoneId" },
    { name: "Color", prop: "color" },
    { name: "Shape", prop: "shape" },
    { name: "Size", prop: "sizeName" },
    { name: "Clarity", prop: "clarity" },
    { name: "Cts", prop: "stoneCts" },
  ]
};
