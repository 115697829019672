import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-current-parameter-search',
  templateUrl: './current-parameter-search.component.html',
  styleUrls: ['./current-parameter-search.component.scss']
})
export class CurrentParameterSearchComponent implements OnInit {
  @Input() data: any;
  public query: any;
  public type = "SS";
  public disable: any = [];
  public cart = false;
  public svcDataOption = false;
  public svcRequestOption = false;
  public showScannerOption = false;
  public elasticIndex = environment.INDEX + ',' + environment.INDEX2;
  public showSoldStone = true;
  public default = {
    criteria: true,
    search: true,
    shape: true,
    carat: true,
    color: true,
    colorOverride: false,
    clarity: true,
    clarityOverride: false,
    description: true,
    finishing: true,
    fluorescence: true,
    lab: true,
    size: true,
    internalStatus: true,
    externalStatus: true,
    transType: true,
    users: true,
    location: true,
    box: true,
    department: true,
    saveSearch: true,
    download: true,
    fm: true,
    party: true,
    availableForSale: true,
    certiDate: true,
    inwardDate: true,
    transactionDate: true
  };
  constructor() { }

  ngOnInit() {
    let name = [];
    this.disable.push("transType");
    name.push(this.type);
    this.query = {
      //   facetFilters: ["transType:" + [this.type], "department:" + [this.department]],
      disjunctiveFacetsRefinements: {
        transType: name,
      }
    };
  }

}
