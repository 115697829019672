import { Component, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";


@Component({
  selector: 'app-manage-process-rule-config',
  templateUrl: './manage-process-rule-config.component.html',
  styleUrls: ['./manage-process-rule-config.component.scss']
})
export class ManageProcessRuleConfigComponent implements OnInit {
  departmentList;
  actionsList;
  doneactionsList = [];
  deptIdList = [];
  stateIdList = [];
  allowDuplicates: Boolean = false;
  public flagAddUpdate: any;
  public stateList = [];
  stateId;
  deptId;
  doneActionId;
  actionsIdsList = [];
  doneActionsIdList = [];
  actionId;
  item;
  id;

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) {

    this.stateList = [{ id: 1, name: 'In Transit' }, { id: 2, name: 'Received' }, { id: 6, name: 'Return' }]



  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'stateList') {
      if (idList.length > 0) {
        this.stateId = obj[0].id;
      } else {
        this.stateId = null
      }
    } else if (property == 'deptList') {
      if (idList.length > 0) {
        this.deptId = obj[0].id;
      } else {
        this.deptId = null
      }
    } else if (property == 'doneActionsList') {
      if (idList.length > 0) {
        this.doneActionId = obj[0].id;
      } else {
        this.doneActionId = null
      }
    } else if (property == 'actionsList') {
      if (idList.length > 0) {
        this.actionId = obj[0].id;
      } else {
        this.actionId = null
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh')
  }

  async ngOnInit() {
    await this.getAllDepartment();
    await this.getAllActions();
    let data = this.navParams.get('item');

    if (data != 0) {
      this.item = data.row;
      console.log(this.item)
      this.id = this.item.id
    }

    this.flagAddUpdate = this.navParams.get('flag');
    if (this.flagAddUpdate == 'update') {
      console.log(this.item)
      if (this.item.deptId) {
        this.deptId = this.item.deptId;
        this.deptIdList = this.departmentList.filter(x => x.id == this.deptId)
      }

      if (this.item.nextAction) {
        this.actionId = this.item.nextAction;
        this.actionsIdsList = this.actionsList.filter(x => x.id == this.actionId)
      }

      if (this.item.ifDone) {
        this.doneActionId = this.item.ifDone
        this.doneActionsIdList = this.actionsList.filter(x => x.id == this.doneActionId)
      }

      if (this.item.stateId) {
        this.stateId = this.item.stateId
        this.stateIdList = this.stateList.filter(x => x.id == this.stateId)
      }

      this.allowDuplicates = this.item.allowDuplicates
    }
  }

  async getAllDepartment() {
    let res: any;
    res = await this.httpApiService.getAllDepartment(
      this.configService.appName
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.departmentList = data.data;
    }
  }

  async getAllActions() {
    let res: any;
    res = await this.httpApiService.getAllActions();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.actionsList = data.data;
      this.doneactionsList = data.data
    }
  }

  async add() {
    let jsonObj = {
      'deptId': this.deptId,
      'stateId': this.stateId,
      'nextAction': this.actionId,
      'ifDone': this.doneActionId,
      'allowDuplicates': this.allowDuplicates
    }


    let res = await this.httpApiService.addProcessRuleCOnfig(jsonObj);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.modalCtrl.dismiss()
    }

  }

  async update() {
    let jsonObj = {
      'deptId': this.deptId,
      'stateId': this.stateId,
      'nextAction': this.actionId,
      'ifDone': this.doneActionId,
      'allowDuplicates': this.allowDuplicates
    }

    let res = await this.httpApiService.updateProcessRuleCOnfig(jsonObj, this.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.modalCtrl.dismiss()
    }
  }

}
