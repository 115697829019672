export default {
  reportNo: 'R-26',
  url: "master-service/api/report/assortment-report-mix",
  method: "GET",
  size: 3,
  fields: [
  ],
  columns: [
  ],
  dataStudioLink: 'https://lookerstudio.google.com/embed/reporting/30db2de0-0e28-49fa-a4c1-cc9f28a7fe66/page/G2gfD'
};