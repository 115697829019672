import { Component, Input, OnInit } from "@angular/core";
import { ModalController, LoadingController, NavParams } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";


@Component({
  selector: "app-pd-assort-details",
  templateUrl: "./pd-assort-details.page.html",
  styleUrls: ["./pd-assort-details.page.scss"]
})
export class PdAssortDetailsPage implements OnInit {
  @Input() pdHistoryList = []
  public rowData: any;
  public assormentData = [];
  public columns = [];
  public headers = [];
  public page = 'AssortPDResult';
  public count = 0;
  paramList = []
  selectedFile: File = null;
  pageNumber = 0;
  noOfRecords = 50
  historyDataSource: any = {};
  historyData = []
  public defaultVotesValue = [];
  public showPdResult = false;
  public pageSize = 50;
  public companyId = null;
  public historyView = null;
  public status: string = '';
  public companyIds: string = '';
  public actionId: number = null;
  constructor(public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, private authService: AuthenticationService, public configService: ConfigService,
  ) {

  }

  async ngOnInit() {
    this.rowData = await this.navParams.get("rowData");
    this.showPdResult = await this.navParams.get("showPdResult");
    this.actionId = await this.navParams.get("actionId");
    this.status = this.navParams.get("status");
    if (this.status == 'showPDData') {
      if (!!this.rowData && this.rowData.epPrice) {
        this.historyView = JSON.parse(this.rowData.epPrice)
      }
      if (this.pdHistoryList.length > 0) {
        for (const property in this.historyView) {
          this.paramList.push({ label: property, value: this.historyView[property], isChange: false })
        }
      }
    }
    else if (this.status == 'showUploadPDForm') {
      this.getDefaultValues()
      this.page = "pdUpload";
      this.columns = [
        { name: "File Name", prop: "fileName" },
        { name: "Status", prop: "status" },
        { name: "By", prop: "username" },
        { name: "Date & Time", prop: "createdDate" }
      ];
      this.loadCertificationFileUploadHistory()
    }
    else {
      this.status = null;
      this.pdAssortData();
    }

  }


  async getDefaultValues() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(dataValue);
      if (
        !!dataValue.map &&
        dataValue.map.companyIds
      ) {
        this.companyIds = dataValue.map.companyIds;
      }
    }
  }

  async pdAssortData() {
    let id = this.rowData.id
    let res = await this.httpApiService.findResultOfPDAssort(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.headers = dataValue.header;
      this.defaultVotesValue = dataValue.defaultVotesValue;
      for (let i = 0; i < this.headers.length; i++) {
        this.columns.push({ name: this.headers[i], prop: this.headers[i] })
      }
      this.assormentData = dataValue.listOfParamValue;
      this.count = this.assormentData.length;

      console.log("assortment data is", this.defaultVotesValue, this.columns, this.headers, this.assormentData);
    }
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  async uploadFile() {
    if (!this.companyIds) {
      await this.configService.showToast("error", 'Please add company from action default');
      return true;
    }
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.uploadPDCatByFile(formData, this.companyIds);
      console.log(res.json());
      await this.configService.dismiss();
      let uploadData = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 400) {
        await this.configService.showToast("error", uploadData.message);
      } else {
        await this.configService.showToast(
          "success",
          uploadData.message
        );
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  //certification file upload history data

  async loadCertificationFileUploadHistory() {

    this.historyDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getUploadHistory(
            'Update Stone Details Parameters',
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.historyData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.historyData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async downloadFile(ev) {
    let res: any;
    res = await this.httpApiService.downloadUploadedFile(ev.id);
  }

}
