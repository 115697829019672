import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams, Events } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { HttpApiService } from 'src/app/services/http-api/http-api.service';
import { ConfigService } from 'src/app/services/config/config.service';
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";


@Component({
  selector: 'app-upload-single-to-mix',
  templateUrl: './upload-single-to-mix.page.html',
  styleUrls: ['./upload-single-to-mix.page.scss'],
})
export class UploadSingleToMixPage implements OnInit {
  public page = 'UploadSingleToMix';
  public file: any;
  public clearFile: any;
  columns: any;
  pageNumber = 0;
  public noOfRecords = 20;
  public pageSize = 20
  totalPages: number;
  historyData: any = [];
  public historyDataSource: any = {};
  count: number = 0;
  getHistoryData: any = []

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events) { }

  ngOnInit() {
    this.columns = [
      { name: "File Name", prop: "fileName" },
      { name: "Date & Time", prop: "createdDate" },
      { name: "By", prop: "username" },
      { name: "Status", prop: "status" }
    ];
    this.loadSingleToMixUploadHistory()
  }

  async loadSingleToMixUploadHistory() {
    this.historyDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getUploadHistory('Single Mixing', this.pageNumber, this.noOfRecords)
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.getHistoryData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.getHistoryData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  close() {
    this.modalCtrl.dismiss()
  }

  fileObject(event) {
    this.clearFile = event.target.value
    this.file = <File>event.target.files[0];
    console.log(this.file);
  }
  
  refresh() {
    this.loadSingleToMixUploadHistory();
  }

  async uploadFile() {
    if (this.file) {
      const formData: FormData = new FormData();
      formData.append("file", this.file);
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.uploadSSToMix(formData);
      console.log(res.json());
      await this.configService.dismiss();
      let data = res.json();
      if (data.status == 400) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.file = null;
        this.clearFile = null;
        this.loadSingleToMixUploadHistory()
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  async downloadFile(file) {
    console.log(file)
    let res: any;
    //res = await this.httpApiService.downloadUploadedFile(file.id);
    res = await this.httpApiService.downloadFileByType(file.id, 'Single Mixing');
  }

}
