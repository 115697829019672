import { Component, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-manage-pricing-size-master',
  templateUrl: './manage-pricing-size-master.page.html',
  styleUrls: ['./manage-pricing-size-master.page.scss'],
})
export class ManagePricingSizeMasterPage implements OnInit {

  public name: any;
  public flagAddUpdate: any;
  public item: any;
  public id: any;
  public fromCts: any = Number;
  public toCts: any = Number;
  public typeList = [];
  public type: any;
  public shapeList = [];
  public shapeIdList = [];
  public clarityIdList = [];
  public typeIdList = []
  public shapeId: any;
  public shortName: any;
  public pointers: any;
  public isActive: Boolean = true;

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) {


  }

  async ngOnInit() {
    let data = this.navParams.get('item');
    if (data != 0) {
      this.item = data;
      console.log(this.item)
      this.id = this.item.id
    }
    await this.findAllTypeForPricing();
    this.flagAddUpdate = this.navParams.get('flag');
    console.log(this.flagAddUpdate)
    if (this.flagAddUpdate == 'update') {
      this.name = this.item.name;
      this.fromCts = this.item.fromCts;
      this.toCts = this.item.toCts;
      await this.findParametersForMixSizeMaster();
      this.clarityIdList = [];
      this.shapeIdList = [];
      this.shapeId = this.item.shape;
      this.isActive = this.item.isActive;
      this.shortName = this.item.shortName;
      this.pointers = this.item.pointers;
      this.type = this.item.type;
      this.shapeIdList.push({ id: this.item.shape, name: this.item.shapeName })
      this.typeList.filter(el => {
        if (el.name = this.item.type) {
          this.typeIdList = []
          this.typeIdList.push({ id: el.id, name: el.name })
        }
      })
    } else {
      await this.findParametersForMixSizeMaster()
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "typeId") {
      if (idList.length > 0) {
        this.type = obj[0].name;
      } else {
        this.type = null;
      }
    } else if (property == 'shapeId') {
      if (idList.length > 0) {
        this.shapeId = idList[0];
      } else {
        this.shapeId = null;
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async findAllTypeForPricing() {
    let res: any;
    res = await this.httpApiService.findAllTypeForPricing();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let tempArray = []
      for (let i = 0; i < dataValue.length; i++) {
        tempArray.push({ id: i + 1, name: dataValue[i] })
      }
      this.typeList = tempArray;
    }
  }

  async findParametersForMixSizeMaster() {
    let res: any;
    res = await this.httpApiService.findParametersForMixSizeMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.shapeList) {
        let data1 = []
        dataValue.shapeList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.shapeList = data1;
      }
    }
  }

  async addSizeName() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        name: this.name,
        fromCts: this.fromCts,
        toCts: this.toCts,
        type: this.type,
        shape: this.shapeId,
        isActive: this.isActive,
        pointers: this.pointers,
        shortName: this.shortName
      }
      console.log(jsonObj)
      res = await this.httpApiService.addPricingSizeMaster(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async updateSizeName() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        name: this.name,
        fromCts: this.fromCts,
        toCts: this.toCts,
        type: this.type,
        shape: this.shapeId,
        isActive: this.isActive,
        pointers: this.pointers,
        shortName: this.shortName
      }
      console.log(jsonObj)
      res = await this.httpApiService.updatePricingSizeMaster(jsonObj, this.id);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async validateControls() {
    console.log(this.fromCts, this.toCts, this.shapeId, this.type, this.name)
    if (this.fromCts != null && this.fromCts != undefined && this.fromCts >= 0 &&
      this.toCts != null && this.toCts != null && this.toCts != '' &&
      this.name != null && this.name != null && this.name != '' &&
      this.shapeId != null && this.shapeId != null && this.shapeId != '' &&
      this.type != null && this.type != null && this.type != ''
    ) {
      return true;
    } else {
      await this.configService.showToast('error', 'Please insert the details')
      return false;
    }
  }

}
