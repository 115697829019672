export default {
    reportNo: 'R-5',
    url: "master-service/api/report/AssortmentStock",
    method: "GET",
    size: 3,
    fields: [
        {
            title: ' Trans Type ',
            input: 'select',
            key: 'transType',
            placeholder: 'Select Trans Type',
            property: 'transType',
            keyName: 'name',
            name: 'name',
            required: true,
            singleSelection: true,
            function: 'getAllTransType',
            default: [],
            size: 3
        },
        {
            title: ' Price Type ',
            input: 'select',
            key: 'priceType',
            placeholder: 'Select Price Type',
            property: 'priceType',
            keyName: 'id',
            name: 'name',
            required: true,
            singleSelection: true,
            itemList: [{
                id: 'current',
                name: 'Current'
            }, {
                id: 'Assort',
                name: 'assort'
            }, {
                id: 'History',
                name: 'history'
            }],
            default: [],
            size: 3
        },
        {
            title: ' Stock Type ',
            input: 'select',
            key: 'stockType',
            placeholder: 'Select Stock Type',
            property: 'stockType',
            keyName: 'id',
            required: true,
            name: 'name',
            singleSelection: true,
            itemList: [{
                id: 'current',
                name: 'Current'
            }, {
                id: 'assorted',
                name: 'Assorted'
            }],
            default: [],
            size: 3
        },
        {
            title: ' Assortment Type ',
            input: 'select',
            key: 'transType',
            placeholder: 'Select Assortment Type',
            property: 'transType',
            keyName: 'id',
            name: 'name',
            singleSelection: true,
            function: 'getAllAssortment',
            default: [],
            size: 3
        },
        {
            title: ' Inward Mode ',
            input: 'select',
            key: 'transMode',
            placeholder: 'Select Inward Mode',
            property: 'transMode',
            keyName: 'id',
            required: true,
            name: 'name',
            singleSelection: true,
            itemList: [{
                id: 'purchase',
                name: 'Purchase'
            }, {
                id: 'manufacturing',
                name: 'Manufacturing'
            }, {
                id: 'recut',
                name: 'Recut'
            }],
            default: [],
            size: 3
        },
        {
            title: ' Shape ',
            input: 'select',
            key: 'shape',
            placeholder: 'Select Shape',
            property: 'shape',
            keyName: 'id',
            name: 'name',
            singleSelection: true,
            function: 'getAllShape',
            default: [],
            size: 3
        },
        {
            title: ' Color ',
            input: 'select',
            key: 'color',
            placeholder: 'Select Color',
            property: 'color',
            keyName: 'id',
            name: 'name',
            singleSelection: true,
            function: 'getAllColour',
            default: [],
            size: 3
        },
        {
            title: ' Clarity ',
            input: 'select',
            key: 'clarity',
            placeholder: 'Select Clarity',
            property: 'clarity',
            keyName: 'id',
            name: 'name',
            singleSelection: true,
            function: 'getAllClarity',
            default: [],
            size: 3
        },
        {
            title: ' From Inward Date ',
            input: 'dateTime',
            key: 'fromInwardDate',
            placeholder: 'Select From Inward Date',
            property: 'fromInwardDate',
            required: true,
            default: [],
            setting: {
                bigBanner: true,
                timePicker: true,
                format: 'yyyy-MM-dd',
                defaultOpen: false,
                minDate: new Date()
            },
            size: 3
        },
        {
            title: ' To Inward Date ',
            input: 'dateTime',
            key: 'toInwardDate',
            placeholder: 'Select To Inward Date',
            property: 'toInwardDate',
            required: true,
            default: [],
            setting: {
                bigBanner: true,
                timePicker: true,
                format: 'yyyy-MM-dd',
                defaultOpen: false,
                minDate: new Date()
            },
            size: 3
        },
        {
            title: ' From Ass Date ',
            input: 'dateTime',
            key: 'fromAssDate',
            placeholder: 'Select From Ass Date',
            property: 'fromAssDate',
            required: true,
            default: [],
            setting: {
                bigBanner: true,
                timePicker: true,
                format: 'yyyy-MM-dd',
                defaultOpen: false,
                minDate: new Date()
            },
            size: 3
        },
        {
            title: ' To Ass Date ',
            input: 'dateTime',
            key: 'toAssDate',
            placeholder: 'Select To Ass Date',
            property: 'toAssDate',
            required: true,
            default: [],
            setting: {
                bigBanner: true,
                timePicker: true,
                format: 'yyyy-MM-dd',
                defaultOpen: false,
                minDate: new Date()
            },
            size: 3
        },
        {
            title: ' History Price Date ',
            input: 'dateTime',
            key: 'historyPriceDate',
            placeholder: 'Select History Price Date',
            property: 'historyPriceDate',
            default: [],
            setting: {
                bigBanner: true,
                timePicker: true,
                format: 'yyyy-MM-dd',
                defaultOpen: false,
                minDate: new Date()
            },
            size: 3
        }
    ],
    columns: [
        { name: "Shape", prop: "shape" },
        { name: "Color", prop: "color" },
        { name: "Single Purity ", prop: "clarity" },
        { name: "Carats ", prop: "stoneCts" },
        { name: "Rate", prop: "price" },
        { name: "Amount", dynamic: ["price", "*", "stoneCts"] },
        { name: "Year Month", prop: "yearMonth" },
    ]
};
