import { Component, Input, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-manage-inter-cssp-transfer',
  templateUrl: './manage-inter-cssp-transfer.page.html',
  styleUrls: ['./manage-inter-cssp-transfer.page.scss'],
})
export class ManageInterCsspTransferPage implements OnInit {
  public baseUrl: any;
  public type = "Export Template";
  public data = [];
  public typePositionReport = "Position Report"
  public typePositionReportMappingFile = "Mapping File"
  public getHistoryData = [];
  public pageNumber = 0;
  public noOfRecords = 10;
  public count: Number = 0;
  public columns = []
  public clearFile: any;
  public selectedFile: any;
  public page = 'manageInterCsspTransfer'
  @Input() searchTitle:any;
  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) { }

  async ngOnInit() {
    this.columns = [
      { name: "File Name", prop: "fileName" },
      { name: "Status", prop: "status" },
      { name: "By", prop: "username" },
      { name: "Date & Time", prop: "createdDate" }
    ];

    if(this.searchTitle !== 'positionReportMappingFile') {
      this.getAllCsspTransferFileUploadHistory(this.pageNumber, this.noOfRecords)
    }

    await this.getBaseUrl();
    this.pageNumber = 0;
    this.noOfRecords = 10;
    if(this.searchTitle === 'positionReport'){
      await this.getAllCsvDownloadHistoryPositionReport(this.pageNumber, this.noOfRecords);
    } else if(this.searchTitle === 'positionReportMappingFile'){
      await this.getAllCsvDownloadHistoryPositionReportMappingFile(this.pageNumber, this.noOfRecords);
    }else{
      await this.getAllCsvDownloadHistory(this.pageNumber, this.noOfRecords);
    }


  }

  async getBaseUrl() {
    this.baseUrl = await this.configService.getBaseUrl();
  }
  async getAllCsvDownloadHistoryPositionReport(pageNumber, noOfRecords) {
    let res: any;
    res = await this.httpApiService.getAllCsvDownloadHistoryByTypePositionReport(this.typePositionReport, pageNumber, noOfRecords);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.data = data.data.content;
      this.count = data.data.totalElements;
    }
  }
  async getAllCsvDownloadHistoryPositionReportMappingFile(pageNumber, noOfRecords) {
    let res: any;
    res = await this.httpApiService.getAllCsvDownloadHistoryByTypePositionReportMappingFile(this.typePositionReportMappingFile, pageNumber, noOfRecords);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.data = data.data.content;
      this.count = data.data.totalElements;
    }
  }
  async getAllCsvDownloadHistory(pageNumber, noOfRecords) {
    let res: any;
    res = await this.httpApiService.getAllCsvDownloadHistoryByType(this.type, pageNumber, noOfRecords);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.data = data.data.content;
      this.count = data.data.totalElements;
      console.log("csv download history ", this.data);
    }
  }







  fileObject(event) {
    this.clearFile = event.target.value
    this.selectedFile = <File>event.target.files[0];
    console.log(this.selectedFile);
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async uploadTransferMapping() {
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.uploadInterCsspTransfer(formData);
      console.log(res.json());
      await this.configService.dismiss();
      let uploadData = res.json();
      if (uploadData.status == 400) {
        await this.configService.showToast("error", uploadData.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", uploadData.error);
      } else {
        await this.configService.showToast("success", uploadData.message);
        this.selectedFile = null;
        this.clearFile = null;
        await this.getAllCsspTransferFileUploadHistory(this.pageNumber, this.noOfRecords);
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  //file upload

  async getAllCsspTransferFileUploadHistory(pageNumber, noOfRecords) {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getUploadHistory('Inter Cssp Transfer', pageNumber, noOfRecords)
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      await this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (res.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.getHistoryData = data.data.content;
      this.count = data.data.totalElements;
    }
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;;
    await this.getAllCsspTransferFileUploadHistory(currentPage.offset, this.noOfRecords)

  }

  async downloadUploadedFile(file) {
    let res: any;
    res = await this.httpApiService.downloadActualLabExpenseFile(file.id, 'Inter Cssp Transfer');
  }

}
