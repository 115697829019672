import { Component, OnInit } from '@angular/core';
import {
  ModalController,
  NavParams,
  LoadingController,
  Events,
  ToastController
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { IonicSelectableComponent } from "ionic-selectable";
import { SearchService } from "src/app/services/search/search.service";

@Component({
  selector: 'app-stonegenerationbuyingpurpose',
  templateUrl: './stonegenerationbuyingpurpose.component.html',
  styleUrls: ['./stonegenerationbuyingpurpose.component.scss']
})
export class StonegenerationbuyingpurposeComponent implements OnInit {
  buyingPurpose:any =[];
  selectedValue=[]

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public toastCtrl: ToastController,
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public event: Events,
    public loadingController: LoadingController
  ) { }

  ngOnInit() {
    this.buyingPurposelist()
  }
  closeModal() {
    this.modalCtrl.dismiss()
  
  }


  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
  if (property == 'prefix') {
    this.selectedValue = obj[0];
    
    console.log(this.selectedValue);
    this.modalCtrl.dismiss;
  } else{
    this.selectedValue= []; 
  }
     
  
  }
  async buyingPurposelist(){
    let res: any;
    
    res = await this.httpApiService.fetchStonebyprefix();
    let data = res.json();
    console.log(data)
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        this.buyingPurpose = data.data;
        console.log(this.buyingPurpose);
      }
    }

}

}


