import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  AlertController,
  Events,
  LoadingController,
  MenuController,
  ModalController,
} from "@ionic/angular";
import { SearchService } from "../../../services/search/search.service";
import { environment } from "../../../../environments/environment";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";
import { CsvDownloadHistoryPage } from "../csv-download-history/csv-download-history.page";
import { ManageSearchPage } from "../manage-search/manage-search.page";
import defaultValue from "./default";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { IonicSelectableComponent } from "ionic-selectable";
import * as moment from "moment";
import { SvcActionsPage } from "../../svc-actions/svc-actions.page";
import { EmailToCustomerPage } from "../email-to-customer/email-to-customer.page";
import { PdFiltersComponent } from "../pd-filters/pd-filters.component";
import { ProgramManagementPage } from "../program-management/program-management.page";
import { ViewScanResultPage } from "../view-scan-result/view-scan-result.page";
import paramViseColumns from "./defaultmasterparams";
import { ManageInterCsspTransferPage } from "../manage-inter-cssp-transfer/manage-inter-cssp-transfer.page";
import { Storage } from "@ionic/storage";
import { BlockForSaleRequestFormPage } from "../block-for-sale-request-form/block-for-sale-request-form.page";

@Component({
  selector: "app-elastic-search",
  templateUrl: "./elastic-search.component.html",
  styleUrls: ["./elastic-search.component.scss"],
})
export class ElasticSearchComponent implements ProcessActionComponent {
  innerWidth: number;
  isItemSelected: boolean;
  selectedInspectionStones: any[] = [];
  filterForSmallScreen(arg0: any) {
    throw new Error("Method not implemented.");
  }
  @ViewChild("selectableComponent")
  selectableComponent: IonicSelectableComponent;
  @Input() data: any;

  @Input() query: any;
  @Input() cart: Boolean;
  @Input() disable: any;
  @Input() type: any;
  @Input() withMe: Boolean = false;
  @Input() blackListedStones = false;
  @Input() showScannerOption: boolean = true;
  @Input() svcDataOption: boolean = false;
  @Input() svcRequestOption: boolean = false;
  @Input() showSoldStone: Boolean = false;
  @Input() rapnetSearch = false;
  @Input() index: string = environment.INDEX;
  @Input() isInwardSearch: Boolean = false;
  @Input() positionReport: boolean = false;
  @Input() hideDefaultTemplates: boolean = false;
  @Output() eventFromPR = new EventEmitter();
  @Input() default: any = {
    criteria: true,
    search: true,
    shape: true,
    carat: true,
    color: true,
    colorOverride: true,
    clarity: true,
    clarityOverride: true,
    description: true,
    finishing: true,
    fluorescence: true,
    lab: true,
    size: false,
    internalStatus: true,
    externalStatus: true,
    transType: true,
    users: true,
    party: true,
    Lotcountry: true,
    location: true,
    box: true,
    department: true,
    saveSearch: true,
    download: true,
    fm: true,
    availableForSale: true,
    certiDate: true,
    inwardDate: true,
    transactionDate: true,
    soldDate: true,
    standardShapeCode: true,
  };
  public showFilters = true;
  public boardBaseUrl: any;
  public displayStoneName: any;
  public ids = [];
  public userDetail: any;
  public perPage = 10;
  public isConnected = false;
  public status: string;
  public totalHits: number;
  public searchTime: number;
  public inspectionView = false;
  public searchPageAllButtons: any = [];
  public currentPage: number;
  public searchResponse = "";
  public totalPages: any;
  public companyId: any;
  public fixedParameter = [];
  public esData: any[];
  public selectedSavedSearch: any;
  public tagName: any;
  public searchHistory: any = [];
  public selectedShapesArray: any = [];
  public selectedOtherShapesArray: any = [];
  public selectedColorsArray: any = [];
  public selectedAdvancedClaritiesArray: any = [];
  public selectedClaritiesArray: any = [];
  public selectedFluorescenceArray: any = [];
  public selectedCutArray: any = [];
  public selectedPolishArray: any = [];
  public selectedSymmetryArray: any = [];
  public selectedFinishArray: any = [];
  public refreshGrid = false;
  public actionDefaultValues: any;
  public searchedStoneArray = [];
  public blackListed = false;
  public PricelistKey: any;
  public rapnetListed = false;
  public searchPara: any = {
    shapes: [],
    colors: [],
    clarities: [],
    cuts: [],
    polishes: [],
    symmetries: [],
    descriptions: [],
    finishings: [],
    fluorescences: [],
    labs: [],
    fancyColorIntensities: [],
    selectedFancyColorIntensity: "",
    fancyColorOvertones: [],
    selectedFancyColorOvertone: "",
    fancyColors: [],
    selectedFancyColor: "",
    // lusters:[],
    // selectedLuster:"",
    // naturals:[],
    // selectedNatural:"",
    saveSearchName: "",
    fromCarat: null,
    toCarat: null,
    stoneId: "",
    certNo: "",
    stoneIdORCertSelected: "stoneId",
    colorType: "white",
    caratType: "specific",
    clarityType: "basic",
    finishingType: "general",
    shapeType: "standard",
    tablePerFrom: "",
    tablePerTo: "",
    depthPerFrom: "",
    depthPerTo: "",
    lengthFrom: "",
    lengthTo: "",
    widthFrom: "",
    widthTo: "",
    depthFrom: "",
    depthTo: "",
    ratioFrom: "",
    ratioTo: "",
    crownAngleFrom: "",
    crownAngleTo: "",
    crownHeightFrom: "",
    crownHeightTo: "",
    girdles: [],
    selectedGirdle: "",
    pavilionAngleFrom: "",
    pavilionAngleTo: "",
    pavilionDepthFrom: "",
    pavilionDepthTo: "",
    pdObject: {},

    InternalStatus: [],
    TransType: [],
    Users: [],
    Parties: [],
    Location: [],
    standardShapeCode: [],
    Box: [],
    Department: [],
    fm: [],
    fromCertiDate: "",
    toCertiDate: "",
    fromInwardDate: "",
    toInwardDate: "",
    fromTransactionDate: "",
    toTransactionDate: "",
    fromSoldDate: "",
    toSoldDate: "",
  };

  public allowStatusList = null;
  public listOfStatus = [];
  public mobileView: boolean = false;
  public hitLength: number = 0;
  public ledOnList = [];
  public rledOnList = [];
  public requestJson = {};
  public selectedStone = [];
  public exportAs: any = [];
  public showIconColumn: boolean = true;
  public isDefault = false;
  public mobileviews = true;
  public inspectiondata: any;
  public queryalldocs = {
    query: {
      match_all: {},
    },
    sort: [{ _score: { order: "desc" } }],
  };
  public keys = [];
  public actionId: any;
  public userId: any;
  public allPriceNameList = [];
  public priceListMasterId: any;
  public priceListName: any;
  public discountPercent: any;
  public priceList: any;
  public limit: any;
  public displayPriceList = [];
  public resultColumns: any = [];
  public actionName: any;
  public deptName: any;
  public hits: any;
  public showHits: Boolean = false;
  public defaultTempalateList = [];
  public defaultExportList = [];
  public scanCount: number = 0;
  public sysStoneList = [];
  public stoneNotFoundRfidList = [];
  public selectedExportedFormat: any;
  public defaultTemplateId: any;
  public selectedSearch: any;
  public totalStones: any;
  public firstLoad: boolean = false;
  public criterias = [];
  public take = 0;
  public boxDetail = [];
  public filteredPD: any;
  public searchPageButtons: any = [];
  public inspectionviewdata: any;
  // public userExportList = []
  public exportList = [];
  public stoneFound = 0;
  public addSoldMustNotQuery: boolean = false;
  public templateId: any;
  public paramList: any;
  public selectedParty: any;
  public isAvailableForSale: boolean = false;
  public gridResultsSorted: boolean = false;
  public listOfParties = [];
  public RepnetPlusval: any;
  public customerSaleMarkUp = null;
  public customerSelection = "";
  public disableTemplate: any = false;
  public searchResultColumns: any = [
    {
      fieldName: "stoneName",
      name: "Stone ID",
      type: "string",
      orderBy: 1,
      allowDeleting: false,
      showStoneTemplate: true,
      mobileViewOrder: 1,
    },
    {
      fieldName: "Rapnet_pluspercarat",
      name: "$/CT",
      type: "integer",
      orderBy: 14,
      allowDeleting: false,
      mobileViewOrder: 2,
    },
    {
      fieldName: "Rapnet_plusDiscountPercent",
      name: "Disc",
      type: "number",
      orderBy: 11,
      allowDeleting: false,
      mobileViewOrder: 3,
    },
    {
      fieldName: "Rapnet_plus",
      name: "$ Total",
      type: "number",
      orderBy: 12,
      allowDeleting: false,
      mobileViewOrder: 4,
    },
  ];
  public contentClass = "";
  smallScreenFilter: any;
  filter: any;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 500) {
      this.contentClass = "";
      this.mobileView = true;
    } else if (this.innerWidth > 500) {
      this.contentClass = "leftRightMargin";
      this.mobileView = false;
    }
  }

  constructor(
    private es: SearchService,
    public exportAsExcelService: ExportAsExcelService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    private cd: ChangeDetectorRef,
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public event: Events,
    private storage: Storage,
    public configService: ConfigService,
    public alertCtrl: AlertController,

    public menu: MenuController
  ) { }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.menu.close();
    }
    // if ((event.ctrlKey || event.metaKey) && (event.key == 'V' || event.key == 'v')) {
    //   let x = document.activeElement.id;
    //   if (this.searchPara.stoneIdORCertSelected == 'stoneId' && x == 'stoneIdCopy') {
    //     this.searchQuery()
    //   }
    // }
    // if (event.key == 'Enter') {
    //   this.searchQuery();
    // }
  }

  onInputChange(event: any) {
    this.searchPara.stoneId = event.toUpperCase(); // Convert input to uppercase
  }

  onInputCertChange(event: any) {
    this.searchPara.certNo = event.toUpperCase(); // Convert input to uppercase
  }

  async ngOnInit() {
    await this.resetSearchpara();
    console.log(this.searchPara);
    this.gridResultsSorted = false;

    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 500) {
      this.contentClass = "";
      this.mobileView = true;
    } else if (this.innerWidth > 500) {
      this.contentClass = "leftRightMargin";
      this.mobileView = false;
    }

    this.companyId = await this.authService.getCompanyId();
    await this.getUserData();
    await this.getAllStatus();
    await this.getAllPriceListName();
    await this.userData();
    if (!!this.withMe) {
      let name = [];
      name.push(this.userDetail.fullName);
      this.query.disjunctiveFacetsRefinements.username = name;
    }
    if (this.data.desc == "SVC Request List Search") {
      let name = [];
      name.push(this.userDetail.fullName);
      this.query.disjunctiveFacetsRefinements.requestUser = name;
    }
    this.es
      .isAvailable()
      .then(
        () => {
          this.status = "OK";
          this.isConnected = true;
        },
        (error) => {
          this.status = "ERROR";
          this.isConnected = false;
          console.error("Server is down", error);
        }
      )
      .then(() => {
        this.cd.detectChanges();
      });
    await this.intializeSearchPara();

    console.log(this.data);
    this.actionName = this.data.desc;

    this.actionId = this.data.actionId;

    await this.getAllTemplates();
    await this.getAllSearches();

    this.event.subscribe(this.actionName, (item) => {
      this.resetSearch();
      this.getAllTemplates();
      this.getAllSearches();
    });
  }

  async getDefaultValues() {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    console.log(this.actionId);
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.actionDefaultValues = dataValue;
      console.log(dataValue);
      if (
        !!this.actionDefaultValues.map &&
        this.actionDefaultValues.map.templateDisable
      ) {
        this.disableTemplate = dataValue.map.templateDisable;
      }
      if (
        !!this.actionDefaultValues.map &&
        !!this.actionDefaultValues.map.defaultTemplate
      ) {
        let templateId = this.actionDefaultValues.map.defaultTemplate;
        this.exportList.forEach((item) => {
          if (item.id == templateId) {
            this.defaultExportList.push(item);
            this.defaultTemplateId = item.id;
            this.templateId = item.id;
          }
        });
      } else {
        if (!this.hideDefaultTemplates) {
          let obj = !!this.exportList[0] ? this.exportList[0] : {};
          this.defaultExportList.push(obj);
          if (!!obj) {
            this.defaultTemplateId = obj.id;
            this.templateId = obj.id;
          }
        }
      }
      console.log(this.defaultTemplateId);
      await this.generateColumns();
    }
  }

  async getTemplateDataById() {
    if (!!this.templateId) {
      // await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.findTemplateById(this.templateId);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        // await this.configService.dismiss();
        let dataValue = data.data;
        let data1 = [];

        let paramList = dataValue.listOfParameters;
        return paramList;
      }
    }
  }

  /**
   * Elasticsearch misbehaves if users enter symbolic characters. User this method to strip out any such characters.
   * @param query - user search query.
   */
  public sanitized(query) {
    console.log("query ", query);
    // if (!!query)
    //   return query.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    // else
    return query;
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
  }

  searchCriteria() {
    if (this.criterias.length > 0) {
      let obj = {
        query: {
          bool: {
            should: this.criterias,
          },
        },
      };
      this.search(obj, this.index, 1);
      this.changeView();
    } else {
      this.configService.showToast("error", "Please add criteria first!");
    }
  }

  clearCriteria(i) {
    this.criterias.splice(i, 1);
  }

  addCriteria() {
    let qry = this.searchQuery(true);
    let { mapOfSearchCriteria } = this.defaultLoad();
    if (Object.keys(mapOfSearchCriteria).length == 0) {
      this.configService.showToast("error", "Please Select One Criteria");
    } else if (
      this.query &&
      Object.keys(mapOfSearchCriteria).length <=
      Object.keys(this.query.disjunctiveFacetsRefinements).length
    ) {
      this.configService.showToast("error", "Please Select One Criteria");
    } else {
      this.criterias.push(qry.query);
      this.firstLoad = false;
      this.intializeSearchPara();
      this.clearSelectionArrays();
    }
  }

  algolia(loadOption: any) {
    let wildcard = {},
      must_not = {},
      search = "";
    let indivisualFilter = [],
      searchFilter = "",
      loadOptions: any = loadOption.filter;
    if (!!loadOption.filter) {
      let options: any = loadOptions;
      if (
        loadOptions[1] == "and" &&
        Array.isArray(options[0]) &&
        Array.isArray(options[2]) &&
        (options[0][1] == "or" || options[2][1] == "or")
      ) {
        let first: any = options[0]
          .map((keys) => Array.isArray(keys) && keys[2])
          .filter((res) => !!res)
          .filter((v, i, a) => a.indexOf(v) === i);
        let secound: any = options[2]
          .map((keys) => Array.isArray(keys) && keys[2])
          .filter((res) => !!res)
          .filter((v, i, a) => a.indexOf(v) === i);
        if (first.length == 1) {
          searchFilter = first[0];
          indivisualFilter = options[2];
        } else if (secound.length == 1) {
          searchFilter = secound[0];
          indivisualFilter = options[0];
        } else {
          console.log("======something Wrong ====");
        }
      } else if (!Array.isArray(loadOption.filter[0])) {
        indivisualFilter = [loadOption.filter];
      } else {
        let first: any = options
          .map((keys) => Array.isArray(keys) && keys[2])
          .filter((res) => !!res)
          .filter((v, i, a) => a.indexOf(v) === i);
        if (first.length == 1) {
          searchFilter = first[0];
        } else {
          indivisualFilter = options;
        }
      }
      if (indivisualFilter.length > 0) {
        indivisualFilter.filter((loadOption) => {
          if (loadOption.length > 0) {
            switch (loadOption[1]) {
              case "contains":
                wildcard[loadOption[0]] = "*" + loadOption[2] + "*";
                break;
              case "notcontains":
                must_not[loadOption[0]] = "*" + loadOption[2] + "*";
                break;
              case "startswith":
                wildcard[loadOption[0]] = loadOption[2] + "*";
                break;
              case "endswith":
                wildcard[loadOption[0]] = "*" + loadOption[2];
                break;
              case "=":
                wildcard[loadOption[0]] = loadOption[2];
                break;
              case "<>":
                must_not[loadOption[0]] = loadOption[2];
                break;
              default:
                break;
            }
          }
        });
      }
      if (searchFilter.length > 0) {
        search = "*" + searchFilter + "*";
      }
    }
    return { wildcard, must_not, search };
  }
  /**
   * Search function.
   * @param query - user input.
   * @param index - ES index to search.
   * @param page  - page.
   */
  async search(query, index, page) {
    let sanitized = await this.sanitized(query);
    console.log("sanitized ", sanitized);
    if (!!sanitized && Object.keys(sanitized).length > 0) {
      this.hits = new CustomStore({
        load: async (loadOptions: any) => {
          let subqry = JSON.parse(JSON.stringify(query));
          let body: any = {};
          if (!!loadOptions.filter) {
            let { wildcard, must_not, search } = this.algolia(loadOptions);
            if (!subqry.query) {
              subqry.query = {};
            }
            if (!subqry.query.bool) {
              subqry.query.bool = {};
            }
            if (!subqry.query.bool.must) {
              subqry.query.bool.must = [];
            }
            if (!subqry.query.bool.must_not) {
              subqry.query.bool.must_not = [];
            }
            Object.keys(wildcard).filter((regkey) => {
              let wildcards = {};
              wildcards[regkey] = wildcard[regkey];
              subqry.query.bool.must.push({ wildcard: wildcards });
            });

            Object.keys(must_not).filter((regkey) => {
              let mustNot = {};
              mustNot[regkey] = must_not[regkey];
              subqry.query.bool.must_not.push({ wildcard: mustNot });
            });
            if (search) {
              subqry.query.bool.must.push({
                query_string: {
                  query: search,
                },
              });
            }
          }
          if (!!loadOptions.sort) {
            subqry.sort = [];
            loadOptions.sort.filter((sorting) => {
              let sort = {};
              sort[sorting.selector] = {
                order: sorting.desc ? "desc" : "asc",
              };
              subqry.sort.push(sort);
            });
          }
          if ([10, 20, 50, 100, 200, 400].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.take;
          } else {
            this.take = loadOptions.take;
          }

          sanitized = await this.sanitized(subqry);
          if (index !== "all") {
            body = await this.es.getPaginatedDocuments(
              sanitized,
              loadOptions.skip,
              index,
              "",
              loadOptions.take
            );
          } else {
            body = await this.es.getPaginatedDocuments(
              sanitized,
              loadOptions.skip,
              "",
              "",
              loadOptions.take
            );
          }
          if (body.hits) {
            this.hitLength = body.hits.total.value;
          }
          let setOfStoneId = [];
          // body.hits.hits.map(d => d._source).map(res => {
          //   this.resultColumns.filter(el => {
          //     if (el.prop.includes('percarat')) {
          //       if (!res[el.prop]) {
          //         let exi = setOfStoneId.find(el => el == res.id)
          //         if (!exi) {
          //           {
          //             setOfStoneId.push(res.id)
          //           }
          //         }
          //       }
          //     }
          //   })
          // })
          setOfStoneId = body.hits.hits
            .map((d) => d._source)
            .filter((el) => !el.isPurchaseConsolidation)
            .map((res) => {
              return res.id;
            });
          let idsForUpdate = [];
          if (!!setOfStoneId && setOfStoneId.length > 0) {
            try {
              let pricingList = await this.getPriceFromDB(setOfStoneId);
              body.hits.hits
                .map((d) => d._source)
                .filter((res) => {
                  this.resultColumns.filter((el) => {
                    for (let [key1, value1] of Object.entries(pricingList)) {
                      if (key1 == res.id) {
                        let stoneValues: any = value1;
                        for (let [key2, val2] of Object.entries(stoneValues)) {
                          let keyS: any = key2;
                          if (
                            (keyS == "internalStatus" ||
                              keyS == "externalStatus") &&
                            el.prop == keyS
                          ) {
                            if (res[el.prop] != val2) {
                              idsForUpdate.push(res.id);
                              res[el.prop] = val2;
                            }
                          } else if (
                            res[el.prop] != null &&
                            res[el.prop] != undefined &&
                            keyS.replaceAll(" ", "") == el.prop
                          ) {
                            if (
                              Math.trunc(Number(res[el.prop])) !=
                              Math.trunc(Number(val2))
                            ) {
                              // idsForUpdate.push(res.id);
                              this.pushIfNotExists(idsForUpdate, res.id);
                              res[el.prop] = val2;
                            }
                          }
                        }
                      }
                    }
                  });
                  return res;
                });
            } catch (err) {
              console.log(err);
            }
          }
          let currentDate = moment().format("YYYY-MM-DD");
          if (!!idsForUpdate && idsForUpdate.length > 0) {
            this.updateIdsThroughWorker(idsForUpdate);
          }
          if (!!loadOptions.group && loadOptions.group.length > 0) {
            if (loadOptions.group[0].selector) {
              this.resultColumns = this.resultColumns.map((res) => {
                if (res.prop == loadOptions.group[0].selector) {
                  res.groupIndex = 0;
                }
                return res;
              });
              return {
                data: Query(
                  body.hits.hits
                    .map((d) => d._source)
                    .map((res) => {
                      res["stoneName"] = String(res["stoneName"]);

                      if (!!res["transDate"]) {
                        res["transDate"] = moment
                          .utc(res["transDate"])
                          .local()
                          .format("DD-MMM-YYYY hh:mm a");
                      }
                      let stoneId = res.stoneName;
                      if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
                        let index = stoneId.split("/", 3).join("/").length;
                        res["stoneName"] = String(
                          stoneId
                            .split(stoneId.substring(index + 1, stoneId.length))
                            .join(
                              stoneId
                                .substring(index + 1, stoneId.length)
                                .slice(
                                  0,
                                  stoneId
                                    .substring(index + 1, stoneId.length)
                                    .indexOf("_")
                                )
                            )
                        );
                      } else {
                        res["stoneName"] = String(res["stoneName"]);
                      }
                      if (!!this.customerSaleMarkUp) {
                        if (
                          !!res["PurchasePartyDisc"] &&
                          res["PurchasePartyPrice"]
                        ) {
                          // res['PurchasePartyDisc'] = res['PurchasePartyDisc'] + this.customerSaleMarkUp;
                          // res['PurchasePartyPrice'] = parseFloat(res['RAPAPORTpercarat']) * res['PurchasePartyDisc'] / 100;
                          // res['PurchasePartyAmt'] = res['PurchasePartyPrice'] * res['cts']

                          res["PurchasePartyDisc"] = Number(
                            parseFloat(res["PurchasePartyDisc"]) +
                            parseFloat(this.customerSaleMarkUp)
                          ).toFixed(2);
                          res["PurchasePartyPrice"] = Number(
                            parseFloat(res["RAPAPORTpercarat"]) -
                            (parseFloat(res["RAPAPORTpercarat"]) *
                              parseFloat(res["PurchasePartyDisc"])) /
                            100
                          ).toFixed(2);
                          res["PurchasePartyAmt"] = Number(
                            parseFloat(res["PurchasePartyPrice"]) *
                            parseFloat(res["cts"])
                          ).toFixed(2);
                        }
                        // let shapeCode =res['eliteShape'] || res['standardShapeCode'] || res['ShapeCode'];
                        // res['ShapeCode'] = shapeCode;
                      }
                      //calculate certiagingdays
                      if (res["CertDate"]) {
                        res["CertiAgingDays"] = this.getDaysDiff(
                          moment
                            .utc(res["CertDate"])
                            .local()
                            .format("YYYY-MM-DD"),
                          currentDate
                        );
                      }
                      if (res["RapnetModifiedDate"]) {
                        res["rapnetAging"] = this.getDaysDiff(
                          moment
                            .utc(res["RapnetModifiedDate"])
                            .local()
                            .format("YYYY-MM-DD"),
                          currentDate
                        );
                      }
                      if (!res["RapnetModifiedDate"]) {
                        res["rapnetAging"] = 0;
                      }
                      if (Number(res["Rapnet_plus"]) <= 0) {
                        res["rapnetAging"] = "";
                      }
                      if (res["inwardDate"]) {
                        res["AgingDays"] = this.getDaysDiff(
                          moment
                            .utc(res["inwardDate"])
                            .local()
                            .format("YYYY-MM-DD"),
                          currentDate
                        );
                      }
                      // res['inwardDate'] =
                      return res;
                    })
                )
                  .groupBy(loadOptions.group[0].selector)
                  .toArray(),
                totalCount: body.hits.total.value,
                groupCount: body.hits.total.value,
              };
            }
          } else {
            this.exportUnsearchedStones(body.hits.hits);

            return {
              data: await body.hits.hits
                .map((d) => d._source)
                .map((res) => {
                  if (!!res["transDate"]) {
                    res["transDate"] = moment
                      .utc(res["transDate"])
                      .local()
                      .format("DD-MMM-YYYY hh:mm a");
                  }
                  let stoneId = res.stoneName;
                  if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
                    let index = stoneId.split("/", 3).join("/").length;
                    res["stoneName"] = String(
                      stoneId
                        .split(stoneId.substring(index + 1, stoneId.length))
                        .join(
                          stoneId
                            .substring(index + 1, stoneId.length)
                            .slice(
                              0,
                              stoneId
                                .substring(index + 1, stoneId.length)
                                .indexOf("_")
                            )
                        )
                    );
                  } else {
                    res["stoneName"] = String(res["stoneName"]);
                  }
                  res["Rapnet_plus"] = Number(
                    Number(res["Rapnet_plus"]).toFixed(2)
                  );
                  if (!!this.customerSaleMarkUp) {
                    if (
                      !!res["PurchasePartyDisc"] &&
                      res["PurchasePartyPrice"]
                    ) {
                      res["PurchasePartyDisc"] = Number(
                        parseFloat(res["PurchasePartyDisc"]) +
                        parseFloat(this.customerSaleMarkUp)
                      ).toFixed(2);
                      res["PurchasePartyPrice"] = Number(
                        parseFloat(res["RAPAPORTpercarat"]) -
                        (parseFloat(res["RAPAPORTpercarat"]) *
                          parseFloat(res["PurchasePartyDisc"])) /
                        100
                      ).toFixed(2);
                      res["PurchasePartyAmt"] = Number(
                        parseFloat(res["PurchasePartyPrice"]) *
                        parseFloat(res["cts"])
                      ).toFixed(2);
                    }
                  }
                  //calculate certiagingdays
                  if (res["CertDate"]) {
                    res["CertiAgingDays"] = this.getDaysDiff(
                      moment.utc(res["CertDate"]).local().format("YYYY-MM-DD"),
                      currentDate
                    );
                  }
                  if (res["RapnetModifiedDate"]) {
                    res["rapnetAging"] = this.getDaysDiff(
                      moment
                        .utc(res["RapnetModifiedDate"])
                        .local()
                        .format("YYYY-MM-DD"),
                      currentDate
                    );
                  }
                  if (!res["RapnetModifiedDate"]) {
                    res["rapnetAging"] = 0;
                  }
                  if (Number(res["Rapnet_plus"]) <= 0) {
                    res["rapnetAging"] = "";
                  }
                  if (res["inwardDate"]) {
                    res["AgingDays"] = this.getDaysDiff(
                      moment
                        .utc(res["inwardDate"])
                        .local()
                        .format("YYYY-MM-DD"),
                      currentDate
                    );
                  }

                  // let shapeCode =res['eliteShape'] || res['standardShapeCode'] || res['ShapeCode'];
                  // res['ShapeCode'] = shapeCode;

                  // res['Rapnet_plus'] = res['Rapnet_plus'].toFixed(2)

                  // this.RepnetPlusval = res.Rapnet_plus
                  return res;
                }),
              totalCount: body.hits.total.value,
            };
          }
        },
      });
    } else {
      this.searchResponse = "Nothing found";
    }
  }

  getDaysDiff(Predate: string, currentDate: string) {
    let date1: any = new Date(Predate);
    let date2: any = new Date(currentDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  async exportUnsearchedStones(stoneData) {
    for (let i = 0; i < stoneData.length; i++) {
      let stoneId = stoneData[i]["_source"].stoneName;
      this.searchedStoneArray = this.searchedStoneArray.filter(function (a) {
        return a !== stoneId;
      });
    }
    this.searchedStoneArray = this.searchedStoneArray.filter((x) => x != "");
    if (this.searchedStoneArray.length > 0) {
      this.getErrorAndStatusOfStoneIds(this.searchedStoneArray);
    }
  }

  async getErrorAndStatusOfStoneIds(unavailableStones) {
    let jsonObj = {
      setOfValues: unavailableStones,
    };
    let res: any;
    res = await this.httpApiService.getErrorAndStatusOfStoneIds(jsonObj);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (!!data.data && data.data.length > 0) {
        this.exportAsExcelService.exportAsExcelFile(data.data, "Error_Stones");
      }
    }
  }

  async intializeSearchPara() {

    this.searchPara = defaultValue;
    Object.keys(this.searchPara).filter((key) => {
      if (Array.isArray(this.searchPara[key])) {
        this.searchPara[key] = this.searchPara[key].map((res) => {
          res.isRefined = false;
          return res;
        });
      }
    });
    console.log("stoneIdstoneIdstoneId", this.searchPara.stoneId)
    this.getCount(this.searchQuery(true));
    // this.selectedSavedSearch="";
  }

  getSelected(selected, qry: any = {}) {
    let match = qry.query.bool.should
      .map((res) => Object.keys(res)[0])
      .indexOf("bool");
    let matches = qry.query.bool.should[match].bool.must.map(
      (res) => !!res.terms && Object.keys(res.terms)[0]
    );
    // if (this.data.desc == 'SVC Single Search') {
    //   qry.query.bool.should[match].bool.should.push({ "match": { "requestUser": this.userDetail.fullName } })
    // }
    let fixedParameter = [];
    Object.keys(selected).filter((signal) => {
      let matchMatch = matches.indexOf(signal);
      if (matchMatch != -1) {
        let obj = {
          terms: {},
        };
        let old =
          qry.query.bool.should[match].bool.must[matchMatch].terms[signal] ||
          [];
        let newObj = selected || [];
        obj.terms[signal] = old
          .concat(newObj)
          .filter((v, i, a) => a.indexOf(v) === i);
        qry.query.bool.should[match].bool.must[matchMatch] = obj;
      } else {
        let obj = {
          terms: {},
        };
        obj.terms[signal] = selected[signal];
        qry.query.bool.should[match].bool.must.push(obj);
      }
      fixedParameter.push({
        key: signal,
        values: selected[signal],
      });
    });
    console.log(JSON.stringify(qry));
    return qry;
  }

  async getCount(qry: any = {}, defaultSelected = false) {
    let isAvailableKeyExist = JSON.stringify(qry).includes(
      '"availableForSale":1'
    );
    if (!!isAvailableKeyExist) {
      this.isAvailableForSale = true;
    }
    let object = {};
    if (this.default.shape) {
      object["shapes"] = "ShapeCode";
    }
    if (this.default.color) {
      object["colors"] = "ColorCode";
    }
    if (this.default.clarity) {
      object["clarities"] = "ClarityCode";
    }
    if (this.default.finishing) {
      object["polishes"] = "PolishCode";
    }
    if (this.default.fluorescence) {
      object["fluorescences"] = "FluorescenceCode";
    }
    if (this.default.lab) {
      object["labs"] = "lab";
    }
    if (this.default.carat) {
      object["ClarityCode"] = "advancedClarities";
    }
    if (this.default.users) {
      object["Users"] = "username";
    }
    if (this.default.location) {
      object["Location"] = "location";
    }
    if (this.default.standardShapeCode) {
      object["standardShapeCode"] = "standardShapeCode";
    }
    if (this.default.size) {
      object["AllSize"] = "Size";
    }
    if (this.default.internalStatus) {
      object["InternalStatus"] = "internalStatus";
    }
    if (this.default.transType) {
      object["TransType"] = "transType";
    }
    if (this.default.box) {
      object["Box"] = "Box";
    }
    if (this.default.department) {
      object["Department"] = "department";
    }
    if (this.default.externalStatus) {
      object["ExternalStatus"] = "externalStatus";
    }
    if (this.default.fm) {
      object["fm"] = "fm";
    }
    if (this.default.party) {
      object["partyName"] = "partyName";
    }
    if (this.default.Lotcountry) {
      object["Lotcountry"] = "Lotcountry";
    }

    // if (this.firstLoad) {
    //   let { mapOfSearchCriteria } = this.defaultLoad();
    //   Object.keys(object).filter(res => Object.keys(mapOfSearchCriteria).indexOf(object[res]) != -1).filter(res => {
    //     delete object[res];
    //   });
    // }

    let obj = {};
    Object.keys(object).filter((key) => {
      let single = object[key];
      obj[single] = {
        terms: { field: single, size: 10000 },
      };
    });
    this.es
      .getPaginatedDocuments(
        {
          aggs: obj,
          ...qry,
        },
        0,
        this.index,
        undefined,
        0
      )
      .then((res) => {
        let tempObj = { ...this.searchPara };
        let selectedValue = [];
        let fmValues = [];
        if (defaultSelected) {
          if (!!qry.query.bool.should[0].simple_query_string) {
            let match = qry.query.bool.should
              .map((res) => Object.keys(res)[0])
              .indexOf("bool");
            selectedValue = qry.query.bool.should[match].bool.must.map(
              (res) => res.terms || res.range
            );
            fmValues = qry.query.bool.should[match].bool.must.map(
              (res) => res.bool
            );
          } else {
            this.criterias.push(...qry.query.bool.should);
          }
        }
        console.log(object);
        Object.keys(object) &&
          Object.keys(object).filter((key) => {
            if (!this.firstLoad) {
              switch (true) {
                case !tempObj[key]:
                case key == "colors" && this.default.colorOverride:
                case key == "clarities" && this.default.clarityOverride:
                  tempObj[key] = res.aggregations[object[key]].buckets.map(
                    (value) => {
                      return {
                        id: value.key,
                        label: value.key,
                        value: value.key,
                        isRefined: false,
                      };
                    }
                  );
                  break;
                default:
                  break;
              }
              if (key == "shapes") {
                let data = res.aggregations[object[key]].buckets.map(
                  (value) => {
                    let match = tempObj[key].filter(
                      (res) => res.value == value.key
                    );
                    if (match.length == 0) {
                      return {
                        id: value.key,
                        label: value.key,
                        value: value.key,
                        isRefined: false,
                      };
                    } else {
                      return match[0];
                    }
                  }
                );
                tempObj[key] = data;
              }
            }

            selectedValue = selectedValue.filter((x) => x);
            fmValues = fmValues.filter((x) => x);
            // console.log(selectedValue)
            // console.log(tempObj)
            tempObj[key] &&
              tempObj[key].map((obj) => {
                if (res.aggregations[object[key]].buckets.length > 0) {
                  obj.count = 0;
                  res.aggregations[object[key]].buckets.map((value) => {
                    if (obj.value == value.key) {
                      obj.count = value.doc_count;
                    }

                    if (defaultSelected) {
                      try {
                        selectedValue.filter((keys) => {
                          if (keys["cts"]) {
                            tempObj["fromCarat"] = keys["cts"]["gte"];
                            tempObj["toCarat"] = keys["cts"]["lte"];
                          }
                          if (Object.keys(keys)[0] == object[key]) {
                            let data: any = Object.values(keys)[0];
                            if (data.indexOf(obj.value) != -1) {
                              obj.isRefined = true;
                            } else {
                              obj.isRefined = false;
                            }
                          }
                        });
                      } catch (e) {
                        obj.isRefined = false;
                      }
                    }
                  });
                  // if (object[key] == 'standardShapeCode') {

                  // }
                  if (object[key] == "FluorescenceCode") {
                    if (obj.label == "FAINT") {
                      res.aggregations[object[key]].buckets.map((value) => {
                        if (
                          value.key == "VERY SLIGHT" ||
                          value.key == "SLIGHT" ||
                          value.key == "FAINT"
                        ) {
                          obj.count += value.doc_count;
                        }
                      });
                    }
                  }
                  if (object[key] == "lab") {
                    if (obj.label == "FM") {
                      res.aggregations[object[key]].buckets.map((value) => {
                        if (
                          value.key == "FM01" ||
                          value.key == "FM02" ||
                          value.key == "BLFM"
                        ) {
                          obj.count += value.doc_count;
                        }
                      });
                    }
                  }
                  return obj;
                } else {
                  if (object[key] == "fm") {
                    let boolQ = obj.query[0]["bool"];
                    let objQuery = JSON.stringify(boolQ);
                    console.log(objQuery);
                    fmValues.filter((x) => {
                      let query1 = JSON.stringify(x);
                      console.log(query1);
                      if (query1 == objQuery) {
                        obj.isRefined = true;
                      } else {
                        obj.isRefined = false;
                      }
                    });
                  } else {
                    tempObj[key] &&
                      tempObj[key].map((obj) => {
                        obj.count = 0;
                      });
                  }
                }
              });
          });
        this.searchPara = { ...tempObj };
        if (!this.firstLoad) {
          if (
            this.data.desc != "Purchase Search" &&
            this.data.desc != "SVC Single Search" &&
            this.data.desc != "Global SVC Search"
          ) {
            this.toggleSelection(this.searchPara.Location, "mumbai", null);
          }
        }
        this.firstLoad = true;
      });
  }

  pushIfNotExists(array, element) {
    if (!array.includes(element)) {
      array.push(element);
    }
  }

  async toggleSelectionPara(para, value) {
    if (para == "stoneIdORCertSelected") {
      if (this.searchPara.stoneIdORCertSelected == "stoneId") {
        this.searchPara.stoneIdORCertSelected = "certNo";
        return;
      } else if (this.searchPara.stoneIdORCertSelected == "certNo") {
        this.searchPara.stoneIdORCertSelected = "stoneId";
        return;
      }
    } else if (para == "colorType") {
      if (this.searchPara.colorType == "white") {
        this.searchPara.colorType = "fancy";
        return;
      } else if (this.searchPara.colorType == "fancy") {
        this.searchPara.colorType = "white";
        return;
      }
    } else if (para == "caratType") {
      if (this.searchPara.caratType == "specific") {
        this.searchPara.caratType = "groups";
        return;
      } else if (this.searchPara.caratType == "groups") {
        this.searchPara.caratType = "specific";
        return;
      }
    } else if (para == "clarityType") {
      this.searchPara.clarityType = value;
      return;
    } else if (para == "finishingType") {
      this.searchPara.finishingType = value;
      return;
    } else if (para == "shapeType") {
      this.searchPara.shapeType = value;
      // if (this.searchPara.shapeType == "standard") {
      //   this.searchPara.shapeType = "standard";
      //   return;
      // }
      // if (this.searchPara.shapeType == "specific") {
      //   this.searchPara.shapeType = "others";
      //   return;
      // }
      // else if (this.searchPara.shapeType == "others") {
      //   this.searchPara.shapeType = "specific";
      //   return;
      // }
    }
  }

  async toggleSelection(paraList, paraVal, parameter: any) {
    console.log(paraList, paraVal);
    this.addSoldMustNotQuery = true;
    console.log(paraList);
    if (parameter == "Color") {
      paraList.map((res) => (res.isRefined = false));
      let firstValue: any;
      if (this.selectedColorsArray.length == 1) {
        if (this.selectedColorsArray[0] == paraVal) {
          this.selectedColorsArray.indexOf(paraVal) === -1
            ? this.selectedColorsArray.push(paraVal)
            : this.selectedColorsArray.splice(
              this.selectedColorsArray.indexOf(paraVal),
              1
            );
        }
        let val1 = this.selectedColorsArray[0];
        let arrayIndex = paraList.findIndex(function (object) {
          return object.value === val1;
        });
        let paraIndex = paraList.findIndex(function (object) {
          return object.value === paraVal;
        });
        if (arrayIndex > paraIndex) {
          this.selectedColorsArray.indexOf(paraVal) === -1
            ? this.selectedColorsArray.push(paraVal)
            : this.selectedColorsArray.splice(
              this.selectedColorsArray.indexOf(paraVal),
              1
            );
        }
        let start = false;
        for (let a in paraList) {
          let val = paraList[a].value;
          let val1 = this.selectedColorsArray[0];
          let checkVal =
            this.selectedColorsArray.indexOf(val) >= 0 ? (start = true) : false;
          if (
            start &&
            this.selectedColorsArray.indexOf(val) === -1 &&
            arrayIndex < paraIndex
          ) {
            if (val != paraVal) {
              this.selectedColorsArray.push(val);
            }
            if (val == paraVal) {
              this.selectedColorsArray.push(val);
              break;
            }
          }
        }
      } else {
        this.selectedColorsArray.indexOf(paraVal) === -1
          ? this.selectedColorsArray.push(paraVal)
          : this.selectedColorsArray.splice(
            this.selectedColorsArray.indexOf(paraVal),
            1
          );
      }
      console.log(this.selectedColorsArray);
      for (let i in this.selectedColorsArray) {
        let val = this.selectedColorsArray[i];
        paraList.filter((a) => a.value == val)[0].isRefined = !paraList.filter(
          (a) => a.value == val
        )[0].isRefined;
      }
      this.getCount(this.searchQuery(true));
    } else if (parameter == "Clarity") {
      paraList.map((res) => (res.isRefined = false));
      if (this.searchPara.clarityType == "advanced") {
        if (this.selectedAdvancedClaritiesArray.length == 1) {
          if (this.selectedAdvancedClaritiesArray[0] == paraVal) {
            this.selectedAdvancedClaritiesArray.indexOf(paraVal) === -1
              ? this.selectedAdvancedClaritiesArray.push(paraVal)
              : this.selectedAdvancedClaritiesArray.splice(
                this.selectedAdvancedClaritiesArray.indexOf(paraVal),
                1
              );
          }
          let val1 = this.selectedAdvancedClaritiesArray[0];
          let arrayIndex = paraList.findIndex(function (object) {
            return object.value === val1;
          });
          let paraIndex = paraList.findIndex(function (object) {
            return object.value === paraVal;
          });
          if (arrayIndex > paraIndex) {
            this.selectedAdvancedClaritiesArray.indexOf(paraVal) === -1
              ? this.selectedAdvancedClaritiesArray.push(paraVal)
              : this.selectedAdvancedClaritiesArray.splice(
                this.selectedAdvancedClaritiesArray.indexOf(paraVal),
                1
              );
          }
          let start = false;
          for (let a in paraList) {
            let val = paraList[a].value;
            let val1 = this.selectedAdvancedClaritiesArray[0];
            let checkVal =
              this.selectedAdvancedClaritiesArray.indexOf(val) >= 0
                ? (start = true)
                : false;
            if (
              start &&
              this.selectedAdvancedClaritiesArray.indexOf(val) === -1 &&
              arrayIndex < paraIndex
            ) {
              if (val != paraVal) {
                this.selectedAdvancedClaritiesArray.push(val);
              }
              if (val == paraVal) {
                this.selectedAdvancedClaritiesArray.push(val);
                break;
              }
            }
          }
        } else {
          this.selectedAdvancedClaritiesArray.indexOf(paraVal) === -1
            ? this.selectedAdvancedClaritiesArray.push(paraVal)
            : this.selectedAdvancedClaritiesArray.splice(
              this.selectedAdvancedClaritiesArray.indexOf(paraVal),
              1
            );
        }
        console.log(this.selectedAdvancedClaritiesArray);
        for (let i in this.selectedAdvancedClaritiesArray) {
          let val = this.selectedAdvancedClaritiesArray[i];
          paraList.filter((a) => a.value == val)[0].isRefined =
            !paraList.filter((a) => a.value == val)[0].isRefined;
        }
        this.getCount(this.searchQuery(true));
      }
      if (this.searchPara.clarityType == "basic") {
        if (this.selectedClaritiesArray.length == 1) {
          if (this.selectedClaritiesArray[0] == paraVal) {
            this.selectedClaritiesArray.indexOf(paraVal) === -1
              ? this.selectedClaritiesArray.push(paraVal)
              : this.selectedClaritiesArray.splice(
                this.selectedClaritiesArray.indexOf(paraVal),
                1
              );
          }
          let val1 = this.selectedClaritiesArray[0];
          let arrayIndex = paraList.findIndex(function (object) {
            return object.value === val1;
          });
          let paraIndex = paraList.findIndex(function (object) {
            return object.value === paraVal;
          });
          if (arrayIndex > paraIndex) {
            this.selectedClaritiesArray.indexOf(paraVal) === -1
              ? this.selectedClaritiesArray.push(paraVal)
              : this.selectedClaritiesArray.splice(
                this.selectedClaritiesArray.indexOf(paraVal),
                1
              );
          }
          let start = false;
          for (let a in paraList) {
            let val = paraList[a].value;
            let val1 = this.selectedClaritiesArray[0];
            let checkVal =
              this.selectedClaritiesArray.indexOf(val) >= 0
                ? (start = true)
                : false;
            if (
              start &&
              this.selectedClaritiesArray.indexOf(val) === -1 &&
              arrayIndex < paraIndex
            ) {
              if (val != paraVal) {
                this.selectedClaritiesArray.push(val);
              }
              if (val == paraVal) {
                this.selectedClaritiesArray.push(val);
                break;
              }
            }
          }
        } else {
          this.selectedClaritiesArray.indexOf(paraVal) === -1
            ? this.selectedClaritiesArray.push(paraVal)
            : this.selectedClaritiesArray.splice(
              this.selectedClaritiesArray.indexOf(paraVal),
              1
            );
        }
        console.log(this.selectedClaritiesArray);
        for (let i in this.selectedClaritiesArray) {
          let val = this.selectedClaritiesArray[i];
          paraList.filter((a) => a.value == val)[0].isRefined =
            !paraList.filter((a) => a.value == val)[0].isRefined;
        }
        this.getCount(this.searchQuery(true));
      }
    } else if (parameter == "Fluorescence") {
      paraList.map((res) => (res.isRefined = false));
      let firstValue: any;
      if (this.selectedFluorescenceArray.length == 1) {
        if (this.selectedFluorescenceArray[0] == paraVal) {
          this.selectedFluorescenceArray.indexOf(paraVal) === -1
            ? this.selectedFluorescenceArray.push(paraVal)
            : this.selectedFluorescenceArray.splice(
              this.selectedFluorescenceArray.indexOf(paraVal),
              1
            );
        }
        let val1 = this.selectedFluorescenceArray[0];
        let arrayIndex = paraList.findIndex(function (object) {
          return object.value === val1;
        });
        let paraIndex = paraList.findIndex(function (object) {
          return object.value === paraVal;
        });
        if (arrayIndex > paraIndex) {
          this.selectedFluorescenceArray.indexOf(paraVal) === -1
            ? this.selectedFluorescenceArray.push(paraVal)
            : this.selectedFluorescenceArray.splice(
              this.selectedFluorescenceArray.indexOf(paraVal),
              1
            );
        }
        let start = false;
        for (let a in paraList) {
          let val = paraList[a].value;
          let val1 = this.selectedFluorescenceArray[0];
          let checkVal =
            this.selectedFluorescenceArray.indexOf(val) >= 0
              ? (start = true)
              : false;
          if (
            start &&
            this.selectedFluorescenceArray.indexOf(val) === -1 &&
            arrayIndex < paraIndex
          ) {
            if (val != paraVal) {
              this.selectedFluorescenceArray.push(val);
            }
            if (val == paraVal) {
              this.selectedFluorescenceArray.push(val);
              break;
            }
          }
        }
      } else {
        this.selectedFluorescenceArray.indexOf(paraVal) === -1
          ? this.selectedFluorescenceArray.push(paraVal)
          : this.selectedFluorescenceArray.splice(
            this.selectedFluorescenceArray.indexOf(paraVal),
            1
          );
      }
      console.log(this.selectedFluorescenceArray);
      for (let i in this.selectedFluorescenceArray) {
        let val = this.selectedFluorescenceArray[i];
        paraList.filter((a) => a.value == val)[0].isRefined = !paraList.filter(
          (a) => a.value == val
        )[0].isRefined;
      }
      this.getCount(this.searchQuery(true));
    } else if (parameter == "Cut") {
      //make finish unselect
      this.selectedFinishArray.length = 0;
      this.searchPara.finishings.map((res) => (res.isRefined = false));
      //--------------//
      paraList.map((res) => (res.isRefined = false));
      let firstValue: any;
      if (this.selectedCutArray.length == 1) {
        if (this.selectedCutArray[0] == paraVal) {
          this.selectedCutArray.indexOf(paraVal) === -1
            ? this.selectedCutArray.push(paraVal)
            : this.selectedCutArray.splice(
              this.selectedCutArray.indexOf(paraVal),
              1
            );
        }
        let val1 = this.selectedCutArray[0];
        let arrayIndex = paraList.findIndex(function (object) {
          return object.value === val1;
        });
        let paraIndex = paraList.findIndex(function (object) {
          return object.value === paraVal;
        });
        if (arrayIndex > paraIndex) {
          this.selectedCutArray.indexOf(paraVal) === -1
            ? this.selectedCutArray.push(paraVal)
            : this.selectedCutArray.splice(
              this.selectedCutArray.indexOf(paraVal),
              1
            );
        }
        let start = false;
        for (let a in paraList) {
          let val = paraList[a].value;
          let val1 = this.selectedCutArray[0];
          let checkVal =
            this.selectedCutArray.indexOf(val) >= 0 ? (start = true) : false;
          if (
            start &&
            this.selectedCutArray.indexOf(val) === -1 &&
            arrayIndex < paraIndex
          ) {
            if (val != paraVal) {
              this.selectedCutArray.push(val);
            }
            if (val == paraVal) {
              this.selectedCutArray.push(val);
              break;
            }
          }
        }
      } else {
        this.selectedCutArray.indexOf(paraVal) === -1
          ? this.selectedCutArray.push(paraVal)
          : this.selectedCutArray.splice(
            this.selectedCutArray.indexOf(paraVal),
            1
          );
      }
      console.log(this.selectedCutArray);
      for (let i in this.selectedCutArray) {
        let val = this.selectedCutArray[i];
        paraList.filter((a) => a.value == val)[0].isRefined = !paraList.filter(
          (a) => a.value == val
        )[0].isRefined;
      }
      this.getCount(this.searchQuery(true));
    } else if (parameter == "Polish") {
      //make finish unselect
      this.selectedFinishArray.length = 0;
      this.searchPara.finishings.map((res) => (res.isRefined = false));
      //--------------//
      paraList.map((res) => (res.isRefined = false));
      let firstValue: any;
      if (this.selectedPolishArray.length == 1) {
        if (this.selectedPolishArray[0] == paraVal) {
          this.selectedPolishArray.indexOf(paraVal) === -1
            ? this.selectedPolishArray.push(paraVal)
            : this.selectedPolishArray.splice(
              this.selectedPolishArray.indexOf(paraVal),
              1
            );
        }
        let val1 = this.selectedPolishArray[0];
        let arrayIndex = paraList.findIndex(function (object) {
          return object.value === val1;
        });
        let paraIndex = paraList.findIndex(function (object) {
          return object.value === paraVal;
        });
        if (arrayIndex > paraIndex) {
          this.selectedPolishArray.indexOf(paraVal) === -1
            ? this.selectedPolishArray.push(paraVal)
            : this.selectedPolishArray.splice(
              this.selectedPolishArray.indexOf(paraVal),
              1
            );
        }
        let start = false;
        for (let a in paraList) {
          let val = paraList[a].value;
          let val1 = this.selectedPolishArray[0];
          let checkVal =
            this.selectedPolishArray.indexOf(val) >= 0 ? (start = true) : false;
          if (
            start &&
            this.selectedPolishArray.indexOf(val) === -1 &&
            arrayIndex < paraIndex
          ) {
            if (val != paraVal) {
              this.selectedPolishArray.push(val);
            }
            if (val == paraVal) {
              this.selectedPolishArray.push(val);
              break;
            }
          }
        }
      } else {
        this.selectedPolishArray.indexOf(paraVal) === -1
          ? this.selectedPolishArray.push(paraVal)
          : this.selectedPolishArray.splice(
            this.selectedPolishArray.indexOf(paraVal),
            1
          );
      }
      console.log(this.selectedPolishArray);
      for (let i in this.selectedPolishArray) {
        let val = this.selectedPolishArray[i];
        paraList.filter((a) => a.value == val)[0].isRefined = !paraList.filter(
          (a) => a.value == val
        )[0].isRefined;
      }
      this.getCount(this.searchQuery(true));
    } else if (parameter == "Symmetry") {
      //make finish unselect
      this.selectedFinishArray.length = 0;
      this.searchPara.finishings.map((res) => (res.isRefined = false));
      //--------------//
      paraList.map((res) => (res.isRefined = false));
      let firstValue: any;
      if (this.selectedSymmetryArray.length == 1) {
        if (this.selectedSymmetryArray[0] == paraVal) {
          this.selectedSymmetryArray.indexOf(paraVal) === -1
            ? this.selectedSymmetryArray.push(paraVal)
            : this.selectedSymmetryArray.splice(
              this.selectedSymmetryArray.indexOf(paraVal),
              1
            );
        }
        let val1 = this.selectedSymmetryArray[0];
        let arrayIndex = paraList.findIndex(function (object) {
          return object.value === val1;
        });
        let paraIndex = paraList.findIndex(function (object) {
          return object.value === paraVal;
        });
        if (arrayIndex > paraIndex) {
          this.selectedSymmetryArray.indexOf(paraVal) === -1
            ? this.selectedSymmetryArray.push(paraVal)
            : this.selectedSymmetryArray.splice(
              this.selectedSymmetryArray.indexOf(paraVal),
              1
            );
        }
        let start = false;
        for (let a in paraList) {
          let val = paraList[a].value;
          let val1 = this.selectedSymmetryArray[0];
          let checkVal =
            this.selectedSymmetryArray.indexOf(val) >= 0
              ? (start = true)
              : false;
          if (
            start &&
            this.selectedSymmetryArray.indexOf(val) === -1 &&
            arrayIndex < paraIndex
          ) {
            if (val != paraVal) {
              this.selectedSymmetryArray.push(val);
            }
            if (val == paraVal) {
              this.selectedSymmetryArray.push(val);
              break;
            }
          }
        }
      } else {
        this.selectedSymmetryArray.indexOf(paraVal) === -1
          ? this.selectedSymmetryArray.push(paraVal)
          : this.selectedSymmetryArray.splice(
            this.selectedSymmetryArray.indexOf(paraVal),
            1
          );
      }
      console.log(this.selectedSymmetryArray);
      for (let i in this.selectedSymmetryArray) {
        let val = this.selectedSymmetryArray[i];
        paraList.filter((a) => a.value == val)[0].isRefined = !paraList.filter(
          (a) => a.value == val
        )[0].isRefined;
      }
      this.getCount(this.searchQuery(true));
    } else if (parameter == "Finish") {
      // console.log('\x1b[41m%s\x1b[0m', ':check search para for is refined ', this.searchPara)
      //make cut, polish, symmetry unselect
      this.selectedCutArray.length = 0;
      this.selectedPolishArray.length = 0;
      this.selectedSymmetryArray.length = 0;
      this.searchPara.cuts.map((res) => (res.isRefined = false));
      this.searchPara.polishes.map((res) => (res.isRefined = false));
      this.searchPara.symmetries.map((res) => (res.isRefined = false));

      //--------------//
      let selected = paraList.filter((a) => a.value == paraVal)[0].isRefined;
      if (selected) {
        paraList.filter((a) => a.value == paraVal)[0].isRefined =
          !paraList.filter((a) => a.value == paraVal)[0].isRefined;
      } else {
        paraList.map((res) => (res.isRefined = false));
        paraList.filter((a) => a.value == paraVal)[0].isRefined =
          !paraList.filter((a) => a.value == paraVal)[0].isRefined;
      }
      if (paraVal == "3EX") {
        this.searchPara.cuts = this.searchPara.cuts.filter((i) => {
          if (i.label == "EX") {
            i.isRefined = !selected;
          }
          return i;
        });
        this.searchPara.polishes = this.searchPara.polishes.filter((i) => {
          if (i.label == "EX") {
            i.isRefined = !selected;
          }
          return i;
        });
        this.searchPara.symmetries = this.searchPara.symmetries.filter((i) => {
          if (i.label == "EX") {
            i.isRefined = !selected;
          }
          return i;
        });
      }
      if (paraVal == "IDEAL") {
        this.searchPara.cuts = this.searchPara.cuts.filter((i) => {
          if (i.label == "EX" || i.label == "Ideal") {
            i.isRefined = !selected;
          }
          return i;
        });
        this.searchPara.polishes = this.searchPara.polishes.filter((i) => {
          if (i.label == "EX" || i.label == "Ideal") {
            i.isRefined = !selected;
          }
          return i;
        });
        this.searchPara.symmetries = this.searchPara.symmetries.filter((i) => {
          if (i.label == "EX" || i.label == "Ideal") {
            i.isRefined = !selected;
          }
          return i;
        });
      }

      if (paraVal == "EX-") {
        this.searchPara.cuts = this.searchPara.cuts.filter((i) => {
          if (i.label == "EX") {
            i.isRefined = !selected;
          }
          return i;
        });
        this.searchPara.polishes = this.searchPara.polishes.filter((i) => {
          if (
            i.label == "VG" ||
            i.label == "EX" ||
            i.label == "GD" ||
            i.label == "FR"
          ) {
            i.isRefined = !selected;
          }
          return i;
        });
        this.searchPara.symmetries = this.searchPara.symmetries.filter((i) => {
          if (
            i.label == "VG" ||
            i.label == "EX" ||
            i.label == "GD" ||
            i.label == "FR"
          ) {
            i.isRefined = !selected;
          }
          return i;
        });
      }

      if (paraVal == "VG+") {
        this.searchPara.cuts = this.searchPara.cuts.filter((i) => {
          if (i.label == "VG" || i.label == "EX") {
            i.isRefined = !selected;
          }
          return i;
        });
        this.searchPara.polishes = this.searchPara.polishes.filter((i) => {
          if (i.label == "VG" || i.label == "EX") {
            i.isRefined = !selected;
          }
          return i;
        });
        this.searchPara.symmetries = this.searchPara.symmetries.filter((i) => {
          if (i.label == "VG" || i.label == "EX") {
            i.isRefined = !selected;
          }
          return i;
        });
      }

      if (paraVal == "VG-") {
        this.searchPara.cuts = this.searchPara.cuts.filter((i) => {
          if (i.label == "VG") {
            i.isRefined = !selected;
          }
          return i;
        });
        this.searchPara.polishes = this.searchPara.polishes.filter((i) => {
          if (i.label == "VG" || i.label == "GD" || i.label == "FR") {
            i.isRefined = !selected;
          }
          return i;
        });
        this.searchPara.symmetries = this.searchPara.symmetries.filter((i) => {
          if (i.label == "VG" || i.label == "GD" || i.label == "FR") {
            i.isRefined = !selected;
          }
          return i;
        });
      }

      if (paraVal == "GD+") {
        this.searchPara.cuts = this.searchPara.cuts.filter((i) => {
          if (i.label == "GD") {
            i.isRefined = !selected;
          }
          return i;
        });
        this.searchPara.polishes = this.searchPara.polishes.filter((i) => {
          if (i.label == "EX" || i.label == "VG" || i.label == "GD") {
            i.isRefined = !selected;
          }
          return i;
        });
        this.searchPara.symmetries = this.searchPara.symmetries.filter((i) => {
          if (i.label == "EX" || i.label == "VG" || i.label == "GD") {
            i.isRefined = !selected;
          }
          return i;
        });
      }
      // let firstValue: any;
      // if (this.selectedFinishArray.length == 1) {
      //   if (this.selectedFinishArray[0] == paraVal) {
      //     this.selectedFinishArray.indexOf(paraVal) === -1 ? this.selectedFinishArray.push(paraVal) : this.selectedFinishArray.splice(this.selectedFinishArray.indexOf(paraVal), 1);
      //   }
      //   let val1 = this.selectedFinishArray[0];
      //   let arrayIndex = paraList.findIndex(function (object) { return object.value === val1; });
      //   let paraIndex = paraList.findIndex(function (object) { return object.value === paraVal; });
      //   if (arrayIndex > paraIndex) {
      //     this.selectedFinishArray.indexOf(paraVal) === -1 ? this.selectedFinishArray.push(paraVal) : this.selectedFinishArray.splice(this.selectedFinishArray.indexOf(paraVal), 1);
      //   }
      //   let start = false
      //   for (let a in paraList) {
      //     let val = paraList[a].value
      //     let val1 = this.selectedFinishArray[0];
      //     let checkVal = this.selectedFinishArray.indexOf(val) >= 0 ? start = true : false;
      //     if (start && this.selectedFinishArray.indexOf(val) === -1 && arrayIndex < paraIndex) {
      //       if (val != paraVal) {
      //         this.selectedFinishArray.push(val)
      //       }
      //       if (val == paraVal) {
      //         this.selectedFinishArray.push(val)
      //         break;
      //       }
      //     }
      //   }
      // } else {
      //   this.selectedFinishArray.indexOf(paraVal) === -1 ? this.selectedFinishArray.push(paraVal) : this.selectedFinishArray.splice(this.selectedFinishArray.indexOf(paraVal), 1);
      // }
      // console.log(this.selectedFinishArray)
      // for (let i in this.selectedFinishArray) {
      //   let val = this.selectedFinishArray[i];
      //   paraList.filter(a => a.value == val)[0].isRefined = !paraList.filter(a => a.value == val)[0].isRefined;
      // }
      this.getCount(this.searchQuery(true));
    } else if (parameter == "partyName") {
      for await (let a of paraList) {
        let isExist = paraVal.find((el) => el == a.value);
        if (!!isExist) {
          console.log(isExist);
          a.isRefined = true;
        } else {
          if (a.isRefined) {
            a.isRefined = false;
          }
        }
      }
      console.log(paraList);
    } else {
      console.log(paraList, paraVal);
      if (!!paraList && paraList.length > 0) {
        paraList.filter((a) => a.value == paraVal)[0].isRefined =
          !paraList.filter((a) => a.value == paraVal)[0].isRefined;
      }
      if (paraVal == "Sold") {
        this.addSoldMustNotQuery = false;
        this.getCount(this.searchQuery(true));
      } else {
        this.getCount(this.searchQuery(true));
      }
    }
  }

  searchQuery(qryReturn = false, soldEnable = false) {
    this.searchedStoneArray = [];
    if (!qryReturn) {
      this.hits = [];
      this.esData = [];
    }
    // let qry =
    // {
    //   "query": {
    //     "query_string": {
    //       "query": ""
    //     },
    //     "match" :{
    //       "ShapeCode": ""
    //     }
    //   }
    // };
    let qry: any = {
      query: {
        bool: {
          should: [
            {
              simple_query_string: {
                query: "",
              },
            },
            {
              bool: {
                must: [],
              },
            },
          ],
        },
      },
    };

    //qry.query.bool.should[1].bool.must

    let mustObj: any = qry.query.bool.should[1].bool.must;

    let obj: any;

    if (this.type == "Sold") {
      obj = [
        {
          simple_query_string: {
            query: "",
          },
        },
        {
          bool: {
            must: [
              {
                match: {
                  internalStatus: "Sold",
                },
              },
            ],
            must_not: [],
          },
        },
      ];
    } else {
      if (this.showSoldStone) {
        obj = [
          {
            simple_query_string: {
              query: "",
            },
          },
          {
            bool: {
              must: [],
            },
          },
        ];
      } else {
        obj = [
          {
            simple_query_string: {
              query: "",
            },
          },
          {
            bool: {
              must: [],
              // "should": [],
              must_not: [
                {
                  match: {
                    internalStatus: "Sold",
                  },
                },
              ],
            },
          },
        ];
      }
    }
    if (!!this.blackListedStones) {
      if (!!this.blackListed) {
        obj = [
          {
            simple_query_string: {
              query: "",
            },
          },
          {
            bool: {
              must: [
                {
                  match: {
                    rejectedStone: this.blackListed,
                  },
                },
              ],
              must_not: [
                {
                  match: {
                    internalStatus: "Sold",
                  },
                },
              ],
            },
          },
        ];
      } else {
        obj = [
          {
            simple_query_string: {
              query: "",
            },
          },
          {
            bool: {
              must: [],
              should: [
                {
                  match: {
                    rejectedStone: this.blackListed,
                  },
                },
              ],
              must_not: [
                {
                  match: {
                    internalStatus: "Sold",
                  },
                },
              ],
            },
          },
        ];
      }
    }

    if (!!this.rapnetSearch) {
      if (!!this.rapnetListed) {
        if (this.showSoldStone) {
          obj = [
            {
              simple_query_string: {
                query: "",
              },
            },
            {
              bool: {
                must: [
                  {
                    match: {
                      isRapnetFile: this.rapnetListed,
                    },
                  },
                ],
              },
            },
          ];
        } else {
          obj = [
            {
              simple_query_string: {
                query: "",
              },
            },
            {
              bool: {
                must: [
                  {
                    match: {
                      isRapnetFile: this.rapnetListed,
                    },
                  },
                ],
                must_not: [
                  {
                    match: {
                      internalStatus: "Sold",
                    },
                  },
                ],
              },
            },
          ];
        }
      }
    }
    if (!!this.isAvailableForSale) {
      if (this.showSoldStone) {
        obj = [
          {
            simple_query_string: {
              query: "",
            },
          },
          {
            bool: {
              must: [
                {
                  match: {
                    availableForSale: 1,
                  },
                },
              ],
            },
          },
        ];
      } else {
        obj = [
          {
            simple_query_string: {
              query: "",
            },
          },
          {
            bool: {
              must: [
                {
                  match: {
                    availableForSale: 1,
                  },
                },
              ],
              must_not: [
                {
                  match: {
                    internalStatus: "Sold",
                  },
                },
              ],
            },
          },
        ];
      }
    }
    qry["query"]["bool"]["should"] = obj;
    // }
    try {
      qry = this.getSelected(
        (this.query && this.query.disjunctiveFacetsRefinements) || {},
        qry
      );
    } catch (e) {
      console.log(e);
    }

    let object = {};
    if (this.default.shape) {
      object["shapes"] = "ShapeCode";
    }
    if (this.default.fluorescence) {
      object["fluorescences"] = "FluorescenceCode";
    }
    if (this.default.lab) {
      object["labs"] = "lab";
    }
    if (this.default.users) {
      object["Users"] = "username";
    }
    if (this.default.size) {
      object["AllSize"] = "Size";
    }
    if (this.default.location) {
      object["Location"] = "location";
    }
    if (this.default.standardShapeCode) {
      object["standardShapeCode"] = "standardShapeCode";
    }
    if (this.default.internalStatus) {
      object["InternalStatus"] = "internalStatus";
    }
    if (this.default.transType) {
      object["TransType"] = "transType";
    }
    if (this.default.box) {
      object["Box"] = "Box";
    }
    if (this.default.department) {
      object["Department"] = "department";
    }
    if (this.default.externalStatus) {
      object["ExternalStatus"] = "externalStatus";
    }
    if (this.default.fm) {
      object["fm"] = "fm";
    }
    if (this.default.party) {
      object["partyName"] = "partyName";
    }
    if (this.default.Lotcountry) {
      object["Lotcountry"] = "Lotcountry";
    }
    let searchPara = this.searchPara;
    let esTermsQuery = this.esTermsQuery;
    console.log(object);
    Object.keys(object).filter((res) => {
      if (searchPara[res]) {
        let tmps = searchPara[res].filter((a) => a.isRefined == true);
        if (!!tmps && tmps.length > 0) {
          let qryObjects = tmps.filter((t) => t.query);
          if (qryObjects && qryObjects.length > 0) {
            for (let qo of qryObjects) {
              for (let q of qo.query) {
                qry.query.bool.should[1].bool.must.push(q);
              }
            }
          }
          let withoutQryObjects = tmps.filter((t) => !t.query);
          if (!!withoutQryObjects && withoutQryObjects.length > 0) {
            let termsQry = esTermsQuery(object[res], withoutQryObjects);
            qry.query.bool.should[1].bool.must.push(termsQry);
          }
        }
      }
    });

    if (
      this.searchPara.stoneIdORCertSelected == "stoneId" &&
      !!this.searchPara.stoneId &&
      this.searchPara.stoneId.length > 0
    ) {
      let tmp = [];
      let issueList = [];
      if (this.searchPara.stoneId.includes(",")) {
        tmp = this.searchPara.stoneId.split("\n");
        tmp.forEach((el) => {
          if (el.indexOf(",") > 0) {
            let data1 = el.split(",");
            let data2 = [];
            data1.forEach((item) => {
              data2.push(item.trim());
            });
            Array.prototype.push.apply(issueList, data2);
          } else {
            issueList.push(el);
          }
        });
        tmp = issueList;
      } else {
        tmp = this.searchPara.stoneId
          .trim()
          .split("\n")
          .filter((line) => line.trim() !== "");
      }
      //  = this.searchPara.stoneId.split("\n");
      //   let issueList = []
      //   tmp.forEach(el => {
      //     if (el.indexOf(",") > 0) {
      //       let data1 = el.split(',')
      //       let data2 = []
      //       data1.forEach(el => {
      //         data2.push(el.trim())
      //       })
      //       Array.prototype.push.apply(issueList, data2);
      //     } else {
      //       issueList.push(el)
      //     }
      //   })

      this.searchedStoneArray = tmp;
      let boolShouldMultiple = {
        bool: {
          must: [],
        },
      };
      let prefixObj: any = {};
      prefixObj = {
        terms: {
          stoneName: [],
        },
      };
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i] != "") {
          prefixObj["terms"]["stoneName"].push(tmp[i].trim());
        }
      }
      boolShouldMultiple.bool.must.push(prefixObj);
      console.log(boolShouldMultiple.bool.must);
      qry.query.bool.should[1].bool.must.push(boolShouldMultiple);
    }
    if (
      this.searchPara.stoneIdORCertSelected == "certNo" &&
      !!this.searchPara.certNo &&
      this.searchPara.certNo.length > 0
    ) {
      let tmp = this.searchPara.certNo.split("\n");
      tmp = tmp.filter(function (el) {
        return el != "";
      });
      for (let i = 0; i < tmp.length; i++) {
        qry.query.bool.should[1].bool.must.push({
          terms: {
            ReportNo: tmp,
          },
        });
      }
    }

    // carat - specific
    if (this.searchPara.caratType == "specific") {
      let rangeQry = this.esRangeQuery(
        "cts",
        this.searchPara.fromCarat,
        this.searchPara.toCarat
      );
      if (!!rangeQry) qry.query.bool.should[1].bool.must.push(rangeQry);
    }

    // carat - groups
    if (this.searchPara.caratType == "groups") {
      let tmp = this.searchPara.sizeGroups.filter((a) => a.isRefined == true);
      let rangeArr = [];

      for (let i = 0; i < tmp.length; i++) {
        let rangeQry = this.esRangeQuery("cts", tmp[i].from, tmp[i].to);
        if (!!rangeQry) rangeArr.push(rangeQry);
      }

      if (rangeArr.length > 0) {
        if (rangeArr.length > 1) {
          let boolShould = {
            bool: {
              should: rangeArr,
            },
          };
          qry.query.bool.should[1].bool.must.push(boolShould);
        } else {
          qry.query.bool.should[1].bool.must.push(rangeArr[0]);
        }
      }
    }

    //colors
    if (this.searchPara.colorType == "white") {
      let tmp = this.searchPara.colors.filter((a) => a.isRefined == true);

      if (!!tmp && tmp.length > 0) {
        let termsQry = this.esTermsQuery("ColorCode", tmp);
        qry.query.bool.should[1].bool.must.push(termsQry);
      }
    }

    let tmp = [];
    // color - fancy
    if (this.searchPara.colorType == "fancy") {
      // fancy color intensity
      if (!!this.searchPara.selectedFancyColorIntensity) {
        if (this.searchPara.selectedFancyColorIntensity.value == "ALL") {
          tmp = this.searchPara.fancyColorIntensities.filter(
            (a) => a.value != "ALL"
          );
        } else {
          tmp = this.searchPara.fancyColorIntensities.filter(
            (a) => a.value == this.searchPara.selectedFancyColorIntensity.value
          );
        }
        if (!!tmp && tmp.length > 0) {
          let termsQry = this.esTermsQuery("FancyColorIntensityCode", tmp);
          qry.query.bool.should[1].bool.must.push(termsQry);
        }
      }

      // fancy color overtone
      if (!!this.searchPara.selectedFancyColorOvertone) {
        if (this.searchPara.selectedFancyColorOvertone.value == "ALL") {
          tmp = this.searchPara.fancyColorOvertones.filter(
            (a) => a.value != "ALL"
          );
        } else {
          tmp = this.searchPara.fancyColorOvertones.filter(
            (a) => a.value == this.searchPara.selectedFancyColorOvertone.value
          );
        }

        if (!!tmp && tmp.length > 0) {
          let termsQry = this.esTermsQuery("FancyColorOvertoneCode", tmp);
          qry.query.bool.should[1].bool.must.push(termsQry);
        }
      }

      // fancy color
      if (!!this.searchPara.selectedFancyColor) {
        let tmp = this.searchPara.fancyColors.filter(
          (a) => a.value == this.searchPara.selectedFancyColor.value
        );

        if (!!tmp && tmp.length > 0) {
          let termsQry = this.esTermsQuery("FancyColorCode", tmp);
          qry.query.bool.should[1].bool.must.push(termsQry);
        }
      }
    }

    // clarity - basic
    if (this.searchPara.clarityType == "basic") {
      //clarities
      let tmp = this.searchPara.clarities.filter((a) => a.isRefined == true);

      if (!!tmp && tmp.length > 0) {
        let termsQry = this.esTermsQuery("ClarityCode", tmp);
        qry.query.bool.should[1].bool.must.push(termsQry);
      }
    }

    // clarity - advanced
    if (this.searchPara.clarityType == "advanced") {
      //clarities
      let tmp = this.searchPara.advancedClarities.filter(
        (a) => a.isRefined == true
      );

      if (!!tmp && tmp.length > 0) {
        let termsQry = this.esTermsQuery("ClarityCode", tmp);
        qry.query.bool.should[1].bool.must.push(termsQry);
      }
    }

    // clarity - parameters
    /*if(this.searchPara.clarityType == 'parameters') {
      let keys = Object.keys(this.searchPara.pdObject);
      if(!!keys && keys.length>0) {
        tmp = keys.filter(a=>this.searchPara.pdObject[a].length>0);
        console.log("tmp pd", tmp);
        for(let i=0;i<tmp.length;i++)
        {
          for(let j=0;j<this.searchPara.pdObject[tmp[i]].length;j++) {
            qry.filters.push({"facetFilters" : [tmp[i].split(" ").join("") + "pdCode", this.searchPara.pdObject[tmp[i]][j] ]})
          }
        }
      }
    }*/

    //descriptions-pending
    tmp = this.searchPara.descriptions.filter((a) => a.isRefined == true);
    if (!!tmp && tmp.length > 0) {
      //mixpanel
      let arr_general = [];
      for (let i = 0; i < tmp.length; i++) {
        arr_general.push(tmp[i].label);
      }

      let lookup = {};
      let paramArr = [];
      for (let i = 0; i < tmp.length; i++) {
        if (!!tmp[i].para && tmp[i].para.length > 0) {
          for (let j = 0; j < tmp[i].para.length; j++) {
            let label = tmp[i].para[j].label;
            let value = tmp[i].para[j].value;

            if (!(label in lookup)) {
              lookup[label] = 1;
              let jsonObj = {};
              let valueArr = [];
              valueArr.push(value);
              jsonObj[label] = valueArr;
              paramArr.push(jsonObj);
            } else {
              let existingJsonObj = paramArr.find((l) => l[label]);
              existingJsonObj[label].push(value);
            }
          }
        }
      }

      let boolShouldMultiple = {
        bool: {
          must: [],
        },
      };
      if (paramArr.length > 0) {
        for (let a in paramArr) {
          let matchQry = {
            terms: paramArr[a],
          };
          boolShouldMultiple.bool.must.push(matchQry);
        }
        qry.query.bool.should[1].bool.must.push(boolShouldMultiple);
      }
    }

    // finishing - general
    if (this.searchPara.finishingType == "general") {
      //finishings
      tmp = this.searchPara.finishings.filter((a) => a.isRefined == true);
      if (!!tmp && tmp.length > 0) {
        let lookup = {};
        let paramArr = [];

        for (let i = 0; i < tmp.length; i++) {
          if (!!tmp[i].para && tmp[i].para.length > 0) {
            for (let j = 0; j < tmp[i].para.length; j++) {
              let label = tmp[i].para[j].label;
              let value = tmp[i].para[j].value;

              if (!(label in lookup)) {
                lookup[label] = 1;
                let jsonObj = {};
                let valueArr = [];
                valueArr.push(value);
                jsonObj[label] = valueArr;
                paramArr.push(jsonObj);
              } else {
                let existingJsonObj = paramArr.find((l) => l[label]);
                existingJsonObj[label].push(value);
              }
            }
          }
          if (!!tmp[i].query && tmp[i].query.length > 0) {
            for (let q of tmp[i].query) {
              qry.query.bool.should[1].bool.must.push(q);
            }
          }
        }
        if (paramArr.length > 0) {
          for (let a in paramArr) {
            let matchQry = {
              terms: paramArr[a],
            };
            qry.query.bool.should[1].bool.must.push(matchQry);
          }
        }
      }
    }

    // finishing- specific
    if (this.searchPara.finishingType == "specific") {
      //cut
      tmp = this.searchPara.cuts.filter((a) => a.isRefined == true);
      if (!!tmp && tmp.length > 0) {
        let termsQry = this.esTermsQuery("CutCode", tmp);
        qry.query.bool.should[1].bool.must.push(termsQry);
      }

      //polish
      tmp = this.searchPara.polishes.filter((a) => a.isRefined == true);

      if (!!tmp && tmp.length > 0) {
        let termsQry = this.esTermsQuery("PolishCode", tmp);
        qry.query.bool.should[1].bool.must.push(termsQry);
      }
      //symmetry
      tmp = this.searchPara.symmetries.filter((a) => a.isRefined == true);

      if (!!tmp && tmp.length > 0) {
        let termsQry = this.esTermsQuery("SymmetryCode", tmp);
        qry.query.bool.should[1].bool.must.push(termsQry);
      }
    }
    if (!!this.searchPara.fromCertiDate && !!this.searchPara.toCertiDate) {
      let fromCertiDate =
        moment(this.searchPara.fromCertiDate).format("yyyy-MM-DD") +
        " " +
        "00:00:00";
      let toCertiDate =
        moment(this.searchPara.toCertiDate).format("yyyy-MM-DD") +
        " " +
        "23:59:59";
      let certiDateRangeQry = this.esDateRangeQuery(
        "CertDate",
        "yyyy-MM-dd HH:mm:ss",
        fromCertiDate,
        toCertiDate
      );
      if (!!certiDateRangeQry)
        qry.query.bool.should[1].bool.must.push(certiDateRangeQry);
    }
    if (!!this.searchPara.fromInwardDate && !!this.searchPara.toInwardDate) {
      let froInwDate =
        moment(this.searchPara.fromInwardDate).format("yyyy-MM-DD") +
        " " +
        "00:00:00";
      let toInwDate =
        moment(this.searchPara.toInwardDate).format("yyyy-MM-DD") +
        " " +
        "23:59:59";
      let inwardDateRangeQry = this.esDateRangeQuery(
        "inwardDate",
        "yyyy-MM-dd HH:mm:ss",
        froInwDate,
        toInwDate
      );
      if (!!inwardDateRangeQry)
        qry.query.bool.should[1].bool.must.push(inwardDateRangeQry);
    }
    if (
      !!this.searchPara.fromTransactionDate &&
      !!this.searchPara.toTransactionDate
    ) {
      let froTrnDate =
        moment(this.searchPara.fromTransactionDate).format("yyyy-MM-DD") +
        " " +
        "00:00:00";
      let toTrnDate =
        moment(this.searchPara.toTransactionDate).format("yyyy-MM-DD") +
        " " +
        "23:59:59";
      let transactionDateRangeQry = this.esDateRangeQuery(
        "transDate",
        "yyyy-MM-dd HH:mm:ss",
        froTrnDate,
        toTrnDate
      );
      if (!!transactionDateRangeQry)
        qry.query.bool.should[1].bool.must.push(transactionDateRangeQry);
    }
    if (!!this.searchPara.fromSoldDate && !!this.searchPara.toSoldDate) {
      let fromSoldDate = moment(this.searchPara.fromSoldDate).format(
        "DD-MMM-yyyy"
      );
      let toSoldDate = moment(this.searchPara.toSoldDate).format("DD-MMM-yyyy");
      let soldDateRangeQry = this.esDateRangeQuery(
        "accInvoiceDate",
        "dd-MMM-yyyy",
        fromSoldDate,
        toSoldDate
      );
      if (!!soldDateRangeQry)
        qry.query.bool.should[1].bool.must.push(soldDateRangeQry);
    }

    // finishing - parameters
    if (this.searchPara.finishingType == "parameters") {
      // table per
      let tablePerRangeQry = this.esRangeQuery(
        "TablePer",
        this.searchPara.tablePerFrom,
        this.searchPara.tablePerTo
      );
      if (!!tablePerRangeQry)
        qry.query.bool.should[1].bool.must.push(tablePerRangeQry);

      // depth per
      let depthPerRangeQry = this.esRangeQuery(
        "TotDepth",
        this.searchPara.depthPerFrom,
        this.searchPara.depthPerTo
      );
      if (!!depthPerRangeQry)
        qry.query.bool.should[1].bool.must.push(depthPerRangeQry);

      // length
      let lengthRangeQry = this.esRangeQuery(
        "Length",
        this.searchPara.lengthFrom,
        this.searchPara.lengthTo
      );
      if (!!lengthRangeQry)
        qry.query.bool.should[1].bool.must.push(lengthRangeQry);

      // Width
      let widthRangeQry = this.esRangeQuery(
        "Width",
        this.searchPara.widthFrom,
        this.searchPara.widthTo
      );
      if (!!widthRangeQry)
        qry.query.bool.should[1].bool.must.push(widthRangeQry);

      // L/W ratio
      let ratioRangeQry = this.esRangeQuery(
        "L_W",
        this.searchPara.ratioFrom,
        this.searchPara.ratioTo
      );
      if (!!ratioRangeQry)
        qry.query.bool.should[1].bool.must.push(ratioRangeQry);

      // crown angle
      let crAngleRangeQry = this.esRangeQuery(
        "CrAngle",
        this.searchPara.crownAngleFrom,
        this.searchPara.crownAngleTo
      );
      if (!!crAngleRangeQry)
        qry.query.bool.should[1].bool.must.push(crAngleRangeQry);

      // crown height
      let crHeightRangeQry = this.esRangeQuery(
        "CrHgt",
        this.searchPara.crownHeightFrom,
        this.searchPara.crownHeightTo
      );
      if (!!crHeightRangeQry)
        qry.query.bool.should[1].bool.must.push(crHeightRangeQry);

      //girdle
      if (!!this.searchPara.selectedGirdle) {
        tmp = this.searchPara.girdles.filter(
          (a) => a.value == this.searchPara.selectedGirdle.value
        );
        if (!!tmp && tmp.length > 0) {
          let termsQry = this.esTermsQuery("GirdleCode", tmp);
          qry.query.bool.should[1].bool.must.push(termsQry);
        }
      }

      // pavilion angle
      let pavAngleRangeQry = this.esRangeQuery(
        "PavAngle",
        this.searchPara.pavilionAngleFrom,
        this.searchPara.pavilionAngleTo
      );
      if (!!pavAngleRangeQry)
        qry.query.bool.should[1].bool.must.push(pavAngleRangeQry);

      // pavilion depth
      // let pavDepthRangeQry = this.esRangeQuery("PavAngle", this.searchPara.pavilionDepthFrom, this.searchPara.pavilionDepthTo);
      // if(!!pavDepthRangeQry)
      //   qry.query.bool.should[1].bool.must.push(pavDepthRangeQry);
    }

    if (
      this.searchPara.SPMpercaratFrom != null &&
      this.searchPara.SPMpercaratTo != null
    ) {
      // SPMpercarat
      if (this.data.desc != "Purchase Search") {
        let SPMpercaratRangeQry = this.esRangeQuery(
          "SPMpercarat",
          this.searchPara.SPMpercaratFrom,
          this.searchPara.SPMpercaratTo
        );
        if (!!SPMpercaratRangeQry) {
          qry.query.bool.should[1].bool.must.push(SPMpercaratRangeQry);
        }
      }

      //Purchasecarat
      if (this.data.desc == "Purchase Search") {
        let purchasePerCaratRangeQry = this.esRangeQuery(
          "PurchasePartyPrice",
          this.searchPara.SPMpercaratFrom,
          this.searchPara.SPMpercaratTo
        );
        if (!!purchasePerCaratRangeQry) {
          qry.query.bool.should[1].bool.must.push(purchasePerCaratRangeQry);
        }
      }
    }

    if (
      this.searchPara.SPMamountFrom != null &&
      this.searchPara.SPMamountTo != null
    ) {
      // SPM amount
      if (this.data.desc != "Purchase Search") {
        let SPMamountRangeQry = this.esRangeQuery(
          "SPM",
          this.searchPara.SPMamountFrom,
          this.searchPara.SPMamountTo
        );
        if (!!SPMamountRangeQry) {
          qry.query.bool.should[1].bool.must.push(SPMamountRangeQry);
        }
      }

      //Purchase Party Amount
      if (this.data.desc == "Purchase Search") {
        let purchaseAmountRangeQry = this.esRangeQuery(
          "PurchasePartyPrice",
          this.searchPara.SPMamountFrom,
          this.searchPara.SPMamountTo
        );
        if (!!purchaseAmountRangeQry) {
          qry.query.bool.should[1].bool.must.push(purchaseAmountRangeQry);
        }
      }
    }

    if (
      this.searchPara.SPMperFrom != null &&
      this.searchPara.SPMperTo != null
    ) {
      // SPMper per
      if (this.data.desc != "Purchase Search") {
        let SPMPerRangeQry = this.esRangeQuery(
          "SPMDiscountPercent",
          this.searchPara.SPMperFrom,
          this.searchPara.SPMperTo
        );
        if (!!SPMPerRangeQry) {
          qry.query.bool.should[1].bool.must.push(SPMPerRangeQry);
        }
      }
      if (this.data.desc == "Purchase Search") {
        let PurchasePerRangeQry = this.esRangeQuery(
          "PurchasePartyDisc",
          this.searchPara.SPMperFrom,
          this.searchPara.SPMperTo
        );
        if (!!PurchasePerRangeQry) {
          qry.query.bool.should[1].bool.must.push(PurchasePerRangeQry);
        }
      }
    }

    if (this.filteredPD) {
      let boolShouldMultiple = {
        bool: {
          should: [],
        },
      };

      for (let key in this.filteredPD) {
        if (!!key) {
          let obj = {};
          obj[key] = this.filteredPD[key];
          boolShouldMultiple.bool.should.push({ terms: obj });
        }
      }
      qry.query.bool.should[1].bool.must.push(boolShouldMultiple);
    }

    qry.query.bool.should[0].simple_query_string.query.replace(
      /[&\/\\#,+()$~%.'":*?<>{}]/g,
      ""
    );

    // console.log(qry, qry.query.bool.should[1].bool.must.length)

    // if (!!qry.query.bool.should[1].bool.must && qry.query.bool.should[1].bool.must.length > 0) {
    //   debugger
    // } else {
    //   debugger
    //   qry.query.bool.should[1].bool['must_not'] = [
    //     {
    //       "match": {
    //         "internalStatus": "Sold"
    //       }
    //     }
    //   ]
    // }

    if (qryReturn) {
      return qry;
    } else {
      this.changeView();
      this.search(qry, this.index, 1);
    }
  }

  esTermsQuery(paramName, valueArr) {
    let termsQry = {
      terms: {},
    };

    termsQry.terms[paramName] = [];
    for (let i = 0; i < valueArr.length; i++) {
      if (!!Array.isArray(valueArr[i].value)) {
        valueArr[i].value.filter((el) => termsQry.terms[paramName].push(el));
      } else {
        termsQry.terms[paramName].push(valueArr[i].value);
      }
    }

    return termsQry;
  }

  esRangeQuery(paramName, from, to) {
    let rangeQry = {
      range: {},
    };
    rangeQry.range[paramName] = {};
    //from
    if (from != null && from != undefined && from != "")
      rangeQry.range[paramName]["gte"] = from;

    //to size
    if (to != null && to != undefined && to != "")
      rangeQry.range[paramName]["lte"] = to;

    if (!!from || !!to) {
      return rangeQry;
    } else {
      return null;
    }
  }

  esDateRangeQuery(paramName, format, from, to) {
    let rangeQry = {
      range: {},
    };
    rangeQry.range[paramName] = {};
    rangeQry.range[paramName]["format"] = format;
    //from
    if (!!from) rangeQry.range[paramName]["gte"] = from;
    //to size
    if (!!to) rangeQry.range[paramName]["lte"] = to;

    if (!!from || !!to) {
      return rangeQry;
    } else {
      return null;
    }
  }

  async clearSelectionArrays() {
    this.selectedShapesArray.length = 0;
    this.selectedColorsArray.length = 0;
    this.selectedClaritiesArray.length = 0;
    this.selectedOtherShapesArray.length = 0;
    this.selectedAdvancedClaritiesArray.length = 0;
    this.selectedFluorescenceArray.length = 0;
    this.selectedCutArray.length = 0;
    this.selectedPolishArray.length = 0;
    this.selectedSymmetryArray.length = 0;
    this.selectedFinishArray.length = 0;
  }

  async resetSearch() {
    setTimeout(() => {
      this.searchPara.stoneId = '';
      console.log("TIMOUT CALLED")
    }, 100)
    this.criterias = []
    await this.resetSearchpara();
    await this.intializeSearchPara();

    // setTimeout(()=>{
    // 	this.showFilters = true;
    // },100);
  }

  async getDefaultSetting() {
    await this.getAllPriceListName();

    console.log("DATA", this.actionDefaultValues, this.allPriceNameList);
    //for VoucherBook
    if (!!this.actionDefaultValues.map) {
      if (!!this.actionDefaultValues.map.Limit) {
        this.limit = this.actionDefaultValues.map.Limit;
      }
    }
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
      // console.log("SSSS", this.allPriceNameList)
    }
    !!this.allPriceNameList &&
      this.allPriceNameList.map((p) => {
        if (p.id == 10) {
          this.PricelistKey = p.searchKey;
        }
      });
    // console.log('sss', this.PricelistKey)
  }

  async generateColumns() {
    this.resultColumns = [];
    // if (this.actionName == 'Mix Search' || this.actionName == 'Mix With Me') {
    //   this.showIconColumn = false;
    //   this.resultColumns = [
    //     { name: "Internal Status", prop: "internalStatus" },
    //     { name: "CSSP", prop: "stoneName" },
    //     { name: "Box Name", prop: "Box" },
    //     { name: "Prog Name", prop: "progName" },
    //     { name: "Carat", prop: "cts", summaryType: 'sum' },
    //     { name: "Size", prop: "Size" },
    //     { name: "Shape", prop: "ShapeCode" },
    //     { name: "Color", prop: "ColorCode" },
    //     { name: "Clarity", prop: "ClarityCode" },
    //     { name: "Department", prop: "department" },
    //     { name: "Party Name", prop: "partyName" },
    //     { name: "Hold By", prop: "requestUser" },
    //     { name: "Username", prop: "username" },
    //     { name: "Location", prop: "location" },
    //   ];
    //   if (!!this.actionDefaultValues.map) {
    //     if (!!this.actionDefaultValues.map.priceListName) {
    //       this.priceListMasterId = this.actionDefaultValues.map.priceListName
    //       this.allPriceNameList.filter(el => {
    //         if (el.id == this.actionDefaultValues.map.priceListName) {
    //           this.priceList = el.name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") + 'percarat';
    //           this.discountPercent = el.name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") + 'DiscountPercent';
    //         }
    //       })
    //     }
    //     if (!!this.actionDefaultValues.map.listOfPrices) {
    //       this.displayPriceList = JSON.parse(this.actionDefaultValues.map.listOfPrices)
    //       if (!!this.displayPriceList && this.displayPriceList.length > 0) {
    //         this.displayPriceList.forEach(el => {
    //           let index = this.allPriceNameList.findIndex(item => item.name == el);
    //           if (index > -1) {
    //             this.resultColumns.push({ name: this.allPriceNameList[index].name + " Disc", prop: this.allPriceNameList[index].name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_") + 'DiscountPercent' })
    //             this.resultColumns.push({ name: this.allPriceNameList[index].name + " Price", prop: this.allPriceNameList[index].name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") + 'percarat' })
    //             this.resultColumns.push({ name: this.allPriceNameList[index].name + " Amount", prop: this.allPriceNameList[index].name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_") })
    //           }
    //         })
    //       }
    //     }
    //   }
    // } else {
    this.paramList = await this.getTemplateDataById();
    let parameters = paramViseColumns.parameters;
    if (!!this.paramList && this.paramList.length > 0) {
      for (let i = 0; i < this.paramList.length; i++) {
        let paramName = this.paramList[i]["masterName"];
        let headerName = this.paramList[i]["headerName"];
        let priceListNameMasterId = this.paramList[i]["priceListNameMasterId"];
        let objArray = parameters.filter((d) => d.masterName == paramName);
        if (objArray.length > 0) {
          let resObj = objArray[0]["resultObject"];
          this.resultColumns.push(resObj);
        } else {
          if (!!this.actionDefaultValues.map) {
            if (!!this.actionDefaultValues.map.priceListName) {
              this.priceListName = this.actionDefaultValues.map.priceListName;

              this.priceListMasterId =
                this.actionDefaultValues.map.priceListName;
              this.allPriceNameList.filter((el) => {
                if (el.id == this.actionDefaultValues.map.priceListName) {
                  this.priceList =
                    el.name
                      .replace(/ /g, "")
                      .replace("/", "_")
                      .replace("(", "_")
                      .replace(")", "_")
                      .replace("Natural", "Natural_")
                      .replace("Table", "stoneTable") + "percarat";
                  this.discountPercent =
                    el.name
                      .replace(/ /g, "")
                      .replace("/", "_")
                      .replace("(", "_")
                      .replace(")", "_")
                      .replace("Natural", "Natural_")
                      .replace("Table", "stoneTable") + "DiscountPercent";
                }
              });
              console.log;
            }
            if (!!this.actionDefaultValues.map.listOfPrices) {
              this.displayPriceList = JSON.parse(
                this.actionDefaultValues.map.listOfPrices
              );
              if (!!this.displayPriceList && this.displayPriceList.length > 0) {
                let index = this.allPriceNameList.findIndex(
                  (item) => item.id == priceListNameMasterId
                );
                if (index > -1) {
                  this.resultColumns.push({
                    name: headerName,
                    prop: paramName,
                  });
                }
              }
            }
          }
        }
      }
    }

    // }
  }

  async userData() {
    this.userDetail = await this.authService.userData();
    this.deptName = this.userDetail.departmentName;
    console.log(this.deptName);
  }

  async changeView() {
    if (this.showHits) {
      this.resetSearch();
      this.showHits = false;
      this.inspectionView = false;
    } else {
      this.showHits = true;
      // this.inspectionView = true;
    }
  }

  // changeview() {
  //   if (this.inspectionView) {
  //     this.inspectionView = false;
  //   } else {
  //     this.inspectionView = true;
  //   }
  // }

  inspection(val: boolean) {
    this.inspectionView = val;
  }

  edata(evaldata: string) {
    this.inspectiondata = evaldata;
    console.log(this.inspectiondata);
    this.inspectionviewdata = this.inspectiondata;
    // debugger
  }

  modifiySearch() {
    this.selectedStone = [];
    this.showHits = false;
  }

  async insertSavedSearch() {
    if (!!this.tagName) {
      let objData;
      if (this.criterias.length > 0) {
        let obj = {
          query: {
            bool: {
              should: this.criterias,
            },
          },
        };
        objData = {
          searchTitle: this.tagName,
          searchString: JSON.stringify(obj),
          actionId: this.data.actionId,
        };
      } else {
        objData = {
          searchTitle: this.tagName,
          searchString: JSON.stringify(this.searchQuery(true)),
          actionId: this.data.actionId,
        };
      }
      let res = await this.httpApiService.addSavedSearches(objData);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        if (this.isItemSelected) {
          this.tagName = this.tagName;
        } else {
          this.tagName = "";
        }
        await this.getAllSearches();
      }
    } else {
      await this.configService.showToast("error", "Please add tag name");
    }
    // this.searchHistory.push({id: ++this.counter, name:this.tagName,state: this.states});
    // console.log("search history", this.searchHistory);
  }

  async getAllSearches() {
    let res: any;
    res = await this.httpApiService.findAllSearch(this.data.actionId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.searchHistory = data.data;
    }
  }

  async savedSearch(event) {
    console.log(event.value.searchString);
    try {
      const newObj: any = {};
      newObj["selectedSavedSearch"] = { ...this.selectedSavedSearch };
      this.resetSearch();
      this.tagName = event.value.searchTitle;
      this.isItemSelected = true;
      this.selectedSavedSearch = newObj.selectedSavedSearch;
      this.criterias = [];
      this.getCount(JSON.parse(event.value.searchString), true);
    } catch (e) {
      console.log(e);
    }
  }

  keyUpFunction(event) {
    alert(event);
  }

  async viewDownloadHistory() {
    const modal = await this.modalCtrl.create({
      component: CsvDownloadHistoryPage,
      backdropDismiss: false,
      cssClass: "requestPage",
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
  async viewDownloadHistoryPositionReport(type: any) {
    const modal = await this.modalCtrl.create({
      component: CsvDownloadHistoryPage,
      backdropDismiss: false,
      cssClass: "requestPage",
      componentProps: { searchTitle: "positionReport" },
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
  async viewDownloadHistoryPositionReportMappingFile(type: any) {
    const modal = await this.modalCtrl.create({
      component: ManageInterCsspTransferPage,
      backdropDismiss: false,
      cssClass: "requestPage",
      componentProps: { searchTitle: "positionReportMappingFile" },
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
  async handleModalDismiss(d) { }

  async handleCartModalDismiss(d) {
    this.refreshGrid = true;
    if (!!d.data) this.resetSearch();
  }

  async deleteSaveSearches() {
    console.log(this.selectedSavedSearch);
    if (!!this.selectedSavedSearch) {
      let res: any;
      res = await this.httpApiService.deleteSearch(this.selectedSavedSearch.id);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.selectedSavedSearch = null;
        console.log("search history", data);
        this.resetSearch();
        await this.getAllSearches();
        await this.configService.showToast("success", data.message);
      }
    } else {
      await this.configService.showToast(
        "error",
        "Please select saved search first"
      );
    }
  }

  dropDownSelection(ev) {
    console.log(ev);
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;

    if (property == "exportAs") {
      this.selectedExportedFormat = idList[0];
    } else if (property == "searchHistory") {
      this.selectedSearch = idList[0];
    } else if (property == "defaultTemplate") {
      if (idList.length > 0) {
        this.defaultTemplateId = idList[0];
        this.defaultExportList = obj;
        this.templateId = this.defaultTemplateId;
        // this.userExportList = [];
        this.generateColumns();
      } else {
        this.defaultTemplateId = null;
        this.templateId = null;
      }
    }
  }

  defaultLoad() {
    var mapOfSearchCriteria = {},
      mapOfNumericCriteria = {},
      boolShouldSearchCriteria = {};
    try {
      let qry = this.searchQuery(true);
      let match = qry.query.bool.should
        .map((res) => Object.keys(res)[0])
        .indexOf("bool");
      try {
        qry.query.bool.should[match].bool.must
          .map((res) => res.terms)
          .filter((res) => !!res)
          .filter((res) => {
            mapOfSearchCriteria[Object.keys(res)[0]] = res[Object.keys(res)[0]];
          });
        if (
          !!mapOfSearchCriteria["internalStatus"] &&
          mapOfSearchCriteria["internalStatus"].length > 0
        ) {
        } else {
          mapOfSearchCriteria["internalStatus"] =
            this.searchPara.InternalStatus.map((el) => el.id);
        }
      } catch (e) {
        console.log(e);
      }
      try {
        let tempdList = qry.query.bool.should[match].bool.must.filter((res) => {
          if (!!res && res.bool) {
            return res.bool;
          }
        });
        tempdList.filter((res) => {
          res.bool.should
            .map((res) => res.terms)
            .filter((res) => !!res)
            .filter((res) => {
              boolShouldSearchCriteria[Object.keys(res)[0]] =
                res[Object.keys(res)[0]];
            });
        });
      } catch (e) {
        console.log(e);
      }
      try {
        qry.query.bool.should[match].bool.must
          .map((res) => res.range)
          .filter((res) => !!res)
          .filter((res) => {
            mapOfNumericCriteria[Object.keys(res)[0]] =
              res[Object.keys(res)[0]];
          });
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      console.log(e);
    }
    console.log(
      mapOfSearchCriteria,
      mapOfNumericCriteria,
      boolShouldSearchCriteria
    );
    // return
    return {
      mapOfSearchCriteria,
      mapOfNumericCriteria,
      boolShouldSearchCriteria,
    };
  }

  async openStoneModal(addOrView) {
    document.getElementById("removeFocus").blur();
    await this.configService.showLoader();
    if (!addOrView) {
      if (this.selectedStone.length > 0) {
        this.ids = [];
        this.requestJson = {};
        this.selectedStone.forEach((el, i) => {
          this.ids.push(el.id);
          this.requestJson[el.id] = {
            discount: el[this.discountPercent],
            price: el[this.priceList] ? el[this.priceList].toString() : null,
            rapPrice: !!this.allPriceNameList[0]
              ? !!el[this.allPriceNameList[0].name + "percarat"]
                ? el[this.allPriceNameList[0].name + "percarat"].toString()
                : null
              : 0,
          };
        });
        let objData;
        objData = {
          mapOfStonePriceAndDiscount: this.requestJson,
          transType: this.type,
          limit: this.limit,
          type: this.actionName,
          withMe: this.withMe,
        };
        console.log(objData);
        // API call to add stone to cart & then open manageSearchPage
        let res = await this.httpApiService.addToCart(objData);
        console.log("res", res.status, res.json().error);
        let data = res.json();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          console.log(data.data);
          await this.configService.showToast("error", data.message);
          if (data.data.length > 0) {
            // let excelData = [];
            // data.data.forEach(el => {
            //   if (el.StoneId) {
            //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
            //   }
            // })
            await this.exportAsExcelService.exportAsExcelFile(
              data.data,
              "Error_Stones"
            );
          }
        } else {
          await this.configService.showToast("success", data.message);
        }
      }
      //  else if (!!this.hits && this.hitLength > (!!this.limit ? parseInt(this.limit) : 1000)) {
      //   await this.loadingController.dismiss()
      //   await this.configService.showToast("error", "You are not allowed to add more than " + (!!this.limit ? parseInt(this.limit) : 1000) + " items");
      //   return false;
      // }
      // else {
      //   if (!!this.hits) {
      //     let { mapOfSearchCriteria, mapOfNumericCriteria } = this.defaultLoad();
      //     let stoneList = this.searchPara.stoneId.split("\n")
      //     let stoneIdList = []
      //     stoneList.forEach(el => {
      //       if (el.indexOf(",") > 0) {
      //         let data1 = el.split(',')
      //         let data2 = []
      //         data1.forEach(el => {
      //           data2.push(el.trim())
      //         })
      //         Array.prototype.push.apply(stoneIdList, data2);
      //       } else {
      //         stoneIdList.push(el)
      //       }
      //     })
      //     stoneIdList = stoneIdList.filter(function (el) {
      //       return el != "";
      //     });
      //     let jsonObj = {
      //       setOfStoneNames: stoneIdList,
      //       mapOfSearchCriteria: mapOfSearchCriteria,
      //       mapOfNumericCriteria: mapOfNumericCriteria,
      //       priceListMasterId: this.priceListMasterId,
      //       transType: this.type,
      //       limit: this.limit,
      //       withMe: this.withMe,
      //       type: this.actionName
      //     }
      //     let res = await this.httpApiService.addToCartByFilterCriteria(jsonObj);
      //     let data = res.json();
      //     if (res.status == 401) {
      //       this.authService.logout();
      //     } else if (res.status == 500) {
      //       await this.configService.showToast("error", data.error);
      //     } else if (res.status == 400) {
      //       console.log(data.data)
      //       // await this.configService.showToast("error", data.error);
      //       await this.configService.showToast("error", data.message);
      //       if (!!data.data && data.data.length > 0) {
      //         // let excelData = [];
      //         // data.data.forEach(el => {
      //         //   if (el.StoneId) {
      //         //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
      //         //   }
      //         // })
      //         await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
      //       }
      //     } else {
      //       await this.configService.showToast("success", data.error);
      //     }
      //   }
      // }
    }
    this.selectedStone = [];
    this.refreshGrid = false;
    //document.getElementById("gridContainer").classList.remove("dx-checkbox-checked");
    const modal = await this.modalCtrl.create({
      component: ManageSearchPage,
      cssClass: "requestPage",
      backdropDismiss: false,
      componentProps: {
        actionId: this.data.actionId,
        actionName: this.data.desc,
        view: "Detail",
        priceListMasterId: this.priceListMasterId,
        displayPriceList: this.displayPriceList,
        stoneID: this.ids,
        type: this.type,
        withMe: this.withMe,
      },
    });
    await this.loadingController.dismiss();
    modal.onDidDismiss().then((d: any) => this.handleCartModalDismiss(d));
    return await modal.present();
  }

  async openFormForSVC(requestFor, type) {
    if (requestFor == "Unhold Customer" || type == "Convert Hold To Ship") {
      let allStoneHoldNotExist = this.selectedStone.find(
        (el) => el.internalStatus != "Hold Customer"
      );
      if (!!allStoneHoldNotExist) {
        await this.configService.showToast(
          "error",
          "One or more stones have different status than Hold Customer"
        );
        return false;
      }
    }
    let listOfActions = await this.storage.get("listOfActions");
    let actionExist = listOfActions.find(
      (action) => action.actionName == "SVC Single Search"
    );
    let stones = [];
    this.selectedStone.forEach((el, i) => {
      this.ids.push(el.id);
      stones.push({
        stoneId: el.id.toString(),
        size: el.cts,
        rapPrice: el["RAPAPORTpercarat"],
        requestDisc: el[this.discountPercent],
        requestPrice: el[this.priceList] ? el[this.priceList].toString() : null,
        stoneRemarks: "",
      });
    });

    document.getElementById("removeFocus").blur();
    await this.configService.showLoader();
    this.refreshGrid = false;
    const modal = await this.modalCtrl.create({
      component: SvcActionsPage,
      cssClass: "requestPage",
      backdropDismiss: false,
      componentProps: {
        actionId: actionExist.id,
        actionName: this.data.desc,
        view: "Detail",
        selectedStone: stones,
        requestFor: requestFor,
        type: this.type,
        withMe: this.withMe,
        convertToShip:
          this.data.desc == "SVC Request List Search" ? true : false,
      },
    });
    await this.loadingController.dismiss();
    modal.onDidDismiss().then((d: any) => this.handleSVCModalDismiss(d));
    return await modal.present();
  }

  handleSVCModalDismiss(d) {
    this.refreshGrid = true;
    if (!!d.data) this.resetSearch();
    // this.selectedStone = []
  }

  async defaultExport() {
    let jsonObj;
    let stoneList = this.searchPara.stoneId.split("\n").filter(el => !!el);
    let stoneIdList = [];
    stoneList.forEach((el) => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(",");
        let data2 = [];
        data1.forEach((el) => {
          data2.push(el.trim());
        });
        Array.prototype.push.apply(stoneIdList, data2);
      } else {
        stoneIdList.push(el);
      }
    });
    stoneIdList = stoneIdList.filter(function (el) {
      return el != "";
    });
    let obj = this.exportList.find((x) => x.id == this.defaultTemplateId);
    let priceArr = [];
    if (!!obj && !!obj.priceList) {
      this.displayPriceList.forEach((el) => {
        let isExist = obj.priceList.includes(el);
        if (isExist) {
          priceArr.push(el);
        }
      });
    }
    if (priceArr.length == 0) {
      priceArr = this.displayPriceList;
    }
    if (this.defaultTemplateId) {
      if (!!this.selectedStone && this.selectedStone.length > 0) {
        jsonObj = {
          setOfStoneIds: this.selectedStone.map((el) => el.id),
          priceListMasterIds: priceArr,
          templateId: this.defaultTemplateId,
          startDate: moment.utc(new Date()).local().format("YYYYMMDD_hhmm"),
        };
      } else {
        if (stoneIdList.length > 0) {
          jsonObj = {
            setOfStoneNames: stoneIdList,
            priceListMasterIds: priceArr,
            templateId: this.defaultTemplateId,
            queryString: JSON.stringify(this.searchQuery(true).query),
            startDate: moment.utc(new Date()).local().format("YYYYMMDD_hhmm"),
          };
        } else {
          if (this.criterias.length > 0) {
            let obj = {
              query: {
                bool: {
                  should: this.criterias,
                },
              },
            };
            jsonObj = {
              setOfStoneNames: [],
              mapOfSearchCriteria: null,
              mapOfNumericCriteria: null,
              boolShouldSearchCriteria: null,
              priceListMasterIds: priceArr,
              queryString: JSON.stringify(obj.query),
              templateId: this.defaultTemplateId,
              startDate: moment.utc(new Date()).local().format("YYYYMMDD_hhmm"),
            };
          } else {
            let {
              mapOfSearchCriteria,
              mapOfNumericCriteria,
              boolShouldSearchCriteria,
            } = this.defaultLoad();
            jsonObj = {
              setOfStoneNames: stoneIdList,
              mapOfSearchCriteria: mapOfSearchCriteria,
              mapOfNumericCriteria: mapOfNumericCriteria,
              boolShouldSearchCriteria: boolShouldSearchCriteria,
              priceListMasterIds: priceArr,
              queryString: JSON.stringify(this.searchQuery(true).query),
              templateId: this.defaultTemplateId,
              startDate: moment.utc(new Date()).local().format("YYYYMMDD_hhmm"),
            };
          }

        }
        if (
          !!jsonObj.mapOfSearchCriteria &&
          !!jsonObj.mapOfNumericCriteria &&
          Object.keys(jsonObj.mapOfSearchCriteria).length === 0 &&
          jsonObj.mapOfSearchCriteria.constructor === Object &&
          Object.keys(jsonObj.mapOfNumericCriteria).length === 0 &&
          jsonObj.mapOfNumericCriteria.constructor === Object
        ) {
          console.log("object is empty!");
          let transTypeArray = {
            transType: ["SS", "MIX"],
          };
          jsonObj["mapOfSearchCriteria"] = transTypeArray;
        }
      }
      if (this.customerSaleMarkUp) {
        jsonObj.markUpBack = this.customerSaleMarkUp;
      }

      if (this.isInwardSearch) {
        jsonObj.elasticIndexArray = this.index.split(",");
      }
      console.log(jsonObj);
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.exportTemplate(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      console.log(data);
      await this.configService.showToast("success", data.message);
      // window.open(this.baseUrl + data.data.filePath.substr(1), '')
      // this.defaultExportList = []
      // this.defaultTemplateId = null;
    } else {
      await this.configService.showToast(
        "error",
        "Please select default template"
      );
    }
  }
  async defaultExportPostionReport(uniqueByStatus) {
    let jsonObj;
    let stoneList = this.searchPara.stoneId.split("\n");
    let stoneIdList = [];
    stoneList.forEach((el) => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(",");
        let data2 = [];
        data1.forEach((el) => {
          data2.push(el.trim());
        });
        Array.prototype.push.apply(stoneIdList, data2);
      } else {
        stoneIdList.push(el);
      }
    });
    stoneIdList = stoneIdList.filter(function (el) {
      return el != "";
    });
    let obj = this.exportList.find((x) => x.id == this.defaultTemplateId);
    let priceArr = [];
    if (!!obj && !!obj.priceList) {
      this.displayPriceList.forEach((el) => {
        let isExist = obj.priceList.includes(el);
        if (isExist) {
          priceArr.push(el);
        }
      });
    }
    if (priceArr.length == 0) {
      priceArr = this.displayPriceList;
    }
    if (this.defaultTemplateId) {
      if (!!this.selectedStone && this.selectedStone.length > 0) {
        jsonObj = {
          setOfStoneIds: this.selectedStone.map((el) => el.id),
          priceListMasterIds: priceArr,
          templateId: this.defaultTemplateId,
          startDate: moment.utc(new Date()).local().format("YYYYMMDD_hhmm"),
        };
      } else {
        if (stoneIdList.length > 0) {
          jsonObj = {
            setOfStoneNames: stoneIdList,
            priceListMasterIds: priceArr,
            templateId: this.defaultTemplateId,
            queryString: JSON.stringify(this.searchQuery(true).query),
            startDate: moment.utc(new Date()).local().format("YYYYMMDD_hhmm"),
          };
        } else {
          let {
            mapOfSearchCriteria,
            mapOfNumericCriteria,
            boolShouldSearchCriteria,
          } = this.defaultLoad();
          jsonObj = {
            setOfStoneNames: stoneIdList,
            mapOfSearchCriteria: mapOfSearchCriteria,
            mapOfNumericCriteria: mapOfNumericCriteria,
            boolShouldSearchCriteria: boolShouldSearchCriteria,
            priceListMasterIds: priceArr,
            queryString: JSON.stringify(this.searchQuery(true).query),
            templateId: this.defaultTemplateId,
            startDate: moment.utc(new Date()).local().format("YYYYMMDD_hhmm"),
          };
        }
        if (
          !!jsonObj.mapOfSearchCriteria &&
          !!jsonObj.mapOfNumericCriteria &&
          Object.keys(jsonObj.mapOfSearchCriteria).length === 0 &&
          jsonObj.mapOfSearchCriteria.constructor === Object &&
          Object.keys(jsonObj.mapOfNumericCriteria).length === 0 &&
          jsonObj.mapOfNumericCriteria.constructor === Object
        ) {
          console.log("object is empty!");
          let transTypeArray = {
            transType: ["SS", "MIX"],
          };
          jsonObj["mapOfSearchCriteria"] = transTypeArray;
        }
      }
      if (this.customerSaleMarkUp) {
        jsonObj.markUpBack = this.customerSaleMarkUp;
      }
      if (uniqueByStatus) {
        jsonObj.uniqueByStatus = true;
      }
      console.log(jsonObj);
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.exportPositionReport(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      console.log(data);
      await this.configService.showToast("success", data.message);
      // window.open(this.baseUrl + data.data.filePath.substr(1), '')
      // this.defaultExportList = []
      // this.defaultTemplateId = null;
    } else {
      await this.configService.showToast(
        "error",
        "Please select default template"
      );
    }
  }
  async getAllTemplates() {
    let userTem, defaultTemp: any;
    userTem = await this.httpApiService.findAllTemplate(false);
    defaultTemp = await this.httpApiService.findAllTemplate(true);
    let data = userTem.json();
    let data1 = defaultTemp.json();
    if (userTem.status == 401) {
      this.authService.logout();
    } else if (userTem.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (userTem.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data);
      // if (!this.hideDefaultTemplates) {
      this.exportList = this.exportList.concat(data1.data);
      // }
      this.exportList = this.exportList.concat(data.data);
      console.log(this.exportList);
      await this.getDefaultValues();
    }
  }

  onClickToOpenVideo(ev) {
    if (!!ev.row.data.location && ev.row.data.location == "mumbai") {
      window.open(
        `https://www.kgdiamonds.com/products/${ev.row.data.stoneName}/${ev.row.data.stoneName}/${ev.row.data.currentLocation}`,
        "_blank"
      );
    } else if (!!ev.row.data.kgStoneId) {
      window.open(
        `https://www.kgdiamonds.com/products/${ev.row.data.stoneName}/${ev.row.data.stoneName}/${ev.row.data.currentLocation}`,
        "_blank"
      );
    } else if (!!ev.row.data.ReportNo) {
      window.open(
        `https://video.diamlist.com/VideoPage.aspx?stoneid=${ev.row.data.ReportNo}`,
        "_blank"
      );
    } else if (!!ev.row.data.ImageUrl) {
      window.open(ev.row.data.ImageUrl, "_blank");
    } else {
      this.configService.showToast("error", "Image not found");
    }
  }

  async onClickToOpenTag(ev) {
    if (!!ev.row.data.id) {
      const modal = await this.modalCtrl.create({
        component: ManageSearchPage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: {
          actionId: this.data.actionId,
          actionName: this.data.desc,
          view: "tags",
          stoneID: ev.row.data.id,
        },
      });
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async showMoreParameters(ev) {
    if (!!ev.row.data.id) {
      this.displayStoneName = ev.row.data.stoneName;
      let res: any;
      res = await this.httpApiService.getStoneDetailById(ev.row.data.id);
      let data = res.json();
      this.keys = [];
      let dataValue = data.data;
      if (dataValue) {
        for (let key in dataValue) {
          if (key != "Image Url") {
            this.keys.push({ key: key, value: dataValue[key] });
          }
        }
      }
      this.menu.open("stoneDetail");
    }
  }

  closeSideMenu() {
    this.menu.close();
  }

  //open email to customer

  async openEmailToCustomerAction(data) {
    const modal = await this.modalCtrl.create({
      component: EmailToCustomerPage,
      backdropDismiss: false,
      cssClass: "emailToCustomer",
      componentProps: {
        selectedRows: this.selectedStone.map((el) => el.stoneName),
        showParty: !!this.svcDataOption ? false : true,
      },
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async openPdFilter() {
    const modal = await this.modalCtrl.create({
      component: PdFiltersComponent,
      backdropDismiss: false,
      cssClass: "pd-filter",
      componentProps: {
        filteredPD: this.filteredPD,
      },
    });
    modal.onDidDismiss().then((d: any) => this.handlePdFilterDismiss(d));
    return await modal.present();
  }

  handlePdFilterDismiss(d) {
    if (d.data) {
      this.filteredPD = d.data;
      this.getCount(this.searchQuery(true));
    }
  }

  async scanHere() {
    this.boxDetail = [];
    this.scanCount = 0;
    this.stoneNotFoundRfidList = [];
    this.sysStoneList = [];
    this.stoneFound = 0;
    let res;
    if (!!this.boardBaseUrl) {
      await this.configService.showLoader();
      await this.httpApiService.scanLedOff(this.boardBaseUrl, [1]);
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      console.log(data);
      if (data.type == "error") {
        this.configService.dismiss();
        await this.configService.showToast("error", "Scanner Not Found");
      } else {
        if (data.result.length > 0) {
          await this.configService.showToast(
            "success",
            `Scan Completed successfully`
          );
          data.result = data.result.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          });
          this.scanCount = data.result.length;
          console.log(this.scanCount, "count");
          //get stoneid using refid
          let jsonObj;
          jsonObj = {
            paramName: "rfid",
            setOfValues: data.result,
            companyId: this.companyId,
          };
          let res2 = await this.httpApiService.getStoneIdByRfid(jsonObj);
          let resData = res2.json();
          this.boxDetail = resData.data.boxRfIdsList;
          this.sysStoneList = resData.data.list;
          this.stoneFound = resData.data.list.length;
          if (!!this.sysStoneList && this.sysStoneList.length > 0) {
            this.searchPara.stoneId = this.sysStoneList
              .map((el) => el.stoneName)
              .join("\n");
          }
          if (this.boxDetail.length > 0) {
            this.scanCount = this.scanCount - this.boxDetail.length;
          }
          if (this.sysStoneList.length > 0) {
            data.result.filter((el) => {
              let isExist = this.sysStoneList.find(
                (item) => el == (!!item.paramValue ? item.paramValue : null)
              );
              if (!isExist) {
                this.stoneNotFoundRfidList.push(el);
              }
            });
            if (this.boxDetail.length > 0) {
              this.stoneNotFoundRfidList = this.stoneNotFoundRfidList.filter(
                (rf) => this.boxDetail.filter((bd) => bd.rfid == rf).length == 0
              );
            }
            if (this.stoneNotFoundRfidList.length > 0) {
              await this.httpApiService.scanWithLightOn(
                this.boardBaseUrl,
                this.stoneNotFoundRfidList
              );
            }
          } else {
            await this.httpApiService.scanWithLightOn(
              this.boardBaseUrl,
              data.result
            );
          }
          this.viewScanResult(this.boxDetail);

          this.configService.dismiss();
          // } else {
          //   if (resData.type == "error") {
          //     this.configService.dismiss();
          //     await this.configService.showToast("error", data.type);
          //   }
          // }
          this.searchQuery();
        } else {
          await this.configService.showToast(
            "error",
            `Stones not found on board`
          );
        }
        this.configService.dismiss();
      }
    } else {
      await this.configService.showToast(
        "error",
        "Scanner Board Url not found"
      );
    }
  }

  async reScan() {
    let res;
    this.ledOnList = [];
    this.rledOnList = [];
    this.scanCount = 0;
    this.sysStoneList = [];
    this.stoneFound = 0;
    if (!!this.boardBaseUrl) {
      await this.configService.showLoader();
      await this.httpApiService.scanLedOff(this.boardBaseUrl, [1]);
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      console.log(data);
      if (data.type == "error") {
        this.configService.dismiss();
        await this.configService.showToast("error", "Scanner Not Found");
      } else {
        if (data.result.length > 0) {
          await this.configService.showToast(
            "success",
            `Scan Completed successfully`
          );
          data.result = data.result.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          });
          this.scanCount = data.result.length;
          //get stoneid using refid
          let jsonObj;
          jsonObj = {
            paramName: "rfid",
            setOfValues: data.result,
            companyId: this.companyId,
          };
          let res2 = await this.httpApiService.getStoneIdByRfid(jsonObj);
          let resData = res2.json();
          this.boxDetail = resData.data.boxRfIdsList;
          resData.data.list.filter((el) => {
            let isExist = this.searchPara.stoneId
              .split("\n")
              .find((item) => el.stoneName == item);
            if (isExist) {
              this.ledOnList.push(el.paramValue);
            }
          });
          // let rleddList = []
          resData.data.list.filter((el) => {
            let isExist = this.searchPara.stoneId
              .split("\n")
              .find((item) => el.stoneName == item);
            if (!isExist) {
              this.rledOnList.push(el.paramValue);
            }
          });
          this.stoneFound = this.ledOnList.length;
          this.viewScanResult(this.boxDetail);
          // if (this.ledOnList.length > 0) {
          //   this.stoneFound = this.ledOnList.length;
          //   await this.httpApiService.scanWithLightOn(this.boardBaseUrl, this.ledOnList);
          // }
        } else {
          await this.configService.showToast(
            "error",
            `Stones not found on board`
          );
        }
        this.configService.dismiss();
      }
    } else {
      await this.configService.showToast(
        "error",
        "Scanner Board Url not found"
      );
    }
  }

  async ledOn() {
    if (!!this.boardBaseUrl) {
      await this.httpApiService.scanLedOff(this.boardBaseUrl, [1]);
      await this.configService.showLoader();
      if (this.ledOnList.length > 0) {
        await this.httpApiService.scanWithLightOn(
          this.boardBaseUrl,
          this.ledOnList
        );
      }
      await this.configService.dismiss();
    } else {
      await this.configService.showToast(
        "error",
        "Scanner Board Url not found"
      );
    }
  }

  async reledOn() {
    if (!!this.boardBaseUrl) {
      await this.httpApiService.scanLedOff(this.boardBaseUrl, [1]);
      await this.configService.showLoader();
      if (this.rledOnList.length > 0) {
        await this.httpApiService.scanWithLightOn(
          this.boardBaseUrl,
          this.rledOnList
        );
      }
      await this.configService.dismiss();
    } else {
      await this.configService.showToast(
        "error",
        "Scanner Board Url not found"
      );
    }
  }

  async ledOff() {
    if (!!this.boardBaseUrl) {
      await this.httpApiService.scanLedOff(this.boardBaseUrl, [1]);
    } else {
      await this.configService.showToast(
        "error",
        "Scanner Board Url not found"
      );
    }
  }

  async viewScanResult(boxDetail) {
    const modal = await this.modalCtrl.create({
      component: ViewScanResultPage,
      componentProps: {
        boxDetail: boxDetail,
        scanCount: this.scanCount,
        stoneFound: this.stoneFound,
      },
      cssClass: "scannerResult",
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    setTimeout(() => {
      this.searchQuery();
    }, 100);
  }

  async openProgramManager() {
    const modal = await this.modalCtrl.create({
      component: ProgramManagementPage,
      componentProps: {
        selectedData: this.selectedStone,
      },
      cssClass: "requestPage",
    });
    modal.onDidDismiss().then((d: any) => this.handleProgramModalDismiss(d));
    return await modal.present();
  }

  async handleProgramModalDismiss(d) {
    this.refreshGrid = true;
    if (!!d.data) this.resetSearch();
  }

  selectParty(ev) {
    console.log(ev);
    if (!!ev.value && ev.value.length > 0) {
      let partyFilters = ev.value.map((el) => el.label);
      this.toggleSelection(
        this.searchPara.partyName,
        partyFilters,
        "partyName"
      );
    }
  }

  async confirmBlackListStone() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: `Are you sure you want to ${this.blackListed ? "UNDO the" : ""
        } blackList the selected Stones?`,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.blackListTheStones();
          },
        },
      ],
    });

    await alert.present();
  }

  async blackListTheStones() {
    let res: any;
    let payLoad = {
      listOfStones: this.selectedStone.map(
        (el) => el.stoneName + "_" + el.partyMasterId
      ),
      isRejected: !this.blackListed,
    };
    console.log(payLoad);
    res = await this.httpApiService.blackListStones(payLoad);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.resetSearch();
      if (!!data.data && data.data.length > 0) {
        this.exportAsExcelService.exportAsExcelFile(data.data, "Error_Stones");
      }
    }
  }

  // purchase customer mark up

  onChangeParty(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (!!event.value) {
      this.customerSaleMarkUp = event.value.salesMarkUp;
    } else {
      this.customerSaleMarkUp = null;
    }
  }

  async searchParty(event: {
    component: IonicSelectableComponent;
    text: string;
  }) {
    let searchText = event.text.trim().toLowerCase();
    await this.getAllCustomer(0, 10, searchText);
  }

  async getAllCustomer(pageNumber, noOfRecords, searchText) {
    let res: any;
    let json = {
      listOfType: ["Export", "Import", "Local Sales"],
    };
    res = await this.httpApiService.findByListOfPartyTypePagination(
      json,
      "Client",
      pageNumber,
      noOfRecords,
      searchText
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.content) {
        let dataValueList = data.data.content;
        if (dataValueList.length > 0) {
          for (let i = 0; i < dataValueList.length; i++) {
            dataValueList[
              i
            ].partyNameWithType = `${dataValueList[i].partyName} ( ${dataValueList[i].type} )`;
          }
          this.listOfParties = dataValueList;
        } else {
          this.listOfParties = [];
        }
      }
    }
  }

  async getPriceFromDB(setOfStoneIds) {
    let res: any;
    let payload = {
      setOfStoneIds: setOfStoneIds,
    };
    res = await this.httpApiService.getPrice(payload);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      return data.data;
    }
  }

  async updateIdsThroughWorker(setOfStoneIds) {
    let res: any;
    let payload = {
      setOfStoneIds: setOfStoneIds,
    };
    res = await this.httpApiService.saveIdsInWorker(payload);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      return data.data;
    }
  }

  async loadInspectView() {
    if (!this.inspectionView) {
      this.searchPageButtons = this.searchPageAllButtons.filter(
        (x) => x.name != "List View"
      );
    } else {
      this.searchPageButtons = this.searchPageAllButtons.filter(
        (x) => x.name != "Virtual Inspection"
      );
    }
  }

  uploadBtnInPR(type: any) {
    console.log(type + "clicked");
  }

  fileChangeEvent(event: any, flag: any): void {
    let payload = {
      file: event,
      flag: flag,
    };
    this.eventFromPR.emit(payload);
  }
  virtualInspection(e: Event) {
    this.mobileView = false;
    this.inspectionView = true;
  }
  selectedStonesForInspection(e: any[]) {
    this.selectedInspectionStones = e;
    // console.log(this.selectedInspectionStones);
  }

  goBackToStoneList(e: Event) {
    this.inspectionView = false;
  }

  shareSelectedStones() {
    if (!!this.selectedStone.length && this.selectedStone.length <= 10) {
      let completeMsg = "";
      this.selectedStone.forEach((stoneData: any) => {
        const message = this.encode_data(stoneData);

        if (completeMsg === "") {
          completeMsg = decodeURIComponent(message);
        } else {
          completeMsg =
            completeMsg +
            "\n" +
            "**********" +
            "\n" +
            decodeURIComponent(message);
        }
      });
      this.checkClipboardPermission(completeMsg);
      // this.copyToClipboard(completeMsg);
    } else {
      this.configService.showToast(
        "error",
        "Maximum 10 stones details can be copied"
      );
    }
  }

  copyToClipboard(data: string) {
    (navigator as any).clipboard
      .writeText(data)
      .then((clipboardRes) => {
        this.configService.showToast(
          "success",
          "Stone(s) details copied successfully"
        );
      })
      .catch((clipboardErr) => {
        this.configService.showToast(
          "error",
          "Unable to copy Stone(s) details"
        );
      });
  }

  checkClipboardPermission(completeMsg: string) {
    this.copyToClipboard(completeMsg);
    // (navigator as any).permissions
    //   .query({ name: 'clipboard-write' as PermissionName })
    //   .then((result: any) => {
    //     if (result.state === 'granted' || result.state == 'prompt') {
    //       this.copyToClipboard(completeMsg);
    //     }
    //      else {
    //       this.configService.showToast(
    //         'error',
    //         'Your browser does not allow copying',
    //       );
    //     }
    //   })
    //   .catch(err => {
    //     this.configService.showToast(
    //       'error',
    //       err,
    //     );
    //   });
  }

  encode_data(stoneData: any) {
    return encodeURIComponent(`
${!!stoneData.stoneName ? stoneData.stoneName : "-"}  ${!!stoneData.ReportNo ? stoneData.ReportNo : "-"
      }
${!!stoneData.cts ? stoneData.cts : "-"}  ${!!stoneData.ColorCode ? stoneData.ColorCode : "-"
      }  ${!!stoneData.ClarityCode ? stoneData.ClarityCode : "-"}  ${!!stoneData.ShapeCode ? stoneData.ShapeCode : "-"
      }  ${!!stoneData.lab ? stoneData.lab : "-"}
    
${!!stoneData.Rapnet_plusDiscountPercent
        ? stoneData.Rapnet_plusDiscountPercent
        : "-"
      }  ${!!stoneData.Rapnet_pluspercarat ? stoneData.Rapnet_pluspercarat : "-"}  ${!!stoneData.Rapnet_plus ? stoneData.Rapnet_plus : "-"
      }$
    
${!!stoneData.CutCode ? stoneData.CutCode : "-"}  ${!!stoneData.PolishCode ? stoneData.PolishCode : "-"
      }  ${!!stoneData.SymmetryCode ? stoneData.SymmetryCode : "-"}  ${!!stoneData.FluorescenceCode ? stoneData.FluorescenceCode : "-"
      }
    
Location: ${!!stoneData.location ? stoneData.location.toUpperCase() : "-"}
    
Diamond Details
${!!stoneData.ImageUrl ? stoneData.ImageUrl : "-"}
 
Diamond Certificate
${!!stoneData.ReportNo
        ? `https://media.diamlist.com/certificate/${stoneData.lab}/${stoneData.ReportNo}.pdf`
        : "-"
      }
    
Diamond Unbranded Video
https://video.diamlist.com/video/${stoneData.stoneName}
    `);
  }

  async getAllStatus() {
    let res: any;
    res = await this.httpApiService.getStatus();
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout();
    } else if (data.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (data.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfStatus = data.data;
    }
  }

  // Open request form for block

  async getDefaultSettingForQueuedAction(actionId) {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", data);
      if (!!dataValue.map) {
        if (!!dataValue.map.isAllowedStatus) {
          let statuslistFromDefaults = JSON.parse(
            dataValue.map.isAllowedStatus
          );
          let availableStatusList = this.listOfStatus.filter(
            (item) =>
              statuslistFromDefaults.filter((el) => el == item.id).length > 0
          );
          this.allowStatusList = availableStatusList.map((el) => el.name);
        }
      }
    }
  }

  async verifyQueuedAction() {
    let listOfActions = await this.storage.get("listOfActions");
    let actionExist = listOfActions.find(
      (action) => action.actionName == "Queued Action"
    );
    if (actionExist) {
      await this.getDefaultSettingForQueuedAction(actionExist.id);
    }
    if (this.allowStatusList) {
      return this.selectedStone.filter((item) =>
        this.allowStatusList.includes(item.internalStatus)
      );
    } else {
      return this.selectedStone;
    }
  }

  async openRequestFormForQueuedAction() {
    let stones = [];
    this.selectedStone = await this.verifyQueuedAction();
    if (this.selectedStone && this.selectedStone.length > 0) {
      this.selectedStone.forEach((el, i) => {
        this.ids.push(el.id);
        stones.push({
          id: el.id,
          size: el.cts,
          rapPrice: el["RAPAPORTpercarat"],
          requestDisc: el[this.discountPercent],
          requestPrice: el[this.priceList] || el["RAPAPORTpercarat"] || null,
          stoneRemarks: "",
        });
      });
      console.log(stones);

      this.refreshGrid = false;
      let modalPage = await this.modalCtrl.create({
        component: BlockForSaleRequestFormPage,
        backdropDismiss: false,
        componentProps: {
          data: stones,
          actionId: this.data.actionId,
          actionType: "Request From Queued Action",
        },
        cssClass: "requestPage",
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modalPage.present();
    } else {
      await this.configService.showToast(
        "error",
        "Stone current status is not allowed to do Queued action"
      );
    }
  }

  ngOnDestroy() {
    this.resetSearchpara();
  }

  async resetSearchpara() {
    console.log("Resetting search parameters...");
    this.searchPara = {
      shapes: [],
      colors: [],
      clarities: [],
      cuts: [],
      polishes: [],
      symmetries: [],
      descriptions: [],
      finishings: [],
      fluorescences: [],
      labs: [],
      fancyColorIntensities: [],
      selectedFancyColorIntensity: "",
      fancyColorOvertones: [],
      selectedFancyColorOvertone: "",
      fancyColors: [],
      selectedFancyColor: "",
      // lusters:[],
      // selectedLuster:"",
      // naturals:[],
      // selectedNatural:"",
      saveSearchName: "",
      fromCarat: null,
      toCarat: null,
      stoneId: "",
      certNo: "",
      stoneIdORCertSelected: "stoneId",
      colorType: "white",
      caratType: "specific",
      clarityType: "basic",
      finishingType: "general",
      shapeType: "standard",
      tablePerFrom: "",
      tablePerTo: "",
      depthPerFrom: "",
      depthPerTo: "",
      lengthFrom: "",
      lengthTo: "",
      widthFrom: "",
      widthTo: "",
      depthFrom: "",
      depthTo: "",
      ratioFrom: "",
      ratioTo: "",
      crownAngleFrom: "",
      crownAngleTo: "",
      crownHeightFrom: "",
      crownHeightTo: "",
      girdles: [],
      selectedGirdle: "",
      pavilionAngleFrom: "",
      pavilionAngleTo: "",
      pavilionDepthFrom: "",
      pavilionDepthTo: "",
      pdObject: {},
      InternalStatus: [],
      TransType: [],
      Users: [],
      Parties: [],
      Location: [],
      standardShapeCode: [],
      Box: [],
      Department: [],
      fm: [],
      fromCertiDate: "",
      toCertiDate: "",
      fromInwardDate: "",
      toInwardDate: "",
      fromTransactionDate: "",
      toTransactionDate: "",
      fromSoldDate: "",
      toSoldDate: "",
    };
    this.selectedSavedSearch = null;
    this.showHits = false;
    this.tagName = "";
    this.firstLoad = false;
    this.customerSelection = "";
    this.isAvailableForSale = false;
    this.blackListed = false;
    this.addSoldMustNotQuery = false;
    this.selectedParty = null;
    this.isItemSelected = false;
    await this.clearSelectionArrays()
    await this.cd.detectChanges();
    console.log("Change detection complete");
  }
}
