import { Component, Input } from "@angular/core";
import {
  ModalController,
  AlertController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageUserPage } from "../manage-user/manage-user.page";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"]
})
export class UserComponent implements ProcessActionComponent {
  @Input() data: any;

  public getAllUserData: any;
  public manageSelectedUser: "";
  public selectedUser: "";
  public items: any;
  public id: any;
  public arrowKeyLocation = -1;
  public fullName = "";
  public mobileNumber = "";
  public email: "";
  public companyName = "";
  public listOfRoles: any = [];
  public flgBtn = "";
  public appName = "";
  public userData: any;
  public username = "";
  public listOfActions: any = [];
  public listOfVoucherBook;
  public type: any;
  public dept: any;
  public deptList: any;
  public allDeptidList: any = [];
  public scanBoardUrl: any;
  public itemActions = [];
  public tab: any = "userDetail";
  public selectedAction = ""
  public selectedUserId: any;
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public stoneHoldMaxDays = null;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) {
    this.getAppName();
  }

  async getAllActions(id) {
    let res: any;
    console.log("this.userData.userId", id);
    res = await this.httpApiService.getAllActionsByUserID(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfActions = data.data;
      this.setActionItems()
      console.log("list of actions by http", res, this.listOfActions);
      // if (this.listOfActions.voucherType) {
      //   this.type = this.listOfActions.voucherType;
      //   console.log("type is", this.type);
      //   let res2: any;
      //   res2 = this.httpApiService.getAllVouchersForSelectedType(this.type);
      //   this.listOfVoucherBook = res.json().data;
      //   console.log("listOfVoucherBook", this.listOfVoucherBook);
      // }
    }
  }
  ngOnInit() {
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    this.selectedUser = "";
    this.getAllUser();
    this.getAllDepartment();
    this.event.subscribe('Users', (item) => {
      this.getAllUser();
      this.getAllDepartment();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Users');
  }

  changeSegment(event) {
    this.tab = event.detail.value;
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "allDepartment") {
      if (idList.length > 0) {
        this.dept = idList[0];
      } else {
        this.dept = null;
      }
    }
  }

  async deleteUserDefault(actionId, userId) {
    console.log("userId", userId);
    console.log("actionId", actionId);
    let res = await this.httpApiService.deleteUserDefault(userId, actionId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.searchUser(this.selectedUser);
      await this.configService.showToast(
        "success",
        "User default deleted successfully"
      );
    }
  }

  async getAppName() {
    this.appName = await this.configService.getAppName();
    console.log(this.appName);
  }

  setItems() {
    this.items = this.getAllUserData;
  }

  getUserDetail(username) {
    this.searchUser(username);
  }

  async searchUser(userName) {
    this.flgBtn = ""
    await this.configService.showLoader();
    let tmpUserName = "";
    this.items = this.getAllUserData;
    if (!!userName) {
      tmpUserName = userName;
      this.manageSelectedUser = userName;
      this.selectedUser = userName;
    } else {
      this.selectedUser = this.manageSelectedUser;
      tmpUserName = this.manageSelectedUser;
    }
    let selectedActionId = this.items.filter(function (search) {
      if (search.username == tmpUserName) {
        return search.id;
      }
    });
    if (selectedActionId.length > 0) {
      let id = selectedActionId[0].id;
      let res: any;
      res = await this.httpApiService.getUserDetailById(id);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.userData = data.data;
        this.selectedUser = this.userData.username;
        this.selectedUserId = this.userData.id;
        this.fullName = this.userData.fullName;
        this.mobileNumber = this.userData.mobileNumber;
        this.scanBoardUrl = this.userData.scanBoardUrl;
        this.companyName = this.userData.companyName;
        this.stoneHoldMaxDays = !!this.userData.stoneHoldMaxDays ? this.userData.stoneHoldMaxDays : null;
        this.email = this.userData.email;
        if (this.userData.departmentId) {
          this.allDeptidList = [];
          this.deptList.filter(el => {
            if (el.id == this.userData.departmentId) {
              let data = { id: el.id, name: el.name };
              this.allDeptidList.push(data);
            }
          });
        }
        console.log("!!!", this.allDeptidList);
        this.dept = this.userData.departmentId;
        this.id = this.userData.id;
        await this.getAllActions(this.id);
        this.username = this.userData.username;
        if (this.userData.listOfRoles && this.userData.listOfRoles.length > 0) {
          this.listOfRoles = this.userData.listOfRoles;
        } else {
          this.listOfRoles = [];
        }
        let index = this.items.findIndex(
          x => x.username == selectedActionId[0].username
        );
        this.arrowKeyLocation = index;
        console.log(this.userData);
      }
    } else {
      await this.configService.dismiss();
      this.selectedUser = "";
      this.companyName = "";
      this.fullName = "";
      this.companyName = "";
      this.mobileNumber = "";
      this.username = "";
      this.email = "";
      this.listOfRoles = [];
    }
  }

  async getAllUser() {
    let res: any;
    let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllUser(fromLocation);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllUserData = data.data;
      this.items = this.getAllUserData;
      console.log("get All User Data", this.getAllUserData);
    }
  }

  async filterItems(ev: any) {
    this.selectedUser = "";
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        return item.username.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocation = -1;
    } else {
      this.arrowKeyLocation = -1;
      this.items = this.getAllUserData;
    }
  }

  async setActionItems() {
    this.itemActions = this.listOfActions
  }

  async filterActionItems(ev: any) {
    this.setActionItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.itemActions = await this.itemActions.filter(function (item) {
        return item.actionName.toLowerCase().includes(val.toLowerCase());
      });
    } else {
    }
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedUser = this.items[
              this.arrowKeyLocation
            ].username;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedUser = this.items[
              this.arrowKeyLocation
            ].username;
            break;
          } else {
            break;
          }
        }
    }
  }

  //open model for add user's role

  async addRoleForUser(id, flgModal) {
    console.log(flgModal);
    if ((flgModal == 'addUser' || flgModal == 'addUserRole') && this.addPermissionFlag == true) {
      let modalPage = await this.modalCtrl.create({
        component: ManageUserPage,
        backdropDismiss: false,
        cssClass: 'largePage',
        componentProps: {
          id: id,
          username: this.selectedUser,
          selectedUserId: this.selectedUserId,
          flgModal: flgModal,
          //voucherBook: this.listOfVoucherBook
        }
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      await modalPage.present();
    }
    if (flgModal == 'updateUserDefault' && this.updatePermissionFlag == true) {
      let modalPage = await this.modalCtrl.create({
        component: ManageUserPage,
        backdropDismiss: false,
        cssClass: 'largePage',
        componentProps: {
          id: id,
          username: this.selectedUser,
          flgModal: flgModal,
          selectedUserId: this.selectedUserId
          //voucherBook: this.listOfVoucherBook
        }
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      await modalPage.present();
    }


  }

  async getAllDepartment() {
    let res: any;
    res = await this.httpApiService.getAllDepartment(
      this.configService.appName
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.deptList = data.data;
      console.log("deptList", this.deptList);
    }
  }

  async handleModalDismiss(d) {
    this.selectedAction = ''
    if (d.data != 'doNotRefresh') {
      if (d.data && d.data == 'addUserRole') {
        await this.getAllActions(this.id);
      } else {
        this.searchUser(this.selectedUser);
        this.getAllUser();
      }
    }
  }
  //delete user role

  async deleteUserRole(id) {
    console.log(id);
    let res: any;
    let jsonObj = {
      userId: this.id,
      roleId: id
    };
    console.log(jsonObj);
    res = await this.httpApiService.deleteUserRole(jsonObj);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast(
        "success",
        "User role deleted successfully"
      );
      this.searchUser(this.selectedUser);
    }
  }

  //update

  async showButton(name) {
    if (name == "fullName") {
      this.flgBtn = "fullName";
    } else if (name == "userName") {
      this.flgBtn = "userName";
    } else if (name == "email") {
      this.flgBtn = "email";
    } else if (name == "phone") {
      this.flgBtn = "phone";
    } else if (name == "department") {
      this.flgBtn = "department";
    } else if (name == "scannerBoardUrl") {
      this.flgBtn = "scannerBoardUrl";
    } else if (name == "stoneHoldMaxDays") {
      this.flgBtn = "stoneHoldMaxDays";
    } else {
      this.flgBtn = "companyName";
    }
  }

  async updateUser() {
    let validate = await this.validateControls();
    console.log(this.dept);
    if (!validate) {
      let jsonObj = {
        username: this.username,
        companyName: this.companyName,
        appName: this.appName,
        fullName: this.fullName,
        mobileNumber: this.mobileNumber,
        email: this.email,
        departmentId: this.dept,
        scanBoardUrl: this.scanBoardUrl,
        stoneHoldMaxDays: this.stoneHoldMaxDays || null,
      };
      let res: any;
      let id = this.id;
      res = await this.httpApiService.updateUser(id, jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.flgBtn = "";
        await this.getAllUser();
        await this.searchUser(this.username);
        await this.configService.showToast(
          "success",
          "User updated successfully"
        );
      }
    }
  }

  async validateControls() {
    if (
      this.username != "" &&
      this.appName != "" &&
      this.fullName != "" &&
      this.companyName != "" &&
      this.dept != null &&
      this.dept != undefined &&
      this.dept != ""
    ) {
      return false;
    } else {
      await this.configService.showToast("error", "Please insert the detail");
      return true;
    }
  }
}
