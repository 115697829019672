import { Component, Input } from "@angular/core";
import {
  ModalController,
  AlertController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { ManageLabPacketServiceMasterPage } from "../manage-lab-packet-service-master/manage-lab-packet-service-master.page";

@Component({
  selector: 'app-lab-packet-service-master',
  templateUrl: './lab-packet-service-master.component.html',
  styleUrls: ['./lab-packet-service-master.component.scss']
})
export class LabPacketServiceMasterComponent implements ProcessActionComponent {
  @Input() data: any;

  public refreshGrid: Boolean = false;
  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 50;
  public items = []
  public count = 0;
  public columns = [];
  public seriesData = [];
  public page = "Lab Packet Series Master";

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) { }


  ngOnInit() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "Lab", prop: "partyName" },
    { name: "Shape", prop: "shapeName" },
    { name: "From Size", prop: "fromSize" },
    { name: "To Size", prop: "toSize" },
    { name: "Max Packet No.", prop: "maxPktNo" },
    { name: "Series Name", prop: "seriesName" },
    { name: "isActive", prop: "isActive" },
    { name: "Date & Time", prop: "createdDate" }
    ]
    this.fetchLabPacketsSeries()
    this.event.subscribe('Lab Packet Series Master', (item) => {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Lab", prop: "partyName" },
      { name: "Shape", prop: "shapeName" },
      { name: "From Size", prop: "fromSize" },
      { name: "To Size", prop: "toSize" },
      { name: "Max Packet No.", prop: "maxPktNo" },
      { name: "Series Name", prop: "seriesName" },
      { name: "isActive", prop: "isActive" },
      { name: "Date & Time", prop: "createdDate" }
      ]
      this.fetchLabPacketsSeries()
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Lab Packet Series Master');
  }

  async manageLabPacketSeries(item, flag) {
    this.refreshGrid = false
    if (flag == 'update') {
      let modal = await this.modalCtrl.create({
        component: ManageLabPacketServiceMasterPage,
        cssClass: "largePage",
        componentProps: { item: item, flag: flag, }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    } if (flag == 'add') {
      let modal = await this.modalCtrl.create({
        component: ManageLabPacketServiceMasterPage,
        cssClass: "largePage",
        componentProps: { item: item, flag: flag, }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async fetchLabPacketsSeries() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.getLabPacketSeriesData();
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.seriesData = data.data;
    }
  }

  async handleModalDismiss(d) {
    await this.fetchLabPacketsSeries();
    this.refreshGrid = true
  }

  async deleteItem(ev) {
    this.confirmDeleteData(ev.row.data);
  }

  async onselect(event) {
    if (event.selectedRowsData.length > 0) {
      this.manageLabPacketSeries(event, 'update')
    }
  }

  async confirmDeleteData(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteData(item.id);
          }
        }
      ]
    }); await alert.present();
  }

  async deleteData(id) {
    await this.configService.showLoader();
    let res = await this.httpApiService.deleteLabPacketSeries(
      id
    );
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.message);
    } else if (!data.isSuccess) {
      await this.configService.showToast(
        "error",
        data.data
      );

    } else {
      await this.configService.showToast(
        "success",
        data.data
      );
    }
    await this.fetchLabPacketsSeries()
  }

}
