export default {
  reportNo: 'R-30',
  url: "master-service/api/report/assortment-report-mix",
  method: "GET",
  size: 3,
  fields: [
  ],
  columns: [
  ],
  dataStudioLink: 'https://lookerstudio.google.com/embed/reporting/4bb5d1ba-d988-443b-b6fa-13af20ba4d3c/page/mFMtD'
};

