import { Component, OnInit, Input } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { SearchService } from "src/app/services/search/search.service";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-finalize-pprice',
  templateUrl: './finalize-pprice.component.html',
  styleUrls: ['./finalize-pprice.component.scss']
})
export class FinalizePPriceComponent implements ProcessActionComponent {
  @Input() data: any;
  stonesList = []
  public columns: any = [];
  public selected: any = [];
  public refreshGrid: boolean = false;
  public inputList: any;
  public allPriceNameList = [];
  public pricelistName = '';
  constructor(private es: SearchService,
    public httpApiService: HttpApiService,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events) { }

  async ngOnInit() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, {
      name: 'Stone Id', prop: 'stoneName'
    },
    { name: 'Color', prop: 'ColorCode' },
    { name: 'Clarity', prop: 'ClarityCode' },
    { name: 'Shape', prop: 'ShapeCode' },
    { name: 'Size', prop: 'cts' },
    { name: 'Lab', prop: 'lab' },
    { name: 'P Price Available', prop: 'isPPricePresent' },
    { name: 'Rapaport', prop: 'RAPAPORTpercarat' },
    ]
    await this.getAllPriceListName()
    await this.getDefaultSetting()
    this.event.subscribe('Finalize P Price', (item) => {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      }, {
        name: 'Stone Id', prop: 'stoneName'
      },
      { name: 'Color', prop: 'ColorCode' },
      { name: 'Clarity', prop: 'ClarityCode' },
      { name: 'Shape', prop: 'ShapeCode' },
      { name: 'Size', prop: 'cts' },
      { name: 'Lab', prop: 'lab' },
      { name: 'P Price Available', prop: 'isPPricePresent' },
      { name: 'Rapaport', prop: 'RAPAPORTpercarat' },
      ]
      this.getAllPriceListName()
      this.getDefaultSetting()
    })
  }


  ngOnDestroy() {
    this.event.unsubscribe('Finalize P Price')
  }

  async fetch() {
    let qry: any =
    {
      "query": {
        "bool": {
          "should": [
            {
              "simple_query_string": {
                "query": ""
              }
            },
            {
              "bool": {
                "must": []
              }
            }
          ]
        }
      }
    }

    let tmp = this.inputList.split("\n");
    let boolShouldMultiple = {
      "bool": {
        "should": []
      }
    };
    let location = {
      "terms": { location: ["mumbai"] }
    }
    qry.query.bool.should[1].bool.must.push(location);
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i] != '') {
        let prefixObj: any = {};
        prefixObj =
        {
          "prefix": {
            "stoneName": tmp[i].trim()
          }
        }
        boolShouldMultiple.bool.should.push(prefixObj);
      }
    }
    qry.query.bool.should[1].bool.must.push(boolShouldMultiple);

    let data = await this.es.getPaginatedDocuments(qry, 0, environment.INDEX, '', 500)
    data = data.hits.hits.map(d => d._source).map(res => {
      let stoneId = res.stoneName;
      if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
        let index = stoneId.split('/', 3).join('/').length;
        res['stoneName'] = String(stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))));
      } else {
        res['stoneName'] = String(res['stoneName']);
      }
      return res;
    })

    this.stonesList = data;

    // let dObj ={
    //   'stoneData':data,
    //   'stickerType':this.stickerType
    // }
    // this.modalCtrl.dismiss(dObj);
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
    }
  }


  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (!!dataValue.map && !!dataValue.map.priceListName) {
        this.allPriceNameList.filter(el => {
          if (el.id == dataValue.map.priceListName) {
            this.pricelistName = el.name;
            this.columns.push({ name: el.name + " Disc", prop: el.name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_") + 'DiscountPercent' })
            this.columns.push({ name: el.name + " Price", prop: el.name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") + 'percarat' })
            this.columns.push({ name: el.name + " Amount", prop: el.name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_") })
          }
        })
      }
    }
  }

  async OnSelectRows(event) {
    this.selected = event.selectedRowsData
  }


  async savePPrice() {
    let formData: any = {};
    this.configService.showLoader()
    this.selected.forEach((el, i) => {
      formData[el.id] = {
        "PPrice Disc": el[this.pricelistName.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_") + 'DiscountPercent'],
        "PPrice per cts": el[this.pricelistName.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") + 'percarat'],
        "PPrice Amt": el[this.pricelistName.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_")],
      };
    });
    let res: any;
    res = await this.httpApiService.savePPrice(
      { stoneRequestParams: formData }
    );
    let data = res.json();
    this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.selected = [];
      this.stonesList = [];
      this.inputList = ''
    }
  }
}
