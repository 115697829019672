import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: 'app-mix-pricing-calc',
  templateUrl: './mix-pricing-calc.component.html',
  styleUrls: ['./mix-pricing-calc.component.scss']
})
export class MixPricingCalcComponent implements ProcessActionComponent {
  @Input() data: any;

  public transType = 'MIX';

  constructor() { }

  ngOnInit() {
  }

}
