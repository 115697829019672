import { Component, Input, OnInit } from '@angular/core';
import { ProcessActionComponent } from '../../processAction';
import { DomSanitizer } from "@angular/platform-browser";


@Component({
  selector: 'app-sales-comparison-report',
  templateUrl: './sales-comparison-report.component.html',
  styleUrls: ['./sales-comparison-report.component.scss']
})
export class SalesComparisonReportComponent implements ProcessActionComponent {
  @Input() data: any;

  constructor(public sanitizer: DomSanitizer) { }

  dataStudioLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://lookerstudio.google.com/embed/reporting/ae2ec184-54ad-445b-ad7b-da818a051ad0/page/mspRD');

  ngOnInit() {
  }

}
