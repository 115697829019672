import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IonIntlTelInputService } from '../../actions/ion-intl-tel-input/controllers/ion-intl-tel-input.service';

@Component({
  selector: 'app-international-codes',
  templateUrl: './international-codes.component.html',
  styleUrls: ['./international-codes.component.scss']
})
export class InternationalCodesComponent implements OnInit {
  countries: any = [];
  @Output() selectedCountry: EventEmitter<any> = new EventEmitter();
  constructor(private ionIntlTelInputService: IonIntlTelInputService) { }

  ngOnInit() {
    this.fetchAllCountries();
// console.log('\x1b[41m%s\x1b[0m', 'countries: ', this.countries);
  }
  private fetchAllCountries() {
    this.countries = this.ionIntlTelInputService.getListOfCountries();
  }
  
  dropDownSelection(ev) {
    console.log(ev);
    if(ev.arrayList.length > 0){
      let x = ev.arrayList[0].name;
      let filteredCode = this.countries.filter((country:any)=>{
        return country.name === x
      })
      // console.log('\x1b[41m%s\x1b[0m', 'filter: ', filteredCode)
      this.selectedCountry.emit(filteredCode);
    }else{
      // console.log('\x1b[41m%s\x1b[0m', 'nothing: ')
      this.selectedCountry.emit('none');

    }
  }
}
