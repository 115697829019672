import { Component, OnInit } from '@angular/core';
import {
  ModalController,
  NavParams,
  LoadingController,
  Events,
  ToastController
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { IonicSelectableComponent } from "ionic-selectable";
import { SearchService } from "src/app/services/search/search.service";
import { StonegenerationbuyingpurposeComponent } from '../stonegenerationbuyingpurpose/stonegenerationbuyingpurpose.component';

@Component({
  selector: 'app-stone-generationbuyinglist',
  templateUrl: './stone-generationbuyinglist.component.html',
  styleUrls: ['./stone-generationbuyinglist.component.scss']
})
export class StoneGenerationbuyinglistComponent implements OnInit {
  columns = [];
  dataSource= [];
  dataColumns = [];
  buyingPurpose;
  prefix;
  id;
  currentStoneNumber;
  public defaultParam = ['buyingPurpose','prefix'];

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public toastCtrl: ToastController,
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public event: Events,
    public loadingController: LoadingController
  ) { 
    
  }


  ngOnInit() {
   this.fetch();
     
}
  logEvent(ev) {
    console.log(ev);
  }
  onRowRemoving(ev) {
    console.log("row removing");
    
  }
  onRowRemoved(ev) {
    console.log(ev);
    console.log("row removed");
    let id = ev.data.id;
    this.deleteData(id);
    console.log(id);
  }
  onInserted(ev) {
    console.log(ev);
    this.buyingPurpose = ev.data.buyingPurpose;
    this.prefix = ev.data.prefix;
  
  this.addStoneBuyingPurpose();
    // if (Object.keys(ev.data).length > 1) {

    // }
  }
  onEditingStart(ev) {
  
  
    console.log("editing start");
  }
  onRowUpdating(ev) {
  
  }
  

  onRowUpdated(ev) {
    console.log(ev);
    this.id = ev.data.id;
    this.prefix = ev.data.prefix;
    // console.log(this.id);
    // console.log(this.prefix);
     this.updateStone();
     this.openModal();
    
    
    
    
  }
  
  async openModal() {
    console.log("calling modal")
    const modal = await this.modalCtrl.create({
      component: StonegenerationbuyingpurposeComponent,
      cssClass: 'small-modal',
      

    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss());
    return await modal.present();
  
  }

  async handleModalDismiss() {

  }
  
  onRowUpdate(ev) {
    
    console.log("update.....")
  }
  
  closeModal() {
    this.modalCtrl.dismiss()
  
  }
  async updateStone() {
    let res: any;
    
    let jsonObj = {
      "prefix": this.prefix,
    }
    console.log(jsonObj)

    res = await this.httpApiService.updateStoneBuyingPurpose(this.id, jsonObj);
    console.log(this.id);

    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
      
    }
    else {
      
       await this.fetch();
      await this.configService.showToast('success', data.message);
      // await this.modalCtrl.dismiss();
    }
    
  }
  async deleteData(id) {
    await this.configService.showLoader();
    let res = await this.httpApiService.deleteStonebyBudgetPurpose(id);
    console.log(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.message);
    } else if (!data.isSuccess) {
      await this.configService.showToast(
        "error",
        data.data
      );

    } else {
      await this.configService.showToast(
        "success",
        data.data
      );
    }

  }
  async addStoneBuyingPurpose() {
    await this.configService.showLoader();
    let res: any;
    let cList = []
    this.dataSource.forEach(el => {
      let obj = {
        buyingPurpose: el.buyingPurpose,

      }
      cList.push(obj)
    })
    console.log(cList);

    let jsonObj = {
      "buyingPurpose": this.buyingPurpose,
      "prefix": this.prefix,

    }
    console.log(this.currentStoneNumber);
    res = await this.httpApiService.addStoneBuyingPurpose(jsonObj);

    let data = res.json();

    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);

    }
    else {
      await this.fetch();
      await this.configService.showToast('success', data.message);
      await this.modalCtrl.dismiss();
    }
  

  }

  async stoneIdGeneration() {
    await this.configService.showLoader();
    let res: any;
    let cList = []
    this.dataSource.forEach(el => {
      let obj = {
        buyingPurpose: el.buyingPurpose,

      }
      cList.push(obj)
    })
  

    let jsonObj = {
      "buyingPurpose":"M",
    }
  
    res = await this.httpApiService.generateBuyingPurposeStoneId(jsonObj);

    let data = res.json();
    console.log(data.data);

    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);

    }
    else {
      // await this.fetch();
      await this.configService.showToast('success', data.message);
      // await this.modalCtrl.dismiss();
  
    
    }
  
  }

  // f(){
  //   console.log("stone id")
  //   this.stoneIdGeneration();
  // }

  async fetch() {

    let res: any;
    res = await this.httpApiService.fetchStonebybuyingpurposeList();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.dataSource = data.data;
      this.dataColumns = [];
      this.defaultParam.forEach(el => {
        
        {
          if (el == '') {
            this.dataColumns.push({
              name: el,
              dataSource: [],
              entityType: '',
              isAllow: false
            })
          }
          else {
            this.dataColumns.push({
              name: el,
              dataSource: [],
              entityType: '',
              isAllow: true
            })
          }
        }
      })
      console.log(this.dataColumns);
    }

    console.log(data);
  }


}
