import { Component, Input, HostListener, ViewChild, ElementRef } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { LoadingController, ModalController, AlertController, Events, IonInput } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { SearchService } from "src/app/services/search/search.service";
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-rfid-history',
  templateUrl: './rfid-history.component.html',
  styleUrls: ['./rfid-history.component.scss']
})
export class RfidHistoryComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild('searchInput') sInput;

  public boardBaseUrl: any;
  public rfid: any;
  public stoneId: any;
  public barcode: any;
  public page = 'RFID History';
  public columns = [];
  public rfidHistoryList = [];
  constructor(
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public configService: ConfigService,
    public event: Events,
    private es: SearchService,
  ) {
    this.event.subscribe('RFID History', (item) => {
      setTimeout(() => {
        this.sInput.setFocus();
      }, 800);
      this.rfidHistoryList = [];
      this.stoneId = null;
      this.rfid = null;
      this.barcode = null;
    })
  }

  ngOnInit() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, { name: "Transaction", prop: "transaction" },
    { name: "Action", prop: "action" },
    { name: "StoneId", prop: "stoneName" }, { name: "RFID", prop: "paramValue" },
    { name: "Remarks", prop: "remark" },
    { name: "Created Date", prop: "createdAt" }, { name: "Created By", prop: "createdBy" }];
  }

  async fetch() {
    if (!!this.rfid || !!this.stoneId) {
      await this.configService.showLoader()
      let res: any;
      res = await this.httpApiService.getRFIDHistory(this.stoneId, this.rfid);
      let data = res.json();
      await this.loadingController.dismiss()
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
      } else {
        this.rfidHistoryList = data.data;
      }
    } else {
      await this.configService.showToast("error", 'Please scan RFID or add manually to check status.');
    }
  }

}
