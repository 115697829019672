import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpApiService } from 'src/app/services/http-api/http-api.service';
import { ProcessActionComponent } from '../../processAction';
import * as moment from 'moment';

@Component({
  selector: 'app-logistics-approval',
  templateUrl: './logistics-approval.component.html',
  styleUrls: ['./logistics-approval.component.scss']
})
export class LogisticsApprovalComponent implements ProcessActionComponent {
  @Input() data: any;
  date: any = moment().format('YYYY-MM-DD');
  isDateSelected: boolean = true;
  listOfVouchers: any[] = [];
  selectedIds: any[] = [];
  selectedTransportIdList = [];
  transporterDetails = [];
  selectedVouchers: string = '';
  transporterName: string = '';
  voucherBookIds: string = '';
  constructor(
    public httpApiService: HttpApiService,
    private authService: AuthenticationService,
    public configService: ConfigService
  ) { }

  ngOnInit() {
    this.getDefaultSetting();
    this.getTransporterDetail('Transport')
  }

  async getTransporterDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.transporterDetails = data.data;
    }
  }

  async getVouchersByDate() {
    let res: any;
    res = await this.httpApiService.getVouchersByDate(
      this.date,
      this.voucherBookIds
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data && data.data.length > 0) {
        this.listOfVouchers = data.data.map((item, index) => ({ id: index, name: item }));
      }
      console.log("listOfVouchers", this.listOfVouchers);
    }
  }


  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "voucherSelection") {
      if (idList.length > 0) {
        this.selectedVouchers = obj.map(el => el.name).join(',');
      } else {
        this.selectedVouchers = '';
        this.selectedIds = []
      }
    }
    if (property == "transportMode") {
      if (idList.length > 0) {
        this.transporterName = obj[0].partyName;
      } else {
        this.transporterName = null;
      }
    }
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", data);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherbookIds) {
          this.voucherBookIds = dataValue.map.voucherbookIds;
          this.getVouchersByDate()
        }
      }
    }
  }

  onDateSelected() {
    this.isDateSelected = !!this.date;
    this.listOfVouchers = [];
    this.selectedIds = [];
    this.selectedVouchers = '';
    this.getVouchersByDate()

  }
  downloadPdf() {
    this.logisticsPdf(this.date);
  }

  async logisticsPdf(date: any) {
    let res: any = await this.httpApiService.logisticsPdf(date, this.selectedVouchers, this.transporterName,this.voucherBookIds);
    let base64String = JSON.parse(res._body).data;
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", res.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", JSON.parse(res._body).error);
    } else {
      await this.downloadPdfFromBase64(base64String);
      await this.configService.showToast("success", 'SUCCESSFULLY DOWNLOADED LOGISTICS APPROVAL LETTER');
    }
  }

  async downloadPdfFromBase64(base64String: any) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = 'Logistics_Approval_Letter.pdf';
    link.click();
  }
}