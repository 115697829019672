import { Component, OnInit, HostListener } from "@angular/core";
import {
  ModalController,
  LoadingController,
  ToastController,
  NavParams,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import * as moment from "moment";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";

@Component({
  selector: "app-manage-certification",
  templateUrl: "./manage-certification.page.html",
  styleUrls: ["./manage-certification.page.scss"]
})
export class ManageCertificationPage implements OnInit {
  public RFidList: any;
  public filtered: any = [];
  public listParameter: any;
  public selectedParameterList = [];
  public flag: any;
  public selectedFile: File = null;
  public paraForRecheck = [];
  public servicesList: any;
  public selectedServices: any;
  public data: any;
  public voucherBookId: any;
  public actionId: any;
  public userId: any;
  public getLabId: any;
  public date: any;
  public certificateNo: any;
  public arr1: any;
  public count: any;
  public listOfActions = [];
  public selectedServicesList: any;
  public diffParameterList = [];
  public title: any;
  public userData: any;
  public action: any;
  public voucherDate: any;
  public voucherType: any;
  public page = 'manageCertificationFile'
  public listOfSoldStones = [];
  public voucherId: any;
  public columns: any;
  public boardBaseUrl: any;
  public listOfLabs = [];
  public labId: any;
  public pageNumber = 0;
  public noOfRecords = 20;
  public pageSize = 20;
  public historyData = [];
  public historyDataSource: any = {};
  public totalPages: Number = 0;
  public url: any;
  public partyType: any;
  public multipleChallanList = [];
  public flagOpenDoc = 'stoneDetail';
  public optionPO: any;
  public DCList = []
  public fxRate = 0;
  public modeOfTransport = '';
  public transType: any;
  public remarks: any;
  public terms: any;
  public disc1: any;
  public disc2: any;
  public companyDetails: any;
  public customerDetails: any;
  public nameOfPersonReceivingGood: any;
  public idDcAvailable = false;
  public showComparision = false;
  public companyId: any;
  public invoiceNo = null;
  public invoiceDate = null;

  constructor(
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public toastCtrl: ToastController,
    public authService: AuthenticationService,
    public configService: ConfigService,
    public navParams: NavParams,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService,
  ) { }

  async ngOnInit() {
    this.companyId = await this.authService.getCompanyId()
    this.historyData = [];
    await this.getUserData();
    this.voucherDate = moment().format('YYYY-MM-DD')
    this.flag = await this.navParams.get("type");
    this.title = await this.navParams.get("title");
    this.partyType = await this.navParams.get('partyType')
    if (!!this.partyType && this.partyType.length > 0) {
      await this.getAllLabs()
    }
    let data = await this.navParams.get("data");
    this.data = await this.navParams.get("data");
    console.log(data)
    if (data) {
      this.voucherId = data.id
    }
    console.log(this.flag, this.voucherId);
    console.log(this.title);
    await this.getAllActions();
    // await this.getParameter();
    // await this.getLab();
    if (this.flag == 2) {

      this.actionId = this.data.actionId;
      this.getLabId = this.data.labId;
      // await this.getAllAdditionalServices();
      await this.getAllAdditionalServicesByLabId(this.getLabId)
      await this.getDataForComparison(this.data.itemData.id);

      console.log(this.actionId, this.getLabId, this.data.itemData);
    }
    else if (this.flag == 4) {
      this.voucherId = this.data.itemData.id;
      this.invoiceNo = !!this.data.itemData.voucherLabel ? this.data.itemData.voucherLabel : null;
      if (!!this.data.itemData.accInvoiceDate) {
        this.invoiceDate = moment(this.data.itemData.accInvoiceDate).format('YYYY-MM-DD');
      }
      this.columns = [
        {
          name: "Sr No", cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          }
        },
        { name: "StoneId", prop: "stoneId" },
        { name: "Rate per carat", prop: "requestPrice" },
        { name: "Cts", prop: "stoneCts" },
      ]
      await this.getCompanyDetail()
      await this.getAllSoldStoneByVoucherId();
      console.log(data)
      if (data.itemData.requestFor && data.itemData.referenceVoucherId) {
        this.idDcAvailable = true;
        await this.findAllTransferRequestedByVoucherIdForDC(data.itemData.referenceVoucherId, data.itemData.requestFor)
      }
      if (data.itemData.partyMasterId) {
        await this.getCustomerDetails(data.itemData.partyMasterId);
        this.nameOfPersonReceivingGood = data.itemData.customerContact
      }
    } else if (this.flag == 6) {
      this.columns = [
        { name: "File Name", prop: "fileName" },
        { name: "Description", prop: "description" },
        { name: "Date & Time", prop: "createdDate" },
        { name: "By", prop: "username" },
        { name: "Status", prop: "status" }
      ];
      this.action = 'Certification Upload'
      await this.loadCertificationFileUploadHistory(this.action, this.pageNumber, this.noOfRecords)
    } else if (this.flag == 7) {
      this.columns = [
        { name: "File Name", prop: "fileName" },
        { name: "Description", prop: "description" },
        { name: "Date & Time", prop: "createdDate" },
        { name: "By", prop: "username" },
        { name: "Status", prop: "status" },
        //{ name: "Comparison File", prop: "fileName" }
      ];
      this.showComparision = true;
      this.action = 'Certification Upload'
      await this.loadCertificationFileUploadHistory(this.action, this.pageNumber, this.noOfRecords)
    } else if (this.flag == 8) {
      this.columns = [
        { name: "File Name", prop: "fileName" },
        { name: "Description", prop: "description" },
        { name: "Date & Time", prop: "createdDate" },
        { name: "By", prop: "username" },
        { name: "Status", prop: "status" }
      ];
      this.action = 'Certification Action Upload'
      await this.loadCertificationFileUploadHistory(this.action, this.pageNumber, this.noOfRecords)
    } else if (this.flag == 9) {
      this.columns = [
        { name: "File Name", prop: "fileName" },
        { name: "Description", prop: "description" },
        { name: "Date & Time", prop: "createdDate" },
        { name: "By", prop: "username" },
        { name: "Status", prop: "status" }
      ];
      this.action = 'Lab Actual Expenses Upload'
      await this.loadCertificationFileUploadHistory(this.action, this.pageNumber, this.noOfRecords)
    } else if (this.flag == 111) {
      this.columns = [
        { name: "File Name", prop: "fileName" },
        { name: "Description", prop: "description" },
        { name: "Date & Time", prop: "createdDate" },
        { name: "By", prop: "username" },
        { name: "Status", prop: "status" }
      ];
      this.action = this.title
      await this.loadCertificationFileUploadHistory(this.action, this.pageNumber, this.noOfRecords)
    }
  }


  // Additional lab services by lab id

  async getAllAdditionalServicesByLabId(labId) {
    let res = await this.httpApiService.getAllAdditionalServicesByLabId(labId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.servicesList = data.data;
      // this.items = this.listOfParameter;
      console.log("get All stone param Data", this.servicesList);
      console.log("QQQQQQQQQQ", this.data)
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == '') {
      this.selectedServices = idList;
    } else if (property == 'labList') {
      if (idList.length > 0) {
        this.labId = idList[0]
      } else {
        this.labId = null;
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event.keyCode)
    if (event.keyCode == 27) {
      this.modalCtrl.dismiss(this.flag);
    }
  }

  //certification file upload history data

  async loadCertificationFileUploadHistory(action, pageNumber, noOfRecords) {
    // let res: any;
    // res = await this.httpApiService.getUploadHistory(
    //   action,
    //   pageNumber,
    //   noOfRecords
    // );
    // let data = res.json();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   let dataValue = data.data;
    //   console.log(data);
    //   this.historyData = dataValue.content;
    //   this.totalPages = dataValue.totalPages;
    //   this.count = dataValue.totalElements;
    // }
    this.historyDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getUploadHistory(
            action,
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.historyData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.historyData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async setPageHistory(currentPage) {
    this.pageNumber = currentPage.offset;
    this.loadCertificationFileUploadHistory(this.action, currentPage.offset, this.noOfRecords);
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
  }



  async getAllLabs() {
    let json = {
      listOfType: this.partyType
    }
    let res: any;
    res = await this.httpApiService.findByListOfPartyType(json, 'Job');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLabs = data.data;
    }
  }

  async getAllSoldStoneByVoucherId() {
    let res: any;
    this.userData = await this.authService.userData();
    res = await this.httpApiService.getAllConfirmedStonesWithStatus(this.voucherId, 'Sold Confirmed');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfSoldStones = data.data.content;
      if (!!data.data.url) {
        this.url = data.data.url;
      }
      console.log(this.listOfSoldStones)
      this.count = this.listOfSoldStones.length;
    }
  }

  async downloadPackingList() {
    await this.httpApiService.downloadPackingList(this.url)
  }


  async getVoucherBook() {
    let actionArray = this.listOfActions.filter(function (item) {
      if (item.actionName == "Print") {
        return item.actionName;
      }

    });
    console.log("****", actionArray)
    if (actionArray.length > 0) {
      this.voucherType = actionArray[0].voucherType;
      await this.getDefaultSetting(this.userData.userId, actionArray[0].id);
    }
  }

  async printLabReceieve() {
    await this.getVoucherBook();
    if (this.voucherBookId != null && this.voucherBookId != undefined && this.voucherBookId != '') {
      await this.configService.showLoader();
      let val = [];
      val.push(this.data.itemData.stoneId);
      let dataObj = {
        setOfValues: val,
        toRole: this.data.itemData.roleId,
        voucherBookId: this.voucherBookId,
        voucherDate: moment().format("YYYY-MM-DD"),
        companyId: this.companyId
      };
      console.log(dataObj)
      let res = await this.httpApiService.returnStone(dataObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 200) {
        await this.configService.showToast("success", data.message);
        await this.closeModal();
      }
    } else {
      await this.configService.showToast('error', 'Voucher book not found.')
    }
  }

  async getAllActions() {
    await this.configService.showLoader()
    let res: any;
    this.userData = await this.authService.userData();
    res = await this.httpApiService.getAllActionsByUserID(this.userData.userId);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfActions = data.data;
      console.log("list of actions by http", res, this.listOfActions);
      if (this.title == "Sold") {
        let actionArray = this.listOfActions.filter(function (item) {
          if (item.actionName == "Sold") {
            return item.actionName;
          }
        });
        if (actionArray.length > 0) {
          this.voucherType = actionArray[0].voucherType;
          this.getDefaultSetting(this.userData.userId, actionArray[0].id);
        }
      } else if (this.title == 'return') {
        let actionArray = this.listOfActions.filter(function (item) {
          if (item.actionName == "Return") {
            return item.actionName;
          }
        });
        if (actionArray.length > 0) {
          this.voucherType = actionArray[0].voucherType;
          this.getDefaultSetting(this.userData.userId, actionArray[0].id);
        }
      }
    }
  }

  async scanHere() {
    this.RFidList = ''
    this.filtered = [];
    let res;
    if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
      await this.configService.showLoader();
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      console.log(data);
      if (data.type == "error") {
        this.configService.dismiss();
        await this.configService.showToast("error", data.type);
        if (
          this.RFidList != null &&
          this.RFidList != "" &&
          this.RFidList != undefined
        ) {
          this.arr1 = this.RFidList.split("\n");
          this.count = this.arr1.length;
        } else {
          this.count = 0;
          this.RFidList = "";
        }
      } else {
        if (data.result.length > 0) {
          if (
            this.RFidList != null &&
            this.RFidList != "" &&
            this.RFidList != undefined
          ) {
            this.arr1 = this.RFidList.split("\n");
            let data1 = await this.merge_array(this.arr1, data.result);
            this.count = data1.length;
            this.RFidList = data1.join("\n");
          } else {
            this.count = data.result.length;
            this.RFidList = data.result.join("\n");
          }
        } else {
          if (
            this.RFidList != null &&
            this.RFidList != "" &&
            this.RFidList != undefined
          ) {
            this.arr1 = this.RFidList.split("\n");
            this.count = this.arr1.length;
          } else {
            this.count = 0;
            this.RFidList = "";
          }
        }
        this.configService.dismiss();
      }
    } else {
      await this.configService.showToast("error", "Scanner Board Url not found");
    }

  }

  async merge_array(array1, array2) {
    let result_array = [];
    let arr = array1.concat(array2);
    let len = arr.length;
    let assoc = {};

    while (len--) {
      let item = arr[len];

      if (!assoc[item]) {
        result_array.unshift(item);
        assoc[item] = true;
      }
    }

    return result_array;
  }

  async saveRfId() {
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let dataObj = {
        setOfValues: this.filtered,
        voucherBookId: this.voucherBookId,
        remarks: "Return Stone",
        type: this.voucherType,
        voucherDate: this.voucherDate,
        companyId: this.companyId
      };
      let res = await this.httpApiService.returnStone(dataObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (!!data.data && data.data.length > 0) {
          await this.configService.showToast("error", data.message);
          await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        } else {
          await this.configService.showToast("error", 'Error occoured while returning stones.');
        }

      } else if (res.status == 200) {
        await this.configService.showToast("success", data.message);
        await this.closeModal();
      }
    }
  }

  async markAsSold() {
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let dataObj = {
        setOfValues: this.filtered,
        voucherBookId: this.voucherBookId,
        remarks: "Sold Stone",
        type: this.voucherType,
        voucherDate: this.voucherDate
      };
      console.log(dataObj);
      let res = await this.httpApiService.markStoneAsConfirmed(dataObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 200) {
        await this.configService.showToast("success", data.message);
        await this.closeModal();
      }
    }
  }

  async getDataForComparison(id) {
    let res = await this.httpApiService.getDataForComparison(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let data1 = data.data;

      console.log("get All diffParameterList", data1);

      data1.forEach(items => {
        if (items.csvCode != null && items.defaultCode != null) {
          if (items.csvValueId != items.defaultValueId) {
            this.diffParameterList.push(items);
          }
        }
        if (items.paramName == "job no") {
          this.certificateNo = items.csvValueId;
        }
        if (items.paramName == "report dt") {
          this.date = items.csvValueId;
        }
      });
      console.log("this.diffParameterList", this.diffParameterList);
    }
  }

  // async getAllAdditionalServices() {
  //   let res = await this.httpApiService.getAllAdditionalServices();
  //   let data = res.json();
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     this.servicesList = data.data;
  //     // this.items = this.listOfParameter;
  //     console.log("get All stone param Data", this.servicesList);
  //   }
  // }

  closeModal() {
    this.modalCtrl.dismiss(this.flag);
  }

  async getParameter() {
    let fromLocation = await this.configService.getFromLocation();
    let res = await this.httpApiService.getDefaultStoneParamMaster(
      fromLocation,
      "SS"
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listParameter = data.data;
      // this.items = this.listOfParameter;
      console.log("get All stone param Data", this.listParameter);
    }
  }
  async validateControls() {
    if (this.filtered.length > 0) {
      if (
        this.voucherBookId != null &&
        this.voucherBookId != undefined &&
        this.voucherBookId != ""
      ) {
        return false;
      } else {
        await this.configService.showToast("error", "Voucher Book not found.");
        return true;
      }
    } else {
      await this.configService.showToast("error", "Please select the detail");
      return true;
    }
  }

  async validateRecheck() {
    if (this.selectedServices != null || this.selectedServices != undefined) {
      return false;
    } else {
      await this.configService.showToast("error", "Please select the detail");
      return true;
    }
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
    console.log(this.selectedFile);
  }
  async uploadReport() {
    if (this.selectedFile) {
      console.log("test ***********");
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      // formData.append("myJson", JSON.stringify(jsonObj));
      if (this.labId != null && this.labId != '' && this.labId != undefined) {

        await this.configService.showLoader();
        let res: any;
        res = await this.httpApiService.labStoneReportUpload(formData, this.labId);
        console.log(res.json());
        await this.configService.dismiss();
        let uploadData = res.json();
        if (uploadData.status == 400) {
          await this.configService.showToast("error", uploadData.error);
        } else if (res.status == 500) {
          await this.configService.showToast("error", uploadData.error);
        } else {
          await this.configService.showToast("success", uploadData.message);
          this.selectedFile = null;
          this.closeModal();
        }
      } else {
        await this.configService.showToast("error", "Please select lab");
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  async uploadRecheck() {
    if (this.selectedFile) {
      if (!!this.labId) {
        console.log("test ***********");
        const formData: FormData = new FormData();
        formData.append("file", this.selectedFile);
        await this.configService.showLoader();
        let res: any;
        res = await this.httpApiService.uploadForCetificateAction(this.labId, formData);
        console.log(res.json());
        await this.configService.dismiss();
        let uploadData = res.json();
        if (uploadData.status == 400) {
          await this.configService.showToast("error", uploadData.error);
        } else if (res.status == 500) {
          await this.configService.showToast("error", uploadData.error);
        } else {
          await this.configService.showToast("success", uploadData.message);
          this.selectedFile = null;
          this.closeModal();
        }
      } else {
        await this.configService.showToast("error", "Please select Lab");
      }

    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  async getDefaultSetting(userId, ActionId) {
    let res: any;
    console.log(userId, ActionId)
    res = await this.httpApiService.getDefaultSetting(userId, ActionId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
      }
    }
  }

  async forRecheck(id) {
    console.log("id", id);
    await this.getVoucherBook();
    let validate = await this.validateRecheck();
    if (!validate) {
      if (this.voucherBookId != null && this.voucherBookId != undefined && this.voucherBookId != '') {
        let stoneId = [];
        stoneId.push(id);
        let userData = await this.authService.userData();
        this.userId = userData.userId;
        let res;
        await this.getDefaultSetting(this.userId, this.actionId);
        // console.log(this.paraForRecheck, this.selectedServices,this.voucherBookId);
        let jsonObj = {
          requestDate: moment().format("YYYY-MM-DD"),
          voucherBookId: parseInt(this.voucherBookId),
          setOfStoneIds: stoneId,
          setOfServiceId: this.selectedServices,
          labId: this.getLabId
        };
        console.log(jsonObj);
        await this.configService.showLoader();
        res = await this.httpApiService.certificateRecheckRequest(jsonObj);
        let data = res.json();
        await this.configService.dismiss();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 200) {
          await this.configService.showToast(
            "success",
            "Recheck request successfully done."
          );
          this.closeModal();
        }
      } else {
        await this.configService.showToast('error', 'Voucher book not found.')
      }
    }
  }


  async downloadActualLabExpenseFile(file) {
    let res: any;
    res = await this.httpApiService.downloadActualLabExpenseFile(file.id, 'Lab Actual Expenses Upload');
  }


  async downloadUploadedFile(file) {
    let res: any;
    res = await this.httpApiService.downloadActualLabExpenseFile(file.id, 'Update PD Parameters');
  }

  async downloadCertificationFile(file) {
    console.log(file)
    let res: any;
    res = await this.httpApiService.downloadCertificationUploadedFile(file.id);
  }

  async downloadComparisonFile(file) {
    console.log(file)
    let res: any;
    res = await this.httpApiService.downloadCertificationComparisonFile(file.row.data.id);
  }

  //upload lab expense

  async uploadLabExpense() {
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      if (this.labId != null && this.labId != '' && this.labId != undefined) {
        await this.configService.showLoader();
        let res: any;
        res = await this.httpApiService.uploadLabActualExpenseCSV(this.labId, formData);
        await this.configService.dismiss();
        let uploadData = res.json();
        if (uploadData.status == 400) {
          await this.configService.showToast("error", uploadData.error);
        } else if (res.status == 500) {
          await this.configService.showToast("error", uploadData.error);
        } else {
          await this.configService.showToast("success", uploadData.message);
          await this.loadCertificationFileUploadHistory(this.action, this.pageNumber, this.noOfRecords)
          // this.closeModal();
        }
      } else {
        await this.configService.showToast("error", "Please select lab");
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  async previewDocument(view, po) {
    this.multipleChallanList = [{ id: 1, name: 'ORIGINAL', isSelect: true }, { id: 2, name: 'DUPLICATE/TRANSPORTER', isSelect: true }, { id: 3, name: 'TRIPLICATE', isSelect: true }]
    this.flagOpenDoc = view;
    if (po) {
      this.optionPO = po;
    }
  }

  async findAllTransferRequestedByVoucherIdForDC(id, actionName) {
    let res: any;
    res = await this.httpApiService.getTransferRequestedByVoucherIdForDC(id, actionName);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.DCList = data.data;
      if (this.DCList.length > 0) {
        this.fxRate = this.DCList[0].exgRate;
        this.modeOfTransport = !!this.DCList[0].modeOfTransport ? this.DCList[0].modeOfTransport : '';
        this.transType = this.DCList[0].transType;
        this.remarks = !!this.DCList[0].remarks ? this.DCList[0].remarks.toString() : '';
        this.terms = !!this.DCList[0].terms ? this.DCList[0].terms : 0;
        this.disc1 = !!this.DCList[0].disc1 ? this.DCList[0].disc1 : 0;
        this.disc2 = !!this.DCList[0].disc2 ? this.DCList[0].disc2 : 0;
      }
      console.log(this.companyDetails,
        this.transType,
        this.terms, this.disc1, this.disc2);
      console.log(this.DCList)
    }
  }

  async getCompanyDetail() {
    let companyName = await this.configService.getFromLocation()
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.companyDetails = data.data;
      console.log(this.companyDetails)
    }
  }

  async getCustomerDetails(id) {
    let res: any;
    res = await this.httpApiService.getCustomerDetails(id);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }

  async printDocument() {
    await this.configService.print()
  }

  async uploadBills() {
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.uploadCsvForConsignmentAutoSold(formData);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 400) {
        await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        await this.configService.showToast("error", data.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.selectedFile = null;
        this.closeModal();
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }


  async updateInvoiceDetail() {
    if (!!this.invoiceNo && !!this.invoiceDate) {
      let jsobObj = {
        invoiceDate: moment(this.invoiceDate).format('DD-MMM-YYYY'),
        invoiceNo: this.invoiceNo
      }
      await this.configService.showLoader()
      let res: any = await this.httpApiService.updateInvoiceDetail(jsobObj, this.voucherId);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    } else {
      await this.configService.showToast('error', 'Please fill invoice no and date')
    }
  }
}
