import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  NavController,
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { IonicSelectableComponent } from 'ionic-selectable';
import * as moment from "moment";

@Component({
  selector: 'app-rapnet-stock-upload',
  templateUrl: './rapnet-stock-upload.component.html',
  styleUrls: ['./rapnet-stock-upload.component.scss']
})
export class RapnetStockUploadComponent implements OnInit {
  @ViewChild('myInput')
  inputFileVariable: ElementRef;
  public uploadDate = moment().format("YYYY-MM-DD")
  columns = [];
  dataSource: any;
  public partyFile: any;
  public partyMasterId: any;
  public partyName: any;



  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "File Name", prop: "fileName" },
    { name: "Status", prop: "status" },
    // { name: "By", prop: "username" },
    { name: "Date & Time", prop: "createdDate" },


    ];
    this.loadUploadHistory()

  }


  async upload() {
    let formData = new FormData();
    formData.append("file", this.partyFile);
    console.log(this.partyFile);

    await this.configService.showLoader();
    console.log(formData);
    let res: any;
    res = await this.httpApiService.uploadRapnetPurchaseFile(formData);
    await this.configService.dismiss();
    let data = res.json();
    console.log(data.error);

    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      data.message = 'File uploaded successfully'
      await this.configService.showToast("success", data.message);
      this.reset();
      this.uploadDate = moment().format("YYYY-MM-DD")
      this.loadUploadHistory();
    }
  }


  onPFileSelected(event) {
    this.partyFile = <File>event.target.files[0];
    console.log("this event is working fine");
  }
  reset() {
    this.inputFileVariable.nativeElement.value = "";
    this.partyFile = '';
    this.partyName = '';
  }
  async loadUploadHistory() {
    let res: any;

    res = await this.httpApiService.getRapnetHistory();
    let data = res.json();
    let sourceData = [];
    console.log(data.data);
    console.log(data);
    // console.log( data.data[0].taskInputJson.fileName);
    for (let i = 0; i < data.data.length; i++) {
      let d = data.data[i];
      let obj = {
        'status': d.status,
        'fileName': d.taskInputJson.fileName,
        'createdDate': d.taskInputJson.createdDate,
        'url': d.taskInputJson.url

      };
      sourceData.push(obj);
      // console.log(d);
    }


    this.dataSource = sourceData;
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {

      }
    }
  }

  async downloadUploadedFile(file) {
    console.log("Downloading.......");
    console.log(file);

    console.log(file.url)
    let res: any;
    if (!!file.url) {
      console.log(file.url);
      window.open(file.url, '_blank')
    }
  }

}
