import { Component, OnInit, ViewChild } from "@angular/core";
import {
  NavController,
  ModalController,
  NavParams,
  Events,
  LoadingController
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: 'app-upload-bulk-to-single',
  templateUrl: './upload-bulk-to-single.page.html',
  styleUrls: ['./upload-bulk-to-single.page.scss'],
})
export class UploadBulkToSinglePage implements OnInit {
  @ViewChild("dataTable") public dataTable: any;
  public flag: any;
  public selected = [];
  public columns = [];
  public listOfUnConfirmPurchaseData = [];
  public selectedStoneList = [];
  public isSelect = false;
  public stoneList = [];
  public fromCts: any;
  public toCts: any;
  public colorList = [];
  public clarityList = [];
  public shapeList = [];
  public colorIdList = [];
  public shapeIdList = [];
  public clarityIdList = [];
  public shapeId = [];
  public clarityId = [];
  public colorId = [];
  public selectedDataColumns = []
  public count: number = 0;
  public listOfBulkPurchasedDetail = [];
  public selectedCount: number = 0;
  public csspCount: number = 0;
  public isNext = true;
  public showSelected = true;
  public openSelectedcsspListFlag = true;
  public actionData: any;
  public csspList = [];
  public selectedCsspList = []
  public csspColumns = []
  public listOfLabs = []
  public selectedFile: File = null;
  public toRole: any;
  public userId: any;
  public voucherBookId: any;
  public actionId: any;
  public historyColumns = []
  public labId: any;
  public page: any;
  public historyCount: Number = 0;
  public historyData: any = [];
  public historyDataSource: any = {};
  public pageNumber = 0;
  public noOfRecords = 10;
  public pageSize = 10;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public configService: ConfigService,
    public authService: AuthenticationService,
    public loadingController: LoadingController,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public event: Events
  ) { }

  async ngOnInit() {
    this.isSelect = false;
    this.selected = []
    this.count = 0;
    this.columns = [
      { name: "Party", prop: "party" },
      { name: "Ie Currency", prop: "ieCurrency" },
      { name: "Ie Invoice No", prop: "ieInvoiceNo" },
      // { name: "Ie Final Year", prop: "financialYear" },
      { name: "Inw Date", prop: "inwDate" },
      { name: "IO_DATE", prop: "ioDate" },
      { name: "Cts", prop: "cts" },
    ]
    this.csspColumns = [
      { name: "CSSP", prop: "stoneId" },
      { name: "Ct.", prop: "size" },
    ];

    this.flag = await this.navParams.get('flag');
    this.actionData = await this.navParams.get('data')
    if (this.flag == 0) {
      this.findUnConfirmPurchaseData()
    } else if (this.flag == 1) {
      this.historyColumns = [
        { name: "File Name", prop: "fileName" },
        { name: "Date & Time", prop: "createdDate" },
        { name: "By", prop: "username" },
        { name: "Status", prop: "status" }
      ];
      await this.loadUploadHistory(this.pageNumber, this.noOfRecords)
      await this.getAllCustomer('Lab');
    } else if (this.flag == 2) {
      this.selectedDataColumns = [{ name: "Party", prop: "party" }, { name: "Ct.", prop: "cts" }, { name: "Transfer Ct.", prop: "cts" }]
      await this.findcsspByCriteria()
    }
    this.listOfBulkPurchasedDetail = [{ id: 1, party: 'LAXMI-20191015', ieCurrency: 'US DOLLAR', ieInvoiceNo: '23452', financialYear: '2019-2020', inwDate: '2019-10-11 09:08:38.000', outwardDate: '2019-10-11 09:08:38.000', cts: '15.45' },
    { id: 2, party: 'BOGHRA-20191015', ieCurrency: 'US DOLLAR', ieInvoiceNo: '23565', financialYear: '2019-2020', inwDate: '2019-10-11 09:08:38.000', outwardDate: '2019-10-11 09:08:38.000', cts: '10.00' }]
    this.count = 2;
  }

  // 

  async loadUploadHistory(pageNumber, noOfRecords) {
    // await this.configService.showLoader();
    // let res: any;
    // res = await this.httpApiService.getUploadHistory(
    //   "Purchase To Single",
    //   pageNumber,
    //   noOfRecords
    // );
    // await this.configService.dismiss();
    // let data = res.json();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   let dataValue = data.data;
    //   console.log(dataValue);
    //   this.historyData = dataValue.content;
    //   this.historyCount = dataValue.totalElements;
    // }
    this.historyDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100,500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getUploadHistory(
            "Purchase To Single",
            pageNumber,
            noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.historyData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.historyData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async setPage(currentPage) {
    console.log(currentPage);
    let res: any;
    this.pageNumber = currentPage.offset;
    await this.loadUploadHistory(this.pageNumber, this.noOfRecords);
  }

  //get default setting

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
      }
    }
  }



  // unconfirm purchase data

  async findUnConfirmPurchaseData() {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.findUnconfirmPurchaseData();
    let data = res.json();
    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfUnConfirmPurchaseData = data.data;
      this.count = this.listOfUnConfirmPurchaseData.length;
    }
  }

  //confirm purchase Data 

  async confirmPurchaseData() {
    let res: any;
    let jsonObj = {
      setOfStoneIds: this.selectedStoneList
    }
    res = await this.httpApiService.confirmPurchaseData(jsonObj);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.selected = []
      this.isSelect = false;
      await this.configService.showToast("success", data.message);
      await this.findUnConfirmPurchaseData()
    }
  }

  // purchase confirm selection

  onSelect(ev) {
    this.selected = ev.selected;
    this.selectedStoneList = [];
    this.selected.forEach(el => {
      this.selectedStoneList.push(el.id);
    })
    // console.log(this.isSelect, this.selectedStoneList, this.selectedStoneList.length, this.listOfPendingAssortment.length)
    if (this.selected.length == this.listOfUnConfirmPurchaseData.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }

  async selectAll(item) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(this.listOfUnConfirmPurchaseData)
      this.isSelect = true;
      this.selectedStoneList = [];
      this.selected.forEach(el => {
        this.selectedStoneList.push(el.id);
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.selectedStoneList = [];
    }
    console.log(this.selectedStoneList)
  }

  // get all labs

  async getAllCustomer(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLabs = data.data;
      console.log("getAllCustomers", this.listOfLabs);

    }
  }

  //update price in cart

  async updateValue(event, cell, rowIndex) {
    console.log('inline editing rowIndex', cell, rowIndex, event.target.value)

    this.selectedStoneList[rowIndex][cell] = parseFloat(event.target.value);

    this.selectedStoneList = [...this.selectedStoneList];
    if (this.selectedStoneList[rowIndex][cell] > this.selectedStoneList[rowIndex]['stoneCts'] ||
      this.selectedStoneList[rowIndex][cell] == 0 ||
      this.selectedStoneList[rowIndex][cell] == "") {
      this.selectedStoneList[rowIndex]['errorCt'] = true;
    } else {
      this.selectedStoneList[rowIndex]['errorCt'] = false;
    }
    if (!this.selectedStoneList[rowIndex][cell]) {
      this.selectedStoneList[rowIndex][cell] = 0
    }
    for (let i = 0; i < this.selectedStoneList.length; i++) {
      if (
        parseFloat(this.selectedStoneList[i].size) >
        parseFloat(this.selectedStoneList[i].stoneCts) ||
        parseFloat(this.selectedStoneList[i].size) == 0 ||
        this.selectedStoneList[i].size == ""
      ) {
        this.isNext = false;
      } else {
        this.isNext = true;
      }
    }
    console.log(this.isNext, this.selectedStoneList)
  }

  //dropdown selection

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'colorIdList') {
      if (idList.length > 0) {
        this.colorId = idList;
      } else {
        this.colorId = [];
      }
    } else if (property == 'shapeIdList') {
      if (idList.length > 0) {
        this.shapeId = idList;
      } else {
        this.shapeId = [];
      }
    } else if (property == 'clarityIdList') {
      if (idList.length > 0) {
        this.clarityId = idList;
      } else {
        this.clarityId = [];
      }
    } else if (property == 'labList') {
      if (idList.length > 0) {
        this.labId = idList[0];
      } else {
        this.labId = [];
      }
    }
  }

  async findcsspByCriteria() {
    let res: any;
    res = await this.httpApiService.findParametersForMixSizeMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.colorList) {
        let data1 = []
        dataValue.colorList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.colorList = data1;
      }
      if (dataValue.clarityList) {
        let data1 = []
        dataValue.clarityList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.clarityList = data1;
      }
      if (dataValue.shapeList) {
        let data1 = []
        dataValue.shapeList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.shapeList = data1;
      }
    }
  }

  async searchCSSP() {
    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      colorId: this.colorId,
      shapeId: this.shapeId,
      clarityId: this.clarityId,
      roleId: this.actionData.roleId,
      fromCts: this.fromCts,
      toCts: this.toCts,
      listOfBoxType: ['Regular', 'Program-Parcel', 'Parcel', 'Purchase', 'Program', 'Assortment-OK'],
    }
    res = await this.httpApiService.findCsspOrCreateIfNotExist(jsonObj, 'false');
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.csspList = data.data;
      if (this.csspList.length > 0) {
        this.csspList.forEach(el => {
          el.isSelected = false;
        })
        this.openSelectedcsspListFlag = true;
      }

    }
  }

  selectList() {
    this.openSelectedcsspListFlag = false;
  }

  selectedItem(item) {
    this.selectedCsspList = []
    this.csspList.forEach(el => {
      if (el.id == item.id) {
        el.isSelected = !el.isSelected;
      } else {
        el.isSelected = false;
      }
      if (el.isSelected == true) {
        this.selectedCsspList.push(el)
      }
    });
    if (this.selectedCsspList.length > 0) {
      this.csspCount = this.selectedCsspList.length
    } else {
      this.csspCount = 0
    }
  }

  closeModal() {
    this.modalCtrl.dismiss()
  }

  // BULK TO BULK  SELECTION

  onSelectPurchaseData(ev) {
    this.selected = ev.selected;
    this.selectedStoneList = [];
    this.selected.forEach(el => {
      this.selectedStoneList.push(el);
    })
    this.selectedCount = this.selected.length
    console.log(this.selectedStoneList)
    if (this.selected.length == this.listOfBulkPurchasedDetail.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }

  async selectAllPurchase(item) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(this.listOfBulkPurchasedDetail)
      this.isSelect = true;
      this.selectedStoneList = [];
      this.selected.forEach(el => {
        this.selectedStoneList.push(el);
      });
      this.selectedCount = this.selected.length
    } else {
      this.isSelect = false;
      this.selected = []
      this.selectedStoneList = [];
    }
  }

  // file object

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  // BULK TO SINGLE FILE UPLOAD

  async convertPurchaseToSingle() {
    if (this.selectedFile) {
      let jsonObj = {
        labId: this.labId,
        isAssortmentDone: true,
        isReadyForSale: true
      };
      console.log(jsonObj);
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("myJson", JSON.stringify(jsonObj));
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.purchaseToSingle(formData, "Repairing");
      console.log(res.json());
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.loadUploadHistory(this.pageNumber, this.noOfRecords);
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  //stoneId without version

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  //download

  async downloadUploadedFile(file) {
    console.log(file)
    let res: any;
    res = await this.httpApiService.downloadUploadedFile(file.id);
  }

}
