export default {
  reportNo: 'R-15',
  url: "master-service/api/report/labCompareReport",
  method: "POST",
  size: 3,
  fields: [
    {
      title: 'Stones',
      input: 'textArea',
      placeholder: 'Enter Stone Ids here...',
      key: 'setOfValues',
      required: true,
      default: [],
      size: 3
    },
    {
      title: 'Template',
      input: 'select',
      key: 'templateId',
      placeholder: 'Select TemplateId',
      property: 'templateId',
      keyName: 'id',
      name: 'templateName',
      required: true,
      singleSelection: true,
      disable:true,
      function: 'getAllDefaultTemplates',
      default: [],
      size: 3
    },
  ],
  columns: [
    { name: "Date", prop: "transactionDate", required: true },
    { name: "Type", prop: "transactionType", required: true },
    { name: "Criteria Name", prop: "criteriaName", required: true },
    { name: "Stone Id", prop: "stoneName", required: true },

    { name: "Opening Carats", prop: "openingCarats" },
    { name: "Opening Value", prop: "openingValue" },
    { name: "Inwards", prop: "inwards", ngIf: ['transactionType', 'inwards'] },
    { name: "Sales Carats", prop: "salesCarats", ngIf: ['transactionType', 'sales'] },
    { name: "Reassort Upgrade", prop: "reassortUpgrade", ngIf: ['transactionType', 'reassortUpgrade'] },
    { name: "Reassort Downgrade", prop: "reassortDowngrade", ngIf: ['transactionType', 'reassortDowngrade'] },
    { name: "Repricing", prop: "repricing", ngIf: ['transactionType', 'repricing'] },

    { name: "List Price", prop: "listPrice" },
    { name: "Closing Carats", prop: "closingCarats" },
    { name: "Closing Value", prop: "closingValue" },
    { name: "Selection Percentage", prop: "selectionPercentage" },
    { name: "Cost Markup", prop: "costMarkup" },
    { name: "Cost Of Goods Sold", prop: "costOfGoodsSold" },
    { name: "Cogs Per Carat", prop: "cogsPerCarat" },
    { name: "Sale Price", prop: "salePrice" },
    { name: "Profit Value", prop: "profitValue" },
    { name: "Cost Of Balance", prop: "costOfBalance" },
    { name: "Cost Per Carat", prop: "costPerCarat" },
    { name: "Inventory Profit", prop: "inventoryProfit" },
  ],
  dataStudioLink: 'https://lookerstudio.google.com/embed/reporting/e436e775-17ac-4e69-be19-e1dc7c6e0657/page/2DtWD'
};
