export default {
  reportNo: 'R-13',
  url: "master-service/api/report/PartyWiseStockDataWithAction",
  method: "GET",
  size: 3,
  fields: [
    {
      title: ' Action Name ',
      input: 'select',
      key: 'actionName',
      placeholder: 'Select Action Name',
      property: 'actionName',
      keyName: 'name',
      required: true,
      name: 'name',
      singleSelection: true,
      itemList: [{
        id: 1,
        name: 'Memo Client'
      }, {
        id: 2,
        name: "Certification"
      },
      {
        id: 3,
        name: "Customer Confirm"
      },
      {
        id: 4,
        name: "Consignment Confirm"
      },
      {
        id: 5,
        name: "Consignment In Stock"
      },
      {
        id: 6,
        name: "Observation"
      },
      {
        id: 7,
        name: "Self Observation"
      },
      {
        id: 8,
        name: "Recut"
      }],
      size: 3
    },
    {
      title: ' Trans Type ',
      input: 'select',
      key: 'transType',
      placeholder: 'Select Trans Type',
      property: 'transType',
      keyName: 'name',
      name: 'name',
      required: true,
      singleSelection: true,
      function: 'getAllTransType',
      default: [],
      size: 3
    },
    {
      title: ' Report Type ',
      input: 'select',
      key: 'type',
      placeholder: 'Select Report Type',
      property: 'type',
      keyName: 'id',
      required: true,
      name: 'name',
      singleSelection: true,
      itemList: [{
        id: 'Details',
        name: 'details'
      }, {
        id: 'Summary',
        name: 'summary'
      }, {
        id: 'semiSummary',
        name: 'SemiSummary'
      }],
      default: [],
      size: 3
    },
    {
      title: ' Transaction Type ',
      input: 'select',
      key: 'transactionType',
      placeholder: 'Select Transaction Type',
      property: 'transactionType',
      keyName: 'id',
      required: true,
      name: 'name',
      singleSelection: true,
      itemList: [{
        id: 'issue',
        name: 'Issue'
      }, {
        id: 'outstanding',
        name: 'Outstanding'
      }, {
        id: 'return',
        name: 'Return'
      }],
      default: [],
      size: 3
    },
    {
      title: ' From Date ',
      input: 'dateTime',
      key: 'fromDate',
      placeholder: 'Select From Date',
      property: 'fromDate',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
    {
      title: ' To Date ',
      input: 'dateTime',
      key: 'toDate',
      placeholder: 'Select To Date',
      property: 'toDate',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
  ],
  columns: [
    { name: "Date", prop: "issueDate" },
    { name: "Voucher No", props: ["shortCode", "bookSerialNumber"], join: '-', required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Party wise", prop: "partyName", showInGroupFooter: true, "displayFormat": " ", },
    { name: "Accusoft No", prop: "voucherLabel", showInGroupFooter: true, "displayFormat": " ", },
    { name: "Accusoft Date", prop: "accVoucherDate", showInGroupFooter: true, "displayFormat": " ", },
    { name: "Stone Id", prop: "stoneId", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Description", props: ["shape", "color", "clarity", "cut", "symmetry", "fluorescence"], join: ' ', ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    {
      name: "Cts", prop: "stoneCts", ngIf: ['type', 'Details'], required: true,
      summaryType: 'sum',
      showInGroupFooter: true
    },
    {
      name: "Pcs", prop: "stonePcs", ngIf: ['type', 'Details'], required: true,
      summaryType: 'sum',
      showInGroupFooter: true
    },
    { name: "Memo price", prop: "price", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Memo Value", prop: "amount", ngIf: ['type', 'Details'], required: true, summaryType: 'sum' },
    { name: "Remark", prop: "remark", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Seq No", prop: "factSeqNo", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },

    { name: "Pcs", prop: "pcs", ngIf: ['type', 'Summary'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Cts", prop: "totalCts", ngIf: ['type', 'Summary'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Memo price", prop: "price", ngIf: ['type', 'Summary'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Memo Value", prop: "value", ngIf: ['type', 'Summary'], required: true, showInGroupFooter: true, "displayFormat": " ", },

    { name: "Pcs", prop: "stonePcs", ngIf: ['type', 'semiSummary'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Cts", prop: "stoneCts", ngIf: ['type', 'semiSummary'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Memo price", prop: "price", ngIf: ['type', 'semiSummary'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Memo Value", prop: "amount", ngIf: ['type', 'semiSummary'], required: true, summaryType: 'sum', showInGroupFooter: true },
    { name: "Seq No", prop: "factSeqNo", ngIf: ['type', 'semiSummary'], required: true, showInGroupFooter: true, "displayFormat": " ", },

  ]
};
