import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as moment from "moment";
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-delivery-challan',
  templateUrl: './delivery-challan.component.html',
  styleUrls: ['./delivery-challan.component.scss']
})
export class DeliveryChallanComponent implements OnInit, OnChanges {

  @Input() companyDetails: any;
  @Input() customerDetails: any;
  @Input() stoneDetails: any;
  @Input() transType: any;
  @Input() nameOfPersonReceivingGood: any;
  @Input() deliveryChallanNo: any;
  @Input() requestAction: any;
  @Input() status: any;
  @Input() salesPersonName: any;
  @Input() terms: number;
  @Input() voucherData: any;
  @Input() disc1: number;
  @Input() disc2: number;
  @Input() fxRate: any;
  @Input() transferBy: any;
  @Input() remarks: any;
  @Input() voucherDate: any;
  @Input() transporterDetails: any;
  @Input() summaryDetail: any;
  @Input() multipleChallanList: any = [];
  @Input() voucherId: any;
  public totalCarat: Number = 0;
  public pricePerCts = 0;
  public numbers = []
  public numbersOnSummary = []
  public numbersOnLast = []
  public totalPricePerCts = 0
  public totalAmount = 0;
  public AmountInRupees = 0
  public stoneDetailsDetailView: any;
  public address: string = '';
  public brokerContact: any = '';
  public deliveryDate: any;
  public state = '';
  public stateCode = '';
  public userDetail: any = null;

  constructor(private authService: AuthenticationService) {
  }

  ngOnChanges(changes: any) {
    console.log(changes.stoneDetails.currentValue, this.remarks)
    this.fxRate = changes.fxRate.currentValue;
    this.totalCarat = 0
    this.totalAmount = 0;
    this.AmountInRupees = 0;
    console.log(this.status, this.terms, this.salesPersonName, this.requestAction, this.transferBy, this.stoneDetails, this.nameOfPersonReceivingGood, this.disc2)
    this.numbers = Array(55).fill(13);
    this.numbersOnLast = Array(20).fill(13);
    this.numbersOnSummary = Array(120).fill(13);
    this.deliveryDate = !!this.voucherDate ? moment(this.voucherDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')
    if (this.status == 'Internal') {
      for (let i = 0; i < this.stoneDetails.length; i++) {
        this.totalCarat = this.totalCarat + (!!this.stoneDetails[i].size ? parseFloat(this.stoneDetails[i].size) : this.stoneDetails[i].stoneCts);
        if (this.stoneDetails[i].requestPrice) {
          this.totalPricePerCts += this.stoneDetails[i].requestPrice;
          this.totalAmount = this.totalAmount + (((this.stoneDetails[i].requestPrice ? this.stoneDetails[i].requestPrice : this.stoneDetails[i].price) * (!!this.stoneDetails[i].size ? parseFloat(this.stoneDetails[i].size) : this.stoneDetails[i].stoneCts)));
        }
      }
      if (!!this.fxRate) {
        this.AmountInRupees = this.totalAmount * this.fxRate
      }

    } else {
      let convertedAmountValue = 0
      let amountValue = 0
      for (let i = 0; i < this.stoneDetails.length; i++) {
        if (!!this.stoneDetails[0].brokerName) {
          this.nameOfPersonReceivingGood = this.stoneDetails[0].brokerName
          this.brokerContact = !!this.stoneDetails[0].brokerContact ? this.stoneDetails[0].brokerContact : ''
        }
        else if (!!this.stoneDetails[0].partyMasterId) {
          this.nameOfPersonReceivingGood = this.stoneDetails[0].customerContact
        }
        else if (!!this.stoneDetails[0].username) {
          this.nameOfPersonReceivingGood = this.stoneDetails[0].username;
        }
        this.salesPersonName = this.stoneDetails[0].requestUser;
        this.address = this.stoneDetails[0].partyAddress;
        this.totalCarat = this.totalCarat + (!!this.stoneDetails[i].size ? parseFloat(this.stoneDetails[i].size) : this.stoneDetails[i].stoneCts);
        if (this.stoneDetails[i].requestPrice || this.stoneDetails[i].price) {
          this.totalPricePerCts += this.stoneDetails[i].requestPrice ? this.stoneDetails[i].requestPrice : this.stoneDetails[i].price;
          this.totalAmount = this.totalAmount + (((this.stoneDetails[i].requestPrice ? this.stoneDetails[i].requestPrice : this.stoneDetails[i].price) * (!!this.stoneDetails[i].size ? parseFloat(this.stoneDetails[i].size) : this.stoneDetails[i].stoneCts)));
        }
        convertedAmountValue += (this.stoneDetails[i].requestPrice * (!!this.stoneDetails[i].size ? parseFloat(this.stoneDetails[i].size) : this.stoneDetails[i].stoneCts)) * this.stoneDetails[i].exgRate;
        amountValue += (this.stoneDetails[i].requestPrice * (!!this.stoneDetails[i].size ? parseFloat(this.stoneDetails[i].size) : this.stoneDetails[i].stoneCts))
      }
      this.fxRate = (convertedAmountValue / amountValue)
      this.AmountInRupees = Number(this.totalAmount.toFixed(2)) * this.fxRate.toFixed(2)
      console.log(this.fxRate, convertedAmountValue, amountValue)
      // this.AmountInRupees = this.totalAmount * this.fxRate
    }
    if (!!this.summaryDetail && this.summaryDetail == 'Detail' && !!this.stoneDetails && this.stoneDetails.length > 0) {
      let cnt = Math.ceil(this.stoneDetails.length / 13);
      let processedElements = 0;
      this.stoneDetailsDetailView = [];
      for (let i = 0; i < cnt; i++) {
        console.log("processedElements ", processedElements, this.stoneDetails.slice(processedElements, 13));
        this.stoneDetailsDetailView[i] = {};
        this.stoneDetailsDetailView[i].stoneDetails = this.stoneDetails.slice(processedElements, processedElements + 13);
        this.stoneDetailsDetailView[i].processedElements = processedElements;
        processedElements += 13;
        if ((i + 1) == cnt) {
          this.stoneDetailsDetailView[i].isLast = true;
        } else {
          this.stoneDetailsDetailView[i].isLast = false;
        }
      }
      console.log("stoneDetailsDetailView ", this.stoneDetailsDetailView);
    }
    if (!!this.customerDetails && this.customerDetails.addresses && this.customerDetails.addresses.length > 0) {
      let isFoundAddress = this.customerDetails.addresses.find(el => el.address == this.address);
      if (!!isFoundAddress) {
        this.customerDetails.gstin = isFoundAddress.gstin;
        this.customerDetails.state = isFoundAddress.state;
        this.customerDetails.stateCode = isFoundAddress.stateCode;
      }
    }
    // if (!this.fxRate)
    //   this.fxRate = 0
    // console.log("fxRate ", this.fxRate);
  }

  async ngOnInit() {
    this.userDetail = await this.authService.userData();
  }

  //stoneId

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

}
