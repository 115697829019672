import { Component, OnInit, Input } from "@angular/core";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { PdAssortDetailsPage } from "../../actions/pd-assort-details/pd-assort-details.page";


@Component({
  selector: "app-inventory-box",
  templateUrl: "./inventory-box.component.html",
  styleUrls: ["./inventory-box.component.scss"]
})
export class InventoryBoxComponent implements OnInit {
  @Input() id: any;
  @Input() transTypeName: any;

  public tab: any = "inventory";
  public noOfStones: any = [];
  public setOfStoneIds: any = [];
  public selected: any = [];
  // public noPagination = true;
  public page = "InventoryBox";
  public columns: any;
  public count: any;
  public isAssort = false;
  public assormentData = []


  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events
  ) { }

  async ngOnInit() {
    if (this.transTypeName == 'SS') {
      this.columns = [
        { name: "StoneId", prop: "stoneId" },
        { name: "Cts", prop: "stoneCts" },
        { name: "Program Name", prop: "programmeNo" },
        { name: "Rfid", prop: "rfid" },
        { name: "Barcode", prop: "barcode" },
        { name: "PD/Assort", prop: "isAssortmentDone" }
      ];
    } else {
      this.columns = [
        { name: "CSSP", prop: "stoneId" },
        { name: "Cts", prop: "stoneCts" },
        { name: "Program Name", prop: "programmeNo" },
        { name: "Barcode", prop: "barcode" },
        { name: "PD/Assort", prop: "isAssortmentDone" }
      ];
    }
    await this.findStonesByBoxID();
    this.event.subscribe('InventoryBox', (item) => {
      this.findStonesByBoxID();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('InventoryBox');
  }

  async findStonesByBoxID() {
    this.noOfStones = []
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findStonesByboxIdWithAvailableStock(
      this.id
    );
    console.log("box stone", res, res.json());

    let data = res.json();
    console.log("data", data);
    this.count = data.data.length;
    console.log("count", this.count);
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.noOfStones = data.data;
    }
  }

  onSelect(ev) {
    console.log("event", ev);
    this.selected = ev.selected;
    // this.events.subscribe("selectedValue",params=>{
    //   console.log("params",params);
    //   this.selected=params
    // });
    console.log("onselect");
    this.setOfStoneIds = [];
    this.selected.forEach(el => {
      this.setOfStoneIds.push(parseInt(el.rfid));
    });
    console.log(this.setOfStoneIds);
  }

  async showResultOfAssortPD(ev) {
    console.log(ev)
    const modal = await this.modalCtrl.create({
      component: PdAssortDetailsPage,
      backdropDismiss: false,
      cssClass: 'largePage',
      componentProps: {
        rowData: ev.row.data
      },
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
    //  this.isAssort=true;
    //  let res=await this.httpApiService.findResultOfPDAssort(id);
    //  let data=res.json();
    //  this.assormentData=data.data;
    //  console.log("assortment data is",this.assormentData);
  }

  async handleModalDismiss(d) {
    await this.findStonesByBoxID();
  }

}
