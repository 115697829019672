import { Component, Input, HostListener, ViewChild } from "@angular/core";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { ManageAssortmentPage } from "../manage-assortment/manage-assortment.page";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ScanRFIDPage } from "../scan-rfid/scan-rfid.page"
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { UploadAssortmentPage } from "../upload-assortment/upload-assortment.page";

@Component({
  selector: "app-assortment",
  templateUrl: "./assortment.component.html",
  styleUrls: ["./assortment.component.scss"]
})
export class AssortmentComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() refreshPage: any;
  @ViewChild("dataTable") public dataTable: any;
  public status: any;
  public assortTab: any = "Pending";
  public tab = "Pending"
  public totalCrt: any;
  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 50;
  public totalPages: any;
  public count: any;
  public count1: any;
  public listOfAssortmentData: any = [];
  public listOfPendingAssortment: any = [];
  public listOfPendingAssortmentDataSource: any = {};
  public listOfAssortmentDatatDataSource: any = {};
  public columns: any;
  public page = "Stone Assortment";
  public isOpen = false;
  public isSelect: Boolean = false;
  public selected = [];
  public voucherBook: any;
  public selectedStoneList = [];
  public showBarcodeBtn: Boolean = false;
  //view variable

  public keyFun: any = true;
  public openView: any = false;
  public option = "FACT. O/W NO WISE";
  public optionList = [];
  public refreshGrid = false;

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events
  ) { }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.keyFun) {
      if (event.keyCode == 86) {
        if (this.tab == 'Pending') {
          this.openView = true;
        }
      }
    }
  }

  ngOnInit() {
    console.log(this.refreshPage)
    if (this.assortTab == "Pending") {
      this.optionList = [{ id: 1, name: 'FACT. O/W NO WISE', isSelected: true }, { id: 2, name: 'PENDING PD OF SS', isSelected: false }, { id: 3, name: 'PENDING ASSORTMENT OF MIX', isSelected: false }]
      this.columns = [
        { name: "Voucher No", prop: "" },
        { name: "Date & Time", prop: "modifiedDate" },
        { name: "Party", prop: "companyName" },
        { name: "Trans Type", prop: "transType" },
        { name: "Fact. O/w No.", prop: "factoryOutWNo" },
        { name: "Pending", prop: "" },
        { name: "Pending Ct.", prop: "" }
      ];

      this.getAllPendingAssortment(this.pageNumber, this.noOfRecords);
    } else {
      this.columns = [
        { name: "Voucher No", prop: "" },
        { name: "Date & Time", prop: "modifiedDate" },
        { name: "Trans Type", prop: "transType" },
        { name: "Total Assorter", prop: "totalAssorter" },
        { name: "allotedTo", prop: "allotedTo" },
        //{ name: "Barcode", prop: "Barcode" }
      ];
      this.showBarcodeBtn = true;
      this.getAllAssignAssortment(this.pageNumber, this.noOfRecords);
    }

    this.event.subscribe('Assortment', (item) => {
      this.viewDataByOption(this.optionList);
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Assortment')
  }

  async closeViewFun() {
    this.openView = false;
  }

  async viewDataByOption(optionList) {
    optionList.forEach(el => {
      if (el.isSelected == true) {
        this.option = el.name;
      }
    })
    console.log(this.option)
    this.selected = []
    this.isSelect = false;
    this.selectedStoneList = []

    if (this.option == 'FACT. O/W NO WISE') {
      this.pageNumber = 0;
      this.noOfRecords = 20;
      if (this.tab == 'Pending') {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Date & Time", prop: "modifiedDate" },
          { name: "Party", prop: "companyName" },
          { name: "Trans Type", prop: "transType" },
          { name: "Fact. O/w No.", prop: "factoryOutWNo" },
          { name: "Pending", prop: "" },
          { name: "Pending Ct.", prop: "" }
        ];
        this.getAllPendingAssortment(this.pageNumber, this.noOfRecords);
      } else {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Date & Time", prop: "modifiedDate" },
          { name: "Trans Type", prop: "transType" },
          { name: "Total Assorter", prop: "totalAssorter" },
          { name: "allotedTo", prop: "allotedTo" },
          //{ name: "Barcode", prop: "Barcode" }
        ];
        this.showBarcodeBtn = true;
        this.getAllAssignAssortment(this.pageNumber, this.noOfRecords);
      }
    } else if (this.option == 'PENDING PD OF SS') {
      this.pageNumber = 0;
      this.noOfRecords = 20000;
      this.columns = [
        { name: "StoneId", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "stoneCts" },
        { name: "Inward Date", prop: "inwardDate" },
        { name: "Clarity", prop: "clarity" },
        { name: "Date & Time", prop: "modifiedDate" },
      ];
      this.findPendingForAssignmentByTransType('SS', this.pageNumber, this.noOfRecords);
    }
    else if (this.option == 'PENDING ASSORTMENT OF MIX') {
      this.pageNumber = 0;
      this.noOfRecords = 20000;
      this.columns = [
        { name: "StoneId", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "stoneCts" },
        { name: "Clarity", prop: "clarity" },
        { name: "Inward Date", prop: "inwardDate" },
        { name: "Date & Time", prop: "modifiedDate" },
      ];
      this.findPendingForAssignmentByTransType('MIX', this.pageNumber, this.noOfRecords);
    }
    else if (this.option == 'ASSIGNED PD OF SS') {
      this.pageNumber = 0;
      this.noOfRecords = 20000;
      this.columns = [
        { name: "StoneId", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "stoneCts" },
        { name: "Clarity", prop: "clarity" },
        { name: "Date & Time", prop: "createdDate" },
      ];
      this.findAssignedStockByTransType('SS', this.pageNumber, this.noOfRecords);
    }
    else if (this.option == 'ASSIGNED ASSORTMENT OF MIX') {
      this.pageNumber = 0;
      this.noOfRecords = 20000;
      this.columns = [
        { name: "StoneId", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "stoneCts" },
        { name: "Clarity", prop: "clarity" },
        { name: "Date & Time", prop: "createdDate" },
      ];
      this.findAssignedStockByTransType('MIX', this.pageNumber, this.noOfRecords);
    }
  }

  async findPendingForAssignmentByTransType(transType, pageNumber, noOfRecords) {
    await this.configService.showLoader();
    this.listOfPendingAssortmentDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        await this.configService.dismiss();
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findPendingForAssignmentByTransType(
            transType,
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfPendingAssortment = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfPendingAssortment,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res = await this.httpApiService.findPendingForAssignmentByTransType(
    //   transType,
    //   pageNumber,
    //   noOfRecords
    // );
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("getAllPendingAssignment", data.data);
    //   this.listOfPendingAssortment = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    //   console.log("!!!!!!!!!!!!!!!!", this.listOfPendingAssortment)
    // }
  }

  async findAssignedStockByTransType(transType, pageNumber, noOfRecords) {
    await this.configService.showLoader();
    this.listOfAssortmentDatatDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        await this.configService.dismiss();
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findAssignedStockByTransType(
            transType,
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfAssortmentData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfAssortmentData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res = await this.httpApiService.findAssignedStockByTransType(
    //   transType,
    //   pageNumber,
    //   noOfRecords
    // );
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   this.listOfAssortmentData = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count1 = data.data.totalElements;
    // }
  }

  async getAllPendingAssortment(pageNumber, noOfRecords) {
    await this.configService.showLoader();
    this.listOfPendingAssortmentDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        await this.configService.dismiss();
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getAllPendingAssortment(
            pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfPendingAssortment = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfPendingAssortment,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res = await this.httpApiService.getAllPendingAssortment(
    //   pageNumber,
    //   noOfRecords
    // );
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("getAllPendingAssignment", data);
    //   this.listOfPendingAssortment = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    // }
  }

  changeSegment(event) {
    this.isOpen = false;
    this.voucherBook = '';
    this.pageNumber = 0;
    this.noOfRecords = 20;
    this.tab = event.detail.value;
    if (event.detail.value == "Pending") {
      this.option = 'FACT. O/W NO WISE'
      this.optionList = [{ id: 1, name: 'FACT. O/W NO WISE', isSelected: true }, { id: 2, name: 'PENDING PD OF SS', isSelected: false }, { id: 3, name: 'PENDING ASSORTMENT OF MIX', isSelected: false }]
      this.columns = [
        { name: "Voucher No", prop: "" },
        { name: "Date & Time", prop: "modifiedDate" },
        { name: "Party", prop: "companyName" },
        { name: "Trans Type", prop: "transType" },
        { name: "Fact. O/w No.", prop: "factoryOutWNo" },
        { name: "Pending", prop: "" },
        { name: "Pending Ct.", prop: "" }
      ];
      this.getAllPendingAssortment(this.pageNumber, this.noOfRecords);
    } else if (event.detail.value == "Assigned") {
      this.option = 'FACT. O/W NO WISE'
      this.optionList = [{ id: 1, name: 'FACT. O/W NO WISE', isSelected: true }, { id: 2, name: 'ASSIGNED PD OF SS', isSelected: false }, { id: 3, name: 'ASSIGNED ASSORTMENT OF MIX', isSelected: false }]
      this.columns = [
        { name: "Voucher No", prop: "" },
        { name: "Date & Time", prop: "modifiedDate" },
        { name: "Trans Type", prop: "transType" },
        { name: "Total Assorter", prop: "totalAssorter" },
        { name: "allotedTo", prop: "allotedTo" },
        //{ name: "Barcode", prop: "Barcode" }
      ];
      this.showBarcodeBtn = true;
      this.getAllAssignAssortment(this.pageNumber, this.noOfRecords);
    }
  }

  async getAllAssignAssortment(pageNumber, noOfRecords) {
    console.log(pageNumber, noOfRecords)
    await this.configService.showLoader();
    this.listOfAssortmentDatatDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        await this.configService.dismiss();
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getAllAssignAssortment(
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfAssortmentData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfAssortmentData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res = await this.httpApiService.getAllAssignAssortment(
    //   pageNumber,
    //   noOfRecords
    // );
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("getAllAssignAssortment *****", data);
    //   this.listOfAssortmentData = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count1 = data.data.totalElements;
    //   console.log(" data.totalElements", data.totalElements);
    // }
  }

  async openConfirmationModal(flag, item) {
    this.refreshGrid = false;
    console.log(flag)
    if (item.type == "mouseenter") {
      //Stop event propagation and let onSelect() work
      item.event.stopPropagation();
    } else {
      if (!this.openView) {
        this.keyFun = false;
        this.status = item.row.id;
        const modal = await this.modalCtrl.create({
          component: ManageAssortmentPage,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: {
            data: item.row,
            type: flag,
            actionId: this.data.actionId
          }
        });
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
    }
  }

  async handleModalDismiss(d) {
    this.selectedStoneList = []
    this.selected = []
    this.isSelect = false;
    this.status = null;
    this.keyFun = true;
    this.refreshGrid = true;
    // this.getAllPendingAssortment();
    console.log("d", d);
    if (d.data == 1 || d.data == 3) {
      if (this.option == 'FACT. O/W NO WISE') {
        if (this.tab == 'Pending') {
          this.getAllPendingAssortment(this.pageNumber, this.noOfRecords);
        } else {
          this.getAllAssignAssortment(this.pageNumber, this.noOfRecords);
        }
      } else if (this.option == 'PENDING PD OF SS') {
        this.pageNumber = 0;
        this.noOfRecords = 20000;
        this.findPendingForAssignmentByTransType('SS', this.pageNumber, this.noOfRecords);
      }
      else if (this.option == 'PENDING ASSORTMENT OF MIX') {
        this.pageNumber = 0;
        this.noOfRecords = 20000;
        this.findPendingForAssignmentByTransType('MIX', this.pageNumber, this.noOfRecords);
      }
      else if (this.option == 'ASSIGNED PD OF SS') {
        this.pageNumber = 0;
        this.noOfRecords = 20000;
        this.findAssignedStockByTransType('SS', this.pageNumber, this.noOfRecords);
      }
      else if (this.option == 'ASSIGNED ASSORTMENT OF MIX') {
        this.pageNumber = 0;
        this.noOfRecords = 20000;
        this.findAssignedStockByTransType('MIX', this.pageNumber, this.noOfRecords);
      }
    } else if (d.data == 2) {
      this.getAllAssignAssortment(this.pageNumber, this.noOfRecords);
    } else {

    }
  }

  async setStoneWisePage(currentPage) {
    this.pageNumber = currentPage.offset;
    if (this.option == 'PENDING PD OF SS') {
      await this.findPendingForAssignmentByTransType('SS', this.pageNumber, this.noOfRecords);
    } else {
      await this.findPendingForAssignmentByTransType('MIX', this.pageNumber, this.noOfRecords);
    }
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getAllPendingAssortment(currentPage.offset, this.noOfRecords);
  }

  async setPageForAssigned(currentPage) {
    console.log(currentPage)
    this.pageNumber = currentPage.offset;
    this.getAllAssignAssortment(currentPage.offset, this.noOfRecords);
  }

  async openBarcode(event) {
    let data = event.row.data
    this.voucherBook = data.shortCode + "-" + data.bookSerialNumber;
    this.isOpen = true;
  }

  async closeModal() {
    this.isOpen = false;
    this.voucherBook = '';
  }

  async openScanModel() {
    this.refreshGrid = false;
    this.keyFun = false;
    const modal = await this.modalCtrl.create({
      component: ScanRFIDPage,
      backdropDismiss: false,
      componentProps: {
        type: "AssignAssortment"
      },
    });
    modal.onDidDismiss().then((d: any) => this.handleDissmissScanModal(d));
    return await modal.present();
  }

  async handleDissmissScanModal(d) {
    console.log(d)
    this.refreshGrid = true;
    this.keyFun = true;
    if (d.data != 'doNotRefresh') {
      const modal = await this.modalCtrl.create({
        component: ManageAssortmentPage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: {
          data: d.data,
          type: 3,
          actionId: this.data.actionId
        }
      });
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }

  }

  //selection dev-extreme grid

  OnSelectRows(ev) {
    this.selected = ev.selectedRowsData;
    this.selectedStoneList = []
    this.selected.forEach(el => {
      this.selectedStoneList.push(el.stoneId)
    });
  }

  async openPendingAssortment() {
    this.refreshGrid = false;
    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      setOfValues: this.selectedStoneList
    };
    res = await this.httpApiService.AssortmentIssue(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let datalist = data.data;
      if (datalist.length > 0) {
        const modal = await this.modalCtrl.create({
          component: ManageAssortmentPage,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: {
            data: datalist,
            type: 3,
            actionId: this.data.actionId
          }
        });
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
    }

  }

  async uploadForAssortment() {
    const modal = await this.modalCtrl.create({
      component: UploadAssortmentPage,
      backdropDismiss: false,
      cssClass: "extraLargePage",
      componentProps: {
      }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async selectAll(item) {

    if (item == true) {
      let arr = []
      this.selected = arr.concat(this.listOfPendingAssortment)
      this.isSelect = true;
      this.selectedStoneList = [];
      this.selected.forEach(el => {
        this.selectedStoneList.push(el.stoneId);
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.selectedStoneList = [];
    }
    console.log(this.selectedStoneList)
  }
}
