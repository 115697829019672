import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import {
  Conditions,
  PotentialBuyersService,
} from "src/app/services/potential-buyers/potential-buyers.service";

@Component({
  selector: "app-potential-buyers-selection-section",
  templateUrl: "./potential-buyers-selection-section.component.html",
  styleUrls: ["./potential-buyers-selection-section.component.scss"],
})
export class PotentialBuyersSelectionSectionComponent implements OnInit {
  @Output() fetchBtnClicked = new EventEmitter<void>();

  public stoneFeatures = "";
  public selectedStone = "";
  public selectedConditions: Conditions = {
    "Last 1 Month": false,
    "Last 3 Months": false,
    "Last 6 Months": false,
    "Last 1 Year": false,
    All: false,
    "Party with max count": true,
    "P & L (%)": false,
    "Ageing wise": false,
    duration: "",
  };

  constructor(private potentialBuyersService: PotentialBuyersService) {}

  ngOnInit() {
    this.potentialBuyersService.currStoneFeatures.subscribe((features) => {
      this.stoneFeatures = features;
    });
    this.potentialBuyersService.currSelectedStone.subscribe((stone) => {
      this.selectedStone = stone;
    });
  }

  initializeSelectedConditions() {
    this.selectedConditions["Last 1 Month"] = false;
    this.selectedConditions["Last 3 Months"] = false;
    this.selectedConditions["Last 6 Months"] = false;
    this.selectedConditions["Last 1 Year"] = false;
    this.selectedConditions["All"] = false;
  }

  onSelect(ev) {
    const selectedElem = ev.target;

    if (selectedElem.tagName === "ION-SELECT") {
      const selectedValues = selectedElem.value.includes("All")
        ? ["All"]
        : selectedElem.value;

      this.initializeSelectedConditions();
      selectedValues.forEach((selectedVal: string) => {
        switch (selectedVal) {
          case "1 Month":
            this.selectedConditions["Last 1 Month"] = true;
            break;
          case "3 Months":
            this.selectedConditions["Last 3 Months"] = true;
            break;
          case "6 Months":
            this.selectedConditions["Last 6 Months"] = true;
            break;
          case "1 Year":
            this.selectedConditions["Last 1 Year"] = true;
            break;
          case "All":
            this.selectedConditions["Last 1 Month"] = true;
            this.selectedConditions["Last 3 Months"] = true;
            this.selectedConditions["Last 6 Months"] = true;
            this.selectedConditions["Last 1 Year"] = true;
            this.selectedConditions["All"] = true;
            break;
        }
      });
    }

    if (
      selectedElem.tagName === "ION-CHECKBOX" ||
      selectedElem.tagName === "ION-SELECT"
    ) {
      this.potentialBuyersService.updateSelectedCondition(
        this.selectedConditions
      );
    }
  }

  fetchData() {
    this.fetchBtnClicked.emit();
  }
}
