export default {
  reportNo: 'R-22',
  url: "master-service/api/report/assortment-report-mix",
  method: "GET",
  size: 3,
  fields: [
  ],
  columns: [
  ],
  dataStudioLink: 'https://lookerstudio.google.com/embed/reporting/cd4cc678-5a19-4178-9d2a-1938a767c823/page/KHKRD'
};