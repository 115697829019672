import { Component, Input } from '@angular/core';
import { LoadingController, AlertController, ModalController, Events } from '@ionic/angular';
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageProgrammePage } from '../../actions/manage-programme/manage-programme.page';
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: 'app-programme',
  templateUrl: './programme.component.html',
  styleUrls: ['./programme.component.scss']
})
export class ProgrammeComponent implements ProcessActionComponent {

  public columns = []
  public page = 'Programme';
  public pageNumber = 0;
  public noOfRecords = 100;
  public pageSize = 100
  public count: Number;
  public userId: any;
  public listOfProgram = [];
  public listOfProgramDataSource: any = {};
  public items = []
  public totalPages;
  public manageSelectedProgram: any;
  public refreshGrid: boolean = false;

  @Input() data: any;
  constructor(public configService: ConfigService,
    public httpApiService: HttpApiService,
    public alertController: AlertController,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    private authService: AuthenticationService,
    public events: Events) { }

  ngOnInit() {
    this.columns = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Name", prop: "progName" },
      { name: "Customer Name", prop: "customerName" },
      { name: "isActive", prop: "isActive" }
    ];
    this.getAllProgram(this.pageNumber, this.noOfRecords);
  }

  async getAllProgram(pageNumber, noOfRecords) {
    // let res: any;
    // await this.configService.showLoader();
    // res = await this.httpApiService.getAllProgram(pageNumber, noOfRecords);
    // let data = res.json();
    // await this.configService.dismiss();
    // if (data.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   this.listOfProgram = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    //   this.setItems();
    //   this.count = this.listOfProgram.length;
    // }
    this.listOfProgramDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500, 5000].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getAllProgram(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfProgram = data.data.content;
            this.setItems();
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfProgram,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getAllProgram(currentPage.offset, this.noOfRecords);
  }

  async setItems() {
    this.items = this.listOfProgram;
  }


  async filterItems(ev: any) {
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        if (
          item.progName &&
          item.progName.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        } else if (
          item.customerName &&
          item.customerName.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        }
      });
    } else {
      this.setItems();
    }
  }

  async deleteConfirm(item) {
    const alert = await this.alertController.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Program ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteProgram(item.row.data);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteProgram(item) {
    let res: any;
    res = await this.httpApiService.deleteProgram(item.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.getAllProgram(this.pageNumber, this.noOfRecords);
    }
  }

  async manageProgramme(item, flag) {
    this.refreshGrid = false;
    if ((item.type == "mouseenter") || (item.column && item.column.name == 'Delete')) {
      //Stop event propagation and let onSelect() work
      item.event.stopPropagation();
    } else {
      let modal = await this.modalCtrl.create({
        component: ManageProgrammePage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: { item: item, flag: flag }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    if (d.data != 'doNotRefresh') {
      await this.getAllProgram(this.pageNumber, this.noOfRecords);
    }
  }

  async uploadExcelFile(item: any, flag: any) {
    let modal = await this.modalCtrl.create({
      component: ManageProgrammePage,
      backdropDismiss: false,
      cssClass: "extraLargePage",
      componentProps: { item: item, flag: flag }
    })
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
}
