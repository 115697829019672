import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service'

@Component({
  selector: 'app-memo-service',
  templateUrl: './memo-service.component.html',
  styleUrls: ['./memo-service.component.scss']
})
export class MemoServiceComponent implements OnInit {

  @Input() data: any

  public consignmentTab = 'Issue';
  public actionName: any;
  public actionId: any;

  constructor(
    public authService: AuthenticationService
  ) { }

  async ngOnInit() {
    let userData = await this.authService.userData();
    console.log("userData", userData, this.data);
    this.actionName = this.data.desc;
    this.actionId = this.data.actionId;
  }

}
