export default {
  "reportNo": "R-3",
  "url": "master-service/api/report/assortmentStockByCssp",
  "method": "GET",
  "size": 3,
  "fields": [
    {
      title: ' Assorted ',
      input: 'select',
      key: 'assorted',
      placeholder: 'Select Assorted',
      property: 'assorted',
      keyName: 'id',
      name: 'name',
      singleSelection: true,
      itemList: [{
        id: true,
        name: 'True'
      }, {
        id: false,
        name: 'False'
      }],
      default: [],
      size: 3
    }
  ],
  "columns": [
    { "name": "CSSP", "prop": "stoneId" },
    { "name": "Normal", "prop": "normal" },
    { "name": "Program", "prop": "program" },
    { "name": "Repairing", "prop": "recut" }
  ]
};