import { Component, OnInit } from "@angular/core";
import {
  ModalController,
  AlertController,
  LoadingController,
  Events,
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import * as moment from "moment";
import { reportsAll } from "./all-reports/all";
import { DomSanitizer } from "@angular/platform-browser";
import jspdf from "jspdf";
import html2canvas from "html2canvas";
import { ExportAsExcelService } from "src/app/services/export-as-excel.service";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
  public reportNo: any = false;
  newTracking = false;
  public newReportLink: any = null;
  public showPrice: boolean = false;
  public showReport: boolean = false;
  public priceListNameMasterId: any;
  public formValues = {};
  public extraValues = {};
  public date;
  public requiredValues: any = [];
  public data: any = [];
  public detailsData: any = [];
  public columns: any = [];
  public detailsColumns: any = [];
  public formData: any = [];
  public showPivotGrid: boolean = false;
  public pivotGridDataSource: any = {};
  public viewDetail: Boolean = false;
  public formDatas: any = [];
  public selectedReport: any;
  public selectedReportName: any;
  public items: any = [];
  public isReportListView: Boolean = true;
  public page = "reports";
  public totalSummary = [];
  public showTotalSummary: Boolean = true;
  public dataStudioLink = null;
  public currentTimeStamp = null;
  showReconciliation: boolean = false;
  showLabExpenseReport: boolean = false;
  labExpenseReportLink: any;
  rapnetBool: boolean = false;
  selectedStoneType: string = "discount";
  stoneDescription: string = "";
  discountPriceArray = [];
  rowData: any[] = [];
  showMixStockMovement: boolean = false;
  mixStockMovementLink: any;
  selectedType: string;
  stoneId: string;
  dateFrom: string;
  dateTo: string;
  labExpenseData: any;
  boxName: any = "";
  constructor(
    public modalCtrl: ModalController,
    public httpApiService: HttpApiService,
    public alertCtrl: AlertController,
    public authService: AuthenticationService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public storage: Storage,
    public sanitizer: DomSanitizer,
    public event: Events,
    private exportAsExcelService: ExportAsExcelService
  ) {
    // this.loadReport(this.reportNo);
  }

  async ngOnInit() {
    this.event.subscribe("Reports", (item) => {
      this.reportNo = false;
      this.showReport = false;
      this.reset();
      this.isReportListView = true;
      this.items.forEach((item) => (item.selected = false));
    });
    await this.getDefaultSetting();
    this.totalSummary = [
      {
        name: "Cts",
        prop: "stoneCts",
        summaryType: "sum",
      },
      {
        name: "Pcs",
        prop: "stonePcs",
        summaryType: "sum",
      },
      {
        name: "Memo Value",
        prop: "amount",
        summaryType: "sum",
      },
    ];
  }

  ngOnDestroy() {
    this.event.unsubscribe("Reports");
  }

  async showReportListView() {
    await this.reset();
    this.isReportListView = true;
  }

  async getTemplateDataById(templateId) {
    if (!!templateId) {
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.findTemplateById(templateId);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.dismiss();
        let dataValue = data.data;
        let data1 = [];

        let paramList = dataValue.listOfParameters;
        return paramList;
      }
    }
  }

  async loadReport(report) {
    this.isReportListView = false;
    await this.reset();
    if (report.reportName.includes("R-18")) {
      this.showReconciliation = true;
    }
    if (report.reportName.includes("R-28")) {
      this.rapnetBool = true;
    }
    // if(report.reportName.includes('R-27') ){
    //   this.showLabExpenseReport = true;
    //   let link = reportsAll[26]['dataStudioLink'];
    //   this.labExpenseReportLink = this.sanitizer.bypassSecurityTrustResourceUrl(link);
    // }
    // if(report.reportName.includes('R-29') ){
    //   this.showMixStockMovement = true;
    //   let link = reportsAll[28]['dataStudioLink'];
    //   this.mixStockMovementLink = this.sanitizer.bypassSecurityTrustResourceUrl(link);
    // }

    console.log("load report reset ", this.formData, this.reportNo);
    let reportNo = report.id;
    report.selected = true;
    this.selectedReportName = report.value;
    this.selectedReport = report;
    this.data = [];
    this.columns = [];
    this.detailsData = [];
    this.detailsColumns = [];
    this.showReport = false;
    this.formValues = {};
    this.items.filter((item) => {
      if (item.id != reportNo) item.selected = false;
    });
    console.log(reportsAll);

    let reportFormData = reportsAll.filter((form) => form.reportNo == reportNo);
    console.log(reportFormData);
    if (reportFormData.length != 1) {
      this.configService.showToast("error", "Report Not Found");
    } else {
      this.reportNo = reportNo;
      this.formData = reportFormData[0];
      if (!!this.formData.dataStudioLink) {
        this.dataStudioLink = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.formData.dataStudioLink
        );
        return true;
      }
      console.log("load report ", this.formData);
      // console.log(this.formData.fields[6]);

      this.formData.fields.filter((item) => {
        if (item.input == "hidden") {
          this.formValues[item.key] = item.default;
        }
        if (
          item.input == "select" &&
          !!item.default &&
          item.default.length > 0 &&
          this.selectedReportName != "Lab Compare Report"
        ) {
          this.formValues[item.key] = item.default[0].id;
        }
      });

      if (this.selectedReportName == "Lab Compare Report") {
        this.showTotalSummary = false;
        console.log(this.selectedReport);
        if (
          !!this.selectedReport.reportValue &&
          !!this.selectedReport.reportValue.defaultTemplate
        ) {
          this.formData.fields = this.formData.fields.map((x) => {
            if (x.key == "templateId") {
              x.default = this.selectedReport.reportValue.defaultExportIdList; //[2]//this.selectedReport.reportValue.defaultTemplate
              this.formValues[x.key] =
                this.selectedReport.reportValue.defaultExportList[0];
            }
            return x;
          });
          let paramList = await this.getTemplateDataById(
            this.selectedReport.reportValue.defaultExportList[0]
          );
          console.log(paramList);
          this.formData.columns = paramList.map((col) => {
            let colRowDto = { name: "", prop: "", required: true };
            if (col.headerName && col.masterName) {
              colRowDto.name = col.headerName;
              colRowDto.prop = col.masterName;
            }
            return colRowDto;
          });
        }
      }
      // this.formData.fields = this.formData.fields.filter(item => item.input != 'hidden');
      for (let i = 0; i < this.formData.fields.length; i++) {
        if (!!this.formData.fields[i].function) {
          console.log(this.formData.fields[i].function);
          let res = await this[this.formData.fields[i].function](
            this.formData.fields[i].setting
          );
          this.formData.fields[i].itemList = res;
        }
      }
      this.requiredValues = this.formData.fields
        .filter((res) => res.required)
        .map((res) => res.key);
      this.formData.fields.map((res) => {
        if (res.input == "dateTime") {
          this.formValues[res.key] = moment().format(
            res.setting.format.toUpperCase()
          );
        }
      });
      console.log("--------------");

      console.log(this.formData.fields);
      if (!!this.formData.loadInitially) {
        await this.getReport(this.formData.url, this.formValues);
      }
    }
  }

  async dataLoad(column, data) {
    column = column.filter((res) => {
      let rt = true;
      if (res.ngIf) {
        if (res.required) {
          if (this.formValues[res.ngIf[0]] != res.ngIf[1]) {
            return false;
          }
        } else {
          if (!!this.formValues[res.ngIf[0]]) {
            if (this.formValues[res.ngIf[0]] != res.ngIf[1]) {
              return false;
            }
          }
        }
      }
      return rt;
    });
    column
      .filter((col) => !!col.props)
      .filter((res) => {
        data = data.map((single) => {
          single[res.name] = res.props
            .map((keyName) => single[keyName])
            .filter((keyName) => !!keyName)
            .join(res.join);
          return single;
        });
      });

    column
      .filter((col) => !!col.dynamic)
      .filter((res) => {
        data = data.map((single) => {
          let no = single[res.dynamic[0]];
          for (let i = 1; i < res.dynamic.length; i++) {
            switch (res.dynamic[i]) {
              case "+":
                no += single[res.dynamic[++i]];
                break;
              case "-":
                no -= single[res.dynamic[++i]];
                break;
              case "*":
                no *= single[res.dynamic[++i]];
                break;
              case "/":
                no /= single[res.dynamic[++i]];
                break;
              case " - ":
                no += " - " + single[res.dynamic[++i]];
                break;
              default:
                console.error("Please Check Dynamic Keys");
                break;
            }
          }

          single[res.name] = no || 0;
          return single;
        });
      });

    this.data = data;
    this.columns = column.map((res) => {
      if (res.prop) {
        return res;
      } else {
        res.prop = res.name;
        return res;
      }
    });
  }

  async onChange(ev, item) {
    if (item.isMultiple) {
      this.formValues[item.key] = ev.value.map((id) => id.id);
    } else {
      this.formValues[item.key] = ev.value.id;
    }
  }

  async serverSelect(ev, item, pageNo = item.setting.pageNo) {
    let obj = {};

    for (let i = 0; i < item.setting.paramter.require.length; i++) {
      let value = this.formValues[item.setting.paramter.require[i]];
      if (!value) {
        let filter = this.formData.fields.filter(
          (field) => field.key == item.setting.paramter.require[i]
        )[0];
        await this.configService.showToast(
          "error",
          filter.title + " is Required "
        );
        return;
      } else {
        obj[item.setting.paramter.require[i]] = value;
      }
    }

    for (let i = 0; i < item.setting.paramter.optional.length; i++) {
      let value = this.formValues[item.setting.paramter.optional[i]];
      if (value) {
        obj[item.setting.paramter.optional[i]] = value;
      }
    }

    obj[item.setting.paramter.searchBy] = ev.text;
    obj["pageNumber"] = pageNo;
    obj["noOfRecords"] = 5;
    item.setting.pageNo = ++pageNo;
    item.search = ev.text;
    let res: any = await this.httpApiService.getReport(
      item.setting.url,
      obj,
      this.formData.method
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        if (item.itemList && item.itemList.length > 0) {
          data.data.content.filter((res) => {
            item.itemList.push({
              id: res,
              name: res,
            });
          });
        } else {
          item.itemList = data.data.content.map((res) => {
            return {
              id: res,
              name: res,
            };
          });
        }
      }
    }
    ev.component.endInfiniteScroll();
  }

  async getellCompanies() {
    let res: any;
    res = await this.httpApiService.getAllCompanies();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      return data.data;
    }
  }

  // async getAllLocations() {
  //   return [
  //     {
  //       id: 1,
  //       name: "hk"
  //     },
  //     {
  //       id: 2,
  //       name: 'Mumbai'
  //     }
  //   ]
  // }

  async getAllDepartment() {
    let res: any;
    res = await this.httpApiService.getAllDepartment(
      this.configService.appName
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (
        !!this.selectedReport.reportValue &&
        !!this.selectedReport.reportValue.departmentList
      ) {
        let result = data.data.filter((item) => {
          return (
            this.selectedReport.reportValue.departmentList.indexOf(item.id) !==
            -1
          );
        });
        console.log("department result ", result);
        return result;
      } else {
        return [];
      }
      // return data.data;
    }
  }

  async getAllActive(setting) {
    let res: any;
    res = await this.httpApiService.getAllActive(this.configService.appName);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let array = data.data;
      Object.keys(setting).filter((res) => {
        array = array.filter((single) => single[res] == setting[res]);
      });
      return array;
    }
  }

  async getAllDefaultTemplates() {
    let res: any;
    res = await this.httpApiService.findAllTemplate(true);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      //return data.data;
      if (
        !!this.selectedReport.reportValue &&
        !!this.selectedReport.reportValue.defaultExportList
      ) {
        let result = data.data.filter((item) => {
          return (
            this.selectedReport.reportValue.defaultExportList.indexOf(
              item.id
            ) !== -1
          );
        });
        console.log("template result ", result);
        return result;
      } else {
        return [];
      }
    }
  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (
        !!this.selectedReport.reportValue &&
        !!this.selectedReport.reportValue.transTypeList
      ) {
        let result = data.data.filter((item) => {
          return (
            this.selectedReport.reportValue.transTypeList.indexOf(item.id) !==
            -1
          );
        });
        console.log("transtype result ", result);
        return result;
      } else {
        return [];
      }
      // return data.data;
    }
  }

  async getAllShape() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("shape");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      return data.data;
    }
    // console.log("shapeList", this.shapeList);
  }

  async getAllPartyLab() {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType("Lab");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      return data.data;
    }
    // console.log("shapeList", this.shapeList);
  }

  async getAllPolicy() {
    let res: any;
    res = await this.httpApiService.getAllPolicy();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      return data.data;
    }
    // console.log("shapeList", this.shapeList);
  }

  async getAllFluorescence() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("fluorescence");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      return data.data;
    }
    // console.log("shapeList", this.shapeList);
  }

  async getAllCut() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("cut");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      return data.data;
    }
    // console.log("shapeList", this.shapeList);
  }

  async getAllColour() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("color");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      return data.data;
    }
    // console.log("shapeList", this.shapeList);
  }

  async getAllAssortment() {
    let res: any;
    res = await this.httpApiService.getAllTypeFor("Assort");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      return data.data;
      // console.log("colorList", this.colorList);
    }
  }

  async getAllClarity() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("clarity");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      return data.data;
      // console.log("clarityList", this.clarityList);
    }
  }

  async showPriceList() {
    if (this.showPrice) {
      this.priceListNameMasterId = 7;
      this.reportNo = "R-1";
    }
  }

  lengthMatch() {
    return (
      this.requiredValues.filter((res) => {
        if (typeof this.formValues[res] == "boolean") {
          if (this.formValues[res] == true || this.formValues[res] == false) {
            return false;
          } else {
            return true;
          }
        } else {
          return !this.formValues[res];
        }
      }).length == 0
    );
  }

  reset() {
    this.showReport = false;
    this.reportNo = null;
    this.viewDetail = false;
    this.dataStudioLink = null;
    Object.keys(this.formValues).filter((res) => {
      // console.log("res ", res, this.formValues, this.formData);
      this.formValues[res] = null;
    });
    this.formData = null;
    this.items.filter((item) => (item.selected = false));
    this.selectedReport = "";
    this.data = [];
    this.columns = [];
    this.detailsData = [];
    this.detailsColumns = [];
    this.formValues = {};
    this.requiredValues = [];
    this.showReconciliation = false;
    this.newTracking = false;
    this.showLabExpenseReport = false;
    this.rapnetBool = false;
    this.showMixStockMovement = false;
  }

  onDateSelect(keyName, setting, ev) {
    this.formValues[keyName] = moment(ev).format(setting.format.toUpperCase());
    console.log(this.formValues[keyName]);
  }

  async getReport(url, obj) {
    this.currentTimeStamp = moment().format("DD-MM-YYYY hh:mm:ss a");
    this.viewDetail = false;
    this.showReport = false;
    this.data = [];
    this.columns = [];
    this.detailsData = [];
    this.detailsColumns = [];

    if (this.selectedReportName == "Lab Compare Report") {
      let res: any;
      let stoneIds = [];

      if (obj.setOfValues.indexOf("\n") >= -1) {
        stoneIds = obj.setOfValues.split("\n");
      }

      obj.setOfValues = stoneIds.filter((n) => n);

      res = await this.httpApiService.compareLabReport(obj);
      let data = res.json();
      await this.configService.dismiss();
      this.formData.fields.filter((item) => {
        if (item.key == "setOfValues") {
          this.formValues[item.key] = "";
        }
      });

      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else {
        if (res.status == 400) {
          await this.configService.showToast("error", data.error);
        } else {
          this.showReport = true;
          this.dataLoad(this.formData.columns, data.data);
        }
      }
    } else {
      await this.getPriceListName();
      await this.configService.showLoader();
      let res: any;
      if (!!obj.fromDate) {
        obj.fromDate = moment(obj.fromDate)
          .set({ hour: 0, minute: 0, second: 0 })
          .format("YYYY-MM-DD HH:mm:ss");
      }
      if (!!obj.toDate) {
        obj.toDate = moment(obj.toDate)
          .set({ hour: 23, minute: 59, second: 59 })
          .format("YYYY-MM-DD HH:mm:ss");
      }
      res = await this.httpApiService.getReport(url, obj, this.formData.method);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else {
        if (res.status == 400) {
          await this.configService.showToast("error", data.error);
        } else {
          this.showReport = true;
          this.dataLoad(this.formData.columns, data.data);
        }
      }
    }
  }

  async getSubReport(event, subColumn) {
    this.boxName = "";
    if (event["row"].boxLabel) {
      this.boxName = event["row"].boxLabel;
    }
    if (subColumn.length != 0) {
      await this.configService.showLoader();
      let res: any;
      let obj: any = {};
      Object.keys(subColumn.obj).filter((key) => {
        if (subColumn.obj[key] && subColumn.obj[key].length == 1) {
          obj[key] = event.row[subColumn.obj[key][0]];
        } else if (key == "boxName") {
          obj[key] = !!event.row.boxLabel ? event.row.boxLabel : null;
        } else {
          obj[subColumn.obj[key]] = this.formValues[key];
        }
      });
      if (this.reportNo == "R-10") {
        if (event.columnName == "Mix Cts") {
          obj["transType"] = "MIX";
        } else if (
          event.columnName == "Single Cts" ||
          event.columnName == "Single Pcs"
        ) {
          obj["transType"] = "SS";
        }
      }
      obj["priceListNameMasterId"] = 8;

      res = await this.httpApiService.getReport(
        subColumn.url,
        obj,
        this.formData.method
      );
      let data = res.json();
      await this.loadingController.dismiss();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else {
        if (res.status == 400) {
          await this.configService.showToast("error", data.error);
        } else {
          data.data = data.data.map((obj: any, i: number) => ({
            ...obj,
            index: i + 1,
          }));
          this.viewDetail = true;
          this.showPivotGrid = subColumn.showPivotGrid;

          if (this.showPivotGrid) {
            this.pivotGridDataSource = {
              fields: subColumn.fields,
              store: data.data,
            };
          } else {
            this.detailsData = data.data;
            this.detailsColumns = subColumn.fields;
          }
        }
      }
    }
  }

  changeView() {
    if (!this.viewDetail) {
      this.viewDetail = true;
    } else {
      this.viewDetail = false;
    }
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    let userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (!!dataValue && !!dataValue.map && !!dataValue.map.reports) {
        let reportsJson = JSON.parse(dataValue.map.reports);
        this.items = reportsJson.filter((report) => {
          let reportName = report.reportName;
          let reportNameWithFile = reportName.split(":").map(function (item) {
            return item.trim();
          });
          if (!!reportNameWithFile && reportNameWithFile.length > 0) {
            report.id = reportNameWithFile[0];
            report.value = reportNameWithFile[1];
          }
          report.selected = false;
          return report.reportValue.enable;
        });
        console.log("reportsJson ", reportsJson);
      }
      console.log("dataValue ", dataValue);
    }
  }

  async getPriceListName() {
    console.log(this.formValues);
    this.showPrice = false;
    // this.formValues['priceListNameMasterId'] = '';
    if (!!this.selectedReport.reportValue) {
      if (!!this.selectedReport.reportValue.showPrice) {
        this.showPrice = true;
        this.selectedReport.reportValue.pricingJson.filter((item) => {
          if (
            !!item.department &&
            !!item.transType &&
            !!item.priceListName &&
            item.department.id == this.formValues["departmentId"] &&
            item.transType.name == this.formValues["transType"]
          ) {
            this.formValues["priceListNameMasterId"] = item.priceListName.id;
          }
        });

        if (!this.formValues["priceListNameMasterId"]) {
          this.selectedReport.reportValue.pricingJson.filter((item) => {
            if (
              !!item.department &&
              !!item.transType &&
              !!item.priceListName &&
              item.transType.name == this.formValues["transType"]
            ) {
              this.formValues["priceListNameMasterId"] = item.priceListName.id;
            }
          });
        }
      } else {
        try {
          if (
            this.selectedReport.reportValue.priceListName &&
            this.selectedReport.reportValue.priceListName.length > 0
          ) {
            this.formValues["priceListNameMasterId"] =
              this.selectedReport.reportValue.priceListName[0].id;
          }
        } catch (e) {
          console.log(e);
        }
      }
      console.log("getPriceListName result ", this.formValues);
    }
  }

  multiSelectCallFunction(event, item) {
    this.formValues[item.key] = event;
    this.showReport = false;
    this.viewDetail = false;
  }
  checkView(ev: any) {
    if (ev.detail.checked == true) {
      this.newTracking = true;
      let link = reportsAll[17]["newDataStudioLink"];
      this.newReportLink = this.sanitizer.bypassSecurityTrustResourceUrl(link);
    } else {
      this.newTracking = false;
    }
  }

  async fetchPlanAndSalesStones() {
    if (this.discountPriceArray.length === 0) {
      this.columns = [
        {
          name: "Sr No",
          cellTemplate: function (cellElement, cellInfo) {
            const pageIndex = cellInfo.component.pageIndex();
            const pageSize = cellInfo.component.pageSize();
            cellElement.append(pageIndex * pageSize + cellInfo.rowIndex + 1);
          },
        },
        {
          name: "STONE_ID",
          prop: "StoneName",
        },
        {
          name: "DAY1",
          prop: "modifiedDate",
        },
        {
          name: "No Of Journeys",
          prop: "journeyDays",
        },
      ];
    } else {
      this.columns.pop();
      this.columns.pop();
    }
    let rowDataItem = {};

    if (
      this.discountPriceArray.length === 0 ||
      (!!this.discountPriceArray.length &&
        this.discountPriceArray[0].stoneName !== this.stoneDescription)
    ) {
      await this.getDiscountPriceData(this.stoneDescription);
    }

    if (this.discountPriceArray.length > 0) {
      this.rowData = [];
      rowDataItem = {
        StoneName: this.discountPriceArray[0].stoneName,
        modifiedDate: this.discountPriceArray[0].uploadDate,
        journeyDays: this.discountPriceArray.length,
      };

      if (this.selectedStoneType == "discount") {
        let obj = {};
        this.columns.push(
          {
            name: "RAPNET_DISC",
            prop: "rapnet_disc",
          },
          {
            name: "Days",
            prop: "day",
          }
        );
        for (let i = 0; i < this.discountPriceArray.length; i++) {
          obj["rapnet_disc"] = this.discountPriceArray[i].discountPercent;
          obj["day"] = this.discountPriceArray[i].ageingDays;
          if (i !== 0) {
            this.rowData.push({ ...obj });
          } else {
            this.rowData.push({ ...rowDataItem, ...obj });
          }
        }
      } else {
        let obj = {};
        this.columns.push(
          {
            name: "RAPNET_PRICE",
            prop: "rapnet_price",
          },
          {
            name: "Days",
            prop: "day",
          }
        );
        for (let i = 0; i < this.discountPriceArray.length; i++) {
          obj["rapnet_price"] = this.discountPriceArray[i].price;
          obj["day"] = this.discountPriceArray[i].ageingDays;
          if (i !== 0) {
            this.rowData.push({ ...obj });
          } else {
            this.rowData.push({ ...rowDataItem, ...obj });
          }
        }
      }
    }
  }

  async getDiscountPriceData(stoneName: any) {
    await this.configService.showLoader();
    let res = await this.httpApiService.getRapnetDiscountPrice(stoneName);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.discountPriceArray = data.data;
    }
  }

  async submitForm(formValue: any) {
    let payload = {
      fromDate: "",
      stoneIds: [],
      expenseType: [],
      toDate: "",
    };
    if (formValue.dateFrom != "") {
      payload.fromDate = formValue.dateFrom;
    }
    let x = formValue.stoneId.split("\n");
    if (x.length > 0) {
      if (x[x.length - 1] === "") {
        x.pop();
        payload.stoneIds = x;
      } else {
        payload.stoneIds = x;
      }
    }
    if (formValue.selectedType.length > 0) {
      payload.expenseType = formValue.selectedType;
    }
    if (formValue.dateTo != "") {
      payload.toDate = formValue.dateTo;
    }
    await this.getLabExpenseReport(payload);

    this.columns = [
      {
        name: "Sr No",
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        },
      },
      {
        name: "Created Date",
        prop: "createdDate",
      },
      {
        name: "Expense Type",
        prop: "expenseType",
      },
      {
        name: "Stone Name",
        prop: "stoneId",
      },
      {
        name: "Invoice No",
        prop: "invoiceNo",
      },

      {
        name: "Invoice Date",
        prop: "invoiceDate",
      },
      {
        name: "CGST",
        prop: "cgst",
      },
      {
        name: "IGST",
        prop: "igst",
      },
      {
        name: "SGST",
        prop: "sgst",
      },
      {
        name: "Total GST",
        prop: "totalGst",
      },
      {
        name: "Fee",
        prop: "fee",
      },
      {
        name: "Handling Charges",
        prop: "handlingCharges",
      },
      {
        name: "Total Amount",
        prop: "totalAmt",
      },
      {
        name: "Service Desc.",
        prop: "serviceDesc",
      },
      {
        name: "Item Desc.",
        prop: "itemDesc",
      },
      {
        name: "Job No",
        prop: "jobNo",
      },
      {
        name: "ControlNo",
        prop: "controlNo",
      },
    ];
    this.rowData = this.labExpenseData.map((obj: any) => {
      obj.createdDate = moment(obj.createdDate).format("YYYY-MM-DD");
      return obj;
    });
  }

  async getLabExpenseReport(payload: any) {
    await this.configService.showLoader();
    let res = await this.httpApiService.getLabExpenseReport(payload);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.labExpenseData = data.data;
    }
  }

  // downloadStockReportInPDF() {
  //   var element = document.getElementById('element-to-print');
  //   var opt = {
  //     margin: 1,
  //     filename: 'myfile.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  //   };
  //   // New Promise-based usage:
  //   // html2pdf().from(element).set(opt).save();
  //   // Old monolithic-style usage:
  //   // html2pdf(element, opt);
  // }

  public downloadAsPDF() {
    var data = document.getElementById("printpdf");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save(
        `Department Wise All Stock-${moment().format("DD-MM-YYYY hh:mm")}.pdf`
      ); // Generated PDF
    });
  }

  async downloadFullStock() {
    await this.configService.showLoader();
    const res = await this.httpApiService.getFullStackReport();
    const fullStockData = JSON.parse(res._body).data;
    const fileName = "Stock Report" + "_" + this.currentTimeStamp.slice(0, 16);

    this.exportAsExcelService.exportAsExcelFile(fullStockData, fileName);
    await this.configService.dismiss();
  }
}
