import { Component, Input, HostListener, ViewChild, ElementRef } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { LoadingController, ModalController, AlertController, Events, IonInput } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { SearchService } from "src/app/services/search/search.service";
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-update-rfid',
  templateUrl: './update-rfid.component.html',
  styleUrls: ['./update-rfid.component.scss']
})
export class UpdateRfidComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild('searchInput') sInput;
  public boardBaseUrl: any;
  public rfid: any;
  public stoneId: any;
  public barcode: any;
  public page = 'UpdateRFID';
  public columns = [];
  public templateUpdatedlist = [];
  constructor(
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public configService: ConfigService,
    public event: Events,
    private es: SearchService,
  ) {
    this.event.subscribe('Update RFID', (item) => {
      setTimeout(() => {
        this.sInput.setFocus();
      }, 800);
      this.templateUpdatedlist = [];
      this.stoneId = null;
      this.rfid = null;
      this.barcode = null;
    })
  }

  ngOnInit() {
    setTimeout(() => {
      this.sInput.setFocus();
    }, 800);
    console.log(document.getElementById("barcode"))
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, { name: "Barcode", prop: "barcode" }, { name: "StoneId", prop: "stoneId" }, { name: "RFID", prop: "rfid" },
    ];
    this.getUserData()
  }

  ngOnDestroy() {
    this.event.unsubscribe('Update RFID');
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
  }

  async scanRfidToAssign() {
    let res: any;
    await this.httpApiService.scanLedOff(this.boardBaseUrl, [1, 2]);
    // await this.configService.showLoader();
    res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
    let data = res.json();
    console.log(data);
    // await this.configService.dismiss();
    if (data.type == "error") {
      await this.configService.showToast("error", data.type);
    } else {
      if (data.result.length > 0) {
        if (data.result.length == 1) {
          this.rfid = data.result[0];
          this.checkRfidStatus()
        } else {
          await this.configService.showToast("error", "Multiple RFIDs found. Please set anyone and try again.");
        }
      } else {
        await this.configService.showToast("error", "RFID not found.");
      }
    }
  }

  async confirmUpdateRfidStatus() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: " Are you sure you want to update? ",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.checkRfidStatus();
          }
        }
      ]
    });

    await alert.present();
  }

  async checkRfidStatus() {
    if (!!this.rfid) {
      let jsonObj = {
        rfid: this.rfid,
        stoneId: this.stoneId,
        isUpdate: true
      }
      await this.configService.showLoader()
      let res: any;
      res = await this.httpApiService.assignRfidToStone(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss()
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        // await this.confirmUpdateRfid(data.error)
        this.assignRfidToStone();
      } else {
        this.templateUpdatedlist.push({
          barcode: this.barcode,
          stoneId: this.stoneId,
          rfid: this.rfid
        })
        this.stoneId = null;
        this.rfid = null;
        this.barcode = null;
        setTimeout(() => {
          this.sInput.setFocus();
        }, 800);
        await this.configService.showToast("success", data.message);

      }
    } else {
      await this.configService.showToast("error", 'Please scan RFID or add manually to check status.');
    }
  }


  async onChange(ev) {
    this.stoneId = null;
    let qry: any =
    {
      "query": {
        "bool": {
          "should": [
            {
              "simple_query_string": {
                "query": ""
              }
            },
            {
              "bool": {
                "must": []
              }
            }
          ]
        }
      }
    }
    console.log("ev.target.value", ev.target.value)
    if (!!ev.target.value) {
      console.log(ev.target.value)
      this.barcode = ev.target.value;
      let boolShouldMultiple = {
        "bool": {
          "should": []
        }
      };
      // let boolMustMultiple = {
      //   "bool": {
      //     "must": []
      //   }
      // };
      let bData = {
        terms: {
          "Barcode": [ev.target.value.toUpperCase()]
        }
      }
      let fmData = {
        terms: {
          "FMBarcode": [ev.target.value]
        }
      }
      let sData = {
        terms: {
          "stoneName": [ev.target.value.toUpperCase()]
        }
      }
      let location = {
        "terms": { location: ["mumbai"] }
      }
      boolShouldMultiple.bool.should.push(bData);
      boolShouldMultiple.bool.should.push(fmData);
      boolShouldMultiple.bool.should.push(sData);
      qry.query.bool.should[1].bool.must.push(location);
      qry.query.bool.should[1].bool.must.push(boolShouldMultiple);

      let data = await this.es.getPaginatedDocuments(qry, 0, environment.INDEX, '', 1)
      if (!!data.hits.hits && data.hits.hits.length > 0) {
        this.stoneId = data.hits.hits[0]._source.stoneName.toString();
        this.scanRfidToAssign()
      } else {
        this.configService.showToast('error', 'barcode with stoneId not found')
      }
    }
  }

  async confirmUpdateRfid(message) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: message + " Are you sure you want to change RFID ? ",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.assignRfidToStone();
          }
        }
      ]
    });

    await alert.present();
  }

  async assignRfidToStone() {
    if (!!this.stoneId && this.rfid) {
      let res: any;
      let jsonObj = {
        rfid: this.rfid,
        stoneId: this.stoneId,
        isUpdate: true
      }
      res = await this.httpApiService.assignRfidToStone(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.templateUpdatedlist.push({
          barcode: this.barcode,
          stoneId: this.stoneId,
          rfid: this.rfid
        })
        this.stoneId = null;
        this.rfid = null;
        this.barcode = null;
        setTimeout(() => {
          this.sInput.setFocus();
        }, 800);
        await this.configService.showToast("success", data.message);
      }
    } else {
      await this.configService.showToast("error", 'Please enter stoneId/RFID');
    }
  }

}