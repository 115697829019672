import { Component, ViewChild, Input } from "@angular/core";
import { ModalController, LoadingController, Events, AlertController } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ManageInterCsspTransferPage } from "../../actions/manage-inter-cssp-transfer/manage-inter-cssp-transfer.page";
import * as moment from "moment";
import { IonicSelectableComponent } from 'ionic-selectable';
import Query from "devextreme/data/query";
import CustomStore from "devextreme/data/custom_store";

@Component({
  selector: 'app-inter-cssptransfer',
  templateUrl: './inter-cssptransfer.component.html',
  styleUrls: ['./inter-cssptransfer.component.scss']
})
export class InterCSSPTransferComponent implements ProcessActionComponent {
  @Input() data: any;

  public type = 'from';
  public csspList = [];
  public fromTransferCssp: any;
  public columns = []
  public toTransferCssp: any;
  public userId: any;
  public showSelected = true;
  public historyDataSource = {};
  public sizeType: any;
  public count: Number = 0;
  public showSelectedFromFlag = false;
  public showSelectedToFlag = false
  public transTypeSearch: any;
  public internalStatus: any;
  public showHistory = false;
  public histories = [];
  public pageNumber = 0;
  public noOfRecords = 20;
  public historyColumn = [];
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public selectedCssp: any;
  public refreshGrid: Boolean = false;
  public pageSize = 50;

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public event: Events,
    public alertCtrl: AlertController
  ) { }

  async ngOnInit() {
    this.columns = [
      { name: "CSSP", prop: "stoneId" },
      { name: "Ct.", prop: "size" },
      { name: "Type", prop: "" },
      { name: "Box Name", prop: "boxName" },
    ]
    this.historyColumn = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "From CSSP", prop: "fromCsspStoneId" },
    { name: "To CSSP", prop: "toCsspStoneId" },
    { name: "Ct.", prop: "cts" },
    { name: "Created Date", prop: "createdDate" },
    ];
    await this.getDefaultSetting()
    this.loadCertificationFileUploadHistory();
    this.event.subscribe('Inter Cssp Transfer', (item) => {
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Inter Cssp Transfer')
  }

  async manageCsspTransfer() {
    let modal = await this.modalCtrl.create({
      component: ManageInterCsspTransferPage,
      backdropDismiss: false,
      cssClass: "largePage",
      componentProps: {}
    })
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      this.csspList = []
    }
  }


  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.type) {
          this.sizeType = dataValue.map.type
        }
        if (!!dataValue.map.internalStatus) {
          this.internalStatus = dataValue.map.internalStatus
        }
      }
    }
  }


  changeType() {
    this.csspList = []
    this.selectedCssp = null
    if (this.type == 'to') {
      this.toTransferCssp = null;
    }
  }

  onChangeCssp(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (!!event.value) {
      if (this.type == 'from') {
        this.fromTransferCssp = event.value;
        if (!!this.fromTransferCssp.boxType && this.fromTransferCssp.boxType == 'Purchase') {
          this.transTypeSearch = ['Purchase']
        } else if (!!this.fromTransferCssp.boxType && this.fromTransferCssp.boxType == 'Program') {
          this.transTypeSearch = ['Regular', 'Program-Parcel', 'Parcel', 'Program', 'Assortment-OK']
        } else {
          this.transTypeSearch = ['Regular', 'Program-Parcel', 'Parcel', 'Program', 'Assortment-OK']
        }
        this.showSelectedFromFlag = true;
      } else {
        this.toTransferCssp = event.value;
        this.showSelectedToFlag = true;
      }
    } else {
    }
  }

  async onSearch(event: { component: IonicSelectableComponent; text: string }) {
    let searchText = event.text.trim().toLowerCase();
    if (!!searchText) {
      await this.searchCSSP(searchText);
    }
  }

  async searchCSSP(searchText) {
    let res: any;
    this.csspList = []
    console.log(this.type)
    if (this.type == 'from') {
      let jsonObj = {
        searchValue: !!searchText ? searchText : '',
        internalStatus: this.internalStatus,
        listOfStoneStatus: [5],
        listOfBoxType: ['Regular', 'Program-Parcel', 'Parcel', 'Purchase', 'Program', 'Assortment-OK'],
        pageNumber: 0,
        noOfRecords: 10,
        availableStock: true
      }
      res = await this.httpApiService.findCsspOrCreateIfNotExist(jsonObj, 'false');
    } else {
      let jsonObj = {
        searchValue: !!searchText ? searchText : '',
        internalStatus: this.internalStatus,
        listOfStoneStatus: [5],
        listOfBoxType: ['Regular'],
        pageNumber: 0,
        noOfRecords: 10
      }
      res = await this.httpApiService.findCsspOrCreateIfNotExist(jsonObj, 'true');
    }
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValueList = data.data.content;
      if (dataValueList.length > 0) {
        for (let i = 0; i < dataValueList.length; i++) {
          let name = await this.showStoneId(dataValueList[i].stoneId);
          dataValueList[i].csspName = name + " ( " + (!!dataValueList[i].transMode ? dataValueList[i].transMode : (!!dataValueList[i].programmeNo ? dataValueList[i].programmeNo : 'Regular')) + " ) (Box: " + dataValueList[i].boxName + ") (Size: " + dataValueList[i].size + " )";
        }
        this.csspList = dataValueList;
        this.count = this.csspList.length;
        if (this.type == 'from') {
          this.toTransferCssp = null;
        }
      } else {
        await this.configService.showToast('error', 'Searched Cssp not found.')
      }
    }
    console.log(this.csspList)
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  async transferCSSP() {
    if (!!this.fromTransferCssp.transferCts && parseFloat(this.fromTransferCssp.transferCts) > 0) {
      let res: any;
      await this.configService.showLoader()
      let jsonObj = {
        fromCsspId: this.fromTransferCssp.id,
        toCsspId: this.toTransferCssp.id,
        cts: this.fromTransferCssp.transferCts
      }
      console.log(jsonObj)
      res = await this.httpApiService.stoneInterCsspTransfer(jsonObj);
      let data = res.json()
      await this.loadingController.dismiss()
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.fromTransferCssp = null;
        this.toTransferCssp = null;
        this.showSelectedFromFlag = false;
        this.showSelectedToFlag = false;
        this.changeType();
      }
    } else {
      await this.configService.showToast('error', 'Please insert valid size value')
    }
  }

  async deleteConfirm(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.delete(item.row.data);
          }
        }
      ]
    });

    await alert.present();
  }

  async delete(item) {
    let res: any;
    res = await this.httpApiService.deleteTransferedCsspStone(item.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.loadCertificationFileUploadHistory();
    }
  }

  async loadCertificationFileUploadHistory() {
    this.historyDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await await this.httpApiService.findAllTransferedCsspByType("interCssp",
            this.pageNumber, this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.histories = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.histories,
            totalCount: 0,
            groupCount: 0
          }
        }
      }
    });
  }

}
