import { Component, Input, OnInit } from '@angular/core';
import { ProcessActionComponent } from '../../processAction';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-live-inventory',
  templateUrl: './live-inventory.component.html',
  styleUrls: ['./live-inventory.component.scss']
})
export class LiveInventoryComponent implements ProcessActionComponent {
  @Input() data: any;

  constructor(public sanitizer: DomSanitizer) { }
  dataStudioLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://lookerstudio.google.com/embed/reporting/bd7cddee-8247-4abd-a827-dd09f31e5eab/page/05kZD');
  ngOnInit() {
  }
  // https://lookerstudio.google.com/embed/reporting/7243975e-e9c6-431d-98d2-385ab1358eb5/page/T7HPD
}
