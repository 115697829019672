import { Injectable } from '@angular/core';
import { Client } from 'elasticsearch-browser';

import { environment } from '../../../environments/environment';
// import { environment } from '../../../environments/environment.prod';
@Injectable({
    providedIn: 'root'
})
export class SearchService {
    perPage = environment.RESULTS_PER_PAGE;

    queryalldocs = {
        'query': {
            'match_all': {}
        },
        'sort': [
            { '_score': { 'order': 'desc' } }
        ]
    };
    private client: Client;

    constructor() {
        if (!this.client) {
            this.connect();
        }
    }

    createIndex(name): any {
        return this.client.indices.create(name);
    }

    isAvailable(): any {
        return this.client.ping({
            requestTimeout: Infinity,
            body: 'Hello JOAC Search!'
        });
    }

    addToIndex(value): any {
        return this.client.create(value);
    }

    getAllDocuments(_query, _index?, _type?) {
        if (_index !== undefined) {
            if (_type !== undefined) {
                return this.client.search({
                    q: _query,
                    index: _index,
                    type: _type,
                    body: this.queryalldocs
                });
            }
            return this.client.search({
                q: _query,
                index: _index,
                body: this.queryalldocs
            });
        } else {
            if (_type !== undefined) {
                return this.client.search({
                    q: _query,
                    type: _type,
                    body: this.queryalldocs
                });
            }
            return this.client.search({
                q: _query,
                body: this.queryalldocs
            });
        }
    }

    async getPaginatedDocuments(_query, _from, _index?, _type?, _perPage = this.perPage) {
        if (!_query.sort) {
            _query.sort = [{
                "_script": {
                    "type": "number",
                    "script": {
                        "lang": "painless",
                        "source": "def order = ['ROUND', 'OVAL', 'EMERALD','CUSHION','PEAR','RADIANT']; doc['ShapeCode'].size() > 0 && order.indexOf(doc['ShapeCode'].value) >= 0 ? order.indexOf(doc['ShapeCode'].value) : Integer.MAX_VALUE"
                    },
                    "order": "asc"
                }
            },
            {
                "Size": {
                    "order": "asc"
                }
            },
            {
                "_script": {
                    "type": "number",
                    "script": {
                        "lang": "painless",
                        "source": "def order = ['FL','IF', 'VVS1', 'VVS2','VS1','VS2','SI1','SI2','SI3','I1+','I1','I-','I2++']; doc['ClarityCode'].size() > 0 && order.indexOf(doc['ClarityCode'].value) >= 0 ? order.indexOf(doc['ClarityCode'].value) : Integer.MAX_VALUE"
                    },
                    "order": "asc"
                }
            },
            {
                "_script": {
                    "type": "number",
                    "script": {
                        "lang": "painless",
                        "source": "def order = ['D','E','F','G','H','I','J','K','L','M','N','O','P','I-OWLB','J-OWLB','K-OWLB','L-OWLB','M-OWLB','N-OWLB','O-OWLB','P-OWLB','N-TTLB','O-TTLB','P-TTLB','K-TTLB','L-TTLB','M-TTLB','N-TLB','O-TLB','P-TLB','N-O','O-P','Q-R','S-T','U-V','W-X','Y-Z','UNASSORT','FANCY','Y','P-R','S-Z']; doc['ColorCode'].size() > 0 && order.indexOf(doc['ColorCode'].value) >= 0 ? order.indexOf(doc['ColorCode'].value) : Integer.MAX_VALUE"
                    },
                    "order": "asc"
                }
            },
            {
                "_script": {
                    "type": "number",
                    "script": {
                        "lang": "painless",
                        "source": "def order = ['EXCL','VGOOD','GOOD','FAIR','POOR']; doc['CutCode'].size() > 0 &&  order.indexOf(doc['CutCode'].value) >= 0 ? order.indexOf(doc['CutCode'].value) : Integer.MAX_VALUE"
                    },
                    "order": "asc"
                }
            },
            {
                "_script": {
                    "type": "number",
                    "script": {
                        "lang": "painless",
                        "source": "def order = ['NONE','FAINT','MEDIUM','STRONG ','VERY STRONG']; doc['FluorescenceCode'].size() > 0 && order.indexOf(doc['FluorescenceCode'].value) >= 0 ? order.indexOf(doc['FluorescenceCode'].value) : Integer.MAX_VALUE"
                    },
                    "order": "asc"
                }
            },
            ]
        }

        console.log(_query)
        if (_index !== undefined) {
            if (_type !== undefined) {
                return this.client.search({
                    body: _query,
                    index: _index,
                    type: _type,
                    from: _from,
                    size: _perPage
                });
            }
            return this.client.search({
                body: _query,
                index: _index,
                from: _from,
                size: _perPage
            });
        } else {
            if (_type !== undefined) {
                return this.client.search({
                    body: _query,
                    type: _type,
                    from: _from,
                    size: _perPage
                });
            }
            return this.client.search({
                body: _query,
                from: (_from - 1) * _perPage,
                size: _perPage
            });
        }
    }

    getNextPage(scroll_id): any {
        return this.client.scroll({
            scrollId: scroll_id,
            scroll: '1m'
        });
    }

    getAllKeys(_index): any {
        return this.client.indices.getMapping({
            index: _index,
        })
    }

    getObject(_id, _index, _type): any {
        if (_index !== undefined || _type !== undefined) {
            return this.client.get({
                index: _index,
                type: _type,
                id: _id
            });
        } else {
            throw new Error('Cannot query item with an undefined index or type or both.');
        }
    }

    private connect() {
        this.client = new Client({
            host: environment.hosts
            // log: 'trace'
        });
    }
}
