import { Component, OnInit, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  Events,
  ToastController
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { IonicSelectableComponent } from "ionic-selectable";
import { SearchService } from "src/app/services/search/search.service";

@Component({
  selector: 'app-cvd-check',
  templateUrl: './cvd-check.component.html',
  styleUrls: ['./cvd-check.component.scss']
})
export class CvdCheckComponent implements OnInit {
  // public Stones = `GIA-7411009635
  // GIA-6422192342
  // GIA-1409601525
  // GIA-6415029394
  // GIA-6412015278
  // GIA-6422183714
  // GIA-2426439116
  // GIA-1407989501
  // GIA-2397668249`;
  public Stones = '';
  public stonesList = [];
  public pageSize: any = 50;
  pageNumber = 0;
  noOfRecords = 15;
  public columns = [];
  public selected = [];
  public enquiryNo;
  public cvdStatus = "";
  public qcStatus = "";
  public nameIdList = []

  public cvdStatusList = [{
    name: 'PASS',
    id: 1
  }, {
    name: 'FAIL',
    id: 2
  }]
  public qcStatusList = [{
    name: 'PASS',
    id: 1
  }, {
    name: 'FAIL',
    id: 2
  }]
  public qry: any =
    {
      "query": {
        "bool": {
          "should": [
            {
              "simple_query_string": {
                "query": ""
              }
            },
            {
              "bool": {
                "must": []
              }
            }
          ]
        }
      }
    }

  constructor(
    private es: SearchService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public toastCtrl: ToastController,
    public authService: AuthenticationService,
    public configService: ConfigService,
    public event: Events
  ) {
    this.event.subscribe('CVD & QC Check', (item) => {
      this.stonesList = [];
    })

  }

  ngOnDestroy() {
    this.event.unsubscribe('CVD & QC Check')
  }

  ngOnInit() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "enquiryno", prop: "enquiryId" },
    {
      name: 'Stone Id', prop: 'stoneName'
    },
    //{ name: "Details", prop: "" },
    { name: 'Clarity', prop: 'ClarityCode' },
    { name: 'Color', prop: 'ColorCode' },
    { name: 'Shape', prop: 'ShapeCode' },
    { name: 'Size', prop: 'cts' },
    { name: 'CVD Status', prop: 'cvdStatus' },
    { name: 'QC Status', prop: 'qcCheck' }
    ]
  }

  async fetchList() {
    await this.configService.showLoader()
    this.stonesList = [];
    this.selected = []
    let listOfStones = []
    if (this.Stones != '') {
      let tmp = this.Stones.split("\n");
      tmp = tmp.map(x => x.trim())

      if (tmp.length > 0) {
        listOfStones = tmp
      }
    }

    let obj = {
      'listOfStones': listOfStones
    }
    let res: any;
    res = await this.httpApiService.fetchStonesForCvdCheck(obj, this.pageSize, this.pageNumber);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data) {
        this.stonesList = data.data
      }
    }
    await this.configService.dismiss();
  }

  OnSelectRows(ev) {
    console.log(ev)
    this.selected = []
    this.selected = ev.selectedRowsData;

    // this.selectedStoneList = []
    // this.selected.forEach(el => {
    //   this.selectedStoneList.push(el.stoneId)
    // });
  }

  async update() {
    let listOfStones = this.stonesList.map(x => {
      let obj = {
        stoneName: x.stoneName,
        partyMasterId: x.partyMasterId,
        enquiryId: x.enquiryId,
        cvdStatus: x.cvdStatus,
        qcCheck: x.qcCheck
      }
      return obj

    })
    listOfStones = listOfStones.filter(el => !(!el.cvdStatus && !el.qcCheck))
    let obj = {
      'listOfStones': listOfStones
    }
    if (!!listOfStones && listOfStones.length > 0) {
      let res: any;
      await this.configService.showLoader()
      res = await this.httpApiService.updateCvdStatus(obj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast(
          "success",
          "Enquiry updated successfully"
        );

      }
      this.stonesList = [];
      this.Stones = ''
      this.nameIdList = [];
      this.cvdStatus = ''
      this.qcStatus = ''
    } else {
      await this.configService.showToast(
        "error",
        "Please fill the detail"
      );
    }

  }


}
