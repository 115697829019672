import { Component, Input } from "@angular/core";
import { ModalController, NavParams, Events, LoadingController } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-manage-action",
  templateUrl: "./manage-action.component.html",
  styleUrls: ["./manage-action.component.scss"]
})
export class ManageActionComponent implements ProcessActionComponent {
  @Input() data: any;

  public items: any;
  public userData: any;
  public manageSelectedAction = "";
  public arrowKeyLocation = -1;
  public listOfActions: any;
  public listOfActionPermission: any;
  public selectedAction = "";
  public description: "";
  public defaultData: any = [];
  public keys: any;
  public actionId: any;
  public matchFromDB: Boolean = false;
  public accusoftSync: Boolean = false;
  public newStockProcessingStatus = '';
  public compareJson = '';
  public svcKam;
  public toUser: number;
  public toLocation: number;
  public toDepartment: number;
  public fromDepartment: number;
  public usersList = [];
  public listOfVoucherBook: any;
  public voucherBook: any;
  public fromUser: any;
  public dept: any;
  public roleId: any;
  public recut: Boolean = false;
  public observation: Boolean = false;
  public certification: Boolean = false;
  public mixing: Boolean = false;
  public requisition: Boolean = false;
  public consignment: Boolean = false;
  public stoneReturn: Boolean = false;
  public priceListName: any;
  public fromDeptidList = [];
  public toDeptidList = [];
  public toLocationidList = [];
  public voucheridList = [];
  public toUseridList = [];
  public priceidList = []
  public multiplePriceIdList = []
  public fromUseridList = [];
  public allPriceNameList = [];
  public toLocationObvidList = [];
  public listOfLocations = [];
  public toRoleIdList = []
  public fromRoleIdList = [];
  public companyIdList = [];
  public kamIdList = [];
  public listOfRoles = []
  public deptList = []
  public voucherType: any;
  public consignmentConfirm: Boolean = false;
  public memoClient: Boolean = false;
  public sold: Boolean = false;
  public customerConfirm: Boolean = false;
  public memoService: Boolean = false;
  public consignmentInStock: Boolean = false;
  public selfObservationEnable: Boolean = false;
  public memoClientEnable: Boolean = false;
  public soldEnable: Boolean = false;
  public customerHoldEnable: Boolean = false;
  public blockForSaleEnable: Boolean = false;
  public customerUnHoldEnable: Boolean = false;
  public unblockForSaleEnable: Boolean = false;
  public customerConfirmEnable: Boolean = false;
  public memoServiceEnable: Boolean = false;
  public consignmentConfirmEnable: Boolean = false;
  public consignmentInStockEnable: Boolean = false;
  public certificationEnable: Boolean = false;
  public requisitionEnable: Boolean = false;
  public requestToKAMEnable: Boolean = false;
  public convertToMixEnable: Boolean = false;
  public toRole: any;
  public fromRole: any;
  public defaultObjRecut = {};
  public defaultObjSelfObservation = {};
  public defaultObjObservation = {};
  public defaultObjMemoClient = {};
  public defaultObjSold = {};
  public defaultObjCustomerHold = {};
  public defaultObjBlockForSale = {};
  public defaultObjCustomerUnHold = {};
  public defaultObjUnblockForSale = {};
  public defaultObjCustomerConfirm = {};
  public defaultObjMemoService = {};
  public defaultObjConsignmentConfirm = {};
  public defaultObjConsignmentInStock = {};
  public defaultObjCertification = {}
  public defaultObjRequisition = {}
  public defaultObjRequestToKAM = {};
  public defaultObjConvertToMix = {};
  public recutEnable: Boolean = false;
  public observationEnable: Boolean = false;
  public requestVoucherBook: any;
  public requestVoucherIdList = [];
  public partyMasterIdList = [];
  public typeList = [];
  public typeIdList = [];
  public type: any;
  public boxTypeList = [];
  public boxType: any;
  public boxTypeIdList = [];
  public partyTypeList = [];
  public partyTypeMemoClientIdList = [];
  public partyTypeSoldIdList = [];
  public partyTypeCConfirmIdList = []
  public partyTypeForMemoServiceIdList = []
  public partyTypeForConsignmentConfirmIdList = []
  public partyTypeForConsignmentInStockIdList = []
  public partyTypeSelfObservationIdList = []
  public partyTypeForCertificationIdList = []
  public partyTypeForRequisitionIdList = []
  public partyTypeRequestToKAMIdList = []
  public partyTypeIdList = []
  public partyType = [];
  // public isAllowedStatus = [];
  public transType: any;
  public transTypeList = [];
  public transTypeIdList = []
  public partyTypeCustomerHoldIdList = []
  public partyTypeBlockForSaleIdList = []
  public limit: Number;
  public ctsDifference: any;
  public internalStatuslist = []
  public internalStatusIdList = []

  public isAllowedStatus: [];
  public isAllowedStatusIdList = [];
  public defaultTemplateId: any;
  public externalStatuslist = []
  public internalStatus: any
  public listOfPrices = []
  public voucherForHoldCustomerIdList = []
  public voucherForBlockForSaleIdList = []
  public voucherForUnHoldCustomerIdList = []
  public voucherForUnblockForSaleIdList = []
  public isDirectCustomerConfirm = false;
  public isDirectMemoClient = false;
  public isDirectSold = false;
  public isDirectConsignmentConfirm = false;
  public isDirectRecut = false;
  public isDirectObservation = false;
  public isDirectMemoService = false;
  public isDirectConsignmentInStock = false;
  public isDirectCertification = false;
  public isDirectConvertToMix = false;
  public voucherBookList = [];
  public voucherbookIdList = [];
  public deptForPriceInReport: any;
  public transTypeForPriceInReport: any;
  public priceForReport: any;

  public priceForReportIdList = [];
  public deptForReportIdList = [];
  public transTypePriceIdList = [];

  public reports = [];
  public basePriceListName: any;
  public basePriceIdList = []

  public extStatusCConfirmIdList = []
  public extStatusMemoClientIdList = []
  public extStatusSoldIdList = []
  public extStatusSelfObvIdList = []
  public extStatusMemoServiceIdList = []
  public extStatusConsignmentInStockIdList = []
  public extStatusConsignmentConfirmIdList = []
  public extStatusRequisitionIdList = []
  public extStatusRecutIdList = []
  public extStatusObservationIdList = []
  public extStatusCertificationIdList = []
  public salesPersonIdList = []
  public pricePolicyChainList = []
  public fullStockProcessList = []
  public listOfChains = []
  public listOfCompanies = []
  public listOfKams = [];
  public pricePolicyChainIdList = []
  public mailIds = ''
  public defaultTempalateList = [];
  public defaultExportList = [];
  public defaultKamPerson = null;
  public salesPersonList = []

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    this.selectedAction = "";
    this.findAllTypeForPricing();
    this.getAllStoneStatus();
    this.getAllActions();
    this.getAllCompany();
    this.getAllPriceListName();
    this.getAllRoles();
    this.getBoxType();
    this.getAllPartyMasterType();
    this.getAllSalesPerson()
    this.getAllTransType();
    this.getAllDepartment();
    this.getAllCompanies();
    this.getAllDefaultTemplates();
    this.event.subscribe('Manage Actions', (item) => {
      this.typeList = [];
      this.listOfRoles = [];
      this.transTypeList = [];
      this.partyTypeList = [];
      this.voucherForHoldCustomerIdList = []
      this.voucherForUnHoldCustomerIdList = []
      this.fromDeptidList = [];
      this.toDeptidList = [];
      this.toLocationidList = [];
      this.fromUseridList = [];
      this.voucherForUnblockForSaleIdList = []
      this.voucheridList = [];
      this.fromDeptidList = [];
      this.boxTypeIdList = [];
      this.basePriceIdList = [];
      this.requestVoucherIdList = []
      this.priceidList = []
      this.multiplePriceIdList = []
      this.toRoleIdList = []
      this.fromRoleIdList = []
      this.partyTypeMemoClientIdList = []
      this.partyTypeSoldIdList = []
      this.partyTypeCConfirmIdList = []
      this.transTypeIdList = []
      this.voucherbookIdList = []
      this.partyMasterIdList = []
      this.partyTypeForMemoServiceIdList = []
      this.partyTypeForConsignmentConfirmIdList = []
      this.partyTypeForConsignmentInStockIdList = []
      this.partyTypeForCertificationIdList = []
      this.partyTypeIdList = []
      this.partyTypeForRequisitionIdList = [];
      this.partyTypeSoldIdList = [];
      this.extStatusSoldIdList = [];
      this.isDirectCustomerConfirm = false;
      this.isDirectMemoClient = false;
      this.isDirectSold = false;
      this.isDirectConsignmentConfirm = false;
      this.isDirectRecut = false;
      this.isDirectObservation = false;
      this.isDirectMemoService = false;
      this.isDirectConsignmentInStock = false;
      this.isDirectCertification = false;
      this.isDirectSold = false;
      this.isDirectConvertToMix = false;
      this.defaultKamPerson = null;
      this.getAllCompanies();
      this.findAllTypeForPricing();
      this.getAllActions();
      this.getBoxType();
      this.getAllCompany();
      this.getAllTransType()
      this.getAllStoneStatus();
      this.getAllPriceListName();
      this.getAllRoles();
      this.getAllPartyMasterType();
      this.getAllDepartment();
      this.getAllSalesPerson();
      this.searchManageActionForPermission(this.selectedAction);
    })
  }

  async getAllCompanies() {
    let res: any;
    res = await this.httpApiService.getAllCompanies();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfCompanies = data.data;
      console.log("getAllCompanies res", res, this.listOfCompanies);
    }
  }

  async getAllDefaultTemplates() {
    let res: any;
    res = await this.httpApiService.findAllTemplate(true)
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.defaultTempalateList = data.data;
    }
  }

  ngOnDestroy() {
    this.event.unsubscribe('Manage Actions');
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "roles") {
      this.roleId = idList[0];
    } else if (property == "fromDepartment") {
      this.fromDepartment = idList[0];
    } else if (property == "toDepartment") {
      this.toDepartment = idList[0];
    } else if (property == "internalStatus") {
      if (idList.length > 0) {
        this.internalStatus = idList[0];
      } else {
        this.internalStatus = null;
        this.internalStatusIdList = []
      }
    } else if (property == "toLocation") {
      if (idList.length > 0) {
        this.toUseridList = [];
        this.usersList = [];
        this.toLocation = idList[0];
        this.getAllToUser(this.toLocation);
      } else {
        this.toLocation = null;
        this.toUseridList = [];
        this.usersList = [];
      }
    } else if (property == "voucherBook") {
      if (idList.length > 0) {
        this.voucherBook = idList[0];
      } else {
        this.voucherBook = null;
      }

    }
    else if (property == "voucherBookList") {
      if (idList.length > 0) {
        this.voucherBookList = idList;
      } else {
        this.voucherBookList = [];
      }
    }
    else if (property == "isAllowedStatus") {
      if (idList.length > 0) {
        this.isAllowedStatus = idList;
      } else {
        this.isAllowedStatus = [];
      }
    }
    else if (property == 'defaultTemplate') {
      if (idList.length > 0) {
        this.defaultTemplateId = idList[0];
      } else {
        this.defaultTemplateId = null;
      }
    }
    else if (property == "voucherBookForHoldCustomer") {
      if (idList.length > 0) {
        this.defaultObjCustomerHold["voucherBookId"] = idList[0]
        this.defaultObjCustomerHold["enable"] = true;
      } else {
        delete this.defaultObjCustomerHold["voucherBookId"];
      }
    }
    else if (property == "voucherBookForBlockForSale") {
      if (idList.length > 0) {
        this.defaultObjBlockForSale["voucherBookId"] = idList[0]
        this.defaultObjBlockForSale["enable"] = true;
      } else {
        delete this.defaultObjBlockForSale["voucherBookId"];
      }
    }
    else if (property == "voucherBookForUnHoldCustomer") {
      if (idList.length > 0) {
        this.defaultObjCustomerUnHold["voucherBookId"] = idList[0]
        this.defaultObjCustomerUnHold["enable"] = true;
      } else {
        delete this.defaultObjCustomerUnHold["voucherBookId"];
      }
    } else if (property == "voucherBookForUnblockForSale") {
      if (idList.length > 0) {
        this.defaultObjUnblockForSale["voucherBookId"] = idList[0]
        this.defaultObjUnblockForSale["enable"] = true;
      } else {
        delete this.defaultObjUnblockForSale["voucherBookId"];
      }
    } else if (property == "requestVoucherBook") {
      this.requestVoucherBook = idList[0];
    } else if (property == "toUsers") {
      this.toUser = idList[0];
      console.log("toUseridList", this.toUseridList);
    } else if (property == "fromUsers") {
      this.fromUser = idList[0];
    } else if (property == "allDepartment") {
      this.dept = idList[0];
    } else if (property == "price") {
      if (idList.length > 0) {
        this.priceListName = idList[0];
      } else {
        this.priceListName = null;
      }
    }
    else if (property == "basePrice") {
      if (idList.length > 0) {
        this.basePriceListName = idList[0];
      } else {
        this.basePriceListName = null;
      }
    } else if (property == "multiplePrice") {
      if (idList.length > 0) {
        this.listOfPrices = idList;
      } else {
        this.listOfPrices = []
      }
    } else if (property == "toRoles") {
      if (idList.length > 0) {
        this.toRole = idList[0];
      } else {
        this.toRole = null;
      }
    }
    else if (property == "fromRoles") {
      if (idList.length > 0) {
        this.fromRole = idList[0];
      } else {
        this.fromRole = null;
      }
    }
    else if (property == "toLocationForRecut") {
      if (idList.length > 0) {
        this.defaultObjRecut["toLocation"] = idList[0];
        this.defaultObjRecut["enable"] = true;
      } else {
        delete this.defaultObjRecut["toLocation"];
      }
      console.log(this.defaultObjRecut)
    } else if (property == "toLocationForObservation") {
      if (idList.length > 0) {
        this.defaultObjObservation["toLocation"] = idList[0];
        this.defaultObjObservation["enable"] = true;
      } else {
        delete this.defaultObjObservation["toLocation"];
      }
      console.log(this.defaultObjObservation)
    } else if (property == "partMaster") {
      if (idList.length > 0) {
        this.defaultObjSelfObservation["partyMaster"] = idList[0];
        this.defaultObjSelfObservation["enable"] = true;
      } else {
        delete this.defaultObjObservation["toLocation"];
      }
    } else if (property == "typeId") {
      if (idList.length > 0) {
        this.type = obj[0].name;
      } else {
        this.type = null;
      }
    } else if (property == "boxType") {
      if (idList.length > 0) {
        this.boxType = obj[0].type;
      } else {
        this.boxType = null;
      }
    } else if (property == "partyTypeForMemoClient") {
      if (idList.length > 0) {
        this.defaultObjMemoClient["partyType"] = obj.map(a => a.type);
        this.defaultObjMemoClient["enable"] = true;
      } else {
        delete this.defaultObjMemoClient["partyType"];
      }
    } else if (property == "partyTypeForSold") {
      if (idList.length > 0) {
        this.defaultObjSold["partyType"] = obj.map(a => a.type);
        this.defaultObjSold["enable"] = true;
      } else {
        delete this.defaultObjSold["partyType"];
      }
    } else if (property == "partyTypeForRequestToKAM") {
      if (idList.length > 0) {
        this.defaultObjRequestToKAM["partyType"] = obj.map(a => a.type);
        this.defaultObjRequestToKAM["enable"] = true;
      } else {
        delete this.defaultObjRequestToKAM["partyType"];
      }
    } else if (property == "partyTypeForCustomerHold") {
      if (idList.length > 0) {
        this.defaultObjCustomerHold["partyType"] = obj.map(a => a.type);
        this.defaultObjCustomerHold["enable"] = true;
      } else {
        delete this.defaultObjCustomerHold["partyType"];
      }
    }
    else if (property == "partyTypeForBlockForSale") {
      if (idList.length > 0) {
        this.defaultObjBlockForSale["partyType"] = obj.map(a => a.type);
        this.defaultObjBlockForSale["enable"] = true;
      } else {
        delete this.defaultObjBlockForSale["partyType"];
      }
    }
    else if (property == "partyTypeForCConfirm") {
      if (idList.length > 0) {
        this.defaultObjCustomerConfirm["partyType"] = obj.map(a => a.type);
        this.defaultObjCustomerConfirm["enable"] = true;
      } else {
        delete this.defaultObjCustomerConfirm["partyType"];
      }
    } else if (property == "partyTypeForMemoService") {
      if (idList.length > 0) {
        this.defaultObjMemoService["partyType"] = obj.map(a => a.type);
        this.defaultObjMemoService["enable"] = true;
      } else {
        delete this.defaultObjMemoService["partyType"];
      }
    } else if (property == "partyTypeForConsignmentInStock") {
      if (idList.length > 0) {
        this.defaultObjConsignmentInStock["partyType"] = obj.map(a => a.type);
        this.defaultObjConsignmentInStock["enable"] = true;
      } else {
        delete this.defaultObjConsignmentInStock["partyType"];
      }
    } else if (property == "partyTypeForConsignmentConfirm") {
      if (idList.length > 0) {
        this.defaultObjConsignmentConfirm["partyType"] = obj.map(a => a.type);
        this.defaultObjConsignmentConfirm["enable"] = true;
      } else {
        delete this.defaultObjConsignmentConfirm["partyType"];
      }
    } else if (property == "partyTypeForRequisition") {
      if (idList.length > 0) {
        this.defaultObjRequisition["partyType"] = obj.map(a => a.type);
        this.defaultObjRequisition["enable"] = true;
      } else {
        delete this.defaultObjRequisition["partyType"];
      }
    } else if (property == "partyTypeForCertification") {
      if (idList.length > 0) {
        this.defaultObjCertification["partyType"] = obj.map(a => a.type);
        this.defaultObjCertification["enable"] = true;
      } else {
        delete this.defaultObjCertification["partyType"];
      }
    } else if (property == "partyType") {
      if (idList.length > 0) {
        this.partyType = obj.map(a => a.type)
      } else {
        this.partyType = []
      }
    } else if (property == "transTypeList") {
      if (idList.length > 0) {
        this.transType = obj[0].name
      } else {
        this.transType = null;
      }
    } else if (property == "reportTransTypeList") {
      console.log(this.defaultData)
      if (idList.length > 0) {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.transTypeList = idList;
                item.reportValue.transTypeIdList = obj;
              }
            })
          }
        })
      } else {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.transTypeList = []
                item.reportValue.transTypeIdList = []
              }
            })
          }
        })
      }
      console.log(this.defaultData)
    } else if (property == "reportDepartmentList") {
      if (idList.length > 0) {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.departmentList = idList;
                item.reportValue.departmentIdList = obj;
                console.log(item.reportValue.departmentIdList)
              }
            })
          }
        })
      } else {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.departmentList = []
                item.reportValue.departmentIdList = [];
              }
            })
          }
        })
      }
      console.log(this.defaultData)
    } else if (property == "defaultTemplateList") {
      if (idList.length > 0) {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.defaultExportList = idList;
                item.reportValue.defaultExportIdList = obj;
                console.log(item.reportValue.defaultExportIdList)
              }
            })
          }
        })
      } else {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.defaultExportList = []
                item.reportValue.defaultExportIdList = [];
              }
            })
          }
        })
      }
      console.log(this.defaultData)
    } else if (property == "reportRapPriceList") {
      if (idList.length > 0) {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.priceListName = obj;
              }
            })
          }
        })
      } else {
        this.defaultData.filter(el => {
          if (el.paramValue) {
            el.paramValue.filter(item => {
              if (item.reportName == masterId) {
                item.reportValue.priceListName = []
              }
            })
          }
        })
      }
    } else if (property == "deptForPriceInReport") {
      if (idList.length > 0) {
        this.deptForPriceInReport = obj[0]
      } else {
        this.deptForPriceInReport = null;
      }
    } else if (property == "transTypeForPriceInReport") {
      if (idList.length > 0) {
        this.transTypeForPriceInReport = obj[0]
      } else {
        this.transTypeForPriceInReport = null;
      }
    } else if (property == "defaultKamPersonSelection") {
      if (idList.length > 0) {
        this.defaultKamPerson = idList[0]
      } else {
        this.defaultKamPerson = null;
      }
    } else if (property == "priceForReport") {
      if (idList.length > 0) {
        this.priceForReport = obj[0]
      } else {
        this.priceForReport = null;
      }
    } else if (property == "extStatusForMemoClient") {
      if (idList.length > 0) {
        this.defaultObjMemoClient["externalStatus"] = idList[0];
        this.defaultObjMemoClient["enable"] = true;
      } else {
        delete this.defaultObjMemoClient["externalStatus"];
      }
    } else if (property == "extStatusForSold") {
      if (idList.length > 0) {
        this.defaultObjSold["externalStatus"] = idList[0];
        this.defaultObjSold["enable"] = true;
      } else {
        delete this.defaultObjSold["externalStatus"];
      }
    }
    else if (property == "extStatusForCConfirm") {
      if (idList.length > 0) {
        this.defaultObjCustomerConfirm["externalStatus"] = idList[0];
        this.defaultObjCustomerConfirm["enable"] = true;
      } else {
        delete this.defaultObjCustomerConfirm["externalStatus"];
      }
    } else if (property == "extStatusForMemoService") {
      if (idList.length > 0) {
        this.defaultObjMemoService["externalStatus"] = idList[0];
        this.defaultObjMemoService["enable"] = true;
      } else {
        delete this.defaultObjMemoService["externalStatus"];
      }
    } else if (property == "extStatusForConsignmentInStock") {
      if (idList.length > 0) {
        this.defaultObjConsignmentInStock["externalStatus"] = idList[0];
        this.defaultObjConsignmentInStock["enable"] = true;
      } else {
        delete this.defaultObjConsignmentInStock["externalStatus"];
      }
    } else if (property == "extStatusForConsignmentConfirm") {
      if (idList.length > 0) {
        this.defaultObjConsignmentConfirm["externalStatus"] = idList[0];
        this.defaultObjConsignmentConfirm["enable"] = true;
      } else {
        delete this.defaultObjConsignmentConfirm["externalStatus"];
      }
    } else if (property == "extStatusForRequisition") {
      if (idList.length > 0) {
        this.defaultObjRequisition["externalStatus"] = idList[0];
        this.defaultObjRequisition["enable"] = true;
      } else {
        delete this.defaultObjRequisition["externalStatus"];
      }
    } else if (property == "extStatusForCertification") {
      if (idList.length > 0) {
        this.defaultObjCertification["externalStatus"] = idList[0];
        this.defaultObjCertification["enable"] = true;
      } else {
        delete this.defaultObjCertification["externalStatus"];
      }
    } else if (property == "extStatusForRecut") {
      if (idList.length > 0) {
        this.defaultObjRecut["externalStatus"] = idList[0];
        this.defaultObjRecut["enable"] = true;
      } else {
        delete this.defaultObjRecut["externalStatus"];
      }
    } else if (property == "extStatusForObservation") {
      if (idList.length > 0) {
        this.defaultObjObservation["externalStatus"] = idList[0];
        this.defaultObjObservation["enable"] = true;
      } else {
        delete this.defaultObjObservation["externalStatus"];
      }
    } else if (property == "pricePolicyChainList") {
      if (idList.length > 0) {
        this.pricePolicyChainList = idList;
      } else {
        this.pricePolicyChainList = [];
      }
    } else if (property == "fullStockProcess") {
      if (idList.length > 0) {
        this.fullStockProcessList = idList;
      } else {
        this.fullStockProcessList = [];
      }
    }



  }

  async getBoxType() {
    let res;
    res = await this.httpApiService.getAllBoxType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.boxTypeList = data.data;
      console.log("boxTypeList", this.boxTypeList);
    }
  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.transTypeList = data.data;
    }
  }

  async getAllSalesPerson() {
    await this.configService.showLoader();
    let location = await this.configService.getFromLocation()
    let res = await this.httpApiService.getAllSalesPerson(
      location,
      'Sales Person'
    );
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.salesPersonList = data.data;
    }
  }


  async getAllPartyMasterType() {
    let res: any;
    res = await this.httpApiService.getAllPartyMasterType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      console.log(dataList)
      if (dataList.length > 0) {
        for (let i = 0; i < dataList.length; i++) {
          this.partyTypeList.push({ id: i + 1, type: dataList[i] })
        }
      }
    }
    console.log("boxTypeList", this.boxTypeList);
  }

  async getUserData() {
    return await this.authService.userData();
  }

  setItems() {
    this.items = this.listOfActions;
  }

  manageActionForPermission(event, item) {
    this.searchManageActionForPermission(item.actionName);
  }

  async getAllRoles() {
    let res: any;
    res = await this.httpApiService.getAllRoles();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfRoles = data.data;
      console.log("getAllRoles res", res, this.listOfRoles);
    }
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
    }
  }

  async getAllDepartment() {
    let res: any;
    let appName = await this.configService.appName;
    res = await this.httpApiService.getAllDepartment(
      appName
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.deptList = data.data;
    }
  }

  // async getVoucherBookListForPendingApproval() {
  //   let res: any;
  //   res = await this.httpApiService.getAllVouchersForSelectedType(
  //     'Returnable'
  //   );
  //   let data = res.json();
  //   console.log(data)
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     this.listOfVoucherBook = data.data;
  //     console.log(this.listOfVoucherBook);
  //   }
  // }

  async searchManageActionForPermission(aName) {
    await this.configService.showLoader();
    let tmpActionName = "";
    this.items = this.listOfActions;
    if (!!aName) {
      tmpActionName = aName;
      this.selectedAction = aName;
      this.manageSelectedAction = aName;
    } else {
      this.selectedAction = this.manageSelectedAction;
      tmpActionName = this.manageSelectedAction;
    }
    let selectedActionId = this.items.filter(function (search) {
      if (search.actionName == tmpActionName) {
        return search.id;
      }
    });
    if (selectedActionId.length > 0) {
      this.actionId = selectedActionId[0].id;
      let res: any;
      res = await this.httpApiService.getAllActionPermissionById(this.actionId);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        let actionData = data.data;
        if (actionData.listOfActionPermissions) {
          this.listOfActionPermission = actionData.listOfActionPermissions;
        }
        this.voucherType = actionData.voucherType
        this.selectedAction = actionData.actionName;
        this.description = actionData.description;
        let index = this.items.findIndex(
          x => x.actionName == selectedActionId[0].actionName
        );
        this.arrowKeyLocation = index;

        if (actionData.actionName == 'Pending Transfer Approval') {
          await this.getVoucherBookListForPendingApproval()
        } else {
          await this.getAllVoucherBook();
        }
        await this.getAllPolicyChain()
        if (actionData.listOfActionDefaults) {
          this.defaultData = actionData.listOfActionDefaults;
          if (actionData.actionName == 'Reports') {
            this.defaultData.forEach(el => {
              if (el.paramName == 'reports') {
                el.paramValue = JSON.parse(el.paramValue)
              }
            })
          }
          this.priceidList = [];
          this.multiplePriceIdList = []
          this.voucheridList = [];
          this.fromDeptidList = [];
          this.toDeptidList = [];
          this.typeIdList = []
          this.toLocationidList = [];
          this.requestVoucherIdList = []
          this.toRoleIdList = []
          this.boxTypeIdList = []
          this.voucheridList = [];
          this.partyTypeMemoClientIdList = []
          this.partyTypeSoldIdList = []
          this.partyTypeCConfirmIdList = []
          this.partyMasterIdList = []
          this.partyTypeForMemoServiceIdList = []
          this.partyTypeForConsignmentConfirmIdList = []
          this.partyTypeForConsignmentInStockIdList = []
          this.partyTypeForCertificationIdList = []
          this.partyTypeIdList = []
          this.partyTypeForRequisitionIdList = []
          this.internalStatusIdList = []
          this.isDirectCustomerConfirm = false;
          this.isDirectMemoClient = false;
          this.isDirectSold = false;
          this.isDirectConsignmentConfirm = false;
          this.isDirectRecut = false;
          this.isDirectObservation = false;
          this.isDirectMemoService = false;
          this.isDirectConsignmentInStock = false;
          this.isDirectCertification = false;
          this.isDirectSold = false;
          this.isDirectConvertToMix = false;
          this.defaultKamPerson = null;
          this.mailIds = '';
          this.defaultData.filter(el => {
            console.log(el.paramName)
            if (el.paramName == 'mailIds') {
              if (!!el.paramValue) {
                this.mailIds = JSON.parse(el.paramValue).toString();
              }
            }

            if (el.paramName == 'fullStockProcess') {
              if (!!el.paramValue) {
                let companyList = el.paramValue.split(',')
                companyList = companyList.map(x => Number(x))
                this.listOfCompanies.forEach(item => {
                  console.log(item.id)
                  if (companyList.indexOf(item.id) > -1) {
                    this.companyIdList.push(item)
                  }
                })
              }
            }

            if (el.paramName == 'newStockProcessingStatus') {
              if (!!el.paramValue) {
                this.newStockProcessingStatus = el.paramValue
              }
            }

            if (el.paramName == 'compareJson') {
              if (!!el.paramValue) {
                this.compareJson = el.paramValue
              }
            }

            if (el.paramName == 'matchFromDB') {
              if (!!el.paramValue) {
                this.matchFromDB = el.paramValue
              }
            }

            if (el.paramName == 'Accusoft sync') {
              if (!!el.paramValue) {
                this.accusoftSync = el.paramValue
              }
            }

            if (el.paramName == 'defaultTemplate') {
              this.defaultTemplateId = el.paramValue
              this.defaultTempalateList.forEach(item => {
                if (item.id == el.paramValue) {
                  this.defaultExportList.push(item)
                }
              })
            }
            if (el.paramName == 'defaultKamPerson') {
              this.defaultKamPerson = el.paramValue
              this.salesPersonList.forEach(item => {
                if (item.id == el.paramValue) {
                  this.salesPersonIdList.push(item)
                }
              })
            }

            if (el.paramName == 'SVCKAMMapping') {
              if (!!el.paramValue) {
                this.svcKam = el.paramValue
              }
            }

            if (el.paramName == 'priceListName') {
              this.priceListName = el.paramValue;
              this.allPriceNameList.forEach(item => {
                if (item.id == el.paramValue) {
                  this.priceidList.push({ id: item.id, name: item.name })
                }
              })
            }
            if (el.paramName == 'basePriceListName') {
              this.basePriceListName = el.paramValue;
              this.basePriceIdList = []
              this.allPriceNameList.forEach(item => {
                if (item.id == el.paramValue) {
                  this.basePriceIdList.push({ id: item.id, name: item.name })
                }
              })
            }
            if (el.paramName == 'listOfPrices') {
              if (!!el.paramValue) {
                this.listOfPrices = JSON.parse(el.paramValue);
                this.multiplePriceIdList = []
                if (!!this.listOfPrices && this.listOfPrices.length > 0) {
                  this.allPriceNameList.forEach(item => {
                    let flag = this.listOfPrices.some(element => element == item.id)
                    if (flag) {
                      this.multiplePriceIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
              }
            }
            if (el.paramName == 'fromDepartment') {
              this.fromDepartment = el.paramValue;
              this.deptList.forEach(item => {
                if (item.id == el.paramValue) {
                  this.fromDeptidList.push({ id: item.id, name: item.name })
                }
              })
            }
            if (el.paramName == 'toDepartment') {
              this.toDepartment = el.paramValue;
              this.deptList.forEach(item => {
                if (item.id == el.paramValue) {
                  this.toDeptidList.push({ id: item.id, name: item.name })
                }
              })
            }
            if (el.paramName == 'internalStatus') {
              this.internalStatus = el.paramValue;
              this.internalStatuslist.forEach(item => {
                if (item.id == el.paramValue) {
                  this.internalStatusIdList.push({ id: item.id, name: item.name })
                }
              })
            }
            if (el.paramName == 'isAllowedStatus') {
              if (!!el.paramValue) {
                let statusList = JSON.parse(el.paramValue);
                this.isAllowedStatusIdList = [];
                for (let i = 0; i < this.internalStatuslist.length; i++) {
                  let flag = statusList.some(element => element == this.internalStatuslist[i].id)
                  if (flag) {
                    this.isAllowedStatusIdList.push({ id: this.internalStatuslist[i].id, name: this.internalStatuslist[i].name })
                  }
                }
              }
            }
            if (el.paramName == 'partyType') {
              if (!!el.paramValue) {
                this.partyType = JSON.parse(el.paramValue);
                this.partyTypeIdList = []
                console.log(this.partyType)
                if (!!this.partyType && this.partyType.length > 0) {
                  this.partyTypeList.forEach(item => {
                    let flag = this.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
              }
            }
            if (el.paramName == 'toLocation') {
              this.toLocation = el.paramValue;
              this.listOfLocations.forEach(item => {
                if (item.id == el.paramValue) {
                  this.toLocationidList.push({ id: item.id, companyName: item.companyName })
                  this.getAllToUser(item.id)
                }
              })
            }
            if (el.paramName == 'Limit') {
              this.limit = el.paramValue;
            }
            if (el.paramName == 'ctsDifference') {
              this.ctsDifference = el.paramValue;
            }
            if (el.paramName == 'voucherBook') {
              this.voucherBook = el.paramValue;
              this.listOfVoucherBook.forEach(item => {
                if (item.id == el.paramValue) {
                  this.voucheridList.push({ id: item.id, bookName: item.bookName })
                }
              })
            }

            if (el.paramName == 'voucherBookList') {
              this.voucherbookIdList = []
              if (!!el.paramValue) {
                this.voucherBookList = JSON.parse(el.paramValue);
                for (let i = 0; i < this.listOfVoucherBook.length; i++) {
                  let flag = this.voucherBookList.some(element => element == this.listOfVoucherBook[i].id)
                  if (flag) {
                    this.voucherbookIdList.push({ id: this.listOfVoucherBook[i].id, bookName: this.listOfVoucherBook[i].bookName })
                  }
                }
              } else {
                this.voucherBookList = []
              }
            }
            if (el.paramName == 'pricePolicyChainList') {
              this.pricePolicyChainIdList = []
              if (!!el.paramValue) {
                this.pricePolicyChainList = JSON.parse(el.paramValue);
                for (let i = 0; i < this.listOfChains.length; i++) {
                  let flag = this.pricePolicyChainList.some(element => element == this.listOfChains[i].id)
                  if (flag) {
                    this.pricePolicyChainIdList.push({ id: this.listOfChains[i].id, name: this.listOfChains[i].name })
                  }
                }
              } else {
                this.pricePolicyChainList = []
              }
            }
            if (el.paramName == 'boxType') {
              this.boxType = el.paramValue;
              this.boxTypeList.forEach(item => {
                if (item.type == el.paramValue) {
                  this.boxTypeIdList.push({ id: item.id, type: item.type })
                }
              })
            }

            if (el.paramName == 'requestVoucherBook') {
              this.requestVoucherBook = el.paramValue;
              this.listOfVoucherBook.forEach(item => {
                if (item.id == el.paramValue) {
                  this.requestVoucherIdList.push({ id: item.id, bookName: item.bookName })
                }
              })
            }
            if (el.paramName == 'toUser') {
              this.toUseridList = [];
              this.toUser = el.paramValue
              this.usersList.forEach(item => {
                if (item.id == parseInt(el.paramValue)) {
                  let data = { id: item.id, username: item.username };
                  this.toUseridList.push(data);
                  console.log(this.toUseridList)
                }
              });
            }
            if (el.paramName == 'toRole') {
              this.toRole = el.paramValue;
              this.toRoleIdList = []
              this.listOfRoles.forEach(item => {
                if (item.id == el.paramValue) {
                  this.toRoleIdList.push({ id: el.id, roleName: item.roleName })
                }
              })
            } if (el.paramName == 'transType') {
              this.transType = el.paramValue;
              this.transTypeIdList = []
              this.transTypeList.forEach(item => {
                if (item.name == el.paramValue) {
                  this.transTypeIdList.push({ id: item.id, name: item.name })
                }
              })
            } if (el.paramName == 'fromRole') {
              this.fromRole = el.paramValue;
              this.fromRoleIdList = []
              this.listOfRoles.forEach(item => {
                if (item.id == el.paramValue) {
                  this.fromRoleIdList.push({ id: el.id, roleName: item.roleName })
                }
              })
            }
            if (el.paramName == 'Return') {
              this.stoneReturn = el.paramValue;
            }
            if (el.paramName == 'type') {
              this.type = el.paramValue
              console.log(this.typeList)
              this.typeList.forEach(item => {
                if (item.name == el.paramValue) {
                  this.typeIdList.push({ id: item.id, name: item.name })
                }
              })
              console.log(this.typeIdList)
            }
            if (el.paramName == 'Recut') {
              this.defaultObjRecut["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.recutEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjRecut["isDirect"] = dataJson.isDirect;
                  this.isDirectRecut = dataJson.isDirect;
                }
                if (!!dataJson.toLocation) {
                  this.toLocation = dataJson.toLocation;
                  this.defaultObjRecut["toLocation"] = this.toLocation;
                  this.toLocationidList = []
                  this.listOfLocations.forEach(item => {
                    if (item.id == this.toLocation) {
                      this.toLocationidList.push({ id: item.id, companyName: item.companyName })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjRecut["externalStatus"] = dataJson.externalStatus;
                  this.extStatusRecutIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusRecutIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.recutEnable) {
                  this.defaultObjRecut["enable"] = true;
                } else {
                  this.defaultObjRecut["enable"] = false;
                }
              } else {
                this.recutEnable = false;
              }
              console.log(this.recutEnable, this.extStatusRecutIdList)
            }

            if (el.paramName == 'Requisition') {
              this.defaultObjRequisition["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.requisitionEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjRequisition["partyType"] = dataJson.partyType;
                  this.partyTypeForRequisitionIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForRequisitionIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjRecut["externalStatus"] = dataJson.externalStatus;
                  this.extStatusRequisitionIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusRequisitionIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.certificationEnable) {
                  this.defaultObjRequisition["enable"] = true;
                } else {
                  this.defaultObjRequisition["enable"] = false;
                }
              } else {
                this.certificationEnable = false;
              }
              console.log(this.partyTypeForRequisitionIdList, this.certificationEnable)
            }
            if (el.paramName == 'Observation') {
              this.defaultObjObservation["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.observationEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjObservation["isDirect"] = dataJson.isDirect;
                  this.isDirectObservation = dataJson.isDirect;
                }
                if (!!dataJson.toLocation) {
                  this.toLocation = dataJson.toLocation;
                  this.toLocationObvidList = []
                  this.listOfLocations.forEach(item => {
                    if (item.id == this.toLocation) {
                      this.toLocationObvidList.push({ id: item.id, companyName: item.companyName })
                    }
                  })
                  this.defaultObjObservation["toLocation"] = this.toLocation;
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjObservation["externalStatus"] = dataJson.externalStatus;
                  this.extStatusObservationIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusObservationIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.observationEnable) {
                  this.defaultObjObservation["enable"] = true;
                } else {
                  this.defaultObjObservation["enable"] = false;
                }
              } else {
                this.observationEnable = false;
              }
            }

            if (el.paramName == 'Memo Client') {
              this.defaultObjMemoClient["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.memoClientEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjMemoClient["isDirect"] = dataJson.isDirect;
                  this.isDirectMemoClient = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjMemoClient["partyType"] = dataJson.partyType;
                  this.partyTypeMemoClientIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeMemoClientIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjMemoClient["externalStatus"] = dataJson.externalStatus;
                  this.extStatusMemoClientIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusMemoClientIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.memoClientEnable) {
                  this.defaultObjMemoClient["enable"] = true;
                } else {
                  this.defaultObjMemoClient["enable"] = false;
                }
              } else {
                this.memoClientEnable = false;
              }
              console.log(this.partyTypeMemoClientIdList, this.memoClientEnable)
            }
            if (el.paramName == 'Sold') {
              this.defaultObjSold["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.soldEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjSold["isDirect"] = dataJson.isDirect;
                  this.isDirectSold = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjSold["partyType"] = dataJson.partyType;
                  this.partyTypeSoldIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeSoldIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjSold["externalStatus"] = dataJson.externalStatus;
                  this.extStatusSoldIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusSoldIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.soldEnable) {
                  this.defaultObjSold["enable"] = true;
                } else {
                  this.defaultObjSold["enable"] = false;
                }
              } else {
                this.soldEnable = false;
              }
              console.log(this.partyTypeSoldIdList, this.soldEnable)
            }
            if (el.paramName == 'Request To KAM') {
              this.defaultObjRequestToKAM["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.requestToKAMEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjRequestToKAM["partyType"] = dataJson.partyType;
                  this.partyTypeRequestToKAMIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeRequestToKAMIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (this.requestToKAMEnable) {
                  this.defaultObjRequestToKAM["enable"] = true;
                } else {
                  this.defaultObjRequestToKAM["enable"] = false;
                }
              } else {
                this.requestToKAMEnable = false;
              }
              console.log(this.partyTypeRequestToKAMIdList, this.requestToKAMEnable)
            }
            if (el.paramName == 'Convert To Mix') {
              this.defaultObjConvertToMix["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                console.log(el.paramValue)
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.convertToMixEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjConvertToMix["isDirect"] = dataJson.isDirect;
                  this.isDirectConvertToMix = dataJson.isDirect;
                }
                if (this.convertToMixEnable) {
                  this.defaultObjConvertToMix["enable"] = true;
                } else {
                  this.defaultObjConvertToMix["enable"] = false;
                }
              } else {
                this.convertToMixEnable = false;
              }
              console.log(this.convertToMixEnable)
            }
            if (el.paramName == 'Hold Customer') {
              this.defaultObjCustomerHold["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                console.log(el.paramValue)
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.customerHoldEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjCustomerHold["partyType"] = dataJson.partyType;
                  this.partyTypeCustomerHoldIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeCustomerHoldIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.voucherBookId) {
                  this.defaultObjCustomerHold["voucherBookId"] = dataJson.voucherBookId;
                  this.voucherForHoldCustomerIdList = []
                  this.listOfVoucherBook.forEach(item => {
                    if (item.id == dataJson.voucherBookId) {
                      this.voucherForHoldCustomerIdList.push({ id: item.id, bookName: item.bookName })
                    }
                  })
                }
                if (this.customerHoldEnable) {
                  this.defaultObjCustomerHold["enable"] = true;
                } else {
                  this.defaultObjCustomerHold["enable"] = false;
                }
              } else {
                this.customerHoldEnable = false;
              }
              console.log(this.partyTypeCustomerHoldIdList, this.customerHoldEnable)
            }
            if (el.paramName == 'Unhold Customer') {
              this.defaultObjCustomerUnHold["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.customerUnHoldEnable = dataJson.enable;
                if (!!dataJson.voucherBookId) {
                  this.defaultObjCustomerUnHold["voucherBookId"] = dataJson.voucherBookId;
                  this.voucherForUnHoldCustomerIdList = []
                  this.listOfVoucherBook.forEach(item => {
                    if (item.id == dataJson.voucherBookId) {
                      this.voucherForUnHoldCustomerIdList.push({ id: item.id, bookName: item.bookName })
                    }
                  })
                }
                if (this.customerUnHoldEnable) {
                  this.defaultObjCustomerUnHold["enable"] = true;
                } else {
                  this.defaultObjCustomerUnHold["enable"] = false;
                }
              } else {
                this.customerUnHoldEnable = false;
              }
            }
            if (el.paramName == 'Unblock For Sale') {
              this.defaultObjUnblockForSale["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.unblockForSaleEnable = dataJson.enable;
                if (!!dataJson.voucherBookId) {
                  this.defaultObjUnblockForSale["voucherBookId"] = dataJson.voucherBookId;
                  this.voucherForUnblockForSaleIdList = []
                  this.listOfVoucherBook.forEach(item => {
                    if (item.id == dataJson.voucherBookId) {
                      this.voucherForUnblockForSaleIdList.push({ id: item.id, bookName: item.bookName })
                    }
                  })
                }
                if (this.unblockForSaleEnable) {
                  this.defaultObjUnblockForSale["enable"] = true;
                } else {
                  this.defaultObjUnblockForSale["enable"] = false;
                }
              } else {
                this.unblockForSaleEnable = false;
              }
            }
            if (el.paramName == 'Block For Sale') {
              this.defaultObjBlockForSale["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                console.log(el.paramValue)
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.blockForSaleEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjBlockForSale["partyType"] = dataJson.partyType;
                  this.partyTypeBlockForSaleIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeBlockForSaleIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.voucherBookId) {
                  this.defaultObjBlockForSale["voucherBookId"] = dataJson.voucherBookId;
                  this.voucherForBlockForSaleIdList = []
                  this.listOfVoucherBook.forEach(item => {
                    if (item.id == dataJson.voucherBookId) {
                      this.voucherForBlockForSaleIdList.push({ id: item.id, bookName: item.bookName })
                    }
                  })
                }
                if (this.customerHoldEnable) {
                  this.defaultObjCustomerHold["enable"] = true;
                } else {
                  this.defaultObjCustomerHold["enable"] = false;
                }
              } else {
                this.customerHoldEnable = false;
              }
              console.log(this.partyTypeCustomerHoldIdList, this.customerHoldEnable)
            }
            if (el.paramName == 'Certification') {
              this.defaultObjCertification["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.certificationEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjCertification["isDirect"] = dataJson.isDirect;
                  this.isDirectCertification = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjCertification["partyType"] = dataJson.partyType;
                  this.partyTypeForCertificationIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForCertificationIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjCertification["externalStatus"] = dataJson.externalStatus;
                  this.extStatusCertificationIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusCertificationIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.certificationEnable) {
                  this.defaultObjCertification["enable"] = true;
                } else {
                  this.defaultObjCertification["enable"] = false;
                }
              } else {
                this.certificationEnable = false;
              }
              console.log(this.partyTypeForCertificationIdList, this.certificationEnable)
            }
            if (el.paramName == 'Consignment In Stock') {
              this.defaultObjConsignmentInStock["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.consignmentInStockEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjConsignmentInStock["isDirect"] = dataJson.isDirect;
                  this.isDirectConsignmentInStock = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjConsignmentInStock["partyType"] = dataJson.partyType;
                  this.partyTypeForConsignmentInStockIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForConsignmentInStockIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjConsignmentInStock["externalStatus"] = dataJson.externalStatus;
                  this.extStatusConsignmentInStockIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusConsignmentInStockIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.consignmentInStockEnable) {
                  this.defaultObjConsignmentInStock["enable"] = true;
                } else {
                  this.defaultObjConsignmentInStock["enable"] = false;
                }
              } else {
                this.consignmentInStockEnable = false;
              }
              console.log('this.partyTypeForConsignmentInStockIdList', this.partyTypeForConsignmentInStockIdList)
            }
            if (el.paramName == 'Consignment Confirm') {
              this.defaultObjConsignmentConfirm["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.consignmentConfirmEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjConsignmentConfirm["isDirect"] = dataJson.isDirect;
                  this.isDirectConsignmentConfirm = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjConsignmentConfirm["partyType"] = dataJson.partyType;
                  this.partyTypeForConsignmentConfirmIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForConsignmentConfirmIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjConsignmentConfirm["externalStatus"] = dataJson.externalStatus;
                  this.extStatusConsignmentConfirmIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusConsignmentConfirmIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.consignmentConfirmEnable) {
                  this.defaultObjConsignmentConfirm["enable"] = true;
                } else {
                  this.defaultObjConsignmentConfirm["enable"] = false;
                }
              } else {
                this.consignmentConfirmEnable = false;
              }
            }
            if (el.paramName == 'Memo Service') {
              this.defaultObjMemoService["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.memoServiceEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjMemoService["isDirect"] = dataJson.isDirect;
                  this.isDirectMemoService = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjMemoService["partyType"] = dataJson.partyType;
                  this.partyTypeForMemoServiceIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForMemoServiceIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjMemoService["externalStatus"] = dataJson.externalStatus;
                  this.extStatusMemoServiceIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusMemoServiceIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.memoServiceEnable) {
                  this.defaultObjMemoService["enable"] = true;
                } else {
                  this.defaultObjMemoService["enable"] = false;
                }
              } else {
                this.memoServiceEnable = false;
              }
            }
            if (el.paramName == 'Customer Confirm') {
              this.defaultObjCustomerConfirm["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.customerConfirmEnable = dataJson.enable;
                if (dataJson.isDirect) {
                  this.defaultObjCustomerConfirm["isDirect"] = dataJson.isDirect;
                  this.isDirectCustomerConfirm = dataJson.isDirect;
                }
                if (!!dataJson.partyType) {
                  this.defaultObjCustomerConfirm["partyType"] = dataJson.partyType;
                  this.partyTypeCConfirmIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeCConfirmIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjCustomerConfirm["externalStatus"] = dataJson.externalStatus;
                  this.extStatusCConfirmIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusCConfirmIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                if (this.customerConfirmEnable) {
                  this.defaultObjCustomerConfirm["enable"] = true;
                } else {
                  this.defaultObjCustomerConfirm["enable"] = false;
                }
              } else {
                this.customerConfirmEnable = false;
              }
            }
            if (el.paramName == 'Self Observation') {
              this.defaultObjSelfObservation["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log("&&&", dataJson)
              if (!!dataJson) {
                this.selfObservationEnable = dataJson.enable;
                if (this.selfObservationEnable) {
                  this.defaultObjSelfObservation["enable"] = true;
                } else {
                  this.defaultObjSelfObservation["enable"] = false;
                }
                if (!!dataJson.externalStatus) {
                  this.defaultObjSelfObservation["externalStatus"] = dataJson.externalStatus;
                  this.extStatusSelfObvIdList = []
                  this.externalStatuslist.forEach(item => {
                    if (item.id == dataJson.externalStatus) {
                      this.extStatusSelfObvIdList.push({ id: item.id, name: item.name })
                    }
                  })
                }
                console.log(this.partyMasterIdList)
              } else {
                this.selfObservationEnable = false;
              }

            }
          })
        } else {
          this.defaultData = [];
        }
      }
      console.log(this.actionId)
      let voucherArray = this.listOfActions.forEach(el => {
        if (el.id == this.actionId) {
          return el;
        }
      })
    } else {
      await this.configService.dismiss();
      this.selectedAction = "";
      this.description = "";
      this.listOfActionPermission = [];
    }
  }

  async findAllTypeForPricing() {
    let res: any;
    res = await this.httpApiService.findAllTypeForPricing();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let tempArray = []
      for (let i = 0; i < dataValue.length; i++) {
        tempArray.push({ id: i + 1, name: dataValue[i] })
      }
      this.typeList = tempArray;
      console.log(this.typeList)
    }
  }


  async getAllVoucherBook() {
    let res: any;
    res = await this.httpApiService.getAllVouchersForSelectedType(
      this.voucherType
    );
    let data = res.json();
    console.log(data)
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfVoucherBook = data.data;
      console.log(this.listOfVoucherBook);
    }
  }

  async filterItems(ev: any) {
    this.selectedAction = "";
    this.listOfActionPermission = [];
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        return item.actionName.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocation = -1;
    } else {
      this.arrowKeyLocation = -1;
      this.items = this.listOfActions;
    }
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedAction = this.items[
              this.arrowKeyLocation
            ].actionName;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedAction = this.items[
              this.arrowKeyLocation
            ].actionName;
            break;
          } else {
            break;
          }
        }
    }
  }

  async getAllActions() {
    let res: any;
    this.userData = await this.getUserData();
    res = await this.httpApiService.getAllActions();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfActions = data.data;
      this.items = this.listOfActions;
      console.log("list of actions by http", res, this.listOfActions);
    }
  }

  async getAllCompany() {
    let res: any;
    // let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.listOfLocations = dataValue;
    }
  }

  async getAllStoneStatus() {
    let res: any;
    res = await this.httpApiService.getStatus();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let internalArray = []
      let externalArray = []
      if (dataValue.length > 0) {
        dataValue.forEach(el => {
          if (el.type == 'Internal' && el.isActive == true) {
            internalArray.push(el);
          } else if (el.type == 'External' && el.isActive == true) {
            externalArray.push(el);
          }
        })
        this.internalStatuslist = internalArray;
        this.externalStatuslist = externalArray;
      }
    }
  }

  async getAllToUser(toLocation) {
    this.usersList = [];
    this.toUser = null;
    let res: any;
    let appName = await this.configService.appName;
    res = await this.httpApiService.getAllUsersByCompany(appName, toLocation);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.length > 0) {
        this.usersList = dataValue;
      } else {
        this.usersList = [];
      }
    }
  }

  filter(obj, predicate) {
    return Object.keys(obj)
      .filter(key => predicate(key, obj[key]))
      .reduce((res, key) => (res[key] = obj[key], res), {});
  }

  async updateUserDefaultData() {
    let res: any;
    console.log(this.defaultData)
    await this.configService.showLoader()
    let jsonObj: any;
    let mainObj = this.defaultData.map(function (key, value) {
      return key.paramName;
    })
    jsonObj = {
      actionId: this.actionId,
      defaultTemplate: this.defaultTemplateId,
      fromDepartment: this.fromDepartment,
      toDepartment: this.toDepartment,
      toUser: this.toUser,
      toLocation: this.toLocation,
      fromUser: this.fromUser,
      voucherBook: this.voucherBook,
      requestVoucherBook: this.requestVoucherBook,
      type: this.type,
      toRole: this.toRole,
      fromRole: this.fromRole,
      Limit: this.limit,
      ctsDifference: this.ctsDifference,
      priceListName: this.priceListName,
      basePriceListName: this.basePriceListName,
      transType: this.transType,
      internalStatus: this.internalStatus,
      boxType: this.boxType,
      voucherBookList: JSON.stringify(this.voucherBookList),
      pricePolicyChainList: JSON.stringify(this.pricePolicyChainList),
      listOfPrices: JSON.stringify(this.listOfPrices),
      defaultKamPerson: this.defaultKamPerson,
      isAllowedStatus: JSON.stringify(this.isAllowedStatus),
      fullStockProcess: this.fullStockProcessList.toString(),
      matchFromDB: this.matchFromDB,
      newStockProcessingStatus: this.newStockProcessingStatus,
      compareJson: this.compareJson,
      'SVCKAMMapping': this.svcKam,
      'Accusoft sync': this.accusoftSync,
      'Return': this.stoneReturn,
      'Recut': Object.keys(this.defaultObjRecut).length > 0 ? JSON.stringify(this.defaultObjRecut) : this.defaultObjRecut["enable"] = false,
      'Observation': Object.keys(this.defaultObjObservation).length > 0 ? JSON.stringify(this.defaultObjObservation) : this.defaultObjObservation["enable"] = false,
      'Self Observation': Object.keys(this.defaultObjSelfObservation).length > 0 ? JSON.stringify(this.defaultObjSelfObservation) : this.defaultObjSelfObservation["enable"] = false,
      'Requisition': Object.keys(this.defaultObjRequisition).length > 0 ? JSON.stringify(this.defaultObjRequisition) : this.defaultObjRequisition["enable"] = false,
      'Certification': Object.keys(this.defaultObjCertification).length > 0 ? JSON.stringify(this.defaultObjCertification) : this.defaultObjCertification["enable"] = false,
      'Consignment Confirm': Object.keys(this.defaultObjConsignmentConfirm).length > 0 ? JSON.stringify(this.defaultObjConsignmentConfirm) : this.defaultObjConsignmentConfirm["enable"] = false,
      'Memo Client': Object.keys(this.defaultObjMemoClient).length > 0 ? JSON.stringify(this.defaultObjMemoClient) : this.defaultObjMemoClient["enable"] = false,
      'Sold': Object.keys(this.defaultObjSold).length > 0 ? JSON.stringify(this.defaultObjSold) : this.defaultObjSold["enable"] = false,
      'Request To KAM': Object.keys(this.defaultObjRequestToKAM).length > 0 ? JSON.stringify(this.defaultObjRequestToKAM) : this.defaultObjRequestToKAM["enable"] = false,
      'Hold Customer': Object.keys(this.defaultObjCustomerHold).length > 0 ? JSON.stringify(this.defaultObjCustomerHold) : this.defaultObjCustomerHold["enable"] = false,
      'Block For Sale': Object.keys(this.defaultObjBlockForSale).length > 0 ? JSON.stringify(this.defaultObjBlockForSale) : this.defaultObjBlockForSale["enable"] = false,
      'Unhold Customer': Object.keys(this.defaultObjCustomerUnHold).length > 0 ? JSON.stringify(this.defaultObjCustomerUnHold) : this.defaultObjCustomerUnHold["enable"] = false,
      'Unblock For Sale': Object.keys(this.defaultObjUnblockForSale).length > 0 ? JSON.stringify(this.defaultObjUnblockForSale) : this.defaultObjUnblockForSale["enable"] = false,
      'Customer Confirm': Object.keys(this.defaultObjCustomerConfirm).length > 0 ? JSON.stringify(this.defaultObjCustomerConfirm) : this.defaultObjCustomerConfirm["enable"] = false,
      'Memo Service': Object.keys(this.defaultObjMemoService).length > 0 ? JSON.stringify(this.defaultObjMemoService) : this.defaultObjMemoService["enable"] = false,
      'Consignment In Stock': Object.keys(this.defaultObjConsignmentInStock).length > 0 ? JSON.stringify(this.defaultObjConsignmentInStock) : this.defaultObjConsignmentInStock["enable"] = false,
      'partyType': JSON.stringify(this.partyType),
      'mailIds': !!this.mailIds ? JSON.stringify(this.mailIds.split(',')) : JSON.stringify([]),
      'Convert To Mix': Object.keys(this.defaultObjConvertToMix).length > 0 ? JSON.stringify(this.defaultObjConvertToMix) : this.defaultObjConvertToMix["enable"] = false,
      // mixing: this.mixing,
    }
    console.log(jsonObj)
    let filtered: any = this.filter(jsonObj, score => mainObj.indexOf(score) != -1);
    console.log(filtered)
    filtered.actionId = this.actionId;
    if (this.manageSelectedAction == "Reports") {
      this.defaultData.forEach(el => {
        console.log(el.paramName)
        if (el.paramName == 'reports') {
          // el.paramValue.forEach(item => {
          //   delete item.reportValue.departmentIdList;
          //   delete item.reportValue.transTypeIdList;
          // })
          filtered.reports = JSON.stringify(el.paramValue)
        }
      })
    }
    if (this.partyType.length == 0) {
      delete filtered['partyType'];
    }
    console.log(filtered)
    res = await this.httpApiService.updateActionDefault(filtered)
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.searchManageActionForPermission(this.selectedAction)
    }
  }

  checkRecut(isDirect) {
    if (this.recutEnable) {
      this.defaultObjRecut["enable"] = true;
      if (isDirect) {
        this.defaultObjRecut["isDirect"] = true;
      }
      else {
        this.defaultObjRecut["isDirect"] = false;
      }
    } else {
      this.defaultObjRecut["enable"] = false;
      delete this.defaultObjRecut["toLocation"];
      delete this.defaultObjRecut["isDirect"];
      delete this.defaultObjRecut["externalStatus"];
    }
    console.log(this.defaultObjRecut)
  }

  checkObservation(isDirect) {
    if (this.observationEnable) {
      this.defaultObjObservation["enable"] = true;
      if (isDirect) {
        this.defaultObjObservation["isDirect"] = true;
      }
      else {
        this.defaultObjObservation["isDirect"] = false;
      }
    } else {
      this.defaultObjObservation["enable"] = false;
      delete this.defaultObjObservation["toLocation"];
      delete this.defaultObjObservation["isDirect"];
      delete this.defaultObjObservation["externalStatus"];
    }
    console.log(this.defaultObjObservation)
  }

  checkSelfObservation() {
    if (this.selfObservationEnable) {
      this.defaultObjSelfObservation["enable"] = true;
    } else {
      this.defaultObjSelfObservation["enable"] = false;
      delete this.defaultObjRecut["externalStatus"];
    }
    console.log(this.defaultObjSelfObservation)
  }

  checkMemoClient(isDirect) {
    if (this.memoClientEnable) {
      this.defaultObjMemoClient["enable"] = true;
      if (isDirect) {
        this.defaultObjMemoClient["isDirect"] = true;
      }
      else {
        this.defaultObjMemoClient["isDirect"] = false;
      }
    } else {
      this.partyTypeMemoClientIdList = []
      this.defaultObjMemoClient["enable"] = false;
      delete this.defaultObjMemoClient["partyType"];
      delete this.defaultObjMemoClient["isDirect"];
      delete this.defaultObjMemoClient["externalStatus"];
    }
    console.log(this.defaultObjMemoClient)
  }

  checkSold(isDirect) {
    if (this.soldEnable) {
      this.defaultObjSold["enable"] = true;
      if (isDirect) {
        this.defaultObjSold["isDirect"] = true;
      }
      else {
        this.defaultObjSold["isDirect"] = false;
      }
    } else {
      this.partyTypeSoldIdList = [];
      this.extStatusSoldIdList = [];
      this.defaultObjSold["enable"] = false;
      delete this.defaultObjSold["partyType"];
      delete this.defaultObjSold["isDirect"];
      delete this.defaultObjSold["externalStatus"];
    }
    console.log(this.defaultObjSold)
  }

  checkRequestToKAM() {
    if (this.requestToKAMEnable) {
      this.defaultObjRequestToKAM["enable"] = true;
    } else {
      this.partyTypeRequestToKAMIdList = []
      this.defaultObjRequestToKAM["enable"] = false;
      delete this.defaultObjRequestToKAM["partyType"];
    }
    console.log(this.defaultObjRequestToKAM)
  }

  checkCustomerHold() {
    if (this.customerHoldEnable) {
      this.defaultObjCustomerHold["enable"] = true;
    } else {
      this.partyTypeCustomerHoldIdList = []
      this.voucherForHoldCustomerIdList = []
      this.defaultObjCustomerHold["enable"] = false;
      delete this.defaultObjCustomerHold["partyType"];
      delete this.defaultObjCustomerHold["voucherBookId"];
    }
    console.log(this.defaultObjCustomerHold)
  }

  checkBlockForSale() {
    if (this.blockForSaleEnable) {
      this.defaultObjBlockForSale["enable"] = true;
    } else {
      this.partyTypeBlockForSaleIdList = []
      this.voucherForBlockForSaleIdList = []
      this.defaultObjBlockForSale["enable"] = false;
      delete this.defaultObjBlockForSale["partyType"];
      delete this.defaultObjBlockForSale["voucherBookId"];
    }
    console.log(this.defaultObjBlockForSale)
  }

  checkConvertToMix(isDirect) {
    if (this.convertToMixEnable) {
      this.defaultObjConvertToMix["enable"] = true;
      if (isDirect) {
        this.defaultObjConvertToMix["isDirect"] = true;
      }
      else {
        this.defaultObjConvertToMix["isDirect"] = false;
      }
    } else {
      this.defaultObjConvertToMix["enable"] = false;
      delete this.defaultObjConvertToMix["isDirect"];
    }
    console.log(this.defaultObjConvertToMix)
  }

  checkCustomerUnHold() {
    if (this.customerUnHoldEnable) {
      this.defaultObjCustomerUnHold["enable"] = true;
    } else {
      this.voucherForUnHoldCustomerIdList = []
      this.defaultObjCustomerUnHold["enable"] = false;
      delete this.defaultObjCustomerHold["voucherBookId"];
    }
    console.log(this.defaultObjCustomerUnHold)
  }

  checkUnblockForSale() {
    if (this.unblockForSaleEnable) {
      this.defaultObjUnblockForSale["enable"] = true;
    } else {
      this.voucherForUnblockForSaleIdList = []
      this.defaultObjUnblockForSale["enable"] = false;
      delete this.defaultObjUnblockForSale["voucherBookId"];
    }
    console.log(this.defaultObjUnblockForSale)
  }


  checkCConfirm(isDirect) {
    if (this.customerConfirmEnable) {
      this.defaultObjCustomerConfirm["enable"] = true;
      if (isDirect) {
        this.defaultObjCustomerConfirm["isDirect"] = true;
      } else {
        this.defaultObjCustomerConfirm["isDirect"] = false;
      }
    } else {
      this.partyTypeCConfirmIdList = []
      this.defaultObjCustomerConfirm["enable"] = false;
      delete this.defaultObjCustomerConfirm["partyType"];
      delete this.defaultObjCustomerConfirm["isDirect"];
      delete this.defaultObjCustomerConfirm["externalStatus"];
    }
    console.log(this.defaultObjCustomerConfirm)
  }

  checkMemoService(isDirect) {
    if (this.memoServiceEnable) {
      this.defaultObjMemoService["enable"] = true;
      if (isDirect) {
        this.defaultObjMemoService["isDirect"] = true;
      } else {
        this.defaultObjMemoService["isDirect"] = false;
      }
    } else {
      this.partyTypeForMemoServiceIdList = []
      this.defaultObjMemoService["enable"] = false;
      delete this.defaultObjMemoService["partyType"];
      delete this.defaultObjMemoService["isDirect"];
      delete this.defaultObjMemoService["externalStatus"];
    }
    console.log(this.defaultObjMemoService)
  }

  checkConsignmentConfirm(isDirect) {
    if (this.consignmentConfirmEnable) {
      this.defaultObjConsignmentConfirm["enable"] = true;
      if (isDirect) {
        this.defaultObjConsignmentConfirm["isDirect"] = true;
      } else {
        this.defaultObjConsignmentConfirm["isDirect"] = false;
      }
    } else {
      this.partyTypeForConsignmentConfirmIdList = []
      this.defaultObjConsignmentConfirm["enable"] = false;
      delete this.defaultObjConsignmentConfirm["partyType"];
      delete this.defaultObjConsignmentConfirm["isDirect"];
      delete this.defaultObjConsignmentConfirm["externalStatus"];
    }
    console.log(this.defaultObjConsignmentConfirm)
  }

  checkConsignmentInStock(isDirect) {
    if (this.consignmentInStockEnable) {
      this.defaultObjConsignmentInStock["enable"] = true;
      if (isDirect) {
        this.defaultObjConsignmentInStock["isDirect"] = true;
      } else {
        this.defaultObjConsignmentInStock["isDirect"] = false;
      }
    } else {
      this.partyTypeForConsignmentInStockIdList = []
      this.defaultObjConsignmentInStock["enable"] = false;
      delete this.defaultObjConsignmentInStock["partyType"];
      delete this.defaultObjConsignmentInStock["isDirect"];
      delete this.defaultObjConsignmentInStock["externalStatus"];
    }
    console.log(this.defaultObjConsignmentInStock)
  }

  checkCertification(isDirect) {
    if (this.certificationEnable) {
      this.defaultObjCertification["enable"] = true;
      if (isDirect) {
        this.defaultObjCertification["isDirect"] = true;
      } else {
        this.defaultObjCertification["isDirect"] = false;
      }
    } else {
      this.partyTypeForCertificationIdList = []
      this.defaultObjCertification["enable"] = false;
      delete this.defaultObjCertification["partyType"];
      delete this.defaultObjCertification["isDirect"];
      delete this.defaultObjCertification["externalStatus"];
    }
    console.log(this.defaultObjCertification)
  }

  checkRequisition() {
    if (this.requisitionEnable) {
      this.defaultObjRequisition["enable"] = true;
    } else {
      this.partyTypeForRequisitionIdList = []
      this.defaultObjRequisition["enable"] = false;
      delete this.defaultObjRequisition["partyType"];
      delete this.defaultObjRequisition["externalStatus"];
    }
    console.log(this.defaultObjRequisition)
  }

  checkForReport(paramValueList, item, index) {
    console.log(paramValueList, item, index)
    if (item.reportValue.enable) {

      paramValueList[index].reportValue.enable = true;
    } else {
      item.reportValue.departmentList = []
      item.reportValue.transTypeList = []
      item.reportValue.pricingJson = []
      item.reportValue.exportTemplateIdList = []
      item.reportValue.departmentIdList = []
      item.reportValue.showPrice = false;
      item.reportValue.transTypeIdList = []
      paramValueList[index].reportValue.enable = false;
    }
  }

  addInPricing(dataObj) {
    this.defaultData.filter(el => {
      if (el.paramValue) {
        el.paramValue.filter(item => {
          if (item.reportName == dataObj.reportName) {
            let data = { department: this.deptForPriceInReport, transType: this.transTypeForPriceInReport, priceListName: this.priceForReport }
            if (item.reportValue.pricingJson.length > 0) {
              let isExist = false;
              isExist = item.reportValue.pricingJson.some(x => (x.department.id == this.deptForPriceInReport.id && x.transType.id == this.transTypeForPriceInReport.id));
              if (!isExist) {
                item.reportValue.pricingJson.push(data);
              } else {
                this.configService.showToast('error', 'Record already exist')
              }
            } else {
              item.reportValue.pricingJson.push(data);
            }
          }
        })
      }
    })

    this.deptForPriceInReport = null;
    this.transTypeForPriceInReport = null;
    this.priceForReport = null;
    this.deptForReportIdList = [];
    this.transTypePriceIdList = [];
    this.priceForReportIdList = [];
    console.log(this.defaultData)
  }

  removeFromPricingJson(pricingItemArr, index) {
    console.log(pricingItemArr, index)
    pricingItemArr.splice(index, 1)
  }

  logout() {
    this.authService.logout();
  }

  async getAllPolicyChain() {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getAllPolicyChain();
    let data = res.json();
    await this.loadingController.dismiss()
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      if (data.data.length > 0) {
        this.listOfChains = data.data;
      }
    }
  }

  async getVoucherBookListForPendingApproval() {
    let res: any;
    res = await this.httpApiService.getAllVouchersForSelectedTypes(
      'Returnable,Non Returnable'
    );
    let data = res.json();
    console.log(data)
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfVoucherBook = data.data;
      console.log(this.listOfVoucherBook);
    }
  }
}
