import { Injectable, EventEmitter, Output } from "@angular/core";
// import * as AWS from 'aws-sdk/global';
// import * as S3 from 'aws-sdk/clients/s3';
import * as moment from "moment";
import { Observable } from "rxjs/internal/Observable";
import { Observer } from "rxjs/internal/types";
@Injectable({
  providedIn: "root",
})
export class UploadService {
  public bucketName = "kgdiamonds-website";
  public bucket: any;
  base64Image: string;
  // @Output() fileName = new EventEmitter<string>();

  constructor() {
 
  }
  public uploadInit(s3Obj: any,bucketName:string='kgdiamonds-website') {
    this.bucket = s3Obj;
    this.bucketName = bucketName;
    console.log(this.bucket);
  }

  

  uploadFile(file: any, rootPath: string) {
    var promise = new Promise((resolve, reject) => {
      console.log("upload image service:  uploadFile: file:", file);
      let contentType = file.type;
      // this.fileName.emit(file.name)
      let fileName = file.name,
        body = file;
      console.log(fileName);

      if (!file.type) {
        contentType = "image/jpeg";
      }

      if (file.body) {
        body = file.body;
        console.log(body);
        const data = body.replace(/^data:image\/[a-z]+;base64,/, "");
        console.log(data);
        body = new Buffer(data, "base64");
      }
      console.log(body);
      let params: any = {
        Bucket: this.bucketName,
        Key: `${rootPath}/${fileName}`,
        Body: body,
        ACL: "public-read",
        ContentType: contentType,
        Metadata: {
          "x-amz-meta-hello": "Custom Metadata Value",
        },
        // 'Content-Disposition': 'attachment'
        ContentDisposition: 'attachment; filename="' + fileName + '"',
      };

      if (!!file.body) {
        params["ContentEncoding"] = "base64";
      }

      console.log(
        "upload image service: uploadFile: param",
        params,
        this.bucket
      );
      this.bucket.upload(params, function (err: any, data: any) {
        if (err) {
          console.log("upload image service: EROOR: ", JSON.stringify(err));
          reject(false);
        }

        if (!!data && data.Location) {
          console.log("upload image service: File Uploaded.", data);
          resolve(data.Location);
        }
      });
    });
    return promise;
  }

  // var promise = new Promise((resolve, reject) => {
  // });

  // uploadFile(file: any, rootPath) {
  //   console.log(file)
  //   const contentType = file.type;

  //   const params = {
  //     Bucket: this.bucketName,
  //     Key: `${rootPath}/${file.name}`,
  //     Body: file,
  //     ACL: 'public-read',
  //     ContentType: contentType
  //   };
  //   this.bucket.upload(params, function (err: any, data: any) {
  //     if (err) {
  //       console.log('EROOR: ', JSON.stringify(err));
  //       return false;
  //     }

  //     if (!!data && data.Location) {
  //       console.log('File Uploaded.', data);
  //       return data.Location
  //     }
  //   });
  // }

  deleteFile(imageName: string) {
    var params = {
      Bucket: this.bucketName,
      Key: String(imageName),
    };
    console.log("upload image service: deleteFile: param", params);
    return new Promise((resolve, reject) => {
      this.bucket.deleteObject(params, function (err: any, data: any) {
        console.log("upload image service: deleteFile: error, data", err, data);
        if (err) {
          reject(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  // downloadImage(imageUrl:any){
  //   this.getBase64ImageFromURL(imageUrl).subscribe((base64data: any) => {
  //     console.log(base64data);
  //     this.base64Image = "data:image/jpg;base64," + base64data;
  //     // save image to disk
  //     var link = document.createElement("a");

  //     document.body.appendChild(link); // for Firefox

  //     link.setAttribute("href", this.base64Image);
  //     link.setAttribute("download", "mrHankey.jpg");
  //     link.click();
  //   });
  // }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: any = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  downloadImages(filename: any) {
    const params = {
      Bucket: this.bucketName,
      Key: filename,
    };

    this.bucket
      .getObject(params, (err: any, data: any) => {
        if (err) {
          console.log(err);
          // shows AWS s3 error
        } else {
          console.log(data);
          // response of binary data
          // use your download function here
          // window.open(data, "_blank");
        }
      })
      .on("httpDownloadProgress", (progress: any) => {
        // shows file download progress
        console.log(progress);
      });
  }
}