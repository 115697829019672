import { Component, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-update-stone-id',
  templateUrl: './update-stone-id.component.html',
  styleUrls: ['./update-stone-id.component.scss']
})
export class UpdateStoneIdComponent implements ProcessActionComponent {
  @Input() data: any;

  public stoneId: any;
  public newStoneId: any;

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public event: Events) { }

  ngOnInit() {
  }

  async confirmUpdateStoneId() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to change StoneId ? ",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.changeStoneId();
          }
        }
      ]
    });

    await alert.present();
  }

  async changeStoneId() {
    if (!!this.stoneId && !!this.newStoneId) {
      let res: any;
      let jsonObj = {
        stoneId: this.stoneId,
        newStoneId: this.newStoneId
      }
      res = await this.httpApiService.changeStoneId(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.stoneId = null;
        this.newStoneId = null;
        await this.configService.showToast("success", data.message);
      }
    } else {
      await this.configService.showToast("error", 'Please enter details');
    }
  }

}
