import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-manufacturing-search',
  templateUrl: './manufacturing-search.component.html',
  styleUrls: ['./manufacturing-search.component.scss']
})
export class ManufacturingSearchComponent implements OnInit {
  @Input() data: any;
  public query: any;
  public type = "SS";
  public location =['surat']
  public disable: any = [];
  public cart = false;
  public svcDataOption = true;
  public showScannerOption = false;
  public default = {
    criteria: true,
    search: true,
    shape: true,
    carat: true,
    color: true,
    colorOverride: false,
    clarity: true,
    clarityOverride: false,
    description: true,
    finishing: true,
    fluorescence: true,
    lab: true,
    size: false,
    internalStatus: true,
    externalStatus: true,
    transType: false,
    users: true,
    location: true,
    box: true,
    department: true,
    saveSearch: true,
    download: true,
    fm: true,
    party: true,
    availableForSale: true,
    certiDate: false,
    inwardDate: true,
    transactionDate: true,
    standardShapeCode: true
  };
  constructor() { }

  ngOnInit() {
    // let name = [];
    // name.push(this.type);
    // this.disable.push("transType");
    this.disable.push("department");
    this.query = {
      //   facetFilters: ["transType:" + [this.type], "department:" + [this.department]],
      disjunctiveFacetsRefinements: {
        // transType: name,
       location: this.location
      }
    };
  }

}
