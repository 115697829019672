export default {
  reportNo: 'R-10',
  url: "master-service/api/report/deptWiseStockSummary",
  method: "GET",
  size: 3,
  fields: [{
    title: 'Company ',
    input: 'select',
    key: 'companyId',
    placeholder: 'Select Company',
    property: 'companyId',
    keyName: 'id',
    name: 'companyName',
    required: true,
    singleSelection: true,
    disable: true,
    function: 'getellCompanies',
    default: [{
      id: 2,
      companyName: 'Mumbai'
    }],
    size: 3
  }],
  loadInitially: true,
  columns: [
    {
      "name": "Department",
      "prop": "name",
      "displayFormat": "Total",
      "showInGroupFooter": true
    },
    {
      "name": "Pcs",
      "prop": "singlePcs",
      "displayFormat": "{0}",
      "summaryType": "sum",
      "showInGroupFooter": true
    },
    {
      "name": "Single Cts",
      "prop": "singleCts",
      "displayFormat": "{0}",
      "summaryType": "sum",
      "showInGroupFooter": true
    },
    {
      "name": "Mix Cts",
      "prop": "mixCts",
      "displayFormat": "{0}",
      "summaryType": "sum",
      "showInGroupFooter": true
    },
    {
      "name": "Total",
      "displayFormat": "{0}",
      "summaryType": "sum",
      "dynamic": ["singleCts", "+", "mixCts"],
      "showInGroupFooter": true
    },
    {
      "name": "Total wgt diff",
      "prop": "totalWgtDiff",
      "displayFormat": "{0}",
      "summaryType": "sum",
      "showInGroupFooter": true
    }
  ],
  "subColumns": {
    "url": "master-service/api/report/deptWiseStockDetails",
    "obj": {
      "departmentId": [
        "id"
      ],
      "departmentName": [
        "name"
      ]
    },
    "showPivotGrid": false,
    "fields": [
      {
        "name": "Status",
        "prop": "status",
        "displayFormat": "Total",
        "showInGroupFooter": true
      },
      {
        "name": "Party",
        "prop": "partyName",
        "displayFormat": " ",
        "showInGroupFooter": true
      },
      {
        "name": "Trans Type",
        "prop": "transType",
        "displayFormat": " ",
        "showInGroupFooter": true
      },
      {
        "name": "Stone Pcs",
        "prop": "stonePcs",
        "displayFormat": "{0}",
        "summaryType": "sum",
        "showInGroupFooter": true
      },
      {
        "name": "Stone Cts",
        "prop": "stoneCts",
        "displayFormat": "{0}",
        "summaryType": "sum",
        "showInGroupFooter": true
      }
    ]
  },
  "companyLogo": true
};
