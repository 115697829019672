import { Component, OnInit } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { LoadingController, ModalController, NavParams } from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-manage-ss-parameter",
  templateUrl: "./manage-ss-parameter.page.html",
  styleUrls: ["./manage-ss-parameter.page.scss"]
})
export class ManageSsParameterPage implements OnInit {
  public item: any;
  public upTolerance: number;
  public downTolerance: number;
  public parameterName: any;
  public nameGroupList = []
  public nameGroup: any;
  public flagNewGroup: Boolean = false;
  public nameGroupIdList = []
  constructor(
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public navParams: NavParams
  ) { }

  async ngOnInit() {
    this.item = await this.navParams.get("id");
    this.upTolerance = await this.item.upTolerance;
    this.downTolerance = await this.item.downTolerance;
    this.parameterName = await this.item.masterName;
    await this.findGroupNames();
    if (!!this.item.nameGroup) {
      this.nameGroupIdList = []
      this.nameGroup = this.item.nameGroup
      this.nameGroupList.filter(el => {
        if (el.name == this.item.nameGroup) {
          this.nameGroupIdList.push({ id: el.id, name: el.name })
        }
      })
    }
    console.log(this.item);

    await this.findGroupNames();
  }
  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async findGroupNames() {
    let res: any;
    await this.configService.showLoader();
    res = await this.httpApiService.findGroupNames();
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      let data1 = []
      if (dataList.length > 0) {
        for (let i = 0; i < dataList.length; i++) {
          data1.push({ id: i + 1, name: dataList[i] })
        }
      } else {
        this.flagNewGroup = true;
      }
      this.nameGroupList = data1;
    }
  }

  addNewGroup() {
    this.flagNewGroup = true;
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "group") {
      if (idList.length > 0) {
        this.nameGroup = obj[0].name;
      } else {
        this.nameGroup = null
      }
    }
  }

  async updateTolerance() {
    let res;
    let id = this.item.id;
    if (this.upTolerance == null) {
      this.upTolerance = 0;
    }
    if (this.downTolerance == null) {
      this.downTolerance = 0;
    }
    let jsonObj = {
      upTolerance: this.upTolerance,
      downTolerance: this.downTolerance,
      nameGroup: this.nameGroup
    };
    console.log(jsonObj);
    await this.configService.showLoader();
    res = await this.httpApiService.updateTolerance(id, jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.modalCtrl.dismiss();
    }
  }
}
