import { Component, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";


@Component({
  selector: 'app-sync-other-office-stock',
  templateUrl: './sync-other-office-stock.component.html',
  styleUrls: ['./sync-other-office-stock.component.scss']
})
export class SyncOtherOfficeStockComponent implements ProcessActionComponent {
  @Input() data: any;
  RFidList: string = '';
  filtered: any[] = [];
  listOfLocations = [];
  companyId = null;
  parametersList = null;
  toLocationidList = []


  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events) { }

  ngOnInit() {
    this.getAllCompany()
    this.getDefaultValues()
  }

  dropDownSelection(ev) {
    let property = ev.property;
    let idList = ev.idList;
    if (property == "company") {
      if (idList.length > 0) {
        this.companyId = idList[0]
      } else {
        this.companyId = null
      }
    }
  }

  async getDefaultValues() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(dataValue)
      if (!!dataValue.map && dataValue.map.Parameters) {
        this.parametersList = !!dataValue.map.Parameters ? JSON.parse(dataValue.map.Parameters).join(",") : ""
      }
    }
  }

  async getAllCompany() {
    let res: any;
    // let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.listOfLocations = dataValue;
    }
  }


  async syncDataOnElastic() {
    await this.configService.showLoader()
    let res: any;
    let filtered = this.RFidList.split("\n");
    let issueList = []
    filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    filtered = issueList.filter(function (el) {
      return el != "";
    });
    let payload = {
      otherOfficeStoneIds: filtered,
      companyId: this.companyId,
      params: this.parametersList.split(",")
    }
    res = await this.httpApiService.manualSyncForOtherLocation(payload);
    let data = res.json();
    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.reset()
      await this.configService.showToast("success", data.message);
    }
  }

  reset() {
    this.toLocationidList = [];
    this.companyId = null;
    this.parametersList = null;
    this.RFidList = '';
  }


}
