import { Injectable } from "@angular/core";

import { Storage } from "@ionic/storage";
import { ConfigService } from "../config/config.service";
import { Headers, Http, ResponseContentType } from "@angular/http";
@Injectable({
  providedIn: "root",
})
export class HttpApiService {
  baseUrl: string;
  appName: string;
  headers: any;
  nodeServerUrl: string;
  trybeServerUrl: string;
  talkBriteUrl: string;

  constructor(
    public http: Http,
    private storage: Storage,
    public configService: ConfigService
  ) {
    this.getBaseUrl();
    this.getAppName();
    this.getNodeServerUrl();
    this.getTrybeServerUrl();
    this.getTalkBriteUrl();

    // this.getApiHeader();
  }

  async getBaseUrl() {
    this.baseUrl = await this.configService.getBaseUrl();
  }

  async getNodeServerUrl() {
    this.nodeServerUrl = await this.configService.getNodeServerUrl();
  }
  async getTalkBriteUrl() {
    this.talkBriteUrl = await this.configService.getTalkBriteUrl();
  }

  async getTrybeServerUrl() {
    this.trybeServerUrl = await this.configService.getTrybeServerUrl();
  }

  async getAppName() {
    this.appName = await this.configService.getAppName();
  }

  async getApiHeader() {
    this.headers = await this.configService.getAPIHeader();
  }

  async getAllActionsByUserID(userId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/action/findAllActionsByUserId?userId=" +
          userId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllActions() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/action/findAll?appName=" +
          this.appName,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllActionPermissionById(ActionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "user-service/api/action/findById?id=" + ActionId, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllRoles() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/role/findAll?appName=" +
          this.appName,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllLayoutCategories() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "layoutCategory", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getLayoutItemsByCategoryId(categoryId: any) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + `layoutCategoryItems/${categoryId}`, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addRole(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "user-service/api/role/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async addNewLayoutCategory(payload: any) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "layoutCategory", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async addNewItemInLayoutCategory(payload: any) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "layoutCategoryItems", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateItemInSpecificCategory(itemId: any, payload: any) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.nodeServerUrl +
          `layoutCategoryItems/${payload.layoutCategoryId}/${itemId}`,
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async updateCategory(categoryId: any, payload: any) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.nodeServerUrl + `layoutCategory/${categoryId}`, payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteItemInSpecificCategory(layoutCategoryId: any, itemId: any) {
    console.log(layoutCategoryId, itemId, "http check");
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.nodeServerUrl +
          `layoutCategoryItems/${itemId}/${layoutCategoryId}`,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async deleteSpecificCategory(layoutCategoryId: any) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.nodeServerUrl + `layoutCategory/${layoutCategoryId}`, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addRolePermission(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "user-service/api/role_permission/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateRole(roleId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "user-service/api/role/update/" + roleId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteRole(roleId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "user-service/api/role/delete/" + roleId, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteRolePermission(roleId, actionPermissionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "user-service/api/role_permission/removeByRoleIdAndActionPermissionId/" +
          roleId +
          "/" +
          actionPermissionId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findRoleDetailsById(roleId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "user-service/api/role/findById?id=" + roleId, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async findLayoutDetailsById(roleId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + `layoutCategory/${roleId}`, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  //Start Manage-User

  async getAllUser(fromLocation) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/user/findAll?appName=" +
          this.appName +
          "&fromLocation=" +
          fromLocation,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getUserDetailById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "user-service/api/user/findById?id=" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getUserDetailByIdForChangePasssword(id, tokenObj) {
    try {
      const response = await this.http
        .get(this.baseUrl + "user-service/api/user/findById?id=" + id, {
          headers: new Headers(tokenObj),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async createUser(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "user-service/api/user/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteUserRole(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "user-service/api/user_role/removeRoleFromUser/" +
          jsonData.userId +
          "/" +
          jsonData.roleId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveRoleForUser(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "user-service/api/user_role/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async changePassword(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "user-service/api/user/resetPassword", jsonData)
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async forgotPassword(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "user-service/api/user/forgotPassword", jsonData)
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateUser(userId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "user-service/api/user/update/" + userId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  //Master CRUD

  async getAllStoneMaster() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/stone-parameter-name/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStoneMasterDetailById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone-parameter-value/findByStoneParameterNameId?id=" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStoneMasterDetailByName(paramName) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone-parameter-value/findByStoneParameterName?stoneParameterName=" +
          paramName,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addStoneParameterValue(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone-parameter-value/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addStoneParameterName(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone-parameter-name/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteStoneParamValue(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/stone-parameter-value/delete/" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async UpdateStoneParameterValue(userId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl +
          "master-service/api/stone-parameter-value/update/" +
          userId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadFile(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/uploadStonesIfNoErrors",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadCsvForSingleSaleStock(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/box/uploadCsvForSingleSaleStock",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadCsvForMixStock(formData, boxType, roleId) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/box/uploadCsvForAssortmentBox?boxType=" +
          boxType +
          "&roleId=" +
          roleId,
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadCsvForMixBoxRegular(formData, boxType, roleId) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/box/uploadCsvForBoxCriteria?boxType=" +
          boxType +
          "&roleId=" +
          roleId,
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadRecut(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/stone/recut", formData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadStock(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/uploadStockData",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteStock() {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/deleteStockData",
          {},
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async approveAllVouchers() {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/voucher/approveAllVouchers",
          {},
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async downloadUploadedFile(fileId) {
    try {
      let url =
        this.baseUrl +
        "master-service/api/stone/csvDownload?fileName=" +
        fileId;
      window.open(url, "_blank");
    } catch (error) {
      return await error;
    }
  }

  async downloadFileByType(fileId, type) {
    try {
      let url =
        this.baseUrl +
        "master-service/api/stone/csvDownload?fileName=" +
        fileId +
        "&fileType=" +
        type;
      window.open(url, "_blank");
    } catch (error) {
      return await error;
    }
  }

  async downloadFileByUrl(url) {
    try {
      const httpOptions = {
        responseType: "blob" as "json",
      };
      const res = await this.http
        .get(url, {
          headers: new Headers({
            responseType: "blob" as "json",
          }),
        })
        .toPromise()
        .catch((err) => {
          const error = err.error;
          return error;
        });
      return await res;
    } catch (error) {
      return await error;
    }
  }

  async saveOkRows(id, formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/uploadStonesIfNoErrors?id=" +
          id,
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getUploadHistory(type, pageNumber, noOfRecords, search = "") {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/csv_upload_history/findByType?type=" +
          type +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          "&search=" +
          search,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getInwardUploadHistory(type, jsonInward, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/csv_upload_history/findByType?type=" +
          type +
          "," +
          jsonInward +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async stockUploadHistory(
    type,
    jsonInward,
    deleteStock,
    recutUpload,
    boxUpload,
    pageNumber,
    noOfRecords
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/csv_upload_history/findByType?type=" +
          type +
          "," +
          jsonInward +
          "," +
          deleteStock +
          "," +
          recutUpload +
          "," +
          boxUpload +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllDepartments() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/department/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllLocations(fromLocation) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/company/findAll?fromLocation=" +
          fromLocation,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllUsersByCompany(appName, companyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/user/findByAppNameAndCompanyId?appName=" +
          appName +
          "&companyId=" +
          companyId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getDefaultSetting(userId, actionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/user_action_default/findByUserIdAndActionIdUsingDTO?userId=" +
          userId +
          "&actionId=" +
          actionId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async transferStone(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/transferStone",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPendingStoneInward(
    voucherType,
    pageNumber,
    noOfRecords,
    voucherBookId
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/pendingToReceive?voucherType=" +
          voucherType +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          "&voucherBookId =" +
          voucherBookId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllByVoucherId(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findPendingToReceiveByVoucherId?voucherId=" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findPurchaseInStockByVoucherId(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findPurchaseInStockByVoucherId?voucherId=" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async receivePendingStoneConfirmation(jsonData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/stone/receive", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllAssignAssortment(pageNumber, noOfRecords) {
    // get all Assign Assortment
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/assignedAssortment?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPendingAssortment(pageNumber, noOfRecords) {
    // get all Pending Assortment
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/pendingForAssignment?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllAssorters(location) {
    // get all Assorter list
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/user/findByLocationAndRole?appName=lattice&roleName=assorter&location=" +
          location,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async findByVoucherIdPendingAssignment(voucherId) {
    // get all Pending (stone) Assignment
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findPendingForAssignmentByVoucherId?voucherId=" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async assignStoneAndAssorter(jsonData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/assortment_vote/createVoters",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async findAssignedAssortmentByVoucherId(voucherId) {
    // get all stone & assorter (stone) Assignment
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findAssignedAssortmentByVoucherId?voucherId=" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async deleteAssignment(voucherId) {
    // delete assortment_vote
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/assortment_vote/deleteAssignmentByVoucher/" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateVotersAndStone(voucherId, jsonData) {
    // update Voters & stone (Assorters)
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl +
          "master-service/api/assortment_vote/updateVoters/" +
          voucherId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllDepartment(appName) {
    // get All department detail
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/department/findAllByAppName?appName=" +
          appName,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllActive(appName) {
    // get All department detail
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/statusMaster/findAllActive", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllTypeFor(name) {
    // get all type assortment detail
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/size_master/findAllByType?type=" +
          name,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllDataFor(name) {
    // get all Shape/Color/Clarity detail
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone-parameter-value/findByStoneParameterName?stoneParameterName=" +
          name,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addBox(jsonData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/box/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async findByDepartmentId(deptId, pageNumber, noOfRecords) {
    // get all findByDepartmentId detail
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/box/findByDepartmentOrRoleId?departmentId=" +
          deptId +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async deleteBox(boxId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "master-service/api/box/delete/" + boxId, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async findBoxById(boxId) {
    // get all Clarity detail
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/box/findById?id=" + boxId, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async updateBox(id, jsonData) {
    // update Box
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "master-service/api/box/update/" + id, jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async pendingStones(pageNumber, noOfRecords) {
    // get pendingStones
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findInComming?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async findByVoucherIdOfPendingAssortmentStocks(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findPendingToReceiveByVoucherId?voucherId=" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async receivedStones(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/receivedForBoxing?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async findByVoucherIdOfReceivedAssortmentStocks(
    voucherId,
    boxType,
    boxLevel
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findByVoucherIdOfReceivedForBoxing?voucherId=" +
          voucherId +
          "&boxType=" +
          boxType +
          "&boxLevel=" +
          boxLevel,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getBoxedStone(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/boxed?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addStoneToBox(jsonData) {
    // get addRFId
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/box/addStones", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateInventory(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/box/addRfidStones", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByVoucherIdOfBoxedStocks(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findByVoucherIdOfBoxedStocks?voucherId=" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findOutGoing(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findOutGoing?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findDelivered(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findDelivered?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async stoneIssue(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/stone/issue", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findUserActionDefaultsByUserIdAndActionIdUsingDTO(userId, actionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/user_action_default/findByUserIdAndActionId?userId=" +
          userId +
          "&actionId=" +
          actionId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addUserDefault(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "user-service/api/user_action_default/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllCompany() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "user-service/api/company/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async updateUserDefault(jsonData) {
    // update updateUserDefault
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .put(
        this.baseUrl + "user-service/api/user_action_default/update",
        jsonData,
        { headers: new Headers(this.headers) }
      )
      .toPromise();
    return await response;
  }

  async calculateAssortmentResult(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/receiveWithCalculateAssortmentResult",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async deleteUserDefault(userId, actionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "user-service/api/user_action_default/deleteByUserIdAndActionId/" +
          userId +
          "/" +
          actionId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async stoneRequest(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/stone/request", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async pickStoneFromBox(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/stone/pickup", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async scanByBoard(boardBaseUrl) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(boardBaseUrl + "SmartBoard/Scan", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStoneIdByRfid(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/findStoneDetailsInSpecifiedParamName",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findPendingToReceiveCount(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/findPendingToReceiveCount",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getDefaultStoneParamMaster(fromLocation, type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/company_sspd_settings/findAllByCompany?companyName=" +
          fromLocation +
          "&transType=" +
          type,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addSsPdSetting(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/company_sspd_settings/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteSsPdSetting(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/company_sspd_settings/delete/" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addToCart(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/cart/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addOrUpdateWithPrice(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/cart/addOrUdateWithPrice",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getCartStone(transType, type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findAllInCart?transType=" +
          transType +
          "&type=" +
          type,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async deleteStoneInCart(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "master-service/api/cart/delete/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findOutGoingStockByVoucherId(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findOutGoingStockByVoucherId?voucherId=" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findDeliveredStockByVoucherId(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findDeliveredStockByVoucherId?voucherId=" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllTypesForVoucher() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/voucher_book/getBookType", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addVoucherBook(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/voucher_book/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getVoucherBookDetailbyId(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/voucher_book/findById?id=" + id,
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllVouchersForSelectedType(selectedType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher_book/findByType?type=" +
          selectedType,
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteVoucher(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "master-service/api/voucher_book/delete/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateVoucher(id, jsonData) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .put(
        this.baseUrl + "master-service/api/voucher_book/update?id=" + id,
        jsonData,
        {
          headers: new Headers(this.headers),
        }
      )
      .toPromise();
    return await response;
  }

  async findRequestedStone(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findRequested?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateTolerance(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl +
          "master-service/api/company_sspd_settings/updateTolerance/?id=" +
          id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findOutRequest(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findOutRequest?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findRequestedStockByVoucherId(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findRequestedStockByVoucherId?voucherId=" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByVoucherIdOfOutRequestStocks(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findByVoucherIdOfOutRequestStocks?voucherId=" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllBoxType() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/box/findAllBoxType", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllServiceByLab(labId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/certification_service/findByRequestAction?action=Certification&paramValue=" +
          labId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStoneListByRequest(
    action,
    id,
    kamId,
    pageNumber,
    noOfRecords,
    serviceIds
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findRequestedByactionAndValue?action=" +
          action +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          (!!id ? "&value=" + id : "") +
          (!!kamId ? "&requestUserId=" + kamId : "") +
          (!!serviceIds ? "&serviceIds=" + serviceIds : ""),
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getRequestedStoneListByVoucherId(
    voucherId,
    action,
    pageNumber,
    noOfRecords
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findRequestedByactionAndValue?voucherId=" +
          voucherId +
          "&action=" +
          action +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
      1;
    } catch (error) {
      return await error;
    }
  }

  async certificateOrConsignmentRequest(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/TransferRequested",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllRequest(action, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findTransferRequestStones?action=" +
          action +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllRequestVoucherWise(action, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findTransferRequested?requestAction=" +
          action +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllRequestVoucherWiseByPartyMasterId(
    action,
    pageNumber,
    noOfRecords,
    partyMasterId
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findTransferRequested?requestAction=" +
          action +
          "&partyMasterId=" +
          partyMasterId +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findTransferRequestStoneByVoucherId(id, requestAction) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findTransferRequestedByVoucherId?voucherId=" +
          id +
          "&requestAction=" +
          requestAction,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async labStoneReportUpload(jsonData, labId) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/uploadLabCertificationCSV?partyMasterId=" +
          labId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllResultUploadedStone(action, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findTransferRequestUploaded?action=" +
          action +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllCustomers() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/party_master/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllCustomersByType(type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/party_master/findByType?type=" +
          type,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByAppNameAndCompanyId(companyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/user/findByAppNameAndCompanyId?appName=" +
          this.appName +
          "&companyId=" +
          companyId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllAdditionalServices() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/certification_service/all", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllAdditionalServicesByLabId(labId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/certification_service/findByLabId?labId=" +
          labId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  certification_service;

  async getDataForComparison(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/getDefaultAndLabParameters?stoneId=" +
          id +
          "&action=Certification",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async issuePickedUp(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/issuePickedUp",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getReceivedAllStone(action, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findTransferRequestReceived?action=" +
          action +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllInComingStone(action, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findTransferRequestInComing?action=" +
          action +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  // async receivedStoneFromCertificateLab(jsonData){
  //   try{
  //     this.headers =await this.configService.getAPIHeader();
  //     const response = await this.http.post(this.baseUrl + "master-service/api/stone/receiveFromCertification",jsonData,{ headers: new Headers(this.headers)}).toPromise();
  //     return await response;
  //   }catch(error){
  //     return await error;
  //   }
  // }
  async returnStone(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/initiateTansferReceive",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async certificateRecheckRequest(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "/master-service/api/stone/certificateRecheckRequest",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  // ============================
  async getAllCustomerList() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/party_master/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllCustomerBypage(partyName, pageNumber, noOfRecords) {
    try {
      let name = !!partyName ? partyName : "";
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/party_master/findAll?partyName=" +
          encodeURIComponent(name) +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getCustomerDetails(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/party_master/findById/?id=" + id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getCustomerContactList(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/customer-contact/findByCustomerMasterId?customerMasterId=" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getCustomerContactDetails(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/customer-contact/findById/?id=" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getDepartmentWiseKamPersonList(dept) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/user/findByDepartment?departmentName=" +
          dept +
          "",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addCustomer(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/party_master/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addCustomerContact(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/customer-contact/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateCustomer(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/party_master/update/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateCustomerContact(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/customer-contact/update/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  // ==========================
  async getAllboxesByDepartmentID(departmentID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/box/findByDepartmentId?departmentId=" +
          departmentID,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findStonesByBoxID(boxID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/stone/findByBoxId?boxId=" + boxID,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findStonesByboxIdWithAvailableStock(boxID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findByBoxId?boxId=" +
          boxID +
          "&availableStock=true",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByLED(boardBaseUrl, obj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(boardBaseUrl + "Smartboard/LedOn", obj, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findBoxByUser() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/box/findByUser", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllTags() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/tags/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStoneTags(stoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stoneTag/findByStoneId?stoneId=" +
          stoneId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addStoneTag(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/stoneTag/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async scanBox(obj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/box/scan", obj, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByDepartmentIdAndUser(departmentID, isAvailable) {
    console.log(isAvailable);
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/box/findByDepartmentIdAndUser?departmentId=" +
          departmentID +
          " &availableStock=" +
          isAvailable,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByDepartmentOrRoleIdByRole(roleId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/box/findByDepartmentOrRoleId?roleId=" +
          roleId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteboxScanError(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "master-service/api/boxScanError/delete/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllErrorByDepartmentAndUser(departmentID, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/boxScanError/findAllByDepartmentAndUser?departmentId=" +
          departmentID +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async scanWithLightOn(boardBaseUrl, Rfids) {
    try {
      let xhttp = new XMLHttpRequest();
      await xhttp.open("POST", boardBaseUrl + "Smartboard/LedOn", true);
      xhttp.onreadystatechange = function (onEvent) {
        if (xhttp.readyState === 4) {
          if (xhttp.status === 200) {
            return JSON.parse(xhttp.responseText);
          } else if (xhttp.status === 500) {
            return xhttp.responseText;
          } else if (xhttp.status === 0) {
            return xhttp.responseText;
          } else {
            return xhttp.statusText;
          }
        }
      };
      await xhttp.setRequestHeader("Content-type", "application/json");
      await xhttp.send(JSON.stringify(Rfids));
    } catch (error) {
      return await error;
    }
  }

  async scanLedOff(boardBaseUrl, rfidList) {
    try {
      var xhttp = new XMLHttpRequest();
      await xhttp.open("POST", boardBaseUrl + "Smartboard/LedOff", true);
      xhttp.onreadystatechange = function (onEvent) {
        if (xhttp.readyState === 4) {
          if (xhttp.status === 200) {
            return JSON.parse(xhttp.responseText);
          } else if (xhttp.status === 500) {
            return xhttp.responseText;
          } else if (xhttp.status === 0) {
            return xhttp.responseText;
          } else {
            return xhttp.statusText;
          }
        }
      };
      await xhttp.setRequestHeader("Content-type", "application/json");
      await xhttp.send(JSON.stringify(rfidList));
    } catch (error) {
      return await error;
    }
  }

  async deleteTag(stoneTagId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl + "master-service/api/stoneTag/delete/" + stoneTagId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addSavedSearches(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/savedSearches/save",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateSavedSearches(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/savedSearches/update/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteSearch(searchId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl + "master-service/api/savedSearches/delete/" + searchId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllSearch(actionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/savedSearches/findAll?actionId=" +
          actionId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllTransType() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/StoneTransType/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findResultOfPDAssort(stoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/assortment_vote_details/findByStoneId?stoneId=" +
          stoneId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addBoxCriteria(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/box_criteria/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getBoxCriteriaById(boxId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/box_criteria/findById?id=" + boxId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateCriteriaById(criteriaId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/box_criteria/update/" + criteriaId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteCriteria(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "master-service/api/box_criteria/delete/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllMixStones() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findAllMixStonesForTransferToSales",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadPriceList(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/price_list/uploadCsvData",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async AddPriceNameMaster(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/price_list_name_master/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPriceName() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/price_list_name_master/findAll",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      // console.log(response)
      // debugger
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async acceptAssortmentWithoutAssortAndPD(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/assortment_vote/acceptAssortmentWithoutAssortAndPD",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addPolicy(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/price_policy/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deletePolicy(policyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl + "master-service/api/price_policy/delete/" + policyId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPolicy() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/price_policy/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateOrder(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/price_policy_block/updateOrder",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async markStoneAsSold(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/markStoneAsSold",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllConfirmedVoucherWithStatus(
    action,
    status,
    pageNumber,
    noOfRecords
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findRequestConfirmedWithStatus?requestAction=" +
          action +
          "&status=" +
          status +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllConfirmedVoucherWithStatusUsingPartyMasterId(
    action,
    status,
    partyMasterId,
    pageNumber,
    noOfRecords
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findRequestConfirmedWithStatus?requestAction=" +
          action +
          "&status=" +
          status +
          "&partyMasterId=" +
          partyMasterId +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllConfirmedStonesWithStatus(voucherId, status) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findTransferRequestWithStatus?voucherId=" +
          voucherId +
          "&status=" +
          status,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addBlocks(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/price_policy_block/add",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addRuleToBlock(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/block_rule/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllBlockByPolicyId(policyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/price_policy_block/findByPricePolicyMasterId?pricePolicyMasterId=" +
          policyId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getBlockDataById(blockId, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/block_rule/findByBlockId?blockId=" +
          blockId +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async applyPolicy(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/price_policy/applyPolicy",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async AssortmentIssue(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/getStoneDetailsForAssortmentIssue",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getInwardStatus(voucherType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/getStoneCountByVoucherType?voucherType=" +
          voucherType,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async disableAssorter(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/assortment_vote/disableAssorters",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllTemplate(isDefault) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/template/findAll?isDefault=" +
          isDefault,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchAllTemplateByUserId(userId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "fetchAllTemplateByUserId/" + userId, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findTemplateById(templateId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/template/findTemplateById?id=" +
          templateId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveTemplate(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/template/save", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteTemplate(templateId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl + "master-service/api/template/delete/" + templateId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateTemplate(jsonData, templateId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/template/update?id=" + templateId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async savePolicy(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/price_policy/savePolicy",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findStonesListOfNotAppliedPolicy(policyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findStonesListOfNotAppliedPolicy?id=" +
          policyId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addProgram(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/program_master/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllProgram(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/program_master/findAll?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getProgramById(programId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/program_master/findById?id=" +
          programId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateProgram(programId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl +
          "master-service/api/program_master/update/" +
          programId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteProgram(programId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/program_master/delete/" +
          programId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async exportTemplate(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/template/exportFileUsingTemplate",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getRuleDataById(ruleId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/block_rule/findById?id=" + ruleId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPendingStonesProgramWise(voucherType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/program_master/pendingToReceive?voucherType=" +
          voucherType +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findPendingToReceiveByProgramId(programId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findPendingToReceiveByProgramId?programId=" +
          programId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteBlock(blockId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/price_policy_block/delete/" +
          blockId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateActionDefault(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "user-service/api/action_default/update/",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStonePriceAndRulesFronStoneId(stoneId, priceListNameId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/getStonePriceAndRulesFromPriceList?stoneId=" +
          stoneId +
          "&priceListNameId=" +
          priceListNameId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updatePolicy(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/price_policy/update/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateBlocks(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/price_policy_block/update/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateBlockWithFile(id, name, formData, findToReplace) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/price_policy_block/updateData/" +
          id +
          "?blockName=" +
          name +
          "&findToReplace=" +
          findToReplace,
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getDataFromKG(date, csvHistoryId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/getDataFromKG?queryDate=" +
          date +
          "&csvUploadHistoryId=" +
          csvHistoryId,
          {},
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updatePriceNameMaster(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl +
          "master-service/api/price_list_name_master/update/" +
          id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllAppliedPolicies(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/price_policy/findAllAppliedPolicies?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllParametersForSingleStone(type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/company_sspd_settings/findAllByCompany?transType=" +
          type,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStockStone() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/stone/findInStockByUserId", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getCountForIncomingAndOutReuqest() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findIncomingAndOutRequestCount",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async savePdResult(jsonData, companyIds) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/assortment_vote/updateStonePDParameters?companies=" +
          companyIds,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStatus() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/statusMaster/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addMasterStatus(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/statusMaster/save", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateMasterStatus(jsonData, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/statusMaster/update/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteMasterStatus(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "master-service/api/statusMaster/delete/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getSizeMaster() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/sizeMaster/getSizeMaster", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addLabParameterMapping(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/lab_parameter_mapping/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllParameterMapping(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/lab_parameter_mapping/findAll?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getParamMappingById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/lab_parameter_mapping/findById?id=" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateLabParameterMapping(jsonData, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl +
          "master-service/api/lab_parameter_mapping/update/" +
          id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async searchLabParameterMapping(search, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/lab_parameter_mapping/search?searchValue=" +
          encodeURIComponent(search) +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteLabParameterMapping(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/lab_parameter_mapping/delete/" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllParameterOfStones() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/lab_parameter_mapping/getAllStoneParameterName",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getlabExpenseUPloadHistory(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/lab_expenses/findAll?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStoneDetailById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone_details/findByStoneId?stoneId=" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async downloadCertificationUploadedFile(fileId) {
    try {
      let url =
        this.baseUrl +
        "master-service/api/stone/certificationCsvDownload?fileName=" +
        fileId;
      window.open(url, "_blank");
    } catch (error) {
      return await error;
    }
  }

  async uploadForCetificateAction(labId, formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/uploadCsvForCerificationRecheck?labId=" +
          labId,
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getPricingSizeMaster(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/size_master/findAll?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addPricingSizeMaster(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/size_master/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updatePricingSizeMaster(jsonData, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/size_master/update/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deletePricingSizeMaster(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "master-service/api/size_master/delete/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async searchPricingSizeMaster(search, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/size_master/search?searchValue=" +
          encodeURIComponent(search) +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addFixedPolicy(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/price_policy_block/addFixedPolicy",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addLabExpenses(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/lab_expenses/uploadCsv",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async manualSalesUpload(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/manualSalesUpload",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async labExpenseCsvDownload(fileId) {
    try {
      let url =
        this.baseUrl +
        "master-service/api/lab_expenses/labExpenseCsvDownload?fileName=" +
        fileId;
      window.open(url, "_blank");
    } catch (error) {
      return await error;
    }
  }
  async manualSalesCsvDownload(fileId) {
    try {
      const fileType = "Update PD Parameters";
      let url =
        this.baseUrl +
        "master-service/api/stone/csvDownload?fileName=" +
        fileId +
        "&fileType=" +
        fileType;
      window.open(url, "_blank");
    } catch (error) {
      return await error;
    }
  }
  async deletelabExpense(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "master-service/api/lab_expenses/delete/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addToCartByFilterCriteria(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/cart/addToCartWithSearchCriteria",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getConversionRateList() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/conversation_rate/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addConversionRate(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/conversation_rate/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateConversionRate(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/conversation_rate/update/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteConversionRate(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl + "master-service/api/conversation_rate/delete/" + id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getCurrencyList() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/currency_master/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteCurrency(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl + "master-service/api/currency_master/delete/" + id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addCurrency(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/currency_master/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateCurrency(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/currency_master/update/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getCurrencyDetailById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/currency_master/findById?id=" + id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getLabConversionRate() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/conversation_rate/findAllLabRate",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getLabConversionRateById(labId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/conversation_rate/findByLabId?labId=" +
          labId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllTypeForPricing() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/size_master/findAllType", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findParametersForMixSizeMaster() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone-parameter-value/findColorClarityShapeIds",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadSizeMasterCSV(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/size_master/uploadSizeMasterCSV",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllAvailableStockBox() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/box/findAllMixBoxesForTransferToSales",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllAvailableCSSPInStockBox(boxId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findAllMixStonesOfBoxForTransferToSales?boxId=" +
          boxId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async downloadCertificationComparisonFile(fileId) {
    try {
      let url =
        this.baseUrl +
        "master-service/lab_certifiaction_result/fileDownload?csvUploadHistoryId=" +
        fileId;
      window.open(url, "_blank");
    } catch (error) {
      return await error;
    }
  }

  async findIncomingByVoucherTypeAndTransType(
    pageNumber,
    noOfRecords,
    voucherType,
    transType
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findIncomingByVoucherTypeAndTransType?voucherType=" +
          voucherType +
          "&transType=" +
          transType +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findReceivedByVoucherTypeAndTransType(
    pageNumber,
    noOfRecords,
    voucherType,
    transType
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findReceivedByVoucherTypeAndTransType?voucherType=" +
          voucherType +
          "&transType=" +
          transType +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStone(transType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findInCommingByTransTypeForSales?transType=" +
          transType +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getIncomingStoneToSales(transType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findInCommingByTransTypeForSales?transType=" +
          transType +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getReceivedStoneToSales(transType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findRecievedStockByTransTypeForSales?transType=" +
          transType +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllCompanies() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "user-service/api/company/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findDetailById(companyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "user-service/api/company/findById?id=" + companyId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateCompany(companyId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "user-service/api/company/update/" + companyId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addCompany(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "user-service/api/company/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findPendingForAssignmentByTransType(
    transType,
    pageNumber,
    noOfRecords
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findPendingForAssignmentByTransType?transType=" +
          transType +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findSvcRequested(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findSvcRequested?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          "&requestPlatform=SVC",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAssignedStockByTransType(transType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findAssignedStockByTransType?transType=" +
          transType +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async assignRfidToStone(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/updateStoneRFID",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findOutRequestStocksByTransType(str) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + str, { headers: new Headers(this.headers) })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getCompanyDetailByName(companyName) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/company/findByName?name=" +
          companyName,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAvailableStock() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/box/findAvailableStock", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findCriteriaByBoxId(boxId, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/box_criteria/findCriteriaByBoxId?id=" +
          boxId +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getTransferRequestedByVoucherIdForDC(voucherId, requestAction) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findAllTransferRequestedByVoucherId?voucherId=" +
          voucherId +
          "&requestAction=" +
          requestAction,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async markStoneAsConfirmed(jsonData) {
    jsonData["updateParametersForSold"] = false;
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/markStoneAsConfirmed",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async markMultipleStoneAsConfirmed(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/markMultipleStoneAsConfirmed",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  // async findStoneByCssp(jsonData) {
  //   try {
  //     this.headers = await this.configService.getAPIHeader();
  //     const response = await this.http.post(this.baseUrl + "master-service/api/stone/findStoneByCssp", jsonData, { headers: new Headers(this.headers) }).toPromise();
  //     return await response;
  //   }
  //   catch (error) {
  //     return await error;
  //   }
  // }

  async findCsspOrCreateIfNotExist(jsonData, isCreate) {
    try {
      let qryParam = "createIfNotExists=" + isCreate;
      if (
        jsonData.hasOwnProperty("pageNumber") &&
        jsonData.hasOwnProperty("noOfRecords")
      ) {
        qryParam +=
          "&pageNumber=" +
          jsonData.pageNumber +
          "&noOfRecords=" +
          jsonData.noOfRecords;
      }
      console.log(qryParam);
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/findStoneByCssp?" + qryParam,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveAuditCssp(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/stone/auditCssp", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteAllFromCart() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "master-service/api/cart/deleteAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateCartData(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.baseUrl + "master-service/api/cart/update/" + id, jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPartyMasterType() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/party_master/findAllType", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getLatestRateByCurrencyName(currencyName) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/currency_master/getLatestRateByCurrencyName?currencyName=" +
          currencyName,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getLatestLabRateByCurrencyName(labId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/currency_master/getLatestRateByLabId?partyMasterId=" +
          labId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async stoneInterCsspTransfer(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/stoneInterCsspTransfer",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async downloadPackingList(queryParam) {
    try {
      let url = this.baseUrl + queryParam;
      window.open(url, "_blank");
    } catch (error) {
      return await error;
    }
  }

  async uploadLabActualExpenseCSV(labId, formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/uploadLabActualExpenseCSV?partyMasterId=" +
          labId,
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async transferToBox(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/box/transferCssp", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addRapaprtMapping(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/price_list_parameter_mapping/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async RAPAPORTMappingUpdate(jsonData, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl +
          "master-service/api/price_list_parameter_mapping/update/" +
          id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async searchRapoParameterMapping(search, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/price_list_parameter_mapping/search?param=" +
          encodeURIComponent(search) +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllRapoParameterMapping(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/price_list_parameter_mapping/findAll?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteRapoParameterMapping(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/price_list_parameter_mapping/delete/" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findUnconfirmPurchaseData() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/getAllUnconfirmPurchaseStone",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async confirmPurchaseData(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/confirmPurchaseStone",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async purchaseToSingle(formData, transMode = "") {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/purchaseToSingle?transMode=" +
          transMode,
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findShapeByType(type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/size_master/findDistinctShapeByType?type=" +
          type,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findSizeByShape(shpaeId, type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/size_master/findByTypeShape?shape=" +
          shpaeId +
          "&type=" +
          type,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAvailalbeShapeByType(type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/size_master/findDistinctAvailableShapeByType?type=" +
          type,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAvailableSizeByShape(shpaeId, type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/size_master/findDistinctAvailableSizeByType?shape=" +
          shpaeId +
          "&type=" +
          type,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async autoBoxing(departmentName, boxLevel) {
    try {
      this.headers = await this.configService.getAPIHeader();
      console.log(this.headers);
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/box/autoBoxing?department=" +
          departmentName +
          "&boxLevel=" +
          boxLevel,
          {},
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getBoxByType(departmentId, boxType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/box/findByDepartmentIdAndType?departmentId=" +
          departmentId +
          "&boxType=" +
          boxType,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findBoxByUserAndType(boxType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/box/findByUserAndType?boxType=" +
          boxType,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findGroupNames() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/company_sspd_settings/findGroupNames",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findGroupValue() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone-parameter-value/findGroupNames",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findOutGoingPickedVouchers(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findOutGoingPickedVouchers?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findOutGoingPickedStockByVoucherId(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findOutGoingPickedStockByVoucherId?voucherId=" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllMixBoxesForTransferToSalesByType(type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/box/findAllMixBoxesForTransferToSalesByType?type=" +
          type,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addTermMaster(jsonData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/termMaster/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateTermMaster(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/termMaster/update/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllTerm() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/termMaster/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteTermMaster(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "master-service/api/termMaster/delete/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteRequest(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl + "master-service/api/stone/deleteRequest/" + voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async checkValidationForRequest(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/validationForRequest",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateStoneParameterFromKG(stoneId, companyId = null) {
    let url =
      "master-service/api/stone/updateStoneParameterFromKG?stoneId=" + stoneId;
    if (companyId) {
      url = url + "&companyId=" + companyId;
    }
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + url, { headers: new Headers(this.headers) })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addPolicyChain(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/policy_chain/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updatePolicyChain(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/policy_chain/update/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deletePolicyChain(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "master-service/api/policy_chain/delete/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPolicyChain() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/policy_chain/findAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getDetailByPolicyChainId(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/policy_chain/findById?id=" + id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getPdParamsByStoneId(stoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/assortment_vote/getPdParamsByStoneId?stoneId=" +
          stoneId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findStoneIdForGivenLocation1(stoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.nodeServerUrl +
          "stone/findStoneIdForGivenLocation?stoneId=" +
          stoneId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findStoneIdForGivenLocation(stoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findStoneIdForGivenLocation?stoneName=" +
          stoneId +
          "&location=8,11",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async applyPolicyChain(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/price_policy/applyPolicyChain",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllConvertedSingleStone(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/getAllSingleStoneConvertFromMix?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPurchaseConfirmedStone() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/getAllPurchaseConfirmedStone",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async convertPurchaseToBulk(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/purchaseToBulk",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async returnAndReceive(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/returnAndReceive",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllSingleStoneIssuedForMixing() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/stone/getAllSingleStoneIssued",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async convertSingleToMix(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/convertSingleToMix",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async changeStoneId(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/updateStoneId",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async purchaseInward(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/purchaseInward?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByListOfPartyType(jsonData, groupType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/party_master/findByListOfType" +
          (!!groupType ? "?groupType=" + groupType : ""),
          jsonData,
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByListOfPartyTypePagination(
    jsonData,
    groupType,
    pageNumber,
    noOfRecords,
    searchValue,
    purchaseCsvMapping = false
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/party_master/findByListOfType?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          (!!groupType ? "&groupType=" + groupType : "") +
          (!!searchValue
            ? "&searchValue=" + encodeURIComponent(searchValue)
            : "") +
          (!!purchaseCsvMapping
            ? "&purchaseCsvMapping=" + purchaseCsvMapping
            : ""),
          jsonData,
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }


  // async getMixinghistoryDataByType(type) {
  //   try {
  //     this.headers = await this.configService.getAPIHeader();
  //     const response = await this.http.get(this.baseUrl + "master-service/api/stone/getAllConvertedStone?transType=" + type, { headers: new Headers(this.headers) }).toPromise();
  //     return await response;
  //   } catch (error) {
  //     return await error;
  //   }
  // }

  async getMixinghistoryDataByType(
    fromTransType,
    toTransType,
    pageNumber,
    noOfRecords
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/getAllTransferStone?fromTransType=" +
          fromTransType +
          "&toTransType=" +
          toTransType +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateBarcode(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/updateBarcode",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async downloadActualLabExpenseFile(fileId, fileType) {
    try {
      let url =
        this.baseUrl +
        "master-service/api/stone/csvDownload?fileName=" +
        fileId +
        "&fileType=" +
        fileType;
      window.open(url, "_blank");
    } catch (error) {
      return await error;
    }
  }

  async getAllCsvDownloadHistoryByType(type, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/csv_download_history/findAllHistoryByType?type=" +
          type +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findPartyByAction(action, isApprove, status) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/party_master/findByTransferAction?action=" +
          action +
          (isApprove != undefined && isApprove != null
            ? "&approved=" + isApprove
            : "") +
          (!!status ? "&status=" + status : ""),
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllPartyByAction(action, isApprove, status) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/party_master/findAllByTransferAction?action=" +
          action +
          (isApprove != undefined && isApprove != null
            ? "&approved=" + isApprove
            : "") +
          (!!status ? "&status=" + status : ""),
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByRequestAction(action) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/party_master/findByRequestAction?action=" +
          action,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllRequestedAction() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/action/findAllRequestedAction",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getBoxesByBoxType(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/box/findBoxByType", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findPolicyCriteriaParameters() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone-parameter-value/findPolicyCriteriaParameters",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllLabs() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/certification_service/all", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addLabService(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/certification_service/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateLabService(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl +
          "master-service/api/certification_service/update/" +
          id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteLabService(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/certification_service/delete/" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async transferStoneByAction(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/transferStoneUsingAction",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByTransTypeAndOrder(transType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/termMaster/findByTransTypeAndOrder?transType=" +
          transType,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findUserByRequestAction(action) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/user/findByRequestAction?action=" +
          action,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async searchCsspTransferMapping(search, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/cssp_transfer_mapping/search?searchValue=" +
          encodeURIComponent(search) +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getCsspTransferMapping(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/cssp_transfer_mapping/findAll?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteCsspTransferMapping(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/cssp_transfer_mapping/delete/" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addCsspTransferMapping(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/cssp_transfer_mapping/add",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateCsspTransferMapping(jsonData, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl +
          "master-service/api/cssp_transfer_mapping/update/" +
          id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadCsspTransferMapping(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/cssp_transfer_mapping/uploadCSV",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updatePDParameters(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/updatePDParameters",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteSoldVoucher(voucherId, status) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/voucher/deleteProcessedWithStatus?voucherId=" +
          voucherId +
          "&status=" +
          status,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async deletePurchaseOrMixToSingleStone(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/stone/deletePurchaseOrMixToSingle/" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllTransferedCsspByType(type, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findAllTransferedCsspByType?type=" +
          type +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteTransferedCsspStone(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/stone/revertTransferedCsspStone/" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadInterCsspTransfer(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/fileUploadForInterCsspTransfer",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getInterCsspTransferHistory(type, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/csv_upload_history/findByType?type=" +
          type +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addSingleMixParamMapping(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/single_mix_parameter_mapping/uploadCsv",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getApprovalList(jsonData, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/voucher/getApprovalList?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getRequestApprovalList(jsonData, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/voucher/getRequestedApprovalListOfWebsite?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllTransferAction(isApprove) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/action/findAllTransferAction?approved=" +
          isApprove,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async approveVoucher(jsondata) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/voucher/approveVoucher",
          jsondata,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteTransferRequest(id) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/voucher/deleteTransferedVoucher/" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveAddress(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/party_master/addAddress",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateAddress(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl + "master-service/api/party_master/updateAddress/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAddressesByPartyId(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/party_master/getAddressList?partyMasterId=" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateRequestFromWebsite(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/voucher/saveUpdateVoucher",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveTags(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/tags/save", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findPendingPartyApproval(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/party_master/findAllPendingPartyApproval?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findIncompleteKYCusers() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/user/findAllNonUploadedKYCSfUsers",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findTagsBySearch(pageNumber, noOfRecords, searchParam) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/tags/findAllWithPagination?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          (!!searchParam ? "&searchParam=" + searchParam : ""),
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addWebsiteParty(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/party_master/addWebsitePartyDetails",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async sendDocumentReminderEmail(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "party/document-reminder-email", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateWebsiteParty(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/party_master/updateWebsitePartyDetails",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async rejectPartyApproval(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/party_master/rejectParty",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async updateKYCparty(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/party_master/updateTalkbriteKycParty",
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async approvePartyApproval(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/party_master/approveParty",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByPendingPartyApprovalId(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/party_master/findByPendingPartyApprovalId?id=" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllWebsiteOffer(pageNumber, noOfRecords, kamUserId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/negotiation_master/findAllOffer?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          (!!kamUserId ? "&kamUserId=" + kamUserId : ""),
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findWebsiteOfferById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/negotiation_master/findAllByNegotiationMasterId?id=" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async rejectPartialOffer(offerId, offerStoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/negotiation_master/partialRejectOffer?id=" +
          offerId +
          "&offerStoneId=" +
          offerStoneId,
          {},
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async rejectOffer(offerId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/negotiation_master/rejectOffer?id=" +
          offerId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateOffer(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/negotiation_master/updateOffer",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async approveOffer(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/negotiation_master/approveOffer",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getReport(url, json, method) {
    console.log(url, json, method);
    try {
      this.headers = await this.configService.getAPIHeader();
      if (method == "GET") {
        let qry = Object.keys(json)
          .map((res) => {
            if (!!json[res]) {
              return `${res}=${json[res]}`;
            } else {
              if (json[res] == false) {
                return `${res}=${json[res]}`;
              }
            }
          })
          .join("&");
        const response = await this.http
          .get(this.baseUrl + url + "?" + qry, {
            headers: new Headers(this.headers),
          })
          .toPromise();
        return await response;
      } else {
        const response = await this.http
          .post(this.baseUrl + url, json, {
            headers: new Headers(this.headers),
          })
          .toPromise();
        return await response;
      }
    } catch (error) {
      return await error;
    }
  }

  async getAllStonesInwardData(voucherType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/report/stonesInwardData?voucherType=" +
          voucherType,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async manageStoneTags(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stoneTag/manageStoneTags ",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async boxStockData(departmentId, transType, priceListNameMasterId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      let searchParams =
        "transType=" + transType + "&departmentId=" + departmentId;
      if (!!priceListNameMasterId) {
        searchParams += "&priceListNameMasterId=" + priceListNameMasterId;
      }
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/report/boxStockData?" +
          searchParams,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async boxStockDataDetails(boxId, transType, priceListNameMasterId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      let searchParams = "boxId=" + boxId + "&transType=" + transType;
      if (!!priceListNameMasterId) {
        searchParams += "&priceListNameMasterId=" + priceListNameMasterId;
      }
      const response = await this.http
        .get(
          this.baseUrl + "master-service/api/report/stockByBox?" + searchParams,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async departmentWiseStockData() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/report/deptWiseStockDetails", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async recutRequestFromKG(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/recutRequestFromKG",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllparameterFromValue(value) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone_details/getAllParameters?stoneValue=" +
          value,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateStoneParameter(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone_details/updateParameterLab",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStoneTrackingDetail(
    stoneValue,
    priceListMasterId,
    finalPriceListMasterId
  ) {
    try {
      let qry = !!priceListMasterId
        ? "&priceListMasterId=" + priceListMasterId
        : "";
      qry += !!finalPriceListMasterId
        ? "&finalPriceListMasterId=" + finalPriceListMasterId
        : "";
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone_movement/allTransactions?stoneValue=" +
          stoneValue +
          qry,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getTransactionParameter(stoneId, transactionDate) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone_details/getTransactionParameter?stoneId=" +
          stoneId +
          "&transactionDate=" +
          transactionDate,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllWithMaster() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone-parameter-value/findAllWithMaster",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllWithMasterForSingleStone() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone-parameter-value/findAllWithMasterForSingleStone",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async calculatePrice(priceListNameId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/price_policy/calculatePrice?priceListNameId=" +
          priceListNameId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async calculateSSPricing(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/price_policy/calculatePriceES?startChainId=1&basePdChainId=2&mixPriceChainId=6&type=SALES",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async calculateMIXPricing(jsonData, mixChainId, typeName) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/price_policy/calculatePriceMIX?mixChainId=" +
          mixChainId +
          "&type=" +
          typeName,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async createRefCustomer(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/ref_person/save", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findRefCustomerPagination(pageNumber, noOfRecords, searchValue) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/ref_person/findAll" +
          "?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          (!!searchValue ? "&searchValue=" + searchValue : ""),
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchRecutReturnStonesByVoucherLabelAndDate(
    jangadNo,
    jangadDate,
    pageNumber,
    noOfRecords
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/fetchRecutReturnStonesByVoucherLabelAndDate?jangadNo=" +
          jangadNo +
          "&jangadDate=" +
          jangadDate +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          {},
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchRecutInwardData() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/stone/findFetchedRecutReturn", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async recutReturnFromKG(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/recutReturnFromKG",
          jsonObj,
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchRecutReturnStonesByUser(jangadNo, jangadDate) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/fetchRecutReturnStonesByUser?jangadNo=" +
          jangadNo +
          "&jangadDate=" +
          jangadDate,
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async resetUserActionDefaultByUserIdAndActionid(userId, actionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "user-service/api/user_action_default/deleteByUserIdAndActionId/" +
          userId +
          "/" +
          actionId,
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findSkipAssortmentCriteriaPagination(
    pageNumber,
    noOfRecords,
    searchValue
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/skip_assortment_master/findAll" +
          "?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          (!!searchValue ? "&searchValue=" + searchValue : ""),
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteSkipCriteria(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/skip_assortment_master/delete/" +
          id,
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addSkipAssortmentCriteria(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/skip_assortment_master/add",
          jsonData,
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateSkipAssortmentCriteria(jsonData, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl +
          "master-service/api/skip_assortment_master/update/" +
          id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async emailToCustomer(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/emailTalkbriteLink",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllSalesPerson(location, roleName) {
    // get all Assorter list
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "user-service/api/user/findByLocationAndRole?appName=lattice&roleName=" +
          roleName +
          "&location=" +
          location,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getWebsiteAllUsers() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/user/findAllTalkbriteUsers", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getRapnetDiscountPrice(stoneName: any) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          `master-service/api/stone-price-history/rapnetJourneyReport?stoneName=${stoneName}&date=2023-02-14&transType=1&priceListNameMasterId=11`,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getLabExpenseReport(payload: any) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + `master-service/api/report/labExpenseReport`,
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllVouchersForSelectedTypes(selectedType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher_book/findByTypes?types=" +
          selectedType,
          {
            headers: new Headers(this.headers),
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getPurchaseInwardDataFromKG(date) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/KGPurchaseInward?queryDate=" +
          date,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getKGPartyDetails(date) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/party_master/KGPartyDetail?queryDate=" +
          date,
          {},
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllSold(status, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findAllSold?status=" +
          status +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateDataFromKG(queryDate) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/updateDataFromKG?queryDate=" +
          queryDate,
          {},
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getFtpData() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "FTP_Master/getAllFtps", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addFtp(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "FTP_Master/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateFtp(jsonData, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.nodeServerUrl + "FTP_Master/update/" + id, jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteFtp(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.nodeServerUrl + "FTP_Master/deleteFtp/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getLabPacketSeriesData() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.nodeServerUrl + "labPacketSeriesMaster/getAllLabPacketSeries",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addLabPacketSeries(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "labPacketSeriesMaster/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateLabPacketSeries(jsonData, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.nodeServerUrl + "labPacketSeriesMaster/update/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteLabPacketSeries(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.nodeServerUrl +
          "labPacketSeriesMaster/deleteLabPacketSeries/" +
          id,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async recutReturn(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/stone/recutReturn", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadSSToMix(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/fileUploadForSingleMix",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchInvocie(invoiceNo) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findAllTransferRequestedByBillNo?billNo=" +
          invoiceNo,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllTransferRequestedByPartyAndStones(jsonobj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/findAllTransferRequestedByPartyAndStones",
          jsonobj,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getErrorAndStatusOfStoneIds(jsonObj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/getErrorAndStatusOfStoneIds",
          jsonObj,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStatusAndPartyWiseVoucher(jsonobj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl + "voucher/getStatusAndPartyWiseVoucher",
          jsonobj,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getOutwardData(date) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/getDataFromKG?queryDate=" +
          date,
          {},
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadNewCutFile(formData) {
    console.log(formData);
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl + "polishgroupmaster/uploadNewCutCriteriaRules",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async resetPolicyChain(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl +
          "master-service/api/policy_chain/updateStatusToError/" +
          id,
          {},
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async approveRejectProgram(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/approveProgram",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getUserActionLogs(actionName) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/userActionLogs/getAllUserActionLogs?actionName=" +
          actionName,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByBarcode(barCode) {
    this.headers = await this.configService.getAPIHeader();
    try {
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findByBarcode?barcode=" +
          barCode,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error.json();
    }
  }

  async deleteConfirmPurchaseStone(listOfIds) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl + "master-service/api/stone/deleteConfirmPurchaseStone",
          { headers: new Headers(this.headers), body: listOfIds }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async pricingFunList() {
    this.headers = await this.configService.getAPIHeader();
    try {
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/template/findAllPricingModificationFunctionNames",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error.json();
    }
  }

  async savePPrice(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/confirmPPrice",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveSecurityCheckResult(formData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "securityCheckLogs/add", formData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findLogsForSecurityCheck(stoneId, pageNumber, noOfRecords) {
    this.headers = await this.configService.getAPIHeader();
    try {
      const response = await this.http
        .get(
          this.nodeServerUrl +
          "securityCheckLogs/find?stoneId=" +
          stoneId +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error.json();
    }
  }

  async compareLabReport(requestObj) {
    this.headers = await this.configService.getAPIHeader();
    console.log(requestObj);
    try {
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/report/compareLabReport",
          requestObj,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error.json();
    }
  }

  async uploadPurchaseFile(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl + "purchaseConsolidation/partyFileUpload",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadPurchaseFileForMultyParty(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl +
          "purchaseConsolidation/uploadFileForMultipleParty",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async internalLabResultUpload(jsonData, labId) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/uploadLabCertificationCSVForInternalLabs?partyMasterId=" +
          labId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadManualPricing(jsonData, labId) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/uploadPriceForIntransitStones",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async labResultUploadForOtherOfficeStone(jsonData, labId) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/uploadLabCertificationCSVBotswana?labId=" +
          labId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async stoneWisePricingHistory(requestObj) {
    this.headers = await this.configService.getAPIHeader();
    console.log(requestObj);
    try {
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone-price-history/findByIdAndDate",
          requestObj,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      console.log("error", error);
      return await error.json();
    }
  }

  async uploadCsvForAssortment(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/assortment_vote_details/assortment-bulk-upload",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async blackListStones(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl + "purchaseConsolidation/manageStoneForBlackList",
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllEnquiries(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.nodeServerUrl +
          `enquiry_Master/getAllEnquiries?noOfRecords=${noOfRecords}&offset=${pageNumber}`,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findEnquiries(searchTxt, enquiryNo, pageNumber, noOfRecords) {
    let qry1 = `enquiry_Master/findEnquiries?`;

    if (enquiryNo != "") {
      qry1 = qry1 + `enquiryId=${enquiryNo}&`;
    }

    if (searchTxt != "") {
      qry1 = qry1 + `searchQry=${searchTxt}&`;
    }

    qry1 = qry1 + `noOfRecords=${noOfRecords}&offset=${pageNumber}`;

    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + qry1, { headers: new Headers(this.headers) })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async craeteEnquiry(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "enquiry_Master/createEnquiry", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateAvailableForSaleStatus(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/updateAvailableForSaleFlagForStones",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();

      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateEnquiry(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.nodeServerUrl + "enquiry_stone/updateStatus", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteEnquiry(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.nodeServerUrl + "enquiry_Master/deleteEnquiryById/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getEnquiryWithDetail(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "enquiry_Master/getEnquiryWithDetail/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateCvdStatus(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.nodeServerUrl + "enquiry_stone/updateCvdStatus", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateStoneDetails(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.nodeServerUrl + "enquiry_stone/updateStoneDetails", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async purchaseconsolidationstones(formData, noofrecords, offset) {
    let fg = "";

    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "enquiry_stone/fetchIncomingStones?" +
          noofrecords +
          "&offset=0",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchStonesDetail(obj, noOfRecords, pageNumber) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl +
          `enquiry_stone/fetchStonesDetail?noOfRecords=${noOfRecords}&offset=${pageNumber}`,
          obj,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchIncomingStones(payload, noOfRecords, pageNumber) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl +
          `enquiry_stone/fetchIncomingStones?noOfRecords=${noOfRecords}&offset=${pageNumber}`,
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchStonesStatusWise(obj, noOfRecords, pageNumber) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl +
          `enquiry_stone/fetchStonesStatusWise?noOfRecords=${noOfRecords}&offset=${pageNumber}`,
          obj,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchStonesForCvdCheck(obj, noOfRecords, pageNumber) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl +
          `enquiry_stone/fetchStonesForCvdCheck?noOfRecords=${noOfRecords}&offset=${pageNumber}`,
          obj,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async receiveStones(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.nodeServerUrl + `enquiry_stone/receiveStones`, payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchStonesForUpdateBankRate(payload, noOfRecords, pageNumber) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl +
          `enquiry_stone/fetchStonesForUpdateDetail?noOfRecords=${noOfRecords}&offset=${pageNumber}`,
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadPurchaseStoneParameters(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl + "enquiry_stone/updateParametersByLab",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchPurchasehParamters(payload, noOfRecords, pageNumber) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl +
          `enquiry_stone/fetchStonesDetailWithParameters?noOfRecords=${noOfRecords}&offset=${pageNumber}`,
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateStonesInSearchPage(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/updateStonesInSearchPage",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async savePdPurchseStoneResult(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "enquiry_stone_pd/addStonePd", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getPdPurchaseParamsByStoneId(stoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.nodeServerUrl +
          "enquiry_stone_pd/getPdParamsByStoneId?stoneId=" +
          stoneId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllBudgetList(noOfRecords, pageNumber) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.nodeServerUrl +
          `design_master/getAllBudgetList?noOfRecords=${noOfRecords}&offset=${pageNumber}`,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addDesignCriteria(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl + "design_criteria/addDesignCriteria",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteBudgetEnquiry(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.nodeServerUrl + "design_master/deleteBudgetId/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getDesignCriteriaStoneById(designBudgetMasterId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.nodeServerUrl +
          "design_criteria/getDesignCriteriaStoneById?designBudgetMasterId=" +
          designBudgetMasterId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async sendMailToPurchaseParty(payload) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "enquiry_Master/sendMailToParty", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateDesignCriteria(designMasterId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.nodeServerUrl +
          "design_criteria/updateDesignCriteria/" +
          designMasterId,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async buyingPurpose() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "stoneBuyingPurpose/getAllBuyingPurpose", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getPricingLogsChainFromUI(taskType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.nodeServerUrl +
          `pricing-logs/getAllPricingLogsFromChain?taskType=${taskType}&noOfRecords=${noOfRecords}&offset=${pageNumber}`,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getInProgressPricingChain() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + `pricing-logs/policyChainInternalStatus`, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async stoneGeneratePrefix() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "stoneGeneratePrefix/fetch/2", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async fetchStonebyprefix() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "stoneGeneratePrefix/fetchAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async deleteStonebyprefix(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.nodeServerUrl + "stoneGeneratePrefix/deleteById/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async addStonePrefix(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "stoneGeneratePrefix/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async updateStonedata(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.nodeServerUrl + "stoneGeneratePrefix/UpdateStonePrefix/" + id,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async fetchStonebybuyingpurposeList() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "stoneBuyingPurpose/fetchAll", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async addStoneBuyingPurpose(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "stoneBuyingPurpose/add", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async updateStoneBuyingPurpose(prefix, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.nodeServerUrl +
          "stoneBuyingPurpose/UpdateStoneBuyingPurpose/" +
          prefix,
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async deleteStonebyBudgetPurpose(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.nodeServerUrl + "stoneBuyingPurpose/deleteById/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async generateBuyingPurposeStoneId(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.nodeServerUrl + "stoneGeneratePrefix/generateStoneId",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async uploadRapnetPurchaseFile(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "enquiryMaster/uploadRapaport", formData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getRapnetHistory() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "enquiryMaster/fetchStatusForNotification", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async donwloadFileByVoucherId(voucherId, requestFor) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/getRequestExcel?voucherId=" +
          voucherId +
          "&requestFor=" +
          requestFor,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getPrice(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone-price-history/getPrices",
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveIdsInWorker(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone-price-history/saveIdsInWorker",
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllConfig() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.trybeServerUrl + "processRuleConfig/findall", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addProcessRuleCOnfig(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(this.trybeServerUrl + "processRuleConfig/add", formData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateProcessRuleCOnfig(formData, id) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .put(
          this.trybeServerUrl + `processRuleConfig/updateById/${id}`,
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteDapartmentConfig(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.trybeServerUrl + "processRuleConfig/deleteById/" + id, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getHistoryData(ftpId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.nodeServerUrl +
          "FTP_Master/getNotficationOnBasisOfFtpId/" +
          ftpId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async disableNotificationMaster(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/notification_master/disableNotificationMaster",
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async enableNotificationMaster(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/notification_master/enableNotificationMaster",
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async enableFTPNotification(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "FTP_Master/restartServiceByIds", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async enableInstantFTPNotification(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "FTP_Master/instantExportFtps", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllOtherOfficeStockUploadList(
    pageNumber,
    noOfRecords,
    taskType,
    companyId
  ) {
    // get all Assign Assortment
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/notification_master/findAllByTaskType?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          "&taskType=" +
          taskType +
          (!!companyId ? "&companyId=" + companyId : ""),
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async changeStateOfOtherOfficeStock(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/notification_master/updateStatusByIds",
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async requestForSVC(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/requestFromTalkbrite",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findRequestedSVCStone(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/findSvcRequested?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          "&requestPlatform=SVC",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findRequestedSVCStoneView(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone/findSvcRequested?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          "&requestPlatform=SVC",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteRequestedStones(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/deleteRequestForStone",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadCsvForReAssortment(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/assortment_vote_details/reassortment",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchLastTrayofStoneIds(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "stone/getLastTrayByIds", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStoneWisePDHistory(stoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/util/getStoneWise?stoneName=" +
          stoneId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllBlockedForSale() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/customer-contact/findAllBlockedForSale",
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getBlockForSaleDetails(
    action,
    partyMasterIds,
    requestPlatForms,
    stoneList,
    searchType
  ) {
    let query = "";
    if (!!partyMasterIds) {
      query += `&partyMasterIds=${partyMasterIds}`;
    }
    if (!!requestPlatForms) {
      query += `&requestPlatForms=${requestPlatForms}`;
    }
    if (!!searchType) {
      query += `&searchType=${searchType}`;
    }
    let payload: any = {};
    if (!!stoneList && stoneList.length > 0) {
      payload.stoneIds = stoneList;
    }
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/findAllTransferRequestStones?action=" +
          action +
          query,
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async stoneUnHoldWithRequest(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/requestBlockForSale",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateMFG(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/script/generateMFG",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadCsvForConsignmentAutoSold(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/uploadCsvForConsignmentAutoSold",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllOtherOfficeStockDeleteRequestList(
    pageNumber,
    noOfRecords,
    taskType,
    companyId
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.nodeServerUrl +
          "stone/fetch/delete-stock-request?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          "&taskType=" +
          taskType +
          (!!companyId ? "&companyId=" + companyId : ""),
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllProcessOfOtherOfficeStock(pageNumber, noOfRecords, searchValue) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.nodeServerUrl +
          "stone/fetch/other-office-stock-process?pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords +
          (!!searchValue ? "&searchValue=" + searchValue : ""),
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async manualSyncForOtherLocation(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/manualSyncForOtherLocation",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async generateAshokaTextFile(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "convert-csv-to-text", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async generatePlandAndSalesStonesTextFile(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "planorsales-stones/text", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async downloadFile() {
    const file = await this.http
      .get(`${this.nodeServerUrl}ashoka.text`, {
        responseType: ResponseContentType.Blob,
      })
      .toPromise();
    return file;
  }
  async downloadPlanStoneFile() {
    const file = await this.http
      .get(`${this.nodeServerUrl}planStones.text`, {
        responseType: ResponseContentType.Blob,
      })
      .toPromise();
    return file;
  }
  async downloadSalesStoneFile() {
    const file = await this.http
      .get(`${this.nodeServerUrl}salesStones.text`, {
        responseType: ResponseContentType.Blob,
      })
      .toPromise();
    return file;
  }
  async uploadPDCatByFile(formData, companyIds) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone/updateStoneDetails?companies=" +
          companyIds,
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadProgramExcelFile(file) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/program_master/programMasterFile",
          file,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getSalesPerson(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.talkBriteUrl + "users/getKgAllSalesPersons", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getDistributionCenter() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.talkBriteUrl + "shop_customers/37/getDistributors", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async signupWithoutOtp(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.talkBriteUrl + "users/signupWithoutOtp", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async bgmFileUpload(payload, type) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      console.log(payload, type);
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/rapnetMapping/uploadMappingFile",
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async nonbgmFileUpload(payload, type) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      console.log(payload, type);
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/rapnetMapping/uploadMappingFile",
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async logisticsPdf(date: any, selectedVouchers, transporterName, voucherBookIds) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          `master-service/api/templatehtml/getLogisticApprovalLetter?voucherDate=${date}&jgrNo=${selectedVouchers}&transporterName=${transporterName}&voucherbookIds=${voucherBookIds}`,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return response;
    } catch (error) {
      return await error;
    }
  }
  async exportPositionReport(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/stone/generatePositionReport",
          jsonData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllCsvDownloadHistoryByTypePositionReport(
    type,
    pageNumber,
    noOfRecords
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/csv_download_history/findAllHistoryByType?type=" +
          type +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllCsvDownloadHistoryByTypePositionReportMappingFile(
    type,
    pageNumber,
    noOfRecords
  ) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/csv_upload_history/findByType?type=" +
          type +
          "&pageNumber=" +
          pageNumber +
          "&noOfRecords=" +
          noOfRecords,
          { headers: new Headers(this.headers) }
        )
        .toPromise();

      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPricingBlocks() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "fetchAllPricingBlocks", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadBlockOnES(blockId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/price_policy/updateSSCalculatorByBlockId?blockId=" +
          blockId,
          {},
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateInvoiceDetail(payload, voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(this.nodeServerUrl + "voucher/update/" + voucherId, payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getProgramRejectionType() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "program-rejection/type", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getProgramRejectionHistory(stoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "program-rejection/history/" + stoneId, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findPartyNameByProgramName(progName) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "findPartyNameByProgramName/" + progName, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveProgramRejectionHistory(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "program-rejection/history", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addESLogstoEP(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.nodeServerUrl + "eplogs/add-price-es-logs", jsonData, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async fetchESUploadHistoryFromEP() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.nodeServerUrl + "eplogs/fetch-price-es-update", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveQueuedAction(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/queuedAction/save", payload, {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getQueuedAction(val, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/queuedStones/getAll?noOfRecords=" +
          noOfRecords +
          "&pageNumber=" +
          pageNumber +
          (!!val ? "&stoneName=" + val : ""),
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getVouchersByDate(voucherDate, voucherbookIds) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher/getJangadList?voucherDate=" +
          voucherDate +
          "&voucherbookIds=" +
          voucherbookIds,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteQueuedAction(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .put(
          this.baseUrl +
          "master-service/api/queuedStones/updateStatus?id=" +
          id +
          "&status=Cancelled",
          {},
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getBuyerByCriteria(payload, size) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/sold_stone/getBuyerByCriteria?size=" +
          size,
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async offerDataUpload(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl + "master-service/api/offer/uploadDataByCsv",
          formData,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getRFIDHistory(stoneId, rfid) {
    // get all type assortment detail
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/stone-param-logs/getStoneParamLogByRfidAndStoneId?" +
          (!!stoneId ? "stoneId=" + stoneId : "") +
          (!!rfid ? "rfid=" + rfid : ""),
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addClientSelectReject(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/selection_rejection/addClientSelectionRejection",
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async trackStonePricing(payload) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(
          this.baseUrl +
          "master-service/api/stone-price-history/findStonePrice",
          payload,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteUnconfirmedPurchasedStones(voucherId: any) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(
          this.baseUrl +
          "master-service/api/stone/deleteUnConfirmPurchaseStone/" +
          voucherId,
          { headers: new Headers(this.headers) }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getFullStackReport() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/stone/getFullStockReport", {
          headers: new Headers(this.headers),
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
}
