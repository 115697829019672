import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import {
  ModalController,
  LoadingController,
  Events,
  AlertController
} from "@ionic/angular";
import { ReceiveincomingstonesComponent } from '../receiveincomingstones/receiveincomingstones.component';
import * as moment from "moment";
import { IonicSelectableComponent } from 'ionic-selectable';
import { AccordionModule } from 'ngx-accordion';


@Component({
  selector: 'app-purchase-update-invoice-no-and-seq-no',
  templateUrl: './purchase-update-invoice-no-and-seq-no.component.html',
  styleUrls: ['./purchase-update-invoice-no-and-seq-no.component.scss']
})
export class PurchaseUpdateInvoiceNoAndSeqNoComponent implements OnInit {
  columns = [];
  dataSource: any = [];
  public partyName: any;
  public pageSize: any = 10;
  pageNumber = 0;
  noOfRecords = 15;
  stonesList = [];
  searchTxt = '';
  selected = [];
  public refreshGrid: Boolean = false;
  public Stones = ``;
  //public Stones = ''; 
  constructor(
    public configService: ConfigService,
    public authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public event: Events
  ) { }

  ngOnInit() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "enquiryno", prop: "enquiryId" },
    {
      name: 'Stone Id', prop: 'stoneName'
    },
    //{ name: "Details", prop: "" },
    { name: 'Clarity', prop: 'ClarityCode' },
    { name: 'Color', prop: 'ColorCode' },
    { name: 'Shape', prop: 'ShapeCode' },
    { name: 'Size', prop: 'cts' },
    { name: 'Invoice No', prop: 'accusoftInvoiceNo' },
    { name: 'Sub SerialNo', prop: 'accusoftsubSerialNo' },
    { name: 'Terms', prop: 'terms' },
    { name: 'Bank Rate', prop: 'BankRate' },
    { name: 'Disc', prop: 'PurchasePartyDisc' },
    { name: 'Price', prop: 'PurchasePartyPrice' },
    { name: 'Amount', prop: 'PurchasePartyAmt' }

    ]

    // this.dataSource=[{srno:1,party:'xyz',totalstones:20},{srno:2,party:'xyz',totalstones:20}];
    this.getStones()

    this.event.subscribe('Update Invoice No In Purchased Stones', (item) => {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "enquiryno", prop: "enquiryId" },
      {
        name: 'Stone Id', prop: 'stoneName'
      },
      //{ name: "Details", prop: "" },
      { name: 'Clarity', prop: 'ClarityCode' },
      { name: 'Color', prop: 'ColorCode' },
      { name: 'Shape', prop: 'ShapeCode' },
      { name: 'Size', prop: 'cts' },
      { name: 'Terms', prop: 'terms' },
      { name: 'Bank Rate', prop: 'BankRate' },
      { name: 'Disc', prop: 'PurchasePartyDisc' },
      { name: 'Price', prop: 'PurchasePartyPrice' },
      { name: 'Amount', prop: 'PurchasePartyAmt' }
      ]
      this.getStones()
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Update Invoice No In Purchased Stones');
  }


  async getStones() {

    let listOfStones = []
    if (this.Stones != '') {
      let tmp = this.Stones.split("\n");
      tmp = tmp.map(x => x.trim())

      if (tmp.length > 0) {
        listOfStones = tmp
      }
    }
    await this.configService.showLoader()
    let obj = {
      'listOfStones': listOfStones,
      "searchQry": this.searchTxt
    }
    let res: any;
    res = await this.httpApiService.fetchStonesForUpdateBankRate(obj, this.pageSize, this.pageNumber);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data) {
        this.stonesList = data.data
      }
    }
  }

  async OnSelectRows(event) {
    this.selected = [...this.selected, event.selectedRowsData]
    // let newArr = []
    // event.selectedRowsData.forEach((item, index) => {
    //   if (newArr.findIndex(i => i.stoneName == item.stoneName) === -1) {
    //     newArr.push(item)
    //   }

    // });
    // this.selected = newArr
  }

  async termsOrBankRateUpdated(event) {
    console.log(event)
  }

  async update() {
    let listOfStones = []
    await this.stonesList.forEach(n => {
      let stones = n.stoneDeatils
      stones.forEach(x => {
        if (x.accusoftInvoiceNo || x.accusoftsubSerialNo) {
          let obj = {
            "stoneName": x.stoneName,
            "partyMasterId": x.partyMasterId,
            "enquiryId": x.enquiryId,
            "accusoftInvoiceNo": x.accusoftInvoiceNo,
            "accusoftsubSerialNo": x.accusoftsubSerialNo
          }
          listOfStones.push(obj)
        }
      })
    })
    let obj = {
      'listOfStones': listOfStones
    }
    let res: any;
    console.log(obj);

    await this.configService.showLoader()
    res = await this.httpApiService.updateStoneDetails(obj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast(
        "success",
        data.message
      );

    }
    // this.stonesList = [];
    this.getStones()
    this.Stones = ''
  }
}
