import { Component, Input, OnInit } from '@angular/core';
import { ProcessActionComponent } from '../../processAction';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-website-reports',
  templateUrl: './website-reports.component.html',
  styleUrls: ['./website-reports.component.scss']
})
export class WebsiteReportsComponent implements ProcessActionComponent {
  @Input() data: any;

  websiteDashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://mixpanel.com/p/6URZURqppqMYfAC2QBeCNg');
  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

}
