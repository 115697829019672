import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './public/login/login.module#LoginPageModule' },
  { path: 'register', loadChildren: './public/register/register.module#RegisterPageModule' },
  {
    path: 'app',
    canActivate: [AuthGuardService],
    loadChildren: './app/app-routing.module#AppRoutingModule'
  },
  { path: 'changePassword', loadChildren: './public/change-password/change-password.module#ChangePasswordPageModule' },
  { path: 'forgotPassword', loadChildren: './public/forgot-password/forgot-password.module#ForgotPasswordPageModule' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
