export default {
  "reportNo": "R-8",
  "url": "master-service/api/report/csspStockMovement",
  "method": "GET",
  "size": 3,
  "fields": [
    {
      "title": " Department Name ",
      "input": "select",
      "key": "departmentId",
      "placeholder": "Select Department Type",
      "property": "departmentId",
      "keyName": "id",
      "name": "name",
      "required": true,
      "singleSelection": true,
      "function": "getAllDepartment",
      "default": [],
      "size": 3
    },
    {
      title: ' Date ',
      input: 'dateTime',
      key: 'date',
      placeholder: 'Select Date',
      property: 'date',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    }
  ],
  "columns": [
    {
      "name": "Stone Name", prop: "stoneId", "displayFormat": "Total",
      "showInGroupFooter": true
    },
    { "name": "Op Stock", prop: "opStock", "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum", },
    { "name": "S To B", prop: "stob", "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum", },
    { "name": "Inward Stock", prop: "inwrdStock", "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum", },
    { "name": "To Stock Transfer", prop: "toStockTransfer", "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum", },
    { "name": "From CSSP Transfer", prop: "fromCsspTransfer", "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum", },
    { "name": "To CSSP Transfer", prop: "toCsspTransfer", "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum", },
    { "name": "Outward Stock", prop: "outwrdStock", "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum", },
    { "name": "B To S", prop: "btos", "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum", },
    {
      "name": "Closing Stock", dynamic: ["opStock", "+", "inwrdStock", "-", "outwrdStock", "-", "btos", "+", "stob", "-", "fromCsspTransfer", "+", "toCsspTransfer"], "displayFormat": "{0}",
      "summaryType": "sum", "showInGroupFooter": true
    }
  ]
};
