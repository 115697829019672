import { Component, Input } from "@angular/core";

import { ModalController, LoadingController, Events } from "@ionic/angular";

import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { PotentialBuyersService } from "src/app/services/potential-buyers/potential-buyers.service";

@Component({
  selector: "app-potential-buyers",
  templateUrl: "./potential-buyers.component.html",
  styleUrls: ["./potential-buyers.component.scss"],
})
export class PotentialBuyersComponent implements ProcessActionComponent {
  @Input() data: any;

  constructor(
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    private potentialBuyerService: PotentialBuyersService
  ) {}

  fetchStoneData(stoneName: string) {
    if (stoneName !== "+ Add Stone") {
      this.potentialBuyerService.fetchStoneDetail();
    }
  }

  fetchPotentialBuyerData() {
    this.potentialBuyerService.fetchPotentialBuyerDetails();
  }
}
