import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController, Events } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-manage-voucher-book",
  templateUrl: "./manage-voucher-book.page.html",
  styleUrls: ["./manage-voucher-book.page.scss"]
})
export class ManageVoucherBookPage implements OnInit {
  public allTypes: any;
  public type: any;
  public selectedType: any;
  public shortCode: any;
  public bookName: any;
  public title: any;
  public selectedItem: any;
  public alltypesWithKey: any;
  public allTypeidList = [];
  public internalStatuslist = []
  public externalStatuslist = [];
  public listOfStatuses = [];
  public internalStatusId: any;
  public allowedStatus: any;
  public externalStatusId: any;
  public availableForSale: Boolean = false;
  public id: any;
  public internalIdList = []
  public externalIdList = []
  public statusIdList = []
  constructor(
    public navParams: NavParams,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public event: Events
  ) {
    this.allTypes = this.navParams.get("typeArray");

    this.alltypesWithKey = [];
    for (let i = 0; i < this.allTypes.length; i++) {
      this.alltypesWithKey.push({ id: i + 1, voucherType: this.allTypes[i] });
    }
    this.type = this.navParams.get("type");
    this.selectedType = this.navParams.get("selectedType");
    this.selectedItem = this.navParams.get("selectedItem");

    if (this.type == "1") {
      this.allTypeidList = [];
      this.title = "Update Voucher Book";
      if (this.selectedItem) {
        this.alltypesWithKey.forEach(element => {
          if (element.voucherType == this.selectedItem.type) {
            let data = { id: element.id, voucherType: element.voucherType };
            this.allTypeidList.push(data);
          }
        });
        this.bookName = this.selectedItem.bookName;
        this.shortCode = this.selectedItem.shortCode;
        this.selectedType = this.selectedItem.type;
        this.id = this.selectedItem.id
      }
    } else {
      this.title = "Add Voucher Book";
    }
  }

  async  ngOnInit() {
    this.getAllStoneStatus();
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "voucherType") {
      let id = idList[0];
      this.alltypesWithKey.forEach(element => {
        if (element.id == id) {
          this.selectedType = element.voucherType;
        }
      });
      console.log("this.selectedType", this.selectedType);
    } else if (property == "internalStatus") {
      if (idList.length > 0) {
        this.internalStatusId = idList[0];
      } else {
        this.internalStatusId = null;
      }
    } else if (property == "externalStatus") {
      if (idList.length > 0) {
        this.externalStatusId = idList[0];
      } else {
        this.externalStatusId = null;
      }
    } else if (property == "allowedStatus") {
      if (idList.length > 0) {
        this.allowedStatus = null;
        this.allowedStatus = idList.join();
        console.log(this.allowedStatus)
      } else {
        this.allowedStatus = null;
      }
    }
  }

  async getVoucherBookDetailbyId() {
    let res: any;
    res = await this.httpApiService.getVoucherBookDetailbyId(this.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.bookName = dataValue.bookName;
      this.availableForSale = dataValue.availableForSale;
      this.shortCode = dataValue.shortCode;
      if (dataValue.internalStatusId) {
        this.internalIdList = []
        this.internalIdList.push({ id: dataValue.internalStatusId, name: dataValue.internalStatusName })
      }
      if (dataValue.externalStatusId) {
        this.externalIdList = []
        this.externalIdList.push({ id: dataValue.externalStatusId, name: dataValue.externalStatusName })
      }
      if (dataValue.allowedStatusList) {
        this.statusIdList = []
        dataValue.allowedStatusList.forEach(item => {
          this.statusIdList.push({ id: item.id, name: item.name })
          let data = [];
          data.push(item.id)
          this.allowedStatus = data.join();
        })
      }
      console.log(this.statusIdList, this.allowedStatus)
      this.internalStatusId = dataValue.internalStatusId
      this.externalStatusId = dataValue.externalStatusId
      console.log(this.externalIdList, this.internalIdList)
    }
  }

  async getAllStoneStatus() {
    let res: any;
    res = await this.httpApiService.getStatus();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.listOfStatuses = dataValue;
      let internalArray = []
      let externalArray = []
      if (dataValue.length > 0) {
        dataValue.forEach(el => {
          if (el.type == 'Internal' && el.isActive == true) {
            internalArray.push(el);
          } else if (el.type == 'External' && el.isActive == true) {
            externalArray.push(el);
          }
        })
        this.internalStatuslist = internalArray;
        this.externalStatuslist = externalArray;
      }

      console.log(this.externalStatuslist, this.internalStatuslist)
      if (this.type == 1) {
        await this.getVoucherBookDetailbyId();
      }
    }
  }

  async addVoucherBook() {
    let validate = await this.validateControls();
    if (!validate) {
      let addObj = {
        type: this.selectedType,
        shortCode: this.shortCode,
        bookName: this.bookName,
        internalStatusId: this.internalStatusId,
        externalStatusId: this.externalStatusId,
        allowedStatus: this.allowedStatus,
        availableForSale: this.availableForSale
      };
      let res;
      res = await this.httpApiService.addVoucherBook(addObj);
      console.log("res", res);
      let resp = res.json();
      if (resp.error) {
        if (resp.error == "invalid_token") {
          this.authService.logout();
        } else {
          await this.configService.showToast("error", resp.error);
        }
      } else {
        await this.configService.showToast(
          "success",
          "Voucher book created successfully"
        );
        this.modalCtrl.dismiss();
      }
    }
  }

  public closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async updateVoucherBook() {
    let validate = await this.validateControls();
    if (!validate) {
      let updateObj = {
        type: this.selectedType,
        shortCode: this.shortCode,
        bookName: this.bookName,
        internalStatusId: this.internalStatusId,
        externalStatusId: this.externalStatusId,
        allowedStatus: this.allowedStatus,
        availableForSale: this.availableForSale
      };
      console.log(updateObj);
      let id = this.selectedItem.id;
      let res: any;
      res = await this.httpApiService.updateVoucher(id, updateObj);
      let resp = res.json();
      if (resp.error) {
        if (resp.error == "invalid_token") {
          this.authService.logout();
        } else {
          await this.configService.showToast("error", resp.error);
        }
      } else {
        await this.configService.showToast(
          "success",
          "Voucher book updated successfully"
        );
        this.modalCtrl.dismiss(this.title);
      }
    }
  }

  async validateControls() {
    if (this.selectedType && this.bookName && this.shortCode) {
      return false;
    } else {
      await this.configService.showToast("error", "Please enter the details");
      return true;
    }
  }
}
