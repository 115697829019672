import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  NavParams,
  ModalController,
  ToastController,
  Events,
  LoadingController
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "src/app/services/http-api/http-api.service";
import * as moment from "moment";

@Component({
  selector: 'app-csv-download-history',
  templateUrl: './csv-download-history.page.html',
  styleUrls: ['./csv-download-history.page.scss'],
})
export class CsvDownloadHistoryPage implements OnInit {
  @ViewChild("dataTable") public dataTable: any;
  @Input() searchTitle:any;
 

  public data = [];
  public baseUrl: any;
  public type = "Export Template";
  public typePositionReport = "Position Report"
  public typePositionReportMappingFile = "Mapping File"
  public columns = []
  public page = "downloadHistory"
  public pageNumber = 0;
  public noOfRecords = 10;
  public count =0;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public toastCtrl: ToastController,
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public event: Events,
    public loadingController: LoadingController
  ) { }

  async ngOnInit() {
    this.columns = [
      { name: "Template Name", prop: "templateName" },
      { name: "Status", prop: "status" },
      { name: "File Export Initiated At", prop: "createdDate" },
      { name: "File Export Finished At", prop: "endDate" },
      { name: "Download", prop: "" }
    ];
    await this.getBaseUrl();
    this.pageNumber = 0;
    this.noOfRecords = 10;
    if(this.searchTitle === 'positionReport'){
      await this.getAllCsvDownloadHistoryPositionReport(this.pageNumber, this.noOfRecords);
    } else if(this.searchTitle === 'positionReportMappingFile'){
      await this.getAllCsvDownloadHistoryPositionReportMappingFile(this.pageNumber, this.noOfRecords);
    }else{
      await this.getAllCsvDownloadHistory(this.pageNumber, this.noOfRecords);
    }
  }
  
  async getBaseUrl() {
    this.baseUrl = await this.configService.getBaseUrl();
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  download(value) {
    console.log(value)
    window.open(this.baseUrl + value.path, '')
  }

  async getAllCsvDownloadHistory(pageNumber, noOfRecords) {
    let res: any;
    res = await this.httpApiService.getAllCsvDownloadHistoryByType(this.type, pageNumber, noOfRecords);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.data = data.data.content;
      this.count = data.data.totalElements;
      console.log("csv download history ", this.data);
    }
  }

  async setPageReceived(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getAllCsvDownloadHistory(currentPage.offset, this.noOfRecords);
  }

  async getAllCsvDownloadHistoryPositionReport(pageNumber, noOfRecords) {
    let res: any;
    res = await this.httpApiService.getAllCsvDownloadHistoryByTypePositionReport(this.typePositionReport, pageNumber, noOfRecords);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.data = data.data.content;
      this.count = data.data.totalElements;
    }
  }
  async getAllCsvDownloadHistoryPositionReportMappingFile(pageNumber, noOfRecords) {
    let res: any;
    res = await this.httpApiService.getAllCsvDownloadHistoryByTypePositionReportMappingFile(this.typePositionReportMappingFile, pageNumber, noOfRecords);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.data = data.data.content;
      this.count = data.data.totalElements;
    }
  }


}
