export default {
  reportNo: 'R-25',
  url: "master-service/api/stone/salesReportSummary",
  method: "POST",
  size: 3,
  fields: [

    {
      title: ' Trans Type ',
      input: 'select',
      key: 'transType',
      placeholder: 'Select Trans Type',
      property: 'transType',
      keyName: 'name',
      name: 'name',
      required: true,
      singleSelection: true,
      function: 'getAllTransType',
      default: [],
      size: 3
    },
    {
      title: ' Report Type ',
      input: 'select',
      key: 'type',
      placeholder: 'Select Report Type',
      property: 'type',
      keyName: 'id',
      required: true,
      name: 'name',
      singleSelection: true,
      itemList: [{
        id: 'Summary',
        name: 'summary'
      }, {
        id: 'Details',
        name: 'details'
      }],
      default: [],
      size: 3
    },
    {
      title: ' From Date ',
      input: 'dateTime',
      key: 'fromDate',
      placeholder: 'Select From Date',
      property: 'fromDate',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
    {
      title: ' To Date ',
      input: 'dateTime',
      key: 'toDate',
      placeholder: 'Select To Date',
      property: 'toDate',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
  ],
  columns: [
    { name: "Invoice Date", prop: "voucherDate" },
    { name: "Acc Invoice Date", prop: "invoiceDate" },
    { name: "Acc Invoice No", prop: "voucherLabel" },
    { name: "Voucher Id", prop: "voucherId", showInGroupFooter: true, "displayFormat": " ", },
    { name: "VoucherNo", prop: "voucherNo", showInGroupFooter: true, "displayFormat": " ", },
    { name: "Party Name", prop: "partyName", showInGroupFooter: true, "displayFormat": " ", },

    { name: "Stone Id", prop: "stoneId", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Shape", prop: "shape", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Color", prop: "color", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Clarity", prop: "clarity", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Description", prop: ["cut", "polish", "symmetry", "fluorescence"], join: ' ', ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Lab", prop: "lab", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    {
      name: "Cts", prop: "cts", ngIf: ['type', 'Details'], required: true,
      summaryType: 'sum',
      showInGroupFooter: true
    },
    {
      name: "Pcs", prop: "pcs", ngIf: ['type', 'Details'], required: true,
      summaryType: 'sum',
      showInGroupFooter: true
    },
    { name: "Memo Disc", prop: "memoDisc", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Memo price", prop: "memoPrice", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Memo Value", prop: "memoValue", ngIf: ['type', 'Details'], required: true, summaryType: 'sum' },
    { name: "Remark", prop: "stoneRemarks", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Broker", prop: "broker", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Terms", prop: "terms", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Less1", prop: "less1", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Less2", prop: "less2", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Total Pcs", prop: "totalPcs", ngIf: ['type', 'Summary'], required: true, summaryType: 'sum', showInGroupFooter: true, sumCol: 'TotalPcs' },
    { name: "Total Cts", prop: "totalCts", ngIf: ['type', 'Summary'], summaryType: 'sum', required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Avg price", prop: "avgPrice", ngIf: ['type', 'Summary'], summaryType: 'avg', required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "NCODE", prop: "nCode", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Mumbai Mfg Amt1", prop: "mumbaiMfgAmt1", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Sale Bulk / Carat", prop: "saleBulkPerCarat", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Party Type", prop: "partyType", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Acc Invoice Month", prop: "accInvoiceMonth", ngIf: ['type', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Total Amount", prop: "totalAmount", ngIf: ['type', 'Summary'], summaryType: 'sum', required: true, showInGroupFooter: true, "displayFormat": " ", },


  ],
};