import { Component, OnInit, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ManageCsspTransferMappingPage } from "../../actions/manage-cssp-transfer-mapping/manage-cssp-transfer-mapping.page"
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: 'app-cssp-transfer-mapping',
  templateUrl: './cssp-transfer-mapping.component.html',
  styleUrls: ['./cssp-transfer-mapping.component.scss']
})
export class CsspTransferMappingComponent implements ProcessActionComponent {
  @Input() data: any;

  public columns = [];
  public page = 'csspTransferMapping';
  public listOfMapping = [];
  public listOfMappingDataSource: any = {};
  public count: Number = 0;
  public items = [];
  public pageNumber = 0;
  public noOfRecords = 100;
  public pageSize = 100;
  public totalPages: Number = 0;
  public manageSeachMapping = '';
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public refreshGrid: Boolean = false;

  constructor(public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public alertCtrl: AlertController,
    public loadingController: LoadingController,
    public event: Events) { }

  ngOnInit() {
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "Assort Shape", prop: "fromShapeCode" },
    { name: "Assort Size", prop: "fromSizeName" },
    { name: "Assort Color", prop: "fromColorCode" },
    { name: "Assort Clarity", prop: "fromClarityCode" },
    { name: "Sale Shape", prop: "toShapeCode" },
    { name: "Sale Size", prop: "toSizeName" },
    { name: "Sale Color", prop: "toColorCode" },
    { name: "Sale Clarity", prop: "toClarityCode" }]
    // if (this.deletePermissionFlag == true) {
    //   this.columns.push({ name: "Action", prop: "" })
    // }
    this.getCsspTransferMapping(this.pageNumber, this.noOfRecords)
    this.event.subscribe('Cssp Transfer Mapping', (item) => {
      this.getCsspTransferMapping(this.pageNumber, this.noOfRecords)
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Cssp Transfer Mapping');
  }

  setItems() {
    this.items = this.listOfMapping;
  }

  async filterItems(ev: any) {
    let val = ev.target.value;
    await this.setItems();
    if (val && val.trim() !== "") {
      let res: any;
      this.pageNumber = 0;
      this.noOfRecords = 100;
      this.listOfMappingDataSource = new CustomStore({
        key: "id",
        load: async (loadOptions: any) => {
          console.log("loadOptions ", loadOptions);
          if (!!loadOptions.take) {
            if ([10, 20, 50, 100,500].indexOf(loadOptions.take) == -1) {
              loadOptions.take = this.pageSize;
            } else {
              this.pageSize = loadOptions.take;
            }
            this.noOfRecords = this.pageSize;
            //loadOptions.take = this.noOfRecords;
            this.pageNumber = loadOptions.skip / loadOptions.take;
            let res: any;
            res = await this.httpApiService.searchCsspTransferMapping(val, this.pageNumber, this.noOfRecords);
            let data = res.json();
            if (res.status == 401) {
              this.authService.logout();
            } else if (res.status == 500) {
              await this.configService.showToast("error", data.error);
            } else if (res.status == 400) {
              await this.configService.showToast("error", data.error);
            } else {
              this.listOfMapping = data.data.content;
              this.setItems();
              return {
                data: Query(data.data.content)
                  .toArray(),
                totalCount: data.data.totalElements,
                groupCount: data.data.totalElements
              };
            }
          } else {
            return {
              data: this.listOfMapping,
              totalCount: this.count,
              groupCount: this.count
            }
          }
        }
      });
      // res = await this.httpApiService.searchCsspTransferMapping(val, this.pageNumber, this.noOfRecords);
      // let data = res.json();
      // if (data.status == 401) {
      //   await this.authService.logout()
      // } else if (data.status == 500) {
      //   await this.configService.showToast('error', data.error)
      // } else if (data.status == 400) {
      //   await this.configService.showToast('error', data.error)
      // } else {
      //   this.listOfMapping = data.data.content;
      //   this.totalPages = data.data.totalPages;
      //   this.count = data.data.totalElements;
      //   this.setItems()
      // }
    } else {
      await this.getCsspTransferMapping(this.pageNumber, this.noOfRecords);
    }
  }

  async getCsspTransferMapping(pageNumber, noOfRecords) {
    // let res: any;
    // res = await this.httpApiService.getCsspTransferMapping(pageNumber, noOfRecords);
    // let data = res.json();
    // if (data.status == 401) {
    //   await this.authService.logout()
    // } else if (data.status == 500) {
    //   await this.configService.showToast('error', data.error)
    // } else if (data.status == 400) {
    //   await this.configService.showToast('error', data.error)
    // } else {
    //   this.listOfMapping = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    //   this.setItems()
    // }
    this.listOfMappingDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100,500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getCsspTransferMapping(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfMapping = data.data.content;
            this.setItems();
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfMapping,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getCsspTransferMapping(currentPage.offset, this.noOfRecords);
  }


  async manageCsspTransfer(item, flag) {
    this.refreshGrid = false;
    if (flag == 'update' && this.updatePermissionFlag == true) {
      let modal = await this.modalCtrl.create({
        component: ManageCsspTransferMappingPage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: { item: item, flag: flag, }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
    if ((flag == 'add' || flag == 'upload') && this.addPermissionFlag == true) {
      let modal = await this.modalCtrl.create({
        component: ManageCsspTransferMappingPage,
        backdropDismiss: false,
        cssClass: "extraLargePage",
        componentProps: { item: item, flag: flag, }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    if (d.data != 'doNotRefresh') {
      this.manageSeachMapping = "";
      await this.getCsspTransferMapping(this.pageNumber, this.noOfRecords);
    }
  }

  //Start Delete Policy 

  async confirmDeleteMapping(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this mapping ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteMapping(item.row.data.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteMapping(id) {
    console.log(id)
    let res: any;
    res = await this.httpApiService.deleteCsspTransferMapping(id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.getCsspTransferMapping(this.pageNumber, this.noOfRecords);
    }
  }

}
