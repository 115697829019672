export default {
  "reportNo": "R-2",
  "url": "master-service/api/report/baseDiscountMatrix",
  "method": "POST",
  "size": 3,
  "fields": [
    {
      title: ' Price List Name ',
      input: 'select',
      key: 'pricePolicyMasterId',
      placeholder: 'Select Price List Name',
      property: 'pricePolicyMasterId',
      keyName: 'id',
      name: 'name',
      required: true,
      singleSelection: true,
      function: 'getAllPolicy',
      default: [],
      size: 3
    },
    {
      title: ' Shape ',
      input: 'select',
      key: 'shapeList',
      placeholder: 'Select Shape',
      property: 'shapeList',
      keyName: 'id',
      name: 'name',
      singleSelection: true,
      function: 'getAllShape',
      default: [],
      size: 3
    },
    {
      title: ' Color ',
      input: 'select',
      key: 'colorList',
      placeholder: 'Select Color',
      property: 'colorList',
      keyName: 'id',
      name: 'name',
      singleSelection: false,
      function: 'getAllColour',
      default: [],
      size: 3
    },
    {
      title: ' Clarity ',
      input: 'select',
      key: 'clarityList',
      placeholder: 'Select Clarity',
      property: 'clarityList',
      keyName: 'id',
      name: 'name',
      singleSelection: false,
      function: 'getAllClarity',
      default: [],
      size: 3
    },
    {
      title: ' From size ',
      input: 'input',
      type: 'text',
      key: 'fromSize',
      placeholder: 'From size',
      property: 'fromSize',
    }, {
      title: ' To size ',
      input: 'input',
      type: 'text',
      key: 'toSize',
      placeholder: 'To size',
      property: 'toSize',
    },
    {
      title: ' Cut ',
      input: 'select',
      key: 'cutList',
      placeholder: 'Select Cut',
      property: 'cutList',
      keyName: 'id',
      name: 'name',
      singleSelection: false,
      function: 'getAllCut',
      default: [],
      size: 3
    },
    {
      title: ' Fluorescence ',
      input: 'select',
      key: 'fluorescenceList',
      placeholder: 'Select Fluorescence',
      property: 'fluorescenceList',
      keyName: 'id',
      name: 'name',
      singleSelection: false,
      function: 'getAllFluorescence',
      default: [],
      size: 3
    }
  ],
  "columns": [
    {
      "name": "Color",
      "prop": "color",
    }, {
      "name": "Shape",
      "prop": "shape",
    }, {
      "name": "Size",
      "prop": "Size",
      "dynamic": ["fromSize", " - ", "toSize"],
    }, {
      "name": "Clarity",
      "prop": "clarity",
    }, {
      "name": "Cut",
      "prop": "cut",
    }, {
      "name": "fluorescence",
      "prop": "fluorescence",
    }, {
      "name": "Cut",
      "prop": "cut",
    }, {
      "name": "Rap",
      "prop": "base",
    }, {
      "name": "Disc",
      "prop": "discPer",
    }, {
      "name": "Price",
      "prop": "rate",
    }
  ]
};
