// Module

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

// import { NgSelectModule } from '@ng-select/ng-select';

import { CountryPlaceholder } from './controllers/country-placeholder';
import { IonIntlTelInputValidators, IonIntlTelInputValidatorDirective } from './controllers/ion-intel-tel-input-directive';
import { IonIntlTelInputComponent } from './ion-intl-tel-input.component';
import { IonIntlTelInputService } from './controllers/ion-intl-tel-input.service';

@NgModule({
  declarations: [
    CountryPlaceholder,
    IonIntlTelInputValidatorDirective,
    IonIntlTelInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    // NgSelectModule
  ],
  exports: [
    IonIntlTelInputComponent,
    IonIntlTelInputValidatorDirective
  ],
  providers: [
    IonIntlTelInputService
  ],
})
export class IonIntlTelInputModule { }