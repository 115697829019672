import { Component, Input, ViewChild } from "@angular/core";
import {
  NavController,
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import CustomStore from 'devextreme/data/custom_store';
import Query from "devextreme/data/query";

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss']
})
export class PurchaseHistoryComponent implements ProcessActionComponent {
  @Input() data;
  public mixingList = []
  public type: any;
  public columns = []
  public mixingIdList = []
  public historyData = []
  public pageNumber = 0;
  public noOfRecords = 20;
  public count = 0;
  public pageSize = 20;
  public page = 'purchaseHistory'
  public items = []
  public historyDataSource: any = [];

  constructor(private authService: AuthenticationService,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public httpApiService: HttpApiService,
    public event: Events,
    public modalCtrl: ModalController,
    public configService: ConfigService) { }

  ngOnInit() {
    this.mixingList = [{ id: 1, name: 'Purchase To Single' }, { id: 2, name: 'Purchase To Mix' }, { id: 3, name: 'Single To Mix' }, { id: 4, name: 'Mix To Single' },]
    this.event.subscribe('Mixing History', (item) => {
      this.mixingIdList = []
      this.type = ''
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Mixing History');
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "mixingType") {
      if (idList.length > 0) {
        this.type = obj[0].name;
        if (this.type == 'Single To Mix') {
          this.columns = [
            {
              name: 'Sr No',
              cellTemplate: function (cellElement, cellInfo) {
                cellElement.append(cellInfo.row.rowIndex + 1);
              }
            },
            { name: "StoneId", prop: "fromStoneId", summaryType: 'stoneId' },
            { name: "Color", prop: "color" },
            { name: "Shape", prop: "shape" },
            { name: "Ct.", prop: "cts" },
            { name: "Clarity", prop: "clarity" },
            { name: "CSSP", prop: "toStoneId", summaryType: 'stoneId' },
            { name: "Date & Time", prop: "createDate" },
          ];
          this.getMixinghistoryDataByType('SS', 'MIX')
        } else if (this.type == 'Mix To Single') {
          this.columns = [
            {
              name: 'Sr No',
              cellTemplate: function (cellElement, cellInfo) {
                cellElement.append(cellInfo.row.rowIndex + 1);
              }
            },
            { name: "CSSP", prop: "fromStoneId", summaryType: 'stoneId' },
            { name: "StoneId", prop: "toStoneId", summaryType: 'stoneId' },
            { name: "Color", prop: "color" },
            { name: "Shape", prop: "shape" },
            { name: "Ct.", prop: "cts" },
            { name: "Clarity", prop: "clarity" },
            { name: "Invoice No", prop: "invoiceNo" },
            { name: "Sequence No", prop: "srNo" },
            { name: "Date & Time", prop: "createDate" },
          ];
          this.getMixinghistoryDataByType('MIX', 'SS')
        } else if (this.type == 'Purchase To Single') {
          this.columns = [
            {
              name: 'Sr No',
              cellTemplate: function (cellElement, cellInfo) {
                cellElement.append(cellInfo.row.rowIndex + 1);
              }
            },
            { name: "Party", prop: "fromStoneId", summaryType: 'stoneId' },
            { name: "StoneId", prop: "toStoneId", summaryType: 'stoneId' },
            { name: "Color", prop: "color" },
            { name: "Shape", prop: "shape" },
            { name: "Ct.", prop: "cts" },
            { name: "Clarity", prop: "clarity" },
            { name: "Invoice No", prop: "invoiceNo" },
            { name: "Sequence No", prop: "srNo" },
            { name: "Date & Time", prop: "createDate" },
          ];
          this.getMixinghistoryDataByType('PURCHASE', 'SS')
        } else if (this.type == 'Purchase To Mix') {
          this.columns = [
            {
              name: 'Sr No',
              cellTemplate: function (cellElement, cellInfo) {
                cellElement.append(cellInfo.row.rowIndex + 1);
              }
            },
            { name: "Party", prop: "fromStoneId", summaryType: 'stoneId' },
            { name: "Invoice No", prop: "invoiceNo" },
            { name: "Sequence No", prop: "srNo" },
            { name: "CSSP", prop: "toStoneId", summaryType: 'stoneId' },
            { name: "Color", prop: "color" },
            { name: "Shape", prop: "shape" },
            { name: "Ct.", prop: "cts" },
            { name: "Clarity", prop: "clarity" },
            { name: "Date & Time", prop: "createDate" },
          ];
          this.getMixinghistoryDataByType('PURCHASE', 'MIX')
        }
      } else {
        this.type = ''
      }

    }
  }

  setItems() {
    this.items = this.historyData;
    this.count = this.historyData.length;
  }


  async filterItems(ev: any) {
    if (!!this.type) {
      let val = ev.target.value;
      console.log(val)
      await this.setItems();
      if (val && val.trim() !== "") {
        this.items = await this.items.filter(function (item) {
          if (item.stoneId && item.stoneId.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.invoiceNo && item.invoiceNo.toString().includes(val.toLowerCase())) {
            return true;
          } else if (item.srNo && item.srNo.toString().includes(val.toLowerCase())) {
            return true;
          } else if (item.mixStoneId && item.mixStoneId.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.color && item.color.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.shape && item.shape.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.clarity && item.clarity.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.cts && item.cts.toString().includes(val.toLowerCase())) {
            return true;
          }
        });
        this.count = this.items.length;
      } else {
        await this.setItems();
      }
    }
  }

  async getMixinghistoryDataByType(fromType, toType) {
    /*await this.configService.showLoader();
    let res: any;
    console.log(this.data.voucherType);
    res = await this.httpApiService.getMixinghistoryDataByType(fromType, toType, this.pageNumber, this.noOfRecords);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.historyData = data.data.content;
        console.log(this.historyData);
        this.count = data.data.totalElements;
        this.setItems()
      }
    }*/
    this.historyDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          // loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getMixinghistoryDataByType(fromType, toType, this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.historyData = data.data.content;
            this.setItems();
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.historyData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

}
