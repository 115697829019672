import { ProcessActionComponent } from '../../processAction';
import { Component, OnInit, Input, HostListener, ElementRef } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
import saveAs from 'file-saver';
@Component({
  selector: 'app-ashoka-text-file',
  templateUrl: './ashoka-text-file.component.html',
  styleUrls: ['./ashoka-text-file.component.scss']
})
export class AshokaTextFileComponent implements ProcessActionComponent {
  @Input() data: any;
  
  stoneList = "";
  filtered = []
  constructor(public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public alertController: AlertController,
    public configService: ConfigService,
    public event: Events) { }

  ngOnInit() {
  }

  async fetchStones() {
        if (!!this.stoneList) {
      this.filtered = this.stoneList.split("\n");
      let issueList = []
      this.filtered.forEach(el => {
        if (el.indexOf(",") > 0) {
          let data1 = el.split(',')
                    let data2 = []
          data1.forEach(el => {
            data2.push(el.trim())
                    })
          Array.prototype.push.apply(issueList, data2);
        } else {
          issueList.push(el)
                  }
      })
      this.filtered = issueList.filter(function (el) {
        return el != "";
      });
    }
    let res: any;
    let payload = {
      stoneIds: this.filtered
    }
        this.configService.showLoader()
    res = await this.httpApiService.generateAshokaTextFile(payload);
    let data = res.json();
        this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let d: any = await this.httpApiService.downloadFile()
            let sss = d.json();
    

      const text = await new Response(sss).text();
      console.log(text,'text file console');
      let split = text.split("\n");
      console.log(split, 'split array ', split.length);
      const firstValues = new Set(split.map((element) => element.split(',')[0]));
      const filteredData = [];
        firstValues.forEach((value) => {
        const filteredEntries = split.filter((element) => element.startsWith(value));
        filteredData.push(filteredEntries);
      });
      
      console.log(filteredData);
      let sortedData = filteredData.map((item)=>{
        return item.sort();
        
      })
      await this.downloadFilesSequentially(sortedData);
    }
  }

  async downloadFilesSequentially(filteredData:any) {
    for (let i = 0; i < filteredData.length; i++) {
      const firstValue = filteredData[i][0].split(',')[0];
      const dataToDownload = filteredData[i].join('\n');
      const dataWithNewline = `${dataToDownload}\n`;
      const blob = new Blob([dataWithNewline], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${firstValue}.txt`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
}