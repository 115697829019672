import { Component, Input } from '@angular/core';
import { LoadingController, AlertController, ModalController, Events } from '@ionic/angular';
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageCompanyPage } from "../../../components/actions/manage-company/manage-company.page";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements ProcessActionComponent {
  @Input() data: any;

  public listOfCompanies: any;
  public selectedCompany = "";
  public items: any;
  public itemsActions: any;
  public arrowKeyLocation = -1;
  public arrowKeyLocationAction = -1;
  public manageSelectedCompany = "";
  public listOfActions: any;
  public flagButton = "";
  public companyName = "";
  public companyId: any;
  public id: any;
  public searchPermission: string = "";

  public gstin: any;
  public email: any;
  public cinNo: any;
  public pan: any;
  public tel: any;
  public fax: any;
  public address: any;
  public state: any;
  public stateCode: any;
  public companyFullName: any;
  public address2: any;


  constructor(public configService: ConfigService,
    public httpApiService: HttpApiService,
    public alertController: AlertController,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    private authService: AuthenticationService,
    public event: Events,) { }

  ngOnInit() {
    this.getellCompanies();
    this.event.subscribe('Company', (item) => {
      this.getellCompanies();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Company')
  }

  async getellCompanies() {
    let res: any;
    res = await this.httpApiService.getAllCompanies();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfCompanies = data.data;
      this.setItems();
      console.log("getellCompanies res", res, this.listOfCompanies);
    }
  }

  setItems() {
    this.items = this.listOfCompanies;
  }

  selectCompany(item) {
    this.selectedCompany = item.companyName;
    console.log("this.selectedCompany", this.selectedCompany);
    this.searchCompany(item.companyName);
    // this.items = [];
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        console.log("item", item, typeof item);
        return item.companyName.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocation = -1;
    } else {
      this.arrowKeyLocation = -1;
      // this.items = [];
    }
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedCompany = this.items[
              this.arrowKeyLocation
            ].companyName;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedCompany = this.items[
              this.arrowKeyLocation
            ].companyName;
            break;
          } else {
            break;
          }
        }
    }
    console.log(this.arrowKeyLocation);
  }

  async searchCompany(companyName) {
    await this.configService.showLoader();
    let tmpcompanyName = "";
    this.items = this.listOfCompanies;
    if (!!companyName) {
      tmpcompanyName = companyName;
      this.selectedCompany = companyName;
      this.manageSelectedCompany = companyName;
    } else {
      this.selectedCompany = this.manageSelectedCompany;
      tmpcompanyName = this.manageSelectedCompany;
    }
    console.log("companyName", companyName, tmpcompanyName);
    let selectedCompanyId = this.items.filter(function (search) {
      if (search.companyName == tmpcompanyName) {
        return search.id;
      }
    });
    console.log("selectedCompanyId", selectedCompanyId);
    if (selectedCompanyId.length > 0) {
      this.companyId = selectedCompanyId[0].id;
      let res: any;
      res = await this.httpApiService.findDetailById(this.companyId);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        console.log("res", res, data);
        let companyData = data.data;
        this.flagButton = ""
        this.companyName = companyData.companyName;
        this.pan = companyData.pan;
        this.gstin = companyData.gstin;
        this.fax = companyData.fax;
        this.cinNo = companyData.cinNo;
        this.email = companyData.email;
        this.tel = companyData.tel;
        this.companyFullName = companyData.companyFullName;
        this.state = companyData.state;
        this.stateCode = companyData.stateCode;
        this.address = companyData.address;
        this.address2 = companyData.address2;
        this.id = companyData.id;
        this.companyName = this.selectedCompany;
        let index = this.items.findIndex(
          x => x.companyName == selectedCompanyId[0].companyName
        );
        this.arrowKeyLocation = index;

      }
    } else {
      await this.configService.dismiss();
      this.selectedCompany = "";
      this.companyName = "";
      this.pan = "";
      this.gstin = "";
      this.fax = "";
      this.tel = "";
      this.state = "";
      this.companyFullName = ""
      this.stateCode = "";
      this.address = "";
      this.email = "";
    }
  }

  async addCompany() {
    const modal = await this.modalCtrl.create({
      component: ManageCompanyPage,
      backdropDismiss: false,
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
  async handleModalDismiss(d) {
    console.log("d", d);
    if (d.data != 'doNotRefresh') {
      this.getellCompanies();
      this.searchCompany(this.selectedCompany);
    }
  }

  ionModalDidDismiss() {
    console.log("inside ionModalDidDismiss");
    this.getellCompanies();
    this.searchCompany(this.selectedCompany);
  }

  async showButton(name) {
    if (name == "companyName") {
      this.flagButton = "companyName";
    } else if (name == "cinNo") {
      this.flagButton = "cinNo";
    } else if (name == "pan") {
      this.flagButton = "pan";
    } else if (name == "gstin") {
      this.flagButton = "gstin";
    } else if (name == "tel") {
      this.flagButton = "tel";
    } else if (name == "fax") {
      this.flagButton = "fax";
    } else if (name == "state") {
      this.flagButton = "state";
    } else if (name == "stateCode") {
      this.flagButton = "stateCode";
    } else if (name == "address") {
      this.flagButton = "address";
    } else if (name == "companyFullName") {
      this.flagButton = "companyFullName";
    } else if (name == "email") {
      this.flagButton = "email";
    } else if (name == "address2") {
      this.flagButton = "address2";
    }
  }

  async updateCompany() {
    let validate = await this.validateControls();
    if (validate) {
      let jsonObj = {
        gstin: this.gstin,
        pan: this.pan,
        tel: this.tel,
        fax: this.fax,
        state: this.state,
        email: this.email,
        stateCode: this.stateCode,
        cinNo: this.cinNo,
        companyFullName: this.companyFullName,
        address: this.address,
        address2: this.address2
      };
      let res: any;
      let id = this.id;
      res = await this.httpApiService.updateCompany(id, jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.flagButton = "";
        await this.getellCompanies();
        await this.searchCompany(this.companyName);
        await this.configService.showToast(
          "success",
          "Company updated successfully"
        );
      }
    }
  }

  async validateControls() {
    if (this.pan != null && this.pan != undefined && this.pan != '') {
      var panPattern = /^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/;
      if (this.pan.search(panPattern) == -1) {
        await this.configService.showToast("error", "Please enter valid PAN");
        return false;
      }
      let gstinPattern = /^(0[1-9]|[1-2][0-9]|3[0-9])([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([a-zA-Z0-9]){1}([a-zA-Z]){1}([a-zA-Z0-9]){1}/;
      if (this.gstin.search(gstinPattern) == -1) {
        await this.configService.showToast("error", "Please enter valid GSTIN");
        return false;
      }
      let email = await this.configService.isValidEmail(this.email)
      if (!email) {
        await this.configService.showToast("error", "Please enter valid Email");
        return false;
      }
      if (this.cinNo != null && this.cinNo != undefined && this.cinNo != '' && this.cinNo.length == 21) {
        if (this.address != null && this.address != '' && this.address != undefined && this.state != null && this.state != undefined && this.state != ''
          && this.stateCode != null && this.stateCode != undefined && this.stateCode != ''
          && this.companyFullName != null && this.companyFullName != undefined && this.companyFullName != '') {
          return true;
        } else {
          await this.configService.showToast("error", "Please enter Details");
          return false;
        }
      } else {
        await this.configService.showToast("error", "Please enter valid CIN No");
        return false;
      }
    }
  }

}
