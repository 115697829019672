import { Component, Input, OnInit } from '@angular/core';
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import {
  ModalController,
  ToastController,
  LoadingController,
  Events
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import defaultParams from '../elastic-search/defaultmasterparams';

@Component({
  selector: 'app-pd-filters',
  templateUrl: './pd-filters.component.html',
  styleUrls: ['./pd-filters.component.scss']
})
export class PdFiltersComponent implements OnInit {
  @Input() filteredPD: any;
  public stoneList = [];
  public stoneIdList = [];
  public stoneParameter = [];
  public stoneId: any;
  public pageNumber = 0;
  public pValues: any;
  public noOfRecords: Number = 100;
  public paramList = [];
  public singleParameter: any;
  public showPopover = false;
  public selectedValuesArray = [];
  public paramIndex = 0;
  public totalParam: any;
  public pdObject = {};
  public SSParamList = []

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public toastCtrl: ToastController,
    public configService: ConfigService,
    public event: Events) { }

  async ngOnInit() {

    await this.singleStoneParameters();
    if (!!this.filteredPD && Object.keys(this.filteredPD).length > 0) {
      console.log(this.filteredPD)
      for (let key in this.filteredPD) {
        console.log(this.filteredPD[key])
        let valueList = this.filteredPD[key];
        for (let i = 0; i < this.stoneParameter.length; i++) {
          if (this.stoneParameter[i].isGroup == true) {
            this.stoneParameter[i].subParameters.forEach(subparamEl => {
              if (subparamEl.masterName == key) {
                let valueIdList = []
                subparamEl.parameterValues.forEach(item => {
                  let flag = valueList.some(val => val == item.name);
                  // console.log("1", valueList, item.id, item.code, flag)
                  if (flag) {
                    item.isSelected = true;
                    valueIdList.push(item.name)
                    this.pdObject[subparamEl.masterName] = valueIdList
                  } else {
                    item.isSelected = false;
                  }
                })
              }
            })
          } else {
            if (key == this.stoneParameter[i].nameGroup) {
              let valueIdList = []
              this.stoneParameter[i].parameterValues.forEach(item => {
                let flag = valueList.some(val => val == item.name);
                // console.log("2", valueList, item.id, item.code, flag)
                if (flag) {
                  item.isSelected = true;
                  valueIdList.push(item.name)
                  this.pdObject[this.stoneParameter[i].masterName] = valueIdList
                } else {
                  item.isSelected = false;
                }
              })
            }
          }
        }
      }

      console.log(this.stoneParameter, this.pdObject)
    }
  }

  async singleStoneParameters() {
    let res = await this.httpApiService.getAllParametersForSingleStone('SS');
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      let data1 = []
      for (let i = 0; i < dataList.length; i++) {
        if (!!dataList[i].nameGroup) {
          if (data1.length > 0) {
            let flag = data1.some(el => el.nameGroup === dataList[i].nameGroup);
            if (!flag) {
              data1.push({
                id: dataList[i].id, nameGroup: dataList[i].nameGroup, isGroup: true, companyId: dataList[i].companyId,
                downTolerance: dataList[i].companyId, masterName: dataList[i].masterName, paramMasterId: dataList[i].paramMasterId, subParameters: []
              })
            }
          } else {
            data1.push({
              id: dataList[i].id, nameGroup: dataList[i].nameGroup, isGroup: true, companyId: dataList[i].companyId,
              downTolerance: dataList[i].companyId, masterName: dataList[i].masterName, paramMasterId: dataList[i].paramMasterId, subParameters: []
            })
          }
        } else {
          let isValueGroup: any;
          let valueGroup1: any;
          if (dataList[i].parameterValues.length > 0) {
            dataList[i].parameterValues.forEach(el => {
              if (!!el.valueGroup) {
                valueGroup1 = el.valueGroup;
              }
            })
            let flag: Boolean;
            dataList[i].parameterValues.filter(function (item) {
              if (item.valueGroup != valueGroup1) {
                flag = true;
                return true;
              }
              return
            });
            if (!!flag && flag == true) {
              isValueGroup = true
            } else {
              isValueGroup = false
            }
          }
          data1.push({
            id: dataList[i].id, nameGroup: dataList[i].masterName, isValueGroup: isValueGroup, isGroup: false, companyId: dataList[i].companyId,
            downTolerance: dataList[i].companyId, masterName: dataList[i].masterName, paramMasterId: dataList[i].paramMasterId, parameterValues: dataList[i].parameterValues
          })
        }
      }

      for (let i = 0; i < data1.length; i++) {
        if (data1[i].isGroup == true) {
          data1[i].totalsubParamList = []
          dataList.forEach(el => {
            if (el.nameGroup == data1[i].nameGroup) {
              data1[i].subParameters.push(el)
            }
          })
          data1[i].subParameters.forEach((el) => {
            data1[i].totalsubParamList.push(el.masterName)
          })
        }
      }

      this.SSParamList = data1;
      this.stoneParameter = JSON.parse(JSON.stringify(this.SSParamList));

      // this.stoneParameter = await this.stoneParameter.map(async (n) => {
      //   let arrayVal = defaultParams.parameters.filter(x => x.masterName == n.masterName)
      //   if (arrayVal.length > 0) {
      //     let masterKeyName = arrayVal[0].resultObject.prop
      //     n['masterKeyName'] = masterKeyName
      //     console.log(n)
      //     if (!!n.subParameters) {
      //       let arrayJ = n.subParameters.map(data => {
      //         let iarrayVal = defaultParams.parameters.filter(j => j.masterName == data.masterName)

      //         if (iarrayVal.length > 0) {
      //           let mName = iarrayVal[0].resultObject.prop
      //           data['masterKeyName'] = mName;
      //         }

      //         return data
      //       })
      //       n['subParameters'] = arrayJ
      //       // debugger
      //     }

      //     console.log(n)
      //   }
      //   return n
      // })


      console.log(this.stoneParameter)

    }
  }


  //select sub parameter value

  async selectedPDParameterValue(parameters, sub, stones, index) {
    console.log(parameters, sub, stones, index)
    if (parameters.isGroup == true) {
      if ((!!stones[index].code && stones[index].code.toLowerCase() == 'non') || stones[index].name.toLowerCase() == 'none') {
        stones.forEach(element => {
          element.isSelected = false;
        });
        stones[index].isSelected = true;
        parameters.isValueGroup = false;
      } else {
        stones.forEach((el, i) => {
          if (!!el.code && el.code.toLowerCase() == 'non' || el.name.toLowerCase() == 'none') {
            stones[i].isSelected = false;
          }
        })
        parameters.isValueGroup = false;
        stones.forEach((el, i) => {
          if (!!el.valueGroup && el.valueGroup == stones[index].valueGroup) {
            stones[i].isSelected = false;
            stones[index].isSelected = true;
          } else if (!!el.valueGroup && el.valueGroup != stones[index].valueGroup) {
            stones[index].isSelected = true;
          }
        })
      }
    } else {
      if (parameters.isValueGroup && parameters.isValueGroup == true) {
        if ((!!stones[index].code && stones[index].code.toLowerCase() == 'non') || stones[index].name.toLowerCase() == 'none') {
          stones.forEach(element => {
            element.isSelected = false;
          });
          stones[index].isSelected = true;
        } else {
          stones.forEach((el, i) => {
            if (!!el.code && el.code.toLowerCase() == 'non' || el.name.toLowerCase() == 'none') {
              stones[i].isSelected = false;
            }
          })
          stones.forEach((el, i) => {
            if (!!el.valueGroup && el.valueGroup == stones[index].valueGroup) {
              stones[i].isSelected = false;
              stones[index].isSelected = true;
            } else if (!!el.valueGroup && el.valueGroup != stones[index].valueGroup) {
              stones[index].isSelected = true;
            }
          })
        }
      } else {
        stones.forEach(element => {
          element.isSelected = false;
        });
        stones[index].isSelected = true;
      }
    }
    if (!!parameters.isValueGroup && parameters.isValueGroup == true && parameters.isGroup == true) {
      let paramList = parameters.subParameters;
      for (let i = 0; i < paramList.length; i++) {
        let valueIdList = [];
        let valueList = paramList[i].parameterValues;
        for (let j = 0; j < valueList.length; j++) {
          if (!!valueList[j].isSelected && valueList[j].isSelected == true) {
            valueIdList.push(valueList[j].name)
          }
        }
        if (valueIdList.length > 0) {
          //this.pdObject[paramList[i].masterName] = valueIdList;
          let keyName = paramList[i].masterName
          let arrayVal = defaultParams.parameters.filter(j => j.masterName == keyName)
          if (arrayVal.length > 0) {
            let mName = arrayVal[0].resultObject.prop
            keyName = mName;
          }
          this.pdObject[keyName] = valueIdList
        }

      }
    } if (parameters.isValueGroup == false && parameters.isGroup == true) {
      let paramList = parameters.subParameters;
      for (let i = 0; i < paramList.length; i++) {
        let valueIdList = [];
        let valueList = paramList[i].parameterValues;
        for (let j = 0; j < valueList.length; j++) {
          if (!!valueList[j].isSelected && valueList[j].isSelected == true) {
            valueIdList.push(valueList[j].name)
          }
        }
        if (valueIdList.length > 0) {
          //this.pdObject[paramList[i].masterName] = valueIdList;
          let keyName = paramList[i].masterName
          let arrayVal = defaultParams.parameters.filter(j => j.masterName == keyName)
          if (arrayVal.length > 0) {
            let mName = arrayVal[0].resultObject.prop
            keyName = mName;
          }
          this.pdObject[keyName] = valueIdList
        }
      }
    } else if (parameters.isGroup == false && parameters.isValueGroup == true) {
      let paramList = stones;
      let valueIdList = [];
      for (let i = 0; i < paramList.length; i++) {
        if (paramList[i].isSelected == true) {
          valueIdList.push(paramList[i].name)
        }
      }
      if (valueIdList.length > 0) {
        //this.pdObject[parameters.nameGroup] = valueIdList;
        let keyName = parameters.nameGroup
        let arrayVal = defaultParams.parameters.filter(j => j.masterName == keyName)
        if (arrayVal.length > 0) {
          let mName = arrayVal[0].resultObject.prop
          keyName = mName;
        }
        this.pdObject[keyName] = valueIdList
      }
    } else if (parameters.isGroup == false && parameters.isValueGroup == false) {
      let paramList = stones;
      let valueIdList = [];
      for (let i = 0; i < paramList.length; i++) {
        if (paramList[i].isSelected == true) {
          valueIdList.push(paramList[i].name)
        }
      }
      if (valueIdList.length > 0) {
        //this.pdObject[parameters.nameGroup] = valueIdList;
        let keyName = parameters.nameGroup
        let arrayVal = defaultParams.parameters.filter(j => j.masterName == keyName)
        if (arrayVal.length > 0) {
          let mName = arrayVal[0].resultObject.prop
          keyName = mName;
        }
        this.pdObject[keyName] = valueIdList
      }
    }
    console.log(this.pdObject, this.stoneParameter);
    console.log(this.pdObject)
  }

  clear() {
    this.pdObject = {};
    this.stoneParameter = JSON.parse(JSON.stringify(this.SSParamList));
  }

  addFilters() {
    //this.pdObject = this.pdObject.filter(x => )
    console.log(this.pdObject)
    this.modalCtrl.dismiss(this.pdObject)
  }

  closeModal() {
    this.modalCtrl.dismiss()
  }
}
