import { Component, Input } from "@angular/core";
import {
  NavController,
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
  Events
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";
import { ProcessActionComponent } from "../../processAction";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: 'app-pricing-logs',
  templateUrl: './pricing-logs.component.html',
  styleUrls: ['./pricing-logs.component.scss']
})
export class PricingLogsComponent implements ProcessActionComponent {
  @Input() data: any;
  UIChainApplyLogsSource: any;
  TransactionalApplyLogsSource: any;
  pageSize = 50
  pageNumber = 0
  totalCount = 0
  policyColumns = []
  transactionPolicyColumns = []
  chainColumns = []
  chainUIData = []
  chainTransactionData = []
  listOfInProgressChain = []
  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events) {
    this.event.subscribe('Pricing Logs', (item) => {
      this.pricingLogsRunFromUI('policyexecution')
      this.pricingLogsTransactional('Stone Policy Execution')
      this.getInternalInProgressPricingChain()
      this.policyColumns = [
        {
          name: 'Sr No',
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          }
        },
        { name: "Task Type", prop: "taskType" },
        { name: "Policy Name", prop: "taskInputJson" },
        { name: "Status", prop: "status" },
        { name: "Created At", prop: "createdAt" },
        { name: "Created By", prop: "createdBy" },
      ];

      this.transactionPolicyColumns = [
        {
          name: 'Sr No',
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          }
        },
        { name: "Task Type", prop: "taskType" },
        { name: "Policy Name", prop: "taskInputJson" },
        { name: "Status", prop: "status" },
        { name: "Created At", prop: "createdAt" },
        { name: "Created By", prop: "createdBy" },
      ];


      this.chainColumns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      }, { name: "Name", prop: "name" },
      { name: "Start Price", prop: "startPriceListName" },
      { name: "End Price", prop: "endPriceListName" },
      { name: "Last Applied", prop: "appliedDate" },
      { name: "Modified Date", prop: "modifiedDate" },
      { name: "Status", prop: "status" },
      { name: "Modified By", prop: "modifiedBy" }];
    })
  }

  ngOnInit() {
    this.policyColumns = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Task Type", prop: "taskType" },
      { name: "Policy Name", prop: "taskInputJson" },
      { name: "Status", prop: "status" },
      { name: "Created At", prop: "createdAt" },
      { name: "Created By", prop: "createdBy" },
    ];
    this.transactionPolicyColumns = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Task Type", prop: "taskType" },
      { name: "Policy Name", prop: "taskInputJson" },
      { name: "Status", prop: "status" },
      { name: "Created At", prop: "createdAt" },
      { name: "Created By", prop: "createdBy" },
    ];


    this.chainColumns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, { name: "Name", prop: "name" },
    { name: "Start Price", prop: "startPriceListName" },
    { name: "End Price", prop: "endPriceListName" },
    { name: "Last Applied", prop: "appliedDate" },
    { name: "Modified Date", prop: "modifiedDate" },
    { name: "Status", prop: "status" },
    { name: "Modified By", prop: "modifiedBy" }];


    this.pricingLogsRunFromUI('Stone Policy Execution')
    this.pricingLogsTransactional('Policy Execution')
    this.getInternalInProgressPricingChain()
  }

  ngOnDestroy() {
    this.event.unsubscribe('Pricing Logs');
  }


  async pricingLogsRunFromUI(taskType) {
    this.UIChainApplyLogsSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getPricingLogsChainFromUI(taskType, this.pageNumber, this.pageSize);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.chainUIData = data.data.content;
            this.totalCount = this.chainUIData.length;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalCount,
              groupCount: data.data.totalCount
            };
          }
        } else {
          return {
            data: this.chainUIData,
            totalCount: this.totalCount,
            groupCount: this.totalCount
          }
        }
      }
    });
  }

  async pricingLogsTransactional(taskType) {
    this.TransactionalApplyLogsSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getPricingLogsChainFromUI(taskType, this.pageNumber, this.pageSize);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.chainTransactionData = data.data.content;
            this.totalCount = this.chainTransactionData.length;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalCount,
              groupCount: data.data.totalCount
            };
          }
        } else {
          return {
            data: this.chainTransactionData,
            totalCount: this.totalCount,
            groupCount: this.totalCount
          }
        }
      }
    });
  }

  async getInternalInProgressPricingChain() {
    let res: any;
    res = await this.httpApiService.getInProgressPricingChain();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfInProgressChain = data.data;
    }
  }


}
