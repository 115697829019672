export const location = [
    {
      id: 'mumbai',
      label: 'Mumbai',
      value: 'mumbai',
    },
    {
      id: 'ny',
      label: 'New York',
      value: 'ny',
    },
    {
      id: 'hk',
      label: 'Hong Kong',
      value: 'hk',
    },
    {
      id: 'aspeconv',
      label: 'Antwerp',
      value: 'aspeconv',
    },
    {
      id: 'dmcc',
      label: 'Dubai',
      value: 'dmcc',
    },
    {
      id: 'shanghai',
      label: 'Shanghai',
      value: 'shanghai',
    },
  ]