import { Component, Input, ViewChild } from "@angular/core";
import {
  NavController,
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManagePricingSizeMasterPage } from "../../../components/actions/manage-pricing-size-master/manage-pricing-size-master.page";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: 'app-pricing-size-master',
  templateUrl: './pricing-size-master.component.html',
  styleUrls: ['./pricing-size-master.component.scss']
})
export class PricingSizeMasterComponent implements ProcessActionComponent {
  @Input() data: any;

  public columns = [];
  public page = 'pricingSizeMaster';
  public listOfSize = [];
  public listOfSizeDataSource: any = {};
  public count: Number = 0;
  public items = [];
  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 50;
  public totalPages: Number = 0;
  public manageSelectedSize = '';
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public refreshGrid : Boolean = false;

  constructor(
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) {
    this.deleteItem = this.deleteItem.bind(this);
  }


  ngOnInit() {
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "Type", prop: "type" },
    { name: "Shape", prop: "shapeName" },
    { name: "Size Name", prop: "name" },
    { name: "From Size", prop: "fromCts" },
    { name: "To Size", prop: "toCts" },
    { name: "Short Name", prop: "shortName" },
    { name: "Pointers", prop: "pointers" },
    { name: "Status", prop: "isActive" }]
    // if (this.deletePermissionFlag == true) {
    //   this.columns.push({ name: "Action", prop: "" })
    // }
    this.getAllPricingSizeMaster(this.pageNumber, this.noOfRecords)
    this.event.subscribe('Mix Size Master', (item) => {
      this.getAllPricingSizeMaster(this.pageNumber, this.noOfRecords)
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Mix Size Master');
  }

  setItems() {
    this.items = this.listOfSize;
  }

  async filterItems(ev: any) {
    let val = ev.target.value;
    await this.setItems();
    if (val && val.trim() !== "") {
      let res: any;
      this.pageNumber = 0;
      this.noOfRecords = 20;
      this.listOfSizeDataSource = new CustomStore({
        key: "id",
        load: async (loadOptions: any) => {
          console.log("loadOptions ", loadOptions);
          if (!!loadOptions.take) {
            if ([10, 20, 50, 100,500].indexOf(loadOptions.take) == -1) {
              loadOptions.take = this.pageSize;
            } else {
              this.pageSize = loadOptions.take;
            }
            this.noOfRecords = this.pageSize;
            //loadOptions.take = this.noOfRecords;
            this.pageNumber = loadOptions.skip / loadOptions.take;
            let res: any;
            res = await await this.httpApiService.searchPricingSizeMaster(val, this.pageNumber, this.noOfRecords);
            let data = res.json();
            if (res.status == 401) {
              this.authService.logout();
            } else if (res.status == 500) {
              await this.configService.showToast("error", data.error);
            } else if (res.status == 400) {
              await this.configService.showToast("error", data.error);
            } else {
              this.listOfSize = data.data.content;
              this.setItems();
              return {
                data: Query(data.data.content)
                  .toArray(),
                totalCount: data.data.totalElements,
                groupCount: data.data.totalElements
              };
            }
          } else {
            return {
              data: this.listOfSize,
              totalCount: this.count,
              groupCount: this.count
            }
          }
        }
      });
      // res = await this.httpApiService.searchPricingSizeMaster(val, this.pageNumber, this.noOfRecords);
      // let data = res.json();
      // if (data.status == 401) {
      //   await this.authService.logout()
      // } else if (data.status == 500) {
      //   await this.configService.showToast('error', data.error)
      // } else if (data.status == 400) {
      //   await this.configService.showToast('error', data.error)
      // } else {
      //   this.listOfSize = data.data.content;
      //   this.totalPages = data.data.totalPages;
      //   this.count = data.data.totalElements;
      //   this.setItems()
      // }
    } else {
      await this.getAllPricingSizeMaster(this.pageNumber, this.noOfRecords);
    }
  }

  async getAllPricingSizeMaster(pageNumber, noOfRecords) {
    this.listOfSizeDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100,500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getPricingSizeMaster(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfSize = data.data.content;
            this.setItems();
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfSize,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res: any;
    // res = await this.httpApiService.getPricingSizeMaster(pageNumber, noOfRecords);
    // let data = res.json();
    // if (data.status == 401) {
    //   await this.authService.logout()
    // } else if (data.status == 500) {
    //   await this.configService.showToast('error', data.error)
    // } else if (data.status == 400) {
    //   await this.configService.showToast('error', data.error)
    // } else {
    //   this.listOfSize = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    //   this.setItems()
    // }
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getAllPricingSizeMaster(currentPage.offset, this.noOfRecords);
  }


  async manageSizeMaster(item, flag) {
    this.refreshGrid = false;

    if ((item.type == "mouseenter") || (item.column && item.column.name == 'Action')) {
      item.event.stopPropagation();
    } else {
      if (flag == 'update' && this.updatePermissionFlag == true) {
        let modal = await this.modalCtrl.create({
          component: ManagePricingSizeMasterPage,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: { item: item.row, flag: flag, }
        })
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
      if (flag == 'add' && this.addPermissionFlag == true) {
        let modal = await this.modalCtrl.create({
          component: ManagePricingSizeMasterPage,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: { item: item, flag: flag, }
        })
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
    }
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    if (d.data != 'doNotRefresh') {
      await this.getAllPricingSizeMaster(this.pageNumber, this.noOfRecords);
    }
  }

  //Start Delete Policy 

  async confirmDeleteSizeMaster(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this size ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteStatus(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteStatus(id) {
    console.log(id)
    let res: any;
    res = await this.httpApiService.deletePricingSizeMaster(id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.getAllPricingSizeMaster(this.pageNumber, this.noOfRecords);
    }
  }

  async deleteItem(ev) {
    this.confirmDeleteSizeMaster(ev.row.data);
  }
  //END

  //Start Delete Block

}
