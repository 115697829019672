import { Component, HostListener, OnInit } from "@angular/core";

import {
  AlertController,
  Events,
  LoadingController,
  ModalController,
} from "@ionic/angular";

import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

import { ExportAsExcelService } from "src/app/services/export-as-excel.service";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { HttpApiService } from "src/app/services/http-api/http-api.service";
import { ConfigService } from "src/app/services/config/config.service";
import { ManageInventoryManagerPage } from "../actions/manage-inventory-manager/manage-inventory-manager.page";

@Component({
  selector: "app-svc-request",
  templateUrl: "./svc-request.component.html",
  styleUrls: ["./svc-request.component.scss"],
})
export class SvcRequestComponent implements OnInit {
  public listOfRequestedStoneDataSource = {};
  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 50;
  public count = 0;
  public keyFun = true;
  public openView = false;
  public columns = [];
  public stonWiseColumns = [];
  public listOfRequestedStone = [];
  public page = "SVCReqestList";
  public refreshGrid = false;
  public option = "STONE WISE";
  public optionList = [];
  public listOfStones = [];

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public configService: ConfigService,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService
  ) {
    this.event.subscribe("SVC Request List", (item) => {
      this.optionList = [
        { id: 1, name: "TRANSACTION WISE", isSelected: false },
        { id: 2, name: "STONE WISE", isSelected: true },
      ];
      this.columns = [
        { name: "Voucher No", prop: "" },
        { name: "Action", prop: "requestFor" },
        { name: "Party Name", prop: "partyName" },
        { name: "Date & Time", prop: "modifiedDate" },
        { name: "Status", prop: "status" },
        { name: "Request From", prop: "requestPlatform" },
        { name: "Cancel Request", prop: "cancelRequest" },
      ];
      this.stonWiseColumns = [
        { name: "Voucher No", prop: "" },
        { name: "Action", prop: "requestFor" },
        { name: "Party Name", prop: "partyName" },
        { name: "Date & Time", prop: "modifiedDate" },
        { name: "Stone Id", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Clarity", prop: "clarity" },
        { name: "Cts", prop: "stoneCts" },
        { name: "Request Disc", prop: "requestDiscount" },
        { name: "Request Price", prop: "requestPrice" },
        { name: "Request Amount", prop: "requestAmt" },
        { name: "Status", prop: "status" },
        { name: "Stone Remarks", prop: "stoneRemarks" },
      ];
      this.viewDataByOption(this.optionList);
    });
  }

  ngOnInit() {
    this.optionList = [
      { id: 1, name: "TRANSACTION WISE", isSelected: false },
      { id: 2, name: "STONE WISE", isSelected: true },
    ];
    this.columns = [
      { name: "Voucher No", prop: "" },
      { name: "Action", prop: "requestFor" },
      { name: "Party Name", prop: "partyName" },
      { name: "Date & Time", prop: "modifiedDate" },
      { name: "Status", prop: "status" },
      { name: "Request From", prop: "requestPlatform" },
      { name: "Cancel Request", prop: "cancelRequest" },
    ];
    this.stonWiseColumns = [
      { name: "Voucher No", prop: "" },
      { name: "Action", prop: "requestFor" },
      { name: "Party Name", prop: "partyName" },
      { name: "Date & Time", prop: "modifiedDate" },
      { name: "Stone Id", prop: "stoneId" },
      { name: "Shape", prop: "shape" },
      { name: "Color", prop: "color" },
      { name: "Clarity", prop: "clarity" },
      { name: "Cts", prop: "stoneCts" },
      { name: "Request Disc", prop: "requestDiscount" },
      { name: "Request Price", prop: "requestPrice" },
      { name: "Request Amount", prop: "requestAmt" },
      { name: "Status", prop: "status" },
      { name: "Stone Remarks", prop: "stoneRemarks" },
    ];
    this.viewDataByOption(this.optionList);
  }

  async findSvcRequested() {
    this.listOfRequestedStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        // await this.configService.dismiss();
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findSvcRequested(
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfRequestedStone = data.data.content;
            return {
              data: Query(data.data.content).toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements,
            };
          }
        } else {
          return {
            data: this.listOfRequestedStone,
            totalCount: this.count,
            groupCount: this.count,
          };
        }
      },
    });
  }

  async viewDataByOption(optionList) {
    optionList.forEach((el) => {
      if (el.isSelected == true) {
        this.option = el.name;
      }
    });
    console.log(this.option);

    if (this.option == "TRANSACTION WISE") {
      this.pageNumber = 0;
      this.noOfRecords = 20;
      this.openView = false;

      this.columns = [
        { name: "Voucher No", prop: "" },
        { name: "Party Name", prop: "partyName" },
        { name: "Date & Time", prop: "modifiedDate" },
        { name: "Status", prop: "status" },
        { name: "Request From", prop: "requestPlatform" },
        { name: "Cancel Request", prop: "cancelRequest" },
      ];
      this.requestedStoneVoucherView();
    } else if (this.option == "STONE WISE") {
      this.pageNumber = 0;
      this.noOfRecords = 20;
      this.openView = false;
      this.columns = this.stonWiseColumns;
      this.requestedStoneView();
      // this.getAllPendingStoneInwardProgramWise(this.pageNumber, this.noOfRecords);
    }
  }

  ngOnDestroy() {
    this.event.unsubscribe("SVC Request List");
    // this.requestedStone()
  }

  //requested

  async requestedStoneVoucherView() {
    this.listOfRequestedStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        // await this.configService.dismiss();
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findRequestedSVCStone(
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfRequestedStone = data.data.content;
            return {
              data: Query(data.data.content).toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements,
            };
          }
        } else {
          return {
            data: this.listOfRequestedStone,
            totalCount: this.count,
            groupCount: this.count,
          };
        }
      },
    });
  }

  //requested stone wise

  async requestedStoneView() {
    this.listOfRequestedStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        // await this.configService.dismiss();
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findRequestedSVCStoneView(
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfRequestedStone = data.data.content;
            return {
              data: Query(data.data.content).toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements,
            };
          }
        } else {
          return {
            data: this.listOfRequestedStone,
            totalCount: this.count,
            groupCount: this.count,
          };
        }
      },
    });
  }

  async deleteFromRequest(item) {
    this.deleteConfirm(item);
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (this.keyFun) {
      if (event.keyCode == 86) {
        this.openView = true;
      }
    }
  }

  //Delete Request

  async deleteConfirm(item) {
    console.log(item);
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Request?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteRequest(item);
          },
        },
      ],
    });

    await alert.present();
  }

  async deleteRequest(item) {
    console.log(item);
    let res: any;
    res = await this.httpApiService.deleteRequest(item.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.requestedStoneVoucherView();
    }
  }

  async openRequestModal(flag, item) {
    this.refreshGrid = false;

    const modal = await this.modalCtrl.create({
      component: ManageInventoryManagerPage,
      backdropDismiss: false,
      cssClass: "requestPage",
      componentProps: {
        data: item.row,
        type: flag,
        inventoryManagerType: "",
      },
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
  }

  selectStones(ev) {
    console.log(ev);
  }

  async closeViewFun() {
    this.openView = false;
  }

  async onSelectRows(event) {
    if (event.selectedRowsData && event.selectedRowsData.length > 0) {
      this.listOfStones = event.selectedRowsData;
    } else {
      this.listOfStones = [];
    }

    console.log(this.listOfStones);
  }

  //Release stones Request

  async releaseStoneConfirm() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to release these stones?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteRequestedStones();
          },
        },
      ],
    });

    await alert.present();
  }

  async deleteRequestedStones() {
    let res: any;
    let voucherAndStoneIdsList: any = [];
    await this.configService.showLoader();
    this.listOfStones.forEach((el) => {
      let isExist = voucherAndStoneIdsList.find(
        (item) => item.voucherId == el.voucherId
      );
      if (!!isExist) {
        voucherAndStoneIdsList.filter((pl) => {
          if (pl.voucherId == isExist.voucherId) {
            pl.stoneIds.push(el.id);
          }
        });
      } else {
        voucherAndStoneIdsList.push({
          voucherId: el.voucherId,
          stoneIds: [el.id],
        });
      }
    });
    let payload = {
      voucherAndStoneIdsList: voucherAndStoneIdsList,
      isSvcRequest: true,
    };
    console.log(payload);
    res = await this.httpApiService.deleteRequestedStones(payload);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.requestedStoneView();
    }
  }
}
