export default {
  "reportNo": "R-9",
  "url": "master-service/api/report/csspWiseSummary",
  "method": "GET",
  "size": 3,
  "fields": [{
    title: ' Department Name ',
    input: 'select',
    key: 'departmentId',
    placeholder: 'Select Department Type',
    property: 'departmentId',
    keyName: 'id',
    name: 'name',
    required: true,
    singleSelection: true,
    function: 'getAllDepartment',
    default: [],
    size: 3
  },
  {
    title: ' Shape ',
    input: 'select',
    key: 'shape',
    placeholder: 'Select Shape',
    property: 'shape',
    keyName: 'id',
    name: 'name',
    singleSelection: true,
    function: 'getAllShape',
    default: [],
    size: 3
  },
  {
    title: ' Color ',
    input: 'select',
    key: 'color',
    placeholder: 'Select Color',
    property: 'color',
    keyName: 'id',
    name: 'name',
    singleSelection: true,
    function: 'getAllColour',
    default: [],
    size: 3
  },
  {
    title: ' Clarity ',
    input: 'select',
    key: 'clarity',
    placeholder: 'Select Clarity',
    property: 'clarity',
    keyName: 'id',
    name: 'name',
    singleSelection: true,
    function: 'getAllClarity',
    default: [],
    size: 3
  },
  {
    title: ' Size ',
    input: 'select',
    key: 'size',
    placeholder: 'Select Size',
    property: 'size',
    keyName: 'id',
    name: 'name',
    singleSelection: true,
    function: 'getAllAssortment',
    default: [],
    size: 3
  }
  ],
  "columns": [
    {
      "name": "Color",
      "prop": "color",
    }, {
      "name": "Shape",
      "prop": "shape",
    }, {
      "name": "Size",
      "prop": "base",
    }, {
      "name": "Clarity",
      "prop": "clarity",
    }, {
      "name": "Cut",
      "prop": "cut",
    }, {
      "name": "fluorescence",
      "prop": "fluorescence",
    }, {
      "name": "Disc",
      "prop": "disc",
    }, {
      "name": "Cut",
      "prop": "cut",
    }, {
      "name": "Price",
      "prop": "price",
    }
  ]
};
