import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "moment"
})
export class MomentPipe implements PipeTransform {
  // transform(value: any, args?: any): any {
  //   let yesterday = moment.utc().subtract(1, "day");
  //   let twoDaysAgo = moment.utc().subtract(2, "day");
  //   if (moment.utc(value).isSame(yesterday, "day")) {
  //     console.log(value,"1")
  //     return "Yesterday";
  //   } else if (moment.utc(value).isBefore(twoDaysAgo, "day")) {
  //     console.log(value,"2")
  //     return value;
  //   } else {
  //     console.log(value,"3")
  //     return moment.utc(value).fromNow();
  //   }

    transform(value: any, args?: any): any {
      if ( moment.utc(value).local().isAfter(moment().subtract(48, "hours"))) {
        // console.log("ads",moment("2019-02-15 04:59:48.000").fromNow());
        return moment.utc(value).local().fromNow();
      } else {
        // console.log("value", value);
        // let date = moment(value).utc();
        // console.log("datee", date);
        return moment.utc(value).local().format("DD-MMM hh:mm a");
      }
    // }
    // console.log(moment.utc(value).isAfter(moment.utc().subtract(3, "hours")))
    // console.log(moment.utc(value).format("DD-MMM hh:mm a"))
    // if ( moment.utc(value).isAfter(moment.utc().subtract(3, "hours"))) {
    //   return  moment.utc(value).fromNow();
    // } else {
    //   return moment.utc(value).format("DD-MMM hh:mm a");
    // }
  }
}
