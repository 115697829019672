import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FormatInputElemValueService {
  constructor() {}

  formatString(str: string): string[] {
    let arr: string[] = [];
    if (str.includes("\n")) {
      arr = str.split("\n").filter((m) => !!m.trim());
    } else {
      arr = [str];
    }

    let newArr: string[] = [];
    arr.forEach((m) => {
      if (m.includes(", ")) {
        let a = m.split(", ").map((m) => m.trim());
        newArr.push(...a);
      } else if (m.includes(",")) {
        let a = m.split(",").map((m) => m.trim());
        newArr.push(...a);
      } else if (m.includes(" ")) {
        let a = m.split(" ").map((m) => m.trim());
        newArr.push(...a);
      } else {
        newArr.push(m.trim());
      }
    });

    return newArr.filter((m) => !!m.trim());
  }
}
