import { Component, OnInit } from "@angular/core";
import {
  NavParams,
  ModalController,
  ToastController,
  Events,
  LoadingController
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "src/app/services/http-api/http-api.service";

@Component({
  selector: "app-manage-customer",
  templateUrl: "./manage-customer.page.html",
  styleUrls: ["./manage-customer.page.scss"]
})
export class ManageCustomerPage implements OnInit {
  public iModal: any;
  public partyName: any;
  public name: any;
  public address: any;
  public zipcode: any;
  public city: any;
  public state: any;
  public gstin: any;
  public mobileNumber: any;
  public contactNumber: any;
  public email: any;
  public contactEmail: any;
  public kamPersonList: any;
  public userId: any;
  public kamPersonId: any;
  public customerId: any;
  public type: any;
  public labReferenceId: any;
  public listOfLabs = []
  public listOfType = []
  public typeIdList = []
  public cinNo: any;
  public pan: any;
  public fax: any;
  public stateCode: any;
  public country: any;
  public groupTypeList: any;
  public groupType: any;
  public item: any;
  public isDefault: Boolean = false;
  public accountNo = '';
  public isDefaultTransportMode: Boolean = false;
  public shippingCost: number = 0;
  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public toastCtrl: ToastController,
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public event: Events,
    public loadingController: LoadingController
  ) { }

  async ngOnInit() {
    await this.getDepartmentWiseKamPersonList();
    await this.getAllType()
    await this.getAllLabs()
    this.groupTypeList = await this.configService.groupType()

    this.iModal = await this.navParams.get("flag");
    this.item = await this.navParams.get("item");
    console.log("iModal", this.item, this.iModal);
    if (this.iModal == 'updateAddress') {
      this.address = this.item.address;
      this.zipcode = this.item.zipcode;
      this.city = this.item.city;
      this.state = this.item.state;
      this.country = this.item.country;
      this.stateCode = this.item.stateCode;
      this.isDefault = this.item.isDefault;
    }
    // this.actionId = await this.navParams.get('actionId')
    if (this.iModal == "addCustomerContact") {
      this.customerId = await this.navParams.get("customerId");
    } else {
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'typeList') {
      if (idList.length > 0) {
        this.type = obj[0].type
      } else {
        this.type = null;
        this.typeIdList = [];
      }
    } else if (property == 'labList') {
      if (idList.length > 0) {
        this.labReferenceId = idList[0]
      } else {
        this.labReferenceId = null;
      }
    } else if (property == 'groupType') {
      if (idList.length > 0) {
        this.groupType = obj[0].name;
      } else {
        this.groupType = null;
      }
    } else if (property == 'kamPerson') {
      if (idList.length > 0) {
        this.kamPersonId = idList[0]
      } else {
        this.kamPersonId = null;
      }
    }
  }

  async getAllType() {
    let res: any;
    res = await this.httpApiService.getAllPartyMasterType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      console.log(dataList)
      if (dataList.length > 0) {
        for (let i = 0; i < dataList.length; i++) {
          this.listOfType.push({ id: i + 1, type: dataList[i] })
        }
      }
    }
  }

  async getAllLabs() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("lab");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLabs = data.data;
      // this.items = this.listOfParameter;
    }
  }


  closeModal() {
    this.modalCtrl.dismiss(this.iModal);
  }

  async getDepartmentWiseKamPersonList() {
    let res: any;
    res = await this.httpApiService.getDepartmentWiseKamPersonList("KAM Sales");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.kamPersonList = data.data;
      console.log("get All Customer", this.kamPersonList);
    }
  }

  async validateControls() {
    if (
      this.partyName != "" &&
      this.mobileNumber != "" &&
      this.email != "" &&
      this.address != "" &&
      this.kamPersonId != "" &&
      this.kamPersonId != undefined &&
      this.kamPersonId != null
    ) {
      if (!(await this.configService.isValidEmail(this.email))) {
        await this.configService.showToast(
          "error",
          "Please enter correct email address"
        );
        return true;
      }
      return false;
    } else {
      await this.configService.showToast("error", "Please insert the detail");
      return true;
    }
  }

  async saveCustomer() {
    let validate = await this.validateCustomerControls();
    if (validate) {
      await this.configService.showLoader();
      let jsonObj = {
        partyName: this.partyName,
        labReferenceId: this.labReferenceId,
        type: this.type,
        gstin: this.gstin,
        pan: this.pan,
        groupType: this.groupType,
        cinNo: this.cinNo,
        fax: this.fax,
        contactNo: this.mobileNumber,
        email: this.email,
        accountNo: this.accountNo,
        kamPersonId: this.kamPersonId,
        shippingCost: this.shippingCost,
        isDefaultTransport: this.isDefaultTransportMode
      };
      if (this.labReferenceId) {
        jsonObj.labReferenceId = this.labReferenceId;
      }
      let res;
      console.log(jsonObj);
      res = await this.httpApiService.addCustomer(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast(
          "success",
          data.message
        );
        await this.closeModal();
      }
    }
  }

  async validateCustomerControls() {
    if (!!this.type) {
      return true;
    } else {
      await this.configService.showToast(
        "false",
        "Please insert details"
      );
      return false;
    }
  }

  async saveCustomerContact() {
    await this.configService.showLoader();
    let jsonObj = {
      name: this.name,
      contactNo: this.contactNumber,
      email: this.contactEmail,
      customerMasterId: this.customerId
    };
    let res;
    console.log(jsonObj);
    res = await this.httpApiService.addCustomerContact(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast(
          "success",
          data.message
        );
        await this.closeModal();
      }
    }
  }

  async saveAddress() {
    await this.configService.showLoader();
    let jsonObj = {
      address: this.address,
      zipcode: this.zipcode,
      city: this.city,
      country: this.country,
      state: this.state,
      stateCode: this.stateCode,
      partyMasterId: this.customerId,
    }
    let res;
    console.log(jsonObj);
    res = await this.httpApiService.saveAddress(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast(
          "success",
          data.message
        );
        await this.closeModal();
      }
    }
  }

  async updateAddress() {
    await this.configService.showLoader();
    let jsonObj = {
      address: this.address,
      zipcode: this.zipcode,
      city: this.city,
      country: this.country,
      state: this.state,
      isDefault: this.isDefault,
      stateCode: this.stateCode,
      partyMasterId: this.item.partyMasterId,
    }
    let res;
    console.log(jsonObj);
    res = await this.httpApiService.updateAddress(this.item.id, jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast(
          "success",
          data.message
        );
        await this.closeModal();
      }
    }
  }
}
