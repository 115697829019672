import { Component, Input, OnInit } from "@angular/core";
import {
  ModalController,
  AlertController,
  LoadingController,
  Events,
  NavParams
} from "@ionic/angular";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpApiService } from "src/app/services/http-api/http-api.service";
import { ProcessActionComponent } from "../processAction";

@Component({
  selector: 'app-other-office-stock-process',
  templateUrl: './other-office-stock-process.component.html',
  styleUrls: ['./other-office-stock-process.component.scss']
})
export class OtherOfficeStockProcessComponent implements ProcessActionComponent {
  @Input() data: any;

  public stockProcessType: string = 'stockUpload';
  public refreshGrid: Boolean = false;
  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 100;
  public selectedIds = []
  public items = []
  public count = 0;
  public columns = [];
  public ftpData = [];
  public page = "Other Office Stock Upload";
  public otherOfficeStockUploadList = []
  public toLocationidList = []
  public companyId = null;
  public listOfLocations = [];
  public taskType = 'Other Office Stock Upload';
  public statusList = [{
    id: 1,
    name: 'To do'
  }, {
    id: 2,
    name: 'To do1'
  }]
  public otherOfficeDataSource: any;
  public deleteOtherOfficeRequestDataSource: any;
  public processtherOfficeRequestDataSource: any;
  public searchValue: string = '';

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    this.getAllCompany();
    this.getAllOtherOfficeStockUploadList()
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "Id", prop: "id" },
    { name: "Date & Time", prop: "notificationDate" },
    { name: "Stock Data", prop: "taskInputJson" },
    { name: "status", prop: "status" },
    ]

    this.event.subscribe('Other Office Stock', (item) => {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Id", prop: "id" },
      { name: "Date & Time", prop: "notificationDate" },
      { name: "Stock Data", prop: "taskInputJson" },
      { name: "status", prop: "status" },

      ]
      this.fetch()
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Other Office Stock')
  }

  async getAllOtherOfficeStockUploadList() {
    this.otherOfficeDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        await this.configService.dismiss();
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getAllOtherOfficeStockUploadList(
            this.pageNumber,
            this.noOfRecords,
            this.taskType,
            this.companyId
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.otherOfficeStockUploadList = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.otherOfficeStockUploadList,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }


  async getAllOtherOfficeStockDeleteRequestList() {
    this.deleteOtherOfficeRequestDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        await this.configService.dismiss();
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getAllOtherOfficeStockDeleteRequestList(
            this.pageNumber,
            this.noOfRecords,
            this.taskType,
            this.companyId
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.otherOfficeStockUploadList = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalCount,
              groupCount: data.data.totalCount
            };
          }
        } else {
          return {
            data: this.otherOfficeStockUploadList,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async getAllProcessOfOtherOfficeStock() {
    this.processtherOfficeRequestDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        await this.configService.dismiss();
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getAllProcessOfOtherOfficeStock(
            this.pageNumber,
            this.noOfRecords,
            this.searchValue
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.otherOfficeStockUploadList = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalCount,
              groupCount: data.data.totalCount
            };
          }
        } else {
          return {
            data: this.otherOfficeStockUploadList,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  fetch() {
    if (this.stockProcessType == "stockUpload") {
      this.taskType = 'Other Office Stock Upload';
      this.getAllOtherOfficeStockUploadList()
    }
    if (this.stockProcessType == "deleteStock") {
      this.taskType = 'Delete Other Office Stock';
      this.getAllOtherOfficeStockDeleteRequestList()
    }

    if (this.stockProcessType == "requestToOtherofficeStock") {
      this.taskType = 'Other Office Stock Process';
      this.getAllProcessOfOtherOfficeStock()
    }
  }

  changeSegment(event) {
    if (event.detail.value == "stockUpload") {
      this.taskType = 'Other Office Stock Upload';
      this.getAllOtherOfficeStockUploadList()
    }
    if (event.detail.value == "deleteStock") {
      this.taskType = 'Delete Other Office Stock';
      this.getAllOtherOfficeStockDeleteRequestList()
    }
    if (event.detail.value == "requestToOtherofficeStock") {
      this.taskType = 'Other Office Stock Process';
      this.getAllProcessOfOtherOfficeStock()
    }
  }
  dropDownSelection(ev) {
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "company") {
      if (idList.length > 0) {
        this.companyId = idList[0]
      } else {
        this.companyId = null
      }
    }
  }

  async getAllCompany() {
    let res: any;
    // let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.listOfLocations = dataValue;
    }
  }

  async confirmChangeOtherOfficeStockState(item) {
    if ((item == 'Enable' || item == 'Disable') && this.selectedIds.length == 0) {
      await this.configService.showToast("error", 'Please select any record');
    } else {
      const alert = await this.alertCtrl.create({
        header: "Confirm!",
        message: `Are you sure you want to ${item} Other office stock?`,
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: blah => {
              console.log("Confirm Cancel: blah");
            }
          },
          {
            text: "Okay",

            handler: () => {
              console.log("Confirm Okay");
              this.changeOtherOfficeState(item);
            }
          }
        ]
      });

      await alert.present();
    }
  }

  async changeOtherOfficeState(state) {

    await this.configService.showLoader();
    let payload: any =
    {
      "taskType": "Other Office Stock Upload"
    }
    if (state == 'Enable') {
      payload['notificationMasterIds'] = this.selectedIds
      payload['fromStatus'] = 'To do1';
      payload['toStatus'] = 'To do';
    }
    if (state == 'Disable') {
      payload['notificationMasterIds'] = this.selectedIds
      payload['fromStatus'] = 'To do';
      payload['toStatus'] = 'To do1';
    }
    if (state == 'Enable All') {
      payload['notificationMasterIds'] = []
      payload['fromStatus'] = 'To do1';
      payload['toStatus'] = 'To do';
    }
    if (state == 'Disable All') {
      payload['notificationMasterIds'] = []
      payload['fromStatus'] = 'To do';
      payload['toStatus'] = 'To do1';
    }
    let res: any;

    res = await this.httpApiService.changeStateOfOtherOfficeStock(payload);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      // this.selectedIds = []
      this.getAllOtherOfficeStockUploadList()
      this.refreshGrid = true
      await this.configService.showToast("success", 'Schedule Stock Export updated successfully');
    }



  }

  async onselect(event) {
    if (event.selectedRowsData.length > 0) {
      this.selectedIds = event.selectedRowsData.map(el => el.id)
    } else {
      this.selectedIds = []
    }
    console.log(this.selectedIds)
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true
  }

}
