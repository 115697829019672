import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-sales-inventory',
  templateUrl: './sales-inventory.component.html',
  styleUrls: ['./sales-inventory.component.scss']
})
export class SalesInventoryComponent implements ProcessActionComponent {
  @Input() data: any;

  public actionName: any;
  public toRole: any;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events
  ) { }

  async ngOnInit() {
    this.actionName = this.data.desc;
    await this.getDefaultSetting()
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", data);
      if (!!dataValue.map) {
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
      }
      console.log(this.toRole);
    }
  }

}
