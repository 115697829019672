import { Component, OnInit, Input } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ModalController, LoadingController } from "@ionic/angular";
import * as moment from "moment";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";

@Component({
  selector: 'app-scan-via-rfid',
  templateUrl: './scan-via-rfid.component.html',
  styleUrls: ['./scan-via-rfid.component.scss']
})
export class ScanViaRFIDComponent implements OnInit {
  @Input() process: any;
  @Input() actionId: any;
  @Input() transType: any;
  @Input() issueWithType: any;
  @Input() stoneList: any;
  @Input() skipAssortment: any;
  @Input() page: any;

  public RFidList: any = "";
  public scannedRFid = [];
  public type: any;
  public userId: any;
  public toUser: any;
  public toRole: any;
  public listOfUsers: any = [];
  public filtered: any = [];
  public count: number = 0;
  public matchCount: number = 0;
  public voucherBookId: any;
  public timer = 10;
  public msgStatus = false;
  public boardBaseUrl: any;
  public fromRole: any;
  public boxDetail: any = [];
  public stoneNotFoundRfidList = [];
  public companyId: any;
  public toDepartment: any;

  public scanCount: number = 0;

  constructor(public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    private authService: AuthenticationService,
    public exportAsExcelService: ExportAsExcelService,) { }

  async ngOnInit() {
    await this.getUserData();
    this.companyId = await this.authService.getCompanyId();
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
  }

  async scanHere() {
    this.filtered = await this.getRFIDList();
    this.matchCount = 0;
    this.scanCount = 0;
    this.boxDetail = [];
    let res;
    if (!!this.boardBaseUrl) {
      await this.configService.showLoader();
      await this.httpApiService.scanLedOff(this.boardBaseUrl, [1]);
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      if (data.type == "error") {
        this.configService.dismiss();
        await this.configService.showToast("error", data.type);
      } else {
        if (data.result.length > 0) {
          data.result = data.result.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          this.scannedRFid = data.result;
          let jsonObj;
          jsonObj = {
            "paramName": "rfid",
            setOfValues: data.result,
            companyId: this.companyId
          }
          let res2 = await this.httpApiService.getStoneIdByRfid(jsonObj);
          let resData = res2.json();
          this.boxDetail = resData.data.boxRfIdsList;
          this.scanCount = this.scannedRFid.length;
          if (this.boxDetail.length > 0) {
            this.scanCount = this.scanCount - this.boxDetail.length;
          }
          let foundRfidList = this.scannedRFid.filter(item => this.filtered.filter(el => el == item).length > 0)
          this.matchCount = foundRfidList.length || 0;
          this.configService.dismiss();

        } else {
          await this.configService.showToast("error", `Stones not found on Scanner Board!`);
          this.configService.dismiss();
        }
      }
    } else {
      await this.configService.showToast("error", "Scanner Board Url not found");
    }
  }


  async findByLED() {
    this.filtered = await this.getRFIDList();
    if (this.filtered.length > 0) {
      if (!!this.boardBaseUrl) {
        await this.configService.showLoader();
        let res: any;
        res = await this.httpApiService.scanWithLightOn(this.boardBaseUrl, this.filtered);
        console.log(res);
        // let data = res.json();
        console.log("findBy LED", res);
        this.configService.dismiss();
      }
      else {
        await this.configService.showToast("error", "Scanner Board Url not found");
      }
    } else {
      await this.configService.showToast("error", "Please add stone!");
    }
  }

  async LedOff() {
    this.filtered = await this.getRFIDList();
    if (!!this.boardBaseUrl) {
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.scanLedOff(this.boardBaseUrl, this.filtered);
      console.log(res);
      await this.loadingController.dismiss()
    } else {
      await this.configService.showToast("error", "Scanner Board Url not found");
    }
  }


  async getRFIDList() {
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    return this.filtered
  }
}
