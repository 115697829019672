import { Component, Input } from '@angular/core';
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: 'app-search-mix-stone',
  templateUrl: './search-mix-stone.component.html',
  styleUrls: ['./search-mix-stone.component.scss']
})
export class SearchMixStoneComponent implements ProcessActionComponent {
  @Input() data: any;
  public query: any;
  public type = "MIX";
  public department = "Sales";
  public disable: any = [];
  public cart = true;
  public default = {
    criteria: true,
    search: true,
    color: true,
    shape: true,
    size: true,
    clarity: true,
    internalStatus: true,
    download: true,
    colorOverride: true,
    clarityOverride: true,
    party:true,
    availableForSale: false,
    certiDate: false,
    inwardDate: true,
    transactionDate: true,
    standardShapeCode: true
  };

  constructor() { }

  ngOnInit() {
    let name = [];
    let departmentName = [];
    departmentName = ["Sales", "KAM Sales"];
    name.push(this.type);
    this.disable.push("transType");
    this.disable.push("department");
    this.query = {
      // facetFilters: ["transType:" + this.type, "department:" + this.department],
      disjunctiveFacetsRefinements: {
        transType: name,
        department: departmentName
      }
    };
  }
}
