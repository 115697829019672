import { Component, Input, ViewChild } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { LoadingController, ModalController, Events } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-stone-finalization',
  templateUrl: './stone-finalization.component.html',
  styleUrls: ['./stone-finalization.component.scss']
})
export class StoneFinalizationComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild("dataTable") public dataTable: any;

  public value = '';
  public listOfParameters = [];
  public selected = [];
  public selectedParamvalue = [];
  public columns = [];
  public listOfLogs = [];
  public actionLogsColumn = [];

  constructor(private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events) { }

  ngOnInit() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "Type", prop: "type" },
    { name: "Lab", prop: "lab" },
    { name: "Final", prop: "isFinal" },
    { name: "StoneId", prop: "stoneName" },
    { name: "Color", prop: "ColorCode" },
    { name: "Shape", prop: "ShapeCode" },
    { name: "Ct.", prop: "cts" },
    { name: "Clarity", prop: "ClarityCode" },
    { name: "Cut", prop: "CutCode" },
    { name: "Polish", prop: "PolishCode" },
    { name: "Symmetry", prop: "SymmetryCpde" },
    { name: "Fluorescence", prop: "FluorescenceCode" },
    ];

    this.actionLogsColumn = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "User Name", prop: "userName" },
    { name: "Description", prop: "description" },
    { name: "Date & Time", prop: "createdDate" },
    ]

    this.event.subscribe('Stone Finalization', (item) => {
      this.value = '';
      this.listOfParameters = [];
    })
  }


  ngOnDestroy() {
    this.event.unsubscribe('Stone Finalization');
  }

  async findAllParamValue() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.getAllparameterFromValue(this.value);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.selected = []
        this.listOfParameters = data.data;
      }
    }
  }

  async getUserActionLogs() {
    let res: any;
    res = await this.httpApiService.getUserActionLogs('Stone Finalization');
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLogs = data.data;
    }
  }



  async updateStoneParameter() {
    await this.configService.showLoader();
    let jsonData = {
      stoneId: this.selected[0].id,
      labId: this.selected[0].lab,
      type: !!this.selected[0].type ? this.selected[0].type : null,
    }
    if (!jsonData.labId) {
      delete jsonData.labId
    }
    if (!jsonData.type) {
      delete jsonData.type
    }
    let res: any;
    res = await this.httpApiService.updateStoneParameter(jsonData);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.listOfParameters = [];
      this.value = '';
    }
  }

  onSelect(ev) {
    this.selected = ev.selectedRowsData;
  }


}
