import { Component, Input } from "@angular/core";
import {
  ModalController,
  AlertController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { RapaportParameterMappingMasterPage } from "../../actions/rapaport-parameter-mapping-master/rapaport-parameter-mapping-master.page"
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
@Component({
  selector: 'app-rapaport-mapping',
  templateUrl: './rapaport-mapping.component.html',
  styleUrls: ['./rapaport-mapping.component.scss']
})
export class RapaportMappingComponent implements ProcessActionComponent {
  @Input() data: any;
  public listOfMappingParamsDataSource: any = {};
  public columns = [];
  public page = 'rapaportMapping';
  public listOfMappingParams = [];
  public pageNumber = 0;
  public noOfRecords = 500;
  public pageSize = 500;
  public items = []
  public count = 0;
  public totalPages: Number = 0;
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public manageSelectedMapping: any;
  public refreshGrid: boolean = false;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) {

    this.deleteItem = this.deleteItem.bind(this);
  }


  ngOnInit() {
    console.log(this.data.listOfActionPermissions)
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    this.columns = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "KG Parameter Name", prop: "masterName" },
      { name: "KG Value", prop: "masterValue" },
      { name: "Rapaport Mapping Value", prop: "mapValue" },
    ]
    // if (this.deletePermissionFlag == true) {
    //   this.columns.push({ name: "Action", prop: "" })
    // }
    this.getAllParamMapping(this.pageNumber, this.noOfRecords)
    this.event.subscribe('RAPAPORT Mapping', (item) => {
      this.getAllParamMapping(this.pageNumber, this.noOfRecords)
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('RAPAPORT Mapping');
  }

  async getAllParamMapping(pageNumber, noOfRecords) {

    this.listOfMappingParamsDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await await this.httpApiService.getAllRapoParameterMapping(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfMappingParams = data.data.content;
            this.setItems();
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfMappingParams,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res: any;
    // res = await this.httpApiService.getAllRapoParameterMapping(pageNumber, noOfRecords);
    // let data = res.json();
    // if (data.status == 401) {
    //   await this.authService.logout()
    // } else if (data.status == 500) {
    //   await this.configService.showToast('error', data.error)
    // } else if (data.status == 400) {
    //   await this.configService.showToast('error', data.error)
    // } else {
    //   this.listOfMappingParams = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    //   this.setItems()
    // }
  }

  async setItems() {
    this.items = this.listOfMappingParams;
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getAllParamMapping(currentPage.offset, this.noOfRecords);
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.listOfMappingParamsDataSource = new CustomStore({
        key: "id",
        load: async (loadOptions: any) => {
          console.log("loadOptions ", loadOptions);
          if (!!loadOptions.take) {
            loadOptions.take = this.noOfRecords;
            this.pageNumber = loadOptions.skip / loadOptions.take;
            let res: any;
            res = await await this.httpApiService.searchRapoParameterMapping(val, this.pageNumber, this.noOfRecords);
            let data = res.json();
            if (res.status == 401) {
              this.authService.logout();
            } else if (res.status == 500) {
              await this.configService.showToast("error", data.error);
            } else if (res.status == 400) {
              await this.configService.showToast("error", data.error);
            } else {
              this.listOfMappingParams = data.data.content;
              this.setItems();
              return {
                data: Query(data.data.content)
                  .toArray(),
                totalCount: data.data.totalElements,
                groupCount: data.data.totalElements
              };
            }
          } else {
            return {
              data: this.listOfMappingParams,
              totalCount: this.count,
              groupCount: this.count
            }
          }
        }
      });
      // let res: any;
      // res = await this.httpApiService.searchRapoParameterMapping(val, this.pageNumber, this.noOfRecords);
      // let data = res.json();
      // if (data.status == 401) {
      //   await this.authService.logout()
      // } else if (data.status == 500) {
      //   await this.configService.showToast('error', data.error)
      // } else if (data.status == 400) {
      //   await this.configService.showToast('error', data.error)
      // } else {
      //   this.listOfMappingParams = data.data.content;
      //   this.totalPages = data.data.totalPages;
      //   this.count = data.data.totalElements;
      //   this.setItems()
      // }
    } else {
      await this.getAllParamMapping(this.pageNumber, this.noOfRecords);
    }
  }

  async manageMappingMaster(item, flag) {
    this.refreshGrid = false;
    if (flag == 'update' && this.updatePermissionFlag == true) {
      let modal = await this.modalCtrl.create({
        component: RapaportParameterMappingMasterPage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: { item: item.row, flag: flag, }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
    if (flag == 'add' && this.addPermissionFlag == true) {
      let modal = await this.modalCtrl.create({
        component: RapaportParameterMappingMasterPage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: { item: item, flag: flag, }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    if (d.data != 'doNotRefresh') {
      await this.getAllParamMapping(this.pageNumber, this.noOfRecords);
    }
  }

  async deleteConfirm(item) {
    console.log(item)
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Mapping ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay" + item);
            this.deleteParamMapping(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteParamMapping(item) {
    let res: any;
    res = await this.httpApiService.deleteRapoParameterMapping(item.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.getAllParamMapping(this.pageNumber, this.noOfRecords);
    }
  }

  async dispaySrNo(row) {
    console.log(row)

    return row.rowIndex + 1;
  }

  async deleteItem(ev) {
    this.deleteConfirm(ev.row.data);
  }

}
