import { Component, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManagePolicyGeneratorPage } from "../manage-policy-generator/manage-policy-generator.page";
import { ManageFilterComponent } from "../manage-filter/manage-filter.component";
import { ManageRulesPage } from "../manage-rules/manage-rules.page";
import { FixedPolicyMatrixPage } from "../fixed-policy-matrix/fixed-policy-matrix.page";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import * as JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import * as FileSaver from 'file-saver';


@Component({
  selector: "app-pricing-policy",
  templateUrl: "./pricing-policy.component.html",
  styleUrls: ["./pricing-policy.component.scss"]
})
export class PricingPolicy implements ProcessActionComponent {
  @Input() data: any;
  public manageSelectedPolicy: "";
  public selectedPolicy: "";
  // public items: any;
  public policyName = "";
  public startFrom: any;
  public startFromList: any;
  public allStartFromIdList: any = [];
  public arrowKeyLocation = -1;
  public policies: any;
  public policyDetails: any;
  public id: any;
  public flgBtn = "";
  public blocks: any;
  public hits: any;
  public editing = {};
  public view = "Pricing";
  public page = "pricingPolicy"
  public items = [];
  public pageNumber = 0;
  public noOfRecords = 25;
  public columns = []
  public count = 0;
  public loadBlockData = [];
  public blockItems = [];
  public pricePolicyMasterId: any;
  public totalPages: number;
  public blockId: any;
  public blockOrder: number = 1;
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public policyColumns = [];
  public pageSize = 50;
  public rulesSource: any;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) {
    this.blocks = [{ id: 1 }, { id: 2 }];
    this.columns = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Name", prop: "name" },
      { name: "Disc % / Fixed Price", prop: "" },
    ];
  }

  async ngOnInit() {
    this.policyColumns = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Policy Name", prop: "name" },
      { name: "Status", prop: "status" },
      { name: "Publish", prop: "" },
      { name: "Not Applied", prop: "status" },
      { name: "Published Date", prop: "modifiedDate" },
      { name: "Modified By", prop: "modifiedBy" },
      { name: "Action", prop: "" }
    ];
    this.selectedPolicy = "";
    this.blockOrder = 1;
    console.log(this.data.listOfActionPermissions)
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    await this.getAllPolicies();
    this.event.subscribe('Pricing Policy', (item) => {
      if (this.view == 'Pricing') {
        this.getAllPolicies();
      } else if (this.view == 'Policy') {
        this.getAllBlockByPolicy();
      } else {
        this.getBlockDataById(this.blockId, this.pageNumber, this.noOfRecords)
      }
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Pricing Policy');
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "allStartFrom") {
      if (idList.length > 0) {
        this.startFrom = idList[0];
      } else {
        this.startFrom = null;
      }
    }
  }

  // GET ALL POLICIES

  async getAllPolicies() {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getAllPolicy();
    let data = res.json();
    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.policies = data.data;
      this.count = data.data.length;
      this.setItems();
      console.log("get policies", this.policies);
    }
  }

  setItems() {
    this.items = this.policies;
  }

  async filterItems(ev: any) {
    this.selectedPolicy = "";
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        if (item.name && item.name.toLowerCase().includes(val.toLowerCase()))
          return true;
      });
    } else {
      this.setItems();
    }
  }

  //END

  //PUBLISH OR REMOVE POLICY

  async onActivate(event) {
    this.pricePolicyMasterId = event.row.id;
    this.policyName = event.row.name;
    this.view = "Policy";
    this.loadBlockData = [];
    await this.getAllBlockByPolicy();
  }

  async publishPolicy(id) {
    await this.configService.showLoader();
    let res: any;
    let jsonObj = {
      pricePolicyMasterId: id
    }
    res = await this.httpApiService.savePolicy(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.getAllPolicies()
    }
  }

  async findStonesListOfNotAppliedPolicy(event) {
    let id = event.id;
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findStonesListOfNotAppliedPolicy(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      console.log(data.data)
      window.open(await this.configService.getBaseUrl() + data.data, "_blank");
    }
  }

  //END

  //GET BLOCKS BY POLICY

  async getAllBlockByPolicy() {
    this.loadBlockData = []
    let res: any;
    await this.configService.showLoader();
    res = await this.httpApiService.getAllBlockByPolicyId(this.pricePolicyMasterId);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      this.loadBlockData = data.data;
      if (this.loadBlockData.length > 0) {
        this.blockOrder = parseInt(this.loadBlockData[this.loadBlockData.length - 1].blockOrder) + 1
      }
    }
  }

  //GET RULES BY BLOCK START

  // async getBlockDataById(id, pageNumber, noOfRecords) {
  //   this.blockId = id;
  //   this.view = "blockDataUpdate";
  //   await this.configService.showLoader()
  //   let res: any;
  //   res = await this.httpApiService.getBlockDataById(id, pageNumber, noOfRecords);
  //   let data = res.json();
  //   await this.configService.dismiss();
  //   if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     console.log(data.data)
  //     let dataValue = data.data;
  //     this.blockItems = dataValue.content;
  //     this.totalPages = dataValue.totalPages;
  //     this.count = dataValue.totalElements;
  //   }
  // }

  async getBlockDataById(id, pageNumber, noOfRecords) {
    this.blockId = id;
    this.view = "blockDataUpdate";
    this.rulesSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getBlockDataById(id, this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.blockItems = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.blockItems,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async setRulePage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getBlockDataById(this.blockId, currentPage.offset, this.noOfRecords);
  }

  //END

  //ADD POLICY START

  async gotoManagePolicyPage(item, flgModal) {
    console.log(flgModal, this.pricePolicyMasterId);
    let modalPage = await this.modalCtrl.create({
      component: ManagePolicyGeneratorPage,
      cssClass: 'extraLargePage',
      backdropDismiss: false,
      componentProps: {
        // id: id,
        // username: this.selectedUser,
        pricePolicyMasterId: this.pricePolicyMasterId,
        flgModal: flgModal,
        blockOrder: this.blockOrder,
        item: item
        //voucherBook: this.listOfVoucherBook
      }
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();

  }

  async gotoManageFilterPage() {
    const modalPage = await this.modalCtrl.create({
      component: ManageFilterComponent,
      backdropDismiss: false,
      componentProps: {
        search: true,
        statusSelected: [],
        publish: [true, false],
        publishDate: [new Date(), new Date()]
      }
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();

  }

  async handleModalDismiss(d) {
    console.log(d)
    if (d.data == 'addBlock' || d.data == 'updateBlock') {
      this.getAllBlockByPolicy();
    } else if (d.data == 'addPolicy' || d.data == 'updatePolicy') {
      this.getAllPolicies();
    } else {

    }

  }

  //END

  // Add OR Update Rule Start

  async ManageRules(item, flag) {
    if ((item.type == "mouseenter") || (item.column && item.column.name == 'Delete')) {
      //Stop event propagation and let onSelect() work
      item.event.stopPropagation();
    } else {
      let modalPage = await this.modalCtrl.create({
        component: ManageRulesPage,
        backdropDismiss: false,
        componentProps: { item: item, flag: flag, blockId: this.blockId },
        cssClass: "largePage"
      })
      modalPage.onDidDismiss().then((d: any) => this.handleRuleModalDismiss(d));
      await modalPage.present();
    }
  }

  async handleRuleModalDismiss(d) {
    await this.getBlockDataById(this.blockId, this.pageNumber, this.noOfRecords);
  }

  //END

  //fixed Policy

  async AddFixedBlock() {
    let modalPage = await this.modalCtrl.create({
      component: FixedPolicyMatrixPage,
      backdropDismiss: false,
      componentProps: {},
      cssClass: "extraLargePage"
    })
    // modalPage.onDidDismiss().then((d: any) => this.handleRuleModalDismiss(d));
    await modalPage.present();
  }

  //Start Delete Policy 

  async confirmDeletePolicy(id) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Policy ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deletePolicy(id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deletePolicy(id) {
    let res: any;
    res = await this.httpApiService.deletePolicy(id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.getAllPolicies();
    }
  }

  //END

  //Start Delete Block

  async confirmDeleteBlock(id) {
    console.log("!!!!!!!!!!!!!!!!!!!!!!", id)
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Block ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteBlock(id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteBlock(id) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.deleteBlock(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.getAllBlockByPolicy();
    }
  }

  //END

  async changeView(view) {
    this.view = view;
  }

  //VIEW CHANGE

  async back(view) {
    if (view == "blockDataUpdate") {
      this.blockItems = [];
      this.view = "Policy";
    } else if (view == "Policy") {
      this.view = "Pricing";
      await this.getAllPolicies();
    }
  }

  //Download Block File

  async downloadFile(id) {
    let res: any;
    res = await this.httpApiService.downloadUploadedFile(id);
  }

  async changePosition(event) {
    let obj = {};
    let i = 1;
    event.map(res => { obj[res.id] = i++; });
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.updateOrder({
      "mapOfBlockAndOrder": obj
    });
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
    }
  }

  exportAsZip() {
    this.createZip(this.loadBlockData);
  }

  async createZip(files: any[]) {
    let loading = await this.loadingController.create({
      message: "Please Wait.."
    });
    await loading.present();
    const zip = new JSZip();
    const name = this.policyName + '.zip';
    let count = 0;
    files.forEach(async function (item) {
      try {
        const file = await JSZipUtils.getBinaryContent(`https://lattice-app.com/master-service/api/stone/csvDownload?fileName=${item.csvUploadHistoryId}`)
        zip.file(`${item.name}.csv`, file, { binary: true });
        count++;
        if (count === files.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            FileSaver.saveAs(content, name);
          });
          await loading.dismiss();
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  async getFile(url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    const res = await this.httpApiService.downloadFileByUrl(url)
    console.log(res._body)
    return res._body;
  }

}
