import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: 'app-assortment-search',
  templateUrl: './assortment-search.component.html',
  styleUrls: ['./assortment-search.component.scss']
})
export class AssortmentSearchComponent implements ProcessActionComponent {
  @Input() data: any;
  public query: any;
  public default = {
    criteria: true,
    search: true,
    shape: true,
    carat: true,
    color: true,
    colorOverride: false,
    clarity: true,
    clarityOverride: false,
    description: true,
    finishing: true,
    fluorescence: true,
    lab: true,
    size: false,
    internalStatus: true,
    externalStatus: true,
    transType: true,
    users: true,
    location: true,
    box: true,
    department: true,
    saveSearch: true,
    download: true,
    fm: true,
    party: true,
    availableForSale: true,
    certiDate: false,
    inwardDate: true,
    transactionDate: true,
    standardShapeCode: true
  };
  public department = "Assortment";
  public disable: any = [];
  public cart = false;

  constructor() { }

  ngOnInit() {
    let departmentName = [];
    departmentName.push(this.department);
    this.disable.push("department");
    this.query = {
      // facetFilters: ["department:" + this.department],
      disjunctiveFacetsRefinements: {
        department: departmentName
      }
    };
  }
}
