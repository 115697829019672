import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { Events } from "@ionic/angular"
import * as moment from "moment";
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.js';
import saveAs from 'file-saver';
import { DecimalPipe } from '@angular/common';
import DataGrid from "devextreme/ui/data_grid";

@Component({
  selector: 'app-dev-extreme-with-pagination',
  templateUrl: './dev-extreme-with-pagination.component.html',
  styleUrls: ['./dev-extreme-with-pagination.component.scss']
})
export class DevExtremeWithPaginationComponent implements OnInit {
  dataGridInstance: DataGrid;
  @Input() columns: any;
  @Input() rowData: any;
  @Input() mode: string;
  @Input() pageNumber: any;
  @Input() noOfRecords: any;
  @Input() statusList: [];
  @Input() stoneStatusList: [];
  @Input() tab: any;
  @Input() count: any;
  @Input() page: any;
  @Input() searchPanelEnable: any;
  @Input() pageSize: any = 50;
  @Input() showPager: boolean = true;
  @Input() totalSummary: any;
  @Input() groupTotalSummary: any;
  @Input() exportEnable: any = true;
  @Input() noRemoteOperations: any;
  @Input() deletePermissionFlag: any;
  @Input() showBarcodeBtn: any;
  @Input() showDownload: boolean = false;
  @Input() showComparision: boolean = false;
  @Input() showPdResultIconColumn: any;
  @Input() option: any;
  @Output() callFunction: EventEmitter<any> = new EventEmitter();
  @Output() pageFunction: EventEmitter<any> = new EventEmitter();
  @Output() deleteFunction: EventEmitter<any> = new EventEmitter();
  @Output() showData: EventEmitter<any> = new EventEmitter();
  @Output() scrollFunction: EventEmitter<any> = new EventEmitter();
  @Output() onClickToDeleteItem: EventEmitter<any> = new EventEmitter();
  @Output() openBarcodeFunction: EventEmitter<any> = new EventEmitter();
  @Output() onClickToOpenAssortPdResult: EventEmitter<any> = new EventEmitter();
  @Output() downloadFileData: EventEmitter<any> = new EventEmitter();
  @Output() downloadComparisonFileData: EventEmitter<any> = new EventEmitter();
  @Input() refreshGrid: boolean = false;
  @Input() deferred: boolean = false;
  @Input() tableId: string = 'gridContainer';
  @Input() allowedPageSizes = [10, 20, 50, 100, 500];


  public selected: any = [];
  public myMesssages = { emptyMessage: "No data to display", totalMessage: "records" }
  public headerHeight = 50;
  public rowHeight = 30;
  public hotSettings;
  public id = 'hotInstance';
  public hooks;
  public columnName: any;
  public selectedRows = 0;

  constructor(public event: Events,
    private el: ElementRef, public _decimalPipe: DecimalPipe
  ) {
    this.deleteItem = this.deleteItem.bind(this)
    this.customCt = this.customCt.bind(this)
    this.showBarcode = this.showBarcode.bind(this)
    this.displayResultIconClick = this.displayResultIconClick.bind(this)
    this.customCtsValue = this.customCtsValue.bind(this);
    this.customCtBox = this.customCtBox.bind(this);
    this.customCtOut = this.customCtOut.bind(this)
    this.customCtPkOnly = this.customCtPkOnly.bind(this);
    this.downloadFile = this.downloadFile.bind(this)
    this.downloadComparisonFile = this.downloadComparisonFile.bind(this)
  }

  ngOnChanges(changes) {
    if (!!changes.refreshGrid && !!changes.refreshGrid.currentValue && !!this.dataGridInstance) {
      this.refresh();
    }
  }

  saveGridInstance(e) {
    this.dataGridInstance = e.component;
  }

  refresh() {
    this.dataGridInstance.clearSelection();
  }

  ngOnInit() {
  }

  onContentReady(event: any) {
    console.log("onContentReady");
    console.log(
      "datagrid sort",
      this.dataGridInstance.getDataSource().sort()[0]
    );
    // Sorting is set on the key instead of the given sorting.
    // Both columns are not showing any sorting
    // if I set the [focusedRowEnabled] option to false, sorting works fine
  }

  beforeOnCellMouseDownFunc = (event, coords, element) => {
    console.log("beforeOnCellMouseDownFunc ", event, coords, element);
    if (coords.row < 0) {
      event.stopImmediatePropagation();
    }
  };

  onCellClick(ev) {
    this.columnName = ev.column.caption;
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true
    }).then(function () {
      // https://github.com/exceljs/exceljs#writing-xlsx
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
      });
    });
    e.cancel = true;
  }

  onSelectionChanged(event) {
    console.log(event)
    event.row = event.selectedRowsData[0];
    event.columnName = this.columnName;
    console.log("onSelectionChanged event ", event);
    this.selectedRows = event.selectedRowsData.length
    this.call(event);
  }

  call(event) {
    //this.refresh();
    this.selected = event.row;
    if (this.selected) {
      this.event.publish("selectedValue", this.selected);
      console.log("selected", this.selected);
      if (event.type == 'click') {
        if (event.columnName != 'Barcode') {
          this.callFunction.emit(event);
        }
      } else {
        this.callFunction.emit(event);
      }
    }

  }

  customVoucher(data) {
    return data.shortCode + "-" + data.bookSerialNumber;
  }

  customPendingCt(data) {
    if (!!data.pendingCarat) {
      return parseFloat(data.pendingCarat.toFixed(3)) + "/ " + data.totalCarat;
    } else {
      if (!!data.totalCarat) {
        return parseFloat(data.totalCarat.toFixed(3)) + "/ " + data.totalCarat;
      }
    }
  }

  customPending(data) {
    if (!!data.pendingPackets) {
      return data.pendingPackets + "/ " + data.totalPackets;
    } else {
      return data.totalPackets + "/ " + data.totalPackets;
    }
  }

  calculateAmount(data) {
    console.log("DDDDD", data)
    if (!!data.requestAmt) {
      return data.requestAmt.toFixed(2) || 0;
    }
  }

  customPktsOut(data) {
    return data.pendingPackets + "/ " + data.totalPackets;
  }

  customCtOut(data) {
    let pendingCarat: any;
    let totalCarat: any;

    pendingCarat = this._decimalPipe.transform(data.pendingCarat, '.3-3')
    totalCarat = this._decimalPipe.transform(data.totalCarat, '.3-3')
    return pendingCarat + "/" + totalCarat;
  }

  customCtsValue(data) {
    let receivedCarat: any;
    let totalCarat: any;

    receivedCarat = this._decimalPipe.transform(data.receivedCarat, '.3-3')
    totalCarat = this._decimalPipe.transform(data.totalCarat, '.3-3')
    return receivedCarat + "/" + totalCarat;
  }

  customPktBox(data) {
    return data.totalPackets;
  }

  customCtBox(data) {
    if (data.totalCarat) {
      return this._decimalPipe.transform(data.totalCarat, '.3-3');
    } else {
      return data.totalCarat;
    }
  }

  customTransType(data) {
    if (!!data.transType) {
      return data.transType;
    } else {
      return 'SS/MIX'
    }
  }

  customPktsValue(data) {
    return data.receivedPackets + "/ " + data.totalPackets;
  }

  customUTCDateTime(data) {
    if (!!data.createdDate) {
      return moment.utc(data.createdDate).local().format('DD-MMM-YYYY hh:mm a');
    }
  }

  customDateTime(data) {
    if (!!data.modifiedDate) {
      return moment.utc(data.modifiedDate).local().format('DD-MMM-YYYY hh:mm a');
    }
    if (!!data.createdDate) {
      return moment.utc(data.createdDate).local().format('DD-MMM-YYYY hh:mm a');
    }
    if (!!data.priceListDate) {
      return moment.utc(data.priceListDate).local().format('DD-MMM-YYYY hh:mm a');
    }
    if (!!data.notificationDate) {
      return moment.utc(data.notificationDate).local().format('DD-MMM-YYYY hh:mm a');
    }
  }

  customModifiedDateTime(data) {
    if (!!data.modifiedDate) {
      return moment.utc(data.modifiedDate).local().format('DD-MMM-YYYY hh:mm a');
    }
  }

  customCreateDateTime(data) {
    if (!!data.createdDate) {
      return moment.utc(data.createdDate).local().format('DD-MMM-YYYY hh:mm a');
    }
  }

  customFWDate(data) {
    if (!!data.factoryOutWDate) {
      return moment(data.factoryOutWDate).format('DD-MMM-YYYY');
    }
  }

  customFWDateTime(data) {
    if (!!data.factoryOutWDate) {
      return moment(data.factoryOutWDate).format('DD-MMM-YYYY hh:mm a');
    }
  }

  customVoucherDateTime(data) {
    if (!!data.voucherDate) {
      return moment(data.voucherDate).format('DD-MMM-YYYY hh:mm a');
    }
  }


  customDate(data) {
    if (!!data.createdDate) {
      return moment(data.createdDate).format("DD-MMM-YYYY");
    }
    if (!!data.modifiedDate) {
      return moment(data.modifiedDate).format("DD-MMM-YYYY");
    }
  }

  customTime(data) {
    if (!!data.createdDate) {
      return moment(data.voucherDate).format('DD-MMM-YYYY hh:mm a');
    }
  }

  customValueForCloseButton(data) {
    if (data.status == 'Picked' || data.status == 'Requested') {
      return true
    } else {
      return "";
    }
  }

  showStoneId(data) {
    let stoneId = data.fromStoneId;

    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let party = stoneId.split('_');
      return party[0];
    } else {
      return stoneId
    }
  }

  showFROMCSSP(data) {
    let stoneId = data.fromCsspStoneId;

    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let party = stoneId.split('_');
      return party[0];
    } else {
      return stoneId
    }
  }

  showTOCSSP(data) {
    let stoneId = data.toCsspStoneId;

    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let party = stoneId.split('_');
      return party[0];
    } else {
      return stoneId
    }
  }

  customPartyName(row) {
    if (row.requestFor == 'Certification') {
      return row.partyName
    } else if (row.requestFor == 'Requisition' || row.requestFor == 'Memo Client' || row.requestFor == 'Consignment Confirm' || row.requestFor == 'Consignment In Stock' || row.requestFor == 'Customer Confirm' || row.requestFor == 'Sold') {
      return row.partyName
    } else if (row.toUser) {
      return row.toUser
    } else {
      return '-';
    }
  }

  customPkts(data) {
    if (data.requestFor == 'Requisition') {
      return data.pendingPackets
    } else {
      return data.totalPackets
    }
  }

  customCt(data) {
    let val: any;
    if (data.requestFor == 'Requisition') {
      val = data.pendingCarat;
    } else {
      val = data.totalCarat;
    }
    return this._decimalPipe.transform(val, '.3-3');
  }

  customPendingCtAss(data) {

  }

  customAssignedPkts(data) {

  }

  deleteItem(event) {
    this.deleteFunction.emit(event);
  }

  showBarcode(row) {
    this.openBarcodeFunction.emit(row);
  }

  displayResultIconClick(event) {
    this.onClickToOpenAssortPdResult.emit(event)
  }

  customStoneId(row) {
    let stoneId = row.stoneId
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  customPktsPkOnly(row) {
    return row.intermediatePackets + "/" + row.totalPackets
  }

  customCtPkOnly(data) {
    let intermediateCarat: any;
    let totalCarat: any;

    intermediateCarat = this._decimalPipe.transform(data.intermediateCarat, '.3-3')
    totalCarat = this._decimalPipe.transform(data.totalCarat, '.3-3')
    return intermediateCarat + "/" + totalCarat;
  }

  downloadFile(data) {
    this.downloadFileData.emit(data.row.data);
  }

  downloadComparisonFile(row) {
    this.downloadComparisonFileData.emit(row);
  }

  customDateTimeForExportInitiated(data) {
    if (!!data.createdDate) {
      return moment.utc(data.createdDate).local().format('DD-MMM-YYYY hh:mm a');
    }
  }

  customDateTimeForExportFinished(data) {
    if (!!data.endDate) {
      return moment.utc(data.endDate).local().format('DD-MMM-YYYY hh:mm a');
    }
  }

}
