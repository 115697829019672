import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import {
  ModalController,
  ToastController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ManageRolesPage } from "../manage-roles/manage-roles.page";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManagePolicyChainPage } from "../../actions/manage-policy-chain/manage-policy-chain.page";

@Component({
  selector: 'app-policy-chain',
  templateUrl: './policy-chain.component.html',
  styleUrls: ['./policy-chain.component.scss']
})
export class PolicyChainComponent implements ProcessActionComponent {
  @Input() data: any;

  public columns = [];
  public listOfChains = []
  public count = 0;
  public items = []
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public page = "policyChain";
  public manageSelectedChain: any;
  public refreshGrid: Boolean = false;
  public deleteBtn: Boolean = false;

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public configService: ConfigService,
    public event: Events) {
  }

  ngOnInit() {
    this.getAllPolicyChain()
    console.log(this.data.listOfActionPermissions)
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      }, { name: "Name", prop: "name" }, { name: "Start Price", prop: "startPriceListName" },
      { name: "End Price", prop: "endPriceListName" },
      { name: "Last Applied", prop: "appliedDate" },
      { name: "Modified Date", prop: "modifiedDate" },
      { name: "Status", prop: "status" }, { name: "Modified By", prop: "modifiedBy" }, { name: "Reset", prop: "" }, { name: "Apply", prop: "" }];

      if (this.deletePermissionFlag == true) {
        this.deleteBtn = true
      }
    }
    this.event.subscribe('Pricing Policy Chain', (item) => {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Name", prop: "name" },
      { name: "Start Price", prop: "startPriceListName" },
      { name: "End Price", prop: "endPriceListName" },
      { name: "Last Applied", prop: "appliedDate" },
      { name: "Modified Date", prop: "modifiedDate" },
      { name: "Status", prop: "status" },
      { name: "Modified By", prop: "modifiedBy" }, { name: "Reset", prop: "" }, { name: "Apply", prop: "" }];
      if (this.deletePermissionFlag == true) {
        this.deleteBtn = true
      }
      this.getAllPolicyChain()
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Pricing Policy Chain');
  }

  async manageChain(item, flag) {
    console.log(item)
    this.refreshGrid = false;
    if ((flag == 'Apply') && (item && (!item.status || item.status == 'Error' || item.status == 'Done'))) {
      flag = 'addCriteria'
      item['row'] = item
      let modal = await this.modalCtrl.create({
        component: ManagePolicyChainPage,
        backdropDismiss: false,
        cssClass: "requestPage",
        componentProps: { item: item, flag: flag, listOfChains: this.listOfChains }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    } else {
      if (item.row) {
        if (flag == 'update' && this.updatePermissionFlag == true) {
          let modal = await this.modalCtrl.create({
            component: ManagePolicyChainPage,
            backdropDismiss: false,
            cssClass: "largePage",
            componentProps: { item: item, flag: flag, }
          })
          modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
          return await modal.present();
        }

      } else {
        if (flag == 'add' && this.addPermissionFlag == true) {
          let modal = await this.modalCtrl.create({
            component: ManagePolicyChainPage,
            backdropDismiss: false,
            cssClass: "largePage",
            componentProps: { item: item, flag: flag, }
          })
          modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
          return await modal.present();
        }
      }
    }
  }

  async filterItems(ev: any) {
    let val = ev.target.value;
    console.log(val)
    await this.setItems();
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        if (item.name && item.name.toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (item.startPriceListName && item.startPriceListName.toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (item.endPriceListName && item.endPriceListName.toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (item.status && item.status.toLowerCase().includes(val.toLowerCase())) {
          return true;
        }
      });
      this.count = this.items.length;
    } else {
      await this.setItems();
    }
  }

  async getAllPolicyChain() {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getAllPolicyChain();
    let data = res.json();
    await this.loadingController.dismiss()
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      if (data.data.length > 0) {
        this.listOfChains = data.data;
        this.count = this.listOfChains.length;
        this.setItems();
      }
    }
  }

  setItems() {
    this.items = this.listOfChains;
    this.count = this.listOfChains.length;
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    if (d.data != 'doNotRefresh') {
      await this.getAllPolicyChain();
    }
  }

  //Start Delete Policy 

  async confirmDeleteChain(item) {
    console.log(item.row.data.id);
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Chain ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteChain(item.row.data.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteChain(id) {
    console.log(id)
    let res: any;
    res = await this.httpApiService.deletePolicyChain(id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.getAllPolicyChain();
    }
  }

  //Start reset chain  

  async confirmResetChain(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to reset this Chain ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.resetChain(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async resetChain(id) {
    console.log(id)
    let res: any;
    res = await this.httpApiService.resetPolicyChain(id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.getAllPolicyChain();
    }
  }


}
