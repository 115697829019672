import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AlertController,
  Events,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { default as DataGrid, default as dxDataGrid } from "devextreme/ui/data_grid";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpApiService } from "src/app/services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";
import {ManageLayoutsComponent} from "../manage-layouts/manage-layouts.component"

@Component({
  selector: "app-layouts",
  templateUrl: "./layouts.component.html",
  styleUrls: ["./layouts.component.scss"],
})
export class LayoutsComponent implements ProcessActionComponent {
  @Input() data: any;
  public selectedRole = "";
  @Input() selected: any = [];
  @Input() refreshGrid: boolean = false;
  @Input() refreshMobileGrid = false;
  dataGridInstance: DataGrid;

  public manageSelectedRole = "";
  listOfLayouts :any;
  public arrowKeyLocation = -1;
  public selectedRows = 0;
  public flagButton = "";
  public selectedRowKeys = [];
  propsForItem: {};
  public itemsActions: any;
  public listOfActions: any;
  public id: any;
  public roleName = "";
  public description: "";
  @Output() callFunction: EventEmitter<any> = new EventEmitter();

  items: any;
  public roleId: any;
  selectedRoleId: any;
  selectedDescription: any;
  public searchPermission: string = "";
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public columnName: any;
  categoryItems: any;
  categoryItemData: any;
  dataForUpdateApi: any;
  categoryIdforDelete: any;
  isActiveForCategory: any;
  constructor(
    private authService: AuthenticationService,
    private httpApiService: HttpApiService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private loadingController: LoadingController,
    private configService: ConfigService,
    private event: Events
  ) {}

  ngOnInit() {
    this.getAllLayoutCategories();
  }

  selectRole(event: any, item: any) {
    this.selectedRole = item.title;
    this.selectedRoleId = item.id;
    this.selectedDescription = item.shortDescription;
    this.searchRole(item.title, item.id,item.shortDescription);
    this.getLayoutItemsByCategoryId( item.id);

     this.propsForItem = {
      categoryName : this.selectedRole,
      categoryId :this.selectedRoleId
    }

  }
  async updateRole(){
  }
  async getAllLayoutCategories() {
    let res: any;
    res = await this.httpApiService.getAllLayoutCategories();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLayouts = data.data;
      this.setItems();
    }
  }
  async getLayoutItemsByCategoryId(categoryId:any) {
    let res: any;
    res = await this.httpApiService.getLayoutItemsByCategoryId(categoryId);
    this.categoryIdforDelete = categoryId;
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.categoryItems = data.data;
      
    }
  }
  updateItem(){
  }
  setItems() {
    this.items = this.listOfLayouts;
  }
  async searchRole(roleName:any, IDofSelection:any, selectedDescription:any) {
    await this.configService.showLoader();
    let tmpRoleName = "";
    this.items = this.listOfLayouts;
    if (!!roleName) {
      tmpRoleName = roleName;
      this.selectedRole = roleName;
      this.manageSelectedRole = roleName;
      this.selectedDescription = selectedDescription;
    } else {

      this.selectedRole = this.manageSelectedRole;
      tmpRoleName = this.manageSelectedRole;

    }
    // let selectedRoleId = this.items.filter(function (search) {
    //   if (search.roleName == tmpRoleName) {
    //   console.log(search, 'search if 55')
    //   console.log(tmpRoleName, 'tmpRoleName if 66')


    //     return search.id;
    //   }
    // });
    //  let selectedRoleId = let a = [ /* Your array of objects */ ];

let selectedRoleId =   [IDofSelection];


    if (selectedRoleId.length > 0) {
      this.roleId = selectedRoleId[0];
      this.getLayoutById(this.roleId);
    } else {
      await this.configService.dismiss();
      this.selectedRole = "";
      this.roleName = "";
      this.description = "";
      this.listOfActions = [];
     
    }
  }
  async getLayoutById(id:any){
    let res: any;
    res = await this.httpApiService.findLayoutDetailsById(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let roleData = data.data;
      this.dataForUpdateApi = roleData;
      this.categoryItemData = roleData;
      this.flagButton = ""
      // this.listOfActions = roleData.listOfActions;
      // this.selectedRole = roleData.roleName;
      this.id = roleData[0].id;
      // this.roleName = this.selectedRole;
      this.description = roleData[0].shortDescription;
      this.roleName = roleData[0].title;
      this.isActiveForCategory = roleData[0].isActive  === 1 ? "Yes" : "No"; ;
      this.setActions();
      // await this.filterJson(roleData);
      let index = this.items.findIndex(
        x => x.id == id
      );
      this.arrowKeyLocation = index;
      
    }
  }
  setActions() {
    // this.itemsActions = this.listOfActions;
  }
  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedRole = this.items[
              this.arrowKeyLocation
            ].roleName;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedRole = this.items[
              this.arrowKeyLocation
            ].roleName;
            break;
          } else {
            break;
          }
        }
    }
    console.log(this.arrowKeyLocation);
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        return item.roleName.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocation = -1;
    } else {
      this.arrowKeyLocation = -1;
    }
  }
  async showButton(name) {
    if (name == "roleName") {
      this.flagButton = "roleName";
    } else if (name == "description") {
      this.flagButton = "description";
    }
  }
  async addLayoutCategoryItems(selectedRole:any,selectedRoleId:any) {
    selectedRole = this.selectedRole;
    selectedRoleId = this.selectedRoleId;
    const modal = await this.modalCtrl.create({
      component: ManageLayoutsComponent,
      backdropDismiss: false,
      componentProps: { role: {selectedRole, selectedRoleId}, type: 2 }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d,2));
    return await modal.present();
  }
  async addLayoutCategory() {
    const modal = await this.modalCtrl.create({
      component: ManageLayoutsComponent,
      backdropDismiss: false,
      componentProps: { role: "", type: 1 }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d, 1));
    return await modal.present();
  }

  async handleModalDismiss(d:any, type:any) {
    if (d.data != 'doNotRefresh' && type==2) {
      this.getLayoutItemsByCategoryId(d.data[0].layoutCategoryId)
    }else if(d.data != 'doNotRefresh' && type==1){
      this.getAllLayoutCategories();
      console.log('category added')
    }else if(d.data != 'doNotRefresh' && type==3){
      this.getLayoutItemsByCategoryId(d.data[0].layoutCategoryId)
    }else if(d.data != 'doNotRefresh' && type==4){
      this.getAllLayoutCategories();
      this.getLayoutById(d.data);
    }
  }
  onCellClick(ev) {
    if(!!ev){
      this.columnName = ev.column.caption;
    }
  }
  saveGridInstance(e) {
    this.dataGridInstance = e.component;
  }
  onSelectionChanged(event) {
    event.row = event.selectedRowsData[0];
    event.columnName = this.columnName;
    this.selectedRows = event.selectedRowsData.length
    this.call(event);
  }
  call(event) {
    this.selected = event.row;
    this.event.publish("selectedValue", this.selected);
    this.setSelectedRows();
    this.callFunction.emit(event);


    if (event.type == 'click') {
      if (event.column.name != 'Barcode') {
        this.callFunction.emit(event);
      }
    } else {
      this.callFunction.emit(event);
    }
  }
  setSelectedRows() {
    if (this.selected && this.selected.length > 0) {
      this.refreshGrid = false;
      this.refreshMobileGrid = false;
      this.selectedRowKeys = this.selected.map((r) => r.stoneName);
    }
  }
  async handleUpdate(e : any, data: any){
    let titleOfCategory = this.selectedRole;
    let rowData = data.data;
    const modal = await this.modalCtrl.create({
      component: ManageLayoutsComponent,
      backdropDismiss: false,
      componentProps: { rowData: {rowData,titleOfCategory}, type: 3 }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d,3));
    return await modal.present();
  }

   deleteItem(e : any, data: any){
  this.deleteItemApi(data.data.layoutCategoryId,data.data.id)
  }

  async deleteItemApi(layoutCategoryId:any,itemId:any){
    
    let res: any;
    res = await this.httpApiService.deleteItemInSpecificCategory(layoutCategoryId,itemId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      // this.resetControls();

      window.alert('Item Deleted');
      this.getLayoutItemsByCategoryId(layoutCategoryId);
      await this.configService.showToast("success", data.message);
    }
  }

  async updateLayoutCategory(){
    let categoryData = this.dataForUpdateApi;
    
    let titleOfCategory = this.selectedRole;
    const modal = await this.modalCtrl.create({
      component: ManageLayoutsComponent,
      backdropDismiss: false,
      componentProps: { categoryData: {categoryData,titleOfCategory}, type: 4 }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d,4));
    return await modal.present();
  }
  async deleteLayoutCategory(){
    console.log('delete clicked');
    const layoutCategoryId= this.categoryIdforDelete;
    let res: any;
    res = await this.httpApiService.deleteSpecificCategory(layoutCategoryId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      // this.resetControls();
      window.alert('Category Deleted');
      this.selectedRole='';
      this.getAllLayoutCategories();
      // this.getLayoutItemsByCategoryId(layoutCategoryId);

      await this.configService.showToast("success", data.message);
    }

  }
}
