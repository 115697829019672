import { Component, OnInit } from '@angular/core';
import {
  Events, LoadingController, ModalController,
  NavParams
} from "@ionic/angular";
import * as moment from "moment";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: 'app-manage-conversion-rate-master',
  templateUrl: './manage-conversion-rate-master.page.html',
  styleUrls: ['./manage-conversion-rate-master.page.scss'],
})
export class ManageConversionRateMasterPage implements OnInit {


  public copy = [];
  public name: any;
  public flagAddUpdate: any;
  public item: any;
  public id: any;
  public code: any;
  public type: any;
  public isDefault: Boolean;

  public fromDate: any
  public fromCurrency: any;
  public toCurrency: any;
  public listOfLabs = []
  public partyMasterId: any;
  public rate: any;
  public fromCurrencyList = [];
  public toCurrencyList = [];
  public toCurrencyIdList = [];
  public fromCurrencyIdList = [];
  public tempArray = [];
  public partyMasterIdList = [];
  public spread: any;

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) {
  }

  async ngOnInit() {
    let data = this.navParams.get('item');
    this.type = this.navParams.get('type')
    this.flagAddUpdate = this.navParams.get('flag');
    if (this.flagAddUpdate == 'updateCurrency') {
      this.id = data;
      this.getCurrencyDetailById(this.id);
    } else if (this.flagAddUpdate == 'updateConversionRate') {
      await this.getAllCustomer('Lab');
      await this.getAllCurrency()
      this.item = data;
      console.log(this.item)
      this.id = this.item.id;
      this.rate = this.item.rate;
      if (!!this.item.spread) {
        this.spread = this.item.spread;
      }
      if (this.type == 'lab') {
        this.partyMasterId = this.item.partyMasterId;
        this.listOfLabs.filter(el => {
          if (el.id == this.partyMasterId) {
            this.partyMasterIdList = [];
            this.partyMasterIdList.push({ id: el.id, partyName: el.partyName })
          }
        })
      }
      console.log(this.partyMasterIdList)
      let data1 = []
      this.toCurrency = this.item.toCurrency;
      this.fromCurrency = this.item.fromCurrency;
      this.toCurrencyList = data1.concat(this.tempArray);
      console.log(this.tempArray, this.toCurrencyList)
      var removeIndex = this.toCurrencyList.map(function (item) { return item.id; }).indexOf(this.fromCurrency);
      this.toCurrencyList.splice(removeIndex, 1);
      this.fromCurrencyList.filter(el => {
        if (el.id == this.fromCurrency) {
          this.fromCurrencyIdList = []
          this.fromCurrencyIdList.push({ id: el.id, name: el.name })
        }
      })
      this.toCurrencyList.filter(el => {
        if (el.id == this.toCurrency) {
          this.toCurrencyIdList = []
          this.toCurrencyIdList.push({ id: el.id, name: el.name })
        }
      })
      let today = new Date(this.item.fromDate)
      this.fromDate =
        today.getFullYear() +
        "-" +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + today.getDate()).slice(-2);

    } else if (this.flagAddUpdate == 'addConversionRate') {
      this.fromDate = moment().format('YYYY-MM-DD')
      await this.getAllCustomer('Lab');
      await this.getAllCurrency()
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'labForCurrency') {
      if (idList.length > 0) {
        this.partyMasterId = idList[0];
      } else {
        this.partyMasterId = null
      }
    } else if (property == 'fromCurrency') {
      if (idList.length > 0) {
        this.fromCurrency = idList[0];
        this.toCurrency = null;
        let data = []
        this.toCurrencyIdList = []
        // this.fromCurrencyList.concat(this.tempArray);
        this.toCurrencyList = data.concat(this.tempArray);
        console.log(this.tempArray, this.toCurrencyList)
        var removeIndex = this.toCurrencyList.map(function (item) { return item.id; }).indexOf(this.fromCurrency);
        this.toCurrencyList.splice(removeIndex, 1);
        // this.toCurrencyList = tempArray;
      } else {
        this.fromCurrency = null
        this.toCurrencyList = []
        this.toCurrencyIdList = []
        this.toCurrency = null;
      }
    } else if (property == 'toCurrency') {
      if (idList.length > 0) {
        this.toCurrency = idList[0];

      } else {
        this.toCurrency = null
      }
    }
  }


  async getAllCustomer(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLabs = data.data;
      console.log("getAllCustomers", this.listOfLabs);

    }
  }

  async getAllCurrency() {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getCurrencyList();
    let data = res.json();
    await this.loadingController.dismiss()
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.fromCurrencyList = data.data;
      this.tempArray = data.data;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh')
  }

  async getCurrencyDetailById(id) {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getCurrencyDetailById(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(dataValue)
      if (dataValue) {
        this.name = dataValue.name;
        this.code = dataValue.code;
        this.isDefault = dataValue.isDefault;
      }
    }
  }

  async addConversionRate() {
    let validate: any;
    if (this.type == 'lab') {
      validate = await this.validateLabControls();
    } else {
      validate = await this.validateControls();
    }
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        fromDate: this.fromDate,
        fromCurrency: this.fromCurrency,
        toCurrency: this.toCurrency,
        rate: this.rate,
        spread: this.spread,
        partyMasterId: this.partyMasterId
      }
      console.log(jsonObj)
      res = await this.httpApiService.addConversionRate(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss(this.flagAddUpdate);
      }
    }
  }

  async updateConversionRate() {
    let validate: any;
    if (this.type == 'lab') {
      validate = await this.validateLabControls();
    } else {
      validate = await this.validateControls();
    }
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        fromDate: this.fromDate,
        fromCurrency: this.fromCurrency,
        toCurrency: this.toCurrency,
        rate: this.rate,
        spread: this.spread,
        partyMasterId: this.partyMasterId
      }
      console.log(jsonObj)
      res = await this.httpApiService.updateConversionRate(this.id, jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss(this.flagAddUpdate);
      }
    }
  }

  async addCurrency() {
    let validate = await this.validateCurrencyControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        name: this.name,
        code: this.code,
        isDefault: this.isDefault
      }
      console.log(jsonObj)
      res = await this.httpApiService.addCurrency(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss(this.flagAddUpdate)
      }
    }
  }

  async updateCurrency() {
    let validate = await this.validateCurrencyControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        name: this.name,
        code: this.code,
        isDefault: this.isDefault
      }
      console.log(jsonObj)
      res = await this.httpApiService.updateCurrency(this.id, jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss(this.flagAddUpdate)
      }
    }
  }

  async getAllLabs() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("lab");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLabs = data.data;
      // this.items = this.listOfParameter;
    }
  }

  async deleteCurrency() {
    let res: any;
    res = await this.httpApiService.deleteCurrency(this.id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.modalCtrl.dismiss(this.flagAddUpdate)
    }
  }

  async validateCurrencyControls() {
    if (this.name != null && this.name != null && this.name != '' &&
      this.code != null && this.code != null && this.code != '') {
      return true;
    } else {
      await this.configService.showToast('error', 'Please enter the details')
      return false;
    }
  }

  async validateControls() {
    if (this.fromCurrency != null && this.fromCurrency != undefined && this.fromCurrency != ''
      && this.toCurrency != null && this.toCurrency != undefined && this.toCurrency != '') {
      if (this.rate != null && this.rate != null && this.rate != '' &&
        this.fromDate != null && this.fromDate != null && this.fromDate != '') {
        return true;
      } else {
        await this.configService.showToast('error', 'Please enter the details')
        return false;
      }
    } else {
      await this.configService.showToast('error', 'Please select Currency')
      return false;
    }
  }


  async validateLabControls() {
    if (this.fromCurrency != null && this.fromCurrency != undefined && this.fromCurrency != ''
      && this.toCurrency != null && this.toCurrency != undefined && this.toCurrency != ''
      && this.partyMasterId != null && this.partyMasterId != undefined && this.partyMasterId != '') {
      if (this.rate != null && this.rate != null && this.rate != '' &&
        this.fromDate != null && this.fromDate != null && this.fromDate != '') {
        return true;
      } else {
        await this.configService.showToast('error', 'Please enter the details')
        return false;
      }
    } else {
      await this.configService.showToast('error', 'Please select details')
      return false;
    }
  }

}
