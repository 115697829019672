import { Component, Input } from "@angular/core";
import {
  Events,
  LoadingController,
  ModalController,
  ToastController
} from "@ionic/angular";
import { SearchService } from "src/app/services/search/search.service";
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";
import { PdAssortDetailsPage } from "../pd-assort-details/pd-assort-details.page";
import * as moment from "moment";

@Component({
  selector: 'app-pd',
  templateUrl: './pd.component.html',
  styleUrls: ['./pd.component.scss']
})
export class PDComponent implements ProcessActionComponent {
  @Input() data: any;
  public stoneList = [];
  public stoneIdList = [];
  public stoneParameter = [];
  public stoneId: any;
  public pageNumber = 0;
  public pValues: any;
  public noOfRecords: Number = 100;
  public paramList = [];
  public singleParameter: any;
  public showPopover = false;
  public selectedValuesArray = [];
  public paramIndex = 0;
  public totalParam: any;
  public pdObject = {};
  public isDisable = true;
  public SSParamList = [];
  public barcode = '';
  public SalesPersonSuggestions = ''
  public remark1 = ''
  public gradingDetail: any = [];
  public companyIds: string = '';
  public columns = [
    { name: "Stone Id", prop: "stoneName" },
    { name: "Stone Cts.", prop: "cts" },
    { name: "Shape", prop: "ShapeCode" },
    { name: "Color", prop: "ColorCode" },
    { name: "Cut", prop: "CutCode" },
    { name: "Clarity", prop: "ClarityCode" },
    { name: "Polish", prop: "PolishCode" },
    { name: "Symmetry", prop: "SymmetryCode" },
    { name: "Fluorescence", prop: "FluorescenceCode" },
    { name: "OurColor", prop: "OurColor" },
    { name: "OurClarity", prop: "OurClarity" },
    { name: "OurShape", prop: "OurShape" },
    { name: "PD cat", prop: "PdCatCode" },
    { name: "lab", prop: "lab" },
    { name: "Lab Pkt No", prop: "labPktSeriesNo" },
    { name: "Inscription", prop: "Inscription" },
    { name: "Report No", prop: "ReportNo" },
    { name: "PavAngle", prop: "PavAngle" },
    { name: "PavHgt", prop: "PavHgt" },
    { name: "CrAngle", prop: "CrAngle" },
    { name: "CrHgt", prop: "CrHgt" },
    { name: "Girdle", prop: "GirdleCode" },
    { name: "Culets", prop: "CuletSizeCode" },
    { name: "TotDepth", prop: "TotDepth" },
    { name: "TablePer", prop: "TablePer" },
  ];
  public pdHistoryList = [];
  id = null;
  public showHistory = false;
  lastPdDate = '';
  lastPdUser = '';
  public columnsForHistory = [{ name: "Stone Id", prop: "stone" },
  { name: "user", prop: "createdBy" },
  { name: "remarks", prop: "remarks" },
  { name: "status", prop: "status" },
  { name: "created_on", prop: "created_on" }]

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public toastCtrl: ToastController,
    public configService: ConfigService,
    private es: SearchService,
    public event: Events) { }

  ngOnInit() {
    this.getDefaultValues()
    // this.inStockStone();
    this.singleStoneParameters();
    this.event.subscribe('PD', (item) => {
      this.pdObject = {}
      this.stoneIdList = []
      this.isDisable = true
      this.barcode = null;
      this.stoneId = null;
      this.remark1 = null;
      this.gradingDetail = [];
      this.SalesPersonSuggestions = null;
      this.cloneData()
    })
  }

  async getDefaultValues() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(dataValue);
      if (
        !!dataValue.map &&
        dataValue.map.companyIds
      ) {
        this.companyIds = dataValue.map.companyIds;
      }
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "stoneParam") {
      if (idList.length > 0) {
        this.stoneId = obj[0].stoneId;
        this.isDisable = true;
        this.cloneData()
        this.getPdParamsByStoneId(this.stoneId)
      } else {
        this.isDisable = true;
        this.stoneId = null;
        this.selectedValuesArray = []
      }
    }
  }

  ngOnDestroy() {
    this.event.unsubscribe('PD');
  }




  async singleStoneParameters() {
    let res = await this.httpApiService.getAllParametersForSingleStone('SS');
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      console.log(dataList)
      dataList = dataList.filter(el => {
        el.parameterValues = el.parameterValues.filter(item => !item.isDisabled)
        return el;
      })
      let data1 = []
      for (let i = 0; i < dataList.length; i++) {
        if (!!dataList[i].nameGroup) {
          if (data1.length > 0) {
            let flag = data1.some(el => el.nameGroup === dataList[i].nameGroup);
            if (!flag) {
              data1.push({
                id: dataList[i].id, nameGroup: dataList[i].nameGroup, isGroup: true, companyId: dataList[i].companyId,
                downTolerance: dataList[i].companyId, masterName: dataList[i].masterName, paramMasterId: dataList[i].paramMasterId, subParameters: []
              })
            }
          } else {
            data1.push({
              id: dataList[i].id, nameGroup: dataList[i].nameGroup, isGroup: true, companyId: dataList[i].companyId,
              downTolerance: dataList[i].companyId, masterName: dataList[i].masterName, paramMasterId: dataList[i].paramMasterId, subParameters: []
            })
          }
        } else {
          let isValueGroup: any;
          let valueGroup1: any;
          if (dataList[i].parameterValues.length > 0) {
            dataList[i].parameterValues.forEach(el => {
              if (!!el.valueGroup) {
                valueGroup1 = el.valueGroup;
              }
            })
            let flag: Boolean;
            dataList[i].parameterValues.filter(function (item) {
              if (item.valueGroup != valueGroup1) {
                flag = true;
                return true;
              }
              return
            });
            if (!!flag && flag == true) {
              isValueGroup = true
            } else {
              isValueGroup = false
            }
          }
          data1.push({
            id: dataList[i].id, nameGroup: dataList[i].masterName, isValueGroup: isValueGroup, isGroup: false, companyId: dataList[i].companyId,
            downTolerance: dataList[i].companyId, masterName: dataList[i].masterName, paramMasterId: dataList[i].paramMasterId, parameterValues: dataList[i].parameterValues
          })
        }
      }


      for (let i = 0; i < data1.length; i++) {
        if (data1[i].isGroup == true) {
          data1[i].totalsubParamList = []
          dataList.forEach(el => {
            if (el.nameGroup == data1[i].nameGroup) {
              data1[i].subParameters.push(el)
            }
          })
          data1[i].subParameters.forEach((el) => {
            data1[i].totalsubParamList.push(el.masterName)
          })
        }
      }

      this.SSParamList = data1;
      this.stoneParameter = JSON.parse(JSON.stringify(this.SSParamList));
      console.log(this.stoneParameter)
    }
  }


  async onChange(ev) {
    this.SalesPersonSuggestions = '';
    this.remark1 = '';
    this.lastPdUser = '';
    this.lastPdDate = '';
    this.stoneId = null;
    let qry: any =
    {
      "query": {
        "bool": {
          "should": [
            {
              "simple_query_string": {
                "query": ""
              }
            },
            {
              "bool": {
                "must": []
              }
            }
          ]
        }
      }
    }
    if (!!ev.target.value) {
      this.barcode = ev.target.value;
      let boolShouldMultiple = {
        "bool": {
          "should": []
        }
      };
      // let boolMustMultiple = {
      //   "bool": {
      //     "must": []
      //   }
      // };
      let bData = {
        terms: {
          "Barcode": [ev.target.value]
        }
      }

      let sData = {
        terms: {
          "stoneName": [ev.target.value]
        }
      }
      let location = {
        "terms": { location: ["mumbai", "botswana"] }
      }

      boolShouldMultiple.bool.should.push(bData);
      // boolShouldMultiple.bool.should.push(fmData);
      boolShouldMultiple.bool.should.push(sData);
      qry.query.bool.should[1].bool.must.push(location);
      qry.query.bool.should[1].bool.must.push(boolShouldMultiple);

      let data = await this.es.getPaginatedDocuments(qry, 0, environment.INDEX, '', 1)
      if (!!data.hits.hits && data.hits.hits.length > 0) {
        this.stoneId = data.hits.hits[0]._source.stoneName.toString();
        this.SalesPersonSuggestions = data.hits.hits[0]._source.SalesPersonSuggestions;
        this.remark1 = data.hits.hits[0]._source.Remark1;
        this.id = data.hits.hits[0]._id;
        this.gradingDetail = data.hits.hits.map(el => el._source);
        this.cloneData()
        this.getPdParamsByStoneId(this.stoneId)
        this.findPDHistory()
      } else {
        this.getPDparamByStoneIdFromDB(ev.target.value)
        this.findPDHistory()
        // this.checkStoneNotOnEs(ev.target.value)
        // this.configService.showToast('error', 'barcode with stoneId not found')
      }
    }
  }

  async getPdParamsByStoneId(stoneId) {
    // let res: any;
    // res = await this.httpApiService.getPdParamsByStoneId(stoneId);
    // let data = res.json();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   let dataValue = data.data
    //   console.log(dataValue)
    let gDetail = this.gradingDetail[0];
    let allKeys = Object.keys(gDetail)
    console.log(this.stoneParameter, this.pdObject)
    for (let i = 0; i < this.stoneParameter.length; i++) {
      if (this.stoneParameter[i].isGroup == true) {
        this.stoneParameter[i].subParameters.forEach(subparamEl => {
          let tedata = subparamEl.masterName.replaceAll(" ", "") + 'Code';
          let valueList = !!gDetail[tedata] ? gDetail[tedata].toString().split(',') : []
          if (allKeys.includes(tedata)) {
            let valueIdList = []
            subparamEl.parameterValues.forEach(item => {
              let flag = valueList.some(val => val == item.name);
              if (flag) {
                item.isSelected = true;
                item.oldSelected = true;
                valueIdList.push(item.id)
                this.pdObject[subparamEl.masterName] = valueIdList
              } else {
                item.isSelected = false;
              }
            })
          }
        })
      } else {
        let nameGroupcode = this.stoneParameter[i].nameGroup.replaceAll(" ", "") + 'Code';
        console.log(nameGroupcode, gDetail[nameGroupcode])
        let valueList = !!gDetail[nameGroupcode] ? gDetail[nameGroupcode].toString().split(',') : []
        if (allKeys.includes(nameGroupcode)) {
          let valueIdList = []
          this.stoneParameter[i].parameterValues.forEach(item => {
            let flag = valueList.some(val => val == item.name);
            if (flag) {
              item.isSelected = true;
              item.oldSelected = true;
              valueIdList.push(item.id)
              this.pdObject[this.stoneParameter[i].masterName] = valueIdList
            } else {
              item.isSelected = false;
            }
          })
        }
      }
    }
    if (Object.keys(this.pdObject).length > 0) {
      this.isDisable = false
    } else {
      this.isDisable = true
    }
  }


  async checkStoneNotOnEs(stoneId) {
    let res: any;
    let res1: any;
    this.gradingDetail = []
    res1 = await this.httpApiService.findStoneIdForGivenLocation(stoneId);
    let data1 = res1.json()
    if (!!data1.data && data1.data.length > 0) {
      this.gradingDetail = data1.data;
      this.cloneData()
      this.getPdParamsByStoneId(stoneId)
    } else {
      await this.configService.showToast("error", 'Stone not found');
    }

  }

  async getPDparamByStoneIdFromDB(stoneId) {
    let res: any;
    res = await this.httpApiService.getPdParamsByStoneId(stoneId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(dataValue, this.stoneId)
      this.stoneId = stoneId;
      if (dataValue.length > 0) {
        this.showHistory = false;
        this.isDisable = false;
        console.log(dataValue)
        dataValue.forEach(el => {
          let valueList = el.paramValueIds.split(',').map(Number);
          for (let i = 0; i < this.stoneParameter.length; i++) {
            if (this.stoneParameter[i].isGroup == true) {
              this.stoneParameter[i].subParameters.forEach(subparamEl => {
                if (subparamEl.masterName == el.paramName) {
                  let valueIdList = []
                  subparamEl.parameterValues.forEach(item => {
                    let flag = valueList.some(val => val == item.id);
                    console.log("1", valueList, item.id, item.code, flag)
                    if (flag) {
                      item.isSelected = true;
                      valueIdList.push(item.id)
                      this.pdObject[subparamEl.masterName] = valueIdList
                    } else {
                      item.isSelected = false;
                    }
                  })
                }
              })
            } else {
              if (el.paramName == this.stoneParameter[i].nameGroup) {
                let valueIdList = []
                this.stoneParameter[i].parameterValues.forEach(item => {
                  let flag = valueList.some(val => val == item.id);
                  console.log("2", valueList, item.id, item.code, flag)
                  if (flag) {
                    item.isSelected = true;
                    valueIdList.push(item.id)
                    this.pdObject[this.stoneParameter[i].masterName] = valueIdList
                  } else {
                    item.isSelected = false;
                  }
                })

              }
            }
          }
        })
      } else {
        this.checkStoneNotOnEs(stoneId)
      }
    }


  }

  cloneData() {
    this.stoneParameter = []
    this.pdObject = {}
    this.stoneParameter = JSON.parse(JSON.stringify(this.SSParamList));
  }

  async inStockStone() {
    let res: any;
    res = await this.httpApiService.getStockStone();
    let data = res.json();
    console.log(data)
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      let data1 = []
      if (dataList.length > 0) {
        dataList.forEach(el => {
          console.log
          if (el.transType == 'SS') {
            data1.push(el)
          }
        })
        console.log(data1)
        this.stoneList = data1
      }
      console.log(this.stoneList)
    }
  }

  //select sub parameter value

  async selectedPDParameterValue(parameters, sub, stones, index) {
    if (stones[index].isSelected) {
      stones[index].isSelected = !stones[index].isSelected;
      let list = this.stoneParameter.filter(el => el.isSelected)
      let pdKeyArray = []
      pdKeyArray = Object.keys(this.pdObject)
      this.stoneParameter.forEach(el => {
        if (el.isGroup == true) {
          if (el.totalsubParamList.length > 0) {
            let test = true;
            el.totalsubParamList.forEach(item => {
              for (let i = 0; i < pdKeyArray.length && test; i++) {
                if (pdKeyArray[i] == item) {
                  if (this.pdObject[pdKeyArray[i]].length > 0) {
                    el.isSelected = true;
                  } else {
                    el.isSelected = false;
                    test = false
                  }
                }
              }
            })
          }
        } else {
          for (let key in this.pdObject) {
            if (el.masterName == key) {
              if (this.pdObject[key].length > 0) {
                el.isSelected = true;
              } else {
                el.isSelected = false;
              }
            }
          }
        }
      })
      if (list.length > 0) {
        this.isDisable = false
      } else {
        this.isDisable = true
      }

      for (let key in this.pdObject) {
        let masterN = !!sub.masterName ? sub.masterName : parameters.masterName;
        if (masterN == key) {
          if (this.pdObject[key].length > 0) {
            let findIndex = this.pdObject[key].findIndex(el => el == stones[index].id)
            if (findIndex > -1) {
              this.pdObject[key].splice(findIndex, 1)
            }
          }
        }
      }
      console.log(this.stoneParameter, this.stoneParameter, this.isDisable, this.pdObject)
      return true;
    }

    if (parameters.isGroup == true) {
      if ((!!stones[index].code && stones[index].code.toLowerCase() == 'non') || stones[index].name.toLowerCase() == 'none') {
        stones.forEach(element => {
          element.isSelected = false;
        });
        stones[index].isSelected = true;
        parameters.isValueGroup = false;
      } else {
        stones.forEach((el, i) => {
          if (!!el.code && el.code.toLowerCase() == 'non' || el.name.toLowerCase() == 'none') {
            stones[i].isSelected = false;
          }
        })
        parameters.isValueGroup = false;
        stones.forEach((el, i) => {
          if (!!el.valueGroup && el.valueGroup == stones[index].valueGroup) {
            stones[i].isSelected = false;
            stones[index].isSelected = true;
          } else if (!!el.valueGroup && el.valueGroup != stones[index].valueGroup) {
            stones[index].isSelected = true;
          }
        })
      }
    } else {
      if (parameters.isValueGroup && parameters.isValueGroup == true) {
        if ((!!stones[index].code && stones[index].code.toLowerCase() == 'non') || stones[index].name.toLowerCase() == 'none') {
          stones.forEach(element => {
            element.isSelected = false;
          });
          stones[index].isSelected = true;
        } else {
          stones.forEach((el, i) => {
            if (!!el.code && el.code.toLowerCase() == 'non' || el.name.toLowerCase() == 'none') {
              stones[i].isSelected = false;
            }
          })
          stones.forEach((el, i) => {
            if (!!el.valueGroup && el.valueGroup == stones[index].valueGroup) {
              stones[i].isSelected = false;
              stones[index].isSelected = true;
            } else if (!!el.valueGroup && el.valueGroup != stones[index].valueGroup) {
              stones[index].isSelected = true;
            }
          })
        }
      } else {
        stones.forEach(element => {
          element.isSelected = false;
        });
        stones[index].isSelected = true;
      }
    }
    if (!!parameters.isValueGroup && parameters.isValueGroup == true && parameters.isGroup == true) {
      let paramList = parameters.subParameters;
      for (let i = 0; i < paramList.length; i++) {
        let valueIdList = [];
        let valueList = paramList[i].parameterValues;
        for (let j = 0; j < valueList.length; j++) {
          if (!!valueList[j].isSelected && valueList[j].isSelected == true) {
            valueIdList.push(valueList[j].id)
          }
        }
        this.pdObject[paramList[i].masterName] = valueIdList;
      }
    } if (parameters.isValueGroup == false && parameters.isGroup == true) {
      let paramList = parameters.subParameters;
      console.log(paramList, "1")
      for (let i = 0; i < paramList.length; i++) {
        let valueIdList = [];
        let valueList = paramList[i].parameterValues;
        for (let j = 0; j < valueList.length; j++) {
          if (!!valueList[j].isSelected && valueList[j].isSelected == true) {
            valueIdList.push(valueList[j].id)
          }
        }
        this.pdObject[paramList[i].masterName] = valueIdList;
      }
    } else if (parameters.isGroup == false && parameters.isValueGroup == true) {
      let paramList = stones;
      console.log(paramList, "2")
      let valueIdList = [];
      for (let i = 0; i < paramList.length; i++) {
        if (paramList[i].isSelected == true) {
          valueIdList.push(paramList[i].id)
        }
      }
      this.pdObject[parameters.nameGroup] = valueIdList;
    } else if (parameters.isGroup == false && parameters.isValueGroup == false) {
      let paramList = stones;
      let valueIdList = [];
      for (let i = 0; i < paramList.length; i++) {
        if (paramList[i].isSelected == true) {
          valueIdList.push(paramList[i].id)
        }
      }
      this.pdObject[parameters.nameGroup] = valueIdList;
    }
    console.log(this.pdObject, this.stoneParameter);

    if (Object.keys(this.pdObject).length > 0) {
      let pdKeyArray = []
      pdKeyArray = Object.keys(this.pdObject)

      this.stoneParameter.forEach(el => {
        if (el.isGroup == true) {
          if (el.totalsubParamList.length > 0) {
            let test = true;
            el.totalsubParamList.forEach(item => {
              for (let i = 0; i < pdKeyArray.length && test; i++) {
                if (pdKeyArray[i] == item) {
                  if (this.pdObject[pdKeyArray[i]].length > 0) {
                    el.isSelected = true;
                  } else {
                    el.isSelected = false;
                    test = false
                  }
                }
              }
            })
          }
        } else {
          for (let key in this.pdObject) {
            if (el.masterName == key) {
              if (this.pdObject[key].length > 0) {
                el.isSelected = true;
              } else {
                el.isSelected = false;
              }
            }
          }
        }
      })
    }
    let list = this.stoneParameter.filter(el => el.isSelected)
    if (list.length > 0) {
      this.isDisable = false
    } else {
      this.isDisable = true
    }
  }



  checkValidation() {
    if (Object.keys(this.pdObject).length > 0) {
      let check = [];
      for (let key in this.pdObject) {
        if (this.pdObject[key].length > 0) {
          return true
        }
      }

      if (check.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false
    }
  }

  async savePD() {
    if (!this.companyIds) {
      await this.configService.showToast("error", 'Please add company from action default');
      return true;
    }
    await this.configService.showLoader()
    let saveStoneObj = {
      stoneId: this.stoneId,
      mapOfParamKeyValue: this.pdObject,
      salesPersonSuggestions: this.SalesPersonSuggestions,
      remark1: this.remark1
    };
    console.log(saveStoneObj)
    let res: any;
    res = await this.httpApiService.savePdResult(saveStoneObj, this.companyIds)
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.pdObject = {}
      this.stoneIdList = []
      this.isDisable = true
      this.stoneId = null;
      this.barcode = null;
      this.remark1 = null;
      this.gradingDetail = [];
      this.SalesPersonSuggestions = null;
      this.cloneData()
      await this.configService.showToast("success", data.message);
    }
  }

  async findPDHistory() {
    const loading = await this.loadingController.create({
      message: "Please Wait.."
    });
    await loading.present();
    let res: any;
    res = await this.httpApiService.getStoneWisePDHistory(this.stoneId);
    let data = res.json();
    console.log(data)
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.loadingController.dismiss()
      this.pdHistoryList = data.data;
      if (this.pdHistoryList.length > 0) {
        this.lastPdUser = this.pdHistoryList[0].createdBy;
        if (!!this.pdHistoryList[0].created_on)
          this.lastPdDate = moment.utc(this.pdHistoryList[0].created_on).local().format('DD-MM-YYYY hh:mm a');;
      }
      console.log(this.stoneList)
    }
  }


  async showPDparams(ev) {
    console.log(ev)
    //  ev.row.data.id = this.id;
    const modal = await this.modalCtrl.create({
      component: PdAssortDetailsPage,
      backdropDismiss: false,
      cssClass: 'largePage',
      componentProps: {
        rowData: ev.row.data,
        pdHistoryList: this.pdHistoryList,
        showPdResult: true,
        status: 'showPDData'
      },
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async uploadFile() {
    const modal = await this.modalCtrl.create({
      component: PdAssortDetailsPage,
      backdropDismiss: false,
      cssClass: 'extraLargePage',
      componentProps: {
        rowData: null,
        showPdResult: false,
        status: 'showUploadPDForm',
        actionId: this.data.actionId,
      },
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async handleModalDismiss(d) {
  }


}
