import { Component, Input, OnInit, Output, HostListener, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  @Input() keyFun: any;
  @Input() openView: any;
  @Input() optionList: any;
  @Input() notFound: any;
  @Output() getDataByOption: EventEmitter<any> = new EventEmitter();
  @Output() closeView: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.keyFun) {
      if (event.keyCode == 86) {
        if (this.optionList.length > 0) {
          this.openView = true;
          let index = this.optionList.findIndex(x => x.isSelected == true);
          let optionLength = this.optionList.length;
          if (index + 1 < optionLength) {
            this.optionList[index].isSelected = false;
            this.optionList[index + 1].isSelected = true;
          } else {
            this.optionList[0].isSelected = true;
            this.optionList[optionLength - 1].isSelected = false;
          }
        }
      } else if (event.keyCode == 27) {
        this.closeView.emit(false)
        this.openView = false;
      } else if (event.keyCode == 13) {
        if (this.openView) {
          this.getDataByOption.emit(this.optionList);
        }
        this.closeView.emit(false)
        this.openView = false;
      } else {
        this.closeView.emit(false)
        this.openView = false;
      }
    }
  }

}
