import { Component, Input, OnInit } from '@angular/core';
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: 'app-default-templates',
  templateUrl: './default-templates.component.html',
  styleUrls: ['./default-templates.component.scss']
})
export class DefaultTemplatesComponent implements ProcessActionComponent {
  @Input() data: any;

  public template = 'default';

  constructor() { }

  ngOnInit() {
  }

}
