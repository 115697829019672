import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import {
  ModalController,
  LoadingController,
  Events,
  AlertController
} from "@ionic/angular";
import { ReceiveincomingstonesComponent } from '../receiveincomingstones/receiveincomingstones.component';
import * as moment from "moment";
import { IonicSelectableComponent } from 'ionic-selectable';
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: 'app-purchase-consolidation-incomingstones',
  templateUrl: './purchase-consolidation-incomingstones.component.html',
  styleUrls: ['./purchase-consolidation-incomingstones.component.scss']
})
export class PurchaseConsolidationIncomingstonesComponent implements OnInit {
  public columns = [];
  dataSource: any = [];

  public uploadDate = moment().format("YYYY-MM-DD")
  listOfStoneDataSource: any = [];
  public pageSize: any = 100;
  pageNumber = 0;
  noOfRecords = 15;
  selectedParty: string = '';
  public refreshGrid: Boolean = false;
  public partyMasterId: any;
  public partyName: any;
  public transactionType: any;
  public status: any;
  public selectedStatus: any;
  public listOfParties = [];
  fromDate = moment().format('YYYY-MM-DD')
  toDate = moment().format('YYYY-MM-DD')
  public enquiryNo;
  totalCount = 0
  public stonesList = [];
  public selected = [];

  public Stones = ``;
  public statusList = [{
    name: 'Pending',
    id: 1
  }, {
    name: 'Received',
    id: 2
  }];


  public transactionStatusList: any = [{
    name: 'Purchase',
    id: 1
  }, {
    name: 'In-House QC',
    id: 2
  }, {
    name: 'Remote QC Reject',
    id: 3
  }]
  // public returnStoneList:any=[{
  //   name: 'Pending',
  //   id: 1
  // }, {

  //   name: 'Receive',
  //   id: 2

  // }]
  public statusIdList = [];
  public salesPersonList = [];
  public salePersonIdList = [];
  public selectedSalesPerson = '';

  constructor(
    public configService: ConfigService,
    public authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public event: Events
  ) { }

  async ngOnInit() {
    this.columns = [
      { name: "enquiryno", prop: "enquiryId" },
      { name: "Stoneid", prop: "stoneName" },
      { name: 'Clarity', prop: 'ClarityCode' },
      { name: 'Color', prop: 'ColorCode' },
      { name: 'Shape', prop: 'ShapeCode' },
      { name: 'Size', prop: 'cts' },
      { name: "Purchase-party", prop: "partyName" },
      { name: "Broker", prop: "brokerName" },
      { name: "Refsales-person", prop: "SalePerson" },
      { name: "Date & Time", prop: "createdDate" },
      { name: "BillReceived", prop: "Billreceived" },
      { name: "CertiReceived", prop: "Certoreceived" },
      { name: "Inward Status", prop: "status" },
      { name: "Transaction Status", prop: "transactionType" }
    ];
    await this.getAllCustomer(this.pageNumber, this.noOfRecords, "");
    this.Fetch()
  }

  async confirmReceive() {
    let isDifferentPartyList = this.selected.filter(el => el.partyMasterId != this.selected[0].partyMasterId)
    if (!!isDifferentPartyList && isDifferentPartyList.length > 0) {
      await this.configService.showToast('error', 'Please select only one purchase party!')
    } else {
      this.refreshGrid = false;
      let listOfStones = this.selected.map(x => {
        let obj = {
          stoneName: x.stoneName,
          partyMasterId: x.partyMasterId,
          enquiryId: x.enquiryId
        }
        return obj
      })
      const modal = await this.modalCtrl.create({
        component: ReceiveincomingstonesComponent,
        cssClass: 'small-modal',
        componentProps: {
          listOfStones: listOfStones
        },
      });
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true
    console.log(d.xdata,);
    this.stonesList = [];
    this.Stones = '';
    this.Fetch()
  }

  async getAllCustomer(pageNumber, noOfRecords, searchText) {
    let res: any;
    let json = {
      listOfType: ['Local Purchase']
    }
    res = await this.httpApiService.findByListOfPartyTypePagination(json, 'Client', pageNumber, noOfRecords, searchText);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.content) {
        let dataValueList = data.data.content;
        if (dataValueList.length > 0) {
          for (let i = 0; i < dataValueList.length; i++) {
            dataValueList[i].partyNameWithType = `${dataValueList[i].partyName} ( ${dataValueList[i].type} )`
          }
          this.listOfParties = dataValueList;
        } else {
          this.listOfParties = []
        }
      }
    }
  }

  onChangeParty(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (!!event.value) {
      this.partyMasterId = event.value.id
      this.partyName = event.value.partyName
    } else {
      this.partyMasterId = null;
      this.partyName = null;
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "status") {
      this.selectedStatus = obj[0];
    } else if (property == "salePerson") {
      this.selectedSalesPerson = idList[0];
    } else if (property == "stoneStatus") {
      if (idList.length > 0) {
        this.status = obj[0].name;
      } else {
        this.status = "";
      }
    } else if (property == 'transactionStatusList') {
      if (idList.length > 0) {
        this.transactionType = obj[0].name;
      } else {
        this.transactionType = "";
      }
    }

  }

  async getDepartmentWiseKamPersonList() {
    let res: any;
    res = await this.httpApiService.getDepartmentWiseKamPersonList("KAM Sales");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.salesPersonList = data.data;
    }
  }

  async searchParty(event: { component: IonicSelectableComponent; text: string }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    await this.getAllCustomer(this.pageNumber, this.noOfRecords, searchText);
  }

  async Fetch() {

    let listOfStones = []
    if (this.Stones != '') {
      let tmp = this.Stones.split("\n");
      tmp = tmp.map(x => x.trim())

      if (tmp.length > 0) {
        listOfStones = tmp
      }
    }

    let obj = {
      'listOfStones': listOfStones,
      'fromDate': this.fromDate,
      'toDate': this.toDate,
      'enquiryId': this.enquiryNo,
      'searchQry': this.partyName,
      'transactionType': this.transactionType,
      'status': this.status,
    }
    this.dataSource = new CustomStore({

      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.fetchIncomingStones(obj, this.noOfRecords, this.pageNumber)
          let data = res.json();

          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.stonesList = data.data;
            this.totalCount = data.data.count
            return {
              data: Query(data.data.data)
                .toArray(),
              totalCount: this.totalCount, //data.data.totalElements,
              groupCount: this.totalCount// data.data.totalElements
            };
          }
        } else {
          return {
            data: this.stonesList,
            totalCount: this.totalCount,
            groupCount: this.totalCount
          }
        }
      }
    });
  }
  // setStatusforstone(transactionType) {
  //   this.defaultParty.transactionType = transactionType
  //   this.partyWiseStones.filter(x => x.party == this.defaultParty.party).map(s => {
  //     s.transactionType = transactionType
  //     s.stones.map(p => {
  //       p['transactionType'] = transactionType
  //     })

  //     return s
  //   })
  // }

  onSelect(ev) {
    this.selected = ev.selectedRowsData;
  }

  Reset() {
    this.partyName = '';
    this.enquiryNo = '';
    this.stonesList = [];
    this.Stones = '';
    this.fromDate = moment().format('YYYY-MM-DD')
    this.toDate = moment().format('YYYY-MM-DD')
    this.Fetch()
  }
}
