import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import {
  ModalController,
  LoadingController,
  Events,
  AlertController
} from "@ionic/angular";
import { ReceiveincomingstonesComponent } from '../receiveincomingstones/receiveincomingstones.component';
import * as moment from "moment";
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-returningpurchasestonemodal',
  templateUrl: './returningpurchasestonemodal.component.html',
  styleUrls: ['./returningpurchasestonemodal.component.scss']
})
export class ReturningpurchasestonemodalComponent implements OnInit {
  public stonesList = [];
  public Stones = ``;
  public selected = [];
  public listOfStones: any;
  public isCerti: boolean = false;
  public isBillNo: boolean = false;
  constructor(
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    private alertCtrl: AlertController,
    public event: Events
  ) { }

  ngOnInit() {

    console.log(this.listOfStones)
  }
  async returnStones() {
    await this.configService.showLoader();
    let obj = {
      'listOfStones': this.listOfStones,
      'isCertiReturned': this.isCerti
    }
    let res: any;
    res = await this.httpApiService.updateEnquiry(obj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
    }
    console.log("hi");
    console.log(data);
    this.modalCtrl.dismiss();

  }


  closeModal() {
    console.log("The close butoon is working fine");
    this.modalCtrl.dismiss();
  }
  // async Return(){
  //   this.modalCtrl.dismiss()

  // }
}