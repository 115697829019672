import { Component, HostListener, OnInit } from '@angular/core';
import {
  Events, LoadingController, ModalController,
  NavParams
} from "@ionic/angular";
import { DragulaService } from "ng2-dragula";
import { ExportAsExcelService } from 'src/app/services/export-as-excel.service';
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: 'app-manage-templates',
  templateUrl: './manage-templates.page.html',
  styleUrls: ['./manage-templates.page.scss'],
})
export class ManageTemplatesPage implements OnInit {
  public getAllStoneParam = [];
  public fileList = [];
  public parameterIdList = [];
  public copy = [];
  public templateName: any;
  public fileType: any;
  public flagAddUpdate: any;
  public item: any;
  public exportIdList = [];
  public id: any;
  public isDefault: any;
  public listOfParameters = [];
  public listOfParametersDisplay = [];
  public allPriceNameList = [];
  public priceList: any;
  public priceIdList = []
  public priceListObj = [];
  public newFormat: boolean = false;
  public websiteFormate: boolean = false;
  public parametersObj = []
  public exportData: any = [];
  public copyValue: any = [];
  public copyVaueToOrginalVal: any = [];
  public isExportBtn: any = true;
  public listOfPricingFun = [];
  public listOfOperations = [{
    id: 1, name: 'add'
  }, {
    id: 2, name: 'reduce'
  }]
  public listOfTemplatePricingModifications = [];
  public listOfTemplateStoneFilterCriterias = [];
  public funIdList = [];
  public fromCriteria = 0;
  public toCriteria = 0;
  public operationIdList = [];
  public addCustomKey = false;
  public configIdList = [];
  public configType = '';

  public customObj = {
    "name": "",
    "headerName": "",
    "type": "customParam",
    "configType": "",
    "configValue": "",
    "fromDB": false
  }

  public configOptionsList = [
    {
      id: 1,
      'name': "Constant",
    },
    {
      id: 2,
      'name': "Transform",
    },
    {
      id: 3,
      'name': "Evaluate"
    }, {
      id: 4,
      'name': "Formula"
    }
  ]

  constructor(private authService: AuthenticationService,
    public dragulaService: DragulaService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public exportAsExcelService: ExportAsExcelService) {
    this.fileList = [{ id: 1, name: 'csv' }
      , { id: 2, name: 'excel' },
    { id: 3, name: 'txt' }
    ]
    this.exportIdList = [{ id: 2, name: 'excel' }]
    this.fileType = this.exportIdList[0].name;
  }
  public operator = '';
  public functionName = '';
  public amount = 0;
  public columns = [];
  public pricingExtCol = ['DiscountPercent', 'percarat']

  @HostListener('window:keyup', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (!this.isExportBtn) {
      return;
    }

    if ((event.ctrlKey || event.metaKey) && event.keyCode == 86) {
      let x = document.activeElement.tagName;
      if (x != 'INPUT') {
        if (navigator['clipboard']) {
          navigator['clipboard'].readText().then(data => {
            this.copyValue = data;
            this.getCopiedData();
          })
        }
      }
    }
  }
  getCopiedData() {
    let records = this.copyValue.split('\n');
    this.copyVaueToOrginalVal = []
    for (let i = 0; i < records.length; i++) {
      if (records[i]) {
        let rowArray = records[i].trim().split('\t');
        this.copyVaueToOrginalVal.push({ parameterName: (!!rowArray[0] ? rowArray[0] : ""), customHeader: (!!rowArray[1] ? rowArray[1] : "") });
      }
    }
    let priceList = [];

    this.allPriceNameList.forEach(d => {
      let pname = d.masterName || d.name;

      this.pricingExtCol.forEach(x => {
        let pName = pname + x
        let obj = {
          'name': pName,
          'id': d.id,
          'ptype': 'child'
        }
        priceList.push(obj)
      })

      let pobj = {
        'name': pname,
        'id': d.id,
        'ptype': 'parent'
      }
      priceList.push(pobj)
    })

    let data = this.getAllStoneParam.concat(priceList);
    data.forEach(d => {
      this.copyVaueToOrginalVal.forEach(e => {
        if ((d.name && d.name.toLowerCase()) == e.parameterName.toLowerCase()) {
          e['id'] = d.id;
          e['parameterName'] = d.name;
          e['type'] = 'price';
          e['ptype'] = d.ptype;
        }
        if ((d.masterName && d.masterName.toLowerCase()) == e.parameterName.toLowerCase()) {
          e['id'] = d.id;
          e['parameterName'] = d.masterName;
          e['type'] = 'param';
        }
      });
    });
    const resultData = [];
    const map = new Map();
    for (const item of this.copyVaueToOrginalVal) { // unique parameterName
      if (!map.has(item.parameterName)) {
        map.set(item.parameterName, true);    // set any value to Map
        resultData.push(item);
      }
    }
    // console.log(this.copyVaueToOrginalVal);
    this.setDatatoDropdown(resultData);
  }

  setDatatoDropdown(data) {
    this.parameterIdList = [];
    this.priceIdList = [];
    this.listOfParameters = [];
    this.priceList = "";
    data.forEach(d => {
      let obj = {}
      if (d.type === 'param') {
        obj = { id: d.id, masterName: d.parameterName, headerName: d.customHeader, 'fromDB': false }
        this.parameterIdList.push(obj);
      }
      if (d.type === 'price') {
        obj = { id: d.id, name: d.parameterName, headerName: d.customHeader, priceListNameMasterId: d.id, 'ptype': d.ptype, 'fromDB': false }
        this.priceIdList.push(obj);
      }
      if (d.hasOwnProperty('id')) {
        this.listOfParameters.push(obj);
      }
    });
    this.priceIdList = this.priceIdList.filter(x => x.ptype != 'child');

    this.priceList = this.priceIdList.map(d => d.id).join();
    console.log(this.priceList)
    console.log(data);
    console.log(this.listOfParameters);
  }
  async ngOnInit() {
    this.flagAddUpdate = this.navParams.get('flag');
    this.columns = [{
      name: "Sr No", cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, { name: "Operator", prop: "operator" }, { name: "Name", prop: "functionName" }, { name: "Amount", prop: "amount" }, { name: "Order", prop: "paramOrder" }]

    this.getAllPricingFunList()
    let data = this.navParams.get('item');
    console.log(data)
    if (data != 0) {
      this.item = data.row;
      this.id = this.item.id;
      this.newFormat = this.item.newFormat;
      this.websiteFormate = this.item.websiteFormate;
    }
    this.isDefault = this.navParams.get('isDefault');
    this.isExportBtn = this.navParams.get('isExportBtn');

    if (this.flagAddUpdate == 'update' || this.flagAddUpdate == 'copy') {
      await this.getTemplateDataById();
    } else {
      await this.getAllStoneMaster();
    }
    await this.getAllPriceListName()
    console.log(this.listOfParameters)

  }

  async merge_array(arr1, arr2) {
    // loop over arr2, add the elements of array2 if it doesn't exist in array1
    var newArr = arr2.reduce((acc, eachArr2Elem) => {
      if (arr1.findIndex((eachArr1Elem) => eachArr1Elem.id === eachArr2Elem.id && eachArr1Elem.name === eachArr2Elem.name) === -1) {
        acc.push(eachArr2Elem)
      }
      return acc
    }, [...arr1]);
    return newArr
  }

  async dropDownSelection(ev) {
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    console.log(ev)
    if (property == 'configOptions') {
      if (idList.length > 0) {
        let name = obj[0].name;
        this.configType = obj[0].name;
        this.customObj.configType = name.charAt(0)
      } else {
        this.fileType = null;
      }
    } else if (property == 'parameterList') {
      if (idList.length > 0) {
        // let deselectIds = obj.map(el => el.id)
        // this.listOfParameters = this.listOfParameters.filter(el => {
        //   return deselectIds.filter(item => el.id == item.id).length > 0
        // });
        // console.log(this.listOfParameters)
        // this.listOfParameters = this.listOfParameters.filter(el => el.type == 'price' || obj.map(e => e.masterName.toLowerCase())
        //   .includes(el.masterName))
        // this.parameterIdList = this.parameterIdList.filter(el => obj.map(e => e.masterName.toLowerCase()).includes(el.masterName.toLowerCase()))

        let newParamArr = obj.filter(el => this.listOfParameters.filter(item => item.masterName == el.masterName).length == 0)
        newParamArr.forEach(el => {
          let isExist = this.listOfParameters.find(item => item.masterName == el.masterName);
          if (!isExist) {
            this.listOfParameters.push({ "id": el.id, "masterName": el.masterName, "type": 'param', "fromDB": false })
          }
        })

        this.listOfParameters = this.listOfParameters.filter(el => el.type == 'price' || el.ptype == 'custom' || obj.map(e => e.masterName).includes(el.masterName))

        // this.parameterIdList = await this.merge_array(this.parameterIdList, newParamArr);
        // console.log(this.parameterIdList, this.listOfParameters)
        // this.parametersObj = obj;
        // let pram = await this.parametersObj.map(c => {
        //   return c.masterName
        // })
        // console.log("pram", pram)
        // let paramsListObject = this.listOfParameters.filter(x => x.type == 'param')
        // console.log("paramsListObject", paramsListObject)
        // let selectedParameters = await this.listOfParameters.map(x => {
        //   if (x.type == 'param' && x.name) {
        //     return x.name
        //   }

        //   if (x.type == 'param' && x.masterName) {
        //     return x.masterName
        //   }

        // })
        // console.log("selectedParameters", selectedParameters)
        // selectedParameters = await selectedParameters.filter(function (element) {
        //   return element !== undefined;
        // });
        // console.log("selectedParameters2", selectedParameters)
        // let val = pram.filter(x => !selectedParameters.includes(x));
        // console.log("val", val)
        // let object = this.parametersObj.filter(x => x.masterName == val)[0];
        // console.log(object)
        // if (object) {
        //   this.listOfParameters.push({ "id": object.id, "name": object.masterName, "type": 'param' })
        // }


        // if (paramsListObject.length == 0 && !object) {
        //   this.parametersObj.forEach(object => {
        //     this.listOfParameters.push({ "id": object.id, "name": object.masterName, "type": 'param' })
        //   })
        // }

        // let val1 = selectedParameters.filter(x => !pram.includes(x))[0];
        // if (val1) {
        //   this.listOfParameters = this.listOfParameters.filter(s => {
        //     if (s.name) {
        //       if (s.name != val1) {
        //         return s
        //       }
        //     }

        //     if (s.masterName) {
        //       if (s.masterName != val1) {
        //         return s
        //       }
        //     }
        //   })
        // }
        // this.parameterIdList = obj
      } else {
        this.parameterIdList = []
        this.parametersObj = []
        //this.listOfParameters = await this.merge_array(this.priceListObj, this.parametersObj);
        this.listOfParameters = this.listOfParameters.filter(x => x.type != 'param')
      }

    } else if (property == 'exportFile') {
      if (idList.length > 0) {
        this.fileType = obj[0].name;
      } else {
        this.fileType = null;
      }
    } else if (property == 'operation') {
      if (idList.length > 0) {
        this.operator = obj[0].name;
      } else {
        this.fileType = null;
      }
    } else if (property == 'function') {
      if (idList.length > 0) {
        this.functionName = obj[0].functionName;
      } else {
        this.fileType = null;
      }
    } else if (property == 'price') {
      if (idList.length > 0) {
        // this.priceIdList = idList;
        this.priceListObj = obj;
        let pram = await this.priceListObj.map(c => {
          return c.name
        })

        // let newPram = []
        // pram.forEach(c => {
        //   this.pricingExtCol.forEach(n => {
        //     let name = c + n
        //     newPram.push(name)
        //   })
        // })
        // pram = newPram
        this.listOfParameters = await this.listOfParameters.filter(x => {
          if (x.type == 'price') {
            console.log(x.name)
            if (!!x.pName) {
              let val = this.listOfParameters.find(i => i.name == x.pName.replaceAll(" ", "") && i.type == 'price')
              console.log(val)
              if (val) {
                return x
              }
            }
          } else {
            return x;
          }
        })

        console.log(this.listOfParameters)
        console.log('--->>>>>>>')
        let priceListObject = this.listOfParameters.filter(x => x.type == 'price')
        let sp = await this.listOfParameters.filter(i => i.ptype != 'child')
        console.log(sp)
        let selectedParameters = sp.map(x => {
          if (x.type == 'price' && x.name) {
            return x.pName
          }

          if (x.type == 'price' && x.masterName) {
            return x.pName
          }

        })
        console.log(selectedParameters)
        console.log('888888888888')
        selectedParameters = await selectedParameters.filter(function (element) {
          return element !== undefined;
        });
        let val = pram.filter(x => !selectedParameters.includes(x));
        // console.log(pram)
        // console.log(selectedParameters)
        // let val = pram.filter(x => {
        //   let r = x.replaceAll(" ", "")
        //   if (r = !selectedParameters.includes(r)) {
        //     return x
        //   }
        // });
        console.log(val)
        let object = this.priceListObj.filter(x => x.name == val)[0];
        if (object) {

          let paramChild = []
          let allPriceCol = this.pricingExtCol.map(x => {
            let pName = object.name.replaceAll(" ", "") + x
            return pName
          })
          // allPriceCol.forEach(n => {
          //   paramChild.push({ "parentId": object.id, "name": n, "type": 'price', 'pName': object.name, })
          // })
          // this.listOfParameters.push({ "id": object.id, "name": object.name, "type": 'price', 'paramChild': paramChild, 'hasChild': true })

          allPriceCol.forEach(n => {
            this.listOfParameters.push({ "id": object.id, "name": n, "type": 'price', 'pName': object.name, 'ptype': 'child', 'fromDB': false })
          })
          this.listOfParameters.push({ "id": object.id, "name": object.name.replaceAll(" ", ""), "type": 'price', 'pName': object.name, 'ptype': 'parent', 'fromDB': false })
          //debugger

        }

        if (priceListObject.length == 0 && !object) {
          this.priceListObj.forEach(object => {

            //this.listOfParameters.push({ "id": object.id, "name": object.name, "type": 'price' })   
            let paramChild = []
            let allPriceCol = this.pricingExtCol.map(x => {
              let pName = object.name + x
              return pName
            })
            allPriceCol.forEach(n => {
              this.listOfParameters.push({ "id": object.id, "name": n, "type": 'price', 'pName': object.name, 'ptype': 'child', 'fromDB': false })
            })
            this.listOfParameters.push({ "id": object.id, "name": object.name, "type": 'price', 'pName': object.name, 'ptype': 'parent', 'fromDB': false })
            //this.listOfParameters.push({ "id": object.id, "name": object.name, "type": 'price', 'hasChild': true })

            // allPriceCol.forEach(n => {
            //   paramChild.push({ "parentId": object.id, "name": n, "type": 'price', 'pName': object.name, })
            // })
            // this.listOfParameters.push({ "id": object.id, "name": object.name, "type": 'price', 'paramChild': paramChild, 'hasChild': true })

          })
        }

        let val1 = selectedParameters.filter(x => !pram.includes(x))[0];
        console.log(val1)
        if (val1) {
          val1 = val1.replaceAll(" ", "")
          this.listOfParameters = this.listOfParameters.filter(s => {
            if (s.name) {
              if (s.name != val1 && s.name != val1 + 'DiscountPercent' && s.name != val1 + 'percarat') {
                return s
              }
            }
            if (s.masterName) {
              if (s.masterName != val1 && s.name != val1 + 'DiscountPercent' && s.name != val1 + 'percarat') {
                return s
              }
            }
          })
        }
        this.priceList = idList.toString();

      } else {
        this.priceList = null;
        this.priceListObj = []
        this.listOfParameters = this.listOfParameters.filter(x => x.type != 'price')
      }
    }
    console.log(this.listOfParameters)
    // this.listOfParametersDisplay = this.listOfParameters.filter(x => !x.hasChild)
  }

  async getTemplateDataById() {
    await this.getAllStoneMaster()
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findTemplateById(this.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.dismiss();
      let dataValue = data.data;
      this.fileType = dataValue.fileType;
      let data1 = [];
      let paramList = dataValue.listOfParameters;
      this.parameterIdList = []
      if (paramList) {
        this.listOfParameters = []
        paramList.forEach(el => {
          data1.push(el.masterName)
        })
        this.listOfParameters = (paramList)
        console.log(this.listOfParameters)
        this.parametersObj = this.listOfParameters;
        this.listOfParameters = await this.listOfParameters.map(x => {
          if (x.priceListNameMaster) {
            let ptype = 'child'
            console.log(x.masterName)
            if (x.priceListNameMaster.replaceAll(" ", "") == x.masterName) {
              ptype = 'parent'
            }
            //x['type'] = 'price';
            //x['name'] = x.masterName;
            // x['pName'] = x.priceListNameMaster;
            //['ptype'] = 'child'
            return {
              "id": x.priceListNameMasterId, "paramOrder": x.paramOrder, "name": x.masterName, "type": 'price', 'pName': x.priceListNameMaster, 'ptype': ptype, 'headerName': x.headerName, 'fromDB': false
            }
          } else if (x.configType) {
            x['ptype'] = 'custom';
            x['name'] = x.masterName;
            x['type'] = 'customParam'
            delete x.masterName
            return x
          } else {
            x['type'] = 'param'
            return x
          }

        })
        // this.listOfParametersDisplay = this.listOfParameters.filter(x => !x.hasChild)
        data1.forEach(el => {
          this.getAllStoneParam.filter(item => {
            if (!!item.masterName && !!el) {
              if (item.masterName.toLowerCase() == el.toLowerCase()) {
                let data = { id: item.id, masterName: item.masterName }
                this.parameterIdList.push(data);
              }
            }
          })
        })
        console.log(this.listOfParameters)
      }
      // parameterIdList
      if (this.fileType) {
        this.exportIdList = [];
        this.fileList.forEach(el => {
          if (el.name == dataValue.fileType) {
            let data = { id: el.id, name: el.name };
            console.log(data)
            this.exportIdList.push(data)
          }
        })
      }
      if (this.flagAddUpdate != 'copy') {
        this.templateName = dataValue.templateName;
      }
      this.listOfTemplatePricingModifications = !!dataValue.listOfTemplatePricingModifications ? dataValue.listOfTemplatePricingModifications : [];
      if (!!dataValue.listOfTemplateStoneFilterCriterias && dataValue.listOfTemplateStoneFilterCriterias.length > 0) {
        this.fromCriteria = dataValue.listOfTemplateStoneFilterCriterias[0].fromCriteria;
        this.toCriteria = dataValue.listOfTemplateStoneFilterCriterias[0].toCriteria
      }
    }
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
      console.log(this.item.priceList)
      if (!!this.item.priceList) {
        this.priceList = this.item.priceList
        this.priceIdList = []
        this.allPriceNameList.filter(el => {
          let isExist = this.item.priceList.includes(el.id)
          if (isExist) {
            this.priceListObj.push({ id: el.id, name: el.name })
            this.priceIdList.push({ id: el.id, name: el.name })
            //this.listOfParameters.push({ "id": el.id, "name": el.name, "type": 'price', 'pName': el.name, 'ptype': 'parent' })
            //this.listOfParameters.push({ "id": el.id, "name": el.name, "type": 'price', 'hasChild': true })
          }
        })
      } else {
        this.priceList = '';
      }
    }
  }

  async getAllStoneMaster() {
    let res: any;
    res = await this.httpApiService.getAllParameterOfStones();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllStoneParam = data.data;
    }
  }


  async getAllPricingFunList() {
    let res: any;
    res = await this.httpApiService.pricingFunList();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfPricingFun = data.data;
    }
  }


  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  exportTemplate(evt) {
    let exportTemplateData = [];
    console.log(this.allPriceNameList)
    let priceList = [];

    this.allPriceNameList.forEach(d => {
      let pname = d.masterName || d.name;

      this.pricingExtCol.forEach(x => {
        let pName = pname + x
        let obj = {
          'name': pName,
          'id': d.id
        }
        priceList.push(obj)
      })

      let pobj = {
        'name': pname,
        'id': d.id
      }
      priceList.push(pobj)
    })
    this.exportData = this.getAllStoneParam.concat(priceList);
    if (this.exportData.length > 0) {
      this.exportData.forEach((d, k) => {
        let obj = {
          "Parameters": "",
          "Custom Header Name": "",
        }
        if (obj['Parameters'] == "" && d['name'] != undefined) {
          obj['Parameters'] = d['name'];
        } else if (obj['Parameters'] == "" && d['masterName'] != undefined) {
          obj['Parameters'] = d['masterName'];
        }
        if (d.type == "price") {
          console.log(d['name'])
        }
        exportTemplateData.push(obj);
      });
      this.exportAsExcelService.exportAsExcelFile(exportTemplateData, 'Manage Templates');
    } else {
      console.log('No Data');
      console.log(this.exportData);
    }
  }

  reOrder($event) {
    this.listOfParameters = this.listOfParametersDisplay
  }

  dragulaServiceModel() {
    let selector = "bag" + Math.random();
    this.dragulaService.createGroup(selector, {
      moves: function (el, container, handle) {
        return handle.className.indexOf("handle") > -1;
      },
      mirrorContainer: document.getElementsByTagName("ion-app")[0]
    });
    this.dragulaService.dropModel("bag").subscribe(value => {
      var objLength = this.listOfParameters.length;
      var i = 0,
        changePosition = [];
      for (i = 0; i < objLength; i++) {
        if (this.listOfParameters[i].id !== this.copy[i].id) {
          changePosition.push({
            key: this.listOfParameters[i].id,
            value: i
          });
        }
      }
    });
    this.cloneBoxDetails(this.parameterIdList);
    console.log(this.parameterIdList)
  }

  cloneBoxDetails(data) {
    this.listOfParameters = [];
    this.listOfParameters = data;
    this.copy = Object.assign({}, this.listOfParameters);
  }

  async addTemplate() {
    console.log(this.parameterIdList)
    let validate = this.validateControls();

    let finalparams = [];
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      for (let i = 0; i < this.listOfParameters.length; i++) {
        if (!this.listOfParameters[i]['fromDB']) {
          this.listOfParameters[i]['fromDB'] = false;
        }
        if (this.listOfParameters[i].name) {
          if (this.listOfParameters[i].type == 'price') {
            this.listOfParameters[i]['priceListNameMasterId'] = this.listOfParameters[i].id;
          }
          this.listOfParameters[i]['masterName'] = this.listOfParameters[i].name;
          delete this.listOfParameters[i]['name'];
          delete this.listOfParameters[i]['pName'];
          delete this.listOfParameters[i]['ptype'];
        }
        this.listOfParameters[i].paramOrder = i + 1;
      }
      if (!!this.fromCriteria && !!this.toCriteria) {
        this.listOfTemplateStoneFilterCriterias.push({
          "paramName": "perCts",
          "fromCriteria": this.fromCriteria,
          "toCriteria": this.toCriteria
        })
      }
      console.log(this.listOfParameters);
      let jsonObj = {
        fileType: this.fileType,
        templateName: this.templateName,
        isDefault: this.isDefault,
        listOfParameters: this.listOfParameters,
        newFormat: this.newFormat,
        websiteFormate: this.websiteFormate,
        priceList: !!this.priceList ? this.priceList : '',
        listOfTemplatePricingModifications: this.listOfTemplatePricingModifications,
        listOfTemplateStoneFilterCriterias: this.listOfTemplateStoneFilterCriterias
      }
      console.log(jsonObj)

      res = await this.httpApiService.saveTemplate(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.message);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async updateTemplate() {
    console.log(this.listOfParameters)
    let validate = this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      for (let i = 0; i < this.listOfParameters.length; i++) {
        if (this.listOfParameters[i].name) {
          if (this.listOfParameters[i].type == 'price') {
            this.listOfParameters[i]['priceListNameMasterId'] = this.listOfParameters[i].id;
          }
          this.listOfParameters[i]['masterName'] = this.listOfParameters[i].name;
          delete this.listOfParameters[i]['name'];
          delete this.listOfParameters[i]['pName'];
          delete this.listOfParameters[i]['ptype'];
        }
        this.listOfParameters[i].paramOrder = i + 1;
      }
      if (!!this.fromCriteria && !!this.toCriteria) {
        this.listOfTemplateStoneFilterCriterias.push({
          "paramName": "perCts",
          "fromCriteria": this.fromCriteria,
          "toCriteria": this.toCriteria
        })
      }
      let jsonObj = {
        id: this.id,
        fileType: this.fileType,
        isDefault: this.isDefault,
        templateName: this.templateName,
        newFormat: this.newFormat,
        websiteFormate: this.websiteFormate,
        listOfParameters: this.listOfParameters,
        priceList: !!this.priceList ? this.priceList : '',
        listOfTemplatePricingModifications: this.listOfTemplatePricingModifications,
        listOfTemplateStoneFilterCriterias: this.listOfTemplateStoneFilterCriterias
      }
      console.log(jsonObj)
      res = await this.httpApiService.updateTemplate(jsonObj, this.id);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.message);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async validateControls() {
    if (!!this.fileType) {
      // if (this.parameterIdList.length > 0) {
      if (!!this.templateName) {
        return true;
      } else {
        await this.configService.showToast('error', 'Please insert Template name')
        return false;
      }
      // } else {
      //   await this.configService.showToast('error', 'Please select parameter')
      //   return false;
      // }
    } else {
      await this.configService.showToast('error', 'Please select file type')
      return false;
    }
  }

  addCriteria() {
    this.listOfTemplatePricingModifications.push({
      operator: this.operator,
      functionName: this.functionName,
      amount: this.amount,
      paramOrder: this.listOfTemplatePricingModifications.length + 1,
    })
    this.funIdList = [];
    this.operationIdList = []
    this.amount = 0;
    this.functionName = '';
    this.operator = '';
  }

  deleteItem(ev) {
    console.log(ev)
    if (ev.row.data.functionName) {
      let index = this.listOfTemplatePricingModifications.findIndex(el => el.functionName == ev.row.data.functionName)
      if (index > -1) {
        console.log(index)
        this.listOfTemplatePricingModifications.splice(index, 1)
        console.log(this.listOfTemplatePricingModifications)
      }
    }
  }


  remove(item) {
    console.log(item)
    if (item.ptype == 'child') {
      console.log(item)
      //let parentId = item.parentId;
      let pName = item.pName;
      let name = item.name;
      let deleteP = false;
      this.listOfParameters = this.listOfParameters.filter(x => x.name != name);
      //this.listOfParametersDisplay = this.listOfParameters.filter(x => x.name != name && !x.hasChild);

      let findChild = this.listOfParameters.filter(x => x.pName == pName);
      console.log(this.listOfParameters)
      if (findChild.length == 0) {
        console.log('delete parent');
        console.log(this.priceListObj)
        //let parent = this.listOfParameters.find(x => x.name == pName).id;
        this.listOfParameters = this.listOfParameters.filter(x => x.name != pName)
        this.priceListObj = this.priceListObj.filter(x => x.name != pName)
        console.log(this.priceListObj)
      }
      console.log(this.listOfParameters)

    } else {
      let name = item.name;
      this.listOfParameters = this.listOfParameters.filter(x => x.name != name);
    }

  }

  reorderFeatures($event) {
    console.log($event)
    //this.listOfParametersDisplay = $event.filter(x => x)
    console.log(this.listOfParameters)
  }

  addCustom(obj) {
    let newObj = obj
    newObj["ptype"] = "custom"
    console.log(newObj)
    this.listOfParameters.push(newObj)
    console.log(this.listOfParameters)
    this.customObj = {
      "name": "",
      "headerName": "",
      "type": "customParam",
      "configType": "",
      "configValue": "",
      "fromDB": false,
    }
    this.configIdList = [];
  }
}

