import { Component, OnInit, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  Events,
  ToastController
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { IonicSelectableComponent } from "ionic-selectable";
import { SelectSchedulerTimePage } from "../../actions/select-scheduler-time/select-scheduler-time.page";
@Component({
  selector: 'app-manage-ftp-master',
  templateUrl: './manage-ftp-master.page.html',
  styleUrls: ['./manage-ftp-master.page.scss'],
})
export class ManageFtpMasterPage implements OnInit {
  @ViewChild('selectableComponent') selectableComponent: IonicSelectableComponent;
  public query: any;
  public exportTemplateId: any;
  // public ftpAction: any;
  public partyId: any;
  public isActive: boolean = false;
  public ftpUsername: any;
  public ftpPort: any;
  public ftpPwd: any;
  public actionId: any;
  public searchHistory: any;
  public selectedSavedSearch: any;
  public listOfTemplates = [];
  public listOfActions = [];
  public fileName = '';
  public userData: any;
  public getAllCustomers: any;
  public flagAddUpdate: any;
  public item: any;
  public id: any;
  public customers: any = [];
  public listOfCustomers: any = [];
  public selectedParty: any;
  public selectedExportTemplate: any;
  public selectedUserIds = []
  public serverUrl: any;
  public pageNumber = 0;
  public noOfRecords = 20;
  public count: Number = 0;
  public runningTime: any;
  public authUrl: string = '';
  public savedSearchId: any;
  public accessConfig: any;
  public listOfUsers: any = [];
  public emailIds: string;
  public settingsDate = {
    bigBanner: true,
    timePicker: false,
    format: 'HH:MM',
    defaultOpen: false,
  }
  public selectedExportVia: any;
  public exportViaList: any = [
    {
      id: 1,
      "name": 'Email'
    },
    {
      id: 2,
      "name": 'FTP'
    },
    {
      id: 3,
      "name": 'Service'
    },
    {
      id: 4,
      "name": 'WASABI'
    },
    {
      id: 5,
      "name": 'API'
    },
    {
      id: 6,
      "name": 'SFTP'
    }
  ];
  public exportIds = [];
  public selectedSavedSearchIds = [];
  public exportTemplateIds = [];
  public schedulerData: any;
  public frequency: any;
  public subject: any;
  public body: any;
  public userId: any = null;
  public history = {
    'data': [],
    'columns': [
      {
        "name": "Task Type",
        "prop": "taskType",
      },
      {
        "name": "Date",
        "prop": "modifiedDate",
      },
      {
        "name": "Status",
        "prop": "status",
      },
    ]
  }
  constructor(
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public toastCtrl: ToastController,
    public authService: AuthenticationService,
    public configService: ConfigService,
    public navParams: NavParams,
    public event: Events
  ) {
  }

  async ngOnInit() {

    this.flagAddUpdate = this.navParams.get('flag');
    await this.getAllActions();
    await this.getAllUser();
    await this.getAllSearches();
    await this.getAllTemplates();

    // await this.getAllCustomer('', this.pageNumber, this.noOfRecords);
    // await this.getAllCustomerList();
    console.log(this.flagAddUpdate)
    if (this.flagAddUpdate == 'update') {
      let data = this.navParams.get('item');
      console.log(data)
      if (data != 0) {
        this.item = data;
        this.id = this.item.id;


        if (this.item.savedSearchId) {
          this.searchHistory.filter(el => {
            if (el.id == this.item.savedSearchId) {
              this.selectedSavedSearchIds = [];
              this.selectedSavedSearchIds.push({ id: el.id, searchTitle: el.searchTitle })
            }
          })
        }

        if (this.item.exportVia) {
          this.exportViaList.filter(el => {
            if (el.name == this.item.exportVia) {
              this.exportIds = []
              this.exportIds.push({ id: el.id, name: el.name })
            }
          })
        }

        if (this.item.userId) {
          this.listOfUsers.filter(el => {
            if (el.id == this.item.userId) {
              this.selectedUserIds = []
              this.selectedUserIds.push({ id: el.id, username: el.username })
            }
          })
        }

        this.listOfCustomers.filter(el => {
          if (el.id == this.item.partyMasterId) {
            this.selectedParty = el;
          }
        })

        this.listOfTemplates.filter(el => {
          if (el.id == this.item.exportTemplateId) {
            this.exportTemplateIds = [];
            this.exportTemplateIds.push({ id: el.id, templateName: el.templateName })
          }
        })

        this.getHistoryData(this.id)
        this.partyId = this.item.partyMasterId;
        this.exportTemplateId = this.item.exportTemplateId;
        // this.ftpAction = this.item.action;
        this.isActive = Boolean(this.item.isActive)
        this.ftpUsername = this.item.userName;
        this.ftpPort = this.item.port;
        this.ftpPwd = this.item.password;
        this.serverUrl = this.item.serverUrl;
        this.authUrl = this.item.authUrl;
        this.fileName = this.item.fileName;
        this.emailIds = this.item.emailIds;
        this.runningTime = JSON.parse(this.item.runningTime);
        this.savedSearchId = this.item.savedSearchId;
        this.selectedExportVia = this.item.exportVia;
        this.userId = this.item.userId;
        this.subject = this.item.subject;
        this.body = this.item.body;
        if (!!this.item.accessConfig) {
          this.accessConfig = this.item.accessConfig
        }
        this.schedulerData = JSON.parse(this.item.runningTime)
        if (!!this.schedulerData && this.schedulerData.frequency) {
          this.frequency = this.schedulerData.frequency;
        }
      }
    }
  }


  closeModal() {
    this.modalCtrl.dismiss()
  }

  dropDownSelection(ev) {
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;

    if (property == 'exportList') {
      if (idList.length > 0) {
        this.selectedExportVia = obj[0].name;
      } else {
        this.selectedExportVia = "";
      }
    } else if (property == 'savedSearch') {
      if (idList.length > 0) {
        this.savedSearchId = idList[0]
      } else {
        this.savedSearchId = null;
      }
    } else if (property == 'exportTemplate') {
      if (idList.length > 0) {
        this.exportTemplateId = idList[0]
      } else {
        this.exportTemplateId = null;
      }
    } else if (property == 'userSelection') {
      if (idList.length > 0) {
        this.userId = idList[0]
      } else {
        this.userId = null;
      }
    }
  }

  async getAllCustomer(name, pageNumber, noOfRecords) {
    let res: any;
    if (!!name) {
      this.pageNumber = 0;
    }
    res = await this.httpApiService.getAllCustomerBypage(name, pageNumber, noOfRecords);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customers = data.data.content;
      this.count = data.data.totalElements;
    }
  }

  async getHistoryData(ftpId) {
    let res: any;

    res = await this.httpApiService.getHistoryData(ftpId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.history.data = data.data;
    }
  }

  // async getAllCustomerList() {
  //   let res: any;
  //   res = await this.httpApiService.getAllCustomerList();
  //   let data = res.json();
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     this.listOfCustomers = data.data;
  //     console.log(this.listOfCustomers)
  //   }

  // }

  async searchParty(event: { component: IonicSelectableComponent; text: string }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    await this.getAllCustomer(searchText, this.pageNumber, this.noOfRecords);
  }

  async getAllActions() {
    let res: any;
    this.userData = await this.authService.userData();
    res = await this.httpApiService.getAllActionsByUserID(this.userData.userId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfActions = data.data;
    }
  }

  async getAllSearches() {
    let actionArray = this.listOfActions.filter(function (item) {
      if (item.actionName == "Search") {
        return item.actionName;
      }
    });
    if (actionArray.length > 0) {
      let res: any;
      res = await this.httpApiService.findAllSearch(actionArray[0].id);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.searchHistory = data.data;
      }
    }

  }

  async getAllTemplates() {
    let res: any;
    res = await this.httpApiService.findAllTemplate(false)
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfTemplates = data.data;
    }
  }

  async savedSearch(event) {
    try {
      this.savedSearchId = event.value.id
    } catch (e) {
      console.log(e);
    }
  }

  // async selectExportVia(event) {
  //   console.log(event.value)
  //   this.selectedExportVia = event.value.id
  // }

  // async selectExportTemplate(event) {
  //   console.log(event.value)
  //   this.exportTemplateId = event.value.id
  // }

  async onChangeParty(event) {
    console.log(event.value)
    this.partyId = event.value.id
  }

  async validateControls() {
    if (
      this.savedSearchId &&
      this.exportTemplateId &&
      // this.ftpAction &&
      this.partyId &&
      (this.selectedExportVia == 'FTP' ? (this.ftpUsername && this.ftpPwd && this.ftpPort) : true)
    ) {
      return true;
    } else {
      await this.configService.showToast("error", "Please insert all the fields.");
      return false;
    }
  }

  async updateFtp() {
    let validate = await this.validateControls();
    if (validate) {
      let obj = {
        "savedSearchId": this.savedSearchId,
        "exportTemplateId": this.exportTemplateId,
        "partyMasterId": this.partyId,
        "serverUrl": this.serverUrl,
        // "action": this.ftpAction,
        "userName": this.ftpUsername,
        "port": this.ftpPort,
        "password": this.ftpPwd,
        "isActive": this.isActive,
        "runningTime": JSON.stringify(this.runningTime),
        "exportVia": this.selectedExportVia,
        "subject": this.subject,
        "body": this.body,
        "authUrl": this.authUrl,
        "accessConfig": "",
        "userId": this.userId,
        "emailIds": this.emailIds,
        "fileName": this.fileName
      }
      if (obj.body)
        obj.body = obj.body.replace(/[\n\r]/g, '\\n');
      if (!!this.accessConfig) {
        obj.accessConfig = JSON.stringify(JSON.parse(this.accessConfig))
      }
      console.log(obj)
      await this.configService.showLoader();
      let res;
      res = await this.httpApiService.updateFtp(obj, this.id);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.message);
      } else if (!data.isSuccess) {
        await this.configService.showToast(
          "error",
          data.data
        );

      } else {
        await this.configService.showToast(
          "success",
          data.data
        );
        await this.closeModal();
      }

    } else {

    }
  }

  async addFtp() {
    console.log(this.runningTime, this.accessConfig)
    let validate = await this.validateControls();
    if (validate) {
      let obj = {
        "savedSearchId": this.savedSearchId,
        "exportTemplateId": this.exportTemplateId,
        "partyMasterId": this.partyId,
        "serverUrl": this.serverUrl,
        // "action": this.ftpAction,
        "userName": this.ftpUsername,
        "port": this.ftpPort,
        "password": this.ftpPwd,
        "isActive": this.isActive,
        "runningTime": JSON.stringify(this.runningTime),
        "exportVia": this.selectedExportVia,
        "subject": this.subject,
        "body": this.body,
        "authUrl": this.authUrl,
        "accessConfig": "",
        "userId": this.userId,
        "emailIds": this.emailIds,
        "fileName": this.fileName
      }
      if (obj.body)
        obj.body = obj.body.replace(/[\n\r]/g, '\\n');
      if (!!this.accessConfig) {
        obj.accessConfig = JSON.stringify(JSON.parse(this.accessConfig))
      }
      console.log(obj)
      await this.configService.showLoader();
      let res;
      res = await this.httpApiService.addFtp(obj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.message);
      } else if (!data.isSuccess) {
        await this.configService.showToast(
          "error",
          data.data
        );

      } else {
        await this.configService.showToast(
          "success",
          data.data
        );
        await this.closeModal();
      }

    } else {

    }
  }

  async getAllUser() {
    let res: any;
    let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllUser(fromLocation);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfUsers = data.data;
    }
  }

  async openTimeModal() {
    // let modal1 = await this.modalCtrl.create({
    //   component: SelectSchedulerTimePage,
    //   backdropDismiss: true,
    //   cssClass: "largePage",
    //   componentProps: { }
    // })
    // modal1.onDidDismiss().then((d: any) => this.handleModalDismiss(d));

    const modal1 = await this.modalCtrl.create({
      component: SelectSchedulerTimePage,
      backdropDismiss: false,
      cssClass: 'largePage',
      componentProps: { 'schedulerData': this.schedulerData },
    });
    modal1.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal1.present();
  }

  async handleModalDismiss(d) {
    if (d.data) {
      this.runningTime = d.data;
      this.frequency = d.data.frequency
    }

  }
}
