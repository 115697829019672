export default {
  reportNo: "R-4",
  url: "master-service/api/report/boxStockData",
  method: "GET",
  size: 3,
  fields: [
    {
      title: " Department Name ",
      input: "select",
      key: "departmentId",
      placeholder: "Select Department Type",
      property: "departmentId",
      keyName: "id",
      name: "name",
      required: true,
      singleSelection: true,
      function: "getAllDepartment",
      default: [],
      size: 3,
    },
    {
      title: " Trans Type ",
      input: "select",
      key: "transType",
      placeholder: "Select Trans Type",
      property: "transType",
      keyName: "name",
      name: "name",
      required: true,
      singleSelection: true,
      function: "getAllTransType",
      default: [],
      size: 3,
    },
  ],
  columns: [
    {
      name: "Box Name",
      prop: "boxLabel",
      displayFormat: "Total",
      showInGroupFooter: true,
    },
    {
      name: "On Hand Cts",
      prop: "onHandCts",
      displayFormat: "{0}",
      summaryType: "sum",
      showInGroupFooter: true,
    },
    {
      name: "UnConfirmed Cts",
      prop: "unConfirmedCts",
      displayFormat: "{0}",
      summaryType: "sum",
      showInGroupFooter: true,
    },
    {
      name: "Outstanding Cts",
      prop: "outStandingCts",
      displayFormat: "{0}",
      summaryType: "sum",
      showInGroupFooter: true,
    },
    {
      name: "Total Cts",
      prop: "totalCts",
      displayFormat: "{0}",
      summaryType: "sum",
      dynamic: ["onHandCts", "+", "outStandingCts"],
      showInGroupFooter: true,
    },
  ],
  subColumns: {
    url: "master-service/api/report/stockByBox",
    obj: {
      boxId: ["id"],
      boxName: "",
      transType: "transType",
    },
    showPivotGrid: true,
    fields: [
      {
        caption: "Stone Id",
        dataField: "stoneId",
        area: "row",
        sortBySummaryField: "Total",
      },
      {
        caption: "Status",
        dataField: "status",
        dataType: "String",
        area: "column",
      },
      {
        caption: "Total",
        dataField: "stockCts",
        dataType: "number",
        summaryType: "sum",
        area: "data",
        format: {
          type: "fixedPoint",
          precision: 3,
        },
      },
    ],
  },
};
