export default {
    reportNo: 'R-27',
    url: "master-service/api/report/assortment-report-mix",
    method: "GET",
    size: 3,
    fields: [
    ],
    columns: [
    ],
    dataStudioLink: 'https://lookerstudio.google.com/embed/reporting/c2533dc5-aaa7-4eba-b148-12ee9444ae05/page/cdAmD'
  };