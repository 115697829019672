import { Component, OnInit, HostListener } from "@angular/core";

import { ModalController, Events, LoadingController } from "@ionic/angular";

import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { CreateWebsiteUserComponent } from "../create-website-user/create-website-user.component";

@Component({
  selector: "app-website-users",
  templateUrl: "./website-users.component.html",
  styleUrls: ["./website-users.component.scss"],
})
export class WebsiteUsersComponent implements OnInit {
  public page = "WebsiteUsers";
  public usersList = [];
  public pageSize = 20;
  public totalPages: Number = 0;
  public columns = [
    {
      name: "Sr No",
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      },
    },
    {
      name: "Name",
      prop: "name",
      allowFilteredRow: true,
    },
    { name: "Email", prop: "email", allowFilteredRow: true },
    { name: "Mobile", prop: "userPhoneNo", allowFilteredRow: true },
    { name: "Party", prop: "companyName", allowFilteredRow: true },
    {
      name: "Distribution Center",
      prop: "distributioncenter",
      allowFilteredRow: true,
    },
    { name: "Sales Person", prop: "salesPerson", allowFilteredRow: true },
    { name: "KYC Status", prop: "KYCStatus" },
    { name: "KYC Submitted", prop: "kycSubmitted" },
    { name: "Last LoggedIn", prop: "lastLoggedIn" },
    { name: "Created By", prop: "created_date" },
  ];

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public event: Events,
    public configService: ConfigService
  ) {}

  ngOnInit() {
    this.getUsers();
  }

  async getUsers() {
    await this.configService.showLoader();
    let res = await this.httpApiService.getWebsiteAllUsers();
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.usersList = data.data;
    }
  }
  async uploadForAssortment(e: any) {
    const modal = await this.modalCtrl.create({
      component: CreateWebsiteUserComponent,
      backdropDismiss: false,
      cssClass: "extraLargePage",
      componentProps: {},
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async handleModalDismiss(d) {
    console.log("modal dismissed");
  }
}
