import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, AlertController, ModalController, Events } from '@ionic/angular';
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ExportAsExcelService } from 'src/app/services/export-as-excel.service';

@Component({
  selector: 'app-program-management',
  templateUrl: './program-management.page.html',
  styleUrls: ['./program-management.page.scss'],
})
export class ProgramManagementPage implements OnInit {
  @Input() selectedData: any;
  public columns: any = []
  public selectedStoneIds: any = [];
  public page = 'ProgramApproval';
  public disableBtn: boolean = false;

  constructor(public configService: ConfigService,
    public httpApiService: HttpApiService,
    public alertController: AlertController,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    private authService: AuthenticationService,
    public exportAsExcelService: ExportAsExcelService,
    public events: Events) { }

  ngOnInit() {

    this.selectedData = this.selectedData.filter(el => el.progName && el.approveProgramStatus != 1);

    this.columns = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Stone Id", prop: "stoneName" },
      { name: "Program", prop: "progName" },
      { name: "Status", prop: "approveProgramStatus" }
    ];
  }

  closeModal() {
    this.modalCtrl.dismiss()
  }

  async OnSelectRows(item) {
    // let disableBtn = item.selectedRowsData.filter(el => !el.progName);
    // console.log(disableBtn)
    // if (disableBtn.length > 0) {
    //   this.disableBtn = true;
    //   await this.configService.showToast("error", `Selected Stones do not have any program`);
    // } else {
    //   this.disableBtn = false;
    // }
    this.selectedStoneIds = item.selectedRowsData.map(el => el.id)
  }

  async saveProgramWithStatus(programStatus) {
    let jsonObj = {
      setOfStoneIds: this.selectedStoneIds,
      approveProgramStatus: programStatus,
    }
    console.log(jsonObj)
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.approveRejectProgram(jsonObj);
    let data = res.json();
    await this.configService.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
      await this.configService.showToast("error", data.message);
    } else {
      await this.configService.showToast("success", data.message);
      this.selectedStoneIds = []
      this.modalCtrl.dismiss('updated')
    }
  }

  validate() {
    if (this.selectedStoneIds.length > 0 && this.disableBtn) {
      return true;
    }
    else if (this.selectedStoneIds.length > 0 && !this.disableBtn) {
      return false;
    }
    else {
      return true;
    }
  }


}
