import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import {
  ModalController,
  LoadingController,
  Events,
  AlertController
} from "@ionic/angular";
import { ReceiveincomingstonesComponent } from '../receiveincomingstones/receiveincomingstones.component';
import * as moment from "moment";
import { IonicSelectableComponent } from 'ionic-selectable';
import { AccordionModule } from 'ngx-accordion';
import { NgModel } from '@angular/forms';
// import { CompileShallowModuleMetadata } from '@angular/compiler';
// import { Item } from 'ionic-angular';


@Component({
  selector: 'app-purchase-updatetermsandbankrates',
  templateUrl: './purchase-updatetermsandbankrates.component.html',
  styleUrls: ['./purchase-updatetermsandbankrates.component.scss']
})
export class PurchaseUpdatetermsandbankratesComponent implements OnInit {
  columns = [];
  dataSource: any = [];
  public partyName: any;
  public bank: any;
  public term: any;
  public pageSize: any = 10;
  public item:any;

  pageNumber = 0;
  noOfRecords = 15;
  stonesList = [];
  searchTxt = '';
  selected = [];
  public val;

  termrates;
  bankRates;
  public refreshGrid: Boolean = false;
  public Stones = ``;
  //public Stones = ''; 
  constructor(
    public configService: ConfigService,
    public authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public event: Events
  ) { }

  ngOnInit() {

    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "enquiryno", prop: "enquiryId" },
    {
      name: 'Stone Id', prop: 'stoneName'
    },
    //{ name: "Details", prop: "" },
    { name: 'Clarity', prop: 'ClarityCode' },
    { name: 'Color', prop: 'ColorCode' },
    { name: 'Shape', prop: 'ShapeCode' },
    { name: 'Size', prop: 'cts' },
    { name: 'Terms', prop: 'terms' },
    { name: 'Bank Rate', prop: 'BankRate' },
    { name: 'Disc', prop: 'PurchasePartyDisc' },
    { name: 'Price', prop: 'PurchasePartyPrice' },
    { name: 'Amount', prop: 'PurchasePartyAmt' }

    ]


    // this.dataSource=[{srno:1,party:'xyz',totalstones:20},{srno:2,party:'xyz',totalstones:20}];
    this.getStones()

    this.event.subscribe('Update Terms & Bank Rates', (item) => {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "enquiryno", prop: "enquiryId" },
      {
        name: 'Stone Id', prop: 'stoneName'
      },
      //{ name: "Details", prop: "" },
      { name: 'Clarity', prop: 'ClarityCode' },
      { name: 'Color', prop: 'ColorCode' },
      { name: 'Shape', prop: 'ShapeCode' },
      { name: 'Size', prop: 'cts' },
      { name: 'Terms', prop: 'terms' },
      { name: 'Bank Rate', prop: 'BankRate' },
      { name: 'Disc', prop: 'PurchasePartyDisc' },
      { name: 'Price', prop: 'PurchasePartyPrice' },
      { name: 'Amount', prop: 'PurchasePartyAmt' }
      ]
      this.getStones()
    })
     this.defaultValues();
  }

  ngOnDestroy() {
    this.event.unsubscribe('Update Terms & Bank Rates');
  }

  async getStones() {

    let listOfStones = []
    if (this.Stones != '') {
      let tmp = this.Stones.split("\n");
      tmp = tmp.map(x => x.trim())

      if (tmp.length > 0) {
        listOfStones = tmp
      }
    }
    await this.configService.showLoader()
    let obj = {
      'listOfStones': listOfStones,
      "searchQry": this.searchTxt

    }
    let res: any;
    res = await this.httpApiService.fetchStonesForUpdateBankRate(obj, this.pageSize, this.pageNumber);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data) {
        this.stonesList = data.data
      }
    }

  }

  async settingValues(value, stones, type) {

    console.log(value, stones, type)

    let listOfStones = [];

    stones.forEach(x => {
      console.log(x)
      if (type == 'terms') {
        x.terms = value;

      }

      if (type == 'BankRate') {
        x.BankRate = value;

      }
      return x
    })

    return stones

  }

  async defaultValues(){
    console.log("hiiiiii");

  }

  async OnSelectRows(event) {
    this.selected = [...this.selected, event.selectedRowsData]
    // let newArr = []
    // event.selectedRowsData.forEach((item, index) => {
    //   if (newArr.findIndex(i => i.stoneName == item.stoneName) === -1) {
    //     newArr.push(item)
    //   }

    // });
    // this.selected = newArr
  }

  async termsOrBankRateUpdated(event) {
    console.log(event)
  }

  async update() {
    let listOfStones = []
    this.stonesList.forEach(n => {
      let stones = n.stoneDeatils
      stones.forEach(x => {
        if (x.BankRate || x.terms) {
          let obj = {
            "stoneName": x.stoneName,
            "partyMasterId": x.partyMasterId,
            "enquiryId": x.enquiryId,
            "bankRate": x.BankRate,
            "terms": x.terms
          }
          listOfStones.push(obj)
        }
      })
    })
    let obj = {
      'listOfStones': listOfStones
    }
    let res: any;
    console.log(obj);

    await this.configService.showLoader()
    res = await this.httpApiService.updateStoneDetails(obj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast(
        "success",
        data.message
      );

    }
    this.getStones()
    // this.stonesList = [];
    this.Stones = ''

  }

}
function stones(value: string, stones: any, type: () => string) {
  throw new Error('Function not implemented.');
}

