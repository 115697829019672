import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  NavController,
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";
import * as XLSX from 'xlsx';
import DataGrid from "devextreme/ui/data_grid"; 
import * as moment from "moment";
import { IonicSelectableComponent } from 'ionic-selectable';





@Component({
  selector: 'app-designbybudget',
  templateUrl: './designbybudget.component.html',
  styleUrls: ['./designbybudget.component.scss']
})
export class DesignbybudgetComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild('selectableComponent') selectableComponent: IonicSelectableComponent;
  columns = [];
  columns1 = [];
  dataColumns = [];
  budgetList: any = [];
  public priceList = [];
  dataSource: any = [];
  public temp = 0;
  list = [];
  public btn=0;
  public criteriaList = [];
  public pageSize: any = 50;
  noOfRecords = 15;
  public rapPriceListId: 3;
  public typeList = [];
  public designNo: string = "";
  public allPriceNameList = [];
  pageNumber = 0;
  refreshGrid:boolean=false;
  recalculteSummary:boolean=false;
  dataGridInstance: DataGrid;
  public designBudgetMasterId = 3;
  public count: any;
  public criteriaList1: any;
  public designList = [];
  


  public customerId = null;
  public selectedParty = null;
  public uploadDate = moment().format("YYYY-MM-DD")
  public listOfParties = []
  public typeName: any;
  public ID;
  public defaultParam = ['shape', 'color', 'clarity', 'cts', 'qty', 'PricePerCarat', 'Total','Copy']
  // public default = ['shape', 'color', 'clarity', 'size', 'qty', 'ratePerCarat'];


  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public loadingController: LoadingController,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService) {

    
      //this.copyFunction= this.copyFunction.bind(this);

    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "Customer", prop: "partyName" },
    { name: "Design No", prop: "designNo" },
    { name: "created By", prop: "createdBy" },
    { name: "Date & Time", prop: "createdDate" },


    ];

  }
  public totalSummary = [
     {
       "fieldName": "PricePerCarat",
       "summaryType": "sum",
       "name":"PricePerCarat"
     },
    {
      "fieldName": "Qty",
      "summaryType": "sum",
      "name":"Qty"
    },
    {
      "fieldName": "Total",
      "summaryType": "sum",
      "name":"Total"
    }
  ]
  // shape:el.Shape,
  // color:el.Color,
  // clarity:el.Clarity,
  // size:el.cts,
  // qty:el.qty,
  // ratePerCarat:el.PricePerCarat,
  // Total:el.Total,

  ngOnInit() {
    this.findAll();
    this.getDefaultSetting()
  
    this.getAllBudgetList()
    // this.budgetList = { Shape: "round", Color: "D", Clarity: "VVS1", Cts: "0.3", Qty: "5", PricePerCarat: "3100", Total: "31000" }
  }

  ngOnChanges(changes) {
    console.log(changes);
    if (!!changes.refreshGrid || !!changes.refreshGrid.currentValue) {
      this.refreshh();
    }
    if (!!changes.recalculteSummary || !!changes.recalculteSummary.currentValue) {
      this.refreshh();
    }

    if (!!changes.totalSummary || !!changes.totalSummary.currentValue) {
      if (this.totalSummary.length != 0) {
        
      }
    }
  }

  refreshh() {
    // if (this.dataGridInstance != undefined) {
    this.dataGridInstance.clearSelection();
    console.log('refreshed Grid and cleaned data');
    // }
  }

  reload(){
    this.dataGridInstance.refresh();
  }

  async Create() {
    this.temp = 1;
    this.btn=1;
    this.designNo=""
    this.criteriaList=[];
  }

  saveGridInstance(e) {
    this.dataGridInstance = e.component;
  }

  async back() {
    this.temp = 0;
 this.btn=0;
    // this.criteriaList = [];
  }


  async findAll() {
    let res: any;
    res = await this.httpApiService.findAllWithMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.list = data.data;
      this.defaultParam.forEach(el => {
        let index = this.list.findIndex(x => x.paramName.toLowerCase() == el.toLowerCase());
        if (index > -1) {
          let valueList = this.list[index].paramValue.filter(item => {
            // if (item.transTypeName == 'SS' || !item.transTypeName) 
            {
              return item;
            }
          })
          this.dataColumns.push({
            name: this.list[index].paramName,
            dataSource: valueList,
            entityType: 'selection',
            isAllow: true
          })
        } else {
          if (el == 'Total' || el == 'PricePerCarat') {
            this.dataColumns.push({
              name: el,
              dataSource: [],
              entityType: '',
              isAllow: false
            })
          } else {
            this.dataColumns.push({
              name: el,
              dataSource: [],
              entityType: '',
              isAllow: true
            })
          }
        }
      })

      console.log(this.dataColumns)
    }
  }

  onChangeParty(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (!!event.value) {
      this.customerId = event.value.id
      this.selectedParty = event.value.partyName
    } else {     
      
    }
  }
  
  

  async searchParty(event: { component: IonicSelectableComponent; text: string }) {
    let searchText = event.text.trim().toLowerCase();
    await this.getAllCustomer(0, 20, searchText);
  }
  

  async getAllCustomer(pageNumber, noOfRecords, searchText) {
    let res: any;
    let json = {
      listOfType: ['Export', 'Import', 'Local Sales', 'Local Purchase']
    }
    res = await this.httpApiService.findByListOfPartyTypePagination(json, 'Client', pageNumber, noOfRecords, searchText);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.content) {
        let dataValueList = data.data.content;
        if (dataValueList.length > 0) {
          for (let i = 0; i < dataValueList.length; i++) {
            dataValueList[i].partyNameWithType = `${dataValueList[i].partyName} ( ${dataValueList[i].type} )`
          }
          this.listOfParties = dataValueList;
        } else {
          this.listOfParties = []
        }
      }
    }
  }
  deleteItem(ev) {
    this.confirmDeleteData(ev.row.data);

  }
  async confirmDeleteData(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: del");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("okay deleting");
            this.deleteData(item.id);
          }
        }
      ]
    });

    await alert.present();
  }
  async deleteData(id) {
    await this.configService.showLoader();
    let res = await this.httpApiService.deleteBudgetEnquiry(id)
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.message);
    } else if (!data.isSuccess) {
      await this.configService.showToast(
        "error",
        data.data
      );

    } else {
      await this.configService.showToast(
        "success",
        data.data
      );
    }
    await this.getAllBudgetList()
  }


  getAllBudgetList() {
    this.dataSource = new CustomStore({

      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getAllBudgetList(this.noOfRecords, this.pageNumber)
          let data = res.json();

          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.designList = data.data;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: 5, //data.data.totalElements,
              groupCount: 5// data.data.totalElements
            };
          }
        } else {
          return {
            data: this.designList,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

async updateDesignCriteria(){
  // await this.configService.showLoader();
  let res: any;
  let cList = []
  console.log( this.criteriaList )
  this.criteriaList.forEach(el => {
    let obj = {
      shape: el.Shape,
      id:!!el.id ? el.id : null,
      color: el.Color,
      clarity: el.Clarity,
      size: el.cts,
      qty: el.qty,
      ratePerCarat: el.PricePerCarat,
      Total: el.Total,
    }
    if(!obj.id){
      delete obj.id
    }
    cList.push(obj)
  })

  let jsonObj = {
    "customerId": this.customerId,
    "designNo": this.designNo,
    "criteria": cList,

  }
  console.log(jsonObj)
  
  res = await this.httpApiService.updateDesignCriteria(this.designBudgetMasterId,jsonObj);

  let data = res.json();
  await this.configService.dismiss();
  if (res.status == 401) {
    this.authService.logout();
  } else if (res.status == 500) {
    await this.configService.showToast("error", data.error);
  } else if (res.status == 400) {
    await this.configService.showToast("error", data.error);

  }
  else {
    this.criteriaList=[];
    
    await this.getDesignCriteriaStoneById();
    // data.message="updated successfully"
    await this.configService.showToast('success', data.message);
    await this.modalCtrl.dismiss();
  }


}
  async addDesignCriteria() {
    await this.configService.showLoader();
    let res: any;
    let cList = []
    this.criteriaList.forEach(el => {
      let obj = {
        shape: el.Shape,
        color: el.Color,
        clarity: el.Clarity,
        size: el.cts,
        qty: el.qty,
        ratePerCarat: el.PricePerCarat,
        Total: el.Total,
      }
      cList.push(obj)
    })

    let jsonObj = {
      "customerId": this.customerId,
      "designNo": this.designNo,
      "criteria": cList,

    }
    res = await this.httpApiService.addDesignCriteria(jsonObj);

    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);

    }
    else {
      await this.configService.showToast('success', data.message);
      await this.modalCtrl.dismiss();
    }


  }


  async generateEnquiry() {
    this.addDesignCriteria();
    // this.temp=0;
  }


  async getDesignCriteriaStoneById() {


    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.getDesignCriteriaStoneById(this.designBudgetMasterId);
    console.log(this.designBudgetMasterId);

    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {

      let valueList = data.data;


      console.log(valueList, this.criteriaList)
      valueList.forEach(el => {
        console.log("in for each shape ")
        console.log(el.id)
  
        let obj = {
         ID :'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          }),
          id:el.id,
          Shape: el.shape,
          Color: el.color,
          Clarity: el.clarity,
          cts: el.size,
          qty: el.qty,
          PricePerCarat: el.ratePerCarat,
          Total: el.Total,
        }
        console.log(obj)
        this.criteriaList.push(obj)
      })

      console.log(this.criteriaList);

    
    }
  }


  async getPricing(ev) {
   this.recalculteSummary=true;
    this.refreshGrid=true;
    this.refreshh();
    
     await this.calculatePrice();
    // if (Object.keys(ev.data).length > 1) {

    // }
   
    // await  this.updateDesignCriteria();
  //    this.getDesignCriteriaStoneById();
  }
 

  onRowInserting(ev){
    console.log("inserting");
  }
  

  onRowRemoving(ev) {

    let index = this.criteriaList.findIndex(x => x.id == ev.data.id);
    if (index > -1) {
      this.criteriaList[index].splice(index, 1);
    }
    
  }
  onRowRemoved(ev) {
  
    let index = this.criteriaList.findIndex(x => x.id == ev.data.id);
    if (index > -1) {
      this.criteriaList[index].splice(index, 1);

      
    }
  }
  onEditingStart(ev){
  
  }
  onRowUpdating(ev){
  let btn=1;

}

onRowUpdated(ev){

  
  console.log("hi i am updated");
}

onRowUpdate(ev) {
  
  let index = this.criteriaList.findIndex(x => x.ID == ev.data.ID);
  if (index > -1) {
    this.criteriaList[index] = ev.data;
    this.criteriaList=ev.data;
    
    
  } 

  this.calculatePrice();
  this.criteriaList=[]
  }

  OnSelectRows(el) {

    this.temp = 1;
    this.criteriaList;
    console.log(el)
    this.designBudgetMasterId = el.row.id;
    this.customerId = el.row.id;
    this.designNo = el.row.designNo;
    this.getDesignCriteriaStoneById();
  


    this.criteriaList = [];

  }

  logEvent(ev) {
  }

  showPrice() {
    this.calculatePrice();
  }

  // dublicateRows(ev){
  //   console.log("copying the rows from dublicate row");
  //      console.log(ev)
  // console.log(ev.designNo);
  // }
 

  async getDefaultSetting() {

    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();

    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.basePriceListName) {
          this.rapPriceListId = dataValue.map.basePriceListName;
        }
        if (!!dataValue.map.listOfPrices) {
          let displayPriceList = JSON.parse(dataValue.map.listOfPrices)
          await this.getAllPriceNameList(displayPriceList);
        }
      }
    }
  }

  async getAllPriceNameList(displayPriceList) {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data
      console.log(this.allPriceNameList)
    }
  }

  // Calculate Price
  
  async calculatePrice() {
    let res: any;
    await this.configService.showLoader()
    res = await this.httpApiService.calculatePrice(this.rapPriceListId, this.criteriaList);
    let data = res.json();
    this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(this.dataColumns)
      if (data.data.length > 0) {
        data.data.forEach(el => {
          let index = this.criteriaList.findIndex(x => x.ID == el.ID);
          if (index > -1) {
            this.criteriaList[index] = Object.assign(this.criteriaList[index], el);
          }
        })
        console.log("this.criticallist: ", this.criteriaList);
        // if (this.priceList.length > 0) {
        this.criteriaList.forEach(el => {
          el.PricePerCarat = el.rapnetplus
          el.Total = Number(el.rapnetplus * Number(el.qty)).toFixed(2)
          this.reload();

          // this.priceList.forEach((item, i) => {
          //   if (el.hasOwnProperty(item.name)) {
          //     let calcPrice = item.name + ' Amount';
          //     console.log(calcPrice, el.cts, el[item.name])
          //     if (!!el.cts && !!el[item.name])
          //       el[calcPrice] = el.cts * el[item.name];
          //     console.log(calcPrice)
          //   }
          // })
        })
        // 
      }
    }
    console.log(" hi I am critical list")
    console.log(this.criteriaList)


  }

   copyCriticalList(valueList) {
  let ev=this.event;
  console.log(valueList);
  let obj = {
    ID:"",
    Shape: valueList.Shape,
    Color: valueList.Color,
    Clarity: valueList.Clarity
  }
  
  this.criteriaList.push(obj);
   
   console.log('getting the event run');
  
  console.log(this.criteriaList);

  }


}