import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { IonicStorageModule } from "@ionic/storage";
import { HttpModule } from "@angular/http";
import { ComponentsModule } from "./components/components.module";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreviewAnyFile } from "@ionic-native/preview-any-file/ngx";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    ComponentsModule,
    AutocompleteLibModule,
    // BrowserAnimationsModule
  ],
  providers: [
    PreviewAnyFile,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
