import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
@Component({
  selector: "app-search-single-stone",
  templateUrl: "./search-single-stone.component.html",
  styleUrls: ["./search-single-stone.component.scss"]
})
export class SearchSingleStoneComponent implements ProcessActionComponent {
  @Input() data: any;
  public query: any;
  public type = "SS";
  public department = ["Sales", "KAM Sales"];
  public disable: any = [];
  public cart = true;
  public default = {
    criteria: true,
    search: true,
    shape: true,
    carat: true,
    color: true,
    colorOverride: false,
    clarity: true,
    clarityOverride: false,
    description: true,
    finishing: true,
    fluorescence: true,
    lab: true,
    size: false,
    internalStatus: true,
    externalStatus: true,
    transType: false,
    users: true,
    location: true,
    box: true,
    department: true,
    saveSearch: true,
    download: true,
    fm: true,
    party: true,
    availableForSale: true,
    certiDate: true,
    inwardDate: true,
    transactionDate: true,
    standardShapeCode:true
  };

  constructor() { }

  ngOnInit() {
    let name = [];
    let departmentName = [];
    departmentName = ["Sales", "KAM Sales"];
    name.push(this.type);
    this.disable.push("transType");
    this.disable.push("department");
    this.query = {
      //   facetFilters: ["transType:" + [this.type], "department:" + [this.department]],
      disjunctiveFacetsRefinements: {
        transType: name,
        department: departmentName
      }
    };
  }
}
