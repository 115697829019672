import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ManageSsParameterPage } from "../../actions/manage-ss-parameter/manage-ss-parameter.page"
import {
  LoadingController,
  ModalController,
  ToastController
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-assort-parameter-config',
  templateUrl: './assort-parameter-config.component.html',
  styleUrls: ['./assort-parameter-config.component.scss']
})
export class AssortParameterConfigComponent implements ProcessActionComponent {
  @Input() data: any;
  constructor() { }

  ngOnInit() {
  }

}
