import { Component, Input, ViewChild, HostListener } from "@angular/core";
import {
  ModalController,
  AlertController,
  LoadingController,
  MenuController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManageSearchPage } from "../manage-search/manage-search.page";
import { CsvDownloadHistoryPage } from "../csv-download-history/csv-download-history.page";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
// extends BaseWidget 
export class SearchComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() query: any;
  @Input() disable: any;
  @Input() type: any;
  @Input() cart: Boolean;
  @Input() withMe: Boolean = false;
  @ViewChild("dataTable") public dataTable: any;

  public state: {
    items: object[];
    refine: Function;
    createURL: Function;
    isFromSearch: boolean;
    searchForItems: Function;
    isShowingMore: boolean;
    canToggleShowMore: boolean;
    toggleShowMore: Function;
    widgetParams: object;
  };

  //prod
  // algoliaConfig = {
  //   apiKey: "eab66bf0f5f968bd3125781160dcff0e",
  //   appId: "R6FNZK3FKK",
  //   indexName: "kgProd",
  // };

  //local
  // algoliaConfig = {
  //   apiKey: "c428d99b1b938d5bb665765a24887456",//"eab66bf0f5f968bd3125781160dcff0e", //
  //   appId: "SGTTZIO938",//"R6FNZK3FKK",
  //   indexName: "test1",// "lattice",
  // };

  public selectedStone = [];
  public stoneList: any = [];
  public RFList = [];
  public newStoneList: any = [];
  public voucherType: any = "sales_request";
  public resultColumns: any = [];
  public showMoreLess = "less";
  public exportAs: any = [];
  public selectedExportedFormat: any;
  public compareStones: any = [];
  public selected = [];
  public view: any;
  public options: any;
  public rows: any[];
  public ratePerCts: boolean = false;
  public culetSizeCode: any = "Add";
  public exportedFormatList: any = [];
  public totalStones: any;
  public shapeCode: boolean = true;
  public clarityCode: boolean = true;
  public colorCode: boolean = true;
  public cutCode: boolean = true;
  public cts: boolean = true;
  public polishCode: boolean = true;
  public symmetryCode: boolean = true;
  public fluorescenceCode: boolean = true;
  public department: boolean = true;
  public location: boolean = true;
  public username: boolean = true;
  public internalStatusCode: boolean = true;
  public totDepth: boolean = false;
  public tableValue: boolean = false;
  public lOrW: boolean = false;
  public search: any;
  public index: any;
  public modifiedHits: any;
  public searches = { query: "", facetFilters: [] };
  public states: any;
  public searchHistory: any = [];
  public tagName: any;
  public counter = 0;
  public searchHistoryList: any = [];
  public selectedSearch: any;
  public selectedSearchParameters: any;
  public showSaveSearch = false;
  public flagDepartment = true;
  public deptName: any;
  public ids = [];
  public isDefault = false;
  public actionId: any;
  public userId: any;
  public allPriceNameList = [];
  public defaultTemplateId: any;
  public userTemplateId: any;
  public defaultTempalateList = []
  public userExportList = []
  public defaultExportList = []
  public keys = []
  public requestJson = {}
  public priceList: any;
  public actionName: any;
  public priceListMasterId: any;
  public discountPercent: any;
  public isShow: Boolean = false;
  public baseUrl: any;
  public limit: any;
  public displayPriceList = []
  public showTemplateDownloadButtion = false;
  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public menu: MenuController,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService,
  ) {
    console.log("calling constructor");
  }

  async ngOnInit() {
    this.getBaseUrl();
    console.log(this.data)
    this.actionName = this.data.desc;
    await this.userData();
    console.log(this.query)
    this.actionId = this.data.actionId;
    console.log(this.cart, this.disable)
    if (this.disable) {
      this.flagDepartment = this.disable.includes('department')
    }

    console.log("*******************", this.disable, this.flagDepartment)
    console.log("test ngOnInit", this.data, this);
    this.getAllTemplates();
    this.getAllDefaultTemplates();
    // this.exportAs = [{ id: 1, name: "csv" }, { id: 2, name: "xlsx" }];
    await this.generateColumns();
    await this.getAllSearches();
    this.event.subscribe(this.actionName, (item) => {
      this.getAllTemplates();
      this.getAllDefaultTemplates();
      this.getAllSearches();
    })
    // this.event.subscribe('refreshAlgolia', (item) => {
    //   this.selected = []
    //   this.selectedStone = []
    // })
  }

  ngOnDestroy() {
    this.event.unsubscribe(this.actionName);
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "exportAs") {
      this.selectedExportedFormat = idList[0];
    } else if (property == "searchHistory") {
      this.selectedSearch = idList[0];
    }
    else if (property == 'defaultTemplate') {
      if (idList.length > 0) {
        this.defaultTemplateId = idList[0];
      } else {
        this.defaultTemplateId = null;

      }
    } else if (property == 'userTemplate') {
      if (idList.length > 0) {
        this.userTemplateId = idList[0];
        console.log(this.userTemplateId)
      } else {
        this.userTemplateId = null;

      }
    }
    console.log(
      "selectedValues",
      idList,
      property,
      this.selectedExportedFormat
    );
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event.keyCode)
    if (event.keyCode == 27) {
      this.menu.close()
    }
  }

  async getAllDefaultTemplates() {
    let res: any;
    res = await this.httpApiService.findAllTemplate(true)
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.defaultTempalateList = data.data;
    }
  }

  async userData() {
    let userData = await this.authService.userData();
    this.deptName = userData.departmentName;
    console.log(this.deptName)
  }


  async getDefaultSetting() {
    await this.getAllPriceListName();
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    console.log(this.actionId)
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATA", dataValue, this.allPriceNameList);
      //for VoucherBook
      if (!!dataValue.map) {
        if (!!dataValue.map.priceListName) {
          this.priceListMasterId = dataValue.map.priceListName
          this.allPriceNameList.filter(el => {
            if (el.id == dataValue.map.priceListName) {
              this.priceList = el.name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") + 'percarat';
              this.discountPercent = el.name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") + 'DiscountPercent';
              if (this.type == 'SS') {
                this.resultColumns.push({ name: "RAPAPORT", prop: this.allPriceNameList[0].name })
                // this.resultColumns.push({ name: "Disc %", prop: el.name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") + 'DiscountPercent' })
              }
              if (!!this.data && this.data.desc == 'Assortment Stock Search') {
                this.resultColumns.push({ name: "RAPAPORT", prop: this.allPriceNameList[0].name })
                // this.resultColumns.push({ name: "Disc %", prop: el.name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") + 'DiscountPercent' })
              }
              if (this.data.desc == 'Search') {
                this.resultColumns.push({ name: "RAPAPORT", prop: this.allPriceNameList[0].name })
                // this.resultColumns.push({ name: "Disc %", prop: el.name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") + 'DiscountPercent' })
              }
              // this.resultColumns.push({ name: el.name, prop: el.name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") + 'percarat' })
              // this.resultColumns.push({ name: "Price", prop: el.name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") })
            }
          })
        }
        if (!!dataValue.map.listOfPrices) {
          this.displayPriceList = JSON.parse(dataValue.map.listOfPrices)
          if (!!this.displayPriceList && this.displayPriceList.length > 0) {
            this.displayPriceList.forEach(el => {
              let index = this.allPriceNameList.findIndex(item => item.id == el);
              if (index > -1) {
                this.resultColumns.push({ name: this.allPriceNameList[index].name + " Disc", prop: this.allPriceNameList[index].name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_") + 'DiscountPercent' })
                this.resultColumns.push({ name: this.allPriceNameList[index].name + " Price", prop: this.allPriceNameList[index].name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_").replace("Natural", "Natural_").replace("Table", "stoneTable") + 'percarat' })
                this.resultColumns.push({ name: this.allPriceNameList[index].name + " Amount", prop: this.allPriceNameList[index].name.replace(/ /g, "").replace("/", "_").replace("(", "_").replace(")", "_") })
              }
            })
          }
        }
        if (!!dataValue.map.Limit) {
          this.limit = dataValue.map.Limit
        }
      }

      console.log("resultColumns", this.resultColumns)
    }
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
    }
  }

  async getAllTemplates() {
    let res: any;
    res = await this.httpApiService.findAllTemplate(this.isDefault)
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.exportAs = data.data;
    }
  }

  shapeTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  internalStatusTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  clarityTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  polishTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  symmetryTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  fluorescenceTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }


  labTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }


  transTypeTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  departmentTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  locationTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  boxTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  userTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  translate(items) {
    // if(this.stoneList==undefined) {
    //   this.stoneList = [];
    // }
    // console.log("before push this.stoneList",this.stoneList);
    // items.forEach(element => {
    //   if(this.stoneList.indexOf(element)===-1){
    //     this.stoneList.push(element);
    //   }
    // });
    // console.log("this.stoneList",this.stoneList, this.selectedStone)
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }
  async setData(data) {
    // hits.forEach(element => {
    //   if(this.stoneList.indexOf(element)===-1){
    //     this.stoneList.push(element)
    //   }
    // });
    console.log("set data", data, this.dataTable);
    if (data.type == "mouseenter") {
      data.event.stopPropagation();
    } else if (data.type == "click" && data.column.name == "Action") {
      this.selected = []
      data.row.isSelected = false;

      data.event.stopPropagation();
    } else {
      await this.compare(data.row);
      console.log("data", data, this.selectedStone.length);
      let test = false;
      if (this.selectedStone.length <= 0) {
        this.selectedStone.push(data.row);
        this.RFList.push(data.row.rfid);
        data.row.isSelected = true;
        console.log(
          "this.stoneList",
          this.stoneList,
          this.RFList,
          this.selectedStone
        );
      } else {
        this.selectedStone.forEach(el => {
          if (el.id == data.row.id) {
            test = true;
          }
        });
        if (test) {
          let index = this.selectedStone.indexOf(data.row);
          let index2 = this.selectedStone.indexOf(data.row.rfid);
          if (index > -1) {
            this.selectedStone.splice(index, 1);
            this.RFList.splice(index2, 1);
            data.row.isSelected = false;
          }
        } else {
          this.selectedStone.push(data.row);
          this.RFList.push(data.row.rfid);
          data.row.isSelected = true;
          console.log("this.stoneList", this.stoneList, this.selectedStone);
        }
      }
    }
  }
  async testLoop() {
    console.log("test");
    if (this.selectedStone.length > 0) {
      this.selectedStone.forEach(element => {
        element.isSelected = true;
      });
    }
  }

  async openStoneModal() {
    console.log(this.states)
    document.getElementById("removeFocus").blur();
    await this.configService.showLoader()
    if (this.selectedStone.length > 0) {
      this.ids = [];
      this.requestJson = {}
      this.selectedStone.forEach((el, i) => {
        console.log(el, this, this.discountPercent, el[this.discountPercent], this.priceList, el[this.priceList])
        this.ids.push(el.id);
        this.requestJson[el.id] = {
          "discount": el[this.discountPercent],
          "price": el[this.priceList] ? el[this.priceList].toString() : null,
          "rapPrice": !!this.allPriceNameList[0] ? (el[this.allPriceNameList[0].name] ? el[this.allPriceNameList[0].name].toString() : null) : 0,
        };
      });
      let objData;
      objData = {
        mapOfStonePriceAndDiscount: this.requestJson,
        transType: this.type,
        limit: this.limit,
        type: this.actionName,
        withMe: this.withMe
      };
      console.log(objData)
      // API call to add stone to cart & then open manageSearchPage
      let res = await this.httpApiService.addToCart(objData);
      console.log("res", res.status, res.json().error);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        console.log(data.data)
        await this.configService.showToast("error", data.message);
        if (data.data.length > 0) {
          data.data.forEach(el => {
            if (el.StoneId) {
              // el.StoneId.forEach(item => {
              //   this.showStoneId(item)
              // })
              el.StoneId = el.StoneId.toString()
            }
          })
          await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        }
      } else {
        await this.configService.showToast("success", data.message);
      }
    } else if (!!this.states && (!!this.states.disjunctiveFacetsRefinements || !!this.states.numericRefinements)) {
      let mapOfSearchCriteria: any;
      let mapOfNumericCriteria: any;
      if (this.states && this.states.disjunctiveFacetsRefinements) {
        mapOfSearchCriteria = this.states.disjunctiveFacetsRefinements;
      }
      if (this.states && this.states.numericRefinements) {
        mapOfNumericCriteria = this.states.numericRefinements;
      }
      console.log(this.limit, this.totalStones)
      if (this.totalStones <= (!!this.limit ? parseInt(this.limit) : 1000)) {
        let jsonObj = {
          mapOfSearchCriteria: mapOfSearchCriteria,
          mapOfNumericCriteria: mapOfNumericCriteria,
          priceListMasterId: this.priceListMasterId,
          transType: this.type,
          limit: this.limit,
          withMe: this.withMe,
          type: this.actionName
        }
        let res = await this.httpApiService.addToCartByFilterCriteria(jsonObj);
        let data = res.json();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          console.log(data.data)
          // await this.configService.showToast("error", data.error);
          await this.configService.showToast("error", data.message);
          if (data.data.length > 0) {
            data.data.forEach(el => {
              if (el.StoneId) {
                el.StoneId = el.StoneId.toString()
              }
            })
            await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
          }
        } else {
          await this.configService.showToast("success", data.message);
        }
      } else {
        await this.configService.showToast("error", "You are not allowed to add more than " + (!!this.limit ? parseInt(this.limit) : 1000) + " items");
      }
    }
    console.log(this.dataTable, this.data.desc)
    if (this.dataTable) {
      this.dataTable.selected = [];
    }
    this.selectedStone = [];
    const modal = await this.modalCtrl.create({
      component: ManageSearchPage,
      cssClass: "requestPage",
      backdropDismiss: false,
      componentProps: {
        actionId: this.data.actionId,
        actionName: this.data.desc,
        view: "Detail",
        priceListMasterId: this.priceListMasterId,
        displayPriceList: this.displayPriceList,
        stoneID: this.ids,
        type: this.type,
        withMe: this.withMe
      }
    });
    await this.loadingController.dismiss()
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
  async handleModalDismiss(d) {
    this.RFList = [];
    // this.selectedStone.forEach(element => {
    //   element.isSelected = true;
    //   this.RFList.push(element.rfid);
    // });

    // if(d.data.add){

    //   this.RFList = d.data.add.split("\n");
    // }
    console.log("RFList", this.RFList);
  }
  async validateControls() {
    if (this.RFList.length > 0) {
      return false;
    } else if (
      this.voucherType != "" &&
      this.voucherType != undefined &&
      this.voucherType != null
    ) {
      await this.configService.showToast("error", "Select stone");
      return true;
    } else {
      await this.configService.showToast("error", "Select type");
      return true;
    }
  }

  async generateColumns() {
    if (this.actionName == 'Mix Search' || this.actionName == 'Mix With Me') {
      this.resultColumns = [
        { name: "Internal Status", prop: "internalStatus" },
        { name: "CSSP", prop: "stoneName" },
        { name: "Box Name", prop: "Box" },
        { name: "Prog Name", prop: "progName" },
        { name: "Carat", prop: "Cts" },
        { name: "Size", prop: "Size" },
        { name: "Shape", prop: "ShapeCode" },
        { name: "Color", prop: "ColorCode" },
        { name: "Clarity", prop: "ClarityCode" },
        { name: "Department", prop: "department" },
        { name: "Party Name", prop: "partyName" },
        { name: "Hold By", prop: "requestUser" },
        { name: "Username", prop: "username" },
        { name: "Location", prop: "location" },
      ];
    } else {
      this.resultColumns = [
        { name: "Stone Name", prop: "stoneName" },
        { name: "Carat", prop: "Cts" },
        { name: "Shape", prop: "ShapeCode" },
        { name: "Color", prop: "ColorCode" },
        { name: "Clarity", prop: "ClarityCode" },
        { name: "Cut", prop: "CutCode" },
        { name: "Polish", prop: "PolishCode" },
        { name: "Symmetry", prop: "SymmetryCode" },
        { name: "Fluorescence", prop: "FluorescenceCode" },
        { name: "Internal Status", prop: "internalStatus" },
        { name: "Party Name", prop: "partyName" },
        { name: "Hold By", prop: "requestUser" },
        { name: "Prog Name", prop: "progName" },
        { name: "Box Name", prop: "Box" },
        { name: "Depth", prop: "TotDepthMm" },
        { name: "Username", prop: "username" },
        { name: "Location", prop: "location" },
        { name: "Department", prop: "department" },
      ];
    }

    await this.getDefaultSetting();
  }

  async getBaseUrl() {
    this.baseUrl = await this.configService.getBaseUrl();
  }

  // async addRemoveColumn(col, name) {
  //   if (col == "RatePerCts") {
  //     if (this.ratePerCts == true) {
  //       this.ratePerCts = false;
  //     } else {
  //       this.ratePerCts = true;
  //     }
  //   } else if (col == "CuletSize") {
  //     if (this.culetSizeCode == "Add") {
  //       this.culetSizeCode = "Remove";
  //     } else {
  //       this.culetSizeCode = "Add";
  //     }
  //   } else if (col == "ShapeCode") {
  //     if (this.shapeCode == true) {
  //       this.shapeCode = false;
  //     } else {
  //       this.shapeCode = true;
  //     }
  //   } else if (col == "internalStatusCode") {
  //     if (this.internalStatusCode == true) {
  //       this.internalStatusCode = false;
  //     } else {
  //       this.internalStatusCode = true;
  //     }
  //   } else if (col == "ClarityCode") {
  //     if (this.clarityCode == true) {
  //       this.clarityCode = false;
  //     } else {
  //       this.clarityCode = true;
  //     }
  //   } else if (col == "ColorCode") {
  //     if (this.colorCode == true) {
  //       this.colorCode = false;
  //     } else {
  //       this.colorCode = true;
  //     }
  //   } else if (col == "CutCode") {
  //     if (this.cutCode == true) {
  //       this.cutCode = false;
  //     } else {
  //       this.cutCode = true;
  //     }
  //   } else if (col == "Cts") {
  //     if (this.cts == true) {
  //       this.cts = false;
  //     } else {
  //       this.cts = true;
  //     }
  //   } else if (col == "PolishCode") {
  //     if (this.polishCode == true) {
  //       this.polishCode = false;
  //     } else {
  //       this.polishCode = true;
  //     }
  //   } else if (col == "SymmetryCode") {
  //     if (this.symmetryCode == true) {
  //       this.symmetryCode = false;
  //     } else {
  //       this.symmetryCode = true;
  //     }
  //   } else if (col == "FluorescenceCode") {
  //     if (this.fluorescenceCode == true) {
  //       this.fluorescenceCode = false;
  //     } else {
  //       this.fluorescenceCode = true;
  //     }
  //   } else if (col == "department") {
  //     if (this.department == true) {
  //       this.department = false;
  //     } else {
  //       this.department = true;
  //     }
  //   } else if (col == "location") {
  //     if (this.location == true) {
  //       this.location = false;
  //     } else {
  //       this.location = true;
  //     }
  //   } else if (col == "username") {
  //     if (this.username == true) {
  //       this.username = false;
  //     } else {
  //       this.username = true;
  //     }
  //   } else if (col == "TotDepth") {
  //     if (this.totDepth == true) {
  //       this.totDepth = false;
  //     } else {
  //       this.totDepth = true;
  //     }
  //   } else if (col == "tableValue") {
  //     if (this.tableValue == true) {
  //       this.tableValue = false;
  //     } else {
  //       this.tableValue = true;
  //     }
  //   } else if (col == "L_W") {
  //     if (this.lOrW == true) {
  //       this.lOrW = false;
  //     } else {
  //       this.lOrW = true;
  //     }
  //   }
  //   let i = this.resultColumns.findIndex(function (item, i) {
  //     return item.name === name;
  //   });
  //   console.log("i", i, "col", col);
  //   if (i == -1) {
  //     this.resultColumns.push({ name: name, prop: col });
  //   } else if (i > -1) {
  //     this.resultColumns.splice(i, 1);
  //   }
  // }

  async show(value) {
    console.log("searchHistoryList", this.searchHistoryList);
    this.showMoreLess = value;
    // await this.clearAll(2000);
  }

  async clearAll(seconds) {
    let element: HTMLElement = document.getElementsByClassName(
      "ais-ClearRefinements-button"
    )[0] as HTMLElement;
    setTimeout(function () {
      console.log("element", element);
      element.click();
    }, seconds);
  }

  onSearchChange({ results, state }: { results: any; state: any }) {
    if (results != undefined) {
      this.totalStones = results.nbHits;
      this.searches.query = results.query;
      this.searches.facetFilters = state.disjunctiveFacetsRefinements;
    }
    this.states = state;
    console.log(
      "results",
      results,
      "state",
      state,
      this.totalStones,
      this.states
    );
  }

  // async export() {
  //   let exportFormat;
  //   await this.exportAs.forEach(format => {
  //     if (format.id == this.selectedExportedFormat) {
  //     }
  //   });
  //   console.log("exported", this.dataTable, exportFormat);
  //   if (exportFormat != undefined) {
  //     const columns: any[] =
  //       this.dataTable.columns || this.dataTable._internalColumns;
  //     const headers = columns.map((column: any) => column.name).filter(e => e); // remove column without name (i.e. falsy value)

  //     this.rows = this.dataTable.rows.map(row => {
  //       let r = {};
  //       columns.forEach(column => {
  //         if (!column.name) {
  //           return;
  //         } // ignore column without name
  //         if (column.prop) {
  //           let prop = column.prop;
  //           r[prop] =
  //             typeof row[prop] === "boolean"
  //               ? row[prop]
  //                 ? "Yes"
  //                 : "No"
  //               : row[prop];
  //         } else {
  //           // special cases handled here
  //         }
  //       });
  //       return r;
  //     });
  //     console.log("headers", headers, "rows", this.rows);
  //     if (exportFormat == "csv") {
  //       this.options = {
  //         fieldSeparator: ",",
  //         quoteStrings: '"',
  //         decimalseparator: ".",
  //         showLabels: true,
  //         headers: headers,
  //         showTitle: false,
  //         title: "Report",
  //         useBom: true
  //       };
  //       let csv = new Angular2CsvComponent();
  //       csv.data = this.rows;
  //       csv.filename = "stones_" + new Date().getTime();
  //       csv.options = this.options;
  //       csv.generateCsv();
  //     } else if (exportFormat == "xlsx") {
  //       const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rows);
  //       const workbook: XLSX.WorkBook = {
  //         Sheets: { data: worksheet },
  //         SheetNames: ["data"]
  //       };
  //       XLSX.writeFile(workbook, "stones_" + new Date().getTime() + ".xlsx");
  //     }
  //   } else {
  //     await this.configService.showToast("error", "Select format type");
  //     return true;
  //   }
  // }

  async compare(row) {
    // let test = false;
    // this.compareStones.forEach(el => {
    //   console.log(el.id, row.id)
    //   if (el.id == row.id) {
    //     test = true;
    //   }
    // });
    // console.log(test)
    // if (test) {
    //   let index = this.selectedStone.indexOf(row);
    //   console.log(index)
    //   if (index > -1) {
    //     this.selectedStone.splice(index, 1);
    //     row.isSelected = false;
    //   }
    // }
    if (this.compareStones.indexOf(row) == -1) {
      this.compareStones.push(row);
    } else if (this.compareStones.indexOf(row) >= -1) {
      let i = this.compareStones.indexOf(row);
      this.compareStones.splice(i, 1);
    }

    console.log("row", row, this.selectedStone, this.compareStones);
  }

  async segmentChanged(event) {
    console.log("segment", event.target.value);
    this.view = event.target.value;
  }

  async bookmark() {
    console.log("selectedStone", this.selectedStone);
  }

  currentRefinementsTranslate(items) {
    console.log("items", items);
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  async openImageVideo(value) {
    console.log("value", value);
    window.open(value, "_blank");
  }

  async saveSearches() {
    // this.showSaveSearch = false;
    // setTimeout(()=>{
    //   this.showSaveSearch = true;
    // },1000);
    if (this.tagName != null && this.tagName != "") {
      let objData = {
        searchTitle: this.tagName,
        searchString: this.states.toString(),
        actionId: this.data.actionId
      };
      // API call to add stone to cart & then open manageSearchPage
      let res = await this.httpApiService.addSavedSearches(objData);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.tagName = "";
        await this.getAllSearches();
      }
    } else {
      await this.configService.showToast("error", "Please add tag name");
    }
    // this.searchHistory.push({id: ++this.counter, name:this.tagName,state: this.states});
    console.log("search history", this.searchHistory);
  }

  async fetchSaveSearches() {
    this.searchHistory.forEach(search => {
      if (search.id == this.selectedSearch) {
        this.selectedSearchParameters = JSON.parse(search.searchString);
        let tmpArr = [];
        tmpArr.push(search);
        this.searchHistoryList = tmpArr;
      }
    });
    let jsonObj = JSON.parse(JSON.stringify(this.selectedSearchParameters));
    this.query = jsonObj;
    this.showMoreLess = "less";
    /*let searchParam = {"facets":this.selectedSearchParameters.disjunctiveFacets,"facetFilters": facetFilters,"query": this.selectedSearchParameters.query};
    this.index.search({state: this.selectedSearchParameters}, (err, content) => {
      if (err) {
        console.log(err);
        console.log(err.debugData);
        return;
      }
      this.modifiedHits = content.hits;
      console.log(content.hits);
    }); */
    // this.search = instantsearch({
    //   indexName: 'test1',
    //   searchClient: algoliasearch(
    //     'SGTTZIO938',
    //     'c428d99b1b938d5bb665765a24887456'
    //   ),
    //   searchParameters: this.selectedSearchParameters,
    //   searchFunction(helper) {
    //     if (helper.state.query) {
    //       helper.search();
    //     }
    //     console.log("helper",helper);
    //   }
    // });
    // const hits1 = hits({
    //   container: 'ngx-datatable',
    //   templates: {
    //     empty: 'No results were found for the query: . Try to remove some filters or change the search query.',
    //     item: `
    //     <ngx-datatable #dataTable id="myTableElementId" *ngIf="view=='all' && modifiedHits==undefined"
    //     [rows]="hits"
    //     [sortType]="'multi'"
    //     [headerHeight]="50"
    //     [footerHeight]="50"
    //     [rowHeight]="35"
    //     [offset]="0"
    //     [scrollbarH]="true"
    //     [selectionType]="'multiClick'"
    //     (activate)="setData($event)">
    //       <ngx-datatable-column *ngFor="let col of resultColumns; let i = index" [name]="col.name" [flexGrow]="0.7" [prop]="col.prop" [width]="130"
    //       [resizeable]="true"
    //       [sortable]="true"
    //       [draggable]="true">
    //         <!-- <ng-template ngx-datatable-header-template>
    //           {{ col.name }}
    //         </ng-template> -->
    //         <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
    //           {{ value }}
    //         </ng-template>
    //       </ngx-datatable-column>
    //       <ngx-datatable-column name="Action" [flexGrow]="0.7" prop="imageVidUrl" [width]="130">
    //         <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
    //           <a (click)="openImageVideo(row.imageVidUrl)"><ion-icon name="eye"></ion-icon></a>
    //           &nbsp;<a (click)="viewTags(row.id)"><ion-icon name="pricetags"></ion-icon></a>
    //         </ng-template>
    //       </ngx-datatable-column>
    //     </ngx-datatable>
    //     `
    //   },
    //   transformItems: items => items.map(item => item),
    //   transformData(items) {
    //     console.log("items",items);
    //   }
    // });
    // this.search.addWidget(hits1);
    // console.log("hits",hits1, this.search,hits1.getConfiguration());
    // this.search.start();
    // this.search.on('search', (data) => {
    //   // Do something on search
    //   console.log("search data",data);
    // });
    // this.search.on('render', (data) => {
    //   // Do something on render
    //   console.log("render data",data);
    // });
  }

  async viewTags(value) {
    console.log("value", value);
    const modal = await this.modalCtrl.create({
      component: ManageSearchPage,
      backdropDismiss: false,
      cssClass: "largePage",
      componentProps: {
        actionId: this.data.actionId,
        actionName: this.data.desc,
        view: "tags",
        stoneID: value
      }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async getAllSearches() {
    let res: any;
    res = await this.httpApiService.findAllSearch(this.data.actionId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      // this.showSaveSearch = true;
      this.searchHistory = data.data;
      console.log("search history", this.searchHistory);
    }
  }

  async deleteSaveSearches() {
    console.log("this.selectedSearch", this.selectedSearch);
    let res: any;
    res = await this.httpApiService.deleteSearch(this.selectedSearch);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("search history", data);
      this.searchHistoryList = [];
      this.showMoreLess = "less";
      await this.getAllSearches();
      await this.configService.showToast("success", data.message);
    }
  }

  async userExport() {
    if (!!this.userTemplateId) {
      if (this.selectedStone.length > 0) {
        this.selectedStone.forEach(element => {
          this.ids.push(element.id);
        });
        let jsonObj = {
          setOfStoneIds: this.ids,
          priceListMasterIds: this.displayPriceList,
          templateId: this.userTemplateId
        }
        await this.configService.showLoader()
        let res: any;
        res = await this.httpApiService.exportTemplate(jsonObj);
        let data = res.json()
        await this.loadingController.dismiss()
        console.log(data)
        this.userExportList = []
        this.userTemplateId = null
        await this.configService.showToast('success', data.message);
      } else {
        console.log(this.states)
        let mapOfSearchCriteria: any;
        let mapOfNumericCriteria: any;
        if (this.states && this.states.disjunctiveFacetsRefinements) {
          mapOfSearchCriteria = this.states.disjunctiveFacetsRefinements;
        }
        if (this.states && this.states.numericRefinements) {
          mapOfNumericCriteria = this.states.numericRefinements;
        }
        let jsonObj = {
          mapOfSearchCriteria: mapOfSearchCriteria,
          mapOfNumericCriteria: mapOfNumericCriteria,
          priceListMasterIds: this.displayPriceList,
          templateId: this.userTemplateId
        }
        await this.configService.showLoader()
        let res: any;
        res = await this.httpApiService.exportTemplate(jsonObj);
        let data = res.json()
        await this.loadingController.dismiss()
        console.log(data)
        await this.configService.showToast('success', data.message);
        this.userExportList = []
        this.userTemplateId = null
      }
    } else {
      console.log("*********")
      await this.configService.showToast('error', 'Please select template')
    }
  }

  async defaultExport() {
    console.log(this.defaultTemplateId, this.selectedStone)
    if (this.defaultTemplateId) {
      if (this.selectedStone.length > 0) {
        this.selectedStone.forEach(element => {
          this.ids.push(element.id);
        });
        let jsonObj = {
          setOfStoneIds: this.ids,
          priceListMasterIds: this.displayPriceList,
          templateId: this.defaultTemplateId
        }
        let res: any;
        await this.configService.showLoader()
        res = await this.httpApiService.exportTemplate(jsonObj);
        let data = res.json()
        await this.loadingController.dismiss()
        this.defaultExportList = []
        this.defaultTemplateId = null
        await this.configService.showToast('success', data.message);
      } else {
        console.log(this.states)
        let mapOfSearchCriteria: any;
        let mapOfNumericCriteria: any;
        if (this.states && this.states.disjunctiveFacetsRefinements) {
          mapOfSearchCriteria = this.states.disjunctiveFacetsRefinements;
        }
        if (this.states && this.states.numericRefinements) {
          mapOfNumericCriteria = this.states.numericRefinements;
        }
        let jsonObj = {
          mapOfSearchCriteria: mapOfSearchCriteria,
          mapOfNumericCriteria: mapOfNumericCriteria,
          priceListMasterIds: this.displayPriceList,
          templateId: this.defaultTemplateId
        }
        await this.configService.showLoader()
        let res: any;
        res = await this.httpApiService.exportTemplate(jsonObj);
        let data = res.json()
        await this.loadingController.dismiss()
        console.log(data)
        await this.configService.showToast('success', data.message);
        // window.open(this.baseUrl + data.data.filePath.substr(1), '')
        this.defaultExportList = []
        this.defaultTemplateId = null
      }
    } else {
      await this.configService.showToast('error', 'Please select template')
    }
  }



  async openStoneDetail(row) {
    let res: any; res = await this.httpApiService.getStoneDetailById(row.id);
    let data = res.json();
    this.keys = []
    let dataValue = data.data;
    if (dataValue) {
      for (let key in dataValue) {
        if (key != 'Image Url') {
          this.keys.push({ key: key, value: dataValue[key] });
        }
      }
    }
    this.menu.open('stoneDetail');
  }

  closeSideMenu() {
    this.menu.close()
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  visibility() {
    this.isShow = !this.isShow
    console.log(this.isShow)
  }

  async viewDownloadHistory() {
    const modal = await this.modalCtrl.create({
      component: CsvDownloadHistoryPage,
      backdropDismiss: false,
      cssClass: 'requestPage'
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
}
