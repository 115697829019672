import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController, Events } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-manage-roles",
  templateUrl: "./manage-roles.page.html",
  styleUrls: ["./manage-roles.page.scss"]
})
export class ManageRolesPage implements OnInit {
  public roleDetails: any;
  public type: any;
  public roleName: any;
  public newRoleName: any;
  public description: any;
  public title: String;
  public listOfActions: any = [];
  public listOfActionsPermissions: any = [];
  public selectedActionName: any;
  public selectedPermissionName: any;
  public actionIdList: any;
  public setOfActionPermissionId: any;
  public actionPermissionIdList: any;
  public roleId: any;

  constructor(
    private authService: AuthenticationService,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public event: Events
  ) {
    this.roleDetails = this.navParams.get("role");
    this.type = this.navParams.get("type");
    if (this.roleDetails.length > 0) {
      this.roleName = this.roleDetails[0].roleName;
    }
    if (this.type == "1") {
      this.title = "Add new Role Permission";
    } else {
      this.title = "Add Role";
    }
    console.log("role details", this.roleDetails, this.type);
    this.getAllActions();
  }

  ngOnInit() {
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    this.setOfActionPermissionId = [];
    if (property == "actionName") {
      if (idList.length > 0) {
        this.actionPermissionIdList = [];
        this.setOfActionPermissionId = [];
        this.onActionSelect(idList[0]);
      } else {
        this.actionPermissionIdList = [];
        this.setOfActionPermissionId = [];
        this.listOfActionsPermissions = [];
      }
    } else if (property == "actionPermission") {
      if (idList.length > 0) {
        this.setOfActionPermissionId = idList;
      } else {
        this.actionPermissionIdList = [];
        this.setOfActionPermissionId = [];
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  onActionSelect(id) {
    if (id != "" && id != null && id != undefined) {
      this.listOfActionsPermissions = this.listOfActions.filter(action => {
        if (action.id == id) {
          return action.listOfActionPermissions;
        }
      });
      if (this.listOfActionsPermissions.length > 0) {
        this.listOfActionsPermissions = this.listOfActionsPermissions[0].listOfActionPermissions;

      } else {
        this.listOfActionsPermissions = [];

      }
      console.log("list of action permissions", this.listOfActionsPermissions);
    }
  }

  async getAllActions() {
    let res: any;
    res = await this.httpApiService.getAllActions();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfActions = data.data;
      console.log("list of actions by http", res, this.listOfActions);
    }
  }

  async addRoleActionPermission() {
    this.roleId = this.roleDetails[0].id;
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        roleId: this.roleId,
        setOfActionPermissionId: this.setOfActionPermissionId
      };
      console.log(jsonObj);
      let res = await this.httpApiService.addRolePermission(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.resetControls();
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async addRole() {
    let validate = await this.validateControls();
    console.log("newRoleName", this.newRoleName, this.description);
    if (!validate) {
      let appName = await this.configService.getAppName();
      let jsonObj = {
        roleName: this.newRoleName,
        appName: appName,
        description: this.description
      };
      let res = await this.httpApiService.addRole(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.resetControls();
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    }
  }

  async validateControls() {
    let flag: number;
    flag = 1;
    if (this.type == "1") {
      if (
        this.setOfActionPermissionId.length > 0 &&
        this.roleId != null &&
        this.roleId != "" &&
        this.roleId != undefined
      ) {
        flag = 0;
      }
    } else {
      if (
        this.newRoleName != null &&
        this.newRoleName != "" &&
        this.description != null &&
        this.description != ""
      ) {
        flag = 0;
      }
    }
    console.log("flag=", flag);
    if (flag) {
      await this.configService.showToast(
        "error",
        "Please enter/select all fields"
      );
      return await true;
    } else {
      return await false;
    }
  }

  async resetControls() {
    if (this.type == "1") {
      this.actionIdList = [];
      this.actionPermissionIdList = [];
      this.setOfActionPermissionId = [];
    } else {
      this.newRoleName = "";
      this.description = "";
    }
  }
}
