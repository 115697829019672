import { ProcessActionComponent } from '../../processAction';
import { Component, OnInit, Input, HostListener, ElementRef } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import CustomStore from 'devextreme/data/custom_store';
import Query from "devextreme/data/query";

import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
import * as moment from "moment";
import { BlockForSaleRequestFormPage } from '../block-for-sale-request-form/block-for-sale-request-form.page';

@Component({
  selector: 'app-block-for-sale',
  templateUrl: './block-for-sale.component.html',
  styleUrls: ['./block-for-sale.component.scss']
})
export class BlockForSaleComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() actionId: any;
  page = 'blockForSale';
  public listOfCustomers: any = [];
  blockForSaleStoneList = []
  partyMasterIds = '';
  requestPlatForms = '';
  public refreshGrid: boolean = false;
  selectedPlatformIdList = [];
  selectedCustomerList = [];
  stoneList = '';
  filtered = []
  searchType: string = 'stoneId';
  requestFromList = [{
    id: 1, name: 'Website'
  },
  {
    id: 2, name: 'SVC'
  }]
  selected = []
  columns = [
    { name: "Request Platform", prop: "requestPlatform" },
    { name: "Voucher No", prop: "" },
    { name: "Party Name", prop: "partyName" },
    { name: "Shipment Type", prop: "shipmentType" },
    { name: "Stone Id", prop: "stoneId" },
    { name: "Color", prop: "color" },
    { name: "Clarity", prop: "clarity" },
    { name: "Cts", prop: "stoneCts" },
    { name: "Stone Remarks", prop: "stoneRemarks" },
    { name: "Request Disc", prop: "requestDiscount" },
    { name: "Request Price", prop: "requestPrice" },
    { name: "Request Amount", prop: "requestAmt" },
    { name: "Request Time", prop: "createdDate" },
    { name: "Request User", prop: "requestUserName" },
    { name: "Purpose", prop: "purpose" },
  ]

  public totalSummary = [
    {
      "fieldName": "requestAmt",
      "summaryType": "custom",
      "name": "Request Amount"
    },
    {
      "fieldName": "stoneCts",
      "summaryType": "custom",
      "name": "Cts"
    }
  ]

  constructor(public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public alertController: AlertController,
    public configService: ConfigService,
    public event: Events) { }

  ngOnInit() {
    this.getCustomerList()
    // this.fetchStones()
  }


  async getCustomerList() {
    let res: any;
    res = await this.httpApiService.findAllBlockedForSale();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfCustomers = data.data;
    }
  }

  async dropDownSelection(ev) {
    console.log(ev);
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;

    if (property == 'partyName') {
      if (idList.length > 0) {
        this.partyMasterIds = idList.toString();
      } else {
        this.partyMasterIds = '';
      }
    }
    if (property == 'requestFrom') {
      if (idList.length > 0) {
        this.requestPlatForms = obj.map(el => el.name).toString();
      } else {
        this.requestPlatForms = '';
      }
    }

  }

  async fetchStones() {
    debugger
    await this.configService.showLoader()
    if (!!this.stoneList) {
      this.filtered = this.stoneList.split("\n");
      let issueList = []
      this.filtered.forEach(el => {
        if (el.indexOf(",") > 0) {
          let data1 = el.split(',')
          let data2 = []
          data1.forEach(el => {
            data2.push(el.trim())
          })
          Array.prototype.push.apply(issueList, data2);
        } else {
          issueList.push(el)
        }
      })
      this.filtered = issueList.filter(function (el) {
        return el != "";
      });
    } else {
      this.filtered = []
    }
    let res: any;
    res = await this.httpApiService.getBlockForSaleDetails('Block For Sale', this.partyMasterIds, this.requestPlatForms, this.filtered, this.searchType);
    let data = res.json();
    this.configService.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.blockForSaleStoneList = data.data.content;
      if (this.filtered.length) {
        this.sortByGivenStoneIds()
      }
    }
  }


  sortByGivenStoneIds() {
    this.blockForSaleStoneList = this.blockForSaleStoneList.sort((a, b) => {
      const indexOfA = this.filtered.indexOf(a.stoneId);
      const indexOfB = this.filtered.indexOf(b.stoneId);
      if (indexOfA !== -1 && indexOfB !== -1) {
        return indexOfA - indexOfB;
      }
      // If only one of them is in the sortOrder array, prioritize it
      if (indexOfA !== -1) {
        return -1;
      }
      if (indexOfB !== -1) {
        return 1;
      }
      // If neither element is in the sortOrder array, maintain the original order
      return 0;
    });
  }

  reset() {
    this.requestPlatForms = '';
    this.partyMasterIds = '';
    this.selectedCustomerList = [];
    this.selectedPlatformIdList = [];
    this.stoneList = '';
  }

  async requestConfirm() {
    if (this.selected && this.selected.length > 0) {
      this.refreshGrid = false;
      let modalPage = await this.modalCtrl.create({
        component: BlockForSaleRequestFormPage,
        backdropDismiss: false,
        componentProps: {
          data: this.selected,
          actionId: this.data.actionId,
          actionType: 'Request From Block For Sale'
        },
        cssClass: "requestPage"
      })
      modalPage
        .onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modalPage.present();
    } else {
      await this.configService.showToast("error", 'Please select the stones');
    }

  }

  onSelect(ev) {
    this.selected = ev.selectedRowsData;
    console.log("this.selected ", this.selected);
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    if (d.data != 'doNotRefresh') {
      this.selected = [];
      this.stoneList = '';
      this.filtered = [];
      this.getCustomerList()
      this.blockForSaleStoneList = [];
      this.reset()
      // this.fetchStones()

    }
  }


}
