import { Component, OnInit } from "@angular/core";
import {
  NavController,
  ModalController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-manage-lab-services',
  templateUrl: './manage-lab-services.page.html',
  styleUrls: ['./manage-lab-services.page.scss'],
})
export class ManageLabServicesPage implements OnInit {

  public service: any;
  public description: any;
  public remark: any;
  public isModal: any;
  public item: any;
  public labId: any;
  public id: any

  constructor(private modalCtrl: ModalController,
    private navParams: NavParams,
    public configService: ConfigService,
    public authService: AuthenticationService,
    private nav: NavController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public event: Events) { }

  ngOnInit() {
    this.isModal = this.navParams.get("flagModal");
    this.labId = this.navParams.get("labId");
    this.item = this.navParams.get("item");
    console.log(this.item, this.isModal)
    if (this.isModal == "update") {
      this.service = this.item.service
      this.description = this.item.serviceDescription
      this.id = this.item.id;
      this.remark = this.item.remark
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh')
  }


  //Create New User

  async createService() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let jsonObj = {
        remark: this.remark,
        service: this.service,
        serviceDescription: this.description,
        labId: this.labId,
      };
      let res: any;
      res = await this.httpApiService.addLabService(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast(
          "success",
          data.message
        );
        await this.modalCtrl.dismiss();
      }
    }
  }

  async updateService() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let jsonObj = {
        remark: this.remark,
        service: this.service,
        serviceDescription: this.description,
        labId: this.labId,
      };
      let res: any;
      res = await this.httpApiService.updateLabService(this.id, jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast(
          "success",
          data.message
        );
        await this.modalCtrl.dismiss();
      }
    }
  }

  async validateControls() {
    if (this.isModal == "add") {
      if (
        !!this.service
      ) {
        return true;
      } else {
        await this.configService.showToast(
          "error",
          "Please select/insert all the information"
        );
        return false;
      }
    } else {
      if (
        !!this.service
      ) {
        return true;
      } else {
        await this.configService.showToast(
          "error",
          "Please select/insert all the information"
        );
        return false;
      }
    }
  }


}
