import { Component, OnInit, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  AlertController,
  Events,
  ToastController
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { IonicSelectableComponent } from "ionic-selectable";
import { SearchService } from "src/app/services/search/search.service";
import { environment } from '../../../../environments/environment';
import { ExportAsExcelService } from "../../../services/export-as-excel.service";


@Component({
  selector: 'app-manage-enquiry-master',
  templateUrl: './manage-enquiry-master.component.html',
  styleUrls: ['./manage-enquiry-master.component.scss']
})
export class ManageEnquiryMasterComponent implements OnInit {
  public query: any;
  public exportTemplateId: any;
  public action: any;
  public editData: any;
  public editData1: any;
  public listOfParties = [];
  public partyFile: any;
  public partyMasterId: any;
  public partyName: any;
  public selectedParty;
  public selectedDefaultParty;
  public selectedSalesPerson = '';
  public editEnquiryData;
  public userId = null;

  pageNumber = 0;
  noOfRecords = 15;
  dataSource: any = [];
  public partyWiseStones: any = [];
  public transType: any;
  public totalStones = 0;
  public statusList = [
    {
      'status': 'Not Available'
    },
    {
      'status': 'Pending'
    }
  ]
  ds: any = [];
  public salesPersonList = [];
  public salePersonIdList = [];
  public brokerList = [];
  public BuyingpurposeList = [];
  // public  =[];
  public selectedBrokerList = []
  public BuyingidList = [];
  public BuyingidList2 = [];
  public brokerId: any;
  public transactionType: any
  public buyingPurpose: any
  public selected;
  public selectedStoneList = [];
  public statusidList = []
  public Stones = '';
  public stonesList = [];

  public c = [];
  public columns = [];
  public loadGrid = false
  public defaultParty;
  public transactionType_1: any
  public buyingPurpose_1: any
  public qry: any =
    {
      "query": {
        "bool": {
          "should": [
            {
              "simple_query_string": {
                "query": ""
              }
            },
            {
              "bool": {
                "must": []
              }
            }
          ]
        }
      }
    }
  constructor(
    private es: SearchService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public toastCtrl: ToastController,
    public authService: AuthenticationService,
    public configService: ConfigService,
    public navParams: NavParams,
    public alertCtrl: AlertController,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService
  ) {
    this.c = [
      { name: "name", prop: "name" },

    ];


    this.action = this.navParams.get('action');
  }

  public transactionStatusList: any = [{
    name: 'Purchase',
    id: 1
  }, {
    name: 'In-House QC',
    id: 2
  }, {
    name: 'Remote QC Reject',
    id: 3
  }];


  public stoneStatusGridList;


  async ngOnInit() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, {
      name: 'Stone Id', prop: 'stoneName'
    },
    //{ name: "Details", prop: "" },
    { name: 'Clarity', prop: 'ClarityCode' },
    { name: 'Color', prop: 'ColorCode' },
    { name: 'Shape', prop: 'ShapeCode' },
    { name: 'Size', prop: 'cts' },
    { name: 'Disc', prop: 'PurchasePartyDisc' },
    { name: 'Price', prop: 'PurchasePartyPrice' },
    { name: 'Amount', prop: 'PurchasePartyAmt' },
    { name: 'Certi Received', prop: 'isCerti' },
      // {name: 'Buying Purpose', prop: 'buyingPurpose'}

    ]


    if (this.editData) {
      console.log(this.editData)
      this.getEnquiryById(this.editData.id);
      this.columns.push(
        { name: 'Status', prop: 'status' },
        { name: 'Transaction Status', prop: 'transactionType' },
        { name: 'Buying Purpose', prop: 'buyingPurpose' }

      )
    }

    // if (this.editData) {
    //   console.log(this.editData)
    //   this.getEnquiryById(this.editData.id);
    //   this.columns.push(
    //     { name: ' Stone Status', prop: 'Stonestatus1' }
    //   )
    // }
    await this.getBrokerDetail('Broker');
    await this.getDepartmentWiseKamPersonList();
    this.stoneStatusGridList = this.transactionStatusList.map(x => x.name)
    this.buyingPurposelist();
  }

  closeModal() {
    this.modalCtrl.dismiss()
  }

  async getAllCustomer(pageNumber, noOfRecords, searchText) {
    let res: any;
    let json = {
      listOfType: ['Export', 'Import', 'Local Sales']
    }
    res = await this.httpApiService.findByListOfPartyTypePagination(json, 'Client', pageNumber, noOfRecords, searchText);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.content) {
        let dataValueList = data.data.content;
        if (dataValueList.length > 0) {
          for (let i = 0; i < dataValueList.length; i++) {
            dataValueList[i].partyNameWithType = `${dataValueList[i].partyName} ( ${dataValueList[i].type} )`
          }
          this.listOfParties = dataValueList;
        } else {
          this.listOfParties = []
        }
      }
    }
  }

  async getBrokerDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        data.data.filter(el => {
          if (!!el.contactNo) {
            el.brokerWithContact = el.partyName + " - " + el.contactNo
          }
          else if (!!el.tel) {
            el.brokerWithContact = el.partyName + " - " + el.tel
          } else {
            el.brokerWithContact = el.partyName
          }
        })
        let noneData: any;
        data.data.filter((el, i) => {
          if (el.partyName == 'NONE') {
            noneData = el;
            data.data.splice(i, 1);
          }
        })
        data.data.unshift(noneData)
        this.brokerList = data.data
      }
    }
  }

  onChangeParty(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (!!event.value) {
      this.partyMasterId = event.value.id
      this.partyName = event.value.partyName
    } else {
      this.partyMasterId = null;
      this.partyName = null;
    }
  }

  async searchParty(event: { component: IonicSelectableComponent; text: string }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    await this.getAllCustomer(this.pageNumber, this.noOfRecords, searchText);
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "salePerson") {
      this.selectedSalesPerson = idList[0];
    } else if (property == 'selectedBroker') {
      if (idList.length > 0) {
        this.brokerId = idList[0];
        this.setBrokerForStone(this.brokerId)
      } else {
        this.brokerId = "";
      }
    } else if (property == 'transactionStatusList') {
      if (idList.length > 0) {
        this.transactionType = obj[0].name;
        this.setStatusforstone(this.transactionType)
      } else {
        this.transactionType = '';
      }
      // }else if (property == 'BuyingpurposeList') {
      //   if (idList.length < 0) {
      //     this.buyingPurpose = obj[0];
      //   } else {
      //     this.buyingPurpose = "";
      //   }
    } else if (property == "BuyingpurposeList") {
      if (idList.length > 0) {
        this.buyingPurpose = obj[0];
        this.setBuyingPurposeForStone(this.buyingPurpose)
        console.log(this.buyingPurpose);
      } else {
        this.buyingPurpose = '';

      }
    }
    else if (property == "BuyingpurposeList2") {
      console.log(property);
      console.log(obj);

      if (idList.length > 0) {
        this.buyingPurpose_1 = obj[0];
        this.setBuyingPurposeForStoneForAll(this.buyingPurpose_1);
        this.showDetails(this.defaultParty);
        console.log(this.buyingPurpose);
      } else {
        this.buyingPurpose_1 = '';
        this.setBuyingPurposeForStoneForAll(this.buyingPurpose_1);
        this.BuyingidList = [];
      }
    }
    else if (property == 'transactionStatusList2') {
      console.log(property);
      console.log(obj);
      if (idList.length > 0) {
        this.transactionType_1 = obj[0].name;
        this.setStatusforstoneForAll(this.transactionType_1);
        this.showDetails(this.defaultParty);
      } else {
        console.log(this.transactionType);
        this.transactionType_1 = '';
        console.log(this.transactionType_1);
        this.setStatusforstoneForAll(this.transactionType_1);
        this.statusidList = [];
      }
    }
  }

  async buyingPurposelist() {
    let res: any;

    res = await this.httpApiService.buyingPurpose();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        this.BuyingpurposeList = data.data;
      }
    }

  }



  async getUserData() {
    let userData = await this.authService.userData();
    this.userId = userData.userId;
  }



  async getDepartmentWiseKamPersonList() {
    await this.getUserData()
    let res: any;
    res = await this.httpApiService.getDepartmentWiseKamPersonList("KAM Sales");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.salesPersonList = data.data;
      //let obj = await this.salesPersonList.filter(x => x.id == this.userId)[0]
      this.salePersonIdList = await this.salesPersonList.filter(x => x.id == this.userId)
      if (!!this.salePersonIdList && this.salePersonIdList.length > 0) {
        this.selectedSalesPerson = this.userId
      }
      // dataValue.filter(item => {
      //   if (item.id == this.userId) {
      //     this.salePersonIdList.push({ id: item.id, fullName: item.fullName })
      //     this.selectedSalesPerson = item.id
      //   }
      // })
      console.log("get All Customer", this.salePersonIdList);
    }
  }

  async fetch() {
    await this.configService.showLoader()
    this.partyWiseStones = [];
    let qry: any =
    {
      "query": {
        "bool": {
          "should": [
            {
              "simple_query_string": {
                "query": ""
              }
            },
            {
              "bool": {
                "must": [{
                  "terms": {
                    "transType": [
                      "SS"
                    ]
                  }
                },
                {
                  "terms": {
                    "internalStatus": [
                      "In Stock"
                    ]
                  }
                }],
                "should": [
                  {
                    "match": {
                      "rejectedStone": false
                    }
                  }
                ],
                "must_not": [
                  {
                    "match": {
                      "internalStatus": "Sold"
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    }

    let tmp = this.Stones.split("\n");
    tmp = tmp.map(el => el.trim()).filter(el => !!el)
    let boolShouldMultiple = {
      "bool": {
        "should": []
      }
    };
    boolShouldMultiple.bool.should.push({
      "terms": {
        "stoneName": tmp
      }
    });
    boolShouldMultiple.bool.should.push({
      "terms": {
        "RefStoneId": tmp
      }
    });
    boolShouldMultiple.bool.should.push({
      "terms": {
        "ReportNo": tmp
      }
    });
    qry.query.bool.should[1].bool.must.push(boolShouldMultiple);

    let data = await this.es.getPaginatedDocuments(qry, 0, environment.INDEX1, '', 100)
    data = data.hits.hits.map(d => d._source).map(res => {
      let stoneId = res.stoneName;
      if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
        let index = stoneId.split('/', 3).join('/').length;
        res['stoneName'] = String(stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))));
      } else {
        res['stoneName'] = String(res['stoneName']);
      }
      return res;
    })

    this.stonesList = data;

    this.customizeStoneData(this.stonesList)
    await this.configService.dismiss()

  }




  async customizeStoneData(stones) {
    console.log(stones);
    // await this.configService.showLoader()
    this.totalStones = stones.length

    for (let i = 0; i < stones.length; i++) {
      if (stones[i]['partyName']) {
        let party = stones[i]['partyName'];
        let partyMasterId = stones[i]['partyMasterId'];
        let SalePerson = stones[i]['SalePerson'];
        let stoneName = stones[i]['stoneName'];
        let ColorCode = stones[i]['ColorCode'];
        let ClarityCode = stones[i]['ClarityCode'];
        let ShapeCode = stones[i]['ShapeCode'];
        let cts = stones[i]['cts'];
        let PurchasePartyDisc = stones[i]['PurchasePartyDisc'];
        let PurchasePartyPrice = stones[i]['PurchasePartyPrice'];
        let PurchasePartyAmt = stones[i]['PurchasePartyAmt'];
        let isCerti = stones[i]['isCerti'] || null;
        let voucherNo = stones[i]['voucherNo'] || '';
        let isBillNo = stones[i]['isBillNo'] || '';
        let brokerId = stones[i]['brokerId'] || '';
        let transactionType = stones[i]['transactionType'] || '';
        let buyingPurpose = stones[i]['buyingPurpose'] || '';
        let sStatus = stones[i]['transactionType'] || '';
        let findObj = this.partyWiseStones.find(x => x.party == party);
        let status = stones[i]['status'] || 'Pending';
        let stoneObj = {
          'stoneName': stoneName,
          'ColorCode': ColorCode,
          'ClarityCode': ClarityCode,
          'ShapeCode': ShapeCode,
          'cts': cts,
          'party': party,
          'PurchasePartyDisc': PurchasePartyDisc,
          'PurchasePartyPrice': PurchasePartyPrice,
          'PurchasePartyAmt': PurchasePartyAmt,
          'partyMasterId': partyMasterId,
          'transactionType': transactionType,
          'status': status,
          'buyingPurpose': buyingPurpose,
          'isCerti': isCerti,
          'isBillNo': isBillNo,
          'voucherNo': voucherNo

          // imp 'trans

        }

        if (stoneObj) {
          stoneObj['brokerId'] = brokerId
          stoneObj['transactionType'] = transactionType
          stoneObj['buyingPurpose'] = buyingPurpose

        }

        if (!!findObj) {

          // findObj.stones.push(stoneObj)
          await this.partyWiseStones.filter(x => x.party == party).map(p => {
            p.stones = [...p.stones, stoneObj]
            p.count = p.stones.length
            return p
          })
        } else {
          let stoneArray = [];

          stoneArray.push(stoneObj)
          let newObj = {
            'party': party,
            'partyMasterId': partyMasterId,
            'SalePerson': SalePerson,
            'stones': stoneArray,
            //'status':'Pending',
            'count': 1
          }

          if (brokerId) {
            newObj['brokerId'] = brokerId
          }
          if (transactionType)
            newObj['transactionType'] = transactionType
          if (buyingPurpose) {
            newObj['buyingPurpose'] = buyingPurpose
          }
          this.partyWiseStones.push(newObj)
        }
      }
    }

    console.log(this.partyWiseStones)



    this.defaultParty = this.partyWiseStones[0]

    if (this.action == 'Update') {
      if (!!this.defaultParty && !!this.defaultParty.brokerId) {
        console.log(this.brokerList)
        this.selectedBrokerList = this.brokerList.filter(t => t.id == this.defaultParty.brokerId)

      }
      // this.setBrokerForStone=
      // this.selectedBrokerList.push(this.defaultParty.brokerId)
    }
    await this.configService.dismiss()
    this.loadGrid = true;
    if (this.transactionType_1) {
      this.manageTransactionStatus()
    }
    if (this.buyingPurpose_1) {
      this.manageBuyingPurpose()
    }
  }

  showDetails(party) {
    this.defaultParty = party;

    console.warn('showDetails');
    console.log(party);
    console.log(this.defaultParty);


    console.log(this.partyWiseStones);
    if (party.brokerId) {
      this.selectedBrokerList = this.brokerList.filter(t => t.id == party.brokerId)
      console.log(this.selectedBrokerList);
      this.setBrokerForStone(party.brokerId)
    } else {
      this.selectedBrokerList = []
    }
    if (party.transactionType) {
      this.statusidList = this.transactionStatusList.filter(t => t.name == party.transactionType)
      console.log(this.statusidList);
      this.setStatusforstone(party.transactionType)
    } else {
      this.statusidList = []

    }
    if (party.buyingPurpose) {
      this.BuyingidList = this.BuyingpurposeList.filter(t => t == party.buyingPurpose)
      console.log(this.BuyingidList);
      this.setBuyingPurposeForStone(party.buyingPurpose);
      console.log(this.buyingPurpose);
    } else {
      this.BuyingidList = []
    }

  }

  async updateEnquiry() {
    await this.configService.showLoader();

    let listOfStones = [];
    this.partyWiseStones.forEach(n => {
      let stones = n.stones
      stones.forEach(s => {
        let stoneName = s.stoneName;
        let transType = s.transactionType;
        let brokerId = s.brokerId || '';
        let partyMasterId = s.partyMasterId;
        let buyingPurpose = s.buyingPurpose;
        console.log(s.buyingPurpose);

        if (s.status || s.buyingPurpose) {
          let status = s.status
          let buyingPurpose = s.buyingPurpose
          listOfStones.push({
            'stoneName': stoneName,
            'brokerId': brokerId,
            'status': status,
            'enquiryId': this.editData.id,
            'partyMasterId': partyMasterId,
            'transactionType': transType,
            'buyingPurpose': buyingPurpose
          })
        }

      })
    })

    let obj = {
      "listOfStones": listOfStones
    }
    console.log(obj)

    let res: any;
    res = await this.httpApiService.updateEnquiry(obj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast(
        "success",
        "Enquiry updated successfully"
      );
      await this.modalCtrl.dismiss();
    }
  }


  async createEnquiry() {
    let listOfStones = [];
    await this.configService.showLoader();
    this.partyWiseStones.forEach(n => {
      let stones = n.stones
      stones.forEach(s => {
        let stoneName = s.stoneName
        let brokerId = s.brokerId || ''
        let transactionType = s.transactionType || ''
        let status = s.status
        let buyingPurpose = s.buyingPurpose

        listOfStones.push({
          'stoneName': stoneName,
          'brokerId': brokerId,
          // 'status': status,
          "transactionType": transactionType,
          "buyingPurpose": buyingPurpose



        })
        console.log(transactionType);
      })




    })


    let obj = {
      "customerId": !!this.selectedParty ? this.selectedParty.id : null,
      "refSalesPerson": this.selectedSalesPerson,
      "listOfStones": listOfStones
    }

    console.log(obj)
    // return
    let res: any;
    res = await this.httpApiService.craeteEnquiry(obj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (!!data.data.data && data.data.data.length > 0) {
        await this.configService.showToast(
          "error",
          "Error in few stones"
        );
        this.exportAsExcelService.exportAsExcelFile(data.data.data, 'Error_Stones')
      }
      else {
        await this.configService.showToast(
          "success",
          "Enquiry created successfully"
        );
      }
      await this.modalCtrl.dismiss();
    }

  }


  OnSelectRows(ev) {
    this.selected = ev.selectedRowsData;
    // this.selectedStoneList = []
    // this.selected.forEach(el => {
    //   this.selectedStoneList.push(el.stoneId)
    // });
  }



  setBrokerForStone(brokerId) {
    console.log(this.defaultParty.party)
    console.log(this.partyWiseStones)
    this.partyWiseStones.filter(x => x.party == this.defaultParty.party).map(s => {
      s.brokerId = brokerId
      s.stones.map(p => {
        p['brokerId'] = brokerId
      })

      return s
    })

    console.log(this.partyWiseStones)
  }
  setStatusforstone(transactionType) {
    this.defaultParty.transactionType = transactionType;
    console.log('setStatusforstone');
    this.partyWiseStones.transactionType = transactionType;
    let updatedpartyWiseStones = this.partyWiseStones.filter(x => x.party == this.defaultParty.party).map(s => {
      s.transactionType = transactionType
      s.stones.map(p => {
        p['transactionType'] = transactionType
      })
      return s
    })

    console.log(this.partyWiseStones);
  }
  setBuyingPurposeForStone(buyingPurpose) {
    console.log(buyingPurpose);
    this.defaultParty.buyingPurpose = this.buyingPurpose;
    this.partyWiseStones.filter(x => x.party == this.defaultParty.party).map(s => {
      s.buyingPurpose = buyingPurpose
      s.stones.map(p => {
        p['buyingPurpose'] = buyingPurpose
      })
      console.log("this is working finee");
      return s
    });

    console.log(this.partyWiseStones);
  }


  async getEnquiryById(id) {
    let res: any;
    res = await this.httpApiService.getEnquiryWithDetail(id)
    let data = res.json();

    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.editEnquiryData = data.data;
      this.customizeStoneData(this.editEnquiryData)
    }
  }
  setBuyingPurposeForStoneForAll(buyingPurpose) {

    this.defaultParty, buyingPurpose = buyingPurpose;
    console.log(this.partyWiseStones);
    this.partyWiseStones.map(plan => {
      let stones = plan.stones;
      stones = stones.map(ps => {
        ps['buyingPurpose'] = buyingPurpose;
        return ps;
      });
      plan['stones'] = stones;
      plan['buyingPurpose'] = buyingPurpose;


      return plan;
    });

  }
  setStatusforstoneForAll(transactionType) {

    this.defaultParty.transactionType = transactionType;
    this.partyWiseStones.stones =
      this.partyWiseStones.map(plan => {
        let stones = plan.stones;
        stones = stones.map(ps => {
          ps['transactionType'] = transactionType;
          this.defaultParty.transactionType = transactionType;
          return ps;
        });
        plan['stones'] = stones;
        plan['transactionType'] = transactionType;
        return plan;
      });

  }

  makeSelected(party) {
    return party === this.defaultParty.party;
  }

  async confirmSendMail(partyDetail) {
    console.log(partyDetail)
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to mail to purchase party?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.sendMailToPurchaseParty(partyDetail);
          }
        }
      ]
    });

    await alert.present();
  }


  async sendMailToPurchaseParty(partyDetail) {
    console.log(partyDetail)
    let res: any;
    let payload = {
      partyMasterId: partyDetail.partyMasterId,
      stoneIdList: partyDetail.stones.map(el => el.stoneName)
    }
    res = await this.httpApiService.sendMailToPurchaseParty(payload)
    let data = res.json();

    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.editEnquiryData = data.data;
      this.customizeStoneData(this.editEnquiryData)
    }
  }

  btndisable() {
    let b = false;
    if (this.partyWiseStones.length > 0) {

      this.partyWiseStones.forEach(n => {

        let stones = n.stones
        stones.forEach(s => {
          if (!!s.transactionType && !!s.buyingPurpose) {
            b = false;
          }
          else if (!!this.transactionStatusList) {
            b = true;

          }
        })
      })
      return b;
    }
    else {

      return true;
    }
  }


  manageTransactionStatus() {
    this.setStatusforstoneForAll(this.transactionType_1);
    this.showDetails(this.defaultParty);
  }

  manageBuyingPurpose() {
    this.setBuyingPurposeForStoneForAll(this.buyingPurpose_1);
    this.showDetails(this.defaultParty);
  }

}


