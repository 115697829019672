import { Component, Input } from "@angular/core";
import { ModalController, LoadingController, Events, AlertController } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import * as moment from "moment";
import { InterBoxTransferPage } from "../../../components/actions/inter-box-transfer/inter-box-transfer.page";

@Component({
  selector: 'app-box-transfer',
  templateUrl: './box-transfer.component.html',
  styleUrls: ['./box-transfer.component.scss']
})
export class BoxTransferComponent implements ProcessActionComponent {
  @Input() data: any;

  public fromCts: any;
  public toCts: any;
  public colorList = [];
  public clarityList = [];
  public shapeList = [];
  public colorIdList = [];
  public shapeIdList = [];
  public clarityIdList = [];
  public shapeId = [];
  public clarityId = [];
  public colorId = [];
  public type = 'from';
  public csspList = [];
  public count = 0;
  public page = 'Inter Box Transfer';
  public parcelCount = 0
  public selectedcsspList = []
  public columns = [];
  public selected = [];
  public isSelect = false;
  public sizeList = [];
  public sizeIdList = [];
  public sizeId: any;
  public shapeName: any;
  public boxType: any;
  public departmentName: any;
  public isProgram: boolean = false;
  public selectedParcel = []
  public parcelCsspList = []
  public isSelectParcel = false;
  public internalStatus: any;
  public showHistory = false;
  public histories = [];
  public pageNumber = 0;
  public noOfRecords = 20;
  public historyColumn = [];
  public singleSelection: any = false;
  public sizeListArr = []

  constructor(public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public event: Events,
    public alertCtrl: AlertController) { }

  async ngOnInit() {

    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },

    { name: "CSSP", prop: "cssp" },
    { name: "Lotno", prop: "stoneId" },
    { name: "Box Name", prop: "boxName" },
    { name: "Ct.", prop: "size" },
    { name: "Inward Date", prop: "inwardDate" }]
    this.historyColumn = [{ name: "Sr No", prop: "" },
    { name: "From CSSP", prop: "fromCsspStoneId" },
    { name: "To CSSP", prop: "toCsspStoneId" },
    { name: "Ct.", prop: "cts" },
    { name: "Created Date", prop: "createdDate" },
    { name: "Delete", prop: "delete" }
    ];
    await this.getDepartment();
    await this.getDefaultSetting()
    await this.findShapeByType();
    await this.getAllHistory(this.pageNumber, this.noOfRecords);
    this.event.subscribe('Inter Box Transfer', (item) => {
      this.getDepartment();
      this.isSelect = false;
      this.selected = []
      this.sizeList = []
      this.shapeList = []
      this.selectedcsspList = []
      this.csspList = []
      this.isProgram = false;
      this.shapeId = [];
      this.fromCts = null;
      this.toCts = null;
      this.sizeIdList = [];
      this.shapeIdList = []
      this.isSelectParcel = false;
      this.parcelCsspList = []
      this.selectedParcel = []
      this.getDefaultSetting()
      this.findShapeByType();

    })
  }

  async getDepartment() {
    let userData = await this.authService.userData();
    this.departmentName = userData.departmentName;
    if (this.departmentName == 'Assortment') {
      this.singleSelection = false;
    } else {
      this.singleSelection = true;
    }
  }

  ngOnDestroy() {
    this.event.unsubscribe('Inter Box Transfer')
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (!!dataValue.map) {
        if (!!dataValue.map.type) {
          this.type = dataValue.map.type;

        }
        if (!!dataValue.map.boxType) {
          this.boxType = dataValue.map.boxType;
        }
        if (!!dataValue.map.internalStatus) {
          this.internalStatus = dataValue.map.internalStatus
        }
      }
      console.log(this.type, this.boxType);
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'shapeList') {
      if (idList.length > 0) {
        this.fromCts = null;
        this.toCts = null;
        this.shapeId = idList;
        this.shapeName = obj[0].name;
        console.log(idList, idList[0])
        this.sizeIdList = []
        this.findSizeByShape(idList[0])
      } else {
        this.sizeIdList = []
        this.shapeId = [];
        this.fromCts = null;
        this.toCts = null;
        this.sizeIdList = []
        this.sizeList = []
      }
    } else if (property == 'sizeList') {
      if (idList.length > 0) {
        if (this.singleSelection) {
          this.sizeId = idList[0];
          this.sizeList.filter(el => {
            if (el.id == idList[0]) {
              this.fromCts = el.fromCts;
              this.toCts = el.toCts;
            }
          })
        } else {
          this.sizeId = idList[0];
          this.sizeListArr = []
          this.sizeList.filter(el => {
            idList.filter(item => {
              if (el.id == item) {
                this.fromCts = el.fromCts;
                this.toCts = el.toCts;
                this.sizeListArr.push(`${el.fromCts}-${el.toCts}`)
              }
            })
          })
        }

      } else {
        this.fromCts = null;
        this.toCts = null;
        this.sizeListArr = []
        this.sizeIdList = []
      }
      console.log(this.sizeListArr)
    }
  }

  async findShapeByType() {
    let res: any;
    res = await this.httpApiService.findAvailalbeShapeByType(!!this.type ? this.type : 'Assort');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let data1 = []
      dataValue.forEach(el => {
        data1.push({ id: el.shape, name: el.shapeName })
      })
      this.shapeList = data1;
      this.sizeList = []
      this.fromCts = null;
      this.toCts = null;
    }
  }

  async findSizeByShape(shapeId) {
    let res: any;
    res = await this.httpApiService.findAvailableSizeByShape(shapeId, !!this.type ? this.type : 'Assort');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.sizeList = data.data;
      console.log(this.sizeList)
    }
  }

  async searchCSSP() {
    this.csspList = []
    this.parcelCsspList = []
    this.count = 0;
    this.parcelCount = 0
    this.selected = []
    this.selectedParcel = []
    let res: any;
    await this.configService.showLoader();
    let jsonObj;
    if (this.departmentName == 'Assortment') {
      if (this.isProgram == true && (!!this.shapeId) && this.sizeListArr && this.sizeListArr.length > 0) {
        jsonObj = {
          shapeId: this.shapeId,
          // fromCts: this.fromCts,
          // toCts: this.toCts,
          availableStock: true,
          sizeList: this.sizeListArr,
          isProgram: this.isProgram,
          listOfBoxType: ['Assortment-OK', 'Program-Parcel']
        }
      } else if (this.isProgram == false && (!!this.shapeId) && this.sizeListArr && this.sizeListArr.length > 0) {
        jsonObj = {
          shapeId: this.shapeId,
          // fromCts: this.fromCts,
          // toCts: this.toCts,
          availableStock: true,
          sizeList: this.sizeListArr,
          isProgram: this.isProgram,
          listOfBoxType: ['Assortment-OK', 'Parcel'],
        }
      } else {
        jsonObj = {
          availableStock: true,
          isProgram: this.isProgram,
          sizeList: this.sizeListArr,
          listOfBoxType: ['Assortment-OK', 'Program-Parcel']
        }
      }

    } else {
      this.isProgram = null;
      jsonObj = {
        shapeId: this.shapeId,
        fromCts: this.fromCts,
        internalStatus: this.internalStatus,
        toCts: this.toCts,
        availableStock: true,
        listOfBoxType: ['Regular', 'Purchase', 'Program'],
      }
    }

    res = await this.httpApiService.findCsspOrCreateIfNotExist(jsonObj, 'false');
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (this.isProgram == true) {
        if (data.data.length > 0) {
          data.data.forEach(el => {
            if (el.boxType == 'Program-Parcel') {
              this.parcelCsspList.push(el)
            } else {
              this.csspList.push(el)
            }
          })
          this.count = this.csspList.length;
          this.parcelCount = this.parcelCsspList.length;
        } else {
          await this.configService.showToast('error', 'Searched Cssp not found.')
        }
      } else if (this.isProgram == false) {
        if (data.data.length > 0) {
          data.data.forEach(el => {
            if (el.boxType == 'Parcel') {
              this.parcelCsspList.push(el)
            } else {
              this.csspList.push(el)
            }
          })
          this.count = this.csspList.length;
          this.parcelCount = this.parcelCsspList.length;
        } else {
          await this.configService.showToast('error', 'Searched Cssp not found.')
        }
      }
      else {
        this.csspList = data.data;
        if (this.csspList.length > 0) {
          this.count = data.data.length;
        } else {
          await this.configService.showToast('error', 'Searched Cssp not found.')
        }
        this.selectedcsspList = []
      }
      console.log(this.csspList)
    }
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  onSelect(ev) {
    this.isSelectParcel = false;
    this.selectedParcel = []
    this.selected = ev.selectedRowsData;
    console.log(this.selected)
    this.selectedcsspList = [];
    this.selected.forEach(el => {
      this.selectedcsspList.push(el);
    })
  }

  onParcelSelect(ev) {
    this.selected = []
    this.selected = ev.selectedRowsData;
    this.selectedcsspList = [];
    this.selectedParcel.forEach(el => {
      this.selectedcsspList.push(el);
    })
  }


  async selectAll(item, itemList) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(itemList)
      this.isSelect = true;
      this.selectedcsspList = [];
      this.selected.forEach(el => {
        console.log(el)
        this.selectedcsspList.push(el)
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.selectedcsspList = []
    }
  }

  async selectParcelAll(item, itemList) {
    if (item == true) {
      let arr = []
      this.selectedParcel = arr.concat(itemList)
      this.isSelectParcel = true;
      this.selectedcsspList = [];
      this.selectedParcel.forEach(el => {
        console.log(el)
        this.selectedcsspList.push(el)
      });
    } else {
      this.isSelectParcel = false;
      this.selectedParcel = []
      this.selectedcsspList = []
    }
  }

  async interBoxTransfer() {
    let modalPage = await this.modalCtrl.create({
      component: InterBoxTransferPage,
      backdropDismiss: false,
      componentProps: {
        stoneList: this.selectedcsspList,
        shapeName: this.shapeName,
        actionId: this.data.actionId, boxType: this.boxType,
        createNew: !!this.departmentName && this.departmentName == 'Assortment' ? true : false,
        isProgram: this.isProgram
      },
      cssClass: "requestPage"
    })
    modalPage
      .onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modalPage.present();
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      this.isSelect = false;
      this.isSelectParcel = false;
      this.parcelCsspList = []
      this.csspList = [];
      this.selectedcsspList = [];
    }
  }

  async paginationFunction(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getAllHistory(this.pageNumber, this.noOfRecords);
  }

  async getAllHistory(pageNumber, noOfRecords) {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.findAllTransferedCsspByType("interBox",
      pageNumber, noOfRecords
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.histories = data.data.content;
      console.log(this.histories);
    }
  }

  async deleteConfirm(item) {
    console.log(item)
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.delete(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async delete(item) {
    console.log(item)
    let res: any;
    res = await this.httpApiService.deleteTransferedCsspStone(item.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.getAllHistory(this.pageNumber, this.noOfRecords);
    }
  }

}
