import { Component, OnInit, Input } from "@angular/core";
import { ModalController, LoadingController, Events, AlertController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-scan-box-error-management',
  templateUrl: './scan-box-error-management.component.html',
  styleUrls: ['./scan-box-error-management.component.scss']
})
export class ScanBoxErrorManagementComponent implements OnInit {
  public page = "scanBoxError";
  public columns: any;
  public count: Number = 0;
  public listOfErrors: any = [];
  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 50;
  public selectedRows = [];
  public RfIds = [];
  public boardBaseUrl: any;

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events,
    public alertCtrl: AlertController,
  ) { }

  ngOnInit() {
    this.columns = [
      { name: "Stone Id", prop: "stoneId" },
      { name: "RFID", prop: "rfid" },
      { name: "Error", prop: "error" }
    ];
  }

  OnSelectRows(event){
    this.selectedRows = event.selectedRowsData;
    this.RfIds = this.selectedRows.map(x => x.rfid);
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async addToBox(){
    let obj = {
      setOfValues : this.RfIds ,
      isAssignment: true,
      overwriteBox: true
    };
    console.log(obj)
    let resp: any;
    resp = await this.httpApiService.scanBox(obj);
    let dataValue = resp.json();
    await this.configService.dismiss();
    if (resp.status == 401) {
      this.authService.logout();
    } else if (resp.status == 500) {
      await this.configService.showToast("error", dataValue.error);
    } else if (resp.status == 400) {
      await this.configService.showToast("error", dataValue.error);
    } else if (dataValue.data.listOfErrors) {
      await this.configService.showToast("error", dataValue.data.message);     
    }
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
  }

  async findByLED() {
    let issueList = []
    this.RfIds.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.RfIds = issueList.filter(function (el) {
      return el != "";
    });
    console.log(this.RfIds)
    if (this.RfIds.length > 0) {
      if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
        await this.configService.showLoader();
        let res: any;
        res = await this.httpApiService.scanWithLightOn(this.boardBaseUrl, this.RfIds);
        console.log(res);
        // let data = res.json();
        console.log("findBy LED", res);
        this.configService.dismiss();
      }
      else {
        await this.configService.showToast("error", "Scanner Board Url not found");
      }
    } else {
      await this.configService.showToast("error", "Please add stone!");
    }
  }

}
