import { Component, HostListener, Input, ViewChild } from "@angular/core";
import { AlertController, Events, LoadingController, ModalController } from "@ionic/angular";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import * as moment from "moment";
import { ExportAsExcelService } from "src/app/services/export-as-excel.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageExternalInventoryStonesPage } from "../../actions/manage-external-inventory-stones/manage-external-inventory-stones.page";
import { ManageInventoryManagerPage } from "../../actions/manage-inventory-manager/manage-inventory-manager.page";
import { ManageRequestApprovalPage } from "../../actions/manage-request-approval/manage-request-approval.page";
import { ManageRequestsPage } from "../../actions/manage-requests/manage-requests.page";
import { PdAssortDetailsPage } from "../../actions/pd-assort-details/pd-assort-details.page";
import { ScanRFIDPage } from "../../actions/scan-rfid/scan-rfid.page";
import { UploadBulkToSinglePage } from "../../actions/upload-bulk-to-single/upload-bulk-to-single.page";
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: "app-inventory-manager",
  templateUrl: "./inventory-manager.component.html",
  styleUrls: ["./inventory-manager.component.scss"]
})
export class InventoryManagerComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild("dataTable") public dataTable: any;
  @Input() inventoryManagerType: any;

  public assortTab: any = "Pending";
  public listOfAssortedPendingStones: any = [];
  public listOfAssortedPendingStonesDataSource: any = {};
  public listOfAssortedReceivedStones: any = [];
  public listOfAssortedReceivedStonesDataSource: any = {};
  public listOfBoxedStones: any = [];
  public listOfBoxedStonesDataSource: any = {};
  public listOfOutGoingStone: any = [];
  public listOfOutGoingStoneDataSource: any = {};
  public listOfDeliveredStone: any = [];
  public listOfDeliveredStoneDataSource: any = {};
  public listOfPickedStone: any = [];
  public listOfPickedStoneDataSource: any = {};
  public listOfTransferredStone: any = [];
  public listOfTransferredStoneDataSource: any = {};
  public listOfRequestedStone: any = [];
  public listOfRequestedStoneDataSource: any = {};
  public totalCrt: any;
  public pageSize = 50;
  public pageNumber: number = 0;
  public noOfRecords = 50;
  public totalPages: any;
  public count: any;
  public status: any;
  public type = "";
  public userId: any;
  public fromDepartment: any;
  public isBoxedTab: any;
  public isPickedTab: boolean;
  public departmentList: any;
  public voucherBookId: any;
  public columns: any;
  public page = "inManager";
  public deptName: any;
  public option = "TRANSACTION WISE";
  public outRequestTotal = 0;
  public countMismatchIncoming: Number = 0;
  public countMismatchOutgoing: Number = 0;
  public interval: any;
  public selected = []
  public tab: any = "Pending";
  public role: any;
  public boardBaseUrl: any;
  public isSelect: Boolean = false;
  public selectedStoneControlNoList = [];
  public trayList = []
  public kamUserList = []
  public boxId: any;
  public kamUserId: any;
  public transferStoneList = []
  public transType: any;
  public errorMsgList = []
  public msgStatus = false;
  public flagBox: any = 'availableBoxList';
  public successMsg: any;
  public scannedRFid: any = [];

  public errorMsg: any;
  public listOfStones = [];
  public listOfStonesDataSource: any = {};
  public boxName: any;
  public boxLevel: any;
  public outGoingTab = "pickedOnly";

  public loadGrid: boolean = false;
  public ledOn: boolean = false;

  //view variable
  public optionList = [];
  public keyFun: any = true;
  public openView: any = false;
  public notFound: any;
  public refreshGrid: boolean = false;

  public currentScanCount: number = 0;
  public boxDetail = [];
  public scanCount: number = 0;
  public ledOnCount: number = 0;
  public ledOffList = [];
  public ledOnStonesIndex: any = [];
  public companyId: any;

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public configService: ConfigService,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService
  ) {
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
    if (!!userData.departmentName) {
      this.fromDepartment = userData.departmentName;
      this.deptName = userData.departmentName;
      if (this.deptName == 'Assortment') {
        this.optionList = [{ id: 1, name: 'TRANSACTION WISE', isSelected: true }, { id: 2, name: 'ASSORTMENT RETURN', isSelected: false }, { id: 2, name: 'PD RETURN', isSelected: false }]
      } else if (this.deptName == 'KAM Sales') {
        this.optionList = [{ id: 1, name: 'TRANSACTION WISE', isSelected: false }, { id: 2, name: 'STONE WISE', isSelected: true }, { id: 2, name: 'CSSP WISE', isSelected: false }]
      }
      else {
        this.optionList = [];
        this.notFound = "View not found."
      }
      if (!!this.inventoryManagerType && this.inventoryManagerType == 'MIX') {
        this.optionList = [{ id: 1, name: 'TRANSACTION WISE', isSelected: true }, { id: 2, name: 'CSSP WISE', isSelected: false }]
      }
      if (!!this.inventoryManagerType && this.inventoryManagerType == 'SS') {
        this.optionList = [{ id: 1, name: 'TRANSACTION WISE', isSelected: true }, { id: 2, name: 'STONE WISE', isSelected: false }]
      }
      console.log("this.optionList", this.inventoryManagerType, this.optionList);
    }
  }

  async ngOnInit() {
    this.companyId = await this.authService.getCompanyId()
    this.page = "inManager";
    await this.getUserData();
    await this.viewDataByOption(this.optionList);
    await this.getCountForIncomingAndOutReuqest();
    this.configService.actionSubject$.subscribe(actionData => {
      if (!!actionData && (actionData == 'Inventory Manager SS' ||
        actionData == 'Inventory Manager MIX' ||
        actionData == 'Inventory Manager')) {
        this.getCountForIncomingAndOutReuqest();
        this.viewDataByOption(this.optionList);
      }
    })
    // if (!!this.inventoryManagerType && this.inventoryManagerType == 'SS') {
    //   this.event.subscribe('Inventory Manager SS', (item) => {
    //     this.getCountForIncomingAndOutReuqest();
    //     this.viewDataByOption(this.optionList);
    //     this.event.unsubscribe('Inventory Manager SS');
    //   })
    // } else if (!!this.inventoryManagerType && this.inventoryManagerType == 'MIX') {
    //   this.event.subscribe('Inventory Manager MIX', (item) => {
    //     this.getCountForIncomingAndOutReuqest();
    //     this.viewDataByOption(this.optionList);
    //     this.event.unsubscribe('Inventory Manager MIX');
    //   })
    // }
    // this.event.subscribe('Inventory Manager', (item) => {
    //   this.getCountForIncomingAndOutReuqest();
    //   this.viewDataByOption(this.optionList);
    //   this.event.unsubscribe('Inventory Manager');
    // })
    // this.interval = setInterval(() => {
    //   this.getCountForIncomingAndOutReuqest();
    // }, 1000 * 10);
  }

  ngOnDestroy() {
    this.configService.actionSubject.next('');
    this.event.unsubscribe('Inventory Manager');
    clearInterval(this.interval);
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "trays") {
      if (idList.length > 0) {
        this.boxId = idList[0];
        this.filterOutRequest()
      } else {
        this.boxId = null;
        this.filterOutRequest()
      }
    } else if (property == "kamUsers") {
      if (idList.length > 0) {
        this.kamUserId = idList.toString();
        this.filterOutRequest()
      } else {
        this.kamUserId = null;
        this.filterOutRequest()
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.keyFun) {
      if (this.deptName == 'Sales') {
        if (this.tab == 'Pending' || this.tab == 'Received' || this.tab == 'Boxed' || this.tab == 'outRequest') {
          if (event.keyCode == 86) {
            this.openView = true;
          }
        }
      } else {
        if (this.tab == 'Pending' || this.tab == 'Boxed' || this.tab == 'Received') {
          if (event.keyCode == 86) {
            this.openView = true;
          }
        }
      }

    }
  }


  async closeViewFun() {
    this.openView = false;
  }

  async viewDataByOption(optionList) {
    optionList.forEach(el => {
      if (el.isSelected == true) {
        this.option = el.name;
      }
    })
    this.pageNumber = 0;
    this.noOfRecords = 50;
    this.selected = []
    this.isSelect = false;
    console.log(this.option, this.tab)
    if (this.option == 'TRANSACTION WISE') {
      if (this.tab == "Requested") {
        this.columns = [
          { name: "Requested for", prop: "requestFor" },
          { name: "Party Name", prop: "partyName" },
          { name: "Pkts", prop: "pendingPackets" },
          { name: "Ct.", prop: "pendingCarat" },
          { name: "Date & Time", prop: "modifiedDate" },
          // { name: "Is Approved", prop: "isApproved" },
          { name: "Is Transfer Approved", prop: "isTransferApproved" },
          { name: "Status", prop: "status" },
          { name: "Request From", prop: "requestPlatform" },
          { name: "Voucher No", prop: "" },
          { name: "Reference Voucher No", prop: "referenceVoucher" },
          { name: "Cancel Request", prop: "cancelRequest" }
        ];
        this.requestedStone(this.pageNumber, this.noOfRecords);
      } else if (this.tab == 'Pending') {
        if (this.deptName == "KAM Sales") {
          this.columns = [
            { name: "Voucher No", prop: "" },
            { name: "Reference Voucher No", prop: "referenceVoucher" },
            { name: "Trans Type", prop: "transType" },
            { name: "Pkts", prop: "pendingPackets" },
            { name: "Ct.", prop: "pendingCarat" },
            { name: "From", prop: "username" },
            { name: "Date & Time", prop: "modifiedDate" }
          ];
        } else {
          this.columns = [
            { name: "Voucher No", prop: "" },
            { name: "Trans Type", prop: "transType" },
            { name: "Pkts", prop: "pendingPackets" },
            { name: "Ct.", prop: "pendingCarat" },
            { name: "From", prop: "username" },
            { name: "Date & Time", prop: "modifiedDate" }
          ];
        }
        this.pendingStones(this.pageNumber, this.noOfRecords);
      } else if (this.tab == 'Received') {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Trans Type", prop: "transType" },
          { name: "Pkts", prop: "pendingPackets" },
          { name: "Ct.", prop: "pendingCarat" },
          { name: "Date & Time", prop: "modifiedDate" }
        ];
        this.receivedStones(this.pageNumber, this.noOfRecords);
      } else if (this.tab == 'Boxed') {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Pkts", prop: "pendingPackets" },
          { name: "Ct.", prop: "pendingCarat" },
          { name: "Date & Time", prop: "modifiedDate" }
        ];
        this.getBoxedStone(this.pageNumber, this.noOfRecords);
      } else if (this.tab == 'outRequest') {
        await this.getCountForIncomingAndOutReuqest();
        if (this.deptName == "Sales") {
          this.columns = [
            { name: "Voucher No", prop: "" },
            { name: "Requested for", prop: "requestFor" },
            { name: "Pkts", prop: "pendingPackets" },
            { name: "Ct.", prop: "pendingCarat" },
            { name: "Amount", prop: "requestAmt" },
            { name: "Party Name", prop: "toLab" },
            { name: "To Location", prop: "toLocation" },
            { name: "Created By", prop: "createdBy" },
            { name: "Stock Controller", prop: "stockControllerName" },
            { name: "Date & Time", prop: "modifiedDate" },
            { name: "Cancel Request", prop: "cancelRequest" }
          ];
        } else {
          this.columns = [
            { name: "Voucher No", prop: "" },
            { name: "Pkts", prop: "pendingPackets" },
            { name: "Ct.", prop: "pendingCarat" },
            { name: "Created By", prop: "createdBy" },
            { name: "Date & Time", prop: "modifiedDate" }
          ];
        }
        console.log(this.columns)
        await this.getOutGoingRequestStone(this.pageNumber, this.noOfRecords);
      } else if (this.tab == 'outgoing') {
        console.log(this.tab, this.outGoingTab)
        if (this.outGoingTab == 'pickedOnly') {
          this.columns = [
            { name: "Voucher No", prop: "" },
            { name: "Requested for", prop: "requestFor" },
            { name: "Pkts", prop: "pendingPackets" },
            { name: "Ct.", prop: "pendingCarat" },
            { name: "Amount", prop: "requestAmt" },
            { name: "Party Name", prop: "toLab" },
            { name: "To Location", prop: "toLocation" },
            { name: "Created By", prop: "createdBy" },
            { name: "Stock Controller", prop: "stockControllerName" },
            { name: "Date & Time", prop: "modifiedDate" },
            { name: "Cancel Request", prop: "cancelRequest" }
          ];
          this.findOutGoingPickedVouchers(this.pageNumber, this.noOfRecords);
        } else if (this.outGoingTab == 'transferred') {
          this.columns = [
            { name: "Voucher No", prop: "" },
            { name: "Reference Voucher", prop: "referenceVoucher" },
            { name: "Requested for", prop: "requestFor" },
            { name: "Pkts", prop: "pendingPackets" },
            { name: "Ct.", prop: "pendingCarat" },
            { name: "Amount", prop: "requestAmt" },
            { name: "Party Name", prop: "toLab" },
            { name: "To Location", prop: "toLocation" },
            { name: "Created By", prop: "createdBy" },
            { name: "Date & Time", prop: "modifiedDate" }
          ];
          this.getTransferredStone(this.pageNumber, this.noOfRecords);
        }
      } else if (this.tab == 'delivered') {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Reference Voucher", prop: "referenceVoucher" },
          { name: "Requested for", prop: "requestFor" },
          { name: "Party Name", prop: "partyName" },
          { name: "Pkts", prop: "pendingPackets" },
          { name: "Ct.", prop: "pendingCarat" },
          { name: "Date & Time", prop: "modifiedDate" },
          { name: "Created By", prop: "createdBy" },
        ];
        this.getDeliveredStone(this.pageNumber, this.noOfRecords);
      }
    } else if (this.option == 'STONE WISE') {
      this.pageNumber = 0;
      this.noOfRecords = 50;
      this.columns = [
        { name: "StoneId", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "stoneCts" },
        { name: "Clarity", prop: "clarity" },
        { name: "Cut", prop: "cut" },
        { name: "Polish", prop: "polishCode" },
        { name: "Symmetry", prop: "symmetryCode" },
        { name: "Fluorescence", prop: "fluorescence" },
        { name: "Lab", prop: "lab" },
        { name: "Date & Time", prop: "modifiedDate" },
      ];
      if (this.tab == 'Pending') {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "StoneId", prop: "stoneId" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Party Name", prop: "partyName" },
          { name: "Remark", prop: "stoneRemarks" },
          { name: "Clarity", prop: "clarity" },
          { name: "Cut", prop: "cut" },
          { name: "Polish", prop: "polishCode" },
          { name: "Symmetry", prop: "symmetryCode" },
          { name: "Fluorescence", prop: "fluorescence" },
          { name: "Lab", prop: "lab" },
          { name: "Date & Time", prop: "modifiedDate" },
        ];
        this.getIncomingStoneToSales('SS', this.pageNumber, this.noOfRecords);

      } else if (this.tab == 'Received') {
        this.getReceivedStoneToSales('SS', this.pageNumber, this.noOfRecords);
      } else if (this.tab == 'outRequest') {
        this.transType = 'SS';
        this.columns = [
          { name: "StoneId", prop: "stoneId" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Tray Name", prop: "boxLabel" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Clarity", prop: "clarity" },
          { name: "Request For", prop: "requestFor" },
          { name: "Request By", prop: "username" },
          { name: "Date & Time", prop: "modifiedDate" },
        ];
        this.noOfRecords = 10000;
        this.findOutRequestStocksByTransType('SS', this.pageNumber, this.noOfRecords);
      }
    } else if (this.option == 'CSSP WISE') {
      this.pageNumber = 0;
      this.noOfRecords = 50;
      this.columns = [
        { name: "CSSP", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "stoneCts" },
        { name: "Clarity", prop: "clarity" },
        { name: "Date & Time", prop: "modifiedDate" },
      ];
      if (this.tab == 'Pending') {
        await this.getIncomingStoneToSales('MIX', this.pageNumber, this.noOfRecords);

      } else if (this.tab == 'Received') {
        this.getReceivedStoneToSales('MIX', this.pageNumber, this.noOfRecords);
      } else if (this.tab == 'outRequest') {
        this.transType = 'MIX'
        this.columns = [
          { name: "StoneId", prop: "stoneId" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Tray Name", prop: "boxLabel" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Clarity", prop: "clarity" },
          { name: "Request For", prop: "requestFor" },
          { name: "Request By", prop: "username" },
          { name: "Date & Time", prop: "modifiedDate" },
        ];
        this.findOutRequestStocksByTransType('MIX', this.pageNumber, this.noOfRecords);
      }
    }
    // Assortment
    else if (this.option == 'ASSORTMENT RETURN') {
      if (this.tab == 'Pending') {
        this.pageNumber = 0;
        this.noOfRecords = 50;
        this.columns = [
          { name: "StoneId", prop: "stoneId" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Clarity", prop: "clarity" },
          { name: "Cut", prop: "cut" },
          { name: "Polish", prop: "polishCode" },
          { name: "Symmetry", prop: "symmetryCode" },
          { name: "Fluorescence", prop: "fluorescence" },
          { name: "Date & Time", prop: "modifiedDate" },
        ];
        this.findIncomingByVoucherTypeAndTransType('MIX', this.pageNumber, this.noOfRecords);
      } else if (this.tab == 'Received') {
        this.pageNumber = 0;
        this.noOfRecords = 50;
        this.columns = [
          { name: "StoneId", prop: "stoneId" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Clarity", prop: "clarity" },
          { name: "Date & Time", prop: "modifiedDate" },
          { name: "Asssortment Status", prop: "isReadyForSale" },
        ];
        this.findReceivedByVoucherTypeAndTransType('MIX', this.pageNumber, this.noOfRecords);
      }
    }
    // Assortment
    else if (this.option == 'PD RETURN') {
      if (this.tab == 'Pending') {
        this.pageNumber = 0;
        this.noOfRecords = 50;
        this.columns = [
          { name: "StoneId", prop: "stoneId" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Clarity", prop: "clarity" },
          { name: "Cut", prop: "cut" },
          { name: "Polish", prop: "polishCode" },
          { name: "Symmetry", prop: "symmetryCode" },
          { name: "Fluorescence", prop: "fluorescence" },
          { name: "Lab", prop: "lab" },
          { name: "Date & Time", prop: "modifiedDate" },
        ];
        this.findIncomingByVoucherTypeAndTransType('SS', this.pageNumber, this.noOfRecords);
      }
      else if (this.tab == 'Received') {
        this.pageNumber = 0;
        this.noOfRecords = 50;
        this.columns = [
          { name: "StoneId", prop: "stoneId" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Clarity", prop: "clarity" },
          { name: "Cut", prop: "cut" },
          { name: "Polish", prop: "polishCode" },
          { name: "Symmetry", prop: "symmetryCode" },
          { name: "Fluorescence", prop: "fluorescence" },
          { name: "Lab", prop: "lab" },
          { name: "Date & Time", prop: "modifiedDate" },
          { name: "PD Status", prop: "isReadyForSale" },
        ];
        this.findReceivedByVoucherTypeAndTransType('SS', this.pageNumber, this.noOfRecords);
      }
    }
    else if (this.option == 'BOX WISE') {
      this.flagBox = 'availableBoxList'
      this.columns = [
        { name: "StoneId", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "stoneCts" },
        { name: "Clarity", prop: "clarity" },
      ];
      this.findAvailableStockBox();
    }
  }

  async changeSegment(event) {
    this.columns = []
    this.changeViewType('availableBoxList', 0)
    this.listOfAssortedPendingStones = [];
    this.listOfAssortedReceivedStones = [];
    this.listOfOutGoingStone = [];
    this.listOfDeliveredStone = [];
    this.listOfPickedStone = [];
    this.listOfTransferredStone = [];
    this.listOfRequestedStone = [];
    this.closeDiv()
    this.isSelect = false;
    this.selected = []
    this.transferStoneList = []
    await this.getUserData();
    console.log(event.detail.value);
    this.option = 'TRANSACTION WISE'
    if (event.detail.value == "Requested") {
      this.columns = [
        { name: "Requested for", prop: "requestFor" },
        { name: "Party Name", prop: "partyName" },
        { name: "Pkts", prop: "pendingPackets" },
        { name: "Ct.", prop: "pendingCarat" },
        { name: "Date & Time", prop: "modifiedDate" },
        // { name: "Is Approved", prop: "isApproved" },
        { name: "Is Transfer Approved", prop: "isTransferApproved" },
        { name: "Status", prop: "status" },
        { name: "Request From", prop: "requestPlatform" },
        { name: "Voucher No", prop: "" },
        { name: "Reference Voucher No", prop: "referenceVoucher" },
        { name: "Cancel Request", prop: "cancelRequest" }
      ];
    } else if (event.detail.value == "Pending") {
      if (this.deptName == "KAM Sales") {
        this.optionList[0].isSelected = false;
        this.optionList[1].isSelected = true;
        this.optionList[2].isSelected = false;
        this.option = 'STONE WISE';
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "StoneId", prop: "stoneId" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Party Name", prop: "partyName" },
          { name: "Remark", prop: "stoneRemarks" },
          { name: "Clarity", prop: "clarity" },
          { name: "Cut", prop: "cut" },
          { name: "Polish", prop: "polishCode" },
          { name: "Symmetry", prop: "symmetryCode" },
          { name: "Fluorescence", prop: "fluorescence" },
          { name: "Lab", prop: "lab" },
          { name: "Date & Time", prop: "modifiedDate" },
        ];
      } else {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Trans Type", prop: "transType" },
          { name: "Pkts", prop: "pendingPackets" },
          { name: "Ct.", prop: "pendingCarat" },
          { name: "From", prop: "username" },
          { name: "Date & Time", prop: "modifiedDate" }
        ];
      }

    } else if (event.detail.value == 'Received') {
      this.columns = [
        { name: "Voucher No", prop: "" },
        { name: "Trans Type", prop: "transType" },
        { name: "Pkts", prop: "pendingPackets" },
        { name: "Ct.", prop: "pendingCarat" },
        { name: "Date & Time", prop: "modifiedDate" }
      ];
    } else if (
      event.detail.value == "outRequest" ||
      event.detail.value == "outgoing"
    ) {
      console.log("*****")
      if (this.deptName == "Sales") {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Requested for", prop: "requestFor" },
          { name: "Pkts", prop: "pendingPackets" },
          { name: "Ct.", prop: "pendingCarat" },
          { name: "Amount", prop: "requestAmt" },
          { name: "Party Name", prop: "toLab" },
          { name: "To Location", prop: "toLocation" },
          { name: "Created By", prop: "createdBy" },
          { name: "Stock Controller", prop: "stockControllerName" },
          { name: "Date & Time", prop: "modifiedDate" },
        ];
        if (event.detail.value == "outRequest" || this.outGoingTab == 'pickedOnly') {
          this.columns.push({ name: "Cancel Request", prop: "cancelRequest" })
        }
      } else {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Pkts", prop: "pendingPackets" },
          { name: "Ct.", prop: "pendingCarat" },
          { name: "Created By", prop: "createdBy" },
          { name: "Date & Time", prop: "modifiedDate" }
        ];
      }
    } else if (event.detail.value == "Boxed") {
      this.optionList = []
      this.option = 'BOX WISE';
      this.optionList = [{ id: 2, name: 'BOX WISE', isSelected: true }]
      if (this.deptName == 'Sales' && !!this.inventoryManagerType && this.inventoryManagerType == 'MIX') {
        this.option = 'BOX WISE';
        this.flagBox = 'availableBoxList'
        this.columns = [
          { name: "StoneId/CSSP", prop: "stoneId" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Clarity", prop: "clarity" },
        ];

      } else {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Pkts", prop: "pendingPackets" },
          { name: "Ct.", prop: "pendingCarat" },
          { name: "Date & Time", prop: "modifiedDate" }
        ];
      }
    } else if (event.detail.value == "delivered") {
      this.columns = [
        { name: "Voucher No", prop: "" },
        { name: "Reference Voucher", prop: "referenceVoucher" },
        { name: "Requested for", prop: "requestFor" },
        { name: "Party Name", prop: "partyName" },
        { name: "Pkts", prop: "pendingPackets" },
        { name: "Ct.", prop: "pendingCarat" },
        { name: "Date & Time", prop: "modifiedDate" },
        { name: "Created By", prop: "createdBy" },
      ];
    }
    else {
      this.columns = [
        { name: "Voucher No", prop: "" },
        { name: "Pkts", prop: "pendingPackets" },
        { name: "Ct.", prop: "pendingCarat" },
        { name: "Date & Time", prop: "modifiedDate" }
      ];
    }
    this.pageNumber = 0;
    this.noOfRecords = 50;
    this.tab = event.detail.value;
    if (event.detail.value == "Requested") {
      this.requestedStone(this.pageNumber, this.noOfRecords);
    } else if (event.detail.value == "Pending") {
      if (this.deptName == 'KAM Sales') {
        this.getIncomingStoneToSales('SS', this.pageNumber, this.noOfRecords);
      } else {
        this.pendingStones(this.pageNumber, this.noOfRecords);
      }
    } else if (event.detail.value == "Received") {
      this.receivedStones(this.pageNumber, this.noOfRecords);
    } else if (event.detail.value == "Boxed") {
      // if (this.deptName == 'Sales' && !!this.inventoryManagerType && this.inventoryManagerType == 'MIX') {
      this.findAvailableStockBox();
      // } else {
      //   this.getBoxedStone(this.pageNumber, this.noOfRecords);
      // }
    } else if (event.detail.value == "outRequest") {
      this.getOutGoingRequestStone(this.pageNumber, this.noOfRecords);
      console.log("this.cokumns", this.columns);
    } else if (event.detail.value == "outgoing") {
      if (this.outGoingTab == "pickedOnly") {
        this.findOutGoingPickedVouchers(this.pageNumber, this.noOfRecords);
      } else if (this.outGoingTab == "transferred") {
        this.getTransferredStone(this.pageNumber, this.noOfRecords);
      }
    } else if (event.detail.value == "delivered") {
      this.getDeliveredStone(this.pageNumber, this.noOfRecords);
    }
  }

  async changeSubSegment(event) {
    console.log('changeSubSegment2')
    this.columns = []
    this.listOfAssortedPendingStones = [];
    this.listOfAssortedReceivedStones = [];
    this.listOfOutGoingStone = [];
    this.listOfOutGoingStoneDataSource = {};
    this.listOfDeliveredStone = [];
    this.listOfPickedStone = [];
    this.listOfTransferredStone = [];
    this.listOfRequestedStone = [];
    this.closeDiv()
    this.isSelect = false;
    this.selected = []
    this.transferStoneList = []
    this.outGoingTab = event.detail.value
    await this.getUserData();
    console.log(event.detail.value);
    this.option = 'TRANSACTION WISE'

    if (event.detail.value == "pickedOnly") {
      if (this.deptName == "Sales") {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Requested for", prop: "requestFor" },
          { name: "Pkts", prop: "pendingPackets" },
          { name: "Ct.", prop: "pendingCarat" },
          { name: "Party Name", prop: "toLab" },
          { name: "To Location", prop: "toLocation" },
          { name: "Created By", prop: "createdBy" },
          { name: "Date & Time", prop: "modifiedDate" },
          { name: "Cancel Request", prop: "cancelRequest" }
        ];
      } else {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Pkts", prop: "pendingPackets" },
          { name: "Ct.", prop: "pendingCarat" },
          { name: "Created By", prop: "createdBy" },
          { name: "Date & Time", prop: "modifiedDate" }
        ];
      }
      this.findOutGoingPickedVouchers(this.pageNumber, this.noOfRecords);
    } else if (event.detail.value == "transferred") {
      if (this.deptName == "Sales") {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Requested for", prop: "requestFor" },
          { name: "Pkts", prop: "pendingPackets" },
          { name: "Ct.", prop: "pendingCarat" },
          { name: "Party Name", prop: "toLab" },
          { name: "To Location", prop: "toLocation" },
          { name: "Created By", prop: "createdBy" },
          { name: "Date & Time", prop: "modifiedDate" }
        ];
      } else {
        this.columns = [
          { name: "Voucher No", prop: "" },
          { name: "Pkts", prop: "pendingPackets" },
          { name: "Ct.", prop: "pendingCarat" },
          { name: "Created By", prop: "createdBy" },
          { name: "Date & Time", prop: "modifiedDate" }
        ];
      }
      this.getTransferredStone(this.pageNumber, this.noOfRecords);
    }
  }

  async autoBoxing() {
    if (this.deptName == "KAM Sales") {
      this.boxLevel = "user";
    } else if (
      this.deptName == "Assortment"
    ) {
      this.boxLevel = "department";
    } else if (this.deptName == "Sales") {
      this.boxLevel = "department";
    } else {
      this.boxLevel = "department";
    }
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.autoBoxing(
      this.deptName,
      this.boxLevel
    );
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.receivedStones(this.pageNumber, this.noOfRecords);
      }
    }
  }

  async findIncomingByVoucherTypeAndTransType(type, pageNumber, noOfRecords) {
    // await this.configService.showLoader();
    this.listOfAssortedPendingStonesDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        // await this.configService.dismiss();
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findIncomingByVoucherTypeAndTransType(
            this.pageNumber,
            this.noOfRecords,
            'Assortment/PD Return',
            type
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfAssortedPendingStones = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfAssortedPendingStones,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res: any;
    // res = await this.httpApiService.findIncomingByVoucherTypeAndTransType(
    //   pageNumber,
    //   noOfRecords,
    //   'Assortment/PD Return',
    //   type
    // );
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   if (res.status == 400) {
    //     await this.configService.showToast("error", data.error);
    //   } else {
    //     this.listOfAssortedPendingStones = data.data.content;
    //     console.log(this.listOfAssortedReceivedStones)
    //     this.totalPages = data.data.totalPages;
    //     this.count = data.data.totalElements;
    //   }
    // }
  }

  async findReceivedByVoucherTypeAndTransType(type, pageNumber, noOfRecords) {
    await this.configService.showLoader();
    this.listOfAssortedReceivedStonesDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        await this.configService.dismiss();
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findReceivedByVoucherTypeAndTransType(
            this.pageNumber,
            this.noOfRecords,
            'Assortment/PD Return',
            type
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfAssortedReceivedStones = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfAssortedReceivedStones,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res: any;
    // res = await this.httpApiService.findReceivedByVoucherTypeAndTransType(
    //   pageNumber,
    //   noOfRecords,
    //   'Assortment/PD Return',
    //   type
    // );
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   if (res.status == 400) {
    //     await this.configService.showToast("error", data.error);
    //   } else {
    //     this.listOfAssortedReceivedStones = data.data.content;
    //     console.log(this.listOfAssortedReceivedStones)
    //     this.totalPages = data.data.totalPages;
    //     this.count = data.data.totalElements;
    //   }
    // }
  }

  async getCountForIncomingAndOutReuqest() {
    let res: any;
    res = await this.httpApiService.getCountForIncomingAndOutReuqest();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      // if (this.option == 'TRANSACTION WISE') {
      this.outRequestTotal = data.data.countOutRequest;
      console.log(this.outRequestTotal)
      // }
      //  else if (this.tab == 'outgoing' && this.option != 'TRANSACTION WISE') {
      //   this.outRequestTotal = data.data.countOutRequestStones;
      // }
    }
  }

  async getAllDepartment() {
    let res: any;
    res = await this.httpApiService.getAllDepartment(
      this.configService.appName
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let data1 = data.data;
      if (data1.length > 0) {
        this.departmentList = data1;
      } else {
        this.departmentList = [];
      }
    }
  }

  async openModal(flag) {
    this.refreshGrid = false;
    console.log("this.voucherBookId", this.voucherBookId);
    let modalPage = await this.modalCtrl.create({
      component: ManageRequestsPage,
      backdropDismiss: false,
      componentProps: { type: flag, inventoryManagerType: this.inventoryManagerType },
      cssClass: "requestPage"
    });
    modalPage
      .onDidDismiss()
      .then((d: any) => this.handleRequestModalDismiss(d));
    return await modalPage.present();
    // return modalPage.present();
  }

  async handleRequestModalDismiss(d) {
    this.refreshGrid = true;
    if (this.outGoingTab == "pickedOnly") {
      this.findOutGoingPickedVouchers(this.pageNumber, this.noOfRecords);
    } else if (this.outGoingTab == "transferred") {
      this.getTransferredStone(this.pageNumber, this.noOfRecords);
    }
  }

  //Boxed Wise

  async findAvailableStockBox() {
    this.listOfBoxedStones = []
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.findAvailableStock()
    let data = res.json()
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfBoxedStones = data.data;
    }
  }

  //stone/cssp wise

  async getIncomingStoneToSales(transType, pageNumber, noOfRecords) {
    this.selected = []
    this.isSelect = false;
    this.transferStoneList = []
    // await this.configService.showLoader()
    this.listOfAssortedPendingStonesDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getIncomingStoneToSales(transType, this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfAssortedPendingStones = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfAssortedPendingStones,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res = await this.httpApiService.getIncomingStoneToSales(transType, pageNumber, noOfRecords);
    // let data = res.json();
    // console.log(data);
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("listOfAssortedPendingStones ???????????", data);
    //   this.listOfAssortedPendingStones = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    // }
  }

  async getReceivedStoneToSales(transType, pageNumber, noOfRecords) {
    // await this.configService.showLoader();
    this.listOfAssortedReceivedStonesDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        // await this.configService.dismiss();
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getReceivedStoneToSales(transType, this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfAssortedReceivedStones = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfAssortedReceivedStones,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res = await this.httpApiService.getReceivedStoneToSales(transType, pageNumber, noOfRecords);
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("receivedStones ???????????", data);
    //   this.listOfAssortedReceivedStones = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    // }
  }

  async findOutRequestStocksByTransType(transType, pageNumber, noOfRecords) {

    // this.count = 0;
    // this.selected = []
    // this.isSelect = false;
    // this.transferStoneList = []
    // await this.configService.showLoader();
    // let str = "";
    // this.listOfOutGoingStoneDataSource = new CustomStore({
    //   key: "id",
    //   load: async (loadOptions: any) => {
    //     console.log("loadOptions ", loadOptions);
    //     await this.configService.dismiss();
    //     if (!!loadOptions.take) {
    //       if ([10, 20, 50, 100].indexOf(loadOptions.take) == -1) {
    //         loadOptions.take = this.pageSize;
    //       } else {
    //         this.pageSize = loadOptions.take;
    //       }
    //       this.noOfRecords = 10000;
    //       //loadOptions.take = this.noOfRecords;
    //       this.pageNumber = loadOptions.skip / loadOptions.take;
    //       if (!!this.kamUserId && !!this.boxId) {
    //         str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + this.transType + "&userId=" + this.kamUserId + "&boxId=" + this.boxId + "&pageNumber=" + this.pageNumber + "&noOfRecords=" + this.noOfRecords;
    //       } else if (!!this.boxId) {
    //         str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + this.transType + "&boxId=" + this.boxId + "&pageNumber=" + this.pageNumber + "&noOfRecords=" + this.noOfRecords;
    //       } else if (!!this.kamUserId) {
    //         str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + this.transType + "&userId=" + this.kamUserId + "&pageNumber=1&noOfRecords=10000"
    //       } else {
    //         str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + this.transType + "&pageNumber=0&noOfRecords=10000"
    //       }
    //       let res: any;
    //       res = await this.httpApiService.findOutRequestStocksByTransType(str);
    //       let data = res.json();
    //       console.log(data);
    //       if (res.status == 401) {
    //         this.authService.logout();
    //       } else if (res.status == 500) {
    //         await this.configService.showToast("error", data.error);
    //       } else if (res.status == 400) {
    //         await this.configService.showToast("error", data.error);
    //       } else {
    //         this.listOfOutGoingStone = data.data.listOfStone.content;
    //         this.totalPages = data.data.listOfStone.totalPages;
    //         this.count = data.data.listOfStone.totalElements;
    //         this.outRequestTotal = data.data.listOfStone.totalElements;
    //         this.countMismatchOutgoing = data.data.listOfStone.totalElements;
    //         if (!!data.data.listOfStone.content && data.data.listOfStone.content.length > 0) {
    //           if (!!data.data.listOfUser && data.data.listOfUser.length > 0) {
    //             this.kamUserList = data.data.listOfUser;
    //           } if (!!data.data.listOfBox && data.data.listOfBox.length > 0) {
    //             this.trayList = data.data.listOfBox;
    //           }
    //           return {
    //             data: Query(data.data.listOfStone.content)
    //               .toArray(),
    //             totalCount: data.data.listOfStone.totalElements,
    //             groupCount: data.data.listOfStone.totalElements,
    //           };

    //         } else {
    //           this.listOfOutGoingStone = []
    //         }
    //       }
    //     } else {
    //       return {
    //         data: this.listOfOutGoingStone,
    //         totalCount: this.count,
    //         groupCount: this.count
    //       }
    //     }
    //   }
    // });
    this.count = 0;
    this.selected = []
    this.isSelect = false;
    this.transferStoneList = []
    // await this.configService.showLoader();
    let str = "";
    if (!!this.kamUserId && !!this.boxId) {
      str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + transType + "&userId=" + this.kamUserId + "&boxId=" + this.boxId + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords;
    } else if (!!this.boxId) {
      str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + transType + "&boxId=" + this.boxId + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords;
    } else if (!!this.kamUserId) {
      str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + transType + "&userId=" + this.kamUserId + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords;
    } else {
      str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + transType + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords
    }
    let res = await this.httpApiService.findOutRequestStocksByTransType(str);
    let data = res.json();
    // await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("listOfOutGoingStone", data);
      if (!!data.data.listOfStone.content && data.data.listOfStone.content.length > 0) {
        this.listOfOutGoingStone = data.data.listOfStone.content;
        this.totalPages = data.data.listOfStone.totalPages;
        this.count = data.data.listOfStone.totalElements;
        this.outRequestTotal = data.data.listOfStone.totalElements;
        this.countMismatchOutgoing = data.data.listOfStone.totalElements;
      } else {
        this.listOfOutGoingStone = []
      }
      if (data.data.listOfUser.length > 0) {
        this.kamUserList = data.data.listOfUser;
      } if (data.data.listOfBox.length > 0) {
        this.trayList = data.data.listOfBox;
      }
    }
  }

  async updateOutRequest() {
    if (this.option == 'TRANSACATION WISE') {
      this.getOutGoingRequestStoneDoNotRefresh(this.pageNumber, this.noOfRecords)
    } else {
      this.pageNumber = 0;
      this.noOfRecords = 500;
      this.findOutRequestStocksByTransTypeRefresh(this.transType, this.pageNumber, this.noOfRecords)
    }
  }

  async findOutRequestStocksByTransTypeRefresh(transType, pageNumber, noOfRecords) {
    this.count = 0;
    // await this.configService.showLoader();
    let str = "";
    if (!!this.kamUserId && !!this.boxId) {
      str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + transType + "&userId=" + this.kamUserId + "&boxId=" + this.boxId + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords;
    } else if (!!this.boxId) {
      str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + transType + "&boxId=" + this.boxId + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords;
    } else if (!!this.kamUserId) {
      str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + transType + "&userId=" + this.kamUserId + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords;
    } else {
      str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + transType + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords
    }
    let res = await this.httpApiService.findOutRequestStocksByTransType(str);
    let data = res.json();
    // await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("listOfOutGoingStone", data);
      if (!!data.data.listOfStone.content && data.data.listOfStone.content.length > 0) {
        this.listOfOutGoingStone = data.data.listOfStone.content;
        this.totalPages = data.data.listOfStone.totalPages;
        this.count = data.data.listOfStone.totalElements;
        this.countMismatchOutgoing = data.data.listOfStone.totalElements;
      } else {
        this.listOfOutGoingStone = []
      }
      if (data.data.listOfUser.length > 0) {
        this.kamUserList = data.data.listOfUser;
      } if (data.data.listOfBox.length > 0) {
        this.trayList = data.data.listOfBox;
      }
    }
  }

  async filterOutRequest() {
    this.count = 0
    console.log(this.kamUserId, this.boxId)
    if (!!this.kamUserId || !!this.boxId) {
      let res: any;
      let str = "";
      if (!!this.kamUserId && !!this.boxId) {
        str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + this.transType + "&userId=" + this.kamUserId + "&boxId=" + this.boxId + "&pageNumber=" + this.pageNumber + "&noOfRecords=10000";
      } else if (!!this.boxId) {
        str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + this.transType + "&boxId=" + this.boxId + "&pageNumber=" + this.pageNumber + "&noOfRecords=10000";
      } else if (!!this.kamUserId) {
        str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + this.transType + "&userId=" + this.kamUserId + "&pageNumber=" + this.pageNumber + "&noOfRecords=10000";
      } else {
        str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + this.transType + "&pageNumber=" + this.pageNumber + "&noOfRecords=10000";
      }
      res = await this.httpApiService.findOutRequestStocksByTransType(str);
      let data = res.json();
      if (data.status == 401) {
        await this.authService.logout()
      } else if (data.status == 500) {
        await this.configService.showToast('error', data.error)
      } else if (data.status == 400) {
        await this.configService.showToast('error', data.error)
      } else {
        if (!!data.data.listOfStone.content && data.data.listOfStone.content.length > 0) {
          this.listOfOutGoingStone = data.data.listOfStone.content;
          this.totalPages = data.data.listOfStone.totalPages;
          this.count = data.data.listOfStone.totalElements;
        } else {
          this.listOfOutGoingStone = []
        }
      }
    } else {
      await this.findOutRequestStocksByTransType(this.transType, 0, 10000);
    }
    // this.count = 0
    // console.log(this.kamUserId, this.boxId)
    // if (!!this.kamUserId || !!this.boxId) {
    //   let res: any;
    //   let str = "";
    //   this.listOfOutGoingStoneDataSource = new CustomStore({
    //     key: "id",
    //     load: async (loadOptions: any) => {
    //       console.log("loadOptions ", loadOptions);
    //       if (!!loadOptions.take) {
    //         if ([10, 20, 50, 100].indexOf(loadOptions.take) == -1) {
    //           loadOptions.take = this.pageSize;
    //         } else {
    //           this.pageSize = loadOptions.take;
    //         }
    //         this.noOfRecords = this.pageSize;
    //         //loadOptions.take = this.noOfRecords;
    //         this.pageNumber = loadOptions.skip / loadOptions.take;
    //         if (!!this.kamUserId && !!this.boxId) {
    //           str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + this.transType + "&userId=" + this.kamUserId + "&boxId=" + this.boxId + "&pageNumber=" + this.pageNumber + "&noOfRecords=" + this.noOfRecords;
    //         } else if (!!this.boxId) {
    //           str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + this.transType + "&boxId=" + this.boxId + "&pageNumber=" + this.pageNumber + "&noOfRecords=" + this.noOfRecords;
    //         } else if (!!this.kamUserId) {
    //           str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + this.transType + "&userId=" + this.kamUserId + "&pageNumber=" + this.pageNumber + "&noOfRecords=" + this.noOfRecords;
    //         } else {
    //           str = "master-service/api/stone/findOutRequestStocksByTransType?transType=" + this.transType + "&pageNumber=" + this.pageNumber + "&noOfRecords=" + this.noOfRecords
    //         }
    //         let res: any;
    //         res = await this.httpApiService.findOutRequestStocksByTransType(str);
    //         let data = res.json();
    //         console.log(data.data.listOfStone.content)
    //         this.listOfOutGoingStone = data.data.listOfStone.content;
    //         if (res.status == 401) {
    //           this.authService.logout();
    //         } else if (res.status == 500) {
    //           await this.configService.showToast("error", data.error);
    //         } else if (res.status == 400) {
    //           await this.configService.showToast("error", data.error);
    //         } else {
    //           if (!!data.data.listOfUser && data.data.listOfUser.length > 0) {
    //             this.kamUserList = data.data.listOfUser;
    //           } if (!!data.data.listOfBox && data.data.listOfBox.length > 0) {
    //             this.trayList = data.data.listOfBox;
    //           }
    //           console.log(data.data.listOfStone.content)
    //           this.listOfOutGoingStone = data.data.listOfStone.content;
    //           return {
    //             data: Query(data.data.listOfStone.content)
    //               .toArray(),
    //             totalCount: data.data.listOfStone.totalElements,
    //             groupCount: data.data.listOfStone.totalElements
    //           };
    //         }
    //       } else {
    //         return {
    //           data: this.listOfOutGoingStone,
    //           totalCount: this.count,
    //           groupCount: this.count
    //         }
    //       }
    //     }
    //   });

    // } else {
    //   await this.findOutRequestStocksByTransType(this.transType, this.pageNumber, this.noOfRecords);
    // }
  }

  async setOutRequestedStonePageView(transType, currentPage) {
    this.pageNumber = currentPage.offset;
    if (this.tab == 'outRequest') {
      this.findOutRequestStocksByTransType(transType, this.pageNumber, this.noOfRecords);
    }
  }

  //requested

  async requestedStone(pageNumber, noOfRecords) {
    // await this.configService.showLoader();
    this.listOfRequestedStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        // await this.configService.dismiss();
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findRequestedStone(
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfRequestedStone = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfRequestedStone,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res = await this.httpApiService.findRequestedStone(
    //   pageNumber,
    //   noOfRecords
    // );
    // console.log("**************", res)
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   console.log("******23424********")
    //   // this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   if (res.status == 400) {
    //     await this.configService.showToast("error", data.error);
    //   } else {
    //     console.log("getAllRequestedStone ???????????", data);
    //     this.listOfRequestedStone = data.data.content;
    //     this.totalPages = data.data.totalPages;
    //     this.count = data.data.totalElements;
    //   }
    // }
  }

  async setRequestedPagePending(currentPage) {
    this.pageNumber = currentPage.offset;
    this.requestedStone(currentPage.offset, this.noOfRecords);
  }

  //pending
  async pendingStones(pageNumber, noOfRecords) {
    this.listOfAssortedPendingStonesDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        // await this.configService.dismiss();
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.pendingStones(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfAssortedPendingStones = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfAssortedPendingStones,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res = await this.httpApiService.pendingStones(pageNumber, noOfRecords);
    // let data = res.json();
    // console.log(data);
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("listOfAssortedPendingStones ???????????", data);
    //   this.listOfAssortedPendingStones = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    // }
  }

  async setPagePending(currentPage) {
    this.pageNumber = currentPage.offset;
    if (this.option == 'TRANSACTION WISE') {
      this.pendingStones(currentPage.offset, this.noOfRecords);
    } else if (this.option == 'ASSORTMENT RETURN') {
      this.findIncomingByVoucherTypeAndTransType('MIX', this.pageNumber, this.noOfRecords);
    } else if (this.option == 'PD RETURN') {
      this.findIncomingByVoucherTypeAndTransType('SS', this.pageNumber, this.noOfRecords);
    }
  }

  // Received

  async receivedStones(pageNumber, noOfRecords) {
    this.listOfAssortedReceivedStonesDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        // await this.configService.dismiss();
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.receivedStones(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfAssortedReceivedStones = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfAssortedReceivedStones,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res = await this.httpApiService.receivedStones(pageNumber, noOfRecords);
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("receivedStones ???????????", data);
    //   this.listOfAssortedReceivedStones = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    // }
  }

  async setPageReceived(currentPage) {
    this.pageNumber = currentPage.offset;
    this.receivedStones(currentPage.offset, this.noOfRecords);

  }

  async setPendingPageForView(transType, currentPage) {
    this.pageNumber = currentPage.offset;
    if (this.tab == 'Pending') {
      this.getIncomingStoneToSales(transType, this.pageNumber, this.noOfRecords);
    } else if (this.tab == 'Received') {
      this.getReceivedStoneToSales(transType, this.pageNumber, this.noOfRecords);
    }
  }

  async setReceivedPageForView(transType, currentPage) {
    this.pageNumber = currentPage.offset;
    this.findReceivedByVoucherTypeAndTransType(transType, this.pageNumber, this.noOfRecords);
  }

  async setPendingAssortPageForView(transType, currentPage) {
    this.pageNumber = currentPage.offset;
    this.findIncomingByVoucherTypeAndTransType(transType, this.pageNumber, this.noOfRecords);
  }


  //getBoxedStone

  async getBoxedStone(pageNumber, noOfRecords) {
    this.listOfBoxedStonesDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {

        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getBoxedStone(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfBoxedStones = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfBoxedStones,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res: any;
    // res = await this.httpApiService.getBoxedStone(pageNumber, noOfRecords);
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("listOfBoxedStone", data);
    //   this.listOfBoxedStones = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    // }
  }

  async setBoxedStonePage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getBoxedStone(currentPage.offset, this.noOfRecords);
  }

  //getOutgoingStone

  async getOutGoingRequestStone(pageNumber, noOfRecords) {
    this.listOfOutGoingStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        // await this.configService.dismiss();
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findOutRequest(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfOutGoingStone = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfOutGoingStone,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res: any;
    // res = await this.httpApiService.findOutRequest(pageNumber, noOfRecords);
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("listOfOutGoingStone", data);
    //   this.listOfOutGoingStone = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.outRequestTotal = data.data.totalElements;
    //   this.countMismatchOutgoing = data.data.totalElements;
    // }
  }

  async getOutGoingRequestStoneDoNotRefresh(pageNumber, noOfRecords) {
    this.listOfOutGoingStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        // await this.configService.dismiss();
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findOutRequest(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfOutGoingStone = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfOutGoingStone,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res: any;
    // res = await this.httpApiService.findOutRequest(pageNumber, noOfRecords);
    // let data = res.json();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("listOfOutGoingStone", data);
    //   this.listOfOutGoingStone = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.outRequestTotal = data.data.totalElements;
    //   this.countMismatchOutgoing = data.data.totalElements;
    // }
  }

  async setOutGoingStonePage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getOutGoingRequestStone(currentPage.offset, this.noOfRecords);
  }

  //get transferred stones

  async getTransferredStone(pageNumber, noOfRecords) {
    this.listOfTransferredStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findOutGoing(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfTransferredStone = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfTransferredStone,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res: any;
    // res = await this.httpApiService.findOutGoing(pageNumber, noOfRecords);
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("listOfTransferredStone", data);
    //   this.listOfTransferredStone = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    // }
  }

  async setPickedStonePage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.findOutGoingPickedVouchers(currentPage.offset, this.noOfRecords);
  }

  async setTransferredStonePage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getTransferredStone(currentPage.offset, this.noOfRecords);
  }


  async setRequestedStonePage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getOutGoingRequestStone(currentPage.offset, this.noOfRecords);
  }

  //get Picked Stone For Transfer

  async findOutGoingPickedVouchers(pageNumber, noOfRecords) {
    this.listOfPickedStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        // await this.configService.dismiss();
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findOutGoingPickedVouchers(this.pageNumber, this.noOfRecords);
          let data = res.json();

          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfPickedStone = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfPickedStone,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res: any;
    // res = await this.httpApiService.findOutGoingPickedVouchers(pageNumber, noOfRecords);
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("listOfPickedStone", data);
    //   this.listOfPickedStone = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    // }
  }



  //getDeliveredStone

  async getDeliveredStone(pageNumber, noOfRecords) {
    this.listOfDeliveredStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          console.log(this.pageNumber);
          let res: any;
          res = await this.httpApiService.findDelivered(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfDeliveredStone = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfDeliveredStone,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res: any;
    // res = await this.httpApiService.findDelivered(pageNumber, noOfRecords);
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   console.log("getDeliveredStone", data);
    //   this.listOfDeliveredStone = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    // }
  }

  async setDeliveredStonePage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getDeliveredStone(currentPage.offset, this.noOfRecords);
  }

  async openStoneModal(flag, item) {
    this.refreshGrid = false;
    if ((item.columnName == 'Cancel Request') && (this.tab == 'outgoing' && this.outGoingTab == 'pickedOnly')) {
      this.deleteConfirm(item.row)
    } else if ((item.columnName == 'Cancel Request') && this.tab == 'outRequest') {
      this.deleteConfirm(item.row)
    } else {
      if (this.option == 'TRANSACTION WISE') {
        this.keyFun = false;
        this.status = item.row.id;
        if (flag == 5) {
          let modalPage = await this.modalCtrl.create({
            component: ManageRequestsPage,
            backdropDismiss: false,
            componentProps: { voucherRequestData: item.row, inventoryManagerType: this.inventoryManagerType },
            cssClass: "requestPage"
          });
          modalPage
            .onDidDismiss()
            .then((d: any) => this.handleRequestModalDismiss(d));
          return await modalPage.present();
        } else {
          const modal = await this.modalCtrl.create({
            component: ManageInventoryManagerPage,
            backdropDismiss: false,
            cssClass: "requestPage",
            componentProps: {
              data: item.row,
              type: flag,
              inventoryManagerType: this.inventoryManagerType,
              deptName: this.deptName
            }
          });
          modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
          return await modal.present();
        }
      }
    }
  }

  async openRequestModal(flag, item) {
    console.log(flag, item)
    console.log((item.columnName == 'Cancel Request') && ((this.tab == 'Requested' && (item.row && (item.row.status == 'Picked' || item.row.status == 'Requested')))));
    console.log((this.tab == 'Requested' && (item.row && !!item.row.isTransferApproved && item.row.isTransferApproved && item.row.requestFor != 'Sold')));
    console.log((this.tab == 'Requested' && (item.row && !!item.row.requestPlatform && item.row.requestPlatform == 'Website')));

    console.log(this.option);

    this.refreshGrid = false;
    if ((item.columnName == 'Cancel Request') && ((this.tab == 'Requested' && (item.row && (item.row.status == 'Picked' || item.row.status == 'Requested'))))) {
      this.deleteConfirm(item.row)
    } else if ((this.tab == 'Requested' && (item.row && !!item.row.isTransferApproved && item.row.isTransferApproved && item.row.requestFor != 'Sold'))) {
      if (this.option == 'TRANSACTION WISE') {
        this.keyFun = false;
        this.status = item.row.id;
        let modalPage = await this.modalCtrl.create({
          component: ManageExternalInventoryStonesPage,
          backdropDismiss: false,
          componentProps: { data: item.row, actionName: item.row.requestFor, type: flag, fromPage: 'requestPage' },
          cssClass: "requestPage"
        });
        modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return modalPage.present();
      }
    } else if ((this.tab == 'Requested' && (item.row && !!item.row.requestPlatform && item.row.requestPlatform == 'Website'))) {
      this.status = item.row.id;
      let modalPage = await this.modalCtrl.create({
        component: ManageRequestApprovalPage,
        backdropDismiss: false,
        componentProps: {
          data: item.row, actionName: item.row.requestFor, type: flag,
          requestPlatform: 'Website'
        },
        cssClass: "requestPage"
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return modalPage.present();
    }
    else if ((this.tab == 'Requested' && (item.row && !!item.row.requestPlatform && item.row.requestPlatform == 'SVC'))) {
      this.status = item.row.id;
      let modalPage = await this.modalCtrl.create({
        component: ManageRequestApprovalPage,
        backdropDismiss: false,
        componentProps: {
          data: item.row, actionName: item.row.requestFor, type: flag,
          requestPlatform: 'SVC',
        },
        cssClass: "requestPage"
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return modalPage.present();
    } else {
      if (this.option == 'TRANSACTION WISE') {
        this.keyFun = false;
        this.status = item.row.id;
        const modal = await this.modalCtrl.create({
          component: ManageInventoryManagerPage,
          backdropDismiss: false,
          cssClass: "requestPage",
          componentProps: {
            data: item.row,
            type: flag,
            inventoryManagerType: this.inventoryManagerType
          }
        });
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
    }
  }


  async deleteFromRequest(item) {
    this.deleteConfirm(item)
  }



  async openScanModel(type) {
    this.refreshGrid = false;
    if (!this.openView) {
      this.keyFun = false;
      const modal = await this.modalCtrl.create({
        component: ScanRFIDPage,
        backdropDismiss: false,
        componentProps: {
          type: type,
          // skipAssortment: this.deptName == 'Assortment' ? true : false
        },
        cssClass: "largePage"
      });
      modal.onDidDismiss().then((d: any) => this.handleScanModalDismiss(d));
      return await modal.present();
    }
  }

  async handleScanModalDismiss(d) {
    this.refreshGrid = true;
    this.keyFun = true;
    this.viewDataByOption(this.optionList)
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    this.status = null;
    this.keyFun = true;
    console.log("d", d);
    if (d.data == 0) {
      this.requestedStone(this.pageNumber, this.noOfRecords);
    } else if (d.data == 1) {
      this.pendingStones(this.pageNumber, this.noOfRecords);
    } else if (d.data == 2) {
      this.receivedStones(this.pageNumber, this.noOfRecords);
    } else if (d.data == 3) {
      this.getBoxedStone(this.pageNumber, this.noOfRecords);
    } else if (d.data == 4) {
      this.getCountForIncomingAndOutReuqest()
      this.getOutGoingRequestStone(this.pageNumber, this.noOfRecords);
    } else if (d.data == 5) {
      this.findOutGoingPickedVouchers(this.pageNumber, this.noOfRecords);
    } else if (d.data == 6) {
      this.getDeliveredStone(this.pageNumber, this.noOfRecords);
    } else if (d.data == 7) {
      this.getTransferredStone(this.pageNumber, this.noOfRecords);
    }
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  // Assortment Result Open Modal

  async showResultOfAssortPD(ev) {
    this.refreshGrid = false;
    console.log(ev)
    this.keyFun = false;
    const modal = await this.modalCtrl.create({
      component: PdAssortDetailsPage,
      backdropDismiss: false,
      cssClass: 'largePage',
      componentProps: {
        rowData: ev.row.data
      },
    });
    modal.onDidDismiss().then((d: any) => this.handleModalResultDismiss(d));
    return await modal.present();
  }

  handleModalResultDismiss(d) {
    this.refreshGrid = true;
    this.keyFun = true;
  }

  // END

  //selection dev-extreme grid

  OnSelectRows(ev) {
    console.log(ev);
    this.selected = ev.selectedRowsData;
    this.transferStoneList = []
    this.selectedStoneControlNoList = [];
    this.selected.forEach(el => {
      this.transferStoneList.push(el.stoneId)
      if (el.rfid) {
        this.selectedStoneControlNoList.push(parseInt(el.rfid));
      }
    });
  }

  //LED ON 

  async findByLED() {
    if (this.selectedStoneControlNoList.length > 0) {
      if (!!this.boardBaseUrl) {
        await this.configService.showLoader();
        let res: any;
        res = await this.httpApiService.scanWithLightOn(this.boardBaseUrl, this.selectedStoneControlNoList);
        console.log(res);
        // let data = res.json();
        console.log("findBy LED", res);
        this.configService.dismiss();
      }
      else {
        await this.configService.showToast("error", "Scanner Board Url not found");
      }
    } else {
      await this.configService.showToast("error", "Please add stone!");
    }
  }

  //END

  //LED OFF

  async LedOff() {
    if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.scanLedOff(this.boardBaseUrl, this.selectedStoneControlNoList);
      console.log(res);
      await this.loadingController.dismiss()
    } else {
      await this.configService.showToast("error", "Scanner Board Url not found");
    }
  }

  //END

  //PICK UP STONES

  async pickStones() {
    let validate = await this.validateControlsForPickStone();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        setOfValues: this.transferStoneList,
        companyId: this.companyId
      };
      console.log(jsonObj);
      res = await this.httpApiService.pickStoneFromBox(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.transferStoneList = []
        this.selectedStoneControlNoList = []
        this.ledOnStonesIndex = []
        this.getCountForIncomingAndOutReuqest();
        this.findOutRequestStocksByTransType(this.transType, 0, 10000);
      }
    }
  }

  async validateControlsForPickStone() {
    if (this.transferStoneList.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  async modalAction(data) {
    console.log(data)
  }
  //END

  //SCAN FOR AUTO ASSIGN 

  async scanHere() {
    let res: any;
    if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
      await this.configService.showLoader();
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      if (data.type == "error") {
        await this.configService.dismiss();
        await this.configService.showToast("error", "Scanner Not Found");
      } else {
        if (data.result.length > 0) {
          data.result = data.result.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          let obj = {
            setOfValues: data.result,
            isAssignment: true,
          };
          let resp: any;
          resp = await this.httpApiService.scanBox(obj);
          let dataValue = resp.json();
          await this.configService.dismiss();
          if (resp.status == 401) {
            this.authService.logout();
          } else if (resp.status == 500) {
            await this.configService.showToast("error", dataValue.error);
          } else if (resp.status == 400) {
            await this.configService.showToast("error", dataValue.error);
          } else if (!!dataValue.data.listOfErrors && dataValue.data.listOfErrors.length > 0) {
            await this.configService.showToast("error", dataValue.data.message);
            // let excelData = [];
            // dataValue.data.listOfErrors.forEach(key => {
            //   excelData.push({ Name: key.stoneId, message: key.error })
            // });
            await this.exportAsExcelService.exportAsExcelFile(dataValue.data.listOfErrors, 'Error_Stones');
          } else {
            await this.configService.showToast("success", dataValue.message);
            this.transType = 'SS';
            this.findOutRequestStocksByTransType(this.transType, this.pageNumber, this.noOfRecords);
          }
        } else {
          await this.configService.dismiss();
          await this.configService.showToast("error", "No data found");
        }
      }
    } else {
      await this.configService.showToast("error", "Scanner Board Url not found");
    }
  }

  //END

  // Received Incoming stones by selection

  async receiveIncomingStones() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";

    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      setOfValues: this.transferStoneList,
      voucherDate: moment().format("YYYY-MM-DD"),
      companyId: this.companyId
    };
    res = await this.httpApiService.receivePendingStoneConfirmation(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.type);
    } else if (res.status == 400) {
      if (data.data) {
        this.errorMsgList = data.data;
        for (let i = 0; i < data.data.length; i++) {
          this.errorMsgList[i].ids = data.data[i].StoneId.join(", ");
        }
        console.log(this.errorMsgList)
        this.errorMsg = data.message
        this.msgStatus = true;
      } else {
        await this.configService.showToast("error", data.error);
      }
    } else {
      let stoneCount = data.data.length > 0 ? data.data[0].StoneId.length : ''
      await this.configService.showToast("success", `${stoneCount} stones received successfully.`);
      this.transferStoneList = []
      this.selected = []
      this.isSelect = false;
      if (this.option == 'ASSORTMENT RETURN') {
        this.findIncomingByVoucherTypeAndTransType('MIX', this.pageNumber, this.noOfRecords);
      } else if (this.option == 'PD RETURN') {
        this.findIncomingByVoucherTypeAndTransType('SS', this.pageNumber, this.noOfRecords);
      } else if (this.option == 'STONE WISE') {
        this.getIncomingStoneToSales('SS', this.pageNumber, this.noOfRecords);
      } else if (this.option == 'CSSP WISE') {
        this.getIncomingStoneToSales('MIX', this.pageNumber, this.noOfRecords);
      }
    }
  }
  //END

  async closeDiv() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
  }

  //boxed View

  async changeViewType(type, item) {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
    this.selected = []
    this.isSelect = false;
    console.log(item)
    this.flagBox = type;
    if (item != 0) {
      this.boxId = item.id;
      this.boxName = item.label;
      await this.findStonesByBoxID(this.boxId)

      if (item.transTypeName == 'MIX') {
        this.columns = [
          { name: "CSSP", prop: "stoneId" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Clarity", prop: "clarity" },
        ];
      } else {
        this.columns = [
          { name: "StoneId", prop: "stoneId" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Clarity", prop: "clarity" },
        ];
      }
    }
  }

  async findStonesByBoxID(boxId) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findStonesByboxIdWithAvailableStock(
      boxId
    );
    console.log("box stone", res, res.json());

    let data = res.json();
    console.log("data", data);
    this.count = data.data.length;
    console.log("count", this.count);
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfStones = data.data;
    }
  }

  //END


  //uploadBulkToSingle

  async openBulkToSingle(flag) {
    this.refreshGrid = false;
    let modal = await this.modalCtrl.create({
      component: UploadBulkToSinglePage,
      componentProps: { flag: flag, data: this.data },
      backdropDismiss: false,
      cssClass: 'requestPage'
    })
    modal
      .onDidDismiss()
      .then((d: any) => this.handleBulkToSingleModal(d));
    return await modal.present();
  }

  async handleBulkToSingleModal(d) {
    this.refreshGrid = true;
    if (d.data != 'doNoRefresh') {
      this.pendingStones(this.pageNumber, this.noOfRecords);
    }
  }

  //Delete Request

  async deleteConfirm(item) {
    console.log(item)
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Request ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteRequest(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteRequest(item) {
    console.log(item)
    let res: any;
    res = await this.httpApiService.deleteRequest(item.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      if (this.tab == 'Requested') {
        await this.requestedStone(this.pageNumber, this.noOfRecords);
      } else if (this.tab == 'outRequest') {
        await this.getOutGoingRequestStone(this.pageNumber, this.noOfRecords);
      } else if (this.outGoingTab == 'pickedOnly') {
        await this.findOutGoingPickedVouchers(this.pageNumber, this.noOfRecords);
      }
    }
  }

  //scanToSelect

  async scanToSelect() {
    this.boxDetail = []
    if (!!this.boardBaseUrl) {
      this.currentScanCount = 0
      await this.httpApiService.scanLedOff(this.boardBaseUrl, this.ledOffList);
      let rfIdList = [];
      this.scannedRFid = []
      this.scanCount = 0
      let res;
      await this.configService.showLoader();
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      if (data.type == "error") {
        this.configService.dismiss();
        await this.configService.showToast("error", data.type);
      } else {
        if (data.result.length > 0) {
          data.result = data.result.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          this.count = data.result.length;
          this.scannedRFid = data.result;
          let jsonObj;
          jsonObj = {
            "paramName": "rfid",
            setOfValues: data.result,
            companyId: this.companyId
          }
          let res2 = await this.httpApiService.getStoneIdByRfid(jsonObj);
          let resData = res2.json();
          this.boxDetail = resData.data.boxRfIdsList;
          this.scanCount = this.scannedRFid.length
          if (this.boxDetail.length > 0) {
            this.scanCount = this.scanCount - this.boxDetail.length;
          }

          for (let i = 0; i < this.listOfOutGoingStone.length; i++) {
            let rfId = this.listOfOutGoingStone[i].rfid;
            if (this.scannedRFid.indexOf(rfId) > -1) {
              let ledOnCount = this.ledOnStonesIndex.filter(el => el.id == this.listOfOutGoingStone[i].id).length
              // let ledOnCount = this.ledOnStonesIndex.filter(el => el == i).length
              if (ledOnCount == 0) {
                this.currentScanCount = this.currentScanCount + 1;
                rfIdList.push(rfId)
                this.ledOnStonesIndex.push(this.listOfOutGoingStone[i]);
              }
              let selectedCount = this.transferStoneList.filter(el => el == this.listOfOutGoingStone[i].id).length
              if (selectedCount == 0) {
                this.ledOffList.push(this.listOfOutGoingStone[i].rfid)
              }
            }
          }
          if (this.boxDetail.length == 1) {
            data.result = data.result.filter(rf =>
              this.boxDetail.filter(bd => bd.rfid == rf).length == 0
            )
            let DTOInventory = {
              boxId: this.boxDetail[0].id,
              setOfRfids: data.result
            }
            await this.httpApiService.updateInventory(DTOInventory);
          }
          this.ledOn = true;
          setTimeout(() => {
            this.ledOn = false;
          }, 1000)
          this.configService.dismiss();
          if (rfIdList.length > 0) {
            await this.httpApiService.scanWithLightOn(this.boardBaseUrl, rfIdList);
          } else {
            this.configService.dismiss();
            await this.configService.showToast("error", "RFID not found!");
          }
        } else {
          await this.configService.showToast("error", `Stones not found on Scanner Board!`);
          this.configService.dismiss();
        }
      }
    } else {
      this.configService.dismiss();
      await this.configService.showToast("error", "Scanner Board Url not found");
    }
  }

}
