import { Injectable, NgZone } from "@angular/core";
import { Storage } from "@ionic/storage";
import { ToastController, LoadingController } from "@ionic/angular";
import { BehaviorSubject, Observable, Subject } from "rxjs";
const TOKEN_KEY = "auth-token";
import { environment } from "../../../environments/environment";
// import { environment } from '../../../environments/environment.prod';

declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;

    webkitRTCPeerConnection: RTCPeerConnection;
  }
}
@Injectable({
  providedIn: "root",
})
export class ConfigService {
  public mode = environment.mode;
  public appName = environment.appName;
  // public fromLocation = "Surat";
  public fromLocation = environment.fromLocation;

  public actionSubject: Subject<String> = new BehaviorSubject<String>("");

  public actionSubject$: Observable<String> = this.actionSubject.asObservable();

  public formFields = [
    { id: 1, name: "Party Name", keyName: "partyMasterId", isRequired: true },
    {
      id: 2,
      name: "Person Name",
      keyName: "customerContactId",
      isRequired: true,
    },
    { id: 24, name: "Address", keyName: "address", isRequired: true },
    { id: 3, name: "Location", keyName: "location", isRequired: true },
    { id: 4, name: "Shipment Date", keyName: "shippingDate", isRequired: true },
    { id: 5, name: "Duration", keyName: "duration", isRequired: false },
    { id: 6, name: "Business Type", keyName: "businessType", isRequired: true },
    { id: 7, name: "Terms", keyName: "termName", isRequired: true },
    { id: 8, name: "Less 1", keyName: "disc1", isRequired: false },
    { id: 9, name: "Less 2", keyName: "disc2", isRequired: false },
    { id: 10, name: "Currency", keyName: "currencyMasterId", isRequired: true },
    {
      id: 11,
      name: "Exchange Rate",
      keyName: "currencyRate",
      isRequired: true,
    },
    {
      id: 12,
      name: "Exchange Rate Spread",
      keyName: "spreadRate",
      isRequired: true,
    },
    {
      id: 13,
      name: "Broker / Comission Agent",
      keyName: "brokerId",
      isRequired: true,
    },
    // { id: 14, name: 'Broker percent', keyName: 'brokerPercentage', isRequired: false },
    {
      id: 15,
      name: "Shipping Cost To Charge Customer",
      keyName: "shippingCost",
      isRequired: true,
    },
    {
      id: 16,
      name: "Shipping Method",
      keyName: "modeOfTransport",
      isRequired: true,
    },
    { id: 17, name: "Remark", keyName: "remarks", isRequired: false },
    { id: 18, name: "Tag", keyName: "setOfTags", isRequired: false },
    {
      id: 19,
      name: "Date & Time of Visit",
      keyName: "visitTime",
      isRequired: true,
    },
    {
      id: 20,
      name: "Customer Reference Name",
      keyName: "customerReferenceId",
      isRequired: true,
    },
    {
      id: 21,
      name: "To Location",
      keyName: "selectedCompany",
      isRequired: true,
    },
    { id: 22, name: "To User", keyName: "userId", isRequired: true },
    {
      id: 23,
      name: "Pick up / Delivery Instruction",
      keyName: "deliveryInstruction",
      isRequired: true,
    },
    {
      id: 25,
      name: "Stock controller",
      keyName: "stockController",
      isRequired: false,
    },
    { id: 26, name: "Purpose", keyName: "purpose", isRequired: false },
    {
      id: 27,
      name: "Shipment Type",
      keyName: "shipmentType",
      isRequired: false,
    },
  ];

  public partyTypeList = [
    {
      id: 1,
      name: "Client",
      actionList: [
        {
          id: 1,
          name: "Hold",
          actionName: "Hold Customer",
          formData: this.formFields,
          allowPermission: [1, 2, 17, 18],
        },
        {
          id: 2,
          name: "Requisition",
          actionName: "Requisition",
          formData: this.formFields,
          allowPermission: [1, 2, 17, 19, 25],
        },
        {
          id: 3,
          name: "Memo",
          actionName: "Memo Client",
          formData: this.formFields,
          allowPermission: [
            1, 2, 24, 3, 17, 4, 5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 25,
          ],
        },
        {
          id: 4,
          name: "Consignment In Stock",
          actionName: "Consignment In Stock",
          formData: this.formFields,
          allowPermission: [
            1, 2, 24, 3, 17, 4, 5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 25,
          ],
        },
        {
          id: 5,
          name: "Consignment Confirm",
          actionName: "Consignment Confirm",
          formData: this.formFields,
          allowPermission: [
            1, 2, 24, 3, 17, 4, 6, 18, 8, 9, 10, 11, 12, 13, 14, 15, 16, 25,
          ],
        },
        {
          id: 6,
          name: "Customer Confirm",
          actionName: "Customer Confirm",
          formData: this.formFields,
          allowPermission: [
            1, 2, 24, 3, 17, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 25,
          ],
        },
        {
          id: 7,
          name: "Sold",
          actionName: "Sold",
          formData: this.formFields,
          allowPermission: [
            1, 2, 24, 3, 17, 4, 6, 7, 8, 9, 18, 10, 11, 12, 13, 14, 15, 16, 25,
          ],
        },
        {
          id: 8,
          name: "Ship",
          actionName: "Ship",
          formData: this.formFields,
          allowPermission: [1, 2, 24, 3, 18, 26, 27, 17],
        },
        {
          id: 9,
          name: "Block For Sale",
          actionName: "Block For Sale",
          formData: this.formFields,
          allowPermission: [1, 2, 17, 18],
        },
      ],
    },
    {
      id: 2,
      name: "House",
      actionList: [
        {
          id: 1,
          name: "Recut/Inscription Removal",
          actionName: "Recut",
          formData: this.formFields,
          allowPermission: [21, 22, 18, 17, 16, 4, 25],
        },
        {
          id: 2,
          name: "Observation",
          actionName: "Observation",
          formData: this.formFields,
          allowPermission: [21, 22, 18, 17, 16, 4, 25],
        },
        {
          id: 3,
          name: "Hold",
          actionName: "Hold Customer",
          formData: this.formFields,
          allowPermission: [1, 2, 17, 20, 18],
        },
        {
          id: 4,
          name: "Memo",
          actionName: "Memo Client",
          formData: this.formFields,
          allowPermission: [1, 2, 24, 3, 17, 5, 4, 16, 8, 9, 25],
        },
        {
          id: 5,
          name: "Consignment In Stock",
          actionName: "Consignment In Stock",
          formData: this.formFields,
          allowPermission: [1, 2, 24, 3, 18, 17, 4, 16, 8, 9, 25],
        },
        {
          id: 6,
          name: "Consignment Confirm",
          actionName: "Consignment Confirm",
          formData: this.formFields,
          allowPermission: [1, 2, 24, 3, 18, 17, 4, 6, 16, 8, 9, 20, 25],
        },
        {
          id: 7,
          name: "Sold",
          actionName: "Sold",
          formData: this.formFields,
          allowPermission: [1, 2, 24, 3, 17, 8, 9, 4, 16, 25],
        },
        {
          id: 8,
          name: "Block For Sale",
          actionName: "Block For Sale",
          formData: this.formFields,
          allowPermission: [1, 2, 17, 20, 18],
        },
      ],
    },
    {
      id: 3,
      name: "Job",
      actionList: [
        // { id: 1, name: 'Recut/Inscription Removal', actionName: 'Recut', formData: this.formFields, allowPermission: [3, 1, 2, 18, 17, 16, 4] },
        {
          id: 2,
          name: "Lab",
          actionName: "Certification",
          formData: this.formFields,
          allowPermission: [1, 2, 24, 3, 17, 16, 4],
        },
        {
          id: 3,
          name: "Memo Service",
          actionName: "Memo Service",
          formData: this.formFields,
          allowPermission: [1, 2, 24, 3, 17, 4],
        },
      ],
    },
    {
      id: 4,
      name: "Internal",
      actionList: [
        {
          id: 1,
          name: "Self Observation",
          actionName: "Self Observation",
          formData: this.formFields,
          allowPermission: [17],
        },
        { id: 2, name: "Return", actionName: "Return" },
        { id: 2, name: "Convert To Mix", actionName: "Convert To Mix" },
        // { id: 3, name: 'Hold', actionName: 'Hold Customer', formData: this.formFields, allowPermission: [17] },
        { id: 4, name: "Unhold Customer", actionName: "Unhold Customer" },
        {
          id: 5,
          name: "Request To KAM",
          actionName: "Request To KAM",
          formData: this.formFields,
          allowPermission: [1, 2, 17],
        },
        {
          id: 6,
          name: "Tags",
          actionName: "Tags",
          formData: this.formFields,
          allowPermission: [18],
        },
        { id: 7, name: "Unblock For Sale", actionName: "Unblock For Sale" },
      ],
    },
  ];
  // isLoading = false;
  loading: HTMLIonLoadingElement;
  private ipRegex = new RegExp(
    /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
  );

  constructor(
    private storage: Storage,
    public toastCtrl: ToastController,
    public loadingController: LoadingController,
    public zone: NgZone
  ) {
    // if (this.mode == 'Production') {
    //   console.log = function () { }
    // }
  }

  async getBaseUrl() {
    if (this.mode == "Production") {
      return "https://lattice-app.com/";
    } else if (this.mode == "Dev") {
      return "https://lattice-dev.atishae.com/";
    } else {
      return "http://192.168.7.119:9998/";
    }
  }

  async getNodeServerUrl() {
    if (this.mode == "Production") {
      return "https://apin.lattice-app.com/";
    } else if (this.mode == "Dev") {
      return "https://apin.lattice-dev.atishae.com/";
    } else {
      return "http://localhost:3000/";
    }
  }
  async getTalkBriteUrl() {
    if (this.mode == "Production") {
      return "https://web-api.lattice-app.com/api/";
    } else if (this.mode == "Dev") {
      return "https://api-dev.kg-website.atishae.com/api/";
    } else {
      return "http://localhost:3000/";
    }
  }

  async getTrybeServerUrl() {
    if (this.mode == "Production") {
      return "https://api.trybe-dev.atishae.com/";
    } else {
      if (this.mode == "Dev") {
        return "https://api.trybe-dev.atishae.com/";
      } else {
        return "http://localhost:3000/";
      }
    }
  }

  async getFromLocation() {
    return await environment.fromLocation;
  }

  async getCartActionList() {
    return await {
      partyTypeList: this.partyTypeList,
      formFields: this.formFields,
    };
  }

  async getAPIHeader() {
    var obj = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: await this.storage.get(TOKEN_KEY),
      //"Access-Control-Allow-Origin": "*"
    };

    return await obj;
  }

  async fileAPIHeader() {
    var obj = {
      Authorization: await this.storage.get(TOKEN_KEY),
    };
    return await obj;
  }
  async isValidEmail(email) {
    const re =
      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(email);

    if (re) {
      return true;
    } else {
      return false;
    }
  }

  async getAppName() {
    return await this.appName;
  }

  async showToast(status, message) {
    if (status == "success") {
      let toast = await this.toastCtrl.create({
        message: message,
        duration: 3000,
        position: "top",
        color: "success",
      });
      await toast.present();
    } else {
      let toast = await this.toastCtrl.create({
        message: message,
        duration: 3000,
        position: "top",
        color: "danger",
      });
      await toast.present();
    }
  }

  async showLoader() {
    // if(!this.isLoading) {
    //   this.isLoading = true;

    this.loading = await this.loadingController.create({
      message: "Please Wait..",
    });
    await this.loading.present();
    // }
  }

  async dismiss() {
    // if(this.isLoading) {
    //   this.isLoading = false;
    await this.loading.dismiss();
    // }
  }

  async groupType() {
    return [
      { id: 1, name: "Client" },
      { id: 2, name: "House" },
      { id: 3, name: "Job" },
      { id: 4, name: "Internal" },
    ];
  }
  async businessType() {
    return [
      { id: 1, name: "Single Stone" },
      { id: 2, name: "List Business" },
      { id: 3, name: "Program" },
      { id: 4, name: "Online Feed" },
    ];
  }

  async print() {
    let css = "@media print { @page { size: A4; } }";

    if (document.getElementsByTagName("app-delivery-challan").length != 0) {
      css = "@media print { @page { size: A5; } }";
    }
    if (!!document.getElementById("stylePrint")) {
      document.getElementById("stylePrint").innerHTML = css;
    } else {
      let head = document.head || document.getElementsByTagName("head")[0],
        style: any = document.createElement("style");

      head.appendChild(style);

      style.type = "text/css";
      style.id = "stylePrint";
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }
    console.log("============>", css);
    window.print();
  }

  async manageNotification(data) {
    console.log(data.data, Notification);
    var option = {
      requireInteraction: true,
      tag: "soManyNotification",
      // icon: '/home/keval/keval/Lattice/lattice/apps/desktop/lattice/src/assets/logo/symbol.jpg'
    };
    if (Notification && Notification.permission === "granted") {
      console.log(Notification, "granted");
      var i = 0;
      var interval = window.setInterval(function () {
        // Thanks to the tag, we should only see the "Hi! 9" notification
        var n = new Notification(data.data, option);
        if (i++ == 9) {
          window.clearInterval(interval);
        }
      }, 200);
    } else if (Notification && Notification.permission !== "denied") {
      console.log(Notification, "denied");
      Notification.requestPermission(function (status) {
        if (status === "granted") {
          var i = 0;
          var interval = window.setInterval(function () {
            var n = new Notification(data.data, option);
            if (i++ == 9) {
              window.clearInterval(interval);
            }
          }, 200);
        } else {
        }
      });
    } else {
      console.log(Notification, "else");
      // alert("Hi!");
    }
  }

  determineLocalIp() {
    window.RTCPeerConnection = this.getRTCPeerConnection();

    const pc = new RTCPeerConnection({
      iceServers: [],
    });
    pc.createDataChannel("");
    pc.createOffer().then(pc.setLocalDescription.bind(pc));

    pc.onicecandidate = (ice) => {
      this.zone.run(() => {
        if (!ice || !ice.candidate || !ice.candidate.candidate) {
          return;
        }
        let localIp = this.ipRegex.exec(ice.candidate.candidate)[1];
        this.storage.set("scanBoardUrlLocal", `http://${localIp}:9000/api/`);
        pc.onicecandidate = () => {};
        pc.close();
      });
    };
  }

  private getRTCPeerConnection() {
    return window.RTCPeerConnection;
  }
}
