import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service'

@Component({
  selector: 'app-consignment',
  templateUrl: './consignment.component.html',
  styleUrls: ['./consignment.component.scss']
})
export class ConsignmentComponent implements OnInit {
  @Input() data:any

  public consignmentTab = 'Issue';
  public actionName:any;
  public actionId:any;
  
  constructor(
    public authService:AuthenticationService
  ) { }

  async ngOnInit() {
    let userData = await this.authService.userData();
    console.log("userData", userData, this.data);
    this.actionName = await this.data.desc
    this.actionId = await this.data.actionId
  }
}
