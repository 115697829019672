export default {
  reportNo: 'R-7',
  url: "master-service/api/report/departmentStockControlData",
  method: "GET",
  size: 3,
  fields: [
    {
      title: ' priceListNameId ',
      input: 'hidden',
      key: 'priceListNameMasterId',
      placeholder: 'Select Price',
      property: 'priceListNameMasterId',
      keyName: 'name',
      name: 'name',
      default: 6,
      size: 3
    },
    {
      title: ' Department Name ',
      input: 'hidden',
      key: 'departmentId',
      placeholder: 'Select Department Type',
      property: 'departmentId',
      keyName: 'id',
      name: 'name',
      required: true,
      singleSelection: true,
      default: 4,
      size: 3
    },
    {
      title: ' Voucher Type ',
      input: 'select',
      key: 'transactionType',
      placeholder: 'Select Transaction Type',
      property: 'transactionType',
      keyName: 'id',
      name: 'name',
      singleSelection: true,
      itemList: [{
        id: 'issue',
        name: 'Issue'
      }, {
        id: 'outstanding',
        name: 'Outstanding'
      }, {
        id: 'return',
        name: 'Return'
      }],
      default: [],
      size: 3
    },
    {
      title: ' Trans Type ',
      input: 'select',
      key: 'transType',
      placeholder: 'Select Trans Type',
      property: 'transType',
      keyName: 'name',
      name: 'name',
      required: true,
      singleSelection: true,
      function: 'getAllTransType',
      default: [],
      size: 3
    },
    {
      title: ' Status ',
      input: 'select',
      key: 'statusId',
      placeholder: 'Select Status',
      property: 'statusId',
      keyName: 'id',
      name: 'name',
      singleSelection: false,
      function: 'getAllActive',
      default: [],
      setting: {
        type: "Internal",
      },
      size: 3
    },
    {
      title: ' From Issue Date ',
      input: 'dateTime',
      key: 'fromIssueDate',
      placeholder: 'Select From Issue Date',
      property: 'fromIssueDate',
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
    {
      title: ' To Issue Date ',
      input: 'dateTime',
      key: 'toIssueDate',
      placeholder: 'Select To Issue Date',
      property: 'toIssueDate',
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
    {
      title: ' Issue Date ',
      input: 'dateTime',
      key: 'issueDate',
      placeholder: 'Select Issue Date',
      property: 'issueDate',
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
  ],
  columns: [
    {
      name: "Date", prop: "issueDate", "displayFormat": " ",
      "showInGroupFooter": true
    },
    {
      name: "Stone Name", prop: "stoneId", "displayFormat": " ",
      "showInGroupFooter": true
    },
    {
      name: "Party", prop: "partyName", "displayFormat": " ",
      "showInGroupFooter": true
    },
    {
      name: "Program", prop: "program", "displayFormat": " ",
      "showInGroupFooter": true
    },
    {
      name: "Iss Cts", prop: "issCts", ngIf: ['voucherType', 'issue'], required: false,
      "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum",
    },
    {
      name: "Ret Cts", prop: "retCts", ngIf: ['voucherType', 'outstanding'], required: false,
      "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum",
    },
    {
      name: "OS / Cts", dynamic: ["issCts", "-", "retCts"], ngIf: ['voucherType', 'return'], required: false,
      "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum",
    },
    {
      name: "Memo Price", prop: "memoPrice",
      "showInGroupFooter": true, "displayFormat": " ",
    },
    {
      name: "Memo Value", prop: "memoValue",
      "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum",
    },
    {
      name: "Current price", prop: "price",
      "showInGroupFooter": true, "displayFormat": " "
    },
    {
      name: "Ref remark", prop: "remark", "displayFormat": " ",
      "showInGroupFooter": true
    },
  ]
};
