import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ManageSsParameterPage } from "../../actions/manage-ss-parameter/manage-ss-parameter.page";
import { LoadingController, ModalController, Events } from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-ss-parameter",
  templateUrl: "./ss-parameter.component.html",
  styleUrls: ["./ss-parameter.component.scss"]
})
export class SSParameterComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() type: any;

  public listOfParameter: any;
  public items: any;
  public arrowKeyLocation = -1;
  public selectedParameter: any;
  public selectedItem: any;
  public defaultData = [];
  public newDefaultData = [];
  public listOfSsPd = [];
  public setOfParamMasterIds = [];
  constructor(
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public configService: ConfigService,
    public modalCtrl: ModalController,
    public event: Events
  ) { }

  ngOnInit() {
    if (this.type == "MIX") {
      this.event.subscribe('Assort Parameter Config', (item) => {
        this.getAllStoneMaster();
        this.getDefaultStoneParamMaster();
      })
    } else {
      this.type = "SS";
      this.event.subscribe('SSPD Parameter Config', (item) => {
        this.getAllStoneMaster();
        this.getDefaultStoneParamMaster();
      })
    }
    this.getAllStoneMaster();
    this.getDefaultStoneParamMaster();
  }

  ngOnDestroy() {
    if (this.type == "MIX") {
      this.event.unsubscribe('Assort Parameter Config');
    } else {
      this.event.unsubscribe('SSPD Parameter Config');
    }
  }

  async getAllStoneMaster() {
    let res: any;
    res = await this.httpApiService.getAllStoneMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfParameter = data.data;
      // this.items = this.listOfParameter;
    }
  }
  async openModal(item) {
    let modalPage = await this.modalCtrl.create({
      component: ManageSsParameterPage,
      backdropDismiss: false,
      componentProps: { id: item },
      cssClass: "largePage"
    });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modalPage.present();
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      await this.getDefaultStoneParamMaster();
    }
    // for (var i in this.setOfParamMasterIds) {
    //   if (this.setOfParamMasterIds[i].id == d.data.id) {
    //     this.setOfParamMasterIds[i].upTolerance = d.data.upTolerance;
    //     this.setOfParamMasterIds[i].downTolerance = d.data.downTolerance;
    //     this.newDefaultData[i].upTolerance = d.data.upTolerance;
    //     this.newDefaultData[i].downTolerance = d.data.downTolerance;
    //     //  break; //Stop this loop, we found it!
    //   }
    // }
    // for (var i in this.listOfSsPd) {
    //   if (this.listOfSsPd[i].id == d.data.id) {
    //     this.listOfSsPd[i].upTolerance = d.data.upTolerance;
    //     this.listOfSsPd[i].downTolerance = d.data.downTolerance;
    //     //  break; //Stop this loop, we found it!
    //     this.setOfParamMasterIds.push(d.data)
    //   }
    // }
  }
  async getDefaultStoneParamMaster() {
    let res: any;
    let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getDefaultStoneParamMaster(
      fromLocation,
      this.type
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfSsPd = data.data;
      // this.items = this.listOfParameter;
    }
  }

  async filterItems(ev: any) {
    if (!!this.listOfParameter && this.listOfParameter.length > 0) {
      this.setItems();
      let val = ev.target.value;
      if (val && val.trim() !== "") {
        this.items = await this.items.filter(function (item) {
          return item.masterName.toLowerCase().includes(val.toLowerCase());
        });
        this.arrowKeyLocation = -1;
      } else {
        this.arrowKeyLocation = -1;
        this.items = [];
      }
    }
  }
  setItems() {
    this.items = this.listOfParameter;
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0 && this.arrowKeyLocation != -1) {
            this.arrowKeyLocation--;
            this.selectedParameter = this.items[
              this.arrowKeyLocation
            ].masterName;

            this.selectedItem = this.items[this.arrowKeyLocation];
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.selectedParameter = this.items[
              this.arrowKeyLocation
            ].masterName;
            this.selectedItem = this.items[this.arrowKeyLocation];
            break;
          } else {
            break;
          }
        }
    }
  }
  async addParameter() {
    let flag: boolean;
    if (this.listOfSsPd && this.listOfSsPd.length > 0) {
      this.listOfSsPd.forEach(v => {
        if (v.masterName == this.selectedItem.masterName) {
          flag = true;
        }
      });
      if (flag == false || flag == undefined) {
        if (this.newDefaultData.length > 0) {
          this.newDefaultData.forEach(v => {
            if (v.masterName == this.selectedItem.masterName) {
              flag = true;
            }
          });
        }
        if (flag == false || flag == undefined) {
          this.newDefaultData.push({
            masterName: this.selectedItem.masterName,
            id: this.selectedItem.id,
            downTolerance: this.selectedItem.downTolerance,
            upTolerance: this.selectedItem.upTolerance
          });
          this.setOfParamMasterIds.push({
            id: this.selectedItem.id,
            upTolerance: this.selectedItem.upTolerance,
            downTolerance: this.selectedItem.downTolerance
          });
        } else {
          await this.configService.showToast(
            "error",
            "Parameter already selected"
          );
        }
      } else {
        await this.configService.showToast("error", "Parameter Already exist");
      }
    } else {
      this.newDefaultData.forEach(el => {
        if (el.masterName == this.selectedItem.masterName) {
          flag = true;
        }
      })
      if (flag == false || flag == undefined) {
        this.newDefaultData.push({
          masterName: this.selectedItem.masterName,
          id: this.selectedItem.id,
          downTolerance: this.selectedItem.downTolerance,
          upTolerance: this.selectedItem.upTolerance
        });
        this.setOfParamMasterIds.push({
          id: this.selectedItem.id,
          upTolerance: this.selectedItem.upTolerance,
          downTolerance: this.selectedItem.downTolerance
        });
      } else {
        await this.configService.showToast(
          "error",
          "Parameter already selected"
        );
        console.log(this.newDefaultData)
      }
    }
    this.items = [];
  }

  async selectParameter(item) {
    let flag: boolean;
    if (this.listOfSsPd && this.listOfSsPd.length > 0) {
      this.listOfSsPd.forEach(v => {
        if (v.masterName == item.masterName) {
          flag = true;
        }
      });
      if (flag == false || flag == undefined) {
        if (this.newDefaultData.length > 0) {
          this.newDefaultData.forEach(v => {
            if (v.masterName == item.masterName) {
              flag = true;
            }
          });
        }
        if (flag == false || flag == undefined) {
          this.newDefaultData.push({
            masterName: item.masterName,
            id: item.id,
            downTolerance: item.downTolerance,
            upTolerance: item.upTolerance
          });
          this.setOfParamMasterIds.push({
            id: item.id,
            upTolerance: item.upTolerance,
            downTolerance: item.downTolerance
          });
        } else {
          await this.configService.showToast(
            "error",
            "Parameter already selected"
          );
        }
      } else {
        await this.configService.showToast("error", "Parameter Already exist");
      }
    } else {
      this.newDefaultData.forEach(el => {
        if (el.masterName == item.masterName) {
          flag = true;
        }
      })
      if (flag == false || flag == undefined) {
        this.newDefaultData.push({
          masterName: item.masterName,
          id: item.id,
          downTolerance: item.downTolerance,
          upTolerance: item.upTolerance
        });
        this.setOfParamMasterIds.push({
          id: item.id,
          upTolerance: item.upTolerance,
          downTolerance: item.downTolerance
        });
      } else {
        await this.configService.showToast(
          "error",
          "Parameter already selected"
        );
        console.log(this.newDefaultData)
      }
    }
    this.items = [];
  }

  async saveParameters() {
    let validate = await this.validateControls();
    let fromLocation = await this.configService.getFromLocation();
    if (!validate) {
      let jsonObj = {
        transType: this.type,
        companyName: fromLocation,
        setOfParamMasterIds: this.setOfParamMasterIds
      };
      let res: any;
      res = await this.httpApiService.addSsPdSetting(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.newDefaultData = [];
        this.selectedParameter = "";
        this.setOfParamMasterIds = [];
        await this.configService.showToast(
          "success",
          "SSPD added successfully"
        );
        this.getDefaultStoneParamMaster();
      }
    }
  }

  async validateControls() {
    if (this.setOfParamMasterIds.length > 0) {
      return false;
    } else {
      await this.configService.showToast(
        "error",
        "Please insert the information"
      );
      return true;
    }
  }

  async removeStone(id) {
    let index = this.newDefaultData.indexOf(id);
    if (index > -1) {
      this.newDefaultData.splice(index, 1);
      this.setOfParamMasterIds.splice(index, 1);
    }
  }

  async removeSSPd(id) {
    let res: any;
    res = await this.httpApiService.deleteSsPdSetting(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.selectedParameter = "";
      this.getDefaultStoneParamMaster();
      await this.configService.showToast(
        "success",
        "SSPD value deleted successfully"
      );
    }
  }
}
