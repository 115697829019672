import { Component, OnInit } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import {
  ModalController,
  LoadingController,
  NavParams
} from "@ionic/angular";
@Component({
  selector: "app-scan-rfid",
  templateUrl: "./scan-rfid.page.html",
  styleUrls: ["./scan-rfid.page.scss"]
})
export class ScanRFIDPage implements OnInit {
  public process: any;
  public actionId: any;
  public scannerBoardAction: any;
  public setOfStoneId = [];
  public skipAssortment: any;
  public page: any;
  public boardBaseUrl: any = '';

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    private authService: AuthenticationService,
  ) {
    this.process = this.navParams.get('type');
    this.setOfStoneId = this.navParams.get('setOfStones');
    this.skipAssortment = this.navParams.get('skipAssortment');
  }

  ngOnInit() {
    this.actionId = "scanToReceive";
    this.getUserData()
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
    if (!!this.boardBaseUrl) {
      console.log(this.boardBaseUrl)
      this.httpApiService.scanLedOff(this.boardBaseUrl, [1]);
    }
  }
}
