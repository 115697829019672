import { Component, Input } from "@angular/core";
import {
  AlertController, Events, LoadingController, ModalController
} from "@ionic/angular";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import * as moment from "moment";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManagePurchaseInwardPage } from "../../actions/manage-purchase-inward/manage-purchase-inward.page";
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: 'app-purchase-inward',
  templateUrl: './purchase-inward.component.html',
  styleUrls: ['./purchase-inward.component.scss']
})
export class PurchaseInwardComponent implements ProcessActionComponent {
  @Input() data: any;

  public columns = [];
  public listOfStoneData = [];
  public listOfStoneDataSource: any = {};
  public count = 0;
  public selected = [];
  public selectedStoneList = [];
  public isSelect = false;
  public pageNumber = 0;
  public noOfRecords = 20;
  public pageSize = 20
  public page = "purchaseInward"
  public pDate = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MMM-yyyy',
    defaultOpen: false,
    minDate: moment(new Date('2023-02-15')).format('YYYY-MM-DD')
  }
  public purchaseInwardDate = moment(new Date()).format('YYYY-MM-DD');
  public refreshGrid: boolean = false;
  public listOfErrors = [];

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events) { }

  ngOnInit() {
    this.columns = [
      { name: "Voucher No", prop: "" },
      { name: "Party Name", prop: "partyName" },
      { name: "Invoice No", prop: "invoiceNo" },
      { name: "Total Sequence no", prop: "srNo" },
      { name: "Pending Ct.", prop: "pendingCarat" },
      { name: "Date & Time", prop: "modifiedDate" }
    ];
    this.pageNumber = 0;
    this.noOfRecords = 20;
    this.getAllPendingPurchaseStoneInward(this.pageNumber, this.noOfRecords)
    this.event.subscribe('Purchase Inward', (item) => {
      this.columns = [
        { name: "Voucher No", prop: "" },
        { name: "Party Name", prop: "partyName" },
        { name: "Invoice No", prop: "invoiceNo" },
        { name: "Total Sequence no", prop: "srNo" },
        { name: "Pending Ct.", prop: "pendingCarat" },
        { name: "Date & Time", prop: "modifiedDate" }
      ];
      this.getAllPendingPurchaseStoneInward(this.pageNumber, this.noOfRecords)
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Purchase Inward');
  }

  async getAllPendingPurchaseStoneInward(pageNumber, noOfRecords) {
    // await this.configService.showLoader();
    // let res: any;
    // console.log(this.data.voucherType);
    // res = await this.httpApiService.purchaseInward(
    //   pageNumber,
    //   noOfRecords
    // );
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   if (res.status == 400) {
    //     await this.configService.showToast("error", data.error);
    //   } else {
    //     this.listOfStoneData = data.data.content;
    //     console.log(this.listOfStoneData);
    //     this.count = data.data.totalElements;
    //   }
    // }
    this.listOfStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.purchaseInward(
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfStoneData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfStoneData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }


  async openModal(item, flag) {
    this.refreshGrid = false;
    if (item.type == "mouseenter") {
      //Stop event propagation and let onSelect() work
      item.event.stopPropagation();
    } else {
      let modalPage = await this.modalCtrl.create({
        component: ManagePurchaseInwardPage,
        backdropDismiss: false,
        componentProps: {
          data: item.row,
          flagModal: flag
        },
        cssClass: 'requestPage'
      })
      modalPage.onDidDismiss().then((d: any) => this.handlePurchaseDetail(d))
      return await modalPage.present();
    }
  }

  async handlePurchaseDetail(d) {
    this.refreshGrid = true;
    if (d.data != 'doNotRefresh') {
      await this.getAllPendingPurchaseStoneInward(this.pageNumber, this.noOfRecords)
    }
  }

  setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getAllPendingPurchaseStoneInward(this.pageNumber, this.noOfRecords)
  }

  async fetchPurchaseInward() {
    this.listOfErrors = [];
    await this.configService.showLoader();
    let res: any;
    // await this.httpApiService.getKGPartyDetails(moment(new Date(this.purchaseInwardDate)).format("DD-MMM-YYYY"));
    res = await this.httpApiService.getPurchaseInwardDataFromKG(moment(new Date(this.purchaseInwardDate)).format("DD-MMM-YYYY"));
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      if (data.totalErrors > 0) {
        if (data.listOfErrorsWithLineNumbers.length > 0) {
          for (let i = 0; i < data.listOfErrorsWithLineNumbers.length; i++) {
            let errMsg = data.listOfErrorsWithLineNumbers[i].errorMessage;
            this.listOfErrors.push(errMsg)
          }
        }
        await this.configService.showToast("error", this.listOfErrors.join(','));
      } else {
        await this.configService.showToast("error", data.error);
      }
    } else {
      this.modalCtrl.dismiss();
      if (res.status == 200) {
        await this.configService.showToast("success", data.message);
      } else {
        await this.configService.showToast("error", data.error);
      }
    }
  }
  deleteItem(evt) {
    this.confirmDeletePurchaseInwardDetail(evt.row.data);
  }
  async confirmDeletePurchaseInwardDetail(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Purchase inward detail ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: e => {
            console.log("Action Cancel" + e);
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Action confirmed");
            console.log(item);
            this.deletePurchaseInwardDetail(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deletePurchaseInwardDetail(id) {
    let res: any;
    res = await this.httpApiService.deleteConfirmPurchaseStone({ listOfVoucherId: [id] });
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.getAllPendingPurchaseStoneInward(this.pageNumber, this.noOfRecords)
    }
  }
}
