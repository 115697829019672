import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, Events, LoadingController, ModalController } from '@ionic/angular';
import CustomStore from 'devextreme/data/custom_store';
import { IonicSelectableComponent } from 'ionic-selectable';
import * as moment from 'moment';
import Query from "devextreme/data/query";
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpApiService } from 'src/app/services/http-api/http-api.service';

@Component({
  selector: 'app-batchpartystockuploader',
  templateUrl: './batchpartystockuploader.component.html',
  styleUrls: ['./batchpartystockuploader.component.scss']
})
export class BatchpartystockuploaderComponent implements OnInit {
  @ViewChild('selectableComponent') selectableComponent: IonicSelectableComponent;
  @Input() data: any;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  public listOfParties = []
  public partyFile: any;
  public partyMasterId: any;
  public partyName: any;
  public selectedParty = ''
  public pageSize: any = 50;
  public uploadDate = moment().format("YYYY-MM-DD")
  listOfStoneDataSource: any = [];
  pageNumber = 0;
  noOfRecords = 15;
  columns = [];
  public refreshGrid: Boolean = false;
  dataSource: any[];



  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    this.columns = [
      { name: "File Name", prop: "fileName" },
      { name: "Status", prop: "status" },
      { name: "By", prop: "username" },
      { name: "Date & Time", prop: "createdDate" }
    ];
   
      this.loadUploadHistory();
      this.reset()
    

  }

  ngOnDestroy() {
    this.event.unsubscribe('Party Stock Uploader');
  }

  onPFileSelected(event) {
    this.partyFile = <File>event.target.files[0];
  }

  async upload() {
    // const formData: FormData = new FormData();
    let formData = new FormData();
    formData.append("file", this.partyFile);
    // formData.append("file", this.partyFile);
    formData.append("date", moment(this.uploadDate).format("YYYY-MM-DD"));

    await this.configService.showLoader();
    console.log(formData);
    let res: any;
    res = await this.httpApiService.uploadPurchaseFileForMultyParty(formData);
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.reset()
      this.uploadDate = moment().format("YYYY-MM-DD")
      await this.loadUploadHistory();
    }
  }

  reset() {
    this.partyFile = null;
    this.partyMasterId = null;
    this.partyName = null;
    this.selectedParty = null;
    this.myInputVariable.nativeElement.value = "";
  }

  async loadUploadHistory() {
    let res: any;

    res = await this.httpApiService.getUploadHistory(
      'Purchase Party File',
      this.pageNumber,
      this.noOfRecords
    );
    let data = res.json();
    console.log(data);
    let sourceData = data.data.content.map((obj: any) => {
      return {
        status: obj.status,
        fileName: obj.fileName,
        createdDate: obj.createdDate,
        url: obj,
        username: obj.username
      }
    });

    this.dataSource = sourceData;
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {

      }
    }
  }

    async downloadUploadedFile(file) {
    console.log(file)
    let res: any;
    if (!!file.url) {
      window.open(file.url, '_blank')
    }
  }




}
