import { Component, OnInit, Input } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ScanRFIDPage } from "../../actions/scan-rfid/scan-rfid.page";
import * as moment from "moment";
import { PdAssortDetailsPage } from "../../actions/pd-assort-details/pd-assort-details.page";
import { bypassSanitizationTrustResourceUrl } from "@angular/core/src/sanitization/bypass";

@Component({
  selector: 'app-manage-lab-packet-service-master',
  templateUrl: './manage-lab-packet-service-master.page.html',
  styleUrls: ['./manage-lab-packet-service-master.page.scss'],
})
export class ManageLabPacketServiceMasterPage implements OnInit {
  public partyType: any;
  public listOfLabs = [];
  public labId: any;
  public partyMasterId: any;
  public shapeIdList = [];
  public shapeList = [];
  public shapeId: any;
  public isActive: boolean = false;
  public seriesName: any;
  public flagAddUpdate: any;
  public id: any;
  public item: any;
  public labIdList = [];
  public showSelected = true;
  public fromSize;
  public toSize;

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) { }

  async ngOnInit() {
    this.flagAddUpdate = this.navParams.get('flag');

    await this.findShapeByType();
    await this.getAllLabs();

    if (this.flagAddUpdate == 'update') {
      let data = this.navParams.get('item');
      if (data != 0) {
        this.item = data.row;
        this.id = this.item.id;
        this.partyMasterId = this.item.partyMasterId;
        this.labId = this.item.labId;
        this.shapeId = this.item.shapeId;
        this.seriesName = this.item.seriesName;
        this.fromSize = this.item.fromSize;
        this.toSize =this.item.toSize;
        this.isActive = Boolean(this.item.isActive)

        this.shapeList.filter(el => {
          if (el.id == this.item.shapeId) {
            this.shapeIdList = []
            this.shapeIdList.push({ id: el.id, name: el.name })
          }
        })

        this.listOfLabs.filter(el => {
          if (el.id == this.item.partyMasterId) {
            this.labIdList = []
            this.labIdList.push({ id: el.id, partyName: el.partyName })
          }
        })
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss()
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'shapeIdList') {
      if (idList.length > 0) {
        this.shapeId = idList[0]
      } else {
        this.shapeId = null;
      }
    } else if (property == 'labList') {
      if (idList.length > 0) {
        this.partyMasterId = idList[0];
        let obj = this.listOfLabs.filter(x => x.id == idList[0]);
        console.log(obj)
        this.labId = obj[0].labReferenceId;
      } else {
        this.partyMasterId = null;
      }
    }
  }

  async findShapeByType() {
    let res: any;
    res = await this.httpApiService.getStoneMasterDetailByName('Shape');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.shapeList = data.data;
    }
  }

  async getAllLabs() {
    let json = {
      listOfType: ["Lab"]
    }
    let res: any;
    res = await this.httpApiService.findByListOfPartyType(json, 'Job');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLabs = data.data;
    }
  }

  async validateControls() {
    if (
      this.partyMasterId &&
      this.fromSize &&
      this.toSize &&
      this.seriesName
    ) {
      return true;
    } else {
      await this.configService.showToast("error", "Please insert all the fields.");
      return false;
    }
  }

  async addLabPacketSeries() {
    let validate = await this.validateControls();
    if (validate) {
      let obj = {
        'partyMasterId': this.partyMasterId,
        'labId': this.labId,
        'shapeId': this.shapeId,
        'seriesName': this.seriesName,
        'isActive': this.isActive,
        'fromSize':Number(this.fromSize),
        'toSize':Number(this.toSize),
      }
      console.log(obj);
      await this.configService.showLoader();
      let res;
      res = await this.httpApiService.addLabPacketSeries(obj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.message);
      } else if (!data.isSuccess) {
        await this.configService.showToast(
          "error",
          data.data
        );

      } else {
        await this.configService.showToast(
          "success",
          data.data
        );
      }
      await this.modalCtrl.dismiss('refresh');
    }
  }

  async updateLabPacketSeries() {
    let validate = await this.validateControls();
    if (validate) {
      let obj = {
        'partyMasterId': this.partyMasterId,
        'labId': this.labId,
        'shapeId': this.shapeId,
        'seriesName': this.seriesName,
        'isActive': this.isActive,
        'fromSize':parseFloat(this.fromSize).toFixed(2),
        'toSize':parseFloat(this.toSize).toFixed(2)
      }
      console.log(obj)
      await this.configService.showLoader();
      let res;
      res = await this.httpApiService.updateLabPacketSeries(obj, this.id);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.message);
      } else if (!data.isSuccess) {
        await this.configService.showToast(
          "error",
          data.data
        );

      } else {
        await this.configService.showToast(
          "success",
          data.data
        );
      }
      await this.modalCtrl.dismiss();
    }
  }

}
