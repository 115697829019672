import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "capitalcase",
})
export class CapitalCasePipe implements PipeTransform {
  transform(value: string, ...args: any[]) {
    return value[0].toUpperCase() + value.slice(1);
  }
}
