import { Component, ViewChild, Input } from "@angular/core";
import {
  ModalController,
  AlertController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import * as moment from "moment";
import CustomStore from 'devextreme/data/custom_store';
import Query from "devextreme/data/query";
import { ScanRFIDPage } from "../../../components/actions/scan-rfid/scan-rfid.page";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";

@Component({
  selector: 'app-mix-to-single-transfer',
  templateUrl: './mix-to-single-transfer.component.html',
  styleUrls: ['./mix-to-single-transfer.component.scss']
})
export class MixToSingleTransferComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild("dataTable") public dataTable: any;

  public stoneList = [];
  public columns = [];
  public csspColumns = [];
  public count: Number = 0;
  public selected = [];
  public selectedStoneList = []
  public isSelect: boolean = false;
  public refreshGrid: boolean = false;
  public voucherBookId: any;
  public toRole: any;
  public successMsg = null;
  public msgStatus = false;
  public items = []
  public pageNumber = 0;
  public noOfRecords = 100000;
  public stoneDataSource: any = [];
  public keyFun: any = true;
  public openView: any = false;
  public companyId: any;
  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public event: Events,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public exportAsExcelService: ExportAsExcelService,
  ) { }

  async ngOnInit() {
    this.companyId = await this.authService.getCompanyId();
    this.columns = [
      { name: "StoneId", prop: "toStoneId", summaryType: 'stoneId' },
      { name: "Color", prop: "color" },
      { name: "Shape", prop: "shape" },
      { name: "Ct.", prop: "cts" },
      { name: "Clarity", prop: "clarity" },
      // { name: "Party", prop: "mixStoneId", summaryType:'stoneId' },
      // { name: "Invoice No", prop: "invoiceNo" },
      // { name: "Sequence No", prop: "srNo" },
      { name: "Barcode", prop: "barcode" },
      { name: "Rfid", prop: "rfid" },
      { name: "Date & Time", prop: "createdDate" },
    ];
    this.getAllConvertedSingleStone()
    this.getDefaultSetting()
    this.event.subscribe('Single Transfer To Assortment', (item) => {
      this.columns = [
        { name: "StoneId", prop: "toStoneId", summaryType: 'stoneId' },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "cts" },
        { name: "Clarity", prop: "clarity" },
        // { name: "Party", prop: "mixStoneId", summaryType:'stoneId' },
        // { name: "Invoice No", prop: "invoiceNo" },
        // { name: "Sequence No", prop: "srNo" },
        { name: "Barcode", prop: "barcode" },
        { name: "Rfid", prop: "rfid" },
        { name: "Date & Time", prop: "createdDate" },
        { name: "Action", prop: "" }
      ];
      this.getAllConvertedSingleStone()
      this.getDefaultSetting()
    })
  }

  setItems() {
    this.items = this.stoneList;
    this.count = this.stoneList.length;
  }


  async filterItems(ev: any) {
    if (this.stoneList.length > 0) {
      let val = ev.target.value;
      console.log(val)
      await this.setItems();
      if (val && val.trim() !== "") {
        this.items = await this.items.filter(function (item) {
          if (item.name && item.name.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.stoneId && item.stoneId.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.mixStoneId && item.mixStoneId.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.clarity && item.clarity.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.shape && item.shape.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.invoiceNo && item.invoiceNo.toString().includes(val.toLowerCase())) {
            return true;
          } else if (item.srNo && item.srNo.toString().includes(val.toLowerCase())) {
            return true;
          } else if (item.color && item.color.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.cts && item.cts.toString().includes(val.toLowerCase())) {
            return true;
          } else if (item.barcode && item.barcode.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.rfid && item.rfid.toString().includes(val.toLowerCase())) {
            return true;
          }
        });
        this.count = this.items.length;
      } else {
        await this.setItems();
      }
    }

  }

  async closeDiv() {
    this.msgStatus = false;
    this.successMsg = "";
  }

  ngOnDestroy() {
    this.event.unsubscribe('Mix To Single Transfer')
  }

  async getAllConvertedSingleStone() {
    console.log("getAllConvertedSingleStone")
    /*await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.getAllConvertedSingleStone(this.pageNumber,this.noOfRecords);
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneList = data.data.content;
      this.count = data.data.totalElements;
      this.setItems()
    }*/

    this.stoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getAllConvertedSingleStone(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.stoneList = data.data.content;
            this.setItems();
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.stoneList,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    console.log("this.stoneList ", this.stoneList);
  }

  onSelect(ev) {
    this.selected = ev.selectedRowsData;
    console.log(this.selected)
    console.log(this.selected)
    this.selectedStoneList = [];
    this.selected.forEach(el => {
      this.selectedStoneList.push(el.id);
    })
    // console.log(this.isSelect, this.selectedStoneList, this.selectedStoneList.length, this.listOfPendingAssortment.length)
    if (this.selected.length == this.stoneList.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }

  async selectAll(item) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(this.stoneList)
      this.isSelect = true;
      this.selectedStoneList = [];
      this.selected.forEach(el => {
        this.selectedStoneList.push(el.id);
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.selectedStoneList = [];
    }
    console.log(this.selectedStoneList)
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", data);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
      }
      console.log(this.voucherBookId, this.toRole);
    }
  }


  async issueStones() {
    this.msgStatus = false;
    await this.configService.showLoader();
    let jsonObj = {
      voucherDate: moment().format("YYYY-MM-DD"),
      setOfStoneIds: this.selectedStoneList,
      voucherBookId: this.voucherBookId,
      toRole: this.toRole,
      isTransferToAssortment: true,
      isAssortmentDone: false,
      isReadyForSale: false,
      companyId: this.companyId
    };
    console.log(jsonObj)
    let res: any;
    res = await this.httpApiService.stoneIssue(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      if (!!data.data && data.data.length > 0) {
        await this.configService.showToast("error", data.message);
        await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
      } else {
        await this.configService.showToast("error", 'Error occoured while transferring stones.');
      }
    } else {
      this.isSelect = false;
      this.selectedStoneList = []
      let stoneCount = data.data.content.length > 0 ? data.data.content[0].StoneId.length : ''
      await this.configService.showToast("success", `${stoneCount} stones transferred successfully`);
      this.selected = []
      this.isSelect = false;
      this.getAllConvertedSingleStone();
    }
  }

  //Start Delete Status 

  async confirmDeleteStone(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this stones ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteSingleStone(item.row.data.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteSingleStone(id) {
    console.log(id)
    let res: any;
    res = await this.httpApiService.deletePurchaseOrMixToSingleStone(id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.selected = []
      this.isSelect = false;
      this.getAllConvertedSingleStone();
    }
  }

  async openScanModel() {
    if (!this.openView) {
      this.keyFun = false;
      const modal = await this.modalCtrl.create({
        component: ScanRFIDPage,
        backdropDismiss: false,
        componentProps: {
          type: "issueWithTransferToAssortment",
          skipAssortment: true
        },
        cssClass: "largePage"
      });
      modal.onDidDismiss().then((d: any) => this.handleScanModalDismiss(d));
      return await modal.present();
    } else {
      this.keyFun = true;
    }
  }

  async handleScanModalDismiss(d) {
    this.getAllConvertedSingleStone();
    this.keyFun = true;

  }

  //END

}



