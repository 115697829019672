import { Component, OnInit, Input } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { ManageVoucherBookPage } from "../manage-voucher-book/manage-voucher-book.page";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import * as moment from "moment";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: 'app-apply-policy',
  templateUrl: './apply-policy.component.html',
  styleUrls: ['./apply-policy.component.scss']
})
export class ApplyPolicyComponent implements ProcessActionComponent {
  @Input() data: any;
  public policies = [];
  public count: Number = 0;
  public allPriceNameList = []
  public pageNumber = 0;
  public noOfRecords = 20;
  public nameId: any;
  public totalPages: Number = 0;
  public policyId: any;
  public page = 'applyPolicy';
  public priceDate: any;
  public policyIdList = [];
  public nameIdList = [];
  public toPriceListNameMasterId: any;
  public toPriceNameList = [];
  public historyData: any;
  public historyDataDataSource : any = {};
  public columns = []
  public pageSize = 20;

  constructor(
    public httpApiService: HttpApiService,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) {
    this.columns = [
      { name: "Start Price List", prop: "fromPriceList" },
      { name: "Policy Name", prop: "policyName" },
      { name: "End Price List", prop: "toPriceList" },
      { name: "Status", prop: "status" },
      { name: "By", prop: "username" },
      { name: "Date", prop: "priceListDate" }
    ];
  }

  ngOnInit() {
    this.priceDate = moment().format('YYYY-MM-DD')

    this.getAllPolicies();
    this.findAppliedPolicies(this.pageNumber, this.noOfRecords);
    this.getAllPriceListName();
    this.event.subscribe('Apply Pricing Policy', (item) => {
      this.getAllPolicies();
      this.findAppliedPolicies(this.pageNumber, this.noOfRecords);
      this.getAllPriceListName();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Apply Pricing Policy')
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "fromPriceListName") {
      if (idList.length > 0) {
        this.nameId = idList[0];
        this.getAllToPriceListName(this.nameId);
      } else {
        this.nameId = null;
      }
    } else if (property == "toPriceListName") {
      if (idList.length > 0) {
        this.toPriceListNameMasterId = idList[0];
      } else {
        this.toPriceListNameMasterId = null;
      }
    } else {
      if (idList.length > 0) {
        this.policyId = idList[0];
      } else {
        this.policyId = null;
      }
    }
  }

  async findAppliedPolicies(pageNumber, noOfRecords) {
    // await this.configService.showLoader();
    // let res: any;
    // res = await this.httpApiService.findAllAppliedPolicies(pageNumber, noOfRecords);
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   this.historyData = data.data.content;
    //   this.totalPages = data.data.totalPages;
    //   this.count = data.data.totalElements;
    //   console.log("get policies", this.historyData);
    // }
    this.historyDataDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100,500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findAllAppliedPolicies(this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.historyData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.historyData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.findAppliedPolicies(currentPage.offset, this.noOfRecords);
  }

  async getAllToPriceListName(id) {
    let index = this.allPriceNameList.findIndex(x => x.id == id);
    console.log(index)
    if (index > -1) {
      this.toPriceNameList.slice(index, 1)
      console.log(this.toPriceNameList)
    }
  }

  async getAllPolicies() {
    let res: any;
    res = await this.httpApiService.getAllPolicy();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.policies = data.data;
      console.log("get policies", this.policies);
    }
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
      this.toPriceNameList = data.data;
    }
  }

  async applyPolicy() {
    let validate = await this.validateControls();
    if (validate) {
      let res: any;
      await this.configService.showLoader();
      let jsonObj = {
        pricePolicyMasterId: this.policyId,
        priceListNameMasterId: this.nameId,
        toPriceListNameMasterId: this.toPriceListNameMasterId,
        priceListDate: this.priceDate
      }
      console.log(jsonObj)
      res = await this.httpApiService.applyPolicy(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.resetData();
        this.toPriceNameList = this.allPriceNameList;
        await this.configService.showToast("success", data.message);
        await this.findAppliedPolicies(this.pageNumber, this.noOfRecords);
      }
    }
  }

  async resetData() {
    this.policyIdList = [];
    this.nameIdList = [];
    this.nameId = null;
    this.policyId = null;
  }

  async validateControls() {
    console.log(this.priceDate, this.nameId, this.policyId);
    if (
      this.priceDate != undefined && this.priceDate != '' && this.priceDate != null &&
      this.nameId != undefined &&
      this.nameId != "" &&
      this.nameId != null &&
      this.policyId != undefined &&
      this.policyId != "" &&
      this.policyId != null && this.toPriceListNameMasterId != null && this.toPriceListNameMasterId != '' && this.toPriceListNameMasterId != undefined
    ) {
      return true;
    } else {
      await this.configService.showToast("error", "Please select the detail");
      return false;
    }
  }

}
