import { Component, OnInit, Input } from '@angular/core';
import {
  NavController,
  ModalController,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import * as moment from "moment";

@Component({
  selector: 'app-audit-cssp',
  templateUrl: './audit-cssp.component.html',
  styleUrls: ['./audit-cssp.component.scss']
})
export class AuditCSSPComponent implements ProcessActionComponent {
  @Input() data: any;

  public fromCts: any;
  public toCts: any;
  public items: any;
  public typeList = [];
  public type: any;
  public colorList = [];
  public clarityList = [];
  public shapeList = [];
  public colorIdList = [];
  public showSelected = true;
  public shapeIdList = [];
  public clarityIdList = [];
  public typeIdList = []
  public shapeId = [];
  public clarityId = [];
  public colorId = [];
  public csspList = [];
  public sizeList = [];
  public sizeIdList = []

  public userId: any;
  public voucherBookId: any;
  public reqJson: any;

  constructor(private authService: AuthenticationService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) { }

  ngOnInit() {
    this.getDefaultSetting()
    this.findShapeByType();
    this.findParametersForMixSizeMaster();

    this.event.subscribe('Audit CSSP', (item) => {
      this.getDefaultSetting()
      this.findShapeByType();
      this.findParametersForMixSizeMaster();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Audit CSSP');
  }


  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'colorIdList') {
      if (idList.length > 0) {
        this.colorId = idList;
        console.log(this.colorId)
      } else {
        this.colorId = [];
      }
    } else if (property == 'shapeIdList') {
      if (idList.length > 0) {
        this.shapeId = idList;
        this.sizeIdList = []
        this.findSizeByShape(this.shapeId)
      } else {
        this.shapeId = [];
      }
    } else if (property == 'clarityIdList') {
      if (idList.length > 0) {
        this.clarityId = idList;
      } else {
        this.clarityId = [];
      }
    } else if (property == 'sizeList') {
      if (idList.length > 0) {
        this.sizeList.filter(el => {
          if (el.id == idList[0]) {
            this.fromCts = el.fromCts;
            this.toCts = el.toCts;
          }
        })
      } else {
        this.sizeIdList = []
      }
    }
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", data);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
      }
      console.log(this.voucherBookId);
    }
  }


  async findParametersForMixSizeMaster() {
    let res: any;
    res = await this.httpApiService.findParametersForMixSizeMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.colorList) {
        let data1 = []
        dataValue.colorList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.colorList = data1;
      }
      if (dataValue.clarityList) {
        let data1 = []
        dataValue.clarityList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.clarityList = data1;
      }
    }
  }

  async searchCSSP() {
    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      colorId: this.colorId,
      shapeId: this.shapeId,
      clarityId: this.clarityId,
      fromCts: this.fromCts,
      toCts: this.toCts,
      listOfBoxType: ['Regular', 'Program-Parcel', 'Parcel', 'Purchase', 'Program', 'Assortment-OK'],
    }
    res = await this.httpApiService.findCsspOrCreateIfNotExist(jsonObj, 'false');
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.csspList = data.data;
      this.csspList.forEach(el => {
        el.loss;
        el.gain;
        el.remarks = ""
      })
      console.log(this.csspList)
    }
  }

  async findShapeByType() {
    let res: any;
    res = await this.httpApiService.findShapeByType('Sale Bulk');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let data1 = []
      dataValue.forEach(el => {
        data1.push({ id: el.shape, name: el.shapeName })
      })
      this.shapeList = data1;
    }
  }

  async findSizeByShape(shapeId) {
    let res: any;
    res = await this.httpApiService.findSizeByShape(shapeId, 'Sale Bulk');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.sizeList = data.data;
      console.log(this.sizeList)
    }
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  async saveCSSP(item) {
    this.items = item;
    if (!!this.voucherBookId) {
      let validate = await this.validateControls()
      if (validate) {
        await this.configService.showLoader()
        let res: any;
        let name = item.id

        this.reqJson = {
          [name]: !!item.gain ? item.gain : "-" + item.loss
        }
        console.log(this.data)
        let jsonObj = {
          mapOfStoneCts: this.reqJson,
          voucherDate: moment().format("YYYY-MM-DD"),
          remarks: item.remarks,
          voucherBookId: this.voucherBookId
        }
        console.log(jsonObj)
        res = await this.httpApiService.saveAuditCssp(jsonObj);
        let data = res.json();
        await this.configService.dismiss();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          await this.configService.showToast("error", data.error);
        } else {
          await this.configService.showToast("success", data.message);
          this.items = null;
          await this.searchCSSP();
        }
      }
    } else {
      await this.configService.showToast("error", 'Voucherbook not found.');
    }
  }

  async validateControls() {
    if (!!this.items && (this.items.gain || this.items.loss) && this.items.remarks) {
      return true
    } else {
      await this.configService.showToast("error", 'Please enter details');
      return false;
    }
  }
}
