import { Component, OnInit, Input } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  Events,
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { IonicSelectableComponent } from "ionic-selectable";

@Component({
  selector: "app-manage-pending-customer-approval",
  templateUrl: "./manage-pending-customer-approval.page.html",
  styleUrls: ["./manage-pending-customer-approval.page.scss"],
})
export class ManagePendingCustomerApprovalPage implements OnInit {
  public item: any;
  public type: any;
  public partyDetail: any;
  public contactDetail: any;
  public docDetails: any;
  public salesPersonDetails: any;
  public addressDetails: any;
  public distributionCenter: any;
  public listOfCustomers = [];
  public selectedCustomer: any;
  public mapType: any = "update";
  public listOfType = [];
  public partyType: any;
  public remarks: any = "";
  public kamPersonId: any;
  public salesPersonList = [];
  public salePersonIdList = [];
  public partyDetailsNotKYC: any;
  public listOfDocuments = [
    {
      id: 1,
      name: "KYC Blank Form. (filled form on company letter head with stamp and signature)",
    },
    {
      id: 2,
      name: "Undertaking of BPP Compliances. (on your company letter head with your stamp and signature)",
    },
    {
      id: 3,
      name: "Copies of company PAN ID, GST certificates.",
    },
    {
      id: 4,
      name: "IE Code copy.",
    },
    {
      id: 5,
      name: "TAN copy.",
    },
    {
      id: 6,
      name: "TDS or TCS Declaration.",
    },
  ];

  documentIdList = [];
  documentList = [];
  contactDetailsNoKyc: any;

  constructor(
    public authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService
  ) {}

  ngOnInit() {
    this.getAllType();
    this.getAllSalesPerson();
    this.item = this.navParams.get("item");
    this.type = this.navParams.get("type");
    if (this.type === "kycCompleted") {
      let jsonDetail = JSON.parse(this.item.partyDetailsJson);
      this.partyDetail = jsonDetail.customerDetails;
      this.contactDetail = jsonDetail.general;
      this.docDetails = jsonDetail.userKYC;
      this.addressDetails = jsonDetail.addressDetails;
      this.distributionCenter = jsonDetail.userAccount.distributioncenter;
      this.salesPersonDetails = jsonDetail.userAccount.salesperson;
      if (!!jsonDetail.userAccount.latticeSalesPerson) {
        this.kamPersonId = jsonDetail.userAccount.latticeSalesPerson.id;
      }

      if (this.addressDetails.length > 0) {
        this.addressDetails.forEach((el) => {
          if (el) {
            el.address = el.block + " " + el.house + " " + el.area;
            el.zipcode = el.pinCode;
          }
        });
      }
    } else {
      this.contactDetailsNoKyc = JSON.parse(this.item.parameter);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss("doNotRefresh");
  }

  dropDownSelection(ev) {
    console.log(ev);
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "customer") {
      if (idList.length > 0) {
        this.selectedCustomer = idList[0];
      } else {
        this.selectedCustomer = null;
      }
    }
    if (property == "typeList") {
      if (idList.length > 0) {
        this.partyType = obj[0].type;
      } else {
        this.partyType = null;
      }
    }
    if (property == "salePerson") {
      if (idList.length > 0) {
        this.kamPersonId = idList[0];
      } else {
        this.kamPersonId = null;
      }
    }
    if (property == "documentList") {
      if (idList.length > 0) {
        this.documentList = obj.map((el) => el.name);
      } else {
        this.kamPersonId = null;
      }
    }
  }

  async getAllType() {
    let res: any;
    res = await this.httpApiService.getAllPartyMasterType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      console.log(dataList);
      if (dataList.length > 0) {
        for (let i = 0; i < dataList.length; i++) {
          this.listOfType.push({ id: i + 1, type: dataList[i] });
        }
      }
    }
  }

  async sendEmailToCustomer() {
    if (!!this.documentList && this.documentList.length > 0) {
      if (!this.contactDetail.email) {
        await this.configService.showToast("error", "Customer email not found");
        return;
      }
      await this.configService.showLoader();
      let jsonObj = {
        email: this.contactDetail.email,
        documentList: this.documentList,
      };
      let res;
      console.log(jsonObj);
      res = await this.httpApiService.sendDocumentReminderEmail(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast(
          "success",
          "Email has been sent to customer successfully"
        );
        this.documentList = [];
        this.documentIdList = [];
      }
    } else {
    }
  }

  async createParty() {
    await this.configService.showLoader();
    let jsonObj = {
      partyDetails: {
        partyName: this.partyDetail.name,
        type: this.partyType,
        groupType: "Client",
      },
      contactDetails: {
        name: this.contactDetail.name + " " + this.contactDetail.lastname,
        contactNo: this.contactDetail.phone,
        email: this.contactDetail.email,
      },
      listOfAddresses: this.addressDetails,
      pendingPartyApprovalId: this.item.id,
    };

    let res;
    console.log(jsonObj);
    res = await this.httpApiService.addWebsiteParty(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.mapType = "";
      this.findByPendingPartyApprovalId();
    }
  }

  async updateParty() {
    await this.configService.showLoader();
    let jsonObj = {
      partyDetails: {
        partyName: this.partyDetail.name,
        type: this.partyType,
        groupType: "Client",
      },
      contactDetails: {
        name: this.contactDetail.name + " " + this.contactDetail.lastname,
        contactNo: this.contactDetail.phone,
        email: this.contactDetail.email,
      },
      listOfAddresses: this.addressDetails,
      pendingPartyApprovalId: this.item.id,
      partyId: this.selectedCustomer,
    };

    let res;
    console.log(jsonObj);
    res = await this.httpApiService.updateWebsiteParty(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.mapType = "";
      this.findByPendingPartyApprovalId();
    }
  }

  async changeType(type) {
    console.log(type);
    if (type == "new") {
      await this.configService.showToast(
        "error",
        "Please create party in Accusoft first!"
      );
    }
    this.partyType = null;
    this.selectedCustomer = null;
    this.mapType = type;
    this.remarks = "";
  }

  async recjectCustomerApproval() {
    await this.configService.showLoader();
    let res;
    let jsonObj = {
      id: this.item.id,
      remarks: this.remarks,
    };
    console.log(jsonObj);
    res = await this.httpApiService.rejectPartyApproval(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.modalCtrl.dismiss();
    }
  }

  async approveCustomer() {
    if (!!this.kamPersonId) {
      let res;
      let jsonObj = {
        id: this.item.id,
        partyId: this.item.partyId,
        partyContactId: this.item.partyContactId,
        kamPersonId: this.kamPersonId,
      };
      await this.configService.showLoader();
      console.log(jsonObj);
      res = await this.httpApiService.approvePartyApproval(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.modalCtrl.dismiss();
      }
    } else {
      await this.configService.showToast(
        "error",
        "Please add sales person for this Party!"
      );
    }
  }

  async findByPendingPartyApprovalId() {
    await this.configService.showLoader();
    let res;
    res = await this.httpApiService.findByPendingPartyApprovalId(this.item.id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.item = data.data;
      let jsonDetail = JSON.parse(this.item.partyDetailsJson);
      this.partyDetail = jsonDetail.customerDetails;
      this.contactDetail = jsonDetail.general;
      this.docDetails = jsonDetail.userKYC;
      this.addressDetails = jsonDetail.addressDetails;
      this.distributionCenter = jsonDetail.userAccount.distributioncenter;
      this.salesPersonDetails = jsonDetail.userAccount.salesperson;
      if (!!jsonDetail.userAccount.latticeSalesPerson) {
        this.kamPersonId = jsonDetail.userAccount.latticeSalesPerson.id;
      }
    }
  }

  async getAllSalesPerson() {
    await this.configService.showLoader();
    let location = await this.configService.getFromLocation();
    let res = await this.httpApiService.getAllSalesPerson(
      location,
      "Sales Person"
    );
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.salesPersonList = data.data;
      if (!!this.kamPersonId) {
        this.salePersonIdList = this.salesPersonList.filter(
          (x) => x.id == this.kamPersonId
        );
      }
    }
  }

  async searchParty(event: {
    component: IonicSelectableComponent;
    text: string;
  }) {
    let searchText = event.text.trim().toLowerCase();
    await this.getAllCustomer(0, 20, searchText);
  }

  async getAllCustomer(pageNumber, noOfRecords, searchText) {
    let res: any;
    let json = {
      listOfType: ["Local Sales", "Export"],
      kyc: "Y",
    };
    res = await this.httpApiService.findByListOfPartyTypePagination(
      json,
      "",
      pageNumber,
      noOfRecords,
      searchText
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValueList = data.data.content;
      if (!!dataValueList && dataValueList.length > 0) {
        for (let i = 0; i < dataValueList.length; i++) {
          dataValueList[
            i
          ].partyNameWithType = `${dataValueList[i].partyName} ( ${dataValueList[i].type} )`;
        }
        this.listOfCustomers = dataValueList;
      } else {
        this.listOfCustomers = [];
      }
    }
  }

  onChangeParty(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (this.type === "kycCompleted") {
      if (!!event.value) {
        this.selectedCustomer = event.value.id;
      } else {
        this.selectedCustomer = null;
      }
    } else {
      this.partyDetailsNotKYC = event.value;
    }
  }
  saveDetails(e: any) {
    let payload = { 
      refCompanyId: this.contactDetailsNoKyc.userAccount.distributioncenter.refCompanyId || this.contactDetailsNoKyc.userAccount.latticeSalesPerson.id, 
      id: this.item.id, 
      partyDetails: this.partyDetailsNotKYC 
    };
    this.updateKYCparty(payload);
  }

  async updateKYCparty(payload:any) {
    await this.configService.showLoader();
    let res = await this.httpApiService.updateKYCparty(payload);
    let data = res.json();
    await this.configService.dismiss();
    if (data.status == 401) {
      this.authService.logout();
    } else if (data.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (data.status == 400) {
      await this.configService.showToast("error", data.message);
    } else {
      await this.configService.showToast("success", data.message);
      this.modalCtrl.dismiss();
    }
  }
}
