import { Component, Input, OnInit } from "@angular/core";

import { PotentialBuyersService } from "src/app/services/potential-buyers/potential-buyers.service";

@Component({
  selector: "app-potential-buyers-data-card",
  templateUrl: "./potential-buyers-data-card.component.html",
  styleUrls: ["./potential-buyers-data-card.component.scss"],
})
export class PotentialBuyersDataCardComponent implements OnInit {
  @Input() potentialBuyerData = [];

  public selectedConditions = {};

  constructor(private potentialBuyersService: PotentialBuyersService) {}

  ngOnInit() {
    this.potentialBuyersService.currSelectedConditions.subscribe(
      (conditions) => {
        this.selectedConditions = conditions;
      }
    );
  }
}
