import { Component, Input, HostListener } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import * as moment from "moment";
import { LoadingController, ModalController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManageStoneInwardPage } from "../manage-stone-inward/manage-stone-inward.page";
import { ScanRFIDPage } from "../../../components/actions/scan-rfid/scan-rfid.page";
import { exportDataGrid } from 'devextreme/excel_exporter';
import CustomStore from 'devextreme/data/custom_store';
import Query from "devextreme/data/query";

@Component({
  selector: "app-stone-inward",
  templateUrl: "./stone-inward.component.html",
  styleUrls: ["./stone-inward.component.scss"]
})
export class StoneInwardComponent implements ProcessActionComponent {
  @Input() data: any;
  public listOfStoneDataSource: any = {};

  public listOfStoneData: any = [];
  public status: any;
  public pageNumber = 0;
  public noOfRecords = 50;
  public totalPages: number;
  public count: number = 0;
  public userId: any;
  public voucherBookId: any;
  public page = "stoneInward";
  public option = "FACT. O/W NO WISE";
  public columns: any;
  public optionList = [];
  public items = [];
  public boardBaseUrl = '';
  //get inward data;

  public pendingTotalStones = 0;
  public totalStones = 0;
  public pendingTotalCts = 0;
  public totalCts = 0;
  public pendingSingleStones = 0;
  public totalSingleStones = 0;
  public pendingSingleStonesCts = 0;
  public totalSingleStonesCts = 0;
  public pendingMixStones = 0;
  public totalMixStones = 0;
  public pendingMixStonesCts = 0;
  public totalMixStonesCts = 0;
  public pageSize: any = 50;
  //view variable

  public keyFun: any = true;
  public openView: any = false;
  public refreshGrid: Boolean = false;

  constructor(
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) {
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", data);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
      }
      console.log(this.voucherBookId);
    }
  }


  async ngOnInit() {
    await this.getDefaultSetting()
    this.optionList = [{ id: 1, name: 'FACT. O/W NO WISE', isSelected: true }, { id: 2, name: 'SINGLE INWARD', isSelected: false }, { id: 3, name: 'BULK INWARD', isSelected: false }, { id: 4, name: 'PROGRAM WISE', isSelected: false }]
    this.getInwardStatus();
    await this.viewDataByOption(this.optionList);
    this.event.subscribe('Inward', (item) => {
      this.getInwardStatus();
      this.viewDataByOption(this.optionList);
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Inward');
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.keyFun) {
      if (event.keyCode == 86) {
        this.openView = true;
      }
    }
  }

  async closeViewFun() {
    this.openView = false;
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
  }

  async viewDataByOption(optionList) {
    optionList.forEach(el => {
      if (el.isSelected == true) {
        this.option = el.name;
      }
    })
    console.log(this.option)

    if (this.option == 'FACT. O/W NO WISE') {
      this.pageNumber = 0;
      this.noOfRecords = 20;
      this.columns = [
        { name: "Voucher No", prop: "bookSerialNumber" },
        { name: "Inward Date & Time", prop: "createdDate" },
        { name: "Trans Type", prop: "transType" },
        { name: "Inward from", prop: "companyName" },
        { name: "Fact. O/w No.", prop: "factoryOutWNo" },
        { name: "Fact. O/w Date", prop: "factoryOutWDate" },
        { name: "Pending", prop: "" },
        { name: "Pending Ct.", prop: "" }
      ];

      this.getAllPendingStoneInward(this.pageNumber, this.noOfRecords);
    } else if (this.option == 'PROGRAM WISE') {
      this.openView = false
      this.columns = [
        { name: "Program Name", prop: "progName" },
        { name: "Pending", prop: "" },
        { name: "Pending Ct.", prop: "" }
      ];
      this.getAllPendingStoneInwardProgramWise(this.pageNumber, this.noOfRecords);
    }
    else if (this.option == 'SINGLE INWARD') {
      this.openView = false
      this.columns = [
        { name: "StoneId", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "stoneCts" },
        { name: "Clarity", prop: "clarity" },
        { name: "Cut", prop: "cut" },
        { name: "Polish", prop: "polishCode" },
        { name: "Symmetry", prop: "symmetryCode" },
        { name: "Fluorescence", prop: "fluorescence" },
        { name: "Lab", prop: "lab" },
        { name: "Inward Date & Time", prop: "modifiedDate" },
      ];
      this.findIncomingByVoucherTypeAndTransType('SS', this.pageNumber, this.noOfRecords);
    }
    else if (this.option == 'BULK INWARD') {
      this.openView = false
      this.columns = [
        { name: "StoneId", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "stoneCts" },
        { name: "Clarity", prop: "clarity" },
        { name: "Cut", prop: "cut" },
        { name: "Polish", prop: "polishCode" },
        { name: "Symmetry", prop: "symmetryCode" },
        { name: "Fluorescence", prop: "fluorescence" },
        { name: "Inward Date & Time", prop: "modifiedDate" },
      ];
      this.findIncomingByVoucherTypeAndTransType('MIX', this.pageNumber, this.noOfRecords);
    }
  }

  pendingPackets(row) {
    if (!!row.pendingPackets) {
      return row.pendingPackets;
    } else {
      return row.totalPackets;
    }
  }

  pendingCarat(row) {
    if (!!row.pendingCarat) {
      return parseFloat(row.pendingCarat.toFixed(3));
    } else {
      return parseFloat(row.totalCarat.toFixed(3));
    }
  }

  async getInwardStatus() {
    let res: any;
    res = await this.httpApiService.getInwardStatus(this.data.voucherType);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        if (data.data) {
          let dataValue = data.data;
          this.pendingTotalStones = dataValue.pendingTotalStones;
          this.totalStones = dataValue.totalStones;
          this.pendingTotalCts = dataValue.pendingTotalCts ? dataValue.pendingTotalCts : 0.00;
          this.totalCts = dataValue.totalCts ? dataValue.totalCts : 0.00;
          this.pendingSingleStones = dataValue.pendingSingleStones;
          this.totalSingleStones = dataValue.totalSingleStones;
          this.pendingSingleStonesCts = dataValue.pendingSingleStonesCts ? dataValue.pendingSingleStonesCts : 0.00;
          this.totalSingleStonesCts = dataValue.totalSingleStonesCts ? dataValue.totalSingleStonesCts : 0.00;
          this.pendingMixStones = dataValue.pendingMixStones;
          this.totalMixStones = dataValue.totalMixStones;
          this.pendingMixStonesCts = dataValue.pendingMixStonesCts ? dataValue.pendingMixStonesCts : 0.00;
          this.totalMixStonesCts = dataValue.totalMixStonesCts ? dataValue.totalMixStonesCts : 0.00;


        }
      }
    }
  }

  async getAllPendingStoneInward(pageNumber, noOfRecords) {
    this.listOfStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getAllPendingStoneInward(
            this.data.voucherType,
            this.pageNumber,
            this.noOfRecords,
            this.voucherBookId
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfStoneData = data.data.content;
            this.setItems();
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfStoneData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  setItems() {
    this.items = this.listOfStoneData;
    this.count = this.listOfStoneData.length;
  }

  async getAllPendingStoneInwardProgramWise(pageNumber, noOfRecords) {
    this.listOfStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          // loadOptions.take = this.noOfRecords;
          // this.pageNumber = loadOptions.skip / loadOptions.take;
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getAllPendingStonesProgramWise(
            this.data.voucherType,
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfStoneData = data.data.content;
            this.setItems();
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfStoneData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async findIncomingByVoucherTypeAndTransType(type, pageNumber, noOfRecords) {
    this.listOfStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          // loadOptions.take = this.noOfRecords;
          // loadOptions.sort = { selector: "createdDate", desc: true };
          // this.pageNumber = loadOptions.skip / loadOptions.take;

          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          loadOptions.sort = { selector: "createdDate", desc: true };
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findIncomingByVoucherTypeAndTransType(
            this.pageNumber,
            this.noOfRecords,
            this.data.voucherType,
            type
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfStoneData = data.data.content;
            this.setItems();
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfStoneData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async openConfirmationModal(item) {
    this.refreshGrid = false;
    if (item.type == "mouseenter") {
      //Stop event propagation and let onSelect() work
      item.event.stopPropagation();
    } else {
      if (!this.openView && (this.option == 'FACT. O/W NO WISE' || this.option == 'PROGRAM WISE')) {
        this.keyFun = false;
        this.status = item.row.id;
        const modal = await this.modalCtrl.create({
          component: ManageStoneInwardPage,
          backdropDismiss: false,
          componentProps: {
            data: item.row,
            option: this.option
          },
          cssClass: 'requestPage'
        });
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      } else {
        this.keyFun = true;
      }
    }
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    this.keyFun = true;
    if (d.data == 1) {
      this.getInwardStatus();
      this.status = null;
      if (this.option == 'FACT. O/W NO WISE') {
        this.getAllPendingStoneInward(this.pageNumber, this.noOfRecords);
      }
      else if (this.option == 'PROGRAM WISE') {
        this.getAllPendingStoneInwardProgramWise(this.pageNumber, this.noOfRecords)
      }
    } else {

    }

  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    if (this.option == 'FACT. O/W NO WISE') {
      this.getAllPendingStoneInward(currentPage.offset, this.noOfRecords);
    }
    else if (this.option == 'PROGRAM WISE') {
      this.getAllPendingStoneInwardProgramWise(currentPage.offset, this.noOfRecords)
    }
    else if (this.option == 'SINGLE INWARD') {
      this.findIncomingByVoucherTypeAndTransType('SS', currentPage.offset, this.noOfRecords)
    }
    else if (this.option == 'BULK INWARD') {
      this.findIncomingByVoucherTypeAndTransType('MIX', currentPage.offset, this.noOfRecords)
    }
  }

  async openScanModel() {
    if (!this.openView) {
      this.keyFun = false;
      const modal = await this.modalCtrl.create({
        component: ScanRFIDPage,
        backdropDismiss: false,
        componentProps: {
          type: "receive",
          skipAssortment: true,
          page: "Inward"
        },
        cssClass: "largePage"
      });
      modal.onDidDismiss().then((d: any) => this.handleScanModalDismiss(d));
      return await modal.present();
    } else {
      this.keyFun = true;
    }
  }



  async handleScanModalDismiss(d) {
    this.getInwardStatus();
    this.keyFun = true;
    this.status = null;
    if (this.option == 'FACT. O/W NO WISE') {
      this.getAllPendingStoneInward(this.pageNumber, this.noOfRecords);
    } else if (this.option == 'PROGRAM WISE') {
      this.getAllPendingStoneInwardProgramWise(this.pageNumber, this.noOfRecords)
    } else if (this.option == 'SINGLE INWARD') {
      this.findIncomingByVoucherTypeAndTransType('SS', this.pageNumber, this.noOfRecords)
    } else if (this.option == 'BULK INWARD') {
      this.findIncomingByVoucherTypeAndTransType('MIX', this.pageNumber, this.noOfRecords)
    }
    if (!!this.boardBaseUrl) {
      console.log(this.boardBaseUrl)
      this.httpApiService.scanLedOff(this.boardBaseUrl, []);
    }
  }



}
