import { Component, Input } from "@angular/core";
import { ModalController, NavParams, Events, LoadingController, AlertController } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManageLabServicesPage } from "../../actions/manage-lab-services/manage-lab-services.page";

@Component({
  selector: 'app-lab-services',
  templateUrl: './lab-services.component.html',
  styleUrls: ['./lab-services.component.scss']
})
export class LabServicesComponent implements ProcessActionComponent {
  @Input() data: any;

  public listOfLabs: any;
  public selectedLab = "";
  public items: any;
  public itemsService: any;
  public arrowKeyLocation = -1;
  public arrowKeyLocationAction = -1;
  public manageSelectedLab = "";
  public listOfServices = []
  public description: "";
  public flagButton = "";
  public labName = "";
  public id: any;
  public searchServices: string = "";
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public roleId: any;
  public labId: any;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public event: Events
  ) { }


  ngOnInit() {
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    this.getAllLabs();
    this.event.subscribe('Lab Services', (item) => {
      this.getAllLabs();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Lab Services');
  }

  async getAllLabs() {
    let res: any;
    res = await this.httpApiService.getStoneMasterDetailByName('Lab');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLabs = data.data;
      this.setItems();
      console.log("listOfLabs res", res, this.listOfLabs);
    }
  }

  setItems() {
    this.items = this.listOfLabs;
  }

  setService() {
    this.itemsService = this.listOfServices;
  }

  selectLab(ev, item) {
    this.selectedLab = item.name;
    console.log("this.selectedLab", this.selectedLab);
    this.searchLab(item.name);
    // this.items = [];
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        console.log("item", item, typeof item);
        return item.name.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocation = -1;
    } else {
      this.arrowKeyLocation = -1;
      this.items = this.listOfLabs;
    }
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedLab = this.items[
              this.arrowKeyLocation
            ].name;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedLab = this.items[
              this.arrowKeyLocation
            ].name;
            break;
          } else {
            break;
          }
        }
    }
    console.log(this.arrowKeyLocation);
  }

  async filterServices(ev: any) {
    this.setService();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.itemsService = await this.itemsService.filter(function (item) {
        console.log("item", item, typeof item);
        if (item.service && item.service.toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (item.serviceDescription && item.serviceDescription.toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (item.remark && item.remark.toLowerCase().includes(val.toLowerCase())) {
          return true;
        }
      });
      this.arrowKeyLocationAction = -1;
    } else {
      this.arrowKeyLocationAction = -1;
      await this.setService();
      // this.itemsService = [];
    }
  }

  async searchLab(labName) {
    await this.configService.showLoader();
    let tmpLabName = "";
    this.items = this.listOfLabs;
    if (!!labName) {
      tmpLabName = labName;
      this.selectedLab = labName;
      this.manageSelectedLab = labName;
    } else {
      this.selectedLab = this.manageSelectedLab;
      tmpLabName = this.manageSelectedLab;
    }
    console.log("labName", labName, tmpLabName);
    let selectedLabId = this.items.filter(function (search) {
      if (search.name == tmpLabName) {
        return search.id;
      }
    });
    console.log("selectedLabId", selectedLabId);
    if (selectedLabId.length > 0) {
      this.labId = selectedLabId[0].id;
      let res: any;
      res = await this.httpApiService.getAllAdditionalServicesByLabId(this.labId);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        console.log("res", res, data);
        this.listOfServices = data.data;
        this.setService();
        // await this.filterJson(roleData);
        let index = this.items.findIndex(
          x => x.name == selectedLabId[0].name
        );
        this.arrowKeyLocation = index;
        console.log(
          "this.listOfActions",
          this.selectedLab
        );
      }
    } else {
      await this.configService.dismiss();
      this.selectedLab = "";
      this.labName = "";
      this.description = "";
      this.listOfServices = [];
    }
  }


  async manageLabService(item, flag) {
    const modal = await this.modalCtrl.create({
      component: ManageLabServicesPage,
      backdropDismiss: false,
      componentProps: { item: item, flagModal: flag, labId: this.labId }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }


  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      this.getAllLabs();
      this.searchLab(this.selectedLab);
    }
  }

  async confirmDeleteService(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this service ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteService(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteService(item) {
    let res = await this.httpApiService.deleteLabService(
      item.id,
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("delete role permission response", data);
      this.searchLab(this.selectedLab);
      await this.configService.showToast("success", data.message);
      this.searchServices = "";
    }
  }

}
