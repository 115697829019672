import { Component, OnInit, Input } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { SearchService } from "src/app/services/search/search.service";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-diamond-security-check',
  templateUrl: './diamond-security-check.component.html',
  styleUrls: ['./diamond-security-check.component.scss']
})
export class DiamondSecurityCheckComponent implements ProcessActionComponent {
  @Input() data: any;

  public RFidList: any = "";
  public type: any;
  public userId: any;
  public toUser: any;
  public toRole: any;
  public listOfUsers: any = [];
  public filtered: any = [];
  public count: number = 0;
  public matchCount: number = 0;
  public arr1: any;
  public arr2: any;
  public listOfStone: any;
  public totalRfidCount: any;
  public boardBaseUrl: any;
  public columns: any = [];
  public usersList = []
  public listOfSecurityType = [{
    id: 1, name: 'Smart-I'
  }, {
    id: 2, name: 'Inscription'
  }]
  public securityType = '';
  public typeIdList = [];
  public userIdList = [];
  public stonesList = [];
  public selected = [];
  public stoneId = '';
  public listOfSecurityLogsDataSource: any = {};
  public listOfLogs = [];
  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 50;
  public historyColumns = []

  constructor(private es: SearchService,
    public httpApiService: HttpApiService,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events) { }

  ngOnInit() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, {
      name: 'Stone Id', prop: 'stoneName'
    },
    { name: 'Color', prop: 'ColorCode' },
    { name: 'Clarity', prop: 'ClarityCode' },
    { name: 'Shape', prop: 'ShapeCode' },
    { name: 'Size', prop: 'cts' },
    { name: 'Lab', prop: 'lab' }
    ]

    this.historyColumns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, {
      name: 'Stone Id', prop: 'stoneName'
    },
    {
      name: 'User', prop: 'fullName'
    },
    {
      name: 'Security Type', prop: 'securityType'
    },
    {
      name: 'Created By', prop: 'createdBy'
    },
    {
      name: 'Created Date', prop: 'createdDate'
    }
    ]
    this.getUserData();
    this.getAllUsers()
    this.event.subscribe('Diamond Security Check', (item) => {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      }, {
        name: 'Stone Id', prop: 'stoneName'
      },
      { name: 'Color', prop: 'ColorCode' },
      { name: 'Clarity', prop: 'ClarityCode' },
      { name: 'Shape', prop: 'ShapeCode' },
      { name: 'Size', prop: 'cts' },
      { name: 'Lab', prop: 'lab' },
      { name: 'P Price Available', prop: 'isPPricePresent' },
      { name: 'Rapaport', prop: 'RAPAPORTpercarat' },
      ]
      this.RFidList = ''
      this.filtered = [];
      this.getAllUsers()
      this.historyColumns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      }, {
        name: 'Stone Id', prop: 'stoneName'
      },
      {
        name: 'User', prop: 'fullName'
      },
      {
        name: 'Security Type', prop: 'securityType'
      },
      {
        name: 'Created By', prop: 'createdBy'
      },
      {
        name: 'Created Date', prop: 'createdDate'
      }
      ]
    })

    this.getUserData();
  }

  ngOnDestroy() {
    this.event.unsubscribe('Diamond Security Check')
  }

  async getAllUsers() {
    let res: any;
    let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllUser(fromLocation);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.usersList = data.data;;
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "user selection") {
      if (idList.length > 0) {
        this.userId = idList[0];
      } else {
        this.userId = null;
      }
    } else if (property == "type selection") {
      if (idList.length > 0) {
        this.securityType = obj[0].name;
      } else {
        this.securityType = null;
      }
    }
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
  }

  async scanHere() {
    this.RFidList = ''
    this.filtered = [];
    this.matchCount = 0;
    this.count = 0
    let res;
    if (!!this.boardBaseUrl) {
      await this.configService.showLoader();
      await this.httpApiService.scanLedOff(this.boardBaseUrl, [1]);
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      console.log(data);
      if (data.type == "error") {
        this.configService.dismiss();
        await this.configService.showToast("error", "Scanner Not Found");
        if (
          !!this.RFidList
        ) {
          this.arr1 = this.RFidList.split("\n");
          this.count = this.arr1.length;
        } else {
          this.count = 0;
          this.RFidList = "";
        }
      } else {
        if (data.result.length > 0) {
          if (
            !!this.RFidList
          ) {
            this.arr1 = this.RFidList.split("\n");
            let data1 = await this.merge_array(this.arr1, data.result);
            data1 = data1.filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            })
            this.count = data1.length;
            this.RFidList = data1.join("\n");
          } else {
            data.result = data.result.filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            })
            this.count = data.result.length;
            this.RFidList = data.result.join("\n");
          }
          this.fetch()
          await this.configService.showToast("success", `Scan Completed successfully`);
        } else {
          this.fetch()
          if (
            !!this.RFidList
          ) {
            this.arr1 = this.RFidList.split("\n");
            this.count = this.arr1.length;
          } else {
            this.count = 0;
            this.RFidList = "";
          }
        }
        this.configService.dismiss();
      }
    } else {
      await this.configService.showToast("error", "Scanner Board Url not found");
    }
  }

  async fetch() {
    this.listOfLogs = []
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    let qry: any =
    {
      "query": {
        "bool": {
          "should": [
            {
              "simple_query_string": {
                "query": ""
              }
            },
            {
              "bool": {
                "must": []
              }
            }
          ]
        }
      }
    }
    if (!!this.filtered) {
      let boolShouldMultiple = {
        "bool": {
          "should": []
        }
      };

      let sData = {
        terms: {
          "stoneName": this.filtered
        }
      }
      let rfidData = {
        terms: {
          "rfid": this.filtered
        }
      }
      boolShouldMultiple.bool.should.push(sData);
      boolShouldMultiple.bool.should.push(rfidData);
      qry.query.bool.should[1].bool.must.push(boolShouldMultiple);
      let data = await this.es.getPaginatedDocuments(qry, 0, environment.INDEX, '', 1)
      data = data.hits.hits.map(d => d._source).map(res => {
        let stoneId = res.stoneName;
        if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
          let index = stoneId.split('/', 3).join('/').length;
          res['stoneName'] = String(stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))));
        } else {
          res['stoneName'] = String(res['stoneName']);
        }
        return res;
      })

      this.stonesList = data;
    }
  }


  async merge_array(array1, array2) {
    let result_array = [];
    let arr = array1.concat(array2);
    let len = arr.length;
    let assoc = {};

    while (len--) {
      let item = arr[len];
      if (!assoc[item]) {
        result_array.unshift(item);
        assoc[item] = true;
      }
    }
    return result_array;
  }

  async OnSelectRows(event) {
    this.selected = event.selectedRowsData
  }


  async saveDetail() {
    let formData: any = [];
    this.configService.showLoader()
    this.selected.forEach((el, i) => {
      formData.push({
        "stoneId": el.id,
        "securityType": this.securityType,
        "userId": this.userId
      })
    });
    let res: any;
    res = await this.httpApiService.saveSecurityCheckResult(
      { listOfStones: formData }
    );
    let data = res.json();
    this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.reset()
    }
  }

  async fetchHistory() {

    this.listOfSecurityLogsDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findLogsForSecurityCheck(this.RFidList, this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfLogs = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalCount,
            };
          }
        } else {
          return {
            data: this.listOfLogs,
            totalCount: this.count,
          }
        }
      }
    });
  }

  reset() {
    this.selected = [];
    this.stonesList = [];
    this.userId = null;
    this.securityType = '';
    this.userIdList = [];
    this.typeIdList = [];
  }
}
