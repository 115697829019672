import { Component, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-stones-with-me",
  templateUrl: "./stones-with-me.component.html",
  styleUrls: ["./stones-with-me.component.scss"]
})
export class StonesWithMeComponent implements ProcessActionComponent {
  @Input() data: any;
  public query: any;
  public disable: any = [];
  public cart = false;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public event: Events
  ) { }

  async ngOnInit() {
    await this.getUserDetails();
  }

  async getUserDetails() {
    let userData = await this.authService.userData();
    console.log("userData", userData);
    let res: any;
    res = await this.httpApiService.getUserDetailById(userData.userId);
    console.log("res", res);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let userData = data.data;
      // this.query = { facetFilters: ['username:Mohammad Ali'] };
      let name = [];
      name.push(userData.fullName);
      this.disable.push("username");
      this.query = {
        facetFilters: ["username:" + userData.fullName + ""],
        disjunctiveFacetsRefinements: { username: name }
      };
      // this.query = { disjunctiveFacetsRefinements: {'username': name}, enablePersonalization: false, attributesToRetrieve: ["username"] };
    }
  }
}
