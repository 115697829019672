import { Component, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-stone-with-me-mix',
  templateUrl: './stone-with-me-mix.component.html',
  styleUrls: ['./stone-with-me-mix.component.scss']
})
export class StoneWithMeMixComponent implements ProcessActionComponent {
  @Input() data: any;
  public query: any;
  public type = "MIX";
  public disable: any = [];
  public cart = true;
  public withMe: Boolean = true;
  public department = ["Sales", "KAM Sales"];
  public default = {
    criteria: true,
    search: true,
    color: true,
    shape: true,
    size: true,
    clarity: true,
    internalStatus: true,
    download: true,
    colorOverride: true,
    clarityOverride: true,
    party: true,
    certiDate: false,
    transactionDate: true,
    standardShapeCode: true
  };

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public event: Events
  ) { }

  ngOnInit() {
    let transType = [];
    let departmentName = [];
    transType.push(this.type)
    departmentName = ["Sales", "KAM Sales"];
    this.disable.push("username");
    this.disable.push("transType");
    this.query = {
      disjunctiveFacetsRefinements: { transType: transType, department: departmentName, }
    };
  }

}
