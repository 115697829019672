import { Component, OnInit, Input } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ModalController, LoadingController } from "@ionic/angular";
import * as moment from "moment";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";

@Component({
  selector: "app-transfer-stone",
  templateUrl: "./transfer-stone.component.html",
  styleUrls: ["./transfer-stone.component.scss"]
})
export class TransferStoneComponent implements OnInit {
  @Input() process: any;
  @Input() actionId: any;
  @Input() transType: any;
  @Input() issueWithType: any;
  @Input() stoneList: any;
  @Input() skipAssortment: any;
  @Input() page: any;

  public RFidList: any = "";
  public type: any;
  public userId: any;
  public toUser: any;
  public toRole: any;
  public listOfUsers: any = [];
  public filtered: any = [];
  public count: number = 0;
  public matchCount: number = 0;
  public voucherBookId: any;
  public timer = 10;
  public arr1: any;
  public arr2: any;
  public errorMsg = null;
  public errorMsgList = [];
  public successMsg = null;
  public msgStatus = false;
  public setOfStoneIds: any = [];
  public listOfStone: any;
  public totalRfidCount: any;
  public boardBaseUrl: any;
  public fromRole: any;
  public errorStoneIdList = [];
  public columns: any = [];
  public scannedStoneList: any = [];
  public boxDetail: any = [];
  public stoneNotFoundRfidList = [];
  public companyId: any;
  public toDepartment: any;
  constructor(
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    private authService: AuthenticationService,
    public exportAsExcelService: ExportAsExcelService,
  ) {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, {
      name: 'Stone Id', prop: 'stoneName'
    },
    { name: 'RFID', prop: 'paramValue' },
    { name: 'Is Matched with stock', prop: 'isMatched' }
    ]
  }

  async ngOnInit() {
    await this.getUserData();
    this.companyId = await this.authService.getCompanyId();
    console.log(this.companyId)
    console.log(this.issueWithType, this.process);
    if (this.process == "issue" || this.process == "issueWithRole") {
      this.getDefaultSetting(null);
    } else if (this.process == 'return' || this.process == "issueWithTransferToAssortment") {
      this.getAllActions()
    } else if (this.process == "scanAndLedOn") {
      console.log(this.stoneList)
      this.RFidList = this.stoneList.join("\n");
      this.filtered = this.stoneList;
      if (this.filtered.length > 0) {
        this.count = this.filtered.length;
      } else {
        this.count = 0;
      }
    }
  }

  async getAllActions() {
    await this.configService.showLoader()
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getAllActionsByUserID(userData.userId);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let listOfActions = data.data;
      console.log("list of actions by http", res, listOfActions);
      if (this.process == 'return') {
        let actionArray = listOfActions.filter(function (item) {
          if (item.actionName == "Return") {
            return item.actionName;
          }
        });
        if (actionArray.length > 0) {
          this.getDefaultSetting(actionArray[0].id);
        }
      }
      if (this.process == 'issueWithTransferToAssortment') {
        let actionArray = listOfActions.filter(function (item) {
          if (item.actionName == "Single Transfer To Assortment") {
            return item.actionName;
          }
        });
        if (actionArray.length > 0) {
          this.getDefaultSetting(actionArray[0].id);
        }
      }
    }
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
  }

  async getDefaultSetting(actionId) {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      !!actionId ? actionId : this.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
        if (!!dataValue.map.toUser) {
          this.toUser = dataValue.map.toUser
        }
        if (!!dataValue.map.fromRole) {
          this.fromRole = dataValue.map.fromRole
        }
        if (!!dataValue.map.toDepartment) {
          this.toDepartment = dataValue.map.toDepartment
        }
      }
    }
  }

  async scanHere() {
    this.RFidList = ''
    this.filtered = [];
    this.matchCount = 0;
    this.count = 0
    this.stoneNotFoundRfidList = []
    this.boxDetail = null;
    let res;
    if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
      await this.configService.showLoader();
      await this.httpApiService.scanLedOff(this.boardBaseUrl, [1]);
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      console.log(data);
      if (data.type == "error") {
        this.configService.dismiss();
        await this.configService.showToast("error", "Scanner Not Found");
        if (
          !!this.RFidList
        ) {
          this.arr1 = this.RFidList.split("\n");
          this.count = this.arr1.length;
        } else {
          this.count = 0;
          this.RFidList = "";
        }
      } else {
        if (data.result.length > 0) {
          if (
            !!this.RFidList
          ) {
            this.arr1 = this.RFidList.split("\n");
            let data1 = await this.merge_array(this.arr1, data.result);
            data1 = data1.filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            })
            this.count = data1.length;
            //get stoneid using refid
            this.RFidList = data1.join("\n");

            if (this.page == 'Inward') {
              let jsonObj;
              jsonObj = {
                "paramName": "rfid",
                setOfValues: data1,
                companyId: this.companyId
              }
              let res2 = await this.httpApiService.getStoneIdByRfid(jsonObj);
              let resData = res2.json();

              this.boxDetail = resData.data.boxRfIdsList;
              if (this.boxDetail.length > 0) {
                this.count = this.count - this.boxDetail.length;
                console.log(this.count, "count")
                let rfidList = this.RFidList.split("\n");

                data.result = rfidList.filter(rf =>
                  this.boxDetail.filter(bd => bd.rfid == rf).length == 0
                )
                this.RFidList = data.result.join("\n")
                console.log("this.RFidList", data.result, this.RFidList)
              }
              console.log("this.RFidList", this.RFidList)
              // if (resData.data.list.length > 0) {
              let res3 = await this.httpApiService.findPendingToReceiveCount(jsonObj);
              let responseData = res3.json();
              this.scannedStoneList = responseData.data
              if (!!responseData.data && responseData.data.length > 0) {
                responseData.data.filter(el => {
                  if (!!el.isMatched) {
                    ++this.matchCount
                  }
                  if (!el.isMatched && !!el.paramValue) {
                    this.stoneNotFoundRfidList.push(el.paramValue)
                  }
                })
                data.result.filter(el => {
                  let isExist = responseData.data.find(item => el == (!!item.paramValue ? item.paramValue : null))
                  if (!isExist) {
                    this.stoneNotFoundRfidList.push(el)
                  }
                })
                if (this.boxDetail.length > 0) {
                  this.stoneNotFoundRfidList = this.stoneNotFoundRfidList.filter(rf =>
                    this.boxDetail.filter(bd => bd.rfid == rf).length == 0
                  )
                }
                if (this.stoneNotFoundRfidList.length > 0) {
                  await this.httpApiService.scanWithLightOn(this.boardBaseUrl, this.stoneNotFoundRfidList);
                }
              } else {
                await this.httpApiService.scanWithLightOn(this.boardBaseUrl, data.result);
              }
              this.configService.dismiss();
              // }
              //  else {
              //   if (resData.type == "error") {
              //     this.configService.dismiss();
              //     await this.configService.showToast("error", data.type);
              //   }
              // }
            }
          } else {
            data.result = data.result.filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            })
            this.count = data.result.length;
            console.log(this.count, "count")
            this.RFidList = data.result.join("\n");
            //get stoneid using refid
            if (this.page == 'Inward') {
              let jsonObj;
              jsonObj = {
                "paramName": "rfid",
                setOfValues: data.result,
                companyId: this.companyId
              }
              let res2 = await this.httpApiService.getStoneIdByRfid(jsonObj);
              let resData = res2.json();
              console.log("getStoneIdByRfid -new", resData)
              this.boxDetail = resData.data.boxRfIdsList;
              if (this.boxDetail.length > 0) {
                this.count = this.count - this.boxDetail.length;
                console.log(this.count, "count")
                let rfidList = this.RFidList.split("\n");

                data.result = rfidList.filter(rf =>
                  this.boxDetail.filter(bd => bd.rfid == rf).length == 0
                )
                this.RFidList = data.result.join("\n")

                console.log("this.RFidList", data.result, this.RFidList)
              }
              // if (resData.data.list.length > 0) {
              if (this.actionId) {
                let res3 = await this.httpApiService.findPendingToReceiveCount(jsonObj);
                let responseData = res3.json();
                this.scannedStoneList = responseData.data
                console.log("responseData", responseData)
                if (!!responseData.data && responseData.data.length > 0) {
                  responseData.data.filter(el => {
                    if (!!el.isMatched) {
                      ++this.matchCount
                    }
                    if (!el.isMatched && !!el.paramValue) {
                      this.stoneNotFoundRfidList.push(el.paramValue)
                    }

                  })
                  data.result.filter(el => {
                    let isExist = responseData.data.find(item => el == (!!item.paramValue ? item.paramValue : null))
                    if (!isExist) {
                      this.stoneNotFoundRfidList.push(el)
                    }
                  })
                  if (this.boxDetail.length > 0) {
                    this.stoneNotFoundRfidList = this.stoneNotFoundRfidList.filter(rf =>
                      this.boxDetail.filter(bd => bd.rfid == rf).length == 0
                    )
                  }
                  console.log(this.stoneNotFoundRfidList)
                  if (this.stoneNotFoundRfidList.length > 0) {
                    await this.httpApiService.scanWithLightOn(this.boardBaseUrl, this.stoneNotFoundRfidList);
                  }
                } else {
                  await this.httpApiService.scanWithLightOn(this.boardBaseUrl, data.result);
                }
              }
              this.configService.dismiss();
              // } else {
              //   if (resData.type == "error") {
              //     this.configService.dismiss();
              //     await this.configService.showToast("error", data.type);
              //   }
              // }
            }
          }
          await this.configService.showToast("success", `Scan Completed successfully`);
        } else {
          if (
            !!this.RFidList
          ) {
            this.arr1 = this.RFidList.split("\n");
            this.count = this.arr1.length;
          } else {
            this.count = 0;
            this.RFidList = "";
          }
        }
        this.configService.dismiss();
      }
    } else {
      await this.configService.showToast("error", "Scanner Board Url not found");
    }
  }

  async merge_array(array1, array2) {
    let result_array = [];
    let arr = array1.concat(array2);
    let len = arr.length;
    let assoc = {};

    while (len--) {
      let item = arr[len];
      if (!assoc[item]) {
        result_array.unshift(item);
        assoc[item] = true;
      }
    }
    return result_array;
  }

  async issueStones() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    console.log(this.filtered)
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let jsonObj;
      let res: any;
      console.log(this.voucherBookId);
      if (this.issueWithType == "YES") {
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          setOfValues: this.filtered,
          voucherBookId: this.voucherBookId,
          toUser: this.toUser,
          transType: this.transType,
          toDepartmentId: this.toDepartment,
          companyId: this.companyId
        };
      } else {
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          setOfValues: this.filtered,
          voucherBookId: this.voucherBookId,
          toDepartmentId: this.toDepartment,
          toUser: this.toUser,
          companyId: this.companyId
        };
      }
      console.log("dataObj", jsonObj);
      res = await this.httpApiService.stoneIssue(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (!!data.data && data.data.length > 0) {
          await this.configService.showToast("error", data.message);
          // let excelData = [];
          // data.data.forEach(el => {
          //   if (el.StoneId) {
          //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
          //   }
          // })
          await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        } else {
          await this.configService.showToast("error", 'Error occoured while transferring stones.');
        }
        // if (data.data) {
        //   this.errorMsgList = data.data;
        //   for (let i = 0; i < this.errorMsgList.length; i++) {
        //     this.errorMsgList[i].ids = this.errorMsgList[i].StoneId.join(", ");
        //   }
        //   console.log(this.errorMsgList)
        //   this.errorMsg = data.message
        //   this.msgStatus = true;
        // } else {
        //   await this.configService.showToast("error", data.error);
        // }
      } else {
        this.RFidList = ""
        this.filtered = []
        let stoneCount = data.data.content.length > 0 ? data.data.content[0].StoneId.length : ''
        await this.configService.showToast("success", `${stoneCount} stones transferred successfully`);
      }
    }
  }

  async issueStonesWithRole() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    console.log(this.filtered)
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let jsonObj;
      let res: any;
      console.log(this.voucherBookId);
      if (this.issueWithType == "YES") {
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          setOfValues: this.filtered,
          voucherBookId: this.voucherBookId,
          toRole: this.toRole,
          fromRole: this.fromRole,
          toDepartmentId: this.toDepartment,
          transType: this.transType,
          companyId: this.companyId
        };
      } else {
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          setOfValues: this.filtered,
          toRole: this.toRole,
          voucherBookId: this.voucherBookId,
          toDepartmentId: this.toDepartment,
          toUser: this.toUser,
          companyId: this.companyId
        };
      }

      console.log("dataObj", jsonObj);
      res = await this.httpApiService.stoneIssue(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (!!data.data && data.data.length > 0) {
          await this.configService.showToast("error", data.message);
          // let excelData = [];
          // data.data.forEach(el => {
          //   if (el.StoneId) {
          //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
          //   }
          // })
          await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        } else {
          await this.configService.showToast("error", 'Error occoured while transferring stones.');
        }
        // if (data.data) {
        //   this.errorMsgList = data.data;
        //   for (let i = 0; i < this.errorMsgList.length; i++) {
        //     this.errorMsgList[i].ids = this.errorMsgList[i].StoneId.join(", ");
        //   }
        //   console.log(this.errorMsgList)
        //   this.errorMsg = data.message
        //   this.msgStatus = true;
        // } else {
        //   await this.configService.showToast("error", data.error);
        // }
      } else {
        this.RFidList = ""
        this.filtered = []
        let stoneCount = data.data.content.length > 0 ? data.data.content[0].StoneId.length : ''
        await this.configService.showToast("success", `${stoneCount} stones transferred successfully`);
      }
    }
  }

  async saveRfId() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    let validate = await this.validateControls();
    if (!validate) {
      let res: any;
      await this.configService.showLoader();
      let jsonObj = {
        setOfValues: this.filtered,
        voucherDate: moment().format("YYYY-MM-DD"),
        companyId: this.companyId
      };
      if (!!this.skipAssortment) {
        jsonObj['skipAssortment'] = true;
      }
      res = await this.httpApiService.receivePendingStoneConfirmation(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (!!data.data && data.data.length > 0) {
          await this.configService.showToast("error", data.message);
          // let excelData = [];
          // data.data.forEach(el => {
          //   if (el.StoneId) {
          //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
          //   }
          // })
          await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        } else {
          await this.configService.showToast("error", 'Error occoured while receiving stones.');
        }
      } else {
        this.RFidList = ""
        this.scannedStoneList = []
        this.filtered = []
        await this.configService.showToast("success", `stones received successfully.`);
      }
    }
  }

  async closeDiv() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
    if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
      await this.httpApiService.scanLedOff(this.boardBaseUrl, this.stoneNotFoundRfidList);
    }
  }

  async validateControls() {
    if (this.filtered.length > 0) {
      return false;
    } else {
      await this.configService.showToast("error", "No value found!");
      return true;
    }
  }

  async findByLED() {
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    console.log(this.filtered)
    if (this.filtered.length > 0) {
      if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
        await this.configService.showLoader();
        let res: any;
        res = await this.httpApiService.scanWithLightOn(this.boardBaseUrl, this.filtered);
        console.log(res);
        // let data = res.json();
        console.log("findBy LED", res);
        this.configService.dismiss();
      }
      else {
        await this.configService.showToast("error", "Scanner Board Url not found");
      }
    } else {
      await this.configService.showToast("error", "Please add stone!");
    }
  }

  async LedOff() {
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.scanLedOff(this.boardBaseUrl, this.stoneNotFoundRfidList.length > 0 ? this.stoneNotFoundRfidList : this.filtered);
      console.log(res);
      await this.loadingController.dismiss()
    } else {
      await this.configService.showToast("error", "Scanner Board Url not found");
    }
  }

  async getStoneDetail() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    let validate = await this.validateControls();
    if (!validate) {
      let res: any;
      await this.configService.showLoader();
      let jsonObj = {
        setOfValues: this.filtered
      };
      res = await this.httpApiService.AssortmentIssue(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        console.log(this.errorMsgList)
        // await this.configService.showToast("error", data.error);
        // if (data.data) {
        //   this.errorMsgList = data.data;
        //   for (let i = 0; i < data.data.length; i++) {
        //     this.errorMsgList[i].ids = data.data[i].StoneId.join(", ");
        //   }
        //   console.log(this.errorMsgList)
        //   this.errorMsg = data.message
        //   this.msgStatus = true;
        // } else {
        //   await this.configService.showToast("error", data.error);
        // }
        if (!!data.data && data.data.length > 0) {
          await this.configService.showToast("error", data.message);
          // let excelData = [];
          // data.data.forEach(el => {
          //   if (el.StoneId) {
          //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
          //   }
          // })
          await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        } else {
          await this.configService.showToast("error", 'Error occoured while transferring stones.');
        }
      } else {
        console.log(data.data)
        let array = data.data;
        if (array.length > 0) {
          this.modalCtrl.dismiss(data.data);
        } else {
          await this.configService.showToast("error", 'No Stones found for assortment.');
        }
        // let array = [{ "id": 1, "stoneId": "WLSN-124", "stoneCts": "0.3", "transType": "SS", "isSelected": true },
        // { "id": 2, "stoneId": "WLSN-144", "stoneCts": "0.23", "transType": "SS", "isSelected": true },
        // { "id": 3, "stoneId": "WLSN-164", "stoneCts": "0.45", "transType": "SS", "isSelected": true }]

      }
    }
  }

  async updateParameterDetails() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
  }

  // return stone

  async returnStones() {
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let dataObj = {
        setOfValues: this.filtered,
        toRole: this.toRole,
        voucherBookId: this.voucherBookId,
        voucherDate: moment().format('YYYY-MM-DD'),
        companyId: this.companyId
      };
      let res = await this.httpApiService.returnAndReceive(dataObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (!!data.data && data.data.length > 0) {
          await this.configService.showToast("error", data.message);
          // let excelData = [];
          // data.data.forEach(el => {
          //   if (el.StoneId) {
          //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
          //   }
          // })
          await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        } else {
          await this.configService.showToast("error", 'Error occoured while transferring stones.');
        }
        // if (data.data) {
        //   this.errorMsgList = data.data;
        //   for (let i = 0; i < this.errorMsgList.length; i++) {
        //     this.errorMsgList[i].ids = this.errorMsgList[i].StoneId.join(", ");
        //   }
        //   console.log(this.errorMsgList)
        //   this.errorMsg = data.message
        //   this.msgStatus = true;
        // } else {
        //   await this.configService.showToast("error", data.error);
        // }
      } else {
        this.RFidList = ""
        this.filtered = []
        let stoneCount = data.data.length > 0 ? data.data[0].StoneId.length : ''
        await this.configService.showToast("success", `${stoneCount} stones returned successfully.`);
      }
    }
  }

  async issueStonesAndTransferToAssortment() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
    this.filtered = this.RFidList.split("\n");
    let issueList = []
    this.filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filtered = issueList.filter(function (el) {
      return el != "";
    });
    console.log(this.filtered)
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let jsonObj;
      let res: any;
      console.log(this.voucherBookId);
      jsonObj = {
        voucherDate: moment().format("YYYY-MM-DD"),
        setOfValues: this.filtered,
        voucherBookId: this.voucherBookId,
        toRole: this.toRole,
        isTransferToAssortment: true,
        isAssortmentDone: false,
        isReadyForSale: false,
        companyId: this.companyId
      };

      console.log("dataObj", jsonObj);
      res = await this.httpApiService.stoneIssue(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (!!data.data && data.data.length > 0) {
          await this.configService.showToast("error", data.message);
          // let excelData = [];
          // data.data.forEach(el => {
          //   if (el.StoneId) {
          //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
          //   }
          // })
          await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        } else {
          await this.configService.showToast("error", 'Error occoured while transferring stones.');
        }
      } else {
        this.RFidList = ""
        this.filtered = []
        let stoneCount = data.data.content.length > 0 ? data.data.content[0].StoneId.length : ''
        await this.configService.showToast("success", `${stoneCount} stones transferred successfully`);
      }
    }
  }
}
