import { Component, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { StoneGenerationbuyinglistComponent } from '../stone-generationbuyinglist/stone-generationbuyinglist.component';
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";
import { StonegenerationbuyingpurposeComponent } from '../stonegenerationbuyingpurpose/stonegenerationbuyingpurpose.component';
import * as XLSX from 'xlsx';
import * as moment from "moment";
import { IonicSelectableComponent } from 'ionic-selectable';
import { async } from '@angular/core/testing';
import { id } from '@swimlane/ngx-datatable/release/utils';
import { CompileShallowModuleMetadata } from '@angular/compiler';

@Component({
  selector: 'app-stone-generation',
  templateUrl: './stone-generation.component.html',
  styleUrls: ['./stone-generation.component.scss']
})
export class StoneGenerationComponent implements OnInit {
  columns = [];
  dataSource = [];
  list = [];
  id;
  prefix;
  currentStoneNumber;
  dataColumns = [];
  public defaultParam = ['id', 'prefix', 'currentStoneNumber'];


  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public loadingController: LoadingController,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService
  ) {

  }

  ngOnInit() {
    //  this.dataSource = [{id:1,prefix:'N1',currentStoneNumber:"2356"}];
    //  this.List = [{partyName:'N1',designNo:'2323'}];
    this.fetchAll();
    console.log("hi")
    
  
  }

  logEvent(ev) {
    console.log(ev);
  }


  async buyingPurposeList() {
    const modal = await this.modalCtrl.create({
      component: StoneGenerationbuyinglistComponent,
      cssClass: 'extraLargePage-for-enquiry',

    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss());
    return await modal.present();
  }

  async handleModalDismiss() {

  }



  onRowRemoving(ev) {
    console.log("row removing");
    this.deleteData(this.id);
  }
  onRowRemoved(ev) {
    console.log(ev);
    console.log("row removed");
    let id = ev.data.id;
    this.deleteData(id);
    console.log(id);
  }

   
 onInserted(ev) {
    console.log(ev);
    // let  prefix = ev.data.prefix;
    // console.log(prefix);
    // let currentStoneNumber = ev.data.currentStoneNumber;
    // console.log(currentStoneNumber)
    this.prefix = ev.data.prefix;
    this.currentStoneNumber = ev.data.currentStoneNumber;
    this.addStonePrefix();
    

    
    

    // if (Object.keys(ev.data).length > 1) {

    // }
  }
  onEditingStart(ev) {
    console.log("editing start");
  }
  onRowUpdating(ev) {

  }

  onRowUpdated(ev) {
    console.log(ev);
    this.id = ev.data.id;
    this.currentStoneNumber = ev.data.currentStoneNumber;
    this.prefix = ev.data.prefix;
    console.log(this.currentStoneNumber);
    console.log(this.prefix);
    this.updateStone();
    
    
  }
  
  onRowUpdate(ev) {
    
    console.log("update.....")
  }
  
  async updateStone() {

    //  await this.configService.showLoader();
    
    let res: any;
    
    let jsonObj = {
      "prefix": this.prefix,
      "currentStoneNumber": this.currentStoneNumber,

    }
    console.log(jsonObj)

    res = await this.httpApiService.updateStonedata(this.id, jsonObj);
    console.log(this.id);

    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
      
    }
    else {
      
      await this.fetchAll();
      await this.configService.showToast('success', data.message);
      await this.modalCtrl.dismiss();
    }
    
  }

  // async stoneIdGeneration() {
  //   await this.configService.showLoader();
  //   let res: any;
  //   let cList = []
  //   this.dataSource.forEach(el => {
  //     let obj = {
  //       buyingPurpose: el.buyingPurpose,

  //     }
  //     cList.push(obj)
  //   })
  

  //   let jsonObj = {
  //     "buyingPurpose": "M"

  //   }
  
  //   res = await this.httpApiService.generateBuyingPurposeStoneId(jsonObj);

  //   let data = res.json();
  //   console.log(data.data);

  //   await this.configService.dismiss();
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);

  //   }
  //   else {
  //     // await this.fetch();
  //     await this.configService.showToast('success', data.message);
  //     await this.modalCtrl.dismiss();
  
    
  //   }
  
  // }
  

  async deleteData(id) {
    await this.configService.showLoader();
    let res = await this.httpApiService.deleteStonebyprefix(id);
    console.log(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.message);
    } else if (!data.isSuccess) {
      await this.configService.showToast(
        "error",
        data.data
      );

    } else {
      await this.configService.showToast(
        "success",
        data.data
      );
    }

  }
  async addStonePrefix() {
    await this.configService.showLoader();
    let res: any;
    let cList = []
    this.dataSource.forEach(el => {
      let obj = {
        prefix: el.prefix,
        currentStoneNumber: el.currentStoneNumber
      }
      cList.push(obj)
    })
    console.log(cList);

    let jsonObj = {
      "prefix": this.prefix,
      "currentStoneNumber": this.currentStoneNumber,

    }
    console.log(this.currentStoneNumber);
    res = await this.httpApiService.addStonePrefix(jsonObj);

    let data = res.json();

    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);

    }
    else {
      await this.fetchAll();
      await this.configService.showToast('success', data.message);
      await this.modalCtrl.dismiss();
    }
  

  }

  async fetchAll() {

    let res: any;
    res = await this.httpApiService.fetchStonebyprefix();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.dataSource = data.data;
      this.dataColumns = [];
      this.defaultParam.forEach(el => {
        {
          if (el == 'id') {
            this.dataColumns.push({
              name: el,
              dataSource: [],
              entityType: '',
              isAllow: false
            })
          }
          else {
            this.dataColumns.push({
              name: el,
              dataSource: [],
              entityType: '',
              isAllow: true
            })
          }
        }
      })
      console.log(this.dataColumns);
    }

    console.log(data);
  }
}

