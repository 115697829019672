import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Events } from "@ionic/angular";

@Component({
  selector: 'app-single-multi-dropdown',
  templateUrl: './single-multi-dropdown.component.html',
  styleUrls: ['./single-multi-dropdown.component.scss']
})
export class SingleMultiDropdownComponent implements OnInit {
  @Input() itemList: any;
  @Input() name: any;
  @Input() singleSelection: any;
  @Input() property: any;
  @Input() placeholder: any;
  @Input() ids: any;
  @Input() type: any;
  @Input() showSelected: any;
  @Input() masterId: any;
  @Output() callFunction: EventEmitter<any> = new EventEmitter();
  @Input() isDisable: any;
  @Input() keyName: any;

  public dropdownSettings: any;
  public itemIds: any;

  constructor(public event: Events) { }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: this.singleSelection,
      idField: 'id',
      textField: this.name,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: this.showSelected ? 1 : 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }

  onItemSelect(item: any) {
    this.itemIds = [];
    this.ids.forEach(el => {
      this.itemIds.push(el.id);
    });
    let jsonObj = {
      property: this.property,
      idList: this.itemIds,
      arrayList: this.ids,
      masterId: this.masterId
    }
    //  this.selected = this.ids.map(res => res[this.selectedKey]);
    if (this.keyName) {
      if (this.property == 'shapeList') {
        this.callFunction.emit(this.ids.map(res => res[this.keyName]));
      } else if (this.singleSelection) {
        this.callFunction.emit(this.ids.map(res => res[this.keyName])[0])
      } else {
        this.callFunction.emit(this.ids.map(res => res[this.keyName]));
      }
    } else {
      this.callFunction.emit(jsonObj);
    }
  }

  onSelectAll(items: any) {
    this.itemIds = [];
    items.forEach(el => {
      this.itemIds.push(el.id);
    });
    let jsonObj = {
      property: this.property,
      idList: this.itemIds,
      arrayList: items,
      masterId: this.masterId
    }
    // this.selected = this.ids.map(res => res[this.selectedKey]);
    if (this.keyName) {
      if (this.singleSelection) {
        this.callFunction.emit(this.ids.map(res => res[this.keyName])[0])
      } else {
        this.callFunction.emit(this.ids.map(res => res[this.keyName]));
      }
    } else {
      this.callFunction.emit(jsonObj);
    }
  }

  onDeSelect(item: any) {
    this.itemIds = [];
    this.ids.forEach(el => {
      this.itemIds.push(el.id);
    });
    let jsonObj = {
      property: this.property,
      idList: this.itemIds,
      arrayList: this.ids,
      masterId: this.masterId
    }
    // this.selected = this.ids.map(res => res[this.selectedKey]);
    if (this.keyName) {
      if (this.singleSelection) {
        this.callFunction.emit(this.ids.map(res => res[this.keyName])[0])
      } else {
        this.callFunction.emit(this.ids.map(res => res[this.keyName]));
      }
    } else {
      this.callFunction.emit(jsonObj);
    }
  }

  onDeSelectAll(items: any) {
    this.itemIds = [];
    let jsonObj = {
      property: this.property,
      idList: this.itemIds,
      arrayList: this.ids,
      masterId: this.masterId
    }
    // this.selected = this.ids.map(res => res[this.selectedKey]);
    if (this.keyName) {
      if (this.singleSelection) {
        this.callFunction.emit(this.ids.map(res => res[this.keyName])[0])
      } else {
        this.callFunction.emit(this.ids.map(res => res[this.keyName]));
      }
    } else {
      this.callFunction.emit(jsonObj);
    }
  }

}
