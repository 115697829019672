import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController, NavParams, Events } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { HttpApiService } from 'src/app/services/http-api/http-api.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { ProcessActionComponent } from '../processAction';
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: 'app-update-pdfile-upload',
  templateUrl: './update-pdfile-upload.component.html',
  styleUrls: ['./update-pdfile-upload.component.scss']
})
export class UpdatePDFileUploadComponent implements ProcessActionComponent {
  @Input() data: any;

  @ViewChild('fileUpload')
  fileInputVariable: ElementRef;

  public page = 'UploadPDParameter';
  public file: any;
  public clearFile: any;
  columns: any;
  pageNumber = 0;
  public noOfRecords = 20;
  public pageSize = 20
  totalPages: number;
  historyData: any = [];
  public historyDataSource: any = {};
  count: number = 0;
  companyIds: string = '';
  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events) { }

  ngOnInit() {
    this.columns = [
      { name: "File Name", prop: "fileName" },
      { name: "Date & Time", prop: "createdDate" },
      { name: "By", prop: "username" },
      { name: "Status", prop: "status" }
    ];
    this.getDefaultValues()
    this.loadPdfileUploadHistory(this.pageNumber, this.noOfRecords)
    this.event.subscribe('Upload PD Parameter', (item) => {
      this.columns = [
        { name: "File Name", prop: "fileName" },
        { name: "Date & Time", prop: "createdDate" },
        { name: "By", prop: "username" },
        { name: "Status", prop: "status" }
      ];
      this.loadPdfileUploadHistory(this.pageNumber, this.noOfRecords)
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Upload PD Parameter');
  }

  async loadPdfileUploadHistory(pageNumber, noOfRecords, search = '') {
    this.historyDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getUploadHistory(
            "Update PD Parameters",
            this.pageNumber,
            this.noOfRecords,
            search
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.historyData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.historyData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  fileObject(event) {
    this.clearFile = event.target.value
    this.file = <File>event.target.files[0];
    console.log(this.file);
  }

  async getDefaultValues() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(dataValue);
      if (
        !!dataValue.map &&
        dataValue.map.companyIds
      ) {
        this.companyIds = dataValue.map.companyIds;
      }
    }
  }


  async updatePdFile() {
    if (this.file) {
      if (!this.companyIds) {
        await this.configService.showToast("error", 'Please add company from action default');
        return true;
      }
      const formData: FormData = new FormData();
      formData.append("file", this.file);
      formData.append("companies", this.companyIds);
      await this.configService.showLoader();
      let res: any;
      let companies = '2,8';
      res = await this.httpApiService.updatePDParameters(formData);
      console.log(res.json());
      await this.configService.dismiss();
      let data = res.json();
      if (data.status == 400) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.file = null;
        this.clearFile = null;
        this.fileInputVariable.nativeElement.value = "";
        this.loadPdfileUploadHistory(this.pageNumber, this.noOfRecords)
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  async downloadFile(file) {
    console.log(file)
    let res: any;
    //res = await this.httpApiService.downloadUploadedFile(file.id);
    res = await this.httpApiService.downloadFileByType(file.id, 'Update PD Parameters');
  }


}
