
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AlertController, Events, LoadingController, ModalController } from "@ionic/angular";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import * as moment from "moment";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageCustomerPage } from "../../actions/manage-customer/manage-customer.page";
import { ManageEnquiryMasterComponent } from '../manage-enquiry-master/manage-enquiry-master.component';
// import { CreateEnquiryFormComponent} from '../create-enquiry-form/create-enquiry-form.component';

@Component({
  selector: 'app-enquiry-master',
  templateUrl: './enquiry-master.component.html',
  styleUrls: ['./enquiry-master.component.scss']
})
export class EnquiryMasterComponent implements OnInit {
  searchTxt = '';
  enquiryNo = '';
  columns = [];
  selected = [];
  enquiryLists = [];
  public pageSize: any = 50;
  pageNumber = 0;
  noOfRecords = 15;
  public refreshGrid: Boolean = false;
  dataSource: any = [];
  public count: any;

  constructor(
    public configService: ConfigService,
    public authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public event: Events
  ) {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "Enquiry No", prop: "id" },
    { name: "Customer", prop: "customerName" },
    { name: "Sales Person", prop: "salesPersonName" },
    { name: "Created by", prop: "createdBy" },
    { name: "Date", prop: "createdDate" },

    ];
    this.event.subscribe('Enquiry Master', (item) => {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Enquiry No", prop: "id" },
      { name: "Customer", prop: "customerName" },
      { name: "Sales Person", prop: "salesPersonName" },
      { name: "Created by", prop: "createdBy" },
      { name: "Date", prop: "createdDate" },]
      this.getAllEnquiries()
    })
  }


  ngOnDestroy() {
    this.event.unsubscribe('Enquiry Master')
  }

  ngOnInit() {
    this.getAllEnquiries()
  }

  getAllEnquiries() {
    this.dataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getAllEnquiries(this.pageNumber, this.noOfRecords)
          let data = res.json();

          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.dataSource = data.data;
            return {
              data: Query(data.data)
                .toArray(),
              totalCount: 5, //data.data.totalElements,
              groupCount: 5// data.data.totalElements
            };
          }
        } else {
          return {
            data: this.dataSource,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  getList() {

  }

  async filterItems(ev: any) {
    let val = ev.target.value;
    // if (val && val.trim() !== "") {
    //   this.items = await this.items.filter(function (item) {
    //     if (
    //       item.progName &&
    //       item.progName.toLowerCase().includes(val.toLowerCase())
    //     ) {
    //       return true;
    //     } else if (
    //       item.customerName &&
    //       item.customerName.toLowerCase().includes(val.toLowerCase())
    //     ) {
    //       return true;
    //     }
    //   });
    // } else {
    //   this.setItems();
    // }
  }

  async openModal(action) {
    this.refreshGrid = false;
    let modalPage;
    if (action == 'Update') {
      modalPage = await this.modalCtrl.create({
        component: ManageEnquiryMasterComponent,
        backdropDismiss: false,
        cssClass: "extraLargePage-for-enquiry",
        componentProps: {
          action: action,
          editData: this.selected
        },
      });
    } else {
      modalPage = await this.modalCtrl.create({
        component: ManageEnquiryMasterComponent,
        backdropDismiss: false,
        cssClass: "extraLargePage-for-enquiry",
        componentProps: { action: action }
      });
    }

    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modalPage.present();
  }

  // async openModal(action) {
  //   console.log("bjbkj");
  //   const modal = await this.modalCtrl.create({
  //     component:CreateEnquiryFormComponent ,
  //     cssClass: 'extraLargePage',
  //     componentProps: { action: action }
  //   });
  //   return await modal.present();
  // }

  async handleModalDismiss(d) {
    this.refreshGrid = true
    console.log(d.xdata,);
    this.getAllEnquiries()
  }

  fetchList() {
    this.dataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findEnquiries(this.searchTxt, this.enquiryNo, this.pageNumber, this.noOfRecords)
          let data = res.json();

          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.dataSource = data.data;
            return {
              data: Query(data.data)
                .toArray(),
              totalCount: 5, //data.data.totalElements,
              groupCount: 5// data.data.totalElements
            };
          }
        } else {
          return {
            data: this.dataSource,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  onselect() {

  }

  deleteItem(ev) {
    this.confirmDeleteData(ev.row.data);
  }

  async confirmDeleteData(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteData(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteData(id) {
    await this.configService.showLoader();
    let res = await this.httpApiService.deleteEnquiry(
      id
    );
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.message);
    } else if (!data.isSuccess) {
      await this.configService.showToast(
        "error",
        data.data
      );

    } else {
      await this.configService.showToast(
        "success",
        data.data
      );
    }
    await this.getAllEnquiries()
  }


  OnSelectRows(ev) {
    console.log(ev);
    this.selected = ev.selectedRowsData[0];
    console.log(this.selected)
    this.openModal('Update')
  }

  Reset() {
    this.searchTxt = '';
    this.enquiryNo = '';
    this.dataSource = [];
    this.fetchList()
  }
}
