import { Component, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-manage-status',
  templateUrl: './manage-status.page.html',
  styleUrls: ['./manage-status.page.scss'],
})
export class ManageStatusPage implements OnInit {

  public copy = [];
  public name: any;
  public fileType: any;
  public flagAddUpdate: any;
  public item: any;
  public id: any;
  public isDefault: any;
  public typeIdList = []
  public typeList = [];
  public type: any;
  public isActive: Boolean = true;

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) {

    this.typeList = [{ id: 1, name: 'Internal' }, { id: 2, name: 'External' }]
    let data = this.navParams.get('item');
    this.isDefault = this.navParams.get('isDefault');
    if (data != 0) {
      this.item = data.row;
      console.log(this.item)
      this.isActive = this.item.isActive
      this.id = this.item.id
    }
    this.flagAddUpdate = this.navParams.get('flag');
    if (this.flagAddUpdate == 'update') {
      this.name = this.item.name;
      this.type = this.item.type;
      console.log(this.type)
      this.typeIdList = []
      this.typeList.filter(item => {
        if (item.name == this.type) {
          this.typeIdList.push(item)
        }
      })
    }
  }

  ngOnInit() {
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'typeList') {
      if (idList.length > 0) {
        this.type = obj[0].name;
      } else {
        this.type = null
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh')
  }

  async addStatus() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        name: this.name,
        isActive: this.isActive,
        type: this.type
      }
      console.log(jsonObj)
      res = await this.httpApiService.addMasterStatus(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async updateStatus() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        id: this.id,
        name: this.name,
        isActive: this.isActive,
        type: this.type
      }
      console.log(jsonObj)
      res = await this.httpApiService.updateMasterStatus(jsonObj, this.id);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async validateControls() {
    if (this.type != null && this.type != undefined && this.type != '') {
      if (this.name != null && this.name != null && this.name != '') {
        return true;
      } else {
        await this.configService.showToast('error', 'Please enter the details')
        return false;
      }
    } else {
      await this.configService.showToast('error', 'Please select type')
      return false;
    }
  }
}
