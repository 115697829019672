export default {
  reportNo: 'R-16',
  url: "master-service/api/report/soldStock",
  method: "GET",
  size: 3,
  fields: [

    {
      title: ' Trans Type ',
      input: 'select',
      key: 'transType',
      placeholder: 'Select Trans Type',
      property: 'transType',
      keyName: 'name',
      name: 'name',
      required: true,
      singleSelection: true,
      function: 'getAllTransType',
      default: [],
      size: 3
    },
    {
      title: ' Report Type ',
      input: 'select',
      key: 'reportType',
      placeholder: 'Select Report Type',
      property: 'type',
      keyName: 'id',
      required: true,
      name: 'name',
      singleSelection: true,
      itemList: [{
        id: 'Summary',
        name: 'summary'
      }, {
        id: 'Details',
        name: 'details'
      }],
      default: [],
      size: 3
    },
    {
      title: ' From Date ',
      input: 'dateTime',
      key: 'fromDate',
      placeholder: 'Select From Date',
      property: 'fromDate',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
    {
      title: ' To Date ',
      input: 'dateTime',
      key: 'toDate',
      placeholder: 'Select To Date',
      property: 'toDate',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
  ],
  columns: [
    { name: "Invoice Date", prop: "voucherDate" },
    // { name: "Invoice No", prop: "invoiceNo" },
    { name: "Voucher Id", prop: "voucherId", showInGroupFooter: true, "displayFormat": " ", },
    { name: "Voucher No", props: ["shortCode", "bookSerialNumber"], join: '-', required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Party Name", prop: "partyName", showInGroupFooter: true, "displayFormat": " ", },

    { name: "Stone Id", prop: "stoneId", ngIf: ['reportType', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Shape", prop: "shape", ngIf: ['reportType', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Color", prop: "color", ngIf: ['reportType', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Clarity", prop: "clarity", ngIf: ['reportType', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Description", props: ["cut", "polish", "symmetry", "fluorescence"], join: ' ', ngIf: ['reportType', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Lab", prop: "lab", ngIf: ['reportType', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    {
      name: "Cts", prop: "requestCts", ngIf: ['reportType', 'Details'], required: true,
      summaryType: 'sum',
      showInGroupFooter: true
    },
    {
      name: "Pcs", prop: "pcs", ngIf: ['reportType', 'Details'], required: true,
      summaryType: 'sum',
      showInGroupFooter: true
    },
    { name: "Memo Disc", prop: "requestDiscount", ngIf: ['reportType', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Memo price", prop: "requestPrice", ngIf: ['reportType', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Memo Value", prop: "requestAmount", ngIf: ['reportType', 'Details'], required: true, summaryType: 'sum' },
    { name: "Remark", prop: "stoneRemarks", ngIf: ['reportType', 'Details'], required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Total Pcs", prop: "totalPkts", ngIf: ['reportType', 'Summary'], required: true, summaryType: 'sum', showInGroupFooter: true, sumCol: 'TotalPcs' },
    { name: "Total Cts", prop: "totalCts", ngIf: ['reportType', 'Summary'], summaryType: 'sum', required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Avg price", prop: "avgPrice", ngIf: ['reportType', 'Summary'], summaryType: 'avg', required: true, showInGroupFooter: true, "displayFormat": " ", },
    { name: "Total Amount", prop: "totalAmt", ngIf: ['reportType', 'Summary'], summaryType: 'sum', required: true, showInGroupFooter: true, "displayFormat": " ", },


  ],
  dataStudioLink: 'https://lookerstudio.google.com/embed/reporting/14ffa81d-8eb1-459b-b805-9628f8f3c3fb/page/WqKHD'
};