import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, LoadingController, Events } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { HttpApiService } from 'src/app/services/http-api/http-api.service';
import { ConfigService } from 'src/app/services/config/config.service';
import CustomStore from 'devextreme/data/custom_store';
import Query from "devextreme/data/query";


@Component({
  selector: 'app-stock-upload',
  templateUrl: './stock-upload.component.html',
  styleUrls: ['./stock-upload.component.scss']
})
export class StockUploadComponent implements OnInit {
  @ViewChild('boxFileUpload')
  boxFileInputVariable: ElementRef;

  @ViewChild('recutFileUpload')
  recutFileInputVariable: ElementRef;

  public list = [];
  public stepsList = [{ id: 1, name: 1 }, { id: 2, name: 2 }, { id: 3, name: 3 }];
  public stepsIdList = [];
  public steps = [];
  public listOfTransType = [];
  public transTypeIdList = [];
  public transType: any;
  public selectedStep: any;
  public pageSize: any = 50;
  public recutFile: any;
  public boxFile: any;
  public roleIdIdList = []
  public allRoles = [];
  public roleId: any;
  public isAssortStock: boolean = false;
  listOfStoneDataSource: any = [];
  selectedFile: File = null;
  pageNumber = 0;
  noOfRecords = 15;
  totalPages: number;
  count: number = 0;
  columns: any = []
  refreshGrid: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events
  ) {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "Description", prop: "description" },
    { name: "Created Date", prop: "createdDate" },
    // { name: "Modified Date", prop: "modifiedDate" },
    { name: "Status", prop: "status" },
    ];
  }

  async ngOnInit() {
    await this.loadUploadHistory();
    await this.getAllTransType();
    await this.getAllRole();
    this.event.subscribe('Stock Upload', (item) => {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "Description", prop: "description" },
      { name: "Created Date", prop: "createdDate" },
      // { name: "Modified Date", prop: "modifiedDate" },
      { name: "Status", prop: "status" },
      ];
      this.loadUploadHistory();
      this.getAllTransType();
      this.getAllRole();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Stock Upload');
  }


  async boxUpload() {
    if (this.boxFile) {
      const formData: FormData = new FormData();
      let roleId = this.roleId;
      formData.append("roleId", roleId);
      formData.append("file", this.boxFile);
      formData.append("boxType", 'Regular');
      await this.configService.showLoader();
      console.log(formData);
      let res: any;
      if (this.transType == 'MIX' && this.isAssortStock) {
        res = await this.httpApiService.uploadCsvForMixStock(formData, 'Assortment-OK', roleId);
      } else if (this.transType == 'MIX' && !this.isAssortStock) {
        res = await this.httpApiService.uploadCsvForMixBoxRegular(formData, 'Regular', roleId);
      } else {
        res = await this.httpApiService.uploadCsvForSingleSaleStock(formData);
      }
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.boxFile = null;
        this.roleId = '';
        this.roleIdIdList = [];
        this.boxFileInputVariable.nativeElement.value = "";
        await this.loadUploadHistory();
      }
    } else {
      await this.configService.showToast("error", 'Please select File');
    }
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  onBoxFileSelected(event) {
    this.boxFile = <File>event.target.files[0];
  }

  onRecutFileSelected(event) {
    this.recutFile = <File>event.target.files[0];
  }

  async setPage(currentPage) {
    console.log(currentPage);
    let res: any;
    this.pageNumber = currentPage.offset;
    await this.loadUploadHistory();
  }

  async loadUploadHistory() {
    this.listOfStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.stockUploadHistory(
            'Stock Upload', 'Json Upload', 'Delete Stock', 'Recut Stock Upload', 'Block Criteria Upload',
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: [],
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async getAllRole() {
    let res: any;
    res = await this.httpApiService.getAllRoles();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allRoles = data.data;
    }
  }

  async upload() {
    if (this.recutFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.recutFile);
      formData.append("transType", this.transType);
      await this.configService.showLoader();
      console.log(formData);
      let res: any;
      res = await this.httpApiService.uploadRecut(formData);
      console.log(res.json());
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.recutFile = null;
        this.recutFileInputVariable.nativeElement.value = "";
        await this.loadUploadHistory();
      }
    } else {
      await this.configService.showToast("error", 'Please select File');
    }
  }

  async submit() {
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("transType", this.transType);
      formData.append("step", this.selectedStep);
      if (this.isAssortStock) {
        formData.append("fileType", "assort")
      }
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.uploadStock(formData);
      console.log(res.json());
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.selectedFile = null;
        this.isAssortStock = false;
        await this.loadUploadHistory();
      }
    } else {
      this.isAssortStock = false;
      await this.configService.showToast("error", 'Please select File');
    }
  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data);
      this.listOfTransType = data.data
      this.transTypeIdList = [];
      this.listOfTransType.forEach(item => {
        // this.transTypeIdList.push({ id: item.id, name: item.name })
        // this.transType = item.name;
      })
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    console.log(obj)
    if (property == "transTypeName") {
      this.transType = obj[0].name;
    } else if (property == "roleForBox") {
      this.roleId = idList[0];
    } else {
      console.log(obj)
      this.selectedStep = obj[0];
      console.log(this.transType)
      console.log(this.selectedStep)
    }
  }

  async validateControls() {
    console.log(
      this.transType,
    );
    if (
      !!this.transType
    ) {
      return true;
    } else {
      await this.configService.showToast("error", "Please insert the detail");
      return false;
    }
  }

  async approve() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to approve all vouchers?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.approveAll();
          }
        }
      ]
    });
    await alert.present();
  }

  async approveAll() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.approveAllVouchers();
    console.log(res.json());
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
    }
  }

  async delete() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to Delete the Stock? This Can't be Undone.",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteStock();
          }
        }
      ]
    });
    await alert.present();
  }


  async deleteStock() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.deleteStock();
    console.log(res.json());
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", "Stock Deleted Successfully.");
    }
  }

  toggle(ev) {
    this.isAssortStock = ev.detail.checked;
  }
}
