import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AlertController, Events, LoadingController, ModalController } from "@ionic/angular";
import { IonicSelectableComponent } from 'ionic-selectable';
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.scss']
})
export class RequestFormComponent implements OnInit {
  @ViewChild('selectableComponent') selectableComponent: IonicSelectableComponent;
  @ViewChild('selectableRefPersonComponent') selectableRefPersonComponent: IonicSelectableComponent;
  @Input() formDetailList: any = [];
  @Input() partyType: any;
  @Input() groupType: any;
  @Input() partyData: any;
  @Input() buttonText: any;
  @Input() selectedAction: any;
  @Output() callApproveFunction: EventEmitter<any> = new EventEmitter();

  public getAllCustomers = []
  public formData = {}
  public userId: any;
  public selectedCustomer: any;
  public getAllCompany: any;
  public selectedCompany: any;
  public usersByCompany: any;
  public selectedCustomerList: any = [];
  public selectedCompanyList: any = [];
  public selectedUserList: any = [];
  public getAllContacts: any;
  public selectedContactList: any = [];
  public customerContactId: any;
  public selectedBox: any;
  public listOfTags: any;
  public selectedTagIdList: any = [];
  public selectedTag = "";
  public setOfTags = []
  public listOfStoneTags: any;
  public items: any;
  public arrowKeyLocation = -1;
  public manageSelectedTag: "";
  public type: any;
  public stoneQtsWiseList = [];
  public isNext: boolean = true;
  public exportAs: any = [];
  public errorMsg = null;
  public errorMsgList = [];
  public successMsg = null;
  public msgStatus = false;
  public isDefault = false;
  public defaultTempalateList = [];
  public defaultTemplateId: any;
  public userTemplateId: any;
  public defaultExportList = []
  public userExportList = []
  public count = 0;
  public flagOpenDoc = false;
  public nameOfPersonReceivingGood: any;
  public customerDetails: any;
  public companyDetails = [];
  public salesPersonName: any;
  public status = "Internal";
  public servicesList = []
  public requestList = []
  public transporterDetails: any;
  public salesPersonList = [];
  public salePersonIdList = [];
  public stockController: any;
  public termName: any;
  public disc1: any;
  public disc2: any;
  public deliveryChallanNo: any;
  public exgRate: any;
  public lab: any;
  public userData: any;
  public listOfActions: any;
  public requestVoucherBookId: any;
  public showSelected = true;
  public termList = [];
  public discountPremium: any;
  public error: any;
  public isError: boolean = false;
  public defaultDetail: any;
  public copyValue: any = null;
  public stoneWithPriceArr = [];
  public columnsForCopyData = [];
  public countCopyArr = 0;
  public priceListMasterId: any;
  public totalCts: any;
  public totalAmount = 0;
  public currencyRate: any;
  public spreadRate: Number = 0;
  public selectedTermList = [];
  public baseUrl: any;
  public addType = 'add';
  public csspType = 'Regular';
  public withMe: Boolean = false;
  public modeOfTransport: any;
  public url: any;
  public displayPriceList = []
  public holdVoucherBookId: any;
  public unHoldVoucherBookId: any;
  public multipleChallanList = []
  public brokerList = []
  public location: any;
  public brokerPercentage: Number;
  public deptCheck;
  public userCheck;
  public selectedType: any;
  public buttonTextOnIsDirect: any;
  public actionList = []
  public visitTime: any;
  public shippingDate: any;
  public brokerId: any;
  public businessType: any;
  public shippingCost: any;
  public deliveryInstruction: any;
  public duration: any;
  public refPartyList = []
  public currencyIdList = []
  public currencyList = []
  public defaultCurrency: any;
  public listOfAddress = [];
  public selectedAddressIdList = [];
  public businessTypeIdList = []
  public refPartyIdList = []
  public selectedBrokerList = []
  public selectedTransportIdList = []
  public selectedTagList = []
  public tagName = '';
  public selectedCurrency: any;
  public settingsDateTime = {
    bigBanner: true,
    timePicker: true,
    format: 'yyyy-MM-dd HH:mm a',
    defaultOpen: false,
    minDate: new Date()
  }
  public settingsDate = {
    bigBanner: true,
    timePicker: false,
    format: 'yyyy-MM-dd',
    defaultOpen: false,
    minDate: new Date()
  }
  public pageNumber = 0;
  public noOfRecords = 10;
  public tagList = []
  public businessTypeList = []
  public refCustomerName: any;
  public refDescription: any;

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events) {

  }

  ngOnChanges(change) {
    console.log(change)
  }

  async ngOnInit() {
    this.businessTypeList = await this.configService.businessType();
    this.formData['partyMasterId'] = this.partyData.partyMasterId;
    this.formData['customerContactId'] = this.partyData.customerContactId;
    this.formData['location'] = this.partyData.location;
    this.formData['visitTime'] = this.partyData.visitTime;
    this.formData['duration'] = this.partyData.duration;
    this.formData['address'] = this.partyData.location;
    this.getAllCurrency()
    this.getAddressesByPartyId(this.partyData.partyMasterId)
    this.getTransporterDetail('Transport');
    this.type = "SS";//need to ask query
    await this.getAllSalesPerson();
    this.getBrokerDetail('Broker');
    this.getTerm('SS')
  }

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.disableScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.disableScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.disableScroll(event);
  }

  disableScroll(event: any) {
    if (event.srcElement.type === "number")
      event.preventDefault();
  }


  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "customer") {
      console.log(this.formData)
      if (idList.length > 0) {
        this.selectedCustomer = idList[0];
        this.formData['partyMasterId'] = idList[0];
        this.lab = obj[0].partyName;
        this.getAddressesByPartyId(idList[0])
        this.getAllCustomerContact();
        this.getCustomerDetails(idList[0]);
      } else {
        delete this.formData['partyMasterId']
        delete this.formData['customerContactId']
        this.selectedContactList = []
        this.selectedCustomer = null;
        this.customerContactId = null;
      }
    } else if (property == "contacts") {
      if (idList.length > 0) {
        this.formData['customerContactId'] = idList[0];
        this.customerContactId = idList[0];
        this.nameOfPersonReceivingGood = obj[0].username;
      } else {
        delete this.formData['customerContactId']
        this.customerContactId = null;
      }
    } else if (property == "address") {
      if (idList.length > 0) {
        this.formData['location'] = obj[0].name;
        this.formData['address'] = obj[0].name;
      } else {
        delete this.formData['location']
        delete this.formData['address']
      }
    } else if (property == "company") {
      if (idList.length > 0) {
        this.formData['selectedCompany'] = idList[0];
        this.selectedCompany = idList[0];
        let companyName = obj[0].companyName
        this.getCompanyDetailByName(companyName)
        this.findByAppNameAndCompanyId(this.selectedCompany);
      } else {
        delete this.formData['customerContactId']
        delete this.formData['userId']
        this.selectedCompany = null;
        this.userId = null;
        this.selectedUserList = []
        this.usersByCompany = null;
      }
    } else if (property == "users") {
      if (idList.length > 0) {
        this.formData['userId'] = idList[0];
        this.userId = idList[0];
        this.nameOfPersonReceivingGood = obj[0].username;
      } else {
        delete this.formData['userId']
        this.userId = null;
        this.nameOfPersonReceivingGood = null;
      }
    } else if (property == "tags") {
      if (idList.length > 0) {
        this.setOfTags = idList;
        this.formData['setOfTags'] = idList;
      } else {
        delete this.formData['setOfTags']
        this.setOfTags = [];
      }
    } else if (property == 'defaultTemplate') {
      this.defaultTemplateId = idList[0];
    } else if (property == 'exportAs') {
      this.userTemplateId = idList[0];
    } else if (property == 'selectedBroker') {
      if (idList.length > 0) {
        this.formData['brokerId'] = idList[0];
        this.brokerId = idList[0];
      } else {
        delete this.formData['brokerId']
        this.brokerId = null;
      }
    } else if (property == 'refParty') {
      if (idList.length > 0) {
        this.formData['customerReferenceId'] = idList[0];
      } else {
        delete this.formData['customerReferenceId']
      }
    } else if (property == 'selectedCurrency') {
      if (idList.length > 0) {
        this.currencyList.filter(el => {
          if (el.id == idList[0]) {
            this.formData['currencyRate'] = 0;
            this.formData['spreadRate'] = 0;
          }
        })
        this.formData['currencyMasterId'] = idList[0];
        this.selectedCurrency = obj[0].name;
      } else {
        delete this.formData['currencyMasterId']
      }
    }
    else if (property == 'businessType') {
      if (idList.length > 0) {
        this.formData['businessType'] = idList[0];
        this.businessType = obj[0].name;
      } else {
        delete this.formData['businessType']
        this.businessType = null;
      }
    }
    else if (property == 'transportMode') {
      if (idList.length > 0) {
        this.formData['modeOfTransport'] = obj[0].partyName
        this.modeOfTransport = obj[0].partyName;
      } else {
        delete this.formData['modeOfTransport']
        this.modeOfTransport = null;
      }
    }
    else if (property == "term") {
      if (idList.length > 0) {

        this.termList.filter(el => {
          if (el.id == idList[0]) {
            this.discountPremium = el.discount
            this.termName = el.name;
            this.formData['termName'] = this.termName
          }
        })
      } else {
        delete this.formData['termName'];
        this.termName = null;
        this.discountPremium = 0;
      }

    } else if (property == "salePerson") {
      if (idList.length > 0) {
        this.formData['stockController'] = idList[0];
        this.stockController = idList[0];
      } else {
        delete this.formData['stockController']
        this.stockController = null;
      }
    }
    console.log(
      "selectedValues",
      idList,
      property,
      this.termName,
      this.selectedCustomer,
      this.selectedCompany,
      this.userId,
      this.stockController
    );
  }

  // async getAllCustomer() {
  //   let res: any;
  //   let json = {
  //     listOfType: this.partyType
  //   }
  //   res = await this.httpApiService.findByListOfPartyType(json, this.groupType);
  //   let data = res.json();
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     this.getAllCustomers = data.data;
  //   }
  // }
  //To company detail for recut and observation 

  async getCompanyDetailByName(companyName) {
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }

  async findByAppNameAndCompanyId(company) {
    let res: any;
    res = await this.httpApiService.findByAppNameAndCompanyId(company);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.usersByCompany = data.data;
      // this.items = this.listOfParameter;
    }
  }

  async getAllCurrency() {
    this.currencyIdList = [];
    let res: any;
    res = await this.httpApiService.getCurrencyList();
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.currencyList = data.data;
      if (this.currencyList.length > 0) {
        this.currencyList.filter(el => {
          if (el.isDefault) {
            this.formData['currencyRate'] = 0;
            this.formData['spreadRate'] = 0;
            this.currencyIdList = []
            this.formData['currencyMasterId'] = el.id
            this.currencyIdList.push({ id: el.id, name: el.name })
            this.defaultCurrency = el.id;
            // this.getLatestRateByCurrencyName(el.name)
          }
        })
      }
    }
  }

  //currency detail

  // async getLatestRateByCurrencyName(currency) {
  //   let res: any;
  //   res = await this.httpApiService.getLatestRateByCurrencyName(currency);
  //   let data = res.json()
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     let currencyDetail = data.data;
  //     this.currencyRate = currencyDetail.rate;
  //     this.spreadRate = currencyDetail.spread;
  //     this.formData['currencyRate'] = currencyDetail.rate;
  //     this.formData['spreadRate'] = (!!currencyDetail.spread ? currencyDetail.spread : 0);
  //     this.exgRate = currencyDetail.rate + (!!currencyDetail.spread ? currencyDetail.spread : 0);
  //     console.log(this.formData, currencyDetail, this.exgRate)
  //   }
  // }

  // List of addresses

  async getAddressesByPartyId(id) {
    let res: any;
    res = await this.httpApiService.getAddressesByPartyId(id);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.selectedAddressIdList = []
      this.listOfAddress = data.data.filter(el => !!el.isActive)
      console.log(this.listOfAddress)
      if (this.listOfAddress.length == 0) {
        delete this.formData['location']
        delete this.formData['address']
        delete this.formData['addSrNo']
      }
      if (this.listOfAddress.length == 1) {
        this.selectedAddressIdList.push({ id: this.listOfAddress[0].id, address: this.listOfAddress[0].address })
        this.location = this.listOfAddress[0].address;
        this.formData['address'] = this.listOfAddress[0].address;
        this.formData['location'] = this.listOfAddress[0].address;
        this.formData['addSrNo'] = this.listOfAddress[0].addSrNo ? this.listOfAddress[0].addSrNo : null;
      }
    }
  }


  //get customer contact detail

  async getAllCustomerContact() {
    // await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getCustomerContactList(
      this.selectedCustomer
    );
    let data = res.json();
    // await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllContacts = data.data;
      if (this.getAllContacts.length == 1) {
        this.selectedContactList = []
        this.selectedContactList.push({ id: this.getAllContacts[0].id, name: this.getAllContacts[0].name });
        this.customerContactId = this.getAllContacts[0].id;
        this.formData['customerContactId'] = this.getAllContacts[0].id;
        this.nameOfPersonReceivingGood = this.getAllContacts[0].name;
      } else {
        this.selectedContactList = []
        delete this.formData['customerContactId']
      }
      // this.items = this.listOfParameter;
    }
  }

  // Customer Details

  async getCustomerDetails(id) {
    let res: any;
    res = await this.httpApiService.getCustomerDetails(id);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
      if (!!this.customerDetails) {
        this.disc1 = this.customerDetails.disc1;
        this.disc2 = this.customerDetails.disc2;
        // this.selectedTermList = []
        // this.termList.filter(el => {
        //   if (el.termDays == this.customerDetails.terms) {
        //     this.selectedTermList.push({ id: el.id, name: el.name })
        //     this.termName = el.name;
        //   }
        // })
      }
    }
  }

  // transporter detail 

  async getTransporterDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        this.transporterDetails = data.data;
      }
    }
  }

  async getAllSalesPerson() {
    let roleName = '';
    if (this.type == 'SS') {
      roleName = 'Sales Stock Manager - Single'
    } else {
      roleName = 'Sales Stock Manager - Mix'
    }
    // console.log('getAllSalesPerson');
    // console.log(this.type);
    // console.log(roleName);

    let location = await this.configService.getFromLocation()
    let res = await this.httpApiService.getAllSalesPerson(
      location,
      roleName
    );
    let data = res.json();
    // console.log(res);
    // console.log(data);

    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.salesPersonList = data.data;
    }
  }


  async getBrokerDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        data.data.filter(el => {
          if (!!el.contactNo) {
            el.brokerWithContact = el.partyName + " - " + el.contactNo
          }
          else if (!!el.tel) {
            el.brokerWithContact = el.partyName + " - " + el.tel
          } else {
            el.brokerWithContact = el.partyName
          }
        })
        let noneData: any;
        data.data.filter((el, i) => {
          if (el.partyName == 'NONE') {
            noneData = el;
            data.data.splice(i, 1);
          }
        })
        data.data.unshift(noneData)
        this.brokerList = data.data
      }
    }
  }
  //tags

  onAddItem(event: {
    component: IonicSelectableComponent
  }) {
    // Clean form.
    this.tagName = ''

    // Show form.
    event.component.showAddItemTemplate();
  }

  async getMoreTags(event) {
    this.pageNumber++;
    await this.findTags(0, this.pageNumber * this.noOfRecords, '');
    event.component.endInfiniteScroll();
  }

  async searchItem(event: { component: IonicSelectableComponent; text: string }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    await this.findTags(this.pageNumber, this.noOfRecords, searchText);
  }

  async createNewTag() {
    let res: any;
    let arr = []
    arr.push(this.tagName)
    if (arr.length > 0) {
      let jsonObj = {
        setOfTags: arr
      }
      await this.configService.showLoader()
      res = await this.httpApiService.saveTags(jsonObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        // Show list.
        this.selectableComponent.hideAddItemTemplate();
        this.findTags(this.pageNumber = 0, this.noOfRecords, '')
      }

    } else {
      await this.configService.showToast('error', 'Please add tag')
    }
  }

  async findTags(pageNumber, noOfRecords, searchText) {
    let res: any;
    res = await this.httpApiService.findTagsBySearch(pageNumber, noOfRecords, searchText);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data)
      this.tagList = data.data.content
    }
  }

  onChange(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (event.value.length > 0) {
      this.formData['setOfTags'] = event.value.map(el => el.id)
    } else {
      delete this.formData['setOfTags']
    }
  }

  async approveOrder() {
    let error: Boolean = false;
    for (let i = 0; i < this.formDetailList.length; i++) {
      if (this.formDetailList[i].isRequired) {
        if (!this.formData[this.formDetailList[i].keyName] && this.formDetailList[i].keyName != 'spreadRate' && this.formDetailList[i].keyName != 'currencyRate') {
          if (this.formDetailList[i].keyName == 'shippingCost' && this.formData[this.formDetailList[i].keyName] == 0) {
          } else {
            await this.configService.showToast('error', this.formDetailList[i].name + ' is required.')
            error = true
            break;
          }
        }
      }
    }
    if (this.selectedCurrency == 'Indian Rupee' && !this.formData['currencyRate']) {
      this.configService.showToast("error", 'Please add exchange rate');
      error = true
    }
    if (error) {
      return false;
    } else {
      console.log(this.formData)
      this.callApproveFunction.emit(this.formData);
    }
  }

  async getTerm(transType) {
    let res: any;
    res = await this.httpApiService.findByTransTypeAndOrder(transType);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValueList = data.data;
      let data1 = []
      if (!!dataValueList && dataValueList.length > 0) {
        dataValueList.forEach(el => {
          if (el.isActive == true) {
            data1.push(el)
          }

        })
        this.termList = data1;

        let advanceExist = this.termList.find(el => el.name == 'ADVANCE')
        if (!!advanceExist) {
          this.selectedTermList.push({ id: advanceExist.id, name: advanceExist.name })
          this.termName = advanceExist.name;
          this.formData['termName'] = this.termName
        }
      }
      console.log("termList ", this.termList);
    }
  }


  async getMoreParty(event) {
    this.pageNumber++;
    await this.getAllCustomer(0, this.pageNumber * this.noOfRecords, '');
    event.component.endInfiniteScroll();
  }

  async searchParty(event: { component: IonicSelectableComponent; text: string }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    await this.getAllCustomer(this.pageNumber, this.noOfRecords, searchText);
  }

  onChangeParty(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (!!event.value) {
      this.formData['partyMasterId'] = event.value.id;
        this.formData["shippingCost"] = event.value.shippingCost;
      this.selectedCustomer = event.value.id;
      this.lab = event.value.partyName;
      this.getAddressesByPartyId(event.value.id)
      this.getAllCustomerContact();
      this.getCustomerDetails(event.value.id);
    } else {
      delete this.formData['partyMasterId'];
      this.selectedCustomer = null;
    }
  }

  async getAllCustomer(pageNumber, noOfRecords, searchText) {
    let res: any;
    let json = {
      listOfType: ['Export', 'Import', 'Local Sales']
    }
    res = await this.httpApiService.findByListOfPartyTypePagination(json, this.groupType, pageNumber, noOfRecords, searchText);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllCustomers = data.data.content;
    }
  }

  // customer reference party

  async getCustomerRefParty(pageNumber, noOfRecords, searchText) {
    let res: any;
    res = await this.httpApiService.findRefCustomerPagination(pageNumber, noOfRecords, searchText);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.refPartyList = data.data.content;
    }
  }

  async searchRefCustomer(event: { component: IonicSelectableComponent; text: string }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    await this.getCustomerRefParty(this.pageNumber, this.noOfRecords, searchText);
  }

  onChangeRefCustomer(event: { component: IonicSelectableComponent; value: any }) {
    console.log("onChangeRefCustomer:", event.value);
    if (!!event.value) {
      this.formData['customerReferenceId'] = event.value.id
    } else {
      delete this.formData['customerReferenceId'];
    }
  }

  onAddRefName(event: {
    component: IonicSelectableComponent
  }) {
    // Clean form.
    this.refCustomerName = '';
    this.refDescription = '';
    // Show form.
    event.component.showAddItemTemplate();
  }

  async createRefCustomer() {
    let res: any;
    if (!!this.refCustomerName) {
      let jsonObj = {
        name: this.refCustomerName,
        description: this.refDescription,
      }
      await this.configService.showLoader();
      res = await this.httpApiService.createRefCustomer(jsonObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.selectableRefPersonComponent.hideAddItemTemplate();
        this.getCustomerRefParty(this.pageNumber = 0, this.noOfRecords, '')
      }

    } else {
      await this.configService.showToast('error', 'Please add name')
    }
  }

  async validateExSpread(ev) {
    if (ev && ev.detail.value < 0) {
      await this.configService.showToast('error', 'Please add proper value')
    }
  }

}
