import {
  Component,
  OnInit,
  Input,
  ViewChild,
  HostListener,
} from "@angular/core";

import {
  ModalController,
  NavParams,
  LoadingController,
  AlertController,
  Events,
} from "@ionic/angular";

import * as moment from "moment";

import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ScanRFIDPage } from "../../actions/scan-rfid/scan-rfid.page";
import { ManageRequestsPage } from "../../actions/manage-requests/manage-requests.page";
import { PdAssortDetailsPage } from "../../actions/pd-assort-details/pd-assort-details.page";

@Component({
  selector: "app-manage-inventory-manager",
  templateUrl: "./manage-inventory-manager.page.html",
  styleUrls: ["./manage-inventory-manager.page.scss"],
})
export class ManageInventoryManagerPage implements OnInit {
  @ViewChild("dataTable") public dataTable: any;

  @Input() dataValue: any;

  public deptName: any;
  public data: any;
  public flag: any;
  public listOfStones: any = [];
  public transferredList: any = [];
  public pickedList: any = [];
  public deliveredList: any = [];
  public requestedStoneList: any = [];
  public getData: any;
  public boxNo: any = null;
  public inwardStoneList: any = [];
  public suggestedStoneList: any = [];
  public selectedStones = [];
  public boxList: any = [];
  public availableSpace: any;
  public totalPieces: number;
  public type: any;
  public sendStoneAction: any;
  public sendStoneList = [];
  public filtered = [];
  public boxType: any;
  public boxLevel: any;
  public columns: any;
  public page = "inManagerPage";
  public isCriteria = false;
  public count: any;
  public noPagination = true;
  public userId: any;
  public userData: any;
  public listOfStoneForLED = [];
  public showParamBox = false;
  public stoneId: any;
  public stoneParams: any;
  public listOfCriteria: any;
  public criteriaId: any;
  public boxTransType: any;
  public currentStoneCount: number;
  public maxCapacity: number;
  public departmentName = "";
  public criteriaIdList = [];
  public cssFlag: boolean;
  public inventoryManagerType: any;
  public transferStoneList = [];
  public boxDetail = [];
  public columnsPick = [];
  public isSelect = false;
  public selected = [];
  public successMsg: any;
  public errorMsg: any;
  public errorMsgList = [];
  public msgStatus = false;
  public boardBaseUrl: any;
  public RFidList: any = [];
  public scannedRFid: any = [];
  public arr1: any;
  public stockBarcodeList: any = [];
  public scanCount = 0;
  public ledOnCount = 0;
  public ledOnStonesIndex: any = [];
  public loadGrid = false;
  public ledOn = false;
  public ledOffList = [];
  public pageSize = 50;
  public currentScanCount = 0;
  public companyId: any;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public event: Events
  ) {
    this.data = this.navParams.get("data");
    this.flag = this.navParams.get("type");
    this.inventoryManagerType = this.navParams.get("inventoryManagerType");
    this.deptName = this.navParams.get("deptName");
    console.log("data", this.data, this.flag);
    if (this.flag == 2 || this.flag == 3) {
      this.columns = [
        {
          name: "Sr No",
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          },
        },
        { name: "StoneId", prop: "stoneId" },
        { name: "Cts", prop: "stoneCts" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Purity", prop: "clarity" },
        { name: "Program Name", prop: "programmeNo" },
        //{ name: "Result", prop: "isAssortmentDone" }
      ];
    } else if (this.flag == 4) {
      this.columnsPick = [
        {
          name: "Sr No",
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          },
        },
        { name: "StoneId", prop: "stoneId" },
        { name: "Box Name", prop: "boxName" },
        { name: "Rfid", prop: "rfid" },
        { name: "Cts", prop: "stoneCts" },
        { name: "Amount", prop: "requestAmt" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Purity", prop: "clarity" },
        { name: "Program Name", prop: "programmeNo" },
        { name: "Status", prop: "status" },
      ];
      this.columns = [
        {
          name: "Sr No",
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          },
        },
        { name: "StoneId", prop: "stoneId" },
        { name: "Box Name", prop: "boxName" },
        { name: "Cts", prop: "stoneCts" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Purity", prop: "clarity" },
        { name: "Program Name", prop: "programmeNo" },
        { name: "Result", prop: "isAssortmentDone" },
      ];
    } else {
      this.columns = [
        {
          name: "Sr No",
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          },
        },
        { name: "StoneId", prop: "stoneId" },
        { name: "Cts", prop: "stoneCts" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Program Name", prop: "programmeNo" },
        { name: "Purity", prop: "clarity" },
        { name: "Amount", prop: "requestAmt" },
        { name: "Status", prop: "status" },
      ];
    }
    if (this.flag == 0) {
      this.findRequestedStoneById(this.data.id);
    } else if (this.flag == 1) {
      this.findByVoucherIdOfPendingAssortmentStocks(this.data.id);
    } else if (this.flag == 2) {
      this.findByVoucherIdOfReceivedAssortmentStocks(this.data.id);
    } else if (this.flag == 3) {
      this.findByVoucherIdOfBoxedStocks(this.data.id);
    } else if (this.flag == 4) {
      this.findByVoucherIdOfOutRequestStocks(this.data.id);
    } else if (this.flag == 5) {
      this.findByVoucherIdOfPickedStock(this.data.id);
    } else if (this.flag == 6) {
      this.findByVoucherIdOfDeliveredStock(this.data.id);
    } else if (this.flag == 7) {
      this.findByVoucherIdOfTransferredStock(this.data.id);
    }
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode == 27) {
      if (
        this.boardBaseUrl != null &&
        this.boardBaseUrl != undefined &&
        this.boardBaseUrl != ""
      ) {
        this.httpApiService.scanLedOff(this.boardBaseUrl, this.ledOffList);
      }
      this.ledOffList = [];
      this.modalCtrl.dismiss();
    }
  }

  async ngOnInit() {
    this.companyId = await this.authService.getCompanyId();
    this.getUserData();
    console.log(this.flag);
  }

  dropDownSelection(ev) {
    console.log(ev);
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "criteria") {
      if (idList.length > 0) {
        this.criteriaId = idList[0];
        this.boxList.forEach((el) => {
          if (el.criteriaIdList) {
            el.criteriaIdList = [];
          }
        });
        this.boxList.filter((el) => {
          if (el.id == masterId) {
            el.criteriaIdList = [];
            el.listOfCriterias.forEach((item) => {
              if (item.id == this.criteriaId) {
                el.criteriaIdList.push({ id: item.id, name: item.name });
              }
            });
          }
        });
        this.selectedStoneCriteriaWise(this.criteriaId);
      } else {
        this.event.publish("availableSpace", {
          availableSpace: 0,
          boxNo: 0,
        });
        this.criteriaId = null;
      }
    } else if (property == "boxCriteria") {
      if (idList.length > 0) {
        this.criteriaId = idList[0];
        this.boxList.forEach((el) => {
          if (el.criteriaIdList) {
            el.criteriaIdList = [];
          }
        });
        this.boxList.filter((el) => {
          if (el.id == masterId) {
            el.criteriaIdList = [];
            el.listOfCriterias.forEach((item) => {
              if (item.id == this.criteriaId) {
                el.criteriaIdList.push({ id: item.id, name: item.name });
              }
            });
          }
        });
        this.selectedStoneCriteriaWise(this.criteriaId);
      } else {
        this.event.publish("availableSpace", {
          availableSpace: 0,
          boxNo: 0,
        });
        this.criteriaId = null;
      }
    } else if (property == "outCriteria") {
      if (idList.length > 0) {
        this.criteriaId = idList[0];
        this.boxList.forEach((el) => {
          if (el.criteriaIdList) {
            el.criteriaIdList = [];
          }
        });
        this.boxList.filter((el) => {
          if (el.id == masterId) {
            el.criteriaIdList = [];
            el.listOfCriterias.forEach((item) => {
              if (item.id == this.criteriaId) {
                el.criteriaIdList.push({ id: item.id, name: item.name });
              }
            });
          }
        });
        this.selectedStoneCriteriaWise(this.criteriaId);
      } else {
        this.event.publish("availableSpace", {
          availableSpace: 0,
          boxNo: 0,
        });
        this.criteriaId = null;
      }
    }
  }

  async showResultOfAssortPD(event) {
    console.log("data isss", event.row.data);
    let data = event.row.data;
    if (data.isAssortmentDone) {
      const modal = await this.modalCtrl.create({
        component: PdAssortDetailsPage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: {
          rowData: data,
        },
      });
      modal.onDidDismiss().then((d: any) => this.handleModalResultDismiss(d));
      return await modal.present();
    }
  }

  handleModalResultDismiss(d) { }
  async showParameter(item) {
    this.showParamBox = true;
    this.stoneId = item.stoneId;
    let stoneParams = {
      color: "Near Colorless",
      clarity: "VVS2",
      Cut: "EXC",
      Polish: "EXC",
      Lab: "GIA",
    };
    this.stoneParams = Object.entries(stoneParams);
    console.log(this.stoneParams);
  }
  async closeParamBox() {
    this.stoneId = null;
    this.showParamBox = false;
  }

  async findRequestedStoneById(id) {
    let res: any;
    await this.configService.showLoader();
    res = await this.httpApiService.findRequestedStockByVoucherId(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.requestedStoneList = data.data;
      console.log("requestedStoneList", this.requestedStoneList);
    }
  }

  async findByVoucherIdOfPendingAssortmentStocks(id) {
    let res: any;
    await this.configService.showLoader();
    res = await this.httpApiService.findByVoucherIdOfPendingAssortmentStocks(
      id
    );
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.inwardStoneList = data.data;
      this.count = this.inwardStoneList.length;
      console.log("inwardStoneList", this.inwardStoneList);
    }
  }

  async findByVoucherIdOfReceivedAssortmentStocks(id) {
    await this.getUserData();
    let res: any;
    await this.configService.showLoader();
    if (this.type == "KAM Sales") {
      this.boxLevel = "user";
      this.boxType = "Regular";
    } else if (
      this.type == "Assortment" &&
      this.data &&
      this.data.type != "Assortment/PD Return"
    ) {
      this.boxLevel = "department";
      this.boxType = "Regular";
    } else if (
      this.departmentName == "Sales" &&
      this.data.shortCode == "ICRI"
    ) {
      this.boxLevel = "department";
      this.boxType = "Regular";
    } else if (
      this.data.type == "Assortment/PD Return" ||
      this.data.shortCode == "ICRI"
    ) {
      this.boxLevel = "department";
      this.boxType = "Assortment";
    } else {
      this.boxLevel = "department";
      this.boxType = "Regular";
    }
    res = await this.httpApiService.findByVoucherIdOfReceivedAssortmentStocks(
      id,
      this.boxType,
      this.boxLevel
    );
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.suggestedStoneList = dataValue.packets;
      this.boxList = dataValue.boxes;
      this.count = this.suggestedStoneList.length;
      console.log(this.boxList);
      if (this.boxList.length == 1) {
        if (
          this.boxList[0].transTypeName == "MIX" &&
          this.boxList[0].listOfCriterias.length > 0
        ) {
          this.boxList[0]["criteriaIdList"] = [];
          this.boxList[0].criteriaIdList.push({
            id: this.boxList[0].listOfCriterias[0].id,
            name: this.boxList[0].listOfCriterias[0].name,
          });
          this.criteriaId = this.boxList[0].listOfCriterias[0].id;
          this.boxNo = this.boxList[0].listOfCriterias[0].boxId;
          this.selectedStoneCriteriaWise(this.criteriaId);
        } else {
          this.cssFlag = true;
          this.criteriaId = this.boxList[0].listOfCriterias[0].id;
          this.getBoxDetail(this.boxList[0], true);
        }
      }
      if (this.suggestedStoneList.length == 0) {
        this.modalCtrl.dismiss(this.flag);
      }
      // dataValue.boxes
      console.log("dataValue", dataValue, this.suggestedStoneList);
    }
  }

  async findByVoucherIdOfBoxedStocks(id) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findByVoucherIdOfBoxedStocks(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("data", data.data);
      let dataValue = data.data;
      this.suggestedStoneList = dataValue.packets;
      this.boxList = dataValue.boxes;
      this.count = this.suggestedStoneList.length;
      if (this.boxList.length == 1) {
        if (
          this.boxList[0].transTypeName == "MIX" &&
          this.boxList[0].listOfCriterias.length > 0
        ) {
          this.boxList[0]["criteriaIdList"] = [];
          this.boxList[0].criteriaIdList.push({
            id: this.boxList[0].listOfCriterias[0].id,
            name: this.boxList[0].listOfCriterias[0].name,
          });
          this.criteriaId = this.boxList[0].listOfCriterias[0].id;
          this.selectedStoneCriteriaWise(this.criteriaId);
        } else {
          this.cssFlag = true;
          this.criteriaId = this.boxList[0].listOfCriterias[0].id;
          this.getBoxDetail(this.boxList[0], true);
        }
      }
      // dataValue.boxes
      console.log("dataValue", dataValue);
    }
  }

  async findByVoucherIdOfOutRequestStocks(id) {
    await this.configService.showLoader();
    this.suggestedStoneList = [];
    this.boxList = [];
    let res: any;
    res = await this.httpApiService.findByVoucherIdOfOutRequestStocks(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("data", data.data);
      let dataValue = data.data;
      this.boxList = dataValue.boxes;
      this.suggestedStoneList = dataValue.packets;
      if (this.boxList.length > 0) {
        if (
          this.boxList[0].transTypeName == "MIX" &&
          this.boxList[0].listOfCriterias.length > 0
        ) {
          this.boxList[0]["criteriaIdList"] = [];
          this.boxList[0].criteriaIdList.push({
            id: this.boxList[0].listOfCriterias[0].id,
            name: this.boxList[0].listOfCriterias[0].name,
          });
          this.criteriaId = this.boxList[0].listOfCriterias[0].id;
          await this.selectedStoneCriteriaWise(this.criteriaId);
        } else {
          this.cssFlag = true;
          this.criteriaId = this.boxList[0].listOfCriterias[0].id;
          // await this.getBoxDetail(this.boxList[0], false);
        }
      }
      if (this.suggestedStoneList.length == 0) {
        this.modalCtrl.dismiss(this.flag);
      }
      // dataValue.boxes
      console.log("dataValue", dataValue);
    }
  }

  async findByVoucherIdOfPickedStock(id) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findOutGoingPickedStockByVoucherId(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("data", data.data);
      let dataValue = data.data;
      this.pickedList = dataValue;
      if (this.pickedList.length > 0) {
      } else {
        this.modalCtrl.dismiss(this.flag);
      }
      console.log("dataValue", dataValue);
    }
  }

  async findByVoucherIdOfTransferredStock(id) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findOutGoingStockByVoucherId(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("data", data.data);
      let dataValue = data.data;
      this.transferredList = dataValue;
      console.log("dataValue", dataValue);
    }
  }

  async findByVoucherIdOfDeliveredStock(id) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findDeliveredStockByVoucherId(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("data", data.data);
      let dataValue = data.data;
      this.deliveredList = dataValue;
      console.log("dataValue", dataValue);
    }
  }

  async getBoxDetail(item, spaceCheck) {
    console.log(item);
    // this.criteriaIdList = null
    this.boxTransType = item.transTypeName;
    this.boxNo = item.id;
    this.maxCapacity = item.maxCapacity;
    this.currentStoneCount = item.currentStoneCount;

    if (this.boxTransType == "SS") {
      this.cssFlag = true;
      this.criteriaId = item.criteriaId;
      this.selectedStones = [];
      this.listOfStoneForLED = [];
      this.availableSpace = this.maxCapacity - this.currentStoneCount;
      this.suggestedStoneList.forEach((element) => {
        if (spaceCheck) {
          if (
            element.criteriaId == item.criteriaId &&
            this.selectedStones.length < this.availableSpace
          ) {
            this.selectedStones.push(element.id);
            this.listOfStoneForLED.push(element.rfid);
          }
        } else {
          if (element.criteriaId == item.criteriaId) {
            this.selectedStones.push(element.id);
            this.listOfStoneForLED.push(element.rfid);
          }
        }
      });
      if (this.selectedStones.length > 0) {
        this.totalPieces = this.selectedStones.length;
      }
      this.event.publish("availableSpace", {
        availableSpace: this.availableSpace,
        boxNo: item.criteriaId,
      });
    } else if (this.boxTransType == "MIX") {
      // this.selectedStones = []
      // this.criteriaId = null
      this.listOfStoneForLED = [];
    } else {
      this.cssFlag = true;
      this.criteriaId = item.criteriaId;
      this.selectedStones = [];
      this.listOfStoneForLED = [];
      this.suggestedStoneList.forEach((element) => {
        if (element.criteriaId == item.criteriaId) {
          this.selectedStones.push(element.id);
          this.listOfStoneForLED.push(element.rfid);
        }
      });
      if (this.selectedStones.length > 0) {
        this.totalPieces = this.selectedStones.length;
      }
      this.event.publish("availableSpace", {
        availableSpace: 10000000,
        boxNo: item.criteriaId,
      });
    }
  }

  async selectedStoneCriteriaWise(criteriaId) {
    this.cssFlag = false;
    this.selectedStones = [];
    // this.availableSpace = this.maxCapacity - this.currentStoneCount;
    console.log(this.suggestedStoneList, this.criteriaId);
    this.suggestedStoneList.forEach((element) => {
      if (element.criteriaId == criteriaId) {
        this.selectedStones.push(element.id);
        this.listOfStoneForLED.push(element.rfid);
      }
    });
    this.event.publish("availableSpace", {
      availableSpace: 10000000,
      boxNo: criteriaId,
    });
  }

  async closeModal() {
    if (!!this.boardBaseUrl) {
      await this.httpApiService.scanLedOff(
        this.boardBaseUrl,
        this.ledOffList.length ? this.ledOffList.length : [1, 2]
      );
    }
    this.ledOffList = [];
    this.modalCtrl.dismiss(this.flag);
  }

  async addStoneInBox() {
    console.log(this.selectedStones);
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        boxId: this.boxNo,
        criteriaId: this.criteriaId,
        setOfStoneIds: this.selectedStones,
      };
      console.log(jsonObj);
      res = await this.httpApiService.addStoneToBox(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.findByVoucherIdOfReceivedAssortmentStocks(this.data.id);
        // this.modalCtrl.dismiss(this.flag);
      }
    }
  }

  async pickStoneFromBox() {
    let validate = await this.validateControlsForPickStone();
    if (validate) {
      if (!!this.boardBaseUrl) {
        await this.httpApiService.scanLedOff(this.boardBaseUrl, [1, 2]);
      }
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        setOfStoneIds: this.selectedStones,
        companyId: this.companyId,
      };
      console.log(jsonObj);
      res = await this.httpApiService.pickStoneFromBox(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.selected = [];
        this.selectedStones = [];
        this.ledOnStonesIndex = [];
        this.findByVoucherIdOfOutRequestStocks(this.data.id);
      }
    }
  }

  async validateControls() {
    console.log(this.selectedStones, this.boxNo);
    if (
      this.selectedStones.length != 0 &&
      this.boxNo != undefined &&
      this.boxNo != "" &&
      this.boxNo != null
    ) {
      return false;
    } else {
      return true;
    }
  }

  async validateControlsForPickStone() {
    console.log(this.selectedStones, this.boxList);
    if (this.selectedStones.length > 0 || this.boxList.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
    if (userData.departmentName) {
      this.departmentName = userData.departmentName;
    }
  }

  async merge_array(array1, array2) {
    let result_array = [];
    let arr = array1.concat(array2);
    let len = arr.length;
    let assoc = {};

    while (len--) {
      let item = arr[len];
      if (!assoc[item]) {
        result_array.unshift(item);
        assoc[item] = true;
      }
    }
    return result_array;
  }

  async openLedOnModel() {
    this.boxDetail = [];
    if (!!this.boardBaseUrl) {
      this.currentScanCount = 0;
      await this.httpApiService.scanLedOff(this.boardBaseUrl, this.ledOffList);
      let rfIdList = [];
      this.scannedRFid = [];
      this.scanCount = 0;
      let res;
      await this.configService.showLoader();
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      if (data.type == "error") {
        this.configService.dismiss();
        await this.configService.showToast("error", data.type);
      } else {
        if (data.result.length > 0) {
          data.result = data.result.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          });
          this.count = data.result.length;
          this.scannedRFid = data.result;
          let jsonObj;
          jsonObj = {
            paramName: "rfid",
            setOfValues: data.result,
            companyId: this.companyId,
          };
          let res2 = await this.httpApiService.getStoneIdByRfid(jsonObj);
          let resData = res2.json();
          this.boxDetail = resData.data.boxRfIdsList;

          this.scanCount = this.scannedRFid.length;
          if (this.boxDetail.length > 0) {
            this.scanCount = this.scanCount - this.boxDetail.length;
          }
          for (let i = 0; i < this.suggestedStoneList.length; i++) {
            let rfId = this.suggestedStoneList[i].rfid;
            if (this.scannedRFid.indexOf(rfId) > -1) {
              let ledOnCount = this.ledOnStonesIndex.filter(
                (el) => el.id == this.suggestedStoneList[i].id
              ).length;
              if (ledOnCount == 0) {
                this.currentScanCount = this.currentScanCount + 1;
                rfIdList.push(rfId);
                this.ledOnStonesIndex.push(this.suggestedStoneList[i]);
              }
              let selectedCount = this.selectedStones.filter(
                (el) => el == this.suggestedStoneList[i].id
              ).length;
              if (selectedCount == 0) {
                this.ledOffList.push(this.suggestedStoneList[i].rfid);
              }
            }
          }
          if (this.boxDetail.length == 1) {
            data.result = data.result.filter(
              (rf) => this.boxDetail.filter((bd) => bd.rfid == rf).length == 0
            );
            let DTOInventory = {
              boxId: this.boxDetail[0].id,
              setOfRfids: data.result,
            };
            await this.httpApiService.updateInventory(DTOInventory);
          }
          this.ledOn = true;
          setTimeout(() => {
            this.ledOn = false;
          }, 1000);
          this.configService.dismiss();
          if (rfIdList.length > 0) {
            await this.httpApiService.scanWithLightOn(
              this.boardBaseUrl,
              rfIdList
            );
          } else {
            this.configService.dismiss();
            await this.configService.showToast("error", "RFID not found!");
          }
        } else {
          await this.configService.showToast(
            "error",
            `Stones not found on Scanner Board!`
          );
          this.configService.dismiss();
        }
      }
    } else {
      this.configService.dismiss();
      await this.configService.showToast(
        "error",
        "Scanner Board Url not found"
      );
    }
  }

  selectOutRequestData(ev) {
    let dataArr = [];
    if (ev.currentDeselectedRowKeys.length > 0) {
      let deselectIds = ev.currentDeselectedRowKeys.map((el) => el.id);
      this.selectedStones = this.selectedStones.filter(function (el) {
        return deselectIds.indexOf(el) < 0;
      });
    }
    dataArr = ev.selectedRowKeys.filter(
      (el) => this.selectedStones.filter((item) => item == el.id).length == 0
    );
    this.selectedStones = this.selectedStones.concat(
      dataArr.map((el) => el.id)
    );
    console.log(this.selectedStones, this.ledOnStonesIndex);
  }

  async handleLedOnModalDismiss(d) {
    console.log("d", d);
  }

  async openScanModel() {
    const modal = await this.modalCtrl.create({
      component: ScanRFIDPage,
      backdropDismiss: false,
      componentProps: {
        type: "receive",
      },
      cssClass: "largePage",
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async handleModalDismiss(d) {
    console.log("d", d);
    this.findByVoucherIdOfPendingAssortmentStocks(this.data.id);
  }

  // Received Incoming stones by selection

  async receiveIncomingStones() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";

    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      setOfValues: this.transferStoneList,
      voucherDate: moment().format("YYYY-MM-DD"),
      companyId: this.companyId,
    };
    res = await this.httpApiService.receivePendingStoneConfirmation(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.type);
    } else if (res.status == 400) {
      if (data.data) {
        this.errorMsgList = data.data;
        for (let i = 0; i < data.data.length; i++) {
          this.errorMsgList[i].ids = data.data[i].StoneId.join(", ");
        }
        console.log(this.errorMsgList);
        this.errorMsg = data.message;
        this.msgStatus = true;
      } else {
        await this.configService.showToast("error", data.error);
      }
    } else {
      let stoneCount = data.data.length > 0 ? data.data[0].StoneId.length : "";
      await this.configService.showToast(
        "success",
        `${stoneCount} stones received successfully.`
      );
      this.transferStoneList = [];
      this.selected = [];
      this.isSelect = false;
      this.findByVoucherIdOfPendingAssortmentStocks(this.data.id);
    }
  }
  //END

  //Selection & select All

  onSelect(ev, itemList) {
    this.selected = ev.selectedRowsData;
    this.selectedStones = [];
    this.transferStoneList = [];
    this.selected.forEach((el) => {
      this.transferStoneList.push(el.stoneId);
      this.selectedStones.push(el.id);
    });
    if (this.selected.length == itemList.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }

  async selectAll(item, itemList) {
    if (item == true) {
      let arr = [];
      this.selected = arr.concat(itemList);
      this.isSelect = true;
      this.transferStoneList = [];
      this.selected.forEach((el) => {
        this.transferStoneList.push(el.stoneId);
      });
    } else {
      this.isSelect = false;
      this.selected = [];
      this.transferStoneList = [];
    }
    console.log(this.transferStoneList);
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split("/", 3).join("/").length;
      return stoneId
        .split(stoneId.substring(index + 1, stoneId.length))
        .join(
          stoneId
            .substring(index + 1, stoneId.length)
            .slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))
        );
    } else {
      return stoneId;
    }
  }

  async closeDiv() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
  }

  //END

  //

  async openModal() {
    let modalPage = await this.modalCtrl.create({
      component: ManageRequestsPage,
      backdropDismiss: false,
      componentProps: {
        voucherRequestData: this.data,
        inventoryManagerType: this.inventoryManagerType,
      },
      cssClass: "requestPage",
    });
    modalPage
      .onDidDismiss()
      .then((d: any) => this.handleRequestModalDismiss(d));
    return await modalPage.present();
    // return modalPage.present();
  }

  async handleRequestModalDismiss(d) {
    this.findByVoucherIdOfPickedStock(this.data.id);
  }

  //Release stones Request

  async releaseStoneConfirm(ev) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to release these stones?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteRequestedStones(ev.row.data.id);
          },
        },
      ],
    });

    await alert.present();
  }

  async deleteRequestedStones(stoneId) {
    let res: any;
    let voucherAndStoneIdsList: any = [];
    await this.configService.showLoader();
    voucherAndStoneIdsList.push({
      voucherId: this.data.id,
      stoneIds: [stoneId],
    });
    let payload = {
      voucherAndStoneIdsList: voucherAndStoneIdsList,
      isSvcRequest: false,
    };
    console.log(payload);
    res = await this.httpApiService.deleteRequestedStones(payload);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.dismiss();
      this.findByVoucherIdOfOutRequestStocks(this.data.id);
      await this.configService.showToast("success", data.message);
    }
  }
}
