import { Component, OnInit, ViewChild, Input } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import * as moment from "moment";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";

@Component({
  selector: 'app-manage-offer-details',
  templateUrl: './manage-offer-details.page.html',
  styleUrls: ['./manage-offer-details.page.scss'],
})
export class ManageOfferDetailsPage implements OnInit {
  @ViewChild("dataTable") public dataTable: any;

  public columns: any = [];
  public offerDetails: any = [];
  public isNext: boolean = true;
  public totalAmount = 0;
  public totalCts: any = 0;
  public item: any;
  public selected = []
  public isSelect: Boolean = false;
  public isConfirm: Boolean = false;
  public setOfStoneIds: any = []
  public location = "";
  public partyTypeList = []
  public formFields: any;
  public formData: any;
  public formDetailList = [];
  public termList = [];
  public termName = '';
  public listOfAddress = []
  public selectedAddressIdList = [];
  public listDiscount: any;
  public listPrice: any;
  public listAmount: any;
  public offeredlistDiscount: any;
  public offeredlistPrice: any;
  public offeredlistAmount: any;
  public selectedTermList = []
  public page = "websiteoffer"
  public totalSummary = [];

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public alertCtrl: AlertController,
    public configService: ConfigService,
    public exportAsExcelService: ExportAsExcelService,) { }

  async ngOnInit() {
    this.item = await this.navParams.get('item')

    this.getAddressesByPartyId(this.item.partyMasterId)
    this.columns = [
      { name: "Sr No", prop: "-" },
      { name: "Stone Id", prop: "stoneName" },
      { name: "Cts", prop: "cts" },
      { name: "Rapnet", prop: "rapnet" },
      { name: "Disc", prop: "discountPercent" },
      { name: "Price", prop: "price" },
      { name: "Amt", prop: "-" },
      { name: "KG Counter %", prop: "kgDiscountPercent", allowEdit: true },
      { name: "KG Counter Price", prop: "kgPrice", allowEdit: true },
      { name: "KG Counter Amt", prop: "kgOfferAmount", allowEdit: true },
      { name: "Offer %", prop: "offerDiscountPercent" },
      { name: "Offer Price", prop: "offerPrice" },
      { name: "Offer Amt", prop: "offerAmt" },
      { name: "Is Matched", prop: "isMatched" },
      { name: "Status", prop: "status" },
      // { name: "Reject", prop: "-" },
    ];

    if (this.item.isListOffer) {
      this.columns.push({ name: "Reject", prop: "-" })
    }
    this.totalSummary = [
      {
        "fieldName": "rapnet",
        "summaryType": "sum"
      },
      {
        "fieldName": "kgDiscountPercent",
        "summaryType": "avg"
      },
      {
        "fieldName": "kgPrice",
        "summaryType": "avg"
      },
      {
        "fieldName": "kgOfferAmount",
        "summaryType": "sum"
      },
      {
        "fieldName": "offerDiscountPercent",
        "summaryType": "avg"
      },
      {
        "fieldName": "offerPrice",
        "summaryType": "avg"
      },
      {
        "fieldName": "offerAmt",
        "summaryType": "sum"
      }
    ]
    this.findWebsiteOfferById()
    await this.getTerm('SS');
    if (this.item.termName) {
      this.termList.filter(el => {
        if (el.name == this.item.termName) {
          this.selectedTermList.push({ id: el.id, name: el.name })
          this.termName = el.name;
        }
      })
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh')
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "term") {
      if (idList.length > 0) {
        this.termList.filter(el => {
          if (el.id == idList[0]) {
            this.termName = el.name;
          }
        })
      }
      else {
        this.location = ''
      }
    } else if (property == "address") {
      if (idList.length > 0) {
        this.location = obj[0].address;
      } else {
        this.location = ''
      }
    }
  }

  // get offer detail by id

  async findWebsiteOfferById() {
    let res: any;
    await this.configService.showLoader()
    res = await this.httpApiService.findWebsiteOfferById(
      this.item.id
    );
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.offerDetails = data.data;
      console.log(this.offerDetails)
      let totalamount = 0;
      this.totalCts = 0;
      let kgTotalAmount = 0;
      let rapnetTotal = 0;
      if (this.offerDetails.length == 0) {
        this.modalCtrl.dismiss()
      } else {
        this.offerDetails.forEach(el => {
          el.error = false;
          el.isChanged = false;
          el.oldKgDiscountPercent = el.kgDiscountPercent;
          el.oldkgPrice = el.kgPrice
          el.oldKgOfferAmount = el.kgOfferAmount
          if (el.kgDiscountPercent == el.offerDiscountPercent) {
            el.isMatched = true;
          } else {
            el.isMatched = false;
          }
          el.offerPrice = (el.offerPrice).toFixed(2);
          el.offerAmt = (el.offerPrice * el.cts).toFixed(2);
          el.kgOfferAmount = (el.kgPrice * el.cts).toFixed(2);
          totalamount += el.offerPrice * el.cts;
          kgTotalAmount += el.kgPrice * el.cts;
          rapnetTotal += el.rapnet * el.cts;
        })
        this.offerDetails.filter(el => this.totalCts += el.cts)
        this.totalCts = Number(this.totalCts.toFixed(3))
        this.listDiscount = Number(((1 - (kgTotalAmount / rapnetTotal)) * 100).toFixed(2));
        this.offeredlistDiscount = Number(((1 - (totalamount / rapnetTotal)) * 100).toFixed(2));
        this.listPrice = Number((kgTotalAmount / this.totalCts).toFixed(2));
        this.offeredlistPrice = Number((totalamount / this.totalCts).toFixed(2));
        this.listAmount = Number((this.totalCts * this.listPrice).toFixed(2));
        this.offeredlistAmount = Number((this.totalCts * this.offeredlistPrice).toFixed(2));

        console.log(this.listDiscount, this.listPrice, this.listAmount)
        // this.updateList(this.listDiscount)
      }
    }
  }

  updateList(value) {
    let totalamount = 0;
    let rapnetTotal = 0;
    this.offerDetails.forEach(el => {
      el.kgDiscountPercent = parseFloat(value);
      el.kgPrice = Number((el.rapnet - (el.rapnet * el.kgDiscountPercent / 100)).toFixed(2));
      el.kgOfferAmount = Number((el.kgPrice * el.cts).toFixed(2));
      el.isChanged = true;
      totalamount += el.kgPrice * el.cts;
      rapnetTotal += el.rapnet * el.cts;
    })
    this.listPrice = Number((totalamount / this.totalCts).toFixed(2));
    this.listAmount = Number((this.totalCts * this.listPrice).toFixed(2));
    console.log(this.listDiscount, this.listPrice, this.listAmount)
  }

  updateKgPrice() {
    this.offerDetails.forEach(el => {
      el.isChanged = true;
      el.kgDiscountPercent = el.offerDiscountPercent;
      el.kgPrice = el.offerPrice;
      el.kgOfferAmount = el.offerPrice * el.cts;
    })
  }

  //update Offer

  async updateOffer() {

    let arr = []
    console.log(this.offerDetails)
    this.offerDetails.forEach(el => {
      if (el.isChanged || el.isMatched) {
        arr.push({
          "id": el.id,
          "kgDiscountPercent": el.kgDiscountPercent,
          "kgPrice": el.kgPrice
        })
      }
    })
    let jsonObj = {
      id: this.item.id,
      listOfOfferStones: arr
    };
    let res;
    if (arr.length > 0) {
      await this.configService.showLoader();
      console.log(jsonObj);
      res = await this.httpApiService.updateOffer(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast(
          "success",
          data.message
        );
        this.findWebsiteOfferById();
      }
    } else {
      await this.configService.showToast(
        "error",
        'You have not changed anything!'
      );
    }

  }


  //update price in offer offerDetails

  offerValueUpdate(bool) {
    console.log(bool)
  }

  async updateValue(event, cell, rowIndex) {
    this.offerDetails[rowIndex][cell] = parseFloat(event.target.value);
    this.offerDetails = [...this.offerDetails];
    this.isNext = true;
    this.offerDetails[rowIndex].error = true;
    if (cell == 'kgDiscountPercent') {
      if (this.offerDetails[rowIndex]['oldKgDiscountPercent'] != event.target.value) {
        this.offerDetails[rowIndex]['isChanged'] = true;
      } else {
        this.offerDetails[rowIndex]['isChanged'] = false;
      }
      this.offerDetails[rowIndex].kgPrice = (this.offerDetails[rowIndex].rapnet * ((100 - this.offerDetails[rowIndex].kgDiscountPercent) / 100)).toFixed(2);
      this.offerDetails[rowIndex].kgOfferAmount = (this.offerDetails[rowIndex].kgPrice * this.offerDetails[rowIndex].cts).toFixed(2);
    } else if (cell == 'kgPrice') {
      if (this.offerDetails[rowIndex]['oldkgPrice'] != event.target.value) {
        this.offerDetails[rowIndex]['isChanged'] = true;
      } else {
        this.offerDetails[rowIndex]['isChanged'] = false;
      }
      let offerPer = ((this.offerDetails[rowIndex].rapnet - this.offerDetails[rowIndex]['kgPrice']) * 100) / this.offerDetails[rowIndex].rapnet;
      this.offerDetails[rowIndex]['kgDiscountPercent'] = offerPer.toFixed(2);
      this.offerDetails[rowIndex].kgOfferAmount = (this.offerDetails[rowIndex].kgPrice * this.offerDetails[rowIndex].cts).toFixed(2);
    } else if (cell == 'kgOfferAmount') {
      if (this.offerDetails[rowIndex]['oldKgOfferAmount'] != event.target.value) {
        this.offerDetails[rowIndex]['isChanged'] = true;
      } else {
        this.offerDetails[rowIndex]['isChanged'] = false;
      }
      this.offerDetails[rowIndex].kgPrice = this.offerDetails[rowIndex].kgOfferAmount / this.offerDetails[rowIndex].cts
      let offerPer = ((this.offerDetails[rowIndex].rapnet - this.offerDetails[rowIndex]['kgPrice']) * 100) / this.offerDetails[rowIndex].rapnet;
      this.offerDetails[rowIndex]['kgDiscountPercent'] = offerPer.toFixed(2);
    }
    this.offerDetails.forEach(item => {
      if ((parseFloat(item.kgDiscountPercent) < parseFloat(item.discountPercent)) || (parseFloat(item.kgDiscountPercent) > parseFloat(item.offerDiscountPercent))) {
        this.isNext = false;
        item.error = true;
      } else {
        item.error = false;
      }
      if (item.kgDiscountPercent == item.offerDiscountPercent) {
        item.isMatched = true;
      } else {
        item.isMatched = false;
      }
    })
  }

  //Start Delete Offer 

  async confirmRemoveOffer(event) {
    let item = event.row.data
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to reject this offer?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteOfferStone(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteOfferStone(offerStoneId) {
    let res: any;
    res = await this.httpApiService.rejectPartialOffer(this.item.id, offerStoneId);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.findWebsiteOfferById();
    }
  }

  //END

  onSelect(ev, itemList) {
    this.selected = ev.selected;
    this.setOfStoneIds = []
    this.selected.forEach(el => {
      this.setOfStoneIds.push(el.stoneId)
    });
    if (this.selected.length == itemList.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }

  async selectMatched(item, itemList) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(itemList)
      // itemList.forEach(el => {
      //   if (el.kgDiscountPercent == el.offerDiscountPercent) {
      //     this.selected.push(el)
      //   } else {
      //     el.isMatched = false;
      //   }
      // })
      this.isSelect = true;
      this.setOfStoneIds = []
      this.selected.forEach(el => {
        this.setOfStoneIds.push(el.stoneId)
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.setOfStoneIds = []
    }
    console.log(this.setOfStoneIds)
  }

  async doCustomerConfirm() {
    let validate = await this.validateControls()
    if (validate) {
      await this.configService.showLoader()
      let res: any;
      let jsonData = {
        id: this.item.id,
        setOfStoneIds: this.setOfStoneIds,
        modeOfTransport: "Delivery - Standard",
        location: this.location,
        requestFor: "Customer Confirm",
        termName: this.termName
      }
      res = await this.httpApiService.approveOffer(jsonData);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 400) {
        if (!!data.data && data.data.length > 0) {
          await this.configService.showToast("error", data.message);
          // let excelData = [];
          // data.data.forEach(el => {
          //   if (el.StoneId) {
          //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
          //   }
          // })
          await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        } else {
          await this.configService.showToast("error", data.message);
        }
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.selected = []
        this.setOfStoneIds = []
        this.location = ''
        this.modalCtrl.dismiss()
      }
    }
  }

  async validateControls() {
    if (!!this.termName && !!this.location && this.setOfStoneIds.length > 0) {
      return true;
    } else {
      await this.configService.showToast('error', 'Please fill the details');
      return false;
    }
  }

  //term master 

  async getTerm(transType) {
    let res: any;
    res = await this.httpApiService.findByTransTypeAndOrder(transType);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValueList = data.data;
      let data1 = []
      if (!!dataValueList && dataValueList.length > 0) {
        dataValueList.forEach(el => {
          if (el.isActive == true) {
            data1.push(el)
          }
        })
        this.termList = data1;
      }
      console.log("termList ", this.termList);
    }
  }

  async getAddressesByPartyId(id) {
    let res: any;
    res = await this.httpApiService.getAddressesByPartyId(id);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfAddress = data.data;
      if (this.listOfAddress.length > 0) {
        this.selectedAddressIdList = []
        this.listOfAddress.forEach(el => {
          if (el.isDefault) {
            this.selectedAddressIdList.push({ id: el.id, address: el.address })
            this.location = el.address;
          }
        })
      }
    }
  }

  //Start Delete Offer 

  async confirmRemoveListOffer() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to remove this list offer ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.rejectListOffer();
          }
        }
      ]
    });

    await alert.present();
  }

  async rejectListOffer() {
    let res: any;
    res = await this.httpApiService.rejectOffer(this.item.id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.modalCtrl.dismiss()
    }
  }


  updateListOffer(type) {
    if (type == 'listDiscount') {
      let totalamount = 0;
      let rapnetTotal = 0;
      this.offerDetails.forEach(el => {
        el.kgDiscountPercent = parseFloat(this.listDiscount);
        el.kgPrice = Number((el.rapnet - (el.rapnet * el.kgDiscountPercent / 100)).toFixed(2));
        el.kgOfferAmount = Number((el.kgPrice * el.cts).toFixed(2));
        el.isChanged = true;
        totalamount += el.kgPrice * el.cts;
        rapnetTotal += el.rapnet * el.cts;
      })
      this.listPrice = Number((totalamount / this.totalCts).toFixed(2));
      this.listAmount = Number((this.totalCts * this.listPrice).toFixed(2));
      console.log(this.listDiscount, this.listPrice, this.listAmount)
    }
  }

  validateOffer(ev) {
    console.log(ev)
    // this.isv
  }
}
