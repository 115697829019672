import { Component, OnInit, HostListener } from "@angular/core";
import { NavParams, ModalController, LoadingController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-manage-stone-inward",
  templateUrl: "./manage-stone-inward.page.html",
  styleUrls: ["./manage-stone-inward.page.scss"]
})
export class ManageStoneInwardPage implements OnInit {
  public factoryOutWNo: any;
  public companyName: any;
  public createdDate: Date;
  public stoneIdentifier: any = [];
  public selectedIdentifier: any = [];
  public setOfStoneIds: any = [];
  public total = 0;
  public voucherId = 0;
  public totalCrt: any;
  public caretAdded = 0;
  public option: any;
  public page = "stoneDetail"
  public columns = [];
  public count: Number = 0;

  constructor(
    private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public httpApiService: HttpApiService,
    public configService: ConfigService
  ) {
    let data = this.navParams.get("data");
    console.log(data);
    this.companyName = data.companyName;
    this.createdDate = data.createdDate;
    this.factoryOutWNo = data.factoryOutWNo;
    this.voucherId = data.id;
    this.option = this.navParams.get("option");
    if (this.option == 'FACT. O/W NO WISE') {
      this.findAllByVoucherId();
    } else if (this.option == 'PROGRAM WISE') {
      this.findAllStoneByProgramId();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event.keyCode)
    if (event.keyCode == 27) {
      this.modalCtrl.dismiss(1);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    }, { name: "Stone Id", prop: "stoneId" }, { name: "Type", prop: "transType" }, { name: "Shape", prop: "shape" }, { name: "Color", prop: "color" }, { name: "Cts", prop: "stoneCts" }]
  }

  async findAllStoneByProgramId() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findPendingToReceiveByProgramId(this.voucherId);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneIdentifier = data.data;
      let total = 0;
      for (let i in this.stoneIdentifier) {
        total = total + parseFloat(this.stoneIdentifier[i].stoneCts);
      }
      this.totalCrt = total.toFixed(3);
      this.stoneIdentifier.forEach(el => {
        el.isSelected = true;
        this.setOfStoneIds.push(el.id);
      });
      if (this.setOfStoneIds.length > 0) {
        this.total = this.setOfStoneIds.length;
      } else {
        this.total = 0;
      }
      this.caretAdded = 0;
      for (let i = 0; i < this.setOfStoneIds.length; i++) {
        let data = this.stoneIdentifier.filter(item => {
          if (item.id == this.setOfStoneIds[i]) {
            return item;
          }
        });
        let crtSum = parseFloat(data[0].stoneCts) + this.caretAdded;
        this.caretAdded = parseFloat(crtSum.toFixed(3));
      }
    }
  }

  async findAllByVoucherId() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findAllByVoucherId(this.voucherId);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneIdentifier = data.data;
      if (this.stoneIdentifier.length > 0) {
        this.count = this.stoneIdentifier.length;
      }
      let total = 0;
      for (let i in this.stoneIdentifier) {
        total = total + parseFloat(this.stoneIdentifier[i].stoneCts);
      }
      this.totalCrt = total.toFixed(3);
      this.stoneIdentifier.forEach(el => {
        el.isSelected = true;
        this.setOfStoneIds.push(el.id);
      });
      if (this.setOfStoneIds.length > 0) {
        this.total = this.setOfStoneIds.length;
      } else {
        this.total = 0;
      }
      this.caretAdded = 0;
      for (let i = 0; i < this.setOfStoneIds.length; i++) {
        let data = this.stoneIdentifier.filter(item => {
          if (item.id == this.setOfStoneIds[i]) {
            return item;
          }
        });
        let crtSum = parseFloat(data[0].stoneCts) + this.caretAdded;
        this.caretAdded = parseFloat(crtSum.toFixed(3));
      }
    }
  }

}
