import { Component, OnInit, HostListener } from "@angular/core";
import { ModalController, Events, NavParams, LoadingController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ExportAsExcelService } from "src/app/services/export-as-excel.service";

@Component({
  selector: "app-manage-assortment",
  templateUrl: "./manage-assortment.page.html",
  styleUrls: ["./manage-assortment.page.scss"]
})
export class ManageAssortmentPage implements OnInit {
  filteredList: any;
  assorterList = [];
  text: string;

  public companyName: any;
  public stoneIdentifier: any = [];
  public createdDate: any;
  public modifiedDate: any;
  public factoryOutWNo: any;
  public voucherId: any;
  public allotTo: any = null;
  public data: any;
  public flag: any;
  public totalCrt: any;
  public setOfStoneIds = [];
  public setAllAssorters = [];
  public total = 0;
  public caretAdded = 0;
  public selectedAssorter: any;
  public items: any = [];
  public test: any;
  public voucherBookId: any;
  public userId: any;
  public actionId: any;
  public selectRow: number;
  public assroterAlloterList = [];
  public assorterIdList = [];
  public requiredMinVotes: number;
  public manageStock: boolean = false;
  public fromRole: any;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public event: Events,
    public configService: ConfigService,
    public exportAsExcelService: ExportAsExcelService,
  ) {
    this.data = this.navParams.get("data");
    this.flag = this.navParams.get("type");
    this.actionId = this.navParams.get("actionId");
    console.log("data", this.data, this.flag);
    if (this.flag == 1 || this.flag == 2) {
      this.companyName = this.data.companyName;
      this.createdDate = this.data.createdDate;
      this.modifiedDate = this.data.modifiedDate;
      this.factoryOutWNo = this.data.factoryOutWNo;
      this.voucherId = this.data.id;
    } else {
      this.stoneIdentifier = this.data;
      this.setItems();
      let total = 0;
      for (let i in this.stoneIdentifier) {
        total = total + parseFloat(this.stoneIdentifier[i].stoneCts);
      }
      console.log(total)
      this.total = this.stoneIdentifier.length;
      this.selectRow = this.total;
      this.totalCrt = total.toFixed(3);
      this.caretAdded = parseFloat(total.toFixed(3));
      this.stoneIdentifier.forEach(el => {
        this.setOfStoneIds.push(el.id);
        el.isSelected = true;
      });
    }

    this.getAllAssorters();
    this.getDefaultSetting();
    if (this.flag == 1) {
      this.findByVoucherIdPendingAssignment();
    } else if (this.flag == 2) {
      this.findAssignedAssortmentByVoucherId();
    } else {
    }
  }

  ngOnInit() {
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'allotedTo') {
      if (idList.length > 0) {
        this.allotTo = idList[0];
      } else {
        this.assroterAlloterList = []
        this.allotTo = null;
        this.assorterIdList = []
        this.assorterList.forEach(el => {
          el.isSelected = false;
        })
      }
    }
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.fromRole) {
          this.fromRole = parseInt(dataValue.map.fromRole)
        }
      }
    }
  }

  async allotToAssorter(item) {
    this.allotTo = item;
  }
  async findAssignedAssortmentByVoucherId() {
    let res: any;
    res = await this.httpApiService.findAssignedAssortmentByVoucherId(
      this.voucherId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneIdentifier = data.data.stoneList;
      this.selectedAssorter = data.data.voterList;
      if (data.data.manageStock) {
        this.manageStock = data.data.manageStock;
      }
      this.assroterAlloterList = data.data.voterList;
      this.allotTo = data.data.allotedVoterId;
      this.requiredMinVotes = data.data.requiredMinVotes;
      if (data.data.allotedVoterId) {
        this.assorterIdList = [];
        data.data.voterList.forEach(el => {
          if (el.id == data.data.allotedVoterId) {
            let data = { id: el.id, username: el.username };
            this.assorterIdList.push(data);
          }
        });
      }
      console.log("selectedAssorter", this.selectedAssorter);
      await this.setItems();
      let total = 0;
      for (let i in this.stoneIdentifier) {
        total = total + parseFloat(this.stoneIdentifier[i].stoneCts);
        console.log("total", total);
      }
      console.log(this.allotTo);
      this.totalCrt = total.toFixed(3);
      this.stoneIdentifier.forEach(el => {
        el.isSelected = true;
        this.setOfStoneIds.push(el.id);
      });

      if (this.setOfStoneIds.length > 0) {
        this.total = this.setOfStoneIds.length;
      } else {
        this.total = 0;
      }
      this.selectRow = this.total
      this.caretAdded = 0;
      for (let i = 0; i < this.setOfStoneIds.length; i++) {
        let data = this.stoneIdentifier.filter(item => {
          if (item.id == this.setOfStoneIds[i]) {
            return item;
          }
        });
        let crtSum = parseFloat(data[0].stoneCts) + this.caretAdded;
        this.caretAdded = parseFloat(crtSum.toFixed(3));
      }
    }
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event.keyCode)
    if (event.keyCode == 27) {
      this.modalCtrl.dismiss();
    }
  }

  async getAllAssorters() {
    await this.configService.showLoader();
    let location = await this.configService.getFromLocation()
    let res = await this.httpApiService.getAllAssorters(
      location
    );
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.assorterList = data.data;
      this.filteredList = this.assorterList;
      if (this.selectedAssorter) {
        this.assorterList.forEach(obj => {
          var existNotification = this.selectedAssorter.find(
            ({ id }) => obj.id === id
          );
          console.log("existNotification", existNotification);
          if (existNotification) {
            obj.isSelected = true;
            this.setAllAssorters.push(obj.id);
          }
        });
        console.log("assorterList", this.assorterList);
      } else {
        this.assorterList.forEach(el => {
          el.isSelected = false;
          // this.setAllAssorters.push(el.id);
          // console.log("this.allotTo", this.allotTo);
          // if (this.allotTo == null) {
          //   this.allotTo = el.id;
          // }
        });
        console.log("assorterList", this.assorterList)
      }
    }
  }

  async onInputChange(input) {
    let val = input.target.value;
    this.setAssorterList();
    if (val && val.trim() !== "") {
      this.assorterList = await this.assorterList.filter(item => {
        return item.username.toLowerCase().indexOf(val.toLowerCase()) > -1;
      });
    } else {
      this.assorterList = this.filteredList;
    }
  }
  async setAssorterList() {
    this.assorterList = this.filteredList;
  }
  async findByVoucherIdPendingAssignment() {
    let res: any;
    res = await this.httpApiService.findByVoucherIdPendingAssignment(
      this.voucherId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneIdentifier = data.data;
      console.log("stoneIdentifier", this.stoneIdentifier);
      await this.setItems();
      let total = 0;
      for (let i in this.stoneIdentifier) {
        total = total + parseFloat(this.stoneIdentifier[i].stoneCts);
      }
      this.totalCrt = total.toFixed(3);
      this.stoneIdentifier.forEach(el => {
        el.isSelected = false;
        // this.setOfStoneIds.push(el.id);
      });

      if (this.setOfStoneIds.length > 0) {
        this.total = this.setOfStoneIds.length;
      } else {
        this.total = 0;
      }
      this.caretAdded = 0;
      for (let i = 0; i < this.setOfStoneIds.length; i++) {
        let data = this.stoneIdentifier.filter(item => {
          if (item.id == this.setOfStoneIds[i]) {
            return item;
          }
        });
        let crtSum = parseFloat(data[0].stoneCts) + this.caretAdded;
        this.caretAdded = parseFloat(crtSum.toFixed(3));
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async selectedItem(item) {
    this.setOfStoneIds = [];
    this.stoneIdentifier.forEach(el => {
      if (el.id == item.id) {
        el.isSelected = !el.isSelected;
      }
      if (el.isSelected == true) {
        this.setOfStoneIds.push(el.id);
      }
    });
    console.log(this.setOfStoneIds);
    if (this.setOfStoneIds.length > 0) {
      this.total = this.setOfStoneIds.length;
    } else {
      this.total = 0;
    }
    this.selectRow = this.total
    this.caretAdded = 0;
    for (let i = 0; i < this.setOfStoneIds.length; i++) {
      let data = this.stoneIdentifier.filter(item => {
        if (item.id == this.setOfStoneIds[i]) {
          return item;
        }
      });
      let crtSum = parseFloat(data[0].stoneCts) + this.caretAdded;
      this.caretAdded = parseFloat(crtSum.toFixed(3));
    }
  }

  async selectedAssorterItem(item) {
    if (this.allotTo == item.id) {
      this.allotTo = null;
      this.assorterIdList = [];
    }
    this.setAllAssorters = [];
    this.assroterAlloterList = [];
    for (let i = 0; i < this.assorterList.length; i++) {
      if (this.assorterList[i].id == item.id) {
        this.assorterList[i].isSelected = !this.assorterList[i].isSelected;
      }
      if (this.assorterList[i].isSelected == true) {
        this.assroterAlloterList.push(this.assorterList[i])
        this.setAllAssorters.push(this.assorterList[i].id);
        if (this.allotTo == null) {
          this.allotTo = this.assroterAlloterList[0].id;
          let data = { id: this.assroterAlloterList[0].id, username: this.assroterAlloterList[0].username }
          this.assorterIdList.push(data)
        }
      }
    }
    if (this.allotTo == item.id) {
      this.allotTo = this.assroterAlloterList[0].id;
      let data = { id: this.assroterAlloterList[0].id, username: this.assroterAlloterList[0].username }
      this.assorterIdList.push(data)
    }
    // this.assorterList.forEach(el => {
    //   if (el.id == item.id) {
    //     el.isSelected = !el.isSelected;
    //     if (this.allotTo == item.id) {
    //       this.allotTo = null;
    //       this.assorterIdList = [];
    //     }
    //   }
    //   if (el.isSelected == true) {
    //     this.assroterAlloterList.push(el)
    //     this.setAllAssorters.push(el.id);
    //   }

    //   console.log(this.setAllAssorters.length)
    //   if (this.setAllAssorters.length == 1) {
    //     this.allotTo = this.setAllAssorters[0];
    //     let data = { id: this.assroterAlloterList[0].id, username: this.assroterAlloterList[0].username }
    //     this.assorterIdList.push(data)
    //   }
    // });
  }

  async assignAssortment() {
    console.log("setAllAssorters", this.setAllAssorters);
    console.log("setOfStoneIds", this.setOfStoneIds);
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj;
      if (!!this.requiredMinVotes && this.requiredMinVotes > 0) {
        jsonObj = {
          voucherId: this.voucherId,
          allotedVoterId: this.allotTo,
          companyName: this.configService.fromLocation,
          setOfVoterIds: this.setAllAssorters,
          setOfStoneIds: this.setOfStoneIds,
          requiredMinVotes: this.requiredMinVotes,
          fromRole: this.fromRole,
          voucherBookId: this.voucherBookId,
          manageStock: this.manageStock
        };
      } else {
        jsonObj = {
          voucherId: this.voucherId,
          allotedVoterId: this.allotTo,
          companyName: this.configService.fromLocation,
          fromRole: this.fromRole,
          setOfVoterIds: this.setAllAssorters,
          setOfStoneIds: this.setOfStoneIds,
          voucherBookId: this.voucherBookId
        };
      }

      console.log(jsonObj);
      res = await this.httpApiService.assignStoneAndAssorter(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.modalCtrl.dismiss(this.flag);
      }
    }
  }
  // ---------------------------------
  async validateControls() {
    if (this.setOfStoneIds.length == 0) {
      await this.configService.showToast("error", "Please select stones");
      return true;
    } else if (this.setAllAssorters.length == 0) {
      await this.configService.showToast("error", "Please select Assorter");
      return true;
    } else {
      return false;
    }
  }

  async validateControlsForAcceptAssortment() {
    if (this.setOfStoneIds.length > 0) {
      return false;
    } else {
      await this.configService.showToast("error", "Please select stones");
      return true;
    }
  }

  setItems() {
    this.items = this.stoneIdentifier;
  }

  async filterStones(ev: any) {
    let val = "";
    this.setItems();
    val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        if (
          item.stoneId &&
          item.stoneId.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        } else if (
          item.stoneCts &&
          item.stoneCts.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        }
      });
    } else {
      this.items = this.stoneIdentifier;
    }
  }

  async updateAssorters() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj;
      if (!!this.requiredMinVotes && this.requiredMinVotes > 0) {
        jsonObj = {
          voucherBookId: this.voucherBookId,
          allotedVoterId: this.allotTo,
          setOfVoterIds: this.setAllAssorters,
          requiredMinVotes: this.requiredMinVotes,
          setOfStoneIds: this.setOfStoneIds,
          fromRole: this.fromRole,
          manageStock: this.manageStock
        };
      } else {
        jsonObj = {
          voucherBookId: this.voucherBookId,
          allotedVoterId: this.allotTo,
          fromRole: this.fromRole,
          setOfVoterIds: this.setAllAssorters,
          setOfStoneIds: this.setOfStoneIds
        }
      }
      console.log(jsonObj);
      let voucherId = this.voucherId;
      res = await this.httpApiService.updateVotersAndStone(voucherId, jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.modalCtrl.dismiss(this.flag);
      }
    }
  }

  async deleteAssignment() {
    console.log("this.voucherId", this.voucherId);
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.deleteAssignment(this.voucherId);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.modalCtrl.dismiss(this.flag);
    }
  }

  async acceptAssortment() {
    let validate = await this.validateControlsForAcceptAssortment();
    if (!validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        listOfStoneIds: this.setOfStoneIds,
        roleId: this.fromRole
      };
      console.log(jsonObj);
      res = await this.httpApiService.acceptAssortmentWithoutAssortAndPD(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.message);
        if (data.data.length > 0) {
          // data.data.forEach(el => {
          //   if (el.StoneId) {
          //     // el.StoneId.forEach(item => {
          //     //   this.showStoneId(item)
          //     // })
          //     el.StoneId = el.StoneId.toString()
          //   }
          // })
          await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        }
      } else {
        await this.configService.showToast("success", data.message);
        this.modalCtrl.dismiss(this.flag);
      }
    }
  }

  async selectNoOfRow(item) {
    console.log(item, this.total)
    if (item != undefined && item != "" && item != null) {
      if (item <= this.items.length) {
        this.stoneIdentifier.forEach(el => {
          el.isSelected = false;
        });
        this.setOfStoneIds = [];
        let selectedArray = this.stoneIdentifier.slice(0, item);
        console.log(selectedArray)
        selectedArray.forEach(el => {
          el.isSelected = true;
          this.setOfStoneIds.push(el.id);
        });
        console.log(this.setOfStoneIds);
        if (this.setOfStoneIds.length > 0) {
          this.total = this.setOfStoneIds.length;
        } else {
          this.total = 0;
        }
        this.caretAdded = 0;
        for (let i = 0; i < this.setOfStoneIds.length; i++) {
          let data = selectedArray.filter(item => {
            if (item.id == this.setOfStoneIds[i]) {
              return item;
            }
          });
          let crtSum = parseFloat(data[0].stoneCts) + this.caretAdded;
          this.caretAdded = parseFloat(crtSum.toFixed(3));
        }
        console.log(this.items.length)
      } else {
        this.stoneIdentifier.forEach(el => {
          el.isSelected = false;
        });
        await this.configService.showToast('error', 'Maximum no.of stones available to be assigned for assortment is exhausted');
      }

    } else {
      if (item == 0) {
        await this.configService.showToast('error', 'Please enter valid number')
      }
    }
  }

  async checkValidNumber(no) {
    if (no != undefined && no != "" && no != null) {
      if (no > 0) {
        if (this.assorterList.length >= no) {

        } else {
          await this.configService.showToast('error', 'Maximum no.of assorter to be assigned for assortment is exhausted')
        }
      } else {
        await this.configService.showToast('error', 'Please enter valid number')
      }
    } else {
      if (no == 0) {
        await this.configService.showToast('error', 'Please enter valid number')
      }
    }
  }
}
