import { Component, EventEmitter, OnInit, Output } from "@angular/core";

import { ModalController } from "@ionic/angular";

import { PotentialBuyersService } from "src/app/services/potential-buyers/potential-buyers.service";
import { PotentialBuyersStoneSelectionPopupComponent } from "src/app/components/actions/potential-buyers/potential-buyers-stone-selection-popup/potential-buyers-stone-selection-popup.component";

@Component({
  selector: "app-btn-group-horizontal",
  templateUrl: "./btn-group-horizontal.component.html",
  styleUrls: ["./btn-group-horizontal.component.scss"],
})
export class BtnGroupHorizontalComponent implements OnInit {
  @Output() activatedBtnChange = new EventEmitter<string>();

  public stoneNames = [];
  public activatedBtn = "+ Add Stone";

  constructor(
    private potentialBuyersService: PotentialBuyersService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.potentialBuyersService.currStoneNames.subscribe(
      (stoneNamesArr: string[]) => {
        this.stoneNames = stoneNamesArr;
      }
    );
  }

  onChange(ev) {
    const clickedElement = ev.target;
    this.activatedBtn = clickedElement.textContent;
    this.potentialBuyersService.updateSelectedStone(this.activatedBtn);
    this.activatedBtnChange.emit(this.activatedBtn);
    this.potentialBuyersService.updatePotentialBuyerDetails([]);
  }

  async onAddStone() {
    const addStonePopup = await this.modalCtrl.create({
      component: PotentialBuyersStoneSelectionPopupComponent,
      cssClass: "add_stone_popup",
    });

    addStonePopup.present();
  }
}
