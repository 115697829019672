import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Events } from "@ionic/angular"
import * as moment from "moment";

@Component({
  selector: "app-stone-info",
  templateUrl: "./stone-info.component.html",
  styleUrls: ["./stone-info.component.scss"]
})
export class StoneInfoComponent implements OnInit {
  @Input() columns: any;
  @Input() rowData: any;
  @Input() tab: any;
  @Input() pageNumber: any;
  @Input() noOfRecords: any;
  @Input() count: any;
  @Input() page: any;
  @Input() noPagination: any;
  @Input() fromDepartment: any;
  @Input() flag: any;
  @Input() availableSpace: any;
  @Input() boxNo: any;
  @Output() callFunction: EventEmitter<any> = new EventEmitter();
  @Output() pageFunction: EventEmitter<any> = new EventEmitter();
  @Output() openBarcodeFunction: EventEmitter<any> = new EventEmitter();
  @Output() deleteFunction: EventEmitter<any> = new EventEmitter();
  @Output() showData: EventEmitter<any> = new EventEmitter();
  @Output() downloadFileData: EventEmitter<any> = new EventEmitter();
  @Output() showResultOfAssortPD: EventEmitter<any> = new EventEmitter();
  @Output() manageTemplates: EventEmitter<any> = new EventEmitter();
  @Output() manageTemplateFunction: EventEmitter<any> = new EventEmitter();
  @Output() downloadComparisonFileData: EventEmitter<any> = new EventEmitter();
  public selected: any = [];
  public myMesssages = { emptyMessage: "No data to display", totalMessage: "records" }

  constructor(public event: Events) { }

  ngOnInit() {
    if (this.page == 'inManagerPage') {
      this.event.subscribe("availableSpace", params => {
        this.availableSpace = params.availableSpace;
        this.boxNo = params.boxNo;
      })
    }

    console.log("*************", this.rowData, this.page, this.flag, this.tab)

  }
  call(event) {
    this.event.publish("selectedValue", this.selected);
    //console.log("selected",this.selected);
    if (event.type == 'click') {
      if (event.column.name != 'Barcode') {
        this.callFunction.emit(event);
      }
    } else {
      this.callFunction.emit(event);
    }

  }
  paginationFunction(event) {
    this.pageFunction.emit(event);
  }
  delete(row) {
    this.deleteFunction.emit(row);
  }
  showParams(row) {
    console.log(row)
    this.showData.emit(row);
  }

  downloadFile(row) {
    console.log(row)
    this.downloadFileData.emit(row);
  }

  downloadComparisonFile(row) {
    this.downloadComparisonFileData.emit(row);
  }

  pendingCaret(row) {
    if (!!row.pendingCarat) {
      return parseFloat(row.pendingCarat.toFixed(3));
    } else {
      if (!!row.totalCarat) {
        return parseFloat(row.totalCarat.toFixed(3));
      }
    }
  }

  pendingPackets(row) {
    if (!!row.pendingPackets) {
      return row.pendingPackets;
    } else {
      return row.totalPackets;
    }
  }

  showResult(row) {
    this.showResultOfAssortPD.emit(row);
  }

  showBarcode(row) {
    this.openBarcodeFunction.emit(row);
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  showParty(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }


  manageTemplate(row) {
    this.manageTemplateFunction.emit(row);
  }

  showDate(value) {
    if (!!value) {
      return moment.utc(value).local().format("DD-MM-YYYY");
    }
  }

  customUTCDateTime(value) {
    if (!!value) {
      return moment.utc(value).local().format('DD-MMM-YYYY hh:mm a');
    }
  }
}
