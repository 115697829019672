import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingController, AlertController, Events } from '@ionic/angular'
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpApiService } from 'src/app/services/http-api/http-api.service';
import { ProcessActionComponent } from '../../processAction';

@Component({
  selector: 'app-last-tray',
  templateUrl: './last-tray.component.html',
  styleUrls: ['./last-tray.component.scss']
})
export class LastTrayComponent implements ProcessActionComponent {
  @Input() data: any;
  columns = [{
    name: 'Sr No',
    cellTemplate: function (cellElement, cellInfo) {
      cellElement.append(cellInfo.row.rowIndex + 1);
    }
  }, {
    name: 'Stone Id', prop: 'stoneId',
  }, { name: 'Tray Name', prop: 'label' }]/*  */
  stoneList = "";
  filteredArr = [];
  companyId = null;
  lastTrayList = []

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public event: Events) {

    this.event.subscribe('Last Tray Detail', (item) => {
      this.columns = [{
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      }, {
        name: 'Stone Id', prop: 'stoneId',
      }, { name: 'Tray Name', prop: 'label' }]
    })
  }
  async ngOnInit() {
    this.companyId = await this.authService.getCompanyId();

  }

  ngOnDestroy() {
    this.event.unsubscribe('Last Tray Detail');
  }


  async fetchLastTrayofStoneIds() {
    this.lastTrayList = []
    this.filteredArr = this.stoneList.split("\n");
    let issueList = []
    this.filteredArr.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    this.filteredArr = issueList.filter(function (el) {
      return el != "";
    });
    await this.configService.showLoader();
    let res: any;
    let payload = {
      stoneIds: this.filteredArr,
      companyId: this.companyId
    }
    res = await this.httpApiService.fetchLastTrayofStoneIds(payload);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("data", data.data);
      this.lastTrayList = data.data;
      console.log(this.lastTrayList)
    }
  }

}
