import { Component, OnInit, ViewChild } from "@angular/core";

import {
  AlertController,
  LoadingController,
  ModalController,
  NavParams,
} from "@ionic/angular";

import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: "app-manage-purchase-inward",
  templateUrl: "./manage-purchase-inward.page.html",
  styleUrls: ["./manage-purchase-inward.page.scss"],
})
export class ManagePurchaseInwardPage implements OnInit {
  @ViewChild("dataTable") public dataTable: any;

  public selected = [];
  public listOfUnConfirmPurchaseData = [];
  public count = 0;
  public selectedStoneList = [];
  public isSelect = false;
  public columns = [];
  public flagModal: any;
  public voucherId: any;
  public voucherDetail = [];
  public page = "managePurchaseInward";
  public voucherCount = 0;
  public pageSize = 500;
  public selectedRecords = 0;
  public selectedCts = 0;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public alertCtrl: AlertController,
    public configService: ConfigService
  ) {}

  async ngOnInit() {
    this.flagModal = await this.navParams.get("flagModal");
    this.columns = [
      {
        name: "Sr No",
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        },
      },
      { name: "Party", prop: "partyName" },
      { name: "Invoice No", prop: "invoiceNo" },
      { name: "Invoice Date", prop: "invDate" },
      { name: "Sequences In Invoice ", prop: "srNo" },
      { name: "Cts", prop: "totalCarat" },
    ];
    console.log(this.flagModal);
    if (this.flagModal == "unconfirmPurchaseDetail") {
      this.columns = [
        {
          name: "Sr No",
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          },
        },
        {
          name: "Voucher No",
          prop: "",
          displayFormat: "Total",
          showInGroupFooter: true,
        },
        { name: "Party", prop: "partyName" },
        { name: "Invoice No", prop: "invoiceNo" },
        { name: "Invoice Date", prop: "invDate" },
        { name: "TRN Type", prop: "trnType" },
        {
          name: "Sequences In Invoice ",
          prop: "srNo",
          displayFormat: "{0}",
          showInGroupFooter: true,
          summaryType: "sum",
          fieldName: "srNo",
        },
        {
          name: "Cts",
          prop: "totalCarat",
          displayFormat: "{0}",
          showInGroupFooter: true,
          summaryType: "sum",
          fieldName: "totalCarat",
        },
      ];
      this.findUnConfirmPurchaseData();
    } else {
      this.columns = [
        {
          name: "Sr No",
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          },
        },
        { name: "Party", prop: "stoneId" },
        { name: "Ie Invoice No", prop: "invoiceNo" },
        { name: "Invoice Date", prop: "invDate" },
        { name: "Sequence No", prop: "srNo" },
        { name: "Ct.", prop: "stoneCts" },
      ];
      let data = this.navParams.get("data");
      console.log(data);
      this.voucherId = data.id;
      this.findAllByVoucherId();
    }
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split("/", 3).join("/").length;
      return stoneId
        .split(stoneId.substring(index + 1, stoneId.length))
        .join(
          stoneId
            .substring(index + 1, stoneId.length)
            .slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))
        );
    } else {
      return stoneId;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  // unconfirm purchase data

  async findUnConfirmPurchaseData() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findUnconfirmPurchaseData();
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfUnConfirmPurchaseData = data.data;
      this.count = this.listOfUnConfirmPurchaseData.length;
    }
  }

  // purchase confirm selection

  onSelect(ev) {
    this.selected = ev.selectedRowsData;
    this.selectedRecords = ev.selectedRowsData.length;
    this.selectedStoneList = [];
    this.selectedCts = 0;
    if (this.selected.length === 0) {
      this.selectedCts = 0;
    }
    this.selected.forEach((el) => {
      this.selectedStoneList.push(el.id);
      this.selectedCts += parseFloat(el.totalCarat);
    });
    this.selectedCts = Number(this.selectedCts.toFixed(3));
    // console.log(this.isSelect, this.selectedStoneList, this.selectedStoneList.length, this.listOfPendingAssortment.length)
    if (this.selected.length == this.listOfUnConfirmPurchaseData.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }

  async selectAll(item) {
    if (item == true) {
      let arr = [];
      this.selected = arr.concat(this.listOfUnConfirmPurchaseData);
      this.isSelect = true;
      this.selectedStoneList = [];
      this.selected.forEach((el) => {
        this.selectedStoneList.push(el.id);
      });
    } else {
      this.isSelect = false;
      this.selected = [];
      this.selectedStoneList = [];
    }
    console.log(this.selectedStoneList);
  }

  //confirm purchase Data

  async confirmPurchaseData() {
    let res: any;
    let jsonObj = {
      listOfVoucherId: this.selectedStoneList,
    };
    res = await this.httpApiService.confirmPurchaseData(jsonObj);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.selected = [];
      this.isSelect = false;
      await this.configService.showToast("success", data.message);
      await this.findUnConfirmPurchaseData();
    }
  }

  //get data by Voucher Id
  async findAllByVoucherId() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findPurchaseInStockByVoucherId(
      this.voucherId
    );
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.voucherDetail = data.data;
      this.voucherCount = data.data.length;
    }
  }

  async deleteItem(ev) {
    this.confirmDeleteData(ev.row.data);
  }

  async confirmDeleteData(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteData(item.id);
          },
        },
      ],
    });

    await alert.present();
  }

  async deleteData(id) {
    await this.configService.showLoader();
    let res = await this.httpApiService.deleteUnconfirmedPurchasedStones(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status === 400) {
      if (!!data.error) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("error", data.message);
      }
    } else if (res.status === 200) {
      await this.configService.showToast("success", data.message);
    } else {
      if (!!data.error) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("error", data.message);
      }
    }
    await this.findUnConfirmPurchaseData();
  }
}
