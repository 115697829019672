import { Component, Input, ViewChild } from "@angular/core";
import {
  NavController,
  ModalController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageMasterPage } from "../manage-master/manage-master.page";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-purchase-to-mix',
  templateUrl: './purchase-to-mix.component.html',
  styleUrls: ['./purchase-to-mix.component.scss']
})
export class PurchaseToMixComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild("dataTable") public dataTable: any;

  public selected = [];
  public columns = [];
  public listOfUnConfirmPurchaseData = [];
  public selectedStoneList = [];
  public isSelect = false;
  public stoneList = [];
  public fromCts: any;
  public toCts: any;
  public colorList = [];
  public clarityList = [];
  public shapeList = [];
  public colorIdList = [];
  public shapeIdList = [];
  public clarityIdList = [];
  public shapeId = [];
  public clarityId = [];
  public colorId = [];
  public selectedDataColumns = []
  public count: number = 0;
  public listOfBulkPurchasedDetail = [];
  public selectedCount: number = 0;
  public csspCount: number = 0;
  public isNext = false;
  public showSelected = true;
  public openSelectedcsspListFlag = true;
  public actionData: any;
  public csspList = [];
  public selectedCsspList = []
  public csspColumns = []
  public listOfLabs = []
  public selectedFile: File = null;
  public toRole: any;
  public userId: any;
  public voucherBookId: any;
  public actionId: any;
  public historyColumns = []
  public labId: any;
  public page: any = 'Purchase to mix';
  public historyCount: Number = 0;
  public historyData: any = [];
  public pageNumber = 0;
  public noOfRecords = 10;
  public transferCsspList = [];
  public sizeList = [];
  public sizeIdList = [];
  public isShow: Boolean = true;

  constructor(public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public event: Events) { }

  ngOnInit() {
    this.columns = [
      { name: "Party", prop: "stoneId" },
      { name: "Party code", prop: "partyCode" },
      { name: "Ct.", prop: "cts" },
      { name: "Invoice No", prop: "invoiceNo" },
      { name: "Seq No", prop: "serno" }
    ]
    this.csspColumns = [
      { name: "CSSP", prop: "stoneId" },
      { name: "Ct.", prop: "size" },
      { name: "Add Ct.", prop: "cts" },
    ];
    this.getAllPurchaseConfirmedStone();
    this.findParameters();
    this.findShapeByType()
    this.selectedDataColumns = [{ name: "Party", prop: "stoneId" },
    { name: "Party code", prop: "partyCode" },
    { name: "Ct.", prop: "cts" },
    { name: "Invoice No", prop: "invoiceNo" },
    { name: "Seq No", prop: "serno" }]
    this.event.subscribe('Purchase To Mix', (item) => {
      this.columns = [
        { name: "Party", prop: "stoneId" },
        { name: "Party code", prop: "partyCode" },
        { name: "Ct.", prop: "cts" },
        { name: "Invoice No", prop: "ieInvoiceNo" },
        { name: "Seq No", prop: "serno" }
      ]
      this.csspColumns = [
        { name: "CSSP", prop: "stoneId" },
        { name: "Ct.", prop: "size" },
        { name: "Add Ct.", prop: "cts" },
      ];
      this.selectedDataColumns = [{ name: "Party", prop: "stoneId" },
      { name: "Party code", prop: "partyCode" },
      { name: "Ct.", prop: "cts" },
      { name: "Invoice No", prop: "ieInvoiceNo" },
      { name: "Seq No", prop: "serno" }]
      this.resetData()
      this.getAllPurchaseConfirmedStone();
      this.findParameters();
      this.findShapeByType()
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Purchase To Mix');
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'colorIdList') {
      if (idList.length > 0) {
        this.colorId = idList;
      } else {
        this.colorId = [];
      }
    } else if (property == 'shapeIdList') {
      if (idList.length > 0) {
        this.shapeId = idList;
        this.sizeIdList = []
        console.log(idList, idList[0])
        this.findSizeByShape(this.shapeId)
        // this.sizeIdList = []
        // this.findSizeByShape(idList[0])
      } else {
        this.shapeId = [];
      }
    } else if (property == 'clarityIdList') {
      if (idList.length > 0) {
        this.clarityId = idList;
      } else {
        this.clarityId = [];
      }
    }
    else if (property == 'sizeList') {
      if (idList.length > 0) {
        this.sizeList.filter(el => {
          if (el.id == idList[0]) {
            this.fromCts = el.fromCts;
            this.toCts = el.toCts;
          }
        })
      } else {
        this.sizeIdList = []
      }
    }
  }

  async findShapeByType() {
    let res: any;
    res = await this.httpApiService.findShapeByType('Sale Bulk');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let data1 = []
      dataValue.forEach(el => {
        data1.push({ id: el.shape, name: el.shapeName })
      })
      this.shapeList = data1;
    }
  }

  async findSizeByShape(shapeId) {
    let res: any;
    res = await this.httpApiService.findSizeByShape(shapeId, 'Sale Bulk');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.sizeList = data.data;
      console.log(this.sizeList)
    }
  }

  //confirmed all purchase data

  async getAllPurchaseConfirmedStone() {
    let res: any;
    res = await this.httpApiService.getAllPurchaseConfirmedStone();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfBulkPurchasedDetail = data.data;
      this.count = this.listOfBulkPurchasedDetail.length;
    }
  }

  // BULK TO BULK  SELECTION

  onSelectPurchaseData(ev) {
    this.selected = ev.selectedRowsData;
    this.selectedStoneList = [];
    this.selected.forEach(el => {
      el.size = el.cts
      this.selectedStoneList.push(el);
    })
    this.selectedCount = this.selected.length
    console.log(this.selectedStoneList)
    this.isShow = false;
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.toString().indexOf("_"))
      // let index = stoneId.split('/', 3).join('/').length;
      // return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  buttonHideShow() {
    this.isShow = !this.isShow;
  }

  async findParameters() {
    let res: any;
    res = await this.httpApiService.findParametersForMixSizeMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.colorList) {
        let data1 = []
        dataValue.colorList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.colorList = data1;
      }
      if (dataValue.clarityList) {
        let data1 = []
        dataValue.clarityList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.clarityList = data1;
      }
      if (dataValue.shapeList) {
        let data1 = []
        dataValue.shapeList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.shapeList = data1;
      }
    }
  }

  async searchCSSP() {
    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      colorId: this.colorId,
      shapeId: this.shapeId,
      clarityId: this.clarityId,
      fromCts: this.fromCts,
      toCts: this.toCts,
      listOfBoxType: ['Purchase'],
      listOfStoneStatus: [5]
    }
    res = await this.httpApiService.findCsspOrCreateIfNotExist(jsonObj, 'true');
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.csspList = data.data;
      if (this.csspList.length > 0) {
        let flag = this.transferCsspList.some(el => el.id == this.csspList[0].id)
        if (!flag) {
          this.transferCsspList = this.transferCsspList.concat(this.csspList)
        }
        this.isNext = false;
        console.log(this.transferCsspList)
        this.csspCount = this.transferCsspList.length;
      } else {
        await this.configService.showToast('error', 'Searched Cssp not found.')
      }

    }
  }

  //update price in cart

  async updateValue(event, cell, rowIndex) {
    console.log('inline editing rowIndex', event.target.value, cell, rowIndex)
    this.transferCsspList[rowIndex][cell] = parseFloat(event.target.value);
    this.transferCsspList = [...this.transferCsspList];
    if (this.transferCsspList[rowIndex][cell] > this.selectedStoneList[0].cts ||
      this.transferCsspList[rowIndex][cell] == 0 ||
      this.transferCsspList[rowIndex][cell] == "") {
      this.isNext = false;
      this.transferCsspList[rowIndex]['errorCt'] = true;
    } else {
      this.transferCsspList[rowIndex]['errorCt'] = false;
      let totalCts = 0;
      this.transferCsspList.forEach(el => {
        totalCts += el.cts
      })
      console.log(this.transferCsspList, totalCts)
      if (totalCts <= this.selectedStoneList[0].cts) {
        this.isNext = true;
      } else {
        await this.configService.showToast('error', 'Size limit exceed!')
        this.isNext = false;
      }
    }

    console.log(this.isNext)
  }

  async convertPurchaseToBulk() {
    let csspStone = {}
    this.transferCsspList.forEach(el => {
      csspStone[el.id] = el.cts
    })
    let jsonObj = {
      stoneId: this.selectedStoneList[0].id,
      csspStone: csspStone
    }
    console.log(jsonObj)
    let res: any;
    res = await this.httpApiService.convertPurchaseToBulk(jsonObj);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.resetData()
      this.getAllPurchaseConfirmedStone();
    }
  }

  resetData() {
    this.isShow = true;
    this.csspList = []
    this.selected = []
    this.clarityId = []
    this.colorId = []
    this.sizeList = []
    this.shapeId = [];
    this.shapeIdList = []
    this.clarityIdList = []
    this.colorIdList = []
    this.transferCsspList = []
    this.sizeIdList = []
    this.selectedStoneList = [];
  };

}
