import { Component, Input, OnInit, HostListener } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { LoadingController, ModalController, AlertController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManageSkipAssortmentPage } from "../manage-skip-assortment/manage-skip-assortment.page";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: 'app-skip-assortment-criteria',
  templateUrl: './skip-assortment-criteria.component.html',
  styleUrls: ['./skip-assortment-criteria.component.scss']
})
export class SkipAssortmentCriteriaComponent implements OnInit {
  @Input() data: any;

  public columns = [];
  public page = 'skipAssortmentCriteria';
  public listOfCriteria = [];
  public listOfCriteriaDatasource: any = {};
  public count = 0;
  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 50;
  public searchValue = '';
  public manageSelectedStatus = '';
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public refreshGrid:boolean = false;

  constructor(private authService: AuthenticationService,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events) {
    this.deleteItem = this.deleteItem.bind(this);
  }

  ngOnInit() {
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "Name", prop: "name" },
    { name: "Trans Type", prop: "transTypeCode" },
    { name: "From Color", prop: "fromColorName" },
    { name: "To Color", prop: "toColorName" },
    { name: "From Shape", prop: "fromShapeName" },
    { name: "To Shape", prop: "toShapeName" },
    { name: "From Clarity", prop: "fromClarityName" },
    { name: "To Clarity", prop: "toClarityName" },
    { name: "From Size", prop: "fromCts" },
    { name: "To Size", prop: "toCts" },
    { name: "Is Active", prop: "isActive" },
    ];
    
    this.getAllCriteria(this.pageNumber, this.noOfRecords, '')
    this.event.subscribe('Status Master', (item) => {
      this.getAllCriteria(this.pageNumber, this.noOfRecords, '')
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Status Master');
  }

  async getAllCriteria(pageNumber, noOfRecords, searchText) {
    await this.configService.showLoader()
    this.listOfCriteriaDatasource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        await this.loadingController.dismiss()
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100,500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res =await this.httpApiService.findSkipAssortmentCriteriaPagination(pageNumber, this.noOfRecords, searchText);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfCriteria = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfCriteria,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
    // let res: any;
    // res = await this.httpApiService.findSkipAssortmentCriteriaPagination(pageNumber, noOfRecords, searchText);
    // let data = res.json();
    // await this.loadingController.dismiss()
    // if (data.status == 401) {
    //   await this.authService.logout()
    // } else if (data.status == 500) {
    //   await this.configService.showToast('error', data.error)
    // } else if (data.status == 400) {
    //   await this.configService.showToast('error', data.error)
    // } else {
    //   this.listOfCriteria = data.data.content;
    // }
  }

  async searchByValue(ev) {
    this.getAllCriteria(this.pageNumber, this.noOfRecords, !!this.searchValue ? this.searchValue : '')
  }

  async manageCriteria(item, flag) {
    this.refreshGrid = false;
    if ((item.type == "mouseenter") || (item.column && item.column.name == 'Action')) {
      item.event.stopPropagation();
    } else {
      if (flag == 'update' && this.updatePermissionFlag == true) {
        let modal = await this.modalCtrl.create({
          component: ManageSkipAssortmentPage,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: { item: item.row, flag: flag, }
        })
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
      if (flag == 'add' && this.addPermissionFlag == true) {
        let modal = await this.modalCtrl.create({
          component: ManageSkipAssortmentPage,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: { item: item,  flag: flag, }
        })
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
    }
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    if (d.data != 'doNotRefresh') {
      this.getAllCriteria(this.pageNumber, this.noOfRecords, '')
    }
  }

  //Start Delete Status 

  async confirmDeleteCriteria(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Criteria ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteCriteria(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteCriteria(id) {
    console.log(id)
    let res: any;
    res = await this.httpApiService.deleteSkipCriteria(id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.getAllCriteria(this.pageNumber, this.noOfRecords, '')
    }
  }

  async deleteItem(ev) {
    this.confirmDeleteCriteria(ev.row.data);
  }
  //END


}
