import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import CustomStore from 'devextreme/data/custom_store';
import Query from "devextreme/data/query";
import { ManageCertificationPage } from '../manage-certification/manage-certification.page';
import { AlertController, Events, ModalController } from '@ionic/angular';
import { ProcessActionComponent } from '../../processAction';

@Component({
  selector: 'app-sold',
  templateUrl: './sold.component.html',
  styleUrls: ['./sold.component.scss']
})
export class SoldComponent implements ProcessActionComponent {
  @Input() data: any;

  columns = [];
  pageNumber: number = 0;
  public noOfRecords = 20;
  public pageSize = 20;
  public page = "sold"
  public stoneDataSource: any = [];
  count: number = 0;
  stoneList: any = []
  public refreshGrid = false;

  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;

  constructor(
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    private authService: AuthenticationService,
    private alertController: AlertController,
    public modalCtrl: ModalController,
    public event: Events
  ) {
    console.log(this.data)


  }

  ngOnInit() {
    if (!!this.data && !!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.deletePermissionFlag)
    }
    this.columns = [
      { name: "Voucher Id", prop: "id" },
      { name: "Voucher No", prop: "" },
      { name: "Voucher Date", prop: "voucherDate" },
      { name: "Acc Invoice No", prop: "voucherLabel" },
      { name: "Acc Invoice Date", prop: "accInvoiceDate" },
      { name: "Party Name", prop: "partyName" },
      { name: "Pkts", prop: "totalPackets" },
      { name: "Ct", prop: "totalCarat" },
      { name: "Created By", prop: "createdBy" },
      { name: "Date & Time", prop: "createdDate" },

    ];
    this.getAllSoldVouchers();
    this.event.subscribe('Sold', (item) => {
      this.getAllSoldVouchers()
    })
  }


  ngOnDestroy() {
    this.event.unsubscribe('Sold');
  }

  async getAllSoldVouchers() {

    this.stoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log(loadOptions)
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findAllSold('Sold Confirmed', this.pageNumber, this.noOfRecords);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.stoneList = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.stoneList,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async onSelect(item) {
    if (!!item.row) {
      item = { itemData: item.row };
      console.log(item);
      this.refreshGrid = false;
      let modalPage = await this.modalCtrl.create({
        component: ManageCertificationPage,
        backdropDismiss: false,
        componentProps: { type: 4, data: item, title: 'Sold', partyType: [] },
        cssClass: 'extraLargePage'
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return modalPage.present();
    }
  }

  handleModalDismiss(d) {
    this.refreshGrid = true;
  }

  async deleteConfirm(item) {
    console.log(item)
    const alert = await this.alertController.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this sold voucher?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteSold(item.row.data);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteSold(item) {
    let res: any;
    res = await this.httpApiService.deleteSoldVoucher(item.id, 'Sold Confirmed');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.getAllSoldVouchers()
    }
  }
}
