import { Component, Input, OnInit } from '@angular/core';
import {
  ModalController,
  NavParams,
  LoadingController,
  Events,
  ToastController
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { IonicSelectableComponent } from "ionic-selectable";
import { SearchService } from "src/app/services/search/search.service";


@Component({
  selector: 'app-receiveincomingstones',
  templateUrl: './receiveincomingstones.component.html',
  styleUrls: ['./receiveincomingstones.component.scss']
})
export class ReceiveincomingstonesComponent implements OnInit {
  public listOfStones: any;
  public isCerti: boolean = false;
  public isBillNo: boolean = false;
  public voucherNo: string = '';

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public toastCtrl: ToastController,
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public event: Events,
    public loadingController: LoadingController
  ) {

  }

  ngOnInit() {


  }

  closeModal() {
    this.modalCtrl.dismiss()
  }

  async Receive() {
    await this.configService.showLoader();
    let obj = {
      'listOfStones': this.listOfStones,
      'isBillNo': this.isBillNo,
      'isCerti': this.isCerti,
      'voucherNo': this.voucherNo,
    }
    let res: any;
    res = await this.httpApiService.receiveStones(obj);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
    }
    await this.configService.dismiss();
    this.closeModal()
  }

}
