import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  OnChanges,
  HostListener,
  Renderer2,
  Inject,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {
  ActionSheetController,
  IonSlides,
  ModalController,
  Platform,
} from "@ionic/angular";

import { Router } from "@angular/router";

import { Observable, from } from "rxjs";
import { Storage } from "@ionic/storage";
import { HttpClient } from "@angular/common/http";
import { NavigateService } from "../../../services/product/navigate.service";
import { PreviewAnyFile } from "@ionic-native/preview-any-file/ngx";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";

@Component({
  selector: "app-inspection-viewing",
  templateUrl: "./inspection-viewing.component.html",
  styleUrls: ["./inspection-viewing.component.scss"],
})
export class InspectionViewingComponent implements OnInit {
  @ViewChild("swipeCard") swipeCard: ElementRef;
  @ViewChild("slideWithNav") slideWithNav: IonSlides;
  @ViewChild("slideWithNav3") slideWithNav3: IonSlides;
  @ViewChild("slideWithNavMobile", {}) slideWithNavMobile: IonSlides;
  @ViewChild("slideWithNav3Mobile", {}) slideWithNav3Mobile: IonSlides;
  @ViewChild("videoIframe") iframe: ElementRef;

  sliderOne: any;
  sliderThree: any;
  design = "new";
  public onMobile: any;
  slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: false,
  };
  slideOptsThree = {
    initialSlide: 0,
    slidesPerView: 5,
  };
  slideOptsThreeMobile = {
    initialSlide: 0,
    slidesPerView: 3,
    autoplay: false,
    pagination: false,
  };
  @Input() defaultSelected:
    | number
    | "none"
    | "360"
    | "Ideal"
    | "White"
    | "Black"
    | "Heart"
    | "Arrow"
    | "Certificate"
    | "ASET" = "none";
  @Input() loadFrom: string = "config";
  @Input() details: boolean = false;
  @Input() selected: any;
  @Input() inspectionviewdata: any;
  @Input() mobileView: boolean = false;
  @Output() currentNo: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  public shortCodes = [];
  stone = [];
  isIframeEmpty: boolean = false;
  cartProducts: any = [];
  public hits: any = [];
  stoneName = "";
  public showiFrame: boolean = false;
  public firstarr: any = [];
  public value: any;
  public certiLink: any;
  colSize = 6;
  isBeginning$: Observable<boolean>;
  isEnd$: Observable<boolean>;
  no: number = 0;
  stoneIndex: number = 0;
  public innerWidth: any;
  public sideMarginClass = "";
  public defaultView: any;
  public firstLoggedIn: any;
  public loggedinUser: any;
  public webViewMobile: boolean = false;
  public dataLoaded: boolean = false;
  isIpad: boolean = false;
  isTabletSize: boolean = false;

  nextSlide() {
    if (this.slideWithNav3) {
      this.slideWithNav3.slideNext();
    }
    console.log(this.slideWithNav3);
  }

  prevSlide() {
    if (this.slideWithNav3) {
      this.slideWithNav3.slidePrev();
    }
  }
  updateSliderIconState() {
    this.isEnd$ = from(this.slideWithNav3.isEnd());
    this.isBeginning$ = from(this.slideWithNav3.isBeginning());
  }
  detectDevice() {
    const detectObj = {
      device: !!navigator.maxTouchPoints ? "touch-device" : "computer",
      orientation: !navigator.maxTouchPoints
        ? "desctop"
        : !window.screen.orientation.angle
        ? "portrait"
        : "landscape",
    };

    return detectObj;
  }

  ngOnChanges(changes: any) {
    if (changes.loadFrom && changes.loadFrom.currentValue === "config") {
      try {
        if (
          changes.selected.currentValue[0].name &&
          this._navigateService.productDetailPageList.length !== 0
        ) {
          console.log(this._navigateService.productDetailPageList);
          this.no = this._navigateService.productDetailPageList.filter(
            (list) =>
              changes.selected.currentValue[0].name.indexOf(
                list.productName
              ) !== -1
          )[0].index;
        }
      } catch (e) {}
    }
    if (
      !changes.selected.previousValue ||
      changes.selected.previousValue[0].name !==
        changes.selected.currentValue[0].name
    ) {
      this.ngOnInit();
    }

    if (this.details) {
      this.colSize = 4;
    }
  }

  public kgPricing: any;
  public searchResultColumns: any = [];
  direction = "";
  swipeElement: any;
  cardanimation: any;
  whatsAppLink: any;

  constructor(
    private storage: Storage,
    private http: HttpClient,
    public _navigateService: NavigateService,
    private cdr: ChangeDetectorRef,
    private previewAnyFile: PreviewAnyFile,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private modalController: ModalController,
    public platform: Platform,
    private router: Router,
    public actionSheetController: ActionSheetController
  ) {
    this.sliderOne = {
      isBeginningSlide: true,
      isEndSlide: false,
      slidesItems: [
        {
          id: 995,
        },
        {
          id: 925,
        },
        {
          id: 940,
        },
        {
          id: 943,
        },
        {
          id: 944,
        },
      ],
    };

    this.platform.ready().then(() => {
      let platforms = this.platform.platforms();
      console.log(platforms);
      console.log(this.platform.is("android"), "android");
      console.log(this.platform.is("desktop"), "desktop");
      console.log(this.platform.is("mobile"), "mobileweb");
      if (this.platform.is("ipad")) {
        this.isIpad = true;
      }
      if (this.platform.is("desktop")) {
        this.onMobile = false;
      } else if (this.platform.is("ios")) {
        this.onMobile = true;
      } else if (this.platform.is("android") && !this.platform.is("mobile")) {
        this.onMobile = true;
      } else if (this.platform.is("mobile")) {
        this.onMobile = false;
        this.webViewMobile = true;
      } else {
        this.onMobile = false;
      }
      this.webViewMobile = !platform.is("cordova");
      console.log("this.webViewMobile", this.webViewMobile);
    });

    this.sliderThree = {
      isBeginningSlide: true,
      isEndSlide: false,
      slidesItems: [
        {
          id: 643,
        },
        {
          id: 532,
        },
        {
          id: 232,
        },
        {
          id: 874,
        },
        {
          id: 193,
        },
      ],
    };
    this.checkIfTabletSize();
  }

  minus(val: boolean = false) {
    if (this.no === 0) {
      this.stoneIndex = this.selected.length - 1;
      this.no = this.selected.length - 1;
    } else {
      if (val) {
        this.stoneIndex--;
      }
      this.no--;
    }
  }
  plus(val: boolean = false) {
    if (
      this.no === this.selected.length - 1 ||
      (this._navigateService &&
        this._navigateService.productDetailPageList &&
        this.no === this._navigateService.productDetailPageList.length - 1)
    ) {
      this.stoneIndex = 0;
      this.no = 0;
    } else {
      if (val) {
        this.stoneIndex++;
      }
      this.no++;
    }
  }

  async ngOnDestroy() {}
  myLoadEvent(data) {
    let stoneName;
    if (data.kgStoneId) {
      stoneName = data.kgStoneId;
    } else {
      stoneName = data.name || data.stoneName;
    }

    const _this = this;
    var image = new Image();
    image.onload = function () {};
    image.onerror = function () {
      _this.isIframeEmpty = true;
      console.log(_this.isIframeEmpty);
    };
    let img = `https://stackg.azureedge.net/v360azurekg/V360_5_0/imaged/${stoneName}/still.jpg`;
    image.src = img;
  }

  stoneVideoAddition(
    no: number,
    href: string,
    image: any,
    link: any,
    name: string,
    enableView: boolean
  ) {
    this.stone.push({
      no,
      href,
      image,
      link,
      name,
      enableView,
    });
  }

  stoneImageAddition(no: number, href: string, link: any, name: string) {
    this.stone.push({
      no,
      href,
      link,
      name,
    });
  }

  async ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 500) {
      this.mobileView = true;
      this.slideOptsOne["pagination"] = false;
    } else if (this.innerWidth > 500) {
      this.mobileView = false;
    }
if(this.mobileView == false){
    this.selected = await this.selected.map((res) => {
      let lab = res["lab"];
      let ReportNo = res["ReportNo"];
      let stoneName = res.name || res.stoneName;
      let currentLocation = res["currentLocation"];
      let tracrId;
      let shapeCode =
        res["eliteShapeCode"] || res["standardShapeCode"] || res["ShapeCode"];

      res["ShapeCode"] = shapeCode;

      if (!!res.kgStoneId) {
        stoneName = res.kgStoneId;
      }

      if (!!res.tracrId) {
        tracrId = res.tracrId;
      }

      this.cdr.detectChanges();
      let images = {
        Ideal: `diaboxexport/${stoneName}/${stoneName}-IdealScope-01.jpg`,
        White: `diaboxexport/${stoneName}/${stoneName}-Officelight%20Gray-01.jpg`,
        Black: `diaboxexport/${stoneName}/${stoneName}-Officelight%20black-01.jpg`,
        Heart: `diaboxexport/${stoneName}/${stoneName}-Hearts-01.jpg`,
        Arrow: `diaboxexport/${stoneName}/${stoneName}-Arrows-01.jpg`,
        ASET: `diaboxexport/${stoneName}/${stoneName}-ASET%20white-01.jpg`,
      };
      Object.keys(images).map((res) => {
        images[res] = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://kgmediaprod.blob.core.windows.net/${images[res]}`
        );
      });

      if (!!res.Cutwise_id) {
        images["Fire"] = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://widget.cutwise.com/video/${res.Cutwise_id}?autoplay=1&sp=43`
        );
      }

      images["Image360"] = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://stackg.azureedge.net/v360azurekg/V360_5_0/Vision360.html?d=${stoneName}`
      );

      if (lab && ReportNo) {
        images[
          "Certificate"
        ] = `https://kgmediaprod.blob.core.windows.net/certificates/${lab}/${ReportNo}.pdf`;
      }

      if (tracrId) {
        images["TracrUrl"] = `https://search.tracr.com/${tracrId}/diamond`;
      }

      return { ...res, ...images };
    });
    await this.loadData(this.selected[0]);
  }
  }

  async loadData(obj, setTransition = false) {
    if (setTransition) {
      const style = this.swipeCard.nativeElement.style;
      style.transform = "none";
    }
    this.stone = [];
    this.stoneName = obj.stoneName ? obj.stoneName : obj.name;

    this.cdr.detectChanges();

    let options = {
      stoneName: this.stoneName,
      removeTitlecase: true,
    };
    if (obj.Image360) {
      this.stoneVideoAddition(
        this.stone.length,
        "iframe",
        obj.White,
        obj.Image360,
        "Video",
        true
      );
    }

    if (obj.Fire) {
      this.stoneVideoAddition(
        this.stone.length,
        "iframe",
        obj.Black,
        obj.Fire,
        "Fire",
        false
      );
    }

    if (obj.Ideal) {
      this.stoneImageAddition(this.stone.length, "image", obj.Ideal, "Ideal");
    }

    if (obj.White) {
      this.stoneImageAddition(this.stone.length, "image", obj.White, "White");
    }

    if (obj.Black) {
      this.stoneImageAddition(this.stone.length, "image", obj.Black, "Black");
    }

    if (obj.Heart) {
      this.stoneImageAddition(this.stone.length, "image", obj.Heart, "Heart");
    }

    if (obj.Arrow) {
      this.stoneImageAddition(this.stone.length, "image", obj.Arrow, "Arrow");
    }

    if (obj.ASET) {
      this.stoneImageAddition(this.stone.length, "image", obj.ASET, "Aset");
    }
    this.dataLoaded = true;
    this.hits = [obj];
    // console.log(
    //   "this.hits ",
    //   this.hits,
    //   this.details,
    //   this.defaultSelected,
    //   this.stone
    // );
    this.updateSliderIconState();
  }

  closeIframe() {
    this.showiFrame = false;
  }

  loaddefaultSlide() {
    let sliderIndex = 0;
    if (this.defaultView) {
      sliderIndex = this.defaultView;
    }
    setTimeout(() => {
      if (this.slideWithNav) {
        this.slideWithNav.slideTo(sliderIndex);
      }
      if (this.slideWithNavMobile) {
        this.slideWithNavMobile.slideTo(sliderIndex);
      }
      if (this.slideWithNav3Mobile) {
        this.slideWithNav3Mobile.slideTo(sliderIndex);
      }
      if (this.slideWithNav3) {
        this.slideWithNav3.slideTo(sliderIndex);
      }
      this.updateSliderIconState();
    }, 100);
  }

  loadSlider(slideWithNav: any, no: number) {
    this.defaultSelected = no;
    setTimeout(() => {
      if (slideWithNav) {
        slideWithNav.slideTo(no);
      }
      if (this.slideWithNavMobile) {
        this.slideWithNavMobile.slideTo(no);
      }
      if (this.slideWithNav3Mobile) {
        this.slideWithNav3Mobile.slideTo(no);
      }
      if (this.slideWithNav3) {
        this.slideWithNav3.slideTo(no);
      }
      this.updateSliderIconState();
    }, 100);
  }

  slidePrev(object, slideView) {
    slideView.slidePrev(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  SlideDidChange(object, slideView) {
    if (
      this.slideWithNav &&
      typeof this.slideWithNav.getActiveIndex === "function"
    ) {
      this.slideWithNav
        .getActiveIndex()
        .then((index) => {
          this.defaultSelected = index;
        })
        .catch((error) => {
          console.error("Error getting active index for slideWithNav:", error);
        });
    }

    if (
      this.slideWithNavMobile &&
      typeof this.slideWithNavMobile.getActiveIndex === "function"
    ) {
      this.slideWithNavMobile
        .getActiveIndex()
        .then((index) => {
          this.defaultSelected = index;
        })
        .catch((error) => {
          console.error(
            "Error getting active index for slideWithNavMobile:",
            error
          );
        });
    }

    this.checkIfNavDisabled(object, slideView);
  }

  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }

  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }

  async changeView() {
    if (this.selected.length > 0) {
      if (this.no == 0) {
        await this.plus(true);
        await this.changeStoneId(this.no);
        await this.loadData(this.selected[this.no]);
      } else if (this.no + 1 == this.selected.length) {
        await this.minus(true);
        await this.changeStoneId(this.no);
        await this.loadData(this.selected[this.no]);
      } else {
        await this.plus(true);
        await this.changeStoneId(this.no);
        await this.loadData(this.selected[this.no]);
      }
    } else if (
      this._navigateService &&
      this._navigateService.productDetailPageList &&
      this._navigateService.productDetailPageList.length > 0
    ) {
      if (this.no == 0) {
        await this.plus(true);
        await this.changeStoneId(this.no);
      } else if (
        this.no + 1 ==
        this._navigateService.productDetailPageList.length
      ) {
        await this.minus(true);
        await this.changeStoneId(this.no - 1);
      } else {
        await this.plus(true);
        await this.changeStoneId(this.no + 1);
      }
    } else {
      this.stoneName = "";
      this.hits = [];
      this.stone = [];
      this.close.emit();
    }
  }
  async nxtStone() {
    await this.plus(true);
    this.changeStoneId(this.no);
    await this.loadData(this.selected[this.no], true);
  }

  changeStoneId(no) {
    let bool = true;
    if (this._navigateService && this._navigateService.productDetailPageList) {
      console.log(this._navigateService.productDetailPageList[no]);
      if (!!this._navigateService.productDetailPageList[no]) {
        this.currentNo.emit(no);
        bool = false;
      } else if (!!this._navigateService.productDetailPageList[0]) {
        this.no = 0;
        this.currentNo.emit(0);
        bool = false;
      }
    } else {
      this.currentNo.emit(no);
      bool = false;
    }
    if (bool) {
      this.stoneName = "";
      this.hits = [];
      this.stone = [];
      this.close.emit();
    }
  }
  selectionClass() {
    this.value = this.inspectionviewdata;
  }
  openCertificatefilenameFile(certificate: any) {
    let certificateLink = `https://media.diamlist.com/video/${certificate}`;
    document
      .querySelector("#certificateLink")
      .setAttribute("href", certificateLink);
    window.open(certificate, "_blank");

    this.certiLink = "";
  }
  openVideoLink(stoneName: any) {
    let videoLink = `https://media.diamlist.com/video/${stoneName}`;
    document.querySelector("#videoLink").setAttribute("href", videoLink);
    window.open(videoLink, "_blank");
  }

  onLoadHandler(stoneName: any) {
    let iframeTag = document.getElementById("iframeTag") as HTMLIFrameElement;
    if (
      iframeTag &&
      this.inspectionviewdata &&
      this.inspectionviewdata.ImageUrl
    ) {
      if (iframeTag.src !== this.inspectionviewdata.ImageUrl) {
        iframeTag.src = this.inspectionviewdata.ImageUrl;
      }
    } else {
      console.error("Iframe element not found.");
    }
  }
  async handleShare() {
    let companyId = await this.storage.get("companyId");
    const whatsappMessage = encodeURIComponent(`
    ${this.inspectionviewdata.stoneName}  ${this.inspectionviewdata.ReportNo}
    ${this.inspectionviewdata.cts}  ${this.inspectionviewdata.ColorCode}  ${this.inspectionviewdata.ClarityCode}  ${this.inspectionviewdata.ShapeCode}   ${this.inspectionviewdata.lab}
    
    ${this.inspectionviewdata.Rapnet_plusDiscountPercent}  ${this.inspectionviewdata.Rapnet_pluspercarat}  ${this.inspectionviewdata.Rapnet_plus}$ 
    
    Polish Code: ${this.inspectionviewdata.PolishCode} 
    Symmetry Code:   ${this.inspectionviewdata.SymmetryCode}
    Fluorescence Code: ${this.inspectionviewdata.FluorescenceCode}
    Cut Code:  ${this.inspectionviewdata.CutCode}
    
    Location:- ${this.inspectionviewdata.location}
    
    Diamond image
    https://www.kgdiamonds.com/products/${this.inspectionviewdata.stoneName}/${this.inspectionviewdata.stoneName}/${companyId}
 
    Diamond certificate
    https://media.diamlist.com/certificate/${this.inspectionviewdata.stoneName}
    
    Diamond image without name
    https://media.diamlist.com/white/${this.inspectionviewdata.stoneName}

    `);
    document
      .querySelector("#whatsappShareBtn")
      .setAttribute(
        "href",
        `https://api.whatsapp.com/send?text=${whatsappMessage}`
      );
    const link = `https://api.whatsapp.com/send?text=${whatsappMessage}`;
    window.open(link);
  }

  checkIfTabletSize() {
    const screenWidth = window.innerWidth;
    this.isTabletSize = screenWidth >= 768 && screenWidth <= 1200;
  }
  checkingShapeCode(res: any) {
    let checkRound = res["ShapeCode"].toLowerCase();
    if (checkRound !== "round") {
      res["CutCode"] = "";
    }
    return res;
  }
  getClass(stone) {
    let className = "videoiframe";
    if (stone.name == "Fire") {
      className = "fireFrame";
    }
    return className;
  }
  downloadVideo() {
    let url = `https://stackg.azureedge.net/v360azurekg/V360_5_0/imaged/${this.stoneName}/video.mp4`;
    if (this.mobileView && !this.webViewMobile) {
      this.previewAnyFile.preview(url).then(
        (res: any) => {
          console.log("file : " + res);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      if (this.isIpad && !this.webViewMobile) {
        this.previewAnyFile.preview(url).then(
          (res: any) => {
            console.log("file : " + res);
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        window.open(url, "_blank");
      }
    }
  }
  async setDefaultView(no, currentSelected = null) {
    if (currentSelected.href == "iframe") {
      if (currentSelected) {
        return (currentSelected.enableView = true);
      }
    } else {
      return (currentSelected.enableView = false);
    }
    this.defaultView = no;
    this.defaultSelected = no;
  }
}
