import { Component, Input, OnInit } from "@angular/core";
import {
  Events, LoadingController, ModalController,
  NavParams
} from "@ionic/angular";
import * as moment from "moment";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
@Component({
  selector: 'app-manage-request-approval',
  templateUrl: './manage-request-approval.page.html',
  styleUrls: ['./manage-request-approval.page.scss'],
})
export class ManageRequestApprovalPage implements OnInit {
  @Input() requestPlatform: string = ''
  public data: any;
  public columns = [];
  public page = 'reqeustFromWebSite';
  public requestedStoneList = []
  public setOfRejectStoneIds = []
  public flag: any;
  public transporterDetails: any;
  public trasportVia: any;
  public visitTime: any;
  public shippingCost = 0;
  public shippingDate: any;
  public formFields = []
  public partyTypeList = []
  public formData = {}
  public formDetailList = []
  public selectedAction = '';
  public action: string = '';
  actionList = []
  public selectedActionIdList = [];
  public deleteBtn: boolean = false;
  public groupType: any = '';

  constructor(public navParams: NavParams,
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public event: Events) {

  }

  ngOnChanges(changes) {
    console.log(changes)
  }

  ngOnInit() {
    this.data = this.navParams.get("data");
    this.flag = this.navParams.get("type");

    if (this.requestPlatform == 'SVC') {
      this.actionList = [{
        id: 2, name: 'Consignment Confirm (House)'
      }, {
        id: 3, name: 'Consignment In Stock (House)'
      }]
      if (this.data.requestFor == 'Customer Confirm') {
        this.groupType = 'House';
        this.action = 'Consignment Confirm';
        this.selectedAction = 'Consignment Confirm (House)';
        this.selectedActionIdList.push({ id: 2, name: 'Consignment Confirm (House)' })
        this.getFormDetail()
      }
    } else {
      this.action = 'Customer Confirm';
      this.selectedAction = 'Customer Confirm';
      this.actionList = [{
        id: 1, name: 'Customer Confirm'
      }, {
        id: 2, name: 'Consignment Confirm (Client)'
      }, {
        id: 3, name: 'Consignment Confirm (House)'
      }]

      if (this.data.requestFor == 'Customer Confirm') {
        this.groupType = 'Client';
        this.selectedActionIdList.push({ id: 1, name: 'Customer Confirm' })
        this.getFormDetail()
      }
    }
    // this.actionList = this.navParams.get('actionList')


    this.getTransporterDetail('Transport');
    if (!!this.data.visitTime) {
      this.visitTime = new Date(this.data.visitTime)
    }
    this.columns = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "StoneId", prop: "stoneId" },
      { name: "Cts", prop: "stoneCts" },
      { name: "Disc", prop: "requestDiscount" },
      { name: "Price", prop: "requestPrice" },
      { name: "Amount", prop: "Amount" },
      { name: "Color", prop: "color" },
      { name: "Shape", prop: "shape" },
      { name: "Purity", prop: "clarity" },
    ];

    if (!this.data.isApproved) {
      this.deleteBtn = true;
    }
    this.findRequestedStoneById(this.data.id);
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'transportMode') {
      if (idList.length > 0) {
        this.trasportVia = obj[0].partyName;
      } else {
        this.trasportVia = null;
      }
    }
    if (property == "selectedAction") {
      console.log(this.formData)
      if (idList.length > 0) {
        if (obj[0].name == 'Consignment Confirm (Client)') {
          this.action = 'Consignment Confirm';
          this.groupType = 'Client';
        } else if (obj[0].name == 'Consignment Confirm (House)') {
          this.action = 'Consignment Confirm';
          this.groupType = 'House';
        } else if (obj[0].name == 'Consignment In Stock (House)') {
          this.action = 'Consignment In Stock';
          this.groupType = 'House';
        } else {
          this.action = obj[0].name;
          this.groupType = 'Client';
        }
        this.selectedAction = obj[0].name
        await this.getFormDetail()
      } else {
        this.action = null;
        this.selectedAction = null;
      }
    }
  }
  async findRequestedStoneById(id) {
    let res: any;
    await this.configService.showLoader();
    res = await this.httpApiService.findRequestedStockByVoucherId(id);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.requestedStoneList = data.data;
      console.log("requestedStoneList", this.requestedStoneList);
    }
  }

  // transporter detail 

  async getTransporterDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        this.transporterDetails = data.data;
      }
    }
  }

  async approveVoucher(item) {
    let res: any;
    let jsonObj;
    if (this.action == 'Consignment Confirm') {
      if (!!item.shippingDate) {
        item.shippingDate = moment(new Date(item.shippingDate)).format("YYYY-MM-DD HH:mm:ss")
      }
      if (!!item['currencyRate']) {
        item.exgRate = item['currencyRate'] + (!!item['spreadRate'] ? item['spreadRate'] : 0)
      }
      item.websiteContactId = this.data.customerContactId
      jsonObj = {
        voucherId: this.data.id,
        setOfRejectStoneIds: this.setOfRejectStoneIds,
        modeOfTransport: this.trasportVia,
        requestFor: this.action,
        stoneVoucherParams: item
      }
    } else {
      if (!!item['currencyRate']) {
        item.exgRate = item['currencyRate'] + (!!item['spreadRate'] ? item['spreadRate'] : 0)
      }
      jsonObj = {
        voucherId: this.data.id,
        setOfRejectStoneIds: this.setOfRejectStoneIds,
        modeOfTransport: this.trasportVia,
        requestFor: this.selectedAction,
        stoneVoucherParams: item,
      }
    }
    let concatedObj = Object.assign(jsonObj, item)
    console.log(concatedObj)
    await this.configService.showLoader()
    res = await this.httpApiService.approveVoucher(concatedObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.setOfRejectStoneIds = []
      await this.configService.showToast("success", data.message);
      await this.modalCtrl.dismiss(this.flag)
    }
    // }
  }

  async updateRequestFromWebsite() {
    let res: any;
    let jsonObj = {
      voucherId: this.data.id,
      setOfRejectStoneIds: this.setOfRejectStoneIds,
      modeOfTransport: this.trasportVia
    }
    if (this.visitTime) {
      jsonObj['visitTime'] = moment(new Date(this.visitTime)).format("YYYY-MM-DD HH:mm:ss");
    }
    console.log(jsonObj)
    await this.configService.showLoader()
    res = await this.httpApiService.updateRequestFromWebsite(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.setOfRejectStoneIds = []
      await this.configService.showToast("success", data.message);
      await this.modalCtrl.dismiss(this.flag)
    }
  }


  async validateControls() {
    if (!!this.trasportVia) {
      return true;
    } else {
      await this.configService.showToast('error', 'Mode of transport is required.')
    }
  }

  //remove from list 
  async removeFromList(item) {
    let obj = item.row.data
    let index = this.requestedStoneList.findIndex(el => el.id == obj.id)
    if (index > -1) {
      this.requestedStoneList.splice(index, 1)
      this.setOfRejectStoneIds.push(obj.id)
      this.requestedStoneList = [...this.requestedStoneList];
    }
  }

  async getFormDetail() {
    this.formDetailList = []
    let cartActionDetail = await this.configService.getCartActionList();
    this.formFields = cartActionDetail.formFields;
    this.partyTypeList = cartActionDetail.partyTypeList;
    this.partyTypeList.forEach(el => {
      console.log(el.name,this.groupType )
      if (el.name == this.groupType) {
        el.actionList.forEach(item => {
          console.log(item.actionName,this.action )
          if (item.actionName == this.action) {
            if (!!item.allowPermission && item.allowPermission.length > 0) {
              item.allowPermission.forEach(el => {
                let indexOfArr = this.formFields.findIndex(item => item.id == el)
                if (indexOfArr > -1) {
                  if (this.formFields[indexOfArr].keyName == 'shippingDate') {
                    this.formData['shippingDate'] = moment().format("YYYY-MM-DD HH:mm:ss")
                  }
                  this.formDetailList.push(this.formFields[indexOfArr])
                }
              })
            }
          }
        }
        )
      }
    })
    console.log(this.formDetailList)

  }

}
