import { Component, Input } from "@angular/core";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { DragulaService } from "ng2-dragula";
import { ScanRFIDPage } from "../../actions/scan-rfid/scan-rfid.page";
import * as moment from "moment";
import { ScanBoxErrorManagementComponent } from '../../actions/scan-box-error-management/scan-box-error-management.component';
import { ExportAsExcelService } from "src/app/services/export-as-excel.service";

// @Component({
//   selector: 'app-inventory',
//   templateUrl: './inventory.component.html',
//   styleUrls: ['./inventory.component.scss']
// })
@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  styleUrls: ["./inventory.component.scss"]
})
export class InventoryComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() actionName: any;
  @Input() toRole: any;

  loadFirstData = [];
  copy = [];
  displayLoader: boolean = true;
  userId: string;
  scanData: any[];
  lastScanId: string = "";
  boxScanData: any;
  req: boolean = true;
  kamReq: any = [];
  setOfStoneIds: any = [];
  flag: any;
  id: any;
  label: any;
  assortmentOkListSS = []
  assortmentOkListMIX = []
  assortmentErrorList = []
  regularList = [];
  parcelList = [];
  programList = [];
  purchaseList = [];
  programParcelList = [];
  public boardBaseUrl: any;
  public items = []

  public transTypeName: any;
  public departmentList: any;
  public fromDepartment: any;
  public transType: any;
  public pageNumber = 0;
  public noOfRecords = 5;
  public count: any;

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public dragulaService: DragulaService,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService
  ) {
    this.flag = "Inventory";
  }

  async ngOnInit() {
    this.getUserData();
    this.getDefaultSetting()
    this.event.subscribe('Inventory', (item) => {
      if (this.flag == "Inventory") {
        this.getUserData();
        this.getDefaultSetting()
      } else if (this.flag == "InventoryBox") {
        this.event.publish('InventoryBox')
      } else if (this.flag == 'InventoryError') {
        this.event.publish('InventoryError')
      }

    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Inventory');
  }

  async getDefaultSetting() {
    if (!!this.data.actionId) {
      let res: any;
      let userData = await this.authService.userData();
      this.userId = userData.userId;
      res = await this.httpApiService.getDefaultSetting(
        this.userId,
        this.data.actionId
      );
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        let dataValue = data.data;
        console.log("DATa", dataValue);
        if (!!dataValue.map) {
          if (!!dataValue.map.transType) {
            this.transType = dataValue.map.transType
          }
        }
        console.log(this.transType)
      }
    }

  }


  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.departmentId) {
      this.fromDepartment = userData.departmentId;
    } else {
      await this.authService.logout()
    }
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
    if (!!this.actionName && this.actionName == 'Mix Sales Inventory') {
      await this.findByDepartmentOrRoleIdByRole()
    } else {
      await this.getAllBoxesByDepartmentID();
    }
  }

  async getAllBoxesByDepartmentID() {
    this.assortmentOkListSS = []
    this.assortmentOkListMIX = []
    this.assortmentErrorList = []
    this.regularList = []
    this.parcelList = []
    this.programList = []
    this.purchaseList = []
    this.programParcelList = []
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findByDepartmentIdAndUser(
      this.fromDepartment,
      'true'
    );
    let data = res.json();
    this.count = data.length;

    if (res.status == 401) {
      await this.configService.dismiss();
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.dismiss();
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.dismiss();
      await this.configService.showToast("error", data.error);
    } else {
      this.loadFirstData = data.data;
      this.loadFirstData.forEach(el => {
        if (el.type == 'Assortment-OK' && el.transTypeName == 'SS') {
          this.assortmentOkListSS.push(el)
        } else if (el.type == 'Assortment-OK' && el.transTypeName == 'MIX') {
          this.assortmentOkListMIX.push(el)
        } else if (el.type == 'Assortment-Error') {
          this.assortmentErrorList.push(el)
        } else if (el.type == 'Regular') {
          if (el.transTypeName == 'SS' && el.currentStoneCount == 0 && !el.hasError  ) {
          } else {
            this.regularList.push(el)
          }
        } else if (el.type == 'Parcel') {
          this.parcelList.push(el)
        } else if (el.type == 'Program') {
          this.programList.push(el)
        } else if (el.type == 'Purchase') {
          this.purchaseList.push(el)
        } else if (el.type == 'Program-Parcel') {
          this.programParcelList.push(el)
        }

      })
      await this.configService.dismiss();
      console.log("data", data, this.loadFirstData);
    }
  }

  async findByDepartmentOrRoleIdByRole() {
    this.assortmentOkListSS = []
    this.assortmentOkListMIX = []
    this.assortmentErrorList = []
    this.parcelList = []
    this.regularList = []
    this.programList = []
    this.purchaseList = []
    this.programParcelList = []
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findByDepartmentOrRoleIdByRole(
      this.toRole
    );
    let data = res.json();
    this.count = data.length;
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {

      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.loadFirstData = data.data;
      this.loadFirstData.forEach(el => {
        if (el.type == 'Assortment-OK' && el.transTypeName == 'SS') {
          this.assortmentOkListSS.push(el)
        } else if (el.type == 'Assortment-OK' && el.transTypeName == 'MIX') {
          this.assortmentOkListMIX.push(el)
        } else if (el.type == 'Assortment-Error') {
          this.assortmentErrorList.push(el)
        } else if (el.type == 'Regular') {
          this.regularList.push(el)
        } else if (el.type == 'Parcel') {
          this.parcelList.push(el)
        } else if (el.type == 'Program') {
          this.programList.push(el)
        } else if (el.type == 'Purchase') {
          this.purchaseList.push(el)
        } else if (el.type == 'Program-Parcel') {
          this.programParcelList.push(el)
        }
      })
      console.log("data", data, this.loadFirstData);
    }
  }

  dragulaServiceModel() {
    let selector = "bag" + Math.random();
    this.dragulaService.createGroup(selector, {
      moves: function (el, container, handle) {
        return handle.className.indexOf("handle") > -1;
      },
      mirrorContainer: document.getElementsByTagName("ion-app")[0]
    });

    this.dragulaService.dropModel("bag").subscribe(value => {
      this.currentLoader(true);
      var objLength = this.loadFirstData.length;
      var i = 0,
        changePosition = [];
      for (i = 0; i < objLength; i++) {
        if (this.loadFirstData[i].id !== this.copy[i].id) {
          changePosition.push({
            key: this.loadFirstData[i].id,
            value: i
          });
        }
      }
      this.cloneBoxDetails(this.loadFirstData);

      // this.boxPositionChange(changePosition);
    });
  }

  shadowColor(color) {
    if (!color) {
      return "shadow-red";
    } else {
      try {
        const date1 = new Date(color);
        const diffTime = Math.abs(new Date().getTime() - date1.getTime());
        const day = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        if (1 > day) {
          return "shadow-green";
        } else if (3 > day) {
          return "shadow-yellow";
        } else {
          return "shadow-red";
        }
      } catch (e) {
        return "shadow-red";
      }
    }
  }

  boxPositionChange(updatedJson) {
    // this._DatabaseProvider.boxPositionChange(updatedJson).then(data => {
    //   this.loadData(true);
    // });
  }

  cloneBoxDetails(data) {
    this.loadFirstData = [];
    this.loadFirstData = data;
    this.copy = Object.assign({}, this.loadFirstData);
  }

  async openScanModel() {
    const modal = await this.modalCtrl.create({
      component: ScanRFIDPage,
      backdropDismiss: false,
      componentProps: {
        type: "ledOn"
      },
      cssClass: "scanRfiDPage"
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
  async handleModalDismiss(d) {
    console.log("d", d);
  }

  async currentLoader(display: boolean) {
    if (this.displayLoader === true && display === true) {
      const loading = await this.loadingController.create({
        message: "Please Wait.."
      });
      await loading.present();
      this.displayLoader = false;
    } else if (display === false) {
      this.configService.dismiss();
      this.displayLoader = true;
    }
  }

  async changeViewType(type, item) {
    console.log(item)
    this.flag = type;
    console.log(this.flag)
    if (item != 0) {
      this.id = item.id;
      this.label = item.label;
      this.transTypeName = item.transTypeName;
    }
  }

  async inventoryErrors(type) {
    this.flag = type;
  }

  async modalAction(data) {
    console.log(data)
  }

  async scanHere() {
    let res;
    if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
      await this.configService.showLoader();
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      await this.configService.dismiss();
      console.log(data);
      if (data.type == "error") {
        await this.configService.showToast("error", data.type);
      } else {
        if (data.result.length > 0) {
          data.result = data.result.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          let obj = {
            setOfValues: data.result,
            isAssignment: true,
          };
          let resp: any;
          resp = await this.httpApiService.scanBox(obj);
          let dataValue = resp.json();
          await this.configService.dismiss();
          if (resp.status == 401) {
            this.authService.logout();
          } else if (resp.status == 500) {
            await this.configService.showToast("error", dataValue.error);
          } else if (resp.status == 400) {
            await this.configService.showToast("error", dataValue.error);
          } else if (!!dataValue.data.listOfErrors && dataValue.data.listOfErrors.length > 0) {
            await this.configService.showToast("error", dataValue.data.message);
            // let excelData: any = [];
            // dataValue.data.listOfErrors.forEach(key => {
            //   excelData.push({ Name: key.stoneId, message: key.error })
            // });
            await this.exportAsExcelService.exportAsExcelFile(dataValue.data.listOfErrors, 'Error_Stones');
          } else {
            await this.configService.showToast("success", dataValue.message);
          }
          await this.getAllBoxesByDepartmentID();
        } else {
          await this.configService.showToast("error", "No data found");
        }
      }
    } else {
      await this.configService.showToast("error", "Scanner Board Url not found");
    }

  }

  timeAgo(time) {
    if (!time) {
      return "";
    }
    return moment
      .utc(time)
      .local()
      .fromNow();
  }


  setItems() {
    this.items = this.loadFirstData;
  }
  async filterItems(ev: any) {
    let val = ev.target.value;
    console.log(val)
    await this.setItems();
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        if (item.label && item.label.toLowerCase().includes(val.toLowerCase())) {
          return true;
        }
      });
    } else {
      await this.setItems();
    }
  }
}
