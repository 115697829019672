import { Component, Input, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-sticker-print-new',
  templateUrl: './sticker-print-new.page.html',
  styleUrls: ['./sticker-print-new.page.scss'],
})
export class StickerPrintNewPage implements OnInit {
  @Input() stoneList: any = [];
  @Input() type: string = ''
  elementType = 'url';
  coupleUpStones = []

  constructor(public modalCtrl: ModalController,
    public configService: ConfigService) { }

  ngOnInit() {
    console.log(this.stoneList)
    if (this.type == 'couple') {
      for (let i = 0; i < this.stoneList.length; i += 2) {
        this.coupleUpStones.push(this.stoneList.slice(i, i + 2))
      }
      console.log(this.coupleUpStones)
    }
  }


  closeModal() {

    this.modalCtrl.dismiss()
  }

  async printDocument() {
    await this.configService.print();
  }

  verifyCut(item) {
    if (!!item && item.ShapeCode) {
      if (!item.ShapeCode.includes('ROUND')) {
        return ''
      } else {
        return item.CutCode
      }
    } else {
      return item.CutCode
    }
  }


  changeForFancy(data) {
    if (data.ColorCode == 'FANCY') {
      if (!!data.ColorDescriptions) {
        let AllWords = data.ColorDescriptions.split(',')[0].split(' ');
        const fancyColor = AllWords.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.slice(0, 1);
        }, '');
        return fancyColor;
      } else {
        return data.ColorCode;
      }
    } else {
      return data.ColorCode;
    }
  }

  twoDecimal(cts) {
    return cts.toFixed(2);
  }

}
