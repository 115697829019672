import { Component, Input, ViewChild, HostListener } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import * as moment from "moment";
import { LoadingController, ModalController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { UploadSingleToMixPage } from "../upload-single-to-mix/upload-single-to-mix.page";
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-single-to-mix',
  templateUrl: './single-to-mix.component.html',
  styleUrls: ['./single-to-mix.component.scss']
})
export class SingleToMixComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild("dataTable") public dataTable: any;

  public page = "singletoMix";
  public fromCts: any;
  public toCts: any;
  public colorList = [];
  public clarityList = [];
  public shapeList = [];
  public colorIdList = [];
  public shapeIdList = [];
  public clarityIdList = [];
  public shapeId = [];
  public clarityId = [];
  public colorId = [];
  public csspList = [];
  public stoneList = [];
  public columns = [];
  public csspColumns = [];
  public count: Number = 0;
  public openSelectedcsspListFlag = true;
  public selectedCsspList = [];
  public showSelected = true;
  public selected = [];
  public selectedStoneList = []
  public isSelect: boolean = false;
  public sizeList = [];
  public sizeIdList = []
  public items = []
  public countCssp = 0;
  public manageSelectedStones: any;
  public pageSize = 20;

  constructor(
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    this.columns = [
      { name: "StoneId", prop: "stoneId" },
      { name: "Color", prop: "color" },
      { name: "Shape", prop: "shape" },
      { name: "Ct.", prop: "cts" },
      { name: "Clarity", prop: "clarity" },
    ];
    this.csspColumns = [
      { name: "CSSP", prop: "stoneId" },
      { name: "Ct.", prop: "size" },
    ];
    this.getAllSingleStoneIssuedForMixing()
    this.findParametersForMixSizeMaster();
    this.findShapeByType()
    this.event.subscribe('Single Mixing', (item) => {
      this.columns = [
        { name: "StoneId", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "cts" },
        { name: "Clarity", prop: "clarity" },
      ];
      this.csspColumns = [
        { name: "CSSP", prop: "stoneId" },
        { name: "Ct.", prop: "size" },
      ];
      this.resetData()
      this.getAllSingleStoneIssuedForMixing()
      this.findParametersForMixSizeMaster();
      this.findShapeByType()

    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Single Mixing');
  }

  async generateCsv() {
    await this.configService.showLoader()
    let fileName = "Single To Bulk Assortment" + moment().format("DD/MM/YYYY")
    let csvData = [];

    for (let i = 0; i < this.items.length; i++) {
      let arrayObj = this.items[i];
      let stoneId = arrayObj.stoneId;
      let shape = arrayObj.shape;
      let cts = arrayObj.cts;
      let color = arrayObj.color;
      let clarity = arrayObj.clarity;
      let voucherDate = arrayObj.voucherDate
      let obj = {
        'Stone Id': stoneId.trim(),
        'Cts': cts,
        'Shape': shape,
        'Color': color,
        'Clarity': clarity,
        'Ent Date': voucherDate,
        'CSSP': '',
        'Program': '',
        'Final Price': '',
        'Tot Pd Rate': '',
        'Avg Price': '',
        'Price Diff': ''
      }
      csvData.push(obj);
    }
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      filename: 'S_To_B',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: false,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(csvData);
    // await this.downloadFile(csvData, fileName);
    await this.loadingController.dismiss()
  }


  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str: String;
    let row: String;

    for (let index in headerList) {
      if (row) {
        row += headerList[index] + ',';
      } else {
        row = headerList[index] + ',';
      }
    }

    row = row.slice(0, -1);
    if (str) {
      str += row + '\n';
    } else {
      str = row + '\n';
    }

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index = 0; index <= headerList.length; index++) {
        let head = headerList[index];
        let val = array[i][head] || '';

        if (headerList[index + 1]) {
          line += val + ",";
        } else {
          line += val
        }
      }
      str += line + '\n';
    }
    return str;
  }

  async downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, ['Stone Id', 'Cts', 'Shape', 'Color', 'Clarity', 'Ent Date', 'CSSP', 'Program', 'Final Price', 'Tot Pd Rate', 'Avg Price', 'Price Diff']);

    // let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv' });
    // let dwldLink = document.createElement("a");
    // let url = URL.createObjectURL(blob);
    var csvContent = "data:text/csv;charset=utf-8,";
    csvContent += csvData
    var url = encodeURI(csvContent);
    var dwldLink = document.createElement("a");
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  async upload() {
    let modal = await this.modalCtrl.create({
      component: UploadSingleToMixPage,
      cssClass: "extraLargePage",
      componentProps: {}
    })
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async handleModalDismiss(d) {
    this.getAllSingleStoneIssuedForMixing()
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'colorIdList') {
      if (idList.length > 0) {
        this.colorId = idList;
      } else {
        this.colorId = [];
      }
    } else if (property == 'shapeIdList') {
      if (idList.length > 0) {
        this.shapeId = idList;
        this.sizeIdList = []
        this.findSizeByShape(this.shapeId)
      } else {
        this.shapeId = [];
      }
    } else if (property == 'clarityIdList') {
      if (idList.length > 0) {
        this.clarityId = idList;
      } else {
        this.clarityId = [];
      }
    } else if (property == 'sizeList') {
      if (idList.length > 0) {
        this.sizeList.filter(el => {
          if (el.id == idList[0]) {
            this.fromCts = el.fromCts;
            this.toCts = el.toCts;
          }
        })
      } else {
        this.sizeIdList = []
      }
    }
  }

  setItems() {
    this.items = this.stoneList;
    this.count = this.items.length;
  }

  async findShapeByType() {
    let res: any;
    res = await this.httpApiService.findShapeByType('Sale Bulk');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let data1 = []
      dataValue.forEach(el => {
        data1.push({ id: el.shape, name: el.shapeName })
      })
      this.shapeList = data1;
    }
  }

  async findSizeByShape(shapeId) {
    let res: any;
    res = await this.httpApiService.findSizeByShape(shapeId, 'Sale Bulk');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.sizeList = data.data;
      console.log(this.sizeList)
    }
  }

  async getAllSingleStoneIssuedForMixing() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.getAllSingleStoneIssuedForMixing();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneList = data.data;
      this.count = data.data.length;
      this.setItems()
      console.log(this.stoneList)
    }
    await this.loadingController.dismiss()
  }

  async findParametersForMixSizeMaster() {
    let res: any;
    res = await this.httpApiService.findParametersForMixSizeMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.colorList) {
        let data1 = []
        dataValue.colorList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.colorList = data1;
      }
      if (dataValue.clarityList) {
        let data1 = []
        dataValue.clarityList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.clarityList = data1;
      }
    }
  }

  async searchCSSP() {
    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      colorId: this.colorId,
      shapeId: this.shapeId,
      clarityId: this.clarityId,
      fromCts: this.fromCts,
      toCts: this.toCts,
      listOfBoxType: ['Regular'],
    }
    res = await this.httpApiService.findCsspOrCreateIfNotExist(jsonObj, 'true');
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.csspList = data.data;
      if (this.csspList.length > 0) {
        this.countCssp = this.csspList.length
      } else {
        await this.configService.showToast('error', 'Searched Cssp not found.')
      }

    }
  }

  async convertSingleToMix() {
    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      listOfStoneIds: this.selectedStoneList,
      stoneId: this.csspList[0].id
    }
    console.log(jsonObj)
    res = await this.httpApiService.convertSingleToMix(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.resetData();
      this.getAllSingleStoneIssuedForMixing()
    }
  }

  resetData() {
    this.csspList = []
    this.selected = []
    this.clarityId = []
    this.colorId = []
    this.sizeList = []
    this.shapeId = [];
    this.shapeIdList = []
    this.clarityIdList = []
    this.colorIdList = []
    this.sizeIdList = []
    this.openSelectedcsspListFlag = false;
  };

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  onSelect(ev) {
    this.selected = ev.selectedRowsData;
    this.selectedStoneList = [];
    this.selected.forEach(el => {
      this.selectedStoneList.push(el.id);
    })
  }

  async filterItems(ev: any) {
    if (this.stoneList.length > 0) {
      let val = ev.target.value;
      console.log(val)
      await this.setItems();
      if (val && val.trim() !== "") {
        this.items = await this.items.filter(function (item) {
          if (item.stoneId && item.stoneId.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.color && item.color.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.clarity && item.clarity.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.shape && item.shape.toLowerCase().includes(val.toLowerCase())) {
            return true;
          }
        });
        this.count = this.items.length;
      } else {
        await this.setItems();
      }
    }

  }

}
