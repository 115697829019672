import { Component, Input, ViewChild } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { LoadingController, ModalController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageUploadStonePage } from "../manage-upload-stone/manage-upload-stone.page";
import { FileUploadComponent } from "../../fileUpload/file-upload.component";
import { ConfigService } from "../../../services/config/config.service";
import * as moment from "moment";

@Component({
  selector: "app-upload-stone",
  templateUrl: "./upload-stone.component.html",
  styleUrls: ["./upload-stone.component.scss"]
})
export class UploadStoneComponent implements ProcessActionComponent {
  // @ViewChild("inputFile") fileInputVariable: any;
  @Input() data: any;

  // file upload data
  historyData: any = [];
  selectedFile: File = null;
  pageNumber = 0;
  noOfRecords = 5;
  totalPages: number;
  count: number = 0;
  userId: number;
  public toLocationIdList = [];
  public voucherBookId: any;
  public toRole: any;
  // interval: any = []

  //Stone Transfer variable
  public pDate = {
    bigBanner: true,
    timePicker: false,
    format: 'dd-MMM-yyyy',
    defaultOpen: false,
    minDate: new Date()
  }
  public updateInwardDate = new Date();
  public outWardDate = new Date();
  voucherDate: any;
  fromLocation: any;
  toLocation: number;
  fromUser: number;
  toUser: number;
  fromDepartment: number;
  toDepartment: number;
  allLocations = [];
  listOfDepartments: any = [];
  listOfUsers: any = [];
  companyId: number;
  isDisable = true;
  public columns = [];
  public page = "Upload";
  public pageSize = 20;
  
  constructor(
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public ConfigService: ConfigService,
    public event: Events
  ) {
    this.getFromLocation();
    this.getUserData();
    let today = new Date();
    this.voucherDate =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);
    // this.interval = [5,10,25]
  }

  ionViewDidLoad() {
    console.log("ionViewDidLoad UploadStoneComponent");
  }

  async ngOnInit() {
    this.columns = [
      {
        name: 'Sr No',
        cellTemplate: function (cellElement, cellInfo) {
          cellElement.append(cellInfo.row.rowIndex + 1);
        }
      },
      { name: "File Name", prop: "fileName" },
      { name: "Description", prop: "description" },
      { name: "Date", prop: "createdDate" },
      { name: "Status", prop: "status" },
    ];
    await this.loadUploadHistory(this.pageNumber, this.noOfRecords);
    await this.getAllLocations();
    this.event.subscribe('Upload', (item) => {
      this.loadUploadHistory(this.pageNumber, this.noOfRecords);
      this.getAllLocations();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Upload');
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "toLocationFile") {
      if (idList.length > 0) {
        this.toLocation = idList[0];
      } else {
        this.toLocation = null;
      }
    }
  }

  async getUserData() {
    let userData = await this.authService.userData();
    this.userId = userData.userId;
  }

  async getFromLocation() {
    this.fromLocation = await this.ConfigService.getFromLocation();
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.data.actionId
    );
    let data1 = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data1.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data1.error);
    } else {

      let dataValue = data1.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.toLocation) {
          this.toLocation = dataValue.map.toLocation;
          this.toLocationIdList = [];
          this.allLocations.forEach(element => {
            if (element.id == this.toLocation) {
              let data = { id: element.id, companyName: element.companyName };
              this.toLocationIdList.push(data);
            }
          });
        }
        if (!!dataValue.map.toDepartment) {
          this.toDepartment = dataValue.map.toDepartment;
        }
        if (!!dataValue.map.fromDepartment) {
          this.fromDepartment = dataValue.map.fromDepartment;
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole;
        }
        if (!!dataValue.map.toUser) {
          this.toUser = dataValue.map.toUser;
        }
      }
    }
  }

  async outWard(){
    let res: any;
    let date = moment(this.outWardDate).format('DD-MMM-YYYY')
    await this.ConfigService.showLoader();
    res = await this.httpApiService.getOutwardData(date);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data.error);
    } else {
      console.log(data.data)
      await this.ConfigService.dismiss();
      await this.ConfigService.showToast("success", data.message);
    }
  }

  async getAllLocations() {
    let res: any;
    let fromLocation = await this.ConfigService.getFromLocation();
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data.error);
    } else {
      this.allLocations = data.data;

      console.log(this.allLocations);
      await this.getDefaultSetting();
    }
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  async uploadFile() {
    let validate = await this.validateControls();
    if (validate) {
      if (this.selectedFile) {
        let jsonObj = {
          voucherDate: this.voucherDate,
          fromLocation: this.fromLocation,
          toLocation: this.toLocation,
          fromUser: this.userId,
          voucherBookId: this.voucherBookId,
          // toUser: this.toUser,
          toRole: this.toRole,
          fromDepartment: this.fromDepartment,
          toDepartment: this.toDepartment,
          remarks: "This is stone remarks"
        };
        console.log(jsonObj);
        const formData: FormData = new FormData();
        formData.append("file", this.selectedFile);
        formData.append("myJson", JSON.stringify(jsonObj));
        await this.ConfigService.showLoader();
        let res: any;
        res = await this.httpApiService.uploadFile(formData);
        console.log(res.json());
        await this.ConfigService.dismiss();
        let uploadData = res.json();
        if (uploadData.status == 400) {
          await this.ConfigService.showToast("error", uploadData.error);
        } else if (res.status == 500) {
          await this.ConfigService.showToast("error", uploadData.error);
        } else if (!!uploadData.data && uploadData.data.totalErrors != 0) {
          await this.ConfigService.showToast("error", "There is an error while uploading the file");
          await this.loadUploadHistory(this.pageNumber, this.noOfRecords);
        } else {
          await this.ConfigService.showToast("success", uploadData.message);
          this.selectedFile = null;
          await this.loadUploadHistory(this.pageNumber, this.noOfRecords);
        }
      } else {
        await this.ConfigService.showToast("error", "Please select file");
      }
    }
  }

  async loadUploadHistory(pageNumber, noOfRecords) {
    await this.ConfigService.showLoader();
    let res: any;
    // Json Inward Upload
    res = await this.httpApiService.getInwardUploadHistory('File Inward Upload', 'Json Upload', pageNumber, noOfRecords);
    await this.ConfigService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(data);
      this.historyData = dataValue.content;
      this.totalPages = dataValue.totalPages;
      this.count = dataValue.totalElements;
    }
  }

  async showProgressStatus(history) {
    const modal = await this.modalCtrl.create({
      component: ManageUploadStonePage,
      backdropDismiss: false,
      componentProps: {
        data: history,
        transferObj: {
          voucherDate: this.voucherDate,
          fromLocation: this.fromLocation,
          toLocation: this.toLocation,
          fromUser: this.userId,
          toRole: this.toRole,
          fromDepartment: this.fromDepartment,
          toDepartment: this.toDepartment,
          remarks: "This is stone remarks"
        }
      }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async handleModalDismiss(d) {
    console.log("d", d);
    this.loadUploadHistory(this.pageNumber, this.noOfRecords);
  }

  async downloadFile(item) {
    if (item.type == 'File Inward Upload') {
      let res: any;
      res = await this.httpApiService.downloadUploadedFile(item.id);
    } else {

    }
  }

  async setPage(currentPage) {
    console.log(currentPage);
    let res: any;
    this.pageNumber = currentPage.offset;
    await this.loadUploadHistory(this.pageNumber, this.noOfRecords);
  }

  async validateControls() {
    console.log(
      this.fromDepartment, this.toDepartment,
      this.fromLocation,
      this.toLocation,
      this.voucherBookId,
      this.userId,
      this.toRole,
      this.voucherDate
    );
    if (
      !!this.fromDepartment &&
      !!this.toDepartment &&
      !!this.fromLocation &&
      !!this.toLocation &&
      !!this.userId &&
      !!this.toRole &&
      !!this.voucherDate &&
      !!this.voucherBookId
    ) {
      return true;
    } else {
      await this.ConfigService.showToast("error", "Please insert the detail");
      return false;
    }
  }

  async updateParamDetail() {
    // this.listOfErrors = [];
    await this.ConfigService.showLoader();
    let res: any;
    await this.httpApiService.getKGPartyDetails(moment(new Date(this.updateInwardDate)).format("DD-MMM-YYYY"));
    res = await this.httpApiService.updateDataFromKG(moment(new Date(this.updateInwardDate)).format("DD-MMM-YYYY"));
    await this.ConfigService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      // if (data.totalErrors > 0) {
      //   if (data.listOfErrorsWithLineNumbers.length > 0) {
      //     for (let i = 0; i < data.listOfErrorsWithLineNumbers.length; i++) {
      //       let errMsg = data.listOfErrorsWithLineNumbers[i].errorMessage;
      //       this.listOfErrors.push(errMsg)
      //     }
      //   }
      //   await this.ConfigService.showToast("error", this.listOfErrors.join(','));
      // }else{
      await this.ConfigService.showToast("error", data.error);
      // }      
    } else {
      // this.modalCtrl.dismiss();
      if (res.status == 200) {
        await this.ConfigService.showToast("success", data.message);
      } else {
        await this.ConfigService.showToast("error", data.error);
      }
    }
  }
}
