import { Component, Input, OnInit } from '@angular/core';
import { ProcessActionComponent } from '../../processAction';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-hold-report',
  templateUrl: './hold-report.component.html',
  styleUrls: ['./hold-report.component.scss']
})
export class HoldReportComponent implements ProcessActionComponent {
  @Input() data: any;

  constructor(public sanitizer: DomSanitizer) { }
  dataStudioLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://lookerstudio.google.com/embed/reporting/0e39316e-f4e3-4759-ad40-15e920515281/page/LF5bD');
  ngOnInit() {
  }

}
