import { Component, OnInit, Input } from '@angular/core';
import * as moment from "moment";

@Component({
  selector: 'app-generate-po',
  templateUrl: './generate-po.component.html',
  styleUrls: ['./generate-po.component.scss']
})
export class GeneratePOComponent implements OnInit {

  @Input() companyDetails: any;
  @Input() customerDetails: any;
  @Input() stoneDetails: any = [];
  @Input() transtype: any;
  @Input() optionPO: any;
  @Input() terms: any;
  @Input() disc1: any;
  @Input() disc2: any;
  @Input() voucherData: any;

  public salesPersonName: any;
  public totalCarat = 0;
  public pricePerCts = 0;
  public totalPricePerCts = 0
  public totalAmount = 0;
  curDate: any;
  public salesPerson = '';
  public brokerName = '';
  public brokerContact = '';
  public date: any;
  public numbers = []
  public voucherNo: number = 0;
  public fxRate = 0;
  public lab: string = ''
  public janghadNo: string = '';
  public address: string = '';
  
  constructor() { }

  ngOnInit() {
    this.curDate = new Date()
    // this.numbers = Array(200).fill(10);
    console.log(this.numbers)
    this.date = moment().format('DD/MM/YYYY');
    this.totalPricePerCts = 0
    for (let i = 0; i < this.stoneDetails.length; i++) {
      this.totalCarat += this.stoneDetails[i].stoneCts;
      this.totalPricePerCts = this.totalPricePerCts + (!!this.stoneDetails[i].requestPrice ? this.stoneDetails[i].requestPrice : this.stoneDetails[i].price);
      this.totalAmount = this.totalAmount + (this.stoneDetails[i].requestPrice * this.stoneDetails[i].stoneCts);

      if( this.stoneDetails[0].partyAddress){
        this.address = this.stoneDetails[0].partyAddress;
      }
      if (!this.lab) {
        this.lab = this.stoneDetails[i].lab;
      }
    }
    console.log(this.totalAmount, this.totalPricePerCts)

    if (!!this.stoneDetails || this.stoneDetails.length > 0) {

      this.terms = !!this.stoneDetails[0].termName ? this.stoneDetails[0].termName : '';
      this.salesPerson = !!this.stoneDetails[0].requestUser ? this.stoneDetails[0].requestUser : '';
    }
    if (!!this.voucherData) {
      this.terms = !!this.voucherData.terms ? this.voucherData.terms : '';
      this.fxRate = !!this.voucherData.exgRate ? this.voucherData.exgRate : 0;
      this.brokerName = !!this.voucherData.brokerName ? this.voucherData.brokerName : '';
      this.brokerContact = !!this.voucherData.brokerContact ? this.voucherData.brokerContact : '';
      this.voucherNo = this.voucherData.id;
      this.janghadNo = this.voucherData.shortCode + "-" + this.voucherData.bookSerialNumber;
    }
    console.log(this.fxRate)
    if (!!this.customerDetails && this.customerDetails.addresses && this.customerDetails.addresses.length > 0) {
      let isFoundAddress = this.customerDetails.addresses.find(el => el.address == this.address);
      if (!!isFoundAddress) {
        this.customerDetails.gstin = isFoundAddress.gstin;
        this.customerDetails.state = isFoundAddress.state;
        this.customerDetails.stateCode = isFoundAddress.stateCode;
      }
    }
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

}
