export default {
    reportNo: 'R-14',
    url: "master-service/api/report/mixProfitLossData",
    method: "GET",
    size: 3,
    fields: [
      {
        title: ' Trans Type ',
        input: 'select',
        key: 'transType',
        placeholder: 'Select Trans Type',
        property: 'transType',
        keyName: 'name',
        name: 'name',
        required: true,
        singleSelection: true,
        function: 'getAllTransType',
        default: [],
        size: 3
      },
      {
        title: ' Transaction Type ',
        input: 'select',
        key: 'transactionType',
        placeholder: 'Select Transaction Type',
        property: 'transactionType',
        keyName: 'id',
        required: true,
        name: 'name',
        singleSelection: true,
        itemList: [{
          id: 'inwards',
          name: 'Inwards'
        }, {
          id: 'sales',
          name: 'Sales'
        }, {
          id: 'reassortUpgrade',
          name: 'Reassort Upgrade'
        }, {
            id: 'repricing',
            name: 'Repricing'
        }, {
            id: 'reassortDowngrade',
            name: 'Reassort Downgrade'
        }],
        default: [],
        size: 3
      },
      {
        title: ' From Date ',
        input: 'dateTime',
        key: 'fromDate',
        placeholder: 'Select From Date',
        property: 'fromDate',
        required: true,
        default: [],
        setting: {
          bigBanner: true,
          timePicker: true,
          format: 'yyyy-MM-dd',
          defaultOpen: false,
          minDate: new Date()
        },
        size: 3
      },
      {
        title: ' To Date ',
        input: 'dateTime',
        key: 'toDate',
        placeholder: 'Select To Date',
        property: 'toDate',
        required: true,
        default: [],
        setting: {
          bigBanner: true,
          timePicker: true,
          format: 'yyyy-MM-dd',
          defaultOpen: false,
          minDate: new Date()
        },
        size: 3
      },
    ],
    columns: [
      { name: "Date", prop: "transactionDate", required: true },
      { name: "Type", prop: "transactionType", required: true },
      { name: "Criteria Name", prop: "criteriaName", required: true },
      { name: "Stone Id", prop: "stoneName", required: true },

      { name: "Opening Carats", prop: "openingCarats" },
      { name: "Opening Value", prop: "openingValue" },
      { name: "Inwards", prop: "inwards", ngIf: ['transactionType', 'inwards'] },
      { name: "Sales Carats", prop: "salesCarats", ngIf: ['transactionType', 'sales'] },
      { name: "Reassort Upgrade", prop: "reassortUpgrade", ngIf: ['transactionType', 'reassortUpgrade'] },
      { name: "Reassort Downgrade", prop: "reassortDowngrade", ngIf: ['transactionType', 'reassortDowngrade'] },
      { name: "Repricing", prop: "repricing", ngIf: ['transactionType', 'repricing'] },

      { name: "List Price", prop: "listPrice" },
      { name: "Closing Carats", prop: "closingCarats" },
      { name: "Closing Value", prop: "closingValue" },
      { name: "Selection Percentage", prop: "selectionPercentage" },
      { name: "Cost Markup", prop: "costMarkup" },
      { name: "Cost Of Goods Sold", prop: "costOfGoodsSold" },
      { name: "Cogs Per Carat", prop: "cogsPerCarat" },
      { name: "Sale Price", prop: "salePrice" },
      { name: "Profit Value", prop: "profitValue" },
      { name: "Cost Of Balance", prop: "costOfBalance" },
      { name: "Cost Per Carat", prop: "costPerCarat" },
      { name: "Inventory Profit", prop: "inventoryProfit" },
    ]
  };
  