export default {
    reportNo: 'R-11',
    url: "master-service/api/report/stonesInwardData",
    method: "GET",
    size: 6,
    fields: [
        {
            title: ' Voucher Type ',
            input: 'select',
            key: 'voucherType',
            placeholder: 'Select Voucher Type',
            property: 'voucherType',
            keyName: 'name',
            name: 'name',
            singleSelection: true,
            required: true,
            itemList: [{
                id: 1,
                name: 'InterCompany Transfer'
            }, {
                id: 2,
                name: 'Purchase'
            }],
            default: [],
            size: 3
        },
        {
            title: ' Inward confirmation ',
            input: 'select',
            key: 'isConfirmed',
            placeholder: 'Select Inward confirmation',
            property: 'isConfirmed',
            keyName: 'id',
            name: 'name',
            singleSelection: true,
            itemList: [{
                id: false,
                name: 'Pending to Receive'
            }, {
                id: true,
                name: 'Received'
            }],
            default: [],
            size: 3
        },
        {
            title: ' Trans Type ',
            input: 'select',
            key: 'transType',
            placeholder: 'Select Trans Type',
            property: 'transType',
            keyName: 'name',
            name: 'name',
            required: true,
            singleSelection: true,
            function: 'getAllTransType',
            default: [],
            size: 3
        },
        {
            title: ' Assortment Status ',
            input: 'select',
            key: 'isAssortmentDone',
            placeholder: 'Select Assortment Status',
            property: 'isAssortmentDone',
            keyName: 'id',
            name: 'name',
            singleSelection: true,
            itemList: [{
                id: true,
                name: 'Done'
            }, {
                id: false,
                name: 'Pending'
            }],
            default: [],
            size: 3
        },
        {
            title: ' From Date ',
            input: 'dateTime',
            key: 'fromDate',
            placeholder: 'Select From Date',
            property: 'fromDate',
            default: new Date(),
            setting: {
                bigBanner: true,
                timePicker: true,
                format: 'yyyy-MM-dd',
                defaultOpen: false,
                minDate: new Date()
            },
            size: 3
        },
        {
            title: ' To Date ',
            input: 'dateTime',
            key: 'toDate',
            placeholder: 'Select To Date',
            property: 'toDate',
            default: new Date(),
            setting: {
                bigBanner: true,
                timePicker: true,
                format: 'yyyy-MM-dd',
                defaultOpen: false,
                minDate: new Date()
            },
            size: 3
        },
        {
            title: 'From Inward Date ',
            input: 'dateTime',
            key: 'fromInwardDate',
            placeholder: 'Select From Date',
            property: 'fromInwardDate',
            default: new Date(),
            setting: {
                bigBanner: true,
                timePicker: true,
                format: 'yyyy-MM-dd',
                defaultOpen: false,
                minDate: new Date()
            },
            size: 3
        },
        {
            title: 'To Inward Date ',
            input: 'dateTime',
            key: 'toInwardDate',
            placeholder: 'Select To Date',
            property: 'toInwardDate',
            default: new Date(),
            setting: {
                bigBanner: true,
                timePicker: true,
                format: 'yyyy-MM-dd',
                defaultOpen: false,
                minDate: new Date()
            },
            size: 3
        },
        {
            title: ' Lot No. ',
            input: 'serverSelect',
            key: 'lotNo',
            placeholder: 'Select Lot No',
            property: 'lotNo1',
            default: [],
            isMultiple: false,
            setting: {
                url: 'master-service/api/report/stonesInwardLotNo',
                paramter: {
                    require: ['voucherType'],
                    optional: ['inwardDate', 'transType', 'transMode', 'receiveDate', 'fromDate', 'toDate', 'isAssortmentDone', 'isConfirmed'],
                    searchBy: 'lotNo'
                },
                totalRecord: 20
            },
            size: 3
        },
        {
            title: ' priceListNameId ',
            input: 'hidden',
            key: 'priceListNameMasterId',
            placeholder: 'Select Price',
            property: 'priceListNameMasterId',
            keyName: 'name',
            name: 'name',
            default: null,
            size: 3
        },
    ],
    dataStudioLink: 'https://lookerstudio.google.com/embed/reporting/a87ad865-7ae6-43a0-99d2-f8d69d6590cb/page/HQPXD',
    columns: [
        {
            name: "Voucher. No.", prop: "invoiceNo", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Inw Date", prop: "inwardDate", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "From Entity Name", prop: "partyName", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "From Entity", prop: "fromEntity", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Trans Mode", prop: "transMode",
            "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum",
        },
        {
            name: "Stone Id", prop: "stoneId", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Cts", prop: "stoneCts",
            "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum",
        },
        {
            name: "Pcs", prop: "stonePcs",
            "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum",
        },
        {
            name: "Shape", prop: "shape", "displayFormat": " ",
            "showInGroupFooter": true,
        },
        {
            name: "Color", prop: "color", "displayFormat": " ",
            "showInGroupFooter": true,
        },
        {
            name: "Clarity", prop: "clarity", "displayFormat": " ",
            "showInGroupFooter": true,
        },
        {
            name: "Cut", prop: "cut", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Polish", prop: "polish", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Symmetry", prop: "symmetry", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Flour", prop: "fluorescence", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Cert Lab", prop: "certiLab", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Size Name", prop: "sizeName", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Trans Type", prop: "transType", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Program", prop: "programmeNo", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Rap", prop: "rapPrice", "displayFormat": " ",
            "showInGroupFooter": true
        },
        { name: "Inw Price", prop: "price", "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum", },
        { name: "Amount", dynamic: ["price", "*", "stoneCts"], "showInGroupFooter": true, "displayFormat": "{0}", "summaryType": "sum", },
        {
            name: "Remark", prop: "remark", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Lot No", prop: "lotNo", "displayFormat": " ",
            "showInGroupFooter": true
        },

        {
            name: "Fct Outw No", prop: "factoryOutWNo", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Fct Outw Date", prop: "factoryOutWDate", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "F Pktno", prop: "fpktNo", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Factory Seqno", prop: "factSeqNo", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Frm	Confirm", prop: "frm", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Year Month", prop: "yearMonth", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Inscr", prop: "inscription", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Fm Inscription", prop: "fmInscription", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "RFID ", prop: "rfid", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Barcode / Smarti Id ", prop: "barcode", "displayFormat": " ",
            "showInGroupFooter": true
        },
        {
            name: "Fm String", prop: "fmStr", "displayFormat": " ",
            "showInGroupFooter": true
        }
    ]
};
