
let fromLocation = 'Mumbai';
if (
  window &&
  "location" in window &&
  "protocol" in window.location &&
  "pathname" in window.location &&
  "host" in window.location
) {
  fromLocation = window.location.hostname.split(".")[0];
}

if (fromLocation == 'localhost') {
  fromLocation = 'Mumbai'
}


export const environment = {
  production: true,
  hosts: [
    {
      host: 'search-el.lattice-app.com',
      auth: 'kg-user:KGLaittceJEH4E=Xrqz7l*KvlkULp',
      protocol: 'https',
      port: 443
    }
  ],
  RESULTS_PER_PAGE: 100,
  INDEX: "kglattice",
  INDEX1: "kgpurchaseconsolidation",
  INDEX2: "converttomix",
  fromLocation: fromLocation || 'Mumbai',
  mode: 'Production',
  appName: fromLocation == 'trybe' ? 'trybe' : 'lattice',
  s3Credentials :    {
    accessKeyId: 'AKIASQAR4AJ62CGTU257',
    secretAccessKey: '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',
    region: 'ap-south-1',
  },
  sentryDSN: 'https://51281c757debd585756f405549983fdb@o4506653997989888.ingest.sentry.io/4506654001201152'
};