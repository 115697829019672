import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Events, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { ConfigService } from 'src/app/services/config/config.service';
import { SearchService } from 'src/app/services/search/search.service';
import { HttpApiService } from 'src/app/services/http-api/http-api.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ProcessActionComponent } from '../../processAction';

@Component({
  selector: 'app-program-rejection',
  templateUrl: './program-rejection.component.html',
  styleUrls: ['./program-rejection.component.scss']
})
export class ProgramRejectionComponent implements ProcessActionComponent {
  @Input() data: any;

  stoneId: string = null;
  stoneDetail: any[] = [];
  programRejectionTypeList = [];
  columns: any[] = [];
  historyColumns: any[] = [];
  historyList: any[] = [];
  partyName: string = ''
  page = 'Program Rejection';
  programRejectionSubTypeList: any[] = [];
  username: string = '';
  allowStatusList = null
  availableStatusList = [];
  inwardDaysLimit = 0;
  listOfStatus = []
  formData: any = {

  };
  isSubType: boolean = false;

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public toastCtrl: ToastController,
    public configService: ConfigService,
    private es: SearchService,
    public event: Events) {
    this.getProgramRejectionType()
  }

  async ngOnInit() {
    this.getUsername()
    await this.getAllStatus()
    await this.getDefaultSetting()
    this.columns = [
      { name: "Program Name", prop: "progName" },
      { name: "Party Name", prop: "progPartyName" },
      { name: "Cts", prop: "cts" },
      { name: "Rapaport/cts", prop: "RAPAPORTpercarat" },
      { name: "Program Amount", prop: "BomprogramAmt" },
      { name: "P Price Amount", prop: "PPriceAmt" },
      { name: "Base PD Amount", prop: "BASEPD" },
      { name: "Mumbai Mfg Amt", prop: "MumbaiMfgAmt" },
      { name: "Mumbai Mfg Amt2", prop: "MumbaiMfgAmt2" },
      { name: "P Price Date", prop: "PPriceDate" },
    ]
    this.historyColumns = [
      { name: "Prog Rejection Type", prop: "prog_type" },
      { name: "Prog Rejection Sub Type", prop: "prog_sub_type" },
      { name: "Program Name", prop: "programName" },
      { name: "Party Name", prop: "progPartyName" },
      { name: "Rejected By", prop: "salesPerson" },
      { name: "Rapaport/cts", prop: "RAPAPORTpercarat" },
      { name: "Program Amount", prop: "BomprogramAmt" },
      { name: "P Price Amount", prop: "PPriceAmt" },
      { name: "Base PD Amount", prop: "BASEPD" },
      { name: "Mumbai Mfg Amt", prop: "MumbaiMfgAmt" },
      { name: "Mumbai Mfg Amt2", prop: "MumbaiMfgAmt2" },
      { name: "Created By", prop: "createdBy" },
      { name: "Created Date", prop: "createdDate" }
    ]
  }


  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", data);
      if (!!dataValue.map) {
        if (!!dataValue.map.isAllowedStatus) {
          this.allowStatusList = JSON.parse(dataValue.map.isAllowedStatus);
          let availableStatusList = this.listOfStatus.filter(item => this.allowStatusList.filter(el => el == item.id).length > 0);
          this.availableStatusList = availableStatusList.map(el => el.name)
        }
        if (!!dataValue.map.Limit) {
          this.inwardDaysLimit = dataValue.map.Limit;
        }
      }
    }
  }

  async onChange(ev) {
    this.resetDetail()
    let qry: any =
    {
      "query": {
        "bool": {
          "should": [
            {
              "simple_query_string": {
                "query": ""
              }
            },
            {
              "bool": {
                "must": []
              }
            }
          ]
        }
      }
    }
    if (!!ev.target.value) {
      this.stoneId = ev.target.value;
      let boolShouldMultiple = {
        "bool": {
          "should": []
        }
      };
      let sData = {
        terms: {
          "stoneName": [ev.target.value]
        }
      }
      let location = {
        "terms": { location: ["mumbai"] }
      }
      this.stoneDetail = []
      boolShouldMultiple.bool.should.push(sData);
      qry.query.bool.should[1].bool.must.push(location);
      qry.query.bool.should[1].bool.must.push(boolShouldMultiple);
      let data = await this.es.getPaginatedDocuments(qry, 0, environment.INDEX, '', 1);
      this.getProgramRejectionHistory()

      if (!!data.hits.hits && data.hits.hits.length > 0) {
        let detailDTO = data.hits.hits[0]._source;
        console.log(this.availableStatusList.includes(detailDTO['internalStatus']))
        if (!this.availableStatusList.includes(detailDTO['internalStatus'])) {
          this.configService.showToast('error', `StoneId with status "${detailDTO['internalStatus']}" not allowed to do program rejection`)
          return;
        }
        detailDTO['BomprogramAmt'] = Number((detailDTO['Bomprogramrate'] * detailDTO['cts']).toFixed(2));
        if (detailDTO['progName']) {
          detailDTO['progPartyName'] = await this.getPartyDetailFromProgName(detailDTO['progName'])
          this.stoneDetail.push(detailDTO);
        }

      } else {
        this.configService.showToast('error', 'StoneId not found')
      }
    }
  }

  dropDownSelection(ev) {
    console.log(ev);
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "PRType") {
      if (idList.length > 0) {
        this.formData["prog_type"] = obj[0].name;
        let selectedSubType = this.programRejectionTypeList.filter(el => el.id == idList[0])[0];
        if (!!selectedSubType.isSubType) {
          this.isSubType = true;
          this.programRejectionSubTypeList = selectedSubType.subTypeList
        } else {
          this.isSubType = false;
          this.programRejectionSubTypeList = []
        }
      } else {
        this.isSubType = false;
        this.programRejectionSubTypeList = []
        this.formData["prog_type"] = null;
      }
    }
    if (property == "PRSubType") {
      console.log(this.formData);
      if (idList.length > 0) {
        this.formData["prog_sub_type"] = obj[0].name;
      } else {
        this.formData["prog_sub_type"] = null;
      }
    }
  }

  async getAllStatus() {
    let res: any;
    res = await this.httpApiService.getStatus();
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.listOfStatus = data.data;
    }
  }

  async getProgramRejectionHistory() {
    let res: any;
    res = await this.httpApiService.getProgramRejectionHistory(this.stoneId);
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      if (data.data && data.data.length > 0) {
        data.data.forEach((item: any) => {
          if (item.PricingDetails) {
            let pricingData = JSON.parse(item.PricingDetails);
            item.programAmount = !!pricingData.programAmount ? pricingData.programAmount : '';
            item.PPriceAmt = !!pricingData.PPriceAmt ? pricingData.PPriceAmt : '';
            item.MumbaiMfgAmt = !!pricingData.MumbaiMfgAmt ? pricingData.MumbaiMfgAmt : '';
            item.MumbaiMfgAmt2 = !!pricingData.MumbaiMfgAmt2 ? pricingData.MumbaiMfgAmt2 : '';
            item.BASEPD = !!pricingData.BASEPD ? pricingData.BASEPD : '';
            item.RAPAPORTpercarat = !!pricingData.RAPAPORTpercarat ? pricingData.RAPAPORTpercarat : '';
            item.BomprogramAmt = !!pricingData.BomprogramAmt ? pricingData.BomprogramAmt : '';
          }
          // item.created_date = item.created_date;
        });
        this.historyList = data.data;
        console.log(this.historyList)
      }

    }
  }

  async getPartyDetailFromProgName(progName) {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.findPartyNameByProgramName(progName);
    let data = res.json();
    await this.loadingController.dismiss()
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      if (!!data.data && !!data.data.partyName) {
        return data.data.partyName
      }
    }
  }

  async getProgramRejectionType() {
    let res: any;
    res = await this.httpApiService.getProgramRejectionType();
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.programRejectionTypeList = data.data;
    }
  }


  //save program rejection form 

  async getUsername() {
    this.username = await this.authService.userName()
  }

  async saveOnProgramRejection() {
    let res: any;

    let validate = await this.validateReuquest();
    let payload = {
      stoneId: this.stoneId,
      prog_type: this.formData.prog_type,
      prog_sub_type: this.formData.prog_sub_type,
      programName: !!this.stoneDetail[0].progName ? this.stoneDetail[0].progName : '',
      progPartyName: !!this.stoneDetail[0].progPartyName ? this.stoneDetail[0].progPartyName : '',
      salesPerson: this.username,
      notes: this.formData.notes,
      PricingDetails: {
        RAPAPORTpercarat: !!this.stoneDetail[0].RAPAPORTpercarat ? this.stoneDetail[0].RAPAPORTpercarat : 0,
        PPriceAmt: !!this.stoneDetail[0].PPriceAmt ? Number(this.stoneDetail[0].PPriceAmt) : 0,
        BASEPD: !!this.stoneDetail[0].BASEPD ? this.stoneDetail[0].BASEPD : 0,
        MumbaiMfgAmt: !!this.stoneDetail[0].MumbaiMfgAmt ? this.stoneDetail[0].MumbaiMfgAmt : 0,
        MumbaiMfgAmt2: !!this.stoneDetail[0].MumbaiMfgAmt2 ? this.stoneDetail[0].MumbaiMfgAmt2 : 0,
        BomprogramAmt: !!this.stoneDetail[0].BomprogramAmt ? Number(this.stoneDetail[0].BomprogramAmt) : 0,
      },
      stoneDetail: this.stoneDetail[0],
      inwardDaysLimit: this.inwardDaysLimit
    }
    console.log(payload)
    if (validate) {
      res = await this.httpApiService.saveProgramRejectionHistory(payload);
      let data = res.json();
      if (data.status == 401) {
        await this.authService.logout()
      } else if (data.status == 500) {
        await this.configService.showToast('error', data.error)
      } else if (data.status == 400) {
        await this.configService.showToast('error', data.error)
      } else {
        this.resetDetail()
        await this.configService.showToast('success', data.message)
      }
    }

  }


  async validateReuquest() {
    if (!this.formData.prog_type) {
      if (this.isSubType && !this.formData.prog_type) {
        await this.configService.showToast('error', 'Please fill required fields')
        return false;
      } else {
        return true
      }
    } else {
      return true
    }
  }

  resetDetail() {
    this.stoneDetail = [];
    this.historyList = [];
    this.stoneId = null;
    this.isSubType = false;
  }




}
