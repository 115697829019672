import { Component, OnInit } from '@angular/core';
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { IonicSelectableComponent } from "ionic-selectable";
import { SearchService } from "src/app/services/search/search.service";
import * as XLSX from 'xlsx';
import { ExportAsExcelService } from "../../../services/export-as-excel.service";

@Component({
  selector: 'app-purchaseconsolidation-updateparameterview',
  templateUrl: './purchaseconsolidation-updateparameterview.component.html',
  styleUrls: ['./purchaseconsolidation-updateparameterview.component.scss']
})
export class PurchaseconsolidationUpdateparameterviewComponent implements OnInit {
  columns = [];
  dataSource: any = [];
  public partyName: any;
  public pageSize: any = 100;
  pageNumber = 0;
  noOfRecords = 15;
  stonesList = [];
  searchTxt = ``;
  selected = [];
  public refreshGrid: Boolean = false;
  public Stones = ``;
  public readFromExcel: any;
  public listOfLabs = [];
  public labIdList = [];
  public labId: any;
  enquiryNo = '';
  public selectedServices: any;

  constructor(
    private es: SearchService,
    public httpApiService: HttpApiService,
    public exportAsExcelService: ExportAsExcelService,
    public authService: AuthenticationService,
    public configService: ConfigService) { }

  async ngOnInit() {
    this.columns = [
      { name: "enquiryno", prop: "enquiryId" },
      { name: "Stoneid", prop: "stoneName" },
      { name: 'Clarity', prop: 'ClarityCode' },
      { name: 'Color', prop: 'ColorCode' },
      { name: 'Shape', prop: 'ShapeCode' },
      { name: 'Size', prop: 'cts' },
      { name: 'Purchase party', prop: 'partyName' },
      { name: 'Broker', prop: 'brokerName' },
      { name: 'Ref Sale Person', prop: 'SalePerson' },
      { name: "Date", prop: "createdDate" },
      { name: "BillReceived", prop: "isBillNo" },
      { name: "CertiReceived", prop: "isCerti" },
      { name: "CVD checked", prop: "cvdStatus" },
      { name: "Bank Rate", prop: "BankRate" },
      { name: "Cert Date", prop: "CertDate" },
      { name: "CrAngle", prop: "CrAngle" },
      { name: "CrHgt", prop: "CrHgt" },
      { name: "CrAngle", prop: "CrAngle" },
      { name: "CuletSize", prop: "CuletSize" },
      { name: "Fluorescence", prop: "Fluorescence" },
      { name: "FluorescenceColor", prop: "FluorescenceColor" },
      { name: "Girdle", prop: "Girdle" },
      { name: "GirdleCondition", prop: "GirdleCondition" },
      { name: "GirdlePer", prop: "GirdlePer" },
      { name: "KeyToSymbols", prop: "KeyToSymbols" },
      { name: "Length", prop: "Length" },
      { name: "PavHgt", prop: "PavHgt" },
      { name: "PolishCode", prop: "PolishCode" },
      { name: "PurchasePartyAmt", prop: "PurchasePartyAmt" },
      { name: "PurchasePartyDisc", prop: "PurchasePartyDisc" },
      { name: "PurchasePartyPrice", prop: "PurchasePartyPrice" },
      { name: "ReportComments", prop: "ReportComments" },
      { name: "ReportNo", prop: "ReportNo" },
      { name: "Service", prop: "Service" },
      { name: "SymmetryCode", prop: "SymmetryCode" },
      { name: "SyntheticIndicator", prop: "SyntheticIndicator" },
      { name: "TablePer", prop: "TablePer" },
      { name: "TotDepth", prop: "TotDepth" },
      { name: "TotDepthMm", prop: "TotDepthMm" },
      { name: "accusoftInvoiceNo", prop: "accusoftInvoiceNo" },
      { name: "accusoftsubSerialNo", prop: "accusoftsubSerialNo" },
      //{ name: "brokerId", prop: "brokerId" },
      { name: "brokerName", prop: "brokerName" },
      { name: "clarityStatus", prop: "clarityStatus" },
      { name: "colorDescription", prop: "colorDescription" },
      { name: "cts", prop: "cts" },
      { name: "disc1", prop: "disc1" },
      { name: "disc2", prop: "disc2" },
      { name: "exRate", prop: "exRate" },
      { name: "expRate", prop: "expRate" },
      { name: "inscription", prop: "inscription" },
      { name: "lotNo", prop: "lotNo" },
      { name: "paintComm", prop: "paintComm" },
      { name: "partyName", prop: "partyName" },
      { name: "shapeDescription", prop: "shapeDescription" },
      { name: "starLength", prop: "starLength" },
      { name: "status", prop: "status" },
      { name: "terms", prop: "terms" },
      { name: "wgtDiff", prop: "wgtDiff" },
      { name: "width", prop: "width" }
    ];

    await this.getAllLabs();
  }

  fileObject(ev) {
    this.readFromExcel = []
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.uploadFile(file);
  }

  async uploadFile(file) {
    if (this.labId) {
      if (file) {
        let myJson = {
          labId: this.labId
        };
        console.log(myJson);
        const formData: FormData = new FormData();
        formData.append("file", file);
        formData.append("labId", this.labId);
        await this.configService.showLoader();
        let res: any;
        res = await this.httpApiService.uploadPurchaseStoneParameters(formData);
        console.log(res.json());
        await this.configService.dismiss();
        let uploadData = res.json();
        if (!uploadData.isSuccess && uploadData.error.error && uploadData.error.data.length > 0) {
          await this.configService.showToast("error", 'Error in file');
          console.log(uploadData)
          if (uploadData.error.data.length > 0) {
            await this.exportAsExcelService.exportAsExcelFile(uploadData.error.data, 'Error_Stones')
          }
        } else if (res.status == 500) {
          await this.configService.showToast("error", uploadData.error);
        } else {
          await this.configService.showToast("success", uploadData.message);
          // await this.loadPriceUploadHistory(this.pageNumber, this.noOfRecords);
          //this.labIdList = [];
          //this.labId = '';
          this.getStones()
        }
      } else {
        await this.configService.showToast("error", "Please select file");
      }
    } else {
      await this.configService.showToast("error", "Please select Lab");
    }
  }


  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == '') {
      this.selectedServices = idList;
    } else if (property == 'labList') {
      if (idList.length > 0) {
        this.labId = idList[0]
      } else {
        this.labId = null;
      }
    }
  }

  async getStones() {
    this.stonesList = []
    let listOfStones = []
    if (this.Stones != '') {
      let tmp = this.Stones.split("\n");
      tmp = tmp.map(x => x.trim())

      if (tmp.length > 0) {
        listOfStones = tmp
      }
    }

    if (listOfStones.length > 0 || this.enquiryNo || this.labId) {
      await this.configService.showLoader()
      let obj = {
        'listOfStones': listOfStones,
        'enquiryId': this.enquiryNo,
        'labId': this.labId
      }
      let res: any;
      res = await this.httpApiService.fetchPurchasehParamters(obj, this.pageSize, this.pageNumber);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        if (data.data) {
          this.stonesList = data.data
          this.Stones = '';
        }
      }
    }

  }

  export() {

  }

  async OnSelectRows(event) {
    this.selected = event.selectedRowsData
  }

  async getAllLabs() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("lab");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLabs = data.data;
      // this.items = this.listOfParameter;
    }
  }

}
