import { Component, OnInit, Input } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { DragulaService } from "ng2-dragula";

@Component({
  selector: 'app-manage-policy-chain',
  templateUrl: './manage-policy-chain.page.html',
  styleUrls: ['./manage-policy-chain.page.scss'],
})
export class ManagePolicyChainPage implements OnInit {
  @Input() listOfChains;
  public policies = []
  public item: any;
  public isActive: any;
  public id: any;
  public flagAddUpdate: any;
  public policyIdList = []
  public chainList = []
  public copy = [];
  public allPriceNameList = []
  public toPriceNameList
  public nameIdList = []
  public priceNameListId: any;
  public startPriceListNameId: any;
  public endPriceListNameId: any;
  public selectedPolicyList = [];
  public fromNameIdList = [];
  public toNameIdList = [];
  public changePosObj = {}
  public criteriaList = []
  public criteria = []
  public stoneList: any = "";
  public filtered: any = [];

  public fromSize: any;
  public toSize: any;

  transTypeList = []
  transTypeIdList = []
  transType = null;
  fromColorIdList = []
  fromClarityIdList = []
  fromCutIdList = []
  fromPolishIdList = []
  fromSymmetryIdList = []
  fromFluorescenceIdList = []
  toColorIdList = []
  toClarityIdList = []
  toCutIdList = []
  toPolishIdList = []
  toSymmetryIdList = []
  toFluorescenceIdList = []
  weightage: number = 0;
  public name: any;

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public dragulaService: DragulaService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) { }

  async ngOnInit() {
    this.selectedPolicyList = []
    let data = this.navParams.get('item');
    await this.getAllPolicies()
    await this.getAllPriceListName()
    await this.getAllTransType()
    if (this.listOfChains) {
      this.listOfChains = this.listOfChains.filter(el => el.id != this.item.id && this.item.weightage < el.weightage).sort(function (a, b) {
        if (a.weightage < b.weightage) { return -1; }
        if (a.weightage > b.weightage) { return 1; }
        return 0;
      })
    }
    if (data != 0) {
      this.item = data.row;
      this.fromNameIdList = []
      this.toNameIdList = []
      this.policyIdList = []
      console.log(this.item)
      this.id = this.item.id;
      this.name = this.item.name
      this.weightage = this.item.weightage
      this.startPriceListNameId = this.item.startPriceListNameId
      this.endPriceListNameId = this.item.endPriceListNameId
      this.fromNameIdList.push({ id: this.item.startPriceListNameId, name: this.item.startPriceListName })
      this.toNameIdList.push({ id: this.item.endPriceListNameId, name: this.item.endPriceListName })
      if (this.item.pricePolicies.length > 0) {
        this.item.pricePolicies.forEach((el, i) => {
          this.chainList.push({ id: el.pricePolicyMasterId, name: el.pricePolicyName })
          this.policyIdList.push({ id: el.pricePolicyMasterId, name: el.pricePolicyName })
          this.selectedPolicyList.push({ id: el.id, pricePolicyMasterId: el.pricePolicyMasterId, policyOrder: el.policyOrder })
        })
        let i = 1;
        this.item.pricePolicies.map(res => { this.changePosObj[res.pricePolicyMasterId] = res.policyOrder; });
      }
    }
    this.flagAddUpdate = this.navParams.get('flag');
    if (this.flagAddUpdate == 'addCriteria') {
      this.findPolicyCriteriaParameters()
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh')
  }


  changePosition(event) {
    this.chainList = event
    this.changePosObj = {};
    let i = 1;
    this.chainList.map(res => { this.changePosObj[res.id] = i++; });
    console.log(this.changePosObj)
  }

  async findPolicyCriteriaParameters() {
    let res: any;
    res = await this.httpApiService.findPolicyCriteriaParameters();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.criteriaList = data.data;
      console.log("findPolicyCriteriaParameters", data.data);
    }
  }

  async getAllPolicies() {
    let res: any;
    res = await this.httpApiService.getAllPolicy();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.policies = data.data;
      console.log("get policies", this.policies);
    }
  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.transTypeList = data.data;
    }
  }

  dropDownSelection(ev) {
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    console.log(this.fromColorIdList)
    if (this.flagAddUpdate == 'addCriteria') {
      let objCr = {}
      let InCriteriaObj = {}
      if (property == 'transType') {
        if (idList.length > 0) {
          this.transType = idList[0]
        } else {
          this.transType = null
        }
      }
      if (property == 'shape') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Shape']) {
              this.criteria.splice(i, 1);
            }
          })
          objCr['Shape'] = { 'eq': idList };
          this.criteria.push(objCr)
        } else {
          this.criteria.forEach((el, i) => {
            if (el['Shape']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      } else if (property == 'fromColor') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Color']) {
              InCriteriaObj = el['Color'];
              this.criteria.splice(i, 1);
            }
          })
          InCriteriaObj['gt'] = idList
          objCr['Color'] = InCriteriaObj;
          this.criteria.push(objCr)
        } else {
          this.toColorIdList = []
          this.criteria.forEach((el, i) => {
            if (el['Color']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      } else if (property == 'toColor') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Color']) {
              InCriteriaObj = el['Color'];
              this.criteria.splice(i, 1);
            }
          })
          InCriteriaObj['lt'] = idList
          objCr['Color'] = InCriteriaObj;
          this.criteria.push(objCr)
        } else {
          this.fromColorIdList = []
          this.criteria.forEach((el, i) => {
            if (el['Color']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      } else if (property == 'fromClarity') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Clarity']) {
              InCriteriaObj = el['Clarity'];
              this.criteria.splice(i, 1);
            }
          })
          InCriteriaObj['gt'] = idList
          objCr['Clarity'] = InCriteriaObj;
          this.criteria.push(objCr)
        } else {
          this.toClarityIdList = []
          this.criteria.forEach((el, i) => {
            if (el['Clarity']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      } else if (property == 'toClarity') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Clarity']) {
              InCriteriaObj = el['Clarity'];
              this.criteria.splice(i, 1);
            }
          })
          InCriteriaObj['lt'] = idList
          objCr['Clarity'] = InCriteriaObj;
          this.criteria.push(objCr)
        } else {
          this.fromClarityIdList = []
          this.criteria.forEach((el, i) => {
            if (el['Clarity']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      } else if (property == 'fromCut') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Cut']) {
              InCriteriaObj = el['Cut'];
              this.criteria.splice(i, 1);
            }
          })
          InCriteriaObj['gt'] = idList
          objCr['Cut'] = InCriteriaObj;
          this.criteria.push(objCr)
        } else {
          this.toCutIdList = []
          this.criteria.forEach((el, i) => {
            if (el['Cut']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      } else if (property == 'toCut') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Cut']) {
              InCriteriaObj = el['Cut'];
              this.criteria.splice(i, 1);
            }
          })
          InCriteriaObj['lt'] = idList
          objCr['Cut'] = InCriteriaObj;
          this.criteria.push(objCr)
        } else {
          this.fromCutIdList = []
          this.criteria.forEach((el, i) => {
            if (el['Cut']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      } else if (property == 'fromPolish') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Polish']) {
              InCriteriaObj = el['Polish'];
              this.criteria.splice(i, 1);
            }
          })
          InCriteriaObj['gt'] = idList
          objCr['Polish'] = InCriteriaObj;
          this.criteria.push(objCr)
        } else {
          this.toPolishIdList = []
          this.criteria.forEach((el, i) => {
            if (el['Polish']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      } else if (property == 'toPolish') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Polish']) {
              InCriteriaObj = el['Polish'];
              this.criteria.splice(i, 1);
            }
          })
          InCriteriaObj['lt'] = idList
          objCr['Polish'] = InCriteriaObj;
          this.criteria.push(objCr)
        } else {
          this.fromPolishIdList = []
          this.criteria.forEach((el, i) => {
            if (el['Polish']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      } else if (property == 'fromSymmetry') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Symmetry']) {
              InCriteriaObj = el['Symmetry'];
              this.criteria.splice(i, 1);
            }
          })
          InCriteriaObj['gt'] = idList
          objCr['Symmetry'] = InCriteriaObj;
          this.criteria.push(objCr)
        } else {
          this.toSymmetryIdList = []
          this.criteria.forEach((el, i) => {
            if (el['Symmetry']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      } else if (property == 'toSymmetry') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Symmetry']) {
              InCriteriaObj = el['Symmetry'];
              this.criteria.splice(i, 1);
            }
          })
          InCriteriaObj['lt'] = idList
          objCr['Symmetry'] = InCriteriaObj;
          this.criteria.push(objCr)
        } else {
          this.fromSymmetryIdList = []
          this.criteria.forEach((el, i) => {
            if (el['Symmetry']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      } else if (property == 'fromFluorescence') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Fluorescence']) {
              InCriteriaObj = el['Fluorescence'];
              this.criteria.splice(i, 1);
            }
          })
          InCriteriaObj['gt'] = idList
          objCr['Fluorescence'] = InCriteriaObj;
          this.criteria.push(objCr)
        } else {
          this.toFluorescenceIdList = []
          this.criteria.forEach((el, i) => {
            if (el['Fluorescence']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      } else if (property == 'toFluorescence') {
        if (idList.length > 0) {
          this.criteria.forEach((el, i) => {
            if (el['Fluorescence']) {
              InCriteriaObj = el['Fluorescence'];
              this.criteria.splice(i, 1);
            }
          })
          InCriteriaObj['lt'] = idList
          objCr['Fluorescence'] = InCriteriaObj;
          this.criteria.push(objCr)
        } else {
          this.fromFluorescenceIdList = []
          this.criteria.forEach((el, i) => {
            if (el['Fluorescence']) {
              this.criteria.splice(i, 1);
            }
          })
        }
      }
      console.log(this.criteria)
    } else {
      if (property == "selectedPolicy") {
        if (idList.length > 0) {
          this.changePosObj = {}
          this.chainList = []
          idList.forEach(el => {
            this.policies.filter(item => {
              if (item.id == el) {
                this.chainList.push(item)
              }
            })
          })
          let i = 1;
          idList.map(res => { this.changePosObj[res] = i++; });
          this.selectedPolicyList
        } else {
          this.chainList = []
          this.policyIdList = []
        }
      } if (property == "fromPriceListName") {
        if (idList.length > 0) {
          this.startPriceListNameId = idList[0];
          this.getAllToPriceListName(this.startPriceListNameId);
        } else {
          this.endPriceListNameId = null;
        }
      } else if (property == "toPriceListName") {
        if (idList.length > 0) {
          this.endPriceListNameId = idList[0];
        } else {
          this.endPriceListNameId = null;
        }
      }
    }


  }

  async getAllToPriceListName(id) {
    let index = this.allPriceNameList.findIndex(x => x.id == id);
    if (index > -1) {
      this.toPriceNameList.slice(index, 1)
    }
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
      this.toPriceNameList = data.data;
    }
  }

  async addPolicyChain() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader()
      let res: any;
      this.selectedPolicyList = []
      if (this.chainList.length > 0) {
        this.chainList.forEach(el => {
          this.selectedPolicyList.push({ pricePolicyMasterId: el.id, policyOrder: this.changePosObj[el.id] })
        })
      }
      let jsonObj = {
        name: this.name,
        startPriceListNameId: this.startPriceListNameId,
        endPriceListNameId: this.endPriceListNameId,
        pricePolicies: this.selectedPolicyList,
        weightage: this.weightage
      }
      console.log(jsonObj)
      res = await this.httpApiService.addPolicyChain(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss()
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    }

  }

  async updatePolicyChain() {
    let validate = await this.validateControls();
    if (validate) {
      let res: any;
      if (this.chainList.length > 0) {
        this.chainList.forEach((el, i) => {
          let flag = this.selectedPolicyList.some(item => item.pricePolicyMasterId == el.id);
          if (!flag) {
            this.selectedPolicyList.push({ pricePolicyMasterId: el.id, policyOrder: this.changePosObj[el.id] })
          }
          this.selectedPolicyList.forEach(item => {
            if (el.id == item.pricePolicyMasterId) {
              item.policyOrder = this.changePosObj[el.id]
            }
          })
        })

        this.selectedPolicyList.forEach(el => {
          let flag = this.chainList.some(item => item.id == el.pricePolicyMasterId);
          if (!flag) {
            let removeIndex = this.selectedPolicyList.findIndex(x => x.id == el.id)
            if (removeIndex > -1) {
              this.selectedPolicyList.splice(removeIndex, 1)
            }

          }
        })
      }
      console.log("***", this.selectedPolicyList)
      let jsonObj = {
        name: this.name,
        startPriceListNameId: this.startPriceListNameId,
        endPriceListNameId: this.endPriceListNameId,
        pricePolicies: this.selectedPolicyList,
        weightage: this.weightage
      }
      res = await this.httpApiService.updatePolicyChain(this.id, jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    }

  }

  async applyPolicyChain() {
    console.log(this.fromSize, this.toSize)
    this.criteria.forEach((el, i) => {
      if (el['cts']) {
        this.criteria.splice(i, 1);
      }
    })
    let InCriteriaObj = {}
    let objCr = {}
    if (this.fromSize != '' && this.fromSize != undefined && this.fromSize >= 0) {
      this.criteria.forEach((el, i) => {
        if (el['cts']) {
          InCriteriaObj = el['Size'];
          this.criteria.splice(i, 1);
        }
      })
      InCriteriaObj['gt'] = [this.fromSize]
      objCr['cts'] = InCriteriaObj;
      this.criteria.push(objCr)
    }
    if (this.toSize != '' && this.toSize != undefined && this.toSize >= 0) {
      this.criteria.forEach((el, i) => {
        if (el['cts']) {
          InCriteriaObj = el['cts'];
          this.criteria.splice(i, 1);
        }
      })
      InCriteriaObj['lt'] = [this.toSize]
      objCr['cts'] = InCriteriaObj;
      this.criteria.push(objCr)
    }
    console.log(this.criteria)
    let validate = await this.validateCriteriaControls();

    if (validate) {
      await this.configService.showLoader()
      let res: any;
      let jsonObj = {};
      if (!!this.stoneList) {
        this.filtered = this.stoneList.split("\n");
        let issueList = []
        this.filtered.forEach(el => {
          if (el.indexOf(",") > 0) {
            let data1 = el.split(',')
            let data2 = []
            data1.forEach(el => {
              data2.push(el.trim())
            })
            Array.prototype.push.apply(issueList, data2);
          } else {
            issueList.push(el)
          }
        })
        this.filtered = issueList.filter(function (el) {
          return el != "";
        });
        jsonObj = {
          policyChainMasterId: this.id,
          listOfStoneId: this.filtered,
          weightage: this.weightage,
          transType: this.transType
        }
      }
      else {
        jsonObj = {
          policyChainMasterId: this.id,
          criteria: this.criteria,
          weightage: this.weightage,
          transType: this.transType
        }
      }
      res = await this.httpApiService.applyPolicyChain(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss()
      if (data.status == 401) {
        await this.authService.logout()
      } else if (data.status == 500) {
        await this.configService.showToast('error', data.error)
      } else if (data.status == 400) {
        await this.configService.showToast('error', data.error)
      } else {
        this.stoneList = '';
        await this.checkForNextChain(jsonObj)
        await this.configService.showToast('success', data.message)
        await this.modalCtrl.dismiss()
      }
    }

  }

  async checkForNextChain(jsonObj) {
    let isList = this.listOfChains.filter(el => el.isSelected)
    console.log(isList)
    if (!!isList && isList.length > 0) {
      for await (let item of isList) {
        jsonObj.policyChainMasterId = item.id;
        jsonObj.weightage = item.weightage;
        await this.httpApiService.applyPolicyChain(jsonObj);
      }
    }
  }

  selectAll(ev) {
    console.log(ev.target.checked)
    if (!!ev.target.checked) {
      this.listOfChains = this.listOfChains.map(el => {
        el.isSelected = true;
        return el
      })
    } else {
      this.listOfChains = this.listOfChains.map(el => {
        el.isSelected = false;
        return el
      })
    }
    console.log(this.listOfChains)
  }

  async validateCriteriaControls() {
    let flagCheck = true;

    for (let i = 0; i < this.criteria.length; i++) {
      if (!this.criteria[i]['Shape']) {
        let keyList = Object.values(this.criteria[i])
        let totalValue = keyList.map(obj => Object.keys(obj))
        console.log(keyList, totalValue, totalValue[0].length)
        if (totalValue[0].length != 2) {
          flagCheck = false
          break;
        }
      }
    }
    if (flagCheck) {
      return true
    } else {
      await this, this.configService.showToast("error", "Please fill missing from to details")
      return false;
    }
  }

  async validateControls() {
    if (this.chainList.length > 0 && !!this.startPriceListNameId && !!this.endPriceListNameId && !!this.name) {
      return true;
    } else {
      await this.configService.showToast('error', 'Please insert details')
      return false;
    }
  }
}
