import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";

import {
  AlertController,
  LoadingController,
  ModalController,
  NavParams,
} from "@ionic/angular";

import { IonicSelectableComponent } from "ionic-selectable";
import * as moment from "moment";
import readXlsxFile from "read-excel-file";

import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";

@Component({
  selector: "app-manage-search",
  templateUrl: "./manage-search.page.html",
  styleUrls: ["./manage-search.page.scss"],
})
export class ManageSearchPage implements OnInit {
  @ViewChild("selectableComponent")
  selectableComponent: IonicSelectableComponent;
  @ViewChild("selectableRefPersonComponent")
  selectableRefPersonComponent: IonicSelectableComponent;

  @ViewChild("excelFile")
  myInputVariable: ElementRef;

  public data = [];
  public rfid: any = [];
  public stoneID: any;
  public userId: any;
  public actionId: any;
  public fromUser: any;
  public noPagination = true;
  public page = "manageSearch";
  public columns: any;
  public view = "Detail";
  public remarks: any;
  public listOfLabs: any;
  public selectedAction: any;
  public selectedLab: any = [];
  public selectedLabidList: any = [];
  public requestFor = "";
  public voucherBookId: any;
  public toRole: any;
  public actions: any;
  public getAllCustomers: any;
  public selectedCustomer: any;
  public getAllCompany: any;
  public selectedCompany: any;
  public usersByCompany: any;
  public selectedCustomerList: any = [];
  public selectedCompanyList: any = [];
  public selectedUserList: any = [];
  public actionName: any;
  public getAllContacts: any;
  public selectedContactList: any = [];
  public selectedBoxList: any = [];
  public customerContactId: any;
  public listOfBox: any;
  public selectedBox: any;
  public listOfTags: any;
  public selectedTagIdList: any = [];
  public selectedTag = "";
  public setOfTags = [];
  public listOfStoneTags: any;
  public items: any;
  public arrowKeyLocation = -1;
  public manageSelectedTag: "";
  public type: any;
  public stoneQtsWiseList = [];
  public isNext: boolean = false;
  public exportAs: any = [];
  public errorMsg = null;
  public errorMsgList = [];
  public successMsg = null;
  public msgStatus = false;
  public isDefault = false;
  public defaultTempalateList = [];
  public defaultTemplateId: any;
  public userTemplateId: any;
  public defaultExportList = [];
  public userExportList = [];
  public count = 0;
  public flagOpenDoc = false;
  public nameOfPersonReceivingGood: any;
  public customerDetails: any;
  public companyDetails = [];
  public salesPersonName: any;
  public status = "Internal";
  public servicesList = [];
  public requestList = [];
  public transporterDetails: any;
  public termName: any;
  public disc1: any;
  public disc2: any;
  public deliveryChallanNo: any;
  public exgRate: any;
  public lab: any;
  public userData: any;
  public listOfActions: any;
  public requestVoucherBookId: any;
  public showSelected = true;
  public termList = [];
  public discountPremium: any;
  public error: any;
  public isError: boolean = false;
  public defaultDetail: any;
  public copyValue: any = null;
  public stoneWithPriceArr = [];
  public columnsForCopyData = [];
  public countCopyArr = 0;
  public priceListMasterId: any;
  public totalCts: any;
  public totalAmount = 0;
  public currencyRate: any;
  public spreadRate: Number = 0;
  public selectedTermList = [];
  public baseUrl: any;
  public addType = "add";
  public csspType = "Regular";
  public withMe: Boolean = false;
  public modeOfTransport: any;
  public url: any;
  public displayPriceList = [];
  public holdVoucherBookId: any;
  public unHoldVoucherBookId: any;
  public blockForSaleVoucherBookId: any;
  public multipleChallanList = [];
  public formDetailList = [];
  public brokerList = [];
  public location: any;
  public brokerPercentage: Number;
  public deptCheck;
  public userCheck;
  public selectedType: any;
  public buttonTextOnIsDirect: any;
  public actionList = [];
  public visitTime: any;
  public shippingDate: any;
  public brokerId: any;
  public businessType: any;
  public selectedCurrency: any;
  public duration: any;
  public formData = {};
  public refPartyList = [];
  public currencyIdList = [];
  public currencyList = [];
  public defaultCurrency: any;
  public listOfAddress = [];
  public selectedAddressIdList = [];
  public businessTypeIdList = [];
  public refPartyIdList = [];
  public selectedBrokerList = [];
  public selectedTransportIdList = [];
  public selectedTagList = [];
  public tagName = "";
  public jangadNo = "";
  public listOfPartyTypeFromDefaults = [];
  public jangadDate = new Date();
  public selectedParty: any;
  public selectedRefCustomer: any;
  public refCustomerName: any;
  public refDescription: any;
  public companyId: any;
  public disableTemplate: any = false;
  public cartUpdateDisable: boolean = false;
  public defaultPartyMasterId = null;
  public unBlockForSaleVoucherBookId = null;
  // public customerPartyType: any;
  public searchResultColumns: any = [
    {
      fieldName: "stoneId",
      name: "Stone ID",
      type: "string",
      orderBy: 1,
      allowDeleting: false,
      showStoneTemplate: true,
      mobileViewOrder: 1,
    },
    {
      fieldName: "rapPrice",
      name: "$/CT",
      type: "integer",
      orderBy: 14,
      allowDeleting: false,
      mobileViewOrder: 2,
    },
    {
      fieldName: "requestDisc",
      name: "Disc",
      type: "number",
      orderBy: 11,
      allowDeleting: false,
      mobileViewOrder: 3,
      allowEditing: true,
      allowUpdating: true,
    },
    {
      fieldName: "requestPrice",
      name: "$ Total",
      type: "number",
      orderBy: 12,
      allowDeleting: false,
      mobileViewOrder: 4,
      allowUpdating: true,
    },
  ];
  public settingsDateTime = {
    bigBanner: true,
    timePicker: true,
    format: "yyyy-MM-dd HH:mm a",
    defaultOpen: false,
    minDate: new Date(),
  };
  public settingsDate = {
    bigBanner: true,
    timePicker: false,
    format: "yyyy-MM-dd",
    defaultOpen: false,
    minDate: new Date(),
  };

  public jDate = {
    bigBanner: true,
    timePicker: false,
    format: "dd-MMM-yyyy",
    defaultOpen: false,
    minDate: new Date(),
  };
  public pageNumber = 0;
  public noOfRecords = 50;
  public tagList = [];
  public tagCount = 0;
  public partyTypeList = [];
  public formFields: any;

  public businessTypeList = [];
  public setOfTagId = [];
  public dataColumns1 = [
    {
      name: "stoneId",
      dataSource: "",
      entityType: "text",
      isAllow: false,
    },
    {
      name: "stoneCts",
      dataSource: "",
      entityType: "text",
      isAllow: false,
    },
    {
      name: "serviceList",
      dataSource: "serviceList",
      entityType: "selection",
      isAllow: true,
    },
  ];
  public showGrid: boolean = false;
  public salesPersonList = [];
  public salePersonIdList = [];
  public stockController: any;
  innerWidth: number;
  public contentClass = "";
  public mobileView: boolean = false;
  columnsMobile: { name: string; prop: string }[];
  rowDataMobile: any[];
  dataForMobile: any;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public httpApiService: HttpApiService,
    public alertCtrl: AlertController,
    public authService: AuthenticationService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public exportAsExcelService: ExportAsExcelService
  ) { }
  @HostListener("mousewheel", ["$event"]) onMouseWheelChrome(event: any) {
    this.disableScroll(event);
  }

  @HostListener("DOMMouseScroll", ["$event"]) onMouseWheelFirefox(event: any) {
    this.disableScroll(event);
  }

  @HostListener("onmousewheel", ["$event"]) onMouseWheelIE(event: any) {
    this.disableScroll(event);
  }

  disableScroll(event: any) {
    if (event.srcElement.type === "number") event.preventDefault();
  }

  @HostListener("window:keyup", ["$event"])
  onKeyPress(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.keyCode == 86) {
      let x = document.activeElement.tagName;
      if (x != "ION-TEXTAREA") {
        if (navigator["clipboard"]) {
          navigator["clipboard"].readText().then((data) => {
            this.copyValue = data;
            console.log(this.copyValue);
            this.getArray();
          });
        }
      }
    }
  }

  dropDownSelection(ev) {
    console.log(ev);
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "customer") {
      console.log(this.formData);
      if (idList.length > 0) {
        this.selectedCustomer = idList[0];
        this.formData["partyMasterId"] = idList[0];
        this.lab = obj[0].partyName;
        this.getAddressesByPartyId(idList[0]);
        this.getAllCustomerContact();
        this.getCustomerDetails(idList[0]);
      } else {
        delete this.formData["partyMasterId"];
        delete this.formData["customerContactId"];
        this.selectedContactList = [];
        this.selectedCustomer = null;
        this.customerContactId = null;
      }
    } else if (property == "contacts") {
      if (idList.length > 0) {
        this.formData["customerContactId"] = idList[0];
        this.customerContactId = idList[0];
        this.nameOfPersonReceivingGood = obj[0].username;
      } else {
        delete this.formData["customerContactId"];
        this.customerContactId = null;
      }
    } else if (property == "address") {
      if (idList.length > 0) {
        this.formData["location"] = obj[0].address;
        this.formData["address"] = obj[0].address;
        this.listOfAddress.filter((el) => {
          if (el.id == idList[0]) {
            this.formData["addSrNo"] = !!el.addSrNo ? el.addSrNo : null;
          }
        });
      } else {
        delete this.formData["location"];
        delete this.formData["address"];
        delete this.formData["addSrNo"];
      }
    } else if (property == "company") {
      if (idList.length > 0) {
        this.formData["selectedCompany"] = idList[0];
        this.selectedCompany = idList[0];
        let companyName = obj[0].companyName;
        this.getCompanyDetailByName(companyName);
        this.findByAppNameAndCompanyId(this.selectedCompany);
      } else {
        delete this.formData["customerContactId"];
        delete this.formData["userId"];
        this.selectedCompany = null;
        this.userId = null;
        this.selectedUserList = [];
        this.usersByCompany = null;
      }
    } else if (property == "users") {
      if (idList.length > 0) {
        this.formData["userId"] = idList[0];
        this.userId = idList[0];
        this.nameOfPersonReceivingGood = obj[0].username;
      } else {
        delete this.formData["userId"];
        this.userId = null;
        this.nameOfPersonReceivingGood = null;
      }
    } else if (property == "box") {
      this.selectedBox = idList[0];
    } else if (property == "tags") {
      if (idList.length > 0) {
        this.setOfTags = idList;
        this.formData["setOfTags"] = idList;
      } else {
        delete this.formData["setOfTags"];
        this.setOfTags = [];
      }
    } else if (property == "defaultTemplate") {
      this.defaultTemplateId = idList[0];
    } else if (property == "exportAs") {
      this.userTemplateId = idList[0];
    } else if (property == "selectedBroker") {
      if (idList.length > 0) {
        this.formData["brokerId"] = idList[0];
        this.brokerId = idList[0];
      } else {
        delete this.formData["brokerId"];
        this.brokerId = null;
      }
    } else if (property == "refParty") {
      if (idList.length > 0) {
        this.formData["customerReferenceId"] = idList[0];
      } else {
        delete this.formData["customerReferenceId"];
      }
    } else if (property == "selectedCurrency") {
      if (idList.length > 0) {
        this.currencyList.filter((el) => {
          if (el.id == idList[0]) {
            this.formData["currencyRate"] = 0;
            this.formData["spreadRate"] = 0;
          }
        });
        this.formData["currencyMasterId"] = idList[0];
        this.selectedCurrency = obj[0].name;
        // this.formData['currency'] = obj[0].name
        // this.getLatestRateByCurrencyName(obj[0].name)
      } else {
        delete this.formData["currencyMasterId"];
      }
    } else if (property == "businessType") {
      if (idList.length > 0) {
        this.formData["businessType"] = idList[0];
        this.businessType = obj[0].name;
      } else {
        delete this.formData["businessType"];
        this.businessType = null;
      }
    } else if (property == "transportMode") {
      if (idList.length > 0) {
        this.formData["modeOfTransport"] = obj[0].partyName;
        this.modeOfTransport = obj[0].partyName;
      } else {
        delete this.formData["modeOfTransport"];
        this.modeOfTransport = null;
      }
    } else if (property == "salePerson") {
      if (idList.length > 0) {
        this.formData["stockController"] = idList[0];
        this.stockController = idList[0];
      } else {
        delete this.formData["stockController"];
        this.stockController = null;
      }
    }

    // else if (property == "additionalServices") {
    //   console.log(idList, obj, masterId)
    //   if (idList.length > 0) {
    //     this.data.filter(el => {
    //       if (el.id == masterId) {
    //         el.serviceIdList = []
    //         el.serviceIdList = obj
    //         delete el.masterId
    //         el[masterId] = idList;
    //       }
    //     })
    //   } else {
    //     this.data.filter(el => {
    //       if (el.id == masterId) {
    //         el.serviceIdList = []
    //         delete el.masterId;
    //       }
    //     })
    //   }
    //   console.log(this.data)
    // }
    else if (property == "term") {
      if (idList.length > 0) {
        this.termList.filter((el) => {
          if (el.id == idList[0]) {
            this.discountPremium = el.discount;
            this.termName = el.name;
            this.formData["termName"] = this.termName;
          }
        });
      } else {
        delete this.formData["termName"];
        this.termName = null;
        this.discountPremium = 0;
      }
    }
    console.log(
      "selectedValues",
      idList,
      property,
      this.termName,
      this.selectedCustomer,
      this.selectedLab,
      this.selectedCompany,
      this.userId
    );
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 500) {
      this.contentClass = "";
      this.mobileView = true;
    } else if (this.innerWidth > 500) {
      this.contentClass = "leftRightMargin";
      this.mobileView = false;
    }

    this.companyId = await this.authService.getCompanyId();
    let cartActionDetail = await this.configService.getCartActionList();
    this.businessTypeList = await this.configService.businessType();
    this.formFields = cartActionDetail.formFields;
    this.partyTypeList = cartActionDetail.partyTypeList;
    this.multipleChallanList = [
      { id: 1, name: "ORIGINAL", isSelect: true },
      { id: 2, name: "DUPLICATE/TRANSPORTER", isSelect: true },
      { id: 3, name: "TRIPLICATE", isSelect: true },
    ];
    this.getBaseUrl();
    this.actionId = this.navParams.get("actionId");
    this.actionName = this.navParams.get("actionName");
    this.view = this.navParams.get("view");
    this.type = this.navParams.get("type");
    this.withMe = this.navParams.get("withMe");
    this.priceListMasterId = this.navParams.get("priceListMasterId");
    this.displayPriceList = this.navParams.get("displayPriceList");
    if (this.type == "SS") {
      this.columnsForCopyData = [
        { name: "Sr No", prop: "" },
        { name: "Stone Id", prop: "stoneId" },
        { name: "Rate per carat", prop: "price" },
        { name: "Remarks", prop: "remarks" },
      ];
    } else {
      this.columnsForCopyData = [
        { name: "Sr No", prop: "" },
        { name: "Stone Id", prop: "stoneId" },
        { name: "Size", prop: "size" },
        { name: "Rate per carat", prop: "price" },
        { name: "Remarks", prop: "remarks" },
      ];
    }

    await this.getAllLocations();
    await this.getCompanyDetail();
    await this.listAllTags();
    await this.getAllSalesPerson();
    let userData = await this.authService.userData();
    this.salesPersonName = userData.fullName;
    this.getAllTemplates();
    this.getAllDefaultTemplates();
    if (this.view == "tags") {
      this.stoneID = this.navParams.get("stoneID");

      this.columns = [
        { name: "Name", prop: "tagName" },
        { name: "Delete", prop: "id" },
      ];
      await this.getStonesTags();
      await this.listAllTags();
    } else {
      this.stoneID = this.navParams.get("stoneID");
      console.log(this.stoneID, this.stoneID.length);
      if (this.type == "SS" && this.mobileView == false) {
        this.columns = [
          { name: "StoneId", prop: "stoneId" },
          { name: "Color", prop: "color" },
          { name: "Clarity", prop: "clarity" },
          { name: "Shape", prop: "shape" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "RAPAPORT", prop: "rapPrice" },
          { name: "Disc %", prop: "discountPercantage" },
          { name: "Rates per cts", prop: "price" },
          { name: "Memo %", prop: "discountPercantage" },
          { name: "Memo Rates/Cts", prop: "price" },
          { name: "Memo Amount", prop: "offerPrice" },
          { name: "Delete", prop: "id" },
          { name: "Remarks", prop: "stoneRemarks" },
        ];
      } else {
        this.columns = [
          { name: "CSSP", prop: "stoneId" },
          { name: "Shape", prop: "shape" },
          { name: "Color", prop: "color" },
          { name: "Clarity", prop: "clarity" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Request Ct.", prop: "size" },
          { name: "Rates per cts", prop: "price" },
          { name: "Memo Rates/Cts", prop: "price" },
          { name: "Memo Amount", prop: "offerPrice" },
          { name: "Delete", prop: "id" },
          { name: "Remarks", prop: "stoneRemarks" },
        ];
      }
      await this.getAllCartData();
      await this.getDefaultSetting();
      this.getTerm(this.type);
      console.log("*****", this.flagOpenDoc);
    }
  }

  async getAllSalesPerson() {
    let roleName = "";
    if (this.type == "SS") {
      roleName = "Sales Stock Manager - Single";
    } else {
      roleName = "Sales Stock Manager - Mix";
    }

    await this.configService.showLoader();
    let location = await this.configService.getFromLocation();
    let res = await this.httpApiService.getAllSalesPerson(location, roleName);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.salesPersonList = data.data;
    }
  }

  async getBaseUrl() {
    this.baseUrl = await this.configService.getBaseUrl();
  }

  //term master

  async getTerm(transType) {
    let res: any;
    res = await this.httpApiService.findByTransTypeAndOrder(transType);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValueList = data.data;
      let data1 = [];
      if (!!dataValueList && dataValueList.length > 0) {
        dataValueList.forEach((el) => {
          if (el.isActive == true) {
            data1.push(el);
          }
        });
        this.termList = data1;
      }
      console.log("termList ", this.termList);
    }
  }

  //currency detail

  // async getLatestRateByCurrencyName(currency) {
  //   let res: any;
  //   res = await this.httpApiService.getLatestRateByCurrencyName(currency);
  //   let data = res.json()
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     await this.configService.showToast("error", data.error);
  //   } else if (res.status == 400) {
  //     await this.configService.showToast("error", data.error);
  //   } else {
  //     let currencyDetail = data.data;
  //     if (!!currencyDetail) {
  //       this.currencyRate = !!currencyDetail.rate ? currencyDetail.rate : 0;
  //       this.spreadRate = currencyDetail.spread;
  //       this.formData['currencyRate'] = !!currencyDetail.rate ? currencyDetail.rate : 0;
  //       this.formData['spreadRate'] = (!!currencyDetail.spread ? currencyDetail.spread : 0);
  //       this.exgRate = this.currencyRate + (!!currencyDetail.spread ? currencyDetail.spread : 0);
  //     } else {
  //       this.currencyRate = 0
  //       this.spreadRate = 0
  //       this.formData['currencyRate'] = 0
  //       this.formData['spreadRate'] = 0
  //       this.exgRate = 0
  //     }

  //     console.log(this.formData, currencyDetail, this.exgRate)
  //   }
  // }

  //From Company Details

  async getCompanyDetail() {
    let companyName = await this.configService.getFromLocation();
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.companyDetails = data.data;
    }
  }

  // List of addresses

  async getAddressesByPartyId(id) {
    let res: any;
    res = await this.httpApiService.getAddressesByPartyId(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      // this.listOfAddress = data.data;
      this.selectedAddressIdList = [];
      this.listOfAddress = data.data.filter((el) => !!el.isActive);
      console.log(this.listOfAddress);
      if (this.listOfAddress.length == 0) {
        delete this.formData["location"];
        delete this.formData["address"];
        delete this.formData["addSrNo"];
      }
      if (this.listOfAddress.length == 1) {
        this.selectedAddressIdList.push({
          id: this.listOfAddress[0].id,
          address: this.listOfAddress[0].address,
        });
        this.location = this.listOfAddress[0].address;
        this.formData["address"] = this.listOfAddress[0].address;
        this.formData["location"] = this.listOfAddress[0].address;
        this.formData["addSrNo"] = this.listOfAddress[0].addSrNo
          ? this.listOfAddress[0].addSrNo
          : null;
      }
    }
  }

  //To company detail for recut and observation

  async getCompanyDetailByName(companyName) {
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }

  // transporter detail

  async getTransporterDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.transporterDetails = data.data;
      if (this.transporterDetails.length > 0) {
        this.transporterDetails.filter((item) => {
          if (!!item.isDefaultTransport) {
            this.formData["modeOfTransport"] = item.partyName;
            this.modeOfTransport = item.partyName;
            this.selectedTransportIdList.push({
              id: item.id,
              partyName: item.partyName,
            });
          }
        });
      }
    }
  }

  async getBrokerDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        data.data.filter((el) => {
          if (!!el.contactNo) {
            el.brokerWithContact = el.partyName + " - " + el.contactNo;
          } else if (!!el.tel) {
            el.brokerWithContact = el.partyName + " - " + el.tel;
          } else {
            el.brokerWithContact = el.partyName;
          }
        });
        let noneData: any;
        data.data.filter((el, i) => {
          if (el.partyName == "NONE") {
            noneData = el;
            data.data.splice(i, 1);
          }
        });
        data.data.unshift(noneData);
        this.brokerList = data.data;
      }
    }
  }

  // Customer Details

  async getCustomerDetails(id) {
    let res: any;
    res = await this.httpApiService.getCustomerDetails(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
      if (!!this.customerDetails && this.customerDetails.type == "Lab") {
        // this.data.forEach(el => {
        //   el.serviceIdList = []
        // })
        this.showGrid = true;
        await this.getAllAdditionalServicesByLabId(
          this.customerDetails.labReferenceId
        );
        this.getLatestLabRateByCurrencyName(this.customerDetails.id);
      }
      if (!!this.customerDetails) {
        this.disc1 = this.customerDetails.disc1;
        this.disc2 = this.customerDetails.disc2;
        this.selectedTermList = [];
        this.termList.filter((el) => {
          if (el.termDays == this.customerDetails.terms) {
            this.selectedTermList.push({ id: el.id, name: el.name });
            this.termName = el.name;
          }
        });
      }
    }
  }

  //get currency rate for lab

  async getLatestLabRateByCurrencyName(partyMasterId) {
    let res: any;
    res = await this.httpApiService.getLatestLabRateByCurrencyName(
      partyMasterId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let currencyDetail = data.data;
      if (currencyDetail.rate) {
        this.exgRate = currencyDetail.rate;
      }
    }
  }

  // Additional lab services by lab id

  async getAllAdditionalServicesByLabId(labId) {
    let res = await this.httpApiService.getAllAdditionalServicesByLabId(labId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.servicesList = data.data;
      this.data.forEach((el) => {
        !!el[el.id] ? delete el[el.id] : "";
        el.serviceList = null;
        if (this.lab.trim().toLowerCase() == "gia india lab pvt ltd") {
          if (el.color.toLowerCase() == "fancy") {
            let obj = this.servicesList.find((x) => x.service == "CDG");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          } else if (el.stoneCts >= 1) {
            let obj = this.servicesList.find((x) => x.service == "DG");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          } else {
            let obj = this.servicesList.find((x) => x.service == "DOSS");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          }
        } else if (this.lab.trim().toLowerCase() == "hrd lab") {
          if (el.stoneCts >= 1) {
            let obj = this.servicesList.find((x) => x.service == "DG");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          } else {
            let obj = this.servicesList.find((x) => x.service == "DD");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          }
        } else if (
          this.lab.trim().toLowerCase() == "international gemological institute"
        ) {
          if (el.stoneCts >= 1) {
            let obj = this.servicesList.find((x) => x.service == "DG");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          } else {
            let obj = this.servicesList.find((x) => x.service == "DOSS");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          }
        } else if (
          this.lab.trim().toLowerCase() == "ncss (non cert single stones)"
        ) {
          if (el.stoneCts >= 1) {
            let obj = this.servicesList.find((x) => x.service == "DG");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          } else {
            let obj = this.servicesList.find((x) => x.service == "DD");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          }
        } else if (this.lab.trim().toLowerCase() == "k g mumbai lab") {
          let obj = this.servicesList.find(
            (x) => x.service == "Diamond Grading"
          );
          if (obj) {
            el.serviceList = obj.id;
            el[el.id] = [obj.id];
          }
        } else if (
          this.lab.trim().toLowerCase() == "fm" ||
          this.lab.trim().toLowerCase() == "forever mark surat"
        ) {
          if (el.stoneCts < 0.3) {
            let obj = this.servicesList.find((x) => x.service == "I");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          } else if (el.stoneCts >= 0.3 && el.stoneCts <= 0.499) {
            let obj = this.servicesList.find((x) => x.service == "IR");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          } else if (el.stoneCts >= 0.5 && el.stoneCts <= 99.99) {
            let obj = this.servicesList.find((x) => x.service == "IG");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          }
        } else if (this.lab.trim().toLowerCase() == "blfm") {
          let obj = this.servicesList.find((x) => x.service == "BL");
          if (obj) {
            el.serviceList = obj.id;
            el[el.id] = [obj.id];
          }
        } else {
          if (el.color.toLowerCase() == "fancy") {
            let obj = this.servicesList.find((x) => x.service == "CDG");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          } else if (el.stoneCts >= 1) {
            let obj = this.servicesList.find((x) => x.service == "DG");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          } else {
            let obj = this.servicesList.find((x) => x.service == "DOSS");
            if (obj) {
              el.serviceList = obj.id;
              el[el.id] = [obj.id];
            }
          }
        }
      });
    }
  }

  // get all templates for export

  async getAllTemplates() {
    let res: any;
    res = await this.httpApiService.findAllTemplate(this.isDefault);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.exportAs = data.data;
    }
  }

  async getAllDefaultTemplates() {
    let res: any;
    res = await this.httpApiService.findAllTemplate(true);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.defaultTempalateList = data.data;
    }
  }

  //add stones to cart

  async addStone() {
    this.stoneID = this.stoneID.split("\n");
    this.stoneID = this.stoneID.filter(function (el) {
      return el != "";
    });
    if (this.stoneID.length > 0) {
      await this.configService.showLoader();
      let objData = {};
      if (this.type == "SS") {
        objData = {
          setOfStones: this.stoneID,
          transType: this.type,
          type: this.actionName,
          departmentName:
            this.actionName == "Single With Me" ||
              this.actionName == "Mix With Me"
              ? "KAM Sales"
              : "Sales",
          priceListNameMasterId: this.priceListMasterId,
          withMe: this.withMe,
        };
      } else {
        objData = {
          setOfStones: this.stoneID,
          transType: this.type,
          type: this.actionName,
          departmentName:
            this.actionName == "Single With Me" ||
              this.actionName == "Mix With Me"
              ? "KAM Sales"
              : "Sales",
          priceListNameMasterId: this.priceListMasterId,
          withMe: this.withMe,
          csspType: this.csspType,
        };
      }
      console.log(objData);
      let res = await this.httpApiService.addToCart(objData);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.addStone();
        // await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (!!data.data && data.data.length > 0) {
          await this.configService.showToast("error", data.message);
          // let excelData = [];
          // data.data.forEach(el => {
          //   if (el.StoneId) {
          //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
          //   }
          // })
          await this.exportAsExcelService.exportAsExcelFile(
            data.data,
            "Error_Stones"
          );
        } else {
          await this.configService.showToast("error", data.message);
        }
      } else {
        await this.configService.showToast("success", data.message);

        await this.getAllCartData();
        await this.changeView("Detail");
      }
    } else {
      await this.configService.showToast("error", "Provide atleast one stone");
    }
  }

  async addStoneWithPrice() {
    if (this.stoneWithPriceArr.length > 0) {
      await this.configService.showLoader();
      let mapOfStonePrice = {};
      let setOfStoneParameter = [];
      let objData = {};
      if (this.type == "SS") {
        this.stoneWithPriceArr.forEach((el) => {
          setOfStoneParameter.push({
            stoneId: el.stoneId,
            price: el.price,
            remarks: !!el.remarks ? el.remarks : null,
          });
        });
        objData = {
          setOfStoneParameter: setOfStoneParameter,
          transType: this.type,
          priceListNameMasterId: this.priceListMasterId,
          type: this.actionName,
          departmentName:
            this.actionName == "Single With Me" ||
              this.actionName == "Mix With Me"
              ? "KAM Sales"
              : "Sales",
          withMe: this.withMe,
        };
      } else {
        let mapOfStonePriceAndCts = [];
        this.stoneWithPriceArr.forEach((el, i) => {
          mapOfStonePriceAndCts.push({
            stoneId: el.stoneId,
            cts: el.size,
            price: el.price,
            remarks: !!el.remarks ? el.remarks : null,
          });
        });
        objData = {
          mapOfStonePriceAndCts: mapOfStonePriceAndCts,
          transType: this.type,
          priceListNameMasterId: this.priceListMasterId,
          type: this.actionName,
          departmentName:
            this.actionName == "Single With Me" ||
              this.actionName == "Mix With Me"
              ? "KAM Sales"
              : "Sales",
          withMe: this.withMe,
          csspType: this.csspType,
        };
      }
      let res = await this.httpApiService.addOrUpdateWithPrice(objData);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        if (data.error.includes("Lock wait")) {
          await this.configService.showToast(
            "error",
            "There's an issue while adding stones into cart. Please wait we are adding request again"
          );
          this.addStoneWithPrice();
        } else {
          await this.configService.showToast("error", data.error);
        }
        // await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (!!data.data) {
          await this.configService.showToast("error", data.message);
          this.errorMsgList = data.data;
          // let excelData = [];
          // this.errorMsgList.forEach(el => {
          //   if (el.StoneId) {
          //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
          //   }
          // })
          await this.exportAsExcelService.exportAsExcelFile(
            this.errorMsgList,
            "Error_Stones"
          );
        } else {
          await this.configService.showToast("error", data.message);
        }
      } else {
        await this.configService.showToast("success", data.message);
        await this.getAllCartData();
        this.isNext = true;
        await this.changeView("Detail");
      }
    } else {
      await this.configService.showToast("error", "Provide atleast one stone");
    }
  }

  //get cart data

  async getAllCartData() {
    this.deptCheck = false;
    this.userCheck = false;
    let res: any;
    res = await this.httpApiService.getCartStone(this.type, this.actionName);
    this.stoneID = "";
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.data = data.data;
      this.dataForMobile = data.data;
      // console.log(this.data);
      this.count = data.data.length;
      this.stoneQtsWiseList = data.data;
      let cts = 0;
      let flagZeroPrice = false;
      this.totalAmount = 0;
      this.data.forEach((el) => {
        if (!!el.price && !!el.rapPrice) {
          el.requestDisc = el.requestedDiscount
            ? el.requestedDiscount
            : !!el.requestPrice
              ? (((el.rapPrice - el.requestPrice) * 100) / el.rapPrice).toFixed(2)
              : el.discountPercantage;
          el.requestPrice = !!el.requestPrice
            ? el.requestPrice
            : el.price
              ? el.price
              : el.rapPrice;
          el.size = el.stoneCts.toFixed(3);
          if (el.requestPrice < 0 || parseInt(el.requestPrice) == 0) {
            flagZeroPrice = true;
          }
        } else {
          if (!!el.requestedDiscount) {
            el.requestDisc = el.requestedDiscount
              ? el.requestedDiscount
              : !!el.requestPrice
                ? (((el.rapPrice - el.requestPrice) * 100) / el.rapPrice).toFixed(
                  2
                )
                : el.discountPercantage;
          } else {
            el.requestDisc = 0;
          }

          el.requestPrice = el.requestPrice ? el.requestPrice : el.rapPrice;
          // if (!!el.rapPrice) {
          //   let offerDisc = ((el.rapPrice - el.requestPrice) * 100) / el.rapPrice;
          //   el.requestDisc = offerDisc.toFixed(2)
          // }
          el.size = el.stoneCts;
        }
        this.totalAmount += el.stoneCts * el.requestPrice;
        cts += el.stoneCts;
        this.totalCts = cts.toFixed(3);
        if (el.requestPrice < 0 || parseInt(el.requestPrice) == 0) {
          flagZeroPrice = true;
        }
        // el.ID = el.id;
      });
      if (this.data.length == 0) {
        this.totalAmount = 0;
        this.totalCts = null;
      }
      if (flagZeroPrice) {
        this.totalAmount = 0;
      }
      if (this.data.length && this.totalAmount) {
        this.isNext = true;
      }
      // this.items = this.listOfParameter;
    }
  }

  //remove all stone

  async deleteStone(item) {
    let res: any;
    res = await this.httpApiService.deleteStoneInCart(item.cartId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.deptCheck = false;
      this.userCheck = false;
      this.isError = false;
      await this.configService.showToast(
        "success",
        "Stone deleted successfully"
      );
      await this.getAllCartData();
    }
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.actionId
    );
    let resp = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", resp.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", resp.error);
    } else {
      let dataValue = resp.data;
      this.defaultDetail = dataValue.map;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook;
        }
        if (!!dataValue.map.requestVoucherBook) {
          this.requestVoucherBookId = dataValue.map.requestVoucherBook;
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole;
        }
        if (!!dataValue.map.templateDisable) {
          this.disableTemplate = dataValue.map.templateDisable;
        }
        if (!!dataValue.map.cartUpdateDisable) {
          this.cartUpdateDisable = dataValue.map.cartUpdateDisable;
        }
        if (dataValue.map.priceListName) {
          this.priceListMasterId = dataValue.map.priceListName;
        }
        if (!!dataValue.map.defaultPartyMasterId) {
          this.defaultPartyMasterId = dataValue.map.defaultPartyMasterId;
          res = await this.httpApiService.getCustomerDetails(
            this.defaultPartyMasterId
          );
          await this.configService.dismiss();
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            let dataValueList = [data.data];
            if (!!dataValueList && dataValueList.length > 0) {
              dataValueList[0].partyNameWithType = `${dataValueList[0].partyName} ( ${dataValueList[0].type} )`;
              this.getAllCustomers = dataValueList;
              this.formData["partyMasterId"] = this.getAllCustomers[0].id;
              this.selectedCustomer = this.getAllCustomers[0].id;
              this.selectedParty = this.getAllCustomers[0];
              this.getAddressesByPartyId(this.getAllCustomers[0].id);
              this.getAllCustomerContact();
              this.getCustomerDetails(this.getAllCustomers[0].id);
            }
          }
        }
        if (!!dataValue.map.toUser) {
          this.fromUser = dataValue.map.toUser;
        }
        if (!!dataValue.map["Hold Customer"]) {
          let dataJson = JSON.parse(dataValue.map["Hold Customer"]);
          this.holdVoucherBookId = dataJson.voucherBookId;
        }
        if (!!dataValue.map["Unhold Customer"]) {
          let dataJson = JSON.parse(dataValue.map["Unhold Customer"]);
          this.unHoldVoucherBookId = dataJson.voucherBookId;
        }
        if (!!dataValue.map["Block For Sale"]) {
          let dataJson = JSON.parse(dataValue.map["Block For Sale"]);
          this.blockForSaleVoucherBookId = dataJson.voucherBookId;
        }
        if (!!dataValue.map["Unblock For Sale"]) {
          let dataJson = JSON.parse(dataValue.map["Unblock For Sale"]);
          this.unBlockForSaleVoucherBookId = dataJson.voucherBookId;
        }
        let data1 = [];
        for (var prop in dataValue.map) {
          if (this.actionName == "Mix Search") {
            if (
              prop == "Requisition" ||
              prop == "Return" ||
              prop == "Mixing" ||
              prop == "Recut" ||
              prop == "Observation" ||
              prop == "Consignment Confirm" ||
              prop == "Customer Confirm" ||
              prop == "Self Observation" ||
              prop == "Request To KAM" ||
              prop == "Hold Customer" ||
              prop == "Unhold Customer" ||
              prop == "Unblock For Sale" ||
              prop == "Memo Client" ||
              prop == "Sold" ||
              prop == "Memo Service" ||
              prop == "Consignment In Stock" ||
              prop == "Block For Sale"
            ) {
              if (
                prop == "Recut" ||
                prop == "Observation" ||
                prop == "Self Observation" ||
                prop == "Memo Client" ||
                prop == "Memo Service" ||
                prop == "Hold Customer" ||
                prop == "Unhold Customer" ||
                prop == "Unblock For Sale" ||
                prop == "Block For Sale" ||
                prop == "Consignment In Stock" ||
                prop == "Sold" ||
                prop == "Consignment Confirm" ||
                prop == "Request To KAM" ||
                prop == "Customer Confirm" ||
                prop == "Requisition"
              ) {
                let dataJson = JSON.parse(dataValue.map[prop]);
                if (dataJson.enable == true) {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              } else {
                if (dataValue.map[prop] == "true") {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              }
            }
          } else if (this.actionName == "Single With Me") {
            if (
              prop == "Certification" ||
              prop == "Return" ||
              prop == "Recut" ||
              prop == "Observation" ||
              prop == "Consignment Confirm" ||
              prop == "Customer Confirm" ||
              prop == "Memo Client" ||
              prop == "Sold" ||
              prop == "Convert To Mix" ||
              prop == "Memo Service" ||
              prop == "Consignment In Stock" ||
              prop == "Block For Sale"
            ) {
              if (
                prop == "Recut" ||
                prop == "Observation" ||
                prop == "Memo Client" ||
                prop == "Memo Service" ||
                prop == "Sold" ||
                prop == "Convert To Mix" ||
                prop == "Consignment In Stock" ||
                prop == "Consignment Confirm" ||
                prop == "Customer Confirm" ||
                prop == "Certification" ||
                prop == "Block For Sale"
              ) {
                let dataJson = JSON.parse(dataValue.map[prop]);
                if (dataJson.enable == true) {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              } else {
                if (dataValue.map[prop] == "true") {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              }
            }
          } else if (this.actionName == "Mix With Me") {
            if (
              prop == "Return" ||
              prop == "Recut" ||
              prop == "Observation" ||
              prop == "Consignment Confirm" ||
              prop == "Customer Confirm" ||
              prop == "Memo Client" ||
              prop == "Memo Service" ||
              prop == "Sold" ||
              prop == "Consignment In Stock"
            ) {
              if (
                prop == "Recut" ||
                prop == "Observation" ||
                prop == "Memo Client" ||
                prop == "Memo Service" ||
                prop == "Sold" ||
                prop == "Consignment In Stock" ||
                prop == "Consignment Confirm" ||
                prop == "Customer Confirm"
              ) {
                let dataJson = JSON.parse(dataValue.map[prop]);
                if (dataJson.enable == true) {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              } else {
                if (dataValue.map[prop] == "true") {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              }
            }
          } else if (this.actionName == "Single Search") {
            if (
              prop == "Certification" ||
              prop == "Requisition" ||
              prop == "Return" ||
              prop == "Mixing" ||
              prop == "Recut" ||
              prop == "Observation" ||
              prop == "Consignment Confirm" ||
              prop == "Self Observation" ||
              prop == "Request To KAM" ||
              prop == "Hold Customer" ||
              prop == "Unhold Customer" ||
              prop == "Unblock For Sale" ||
              prop == "Customer Confirm" ||
              prop == "Memo Client" ||
              prop == "Sold" ||
              prop == "Convert To Mix" ||
              prop == "Memo Service" ||
              prop == "Consignment In Stock" ||
              prop == "Block For Sale"
            ) {
              if (
                prop == "Recut" ||
                prop == "Observation" ||
                prop == "Self Observation" ||
                prop == "Memo Client" ||
                prop == "Memo Service" ||
                prop == "Hold Customer" ||
                prop == "Unhold Customer" ||
                prop == "Unblock For Sale" ||
                prop == "Request To KAM" ||
                prop == "Consignment In Stock" ||
                prop == "Consignment Confirm" ||
                prop == "Sold" ||
                prop == "Convert To Mix" ||
                prop == "Customer Confirm" ||
                prop == "Certification" ||
                prop == "Requisition" ||
                prop == "Block For Sale"
              ) {
                let dataJson = JSON.parse(dataValue.map[prop]);

                if (dataJson.enable == true) {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              } else {
                if (dataValue.map[prop] == "true") {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              }
            }
          } else if (this.actionName == "SVC Single Search") {
            if (
              prop == "Certification" ||
              prop == "Requisition" ||
              prop == "Return" ||
              prop == "Mixing" ||
              prop == "Recut" ||
              prop == "Observation" ||
              prop == "Consignment Confirm" ||
              prop == "Self Observation" ||
              prop == "Request To KAM" ||
              prop == "Hold Customer" ||
              prop == "Unhold Customer" ||
              prop == "Customer Confirm" ||
              prop == "Memo Client" ||
              prop == "Sold" ||
              prop == "Convert To Mix" ||
              prop == "Memo Service" ||
              prop == "Consignment In Stock"
            ) {
              if (
                prop == "Recut" ||
                prop == "Observation" ||
                prop == "Self Observation" ||
                prop == "Memo Client" ||
                prop == "Memo Service" ||
                prop == "Hold Customer" ||
                prop == "Unhold Customer" ||
                prop == "Request To KAM" ||
                prop == "Consignment In Stock" ||
                prop == "Consignment Confirm" ||
                prop == "Sold" ||
                prop == "Convert To Mix" ||
                prop == "Customer Confirm" ||
                prop == "Certification" ||
                prop == "Requisition"
              ) {
                let dataJson = JSON.parse(dataValue.map[prop]);

                if (dataJson.enable == true) {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              } else {
                if (dataValue.map[prop] == "true") {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              }
            }
          }
        }
        this.actions = data1;
        console.log(this.actions);
      }
    }
  }

  closeDiv() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
  }

  closeErrorMsg() {
    this.error = "";
    this.isError = false;
  }

  // Request Stone

  async requestStone() {
    this.exgRate =
      this.formData["currencyRate"] +
      (!!this.formData["spreadRate"] ? this.formData["spreadRate"] : 0);
    let setOfStoneIds = [];
    this.requestList = [];
    let modifyForDisc1Disc2 = JSON.parse(JSON.stringify(this.data));
    if (!!this.formData["disc1"] || !!this.formData["disc2"]) {
      modifyForDisc1Disc2.forEach((el) => {
        let reqPriceN = Number(el.requestPrice) || 0;
        let disc1N = Number(this.formData["disc1"]);
        let disc2N = Number(this.formData["disc2"]);
        let rapPrice = Number(el.rapPrice) || 0;
        let size = el.size || el.stoneCts;

        if (!!this.formData["disc1"]) {
          reqPriceN = reqPriceN - (reqPriceN * disc1N) / 100;
        }
        if (!!this.formData["disc2"]) {
          reqPriceN = reqPriceN - (reqPriceN * disc2N) / 100;
        }

        el.requestPrice = reqPriceN;
        el.requestAmt = Number((el.requestPrice * size).toFixed(2));
        el.requestDisc = !!rapPrice
          ? Number((((rapPrice - el.requestPrice) * 100) / rapPrice).toFixed(2))
          : !!el.requestDisc
            ? Number(el.requestDisc).toFixed(2)
            : 0;
      });
    }

    modifyForDisc1Disc2.forEach((el, i) => {
      const size = el.size || el.stoneCts;
      const rapPrice = el.rapPrice || null;
      const requestPrice = parseFloat(
        (Number(el.requestPrice) || Number(el.price) || 0).toFixed(2)
      );
      const requestDiscount = rapPrice
        ? parseFloat((((Number(rapPrice) - Number(requestPrice)) * 100) / Number(rapPrice)).toFixed(2))
        : parseFloat((Number(el.requestDisc) || 0).toFixed(2));
      const requestAmt = parseFloat((requestPrice * size).toFixed(2));
      const stoneRemarks = el.stoneRemarks || this.formData["remarks"] || null;
      setOfStoneIds.push(el.id);
      this.requestList.push({
        stoneId: el.id.toString(),
        size: size,
        rapPrice: rapPrice,
        requestPrice: requestPrice,
        requestDiscount: requestDiscount,
        requestAmt: requestAmt,
        listOfServices: this.data[i][el.id],
        srNo: i + 1,
        stoneRemarks: stoneRemarks,
      });
    });
    console.log(this.requestList);
    let validate = await this.validateControls();
    if (!!this.requestFor && this.requestFor == "Certification") {
      this.requestList.filter((el) => {
        if (el && el.listOfServices && el.listOfServices.length > 0) {
          return true;
        } else {
          this.configService.showToast(
            "error",
            "Service not selected for given stones"
          );
          validate = false;
          return false;
        }
      });
    }
    if (validate) {
      if (
        this.selectedCurrency == "Indian Rupee" &&
        !this.formData["currencyRate"]
      ) {
        this.configService.showToast("error", "Please add exchange rate");
        validate = false;
        return false;
      }
      await this.configService.showLoader();
      let res: any;
      let basicObj,
        concatedObj,
        jsonObj = {};
      if (this.formData["shippingDate"]) {
        this.formData["shippingDate"] = moment(
          new Date(this.formData["shippingDate"])
        ).format("YYYY-MM-DD");
      }
      if (this.formData["visitTime"]) {
        this.formData["visitTime"] = moment(
          new Date(this.formData["visitTime"])
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      if (!this.formData["disc1"]) {
        this.formData["disc1"] = 0;
      }
      if (!this.formData["disc2"]) {
        this.formData["disc2"] = 0;
      }
      let dataJson, externalStatus;
      if (this.defaultDetail[this.requestFor]) {
        dataJson = JSON.parse(this.defaultDetail[this.requestFor]);
        externalStatus = dataJson.externalStatus;
      }
      basicObj = {
        voucherDate: moment().format("YYYY-MM-DD"),
        voucherBookId: this.voucherBookId,
        setOfStoneParameter: this.requestList,
        fromRole: this.toRole,
        requestFor: this.requestFor,
        externalStatus: externalStatus ? externalStatus : null,
        exgRate: this.exgRate,
      };
      concatedObj = Object.assign(basicObj, this.formData);
      if (this.requestFor == "Request To KAM") {
        delete concatedObj["fromRole"];
        delete concatedObj["requestFor"];
        let obj = {
          fromUser: this.fromUser,
          requestFor: "Requisition",
          voucherBookId: this.holdVoucherBookId,
          updateStoneStatus: false,
          validateStatus: false,
        };
        jsonObj = Object.assign(concatedObj, obj);
      } else if (this.requestFor == "Hold Customer") {
        let obj = {
          voucherBookId: this.holdVoucherBookId,
        };
        jsonObj = Object.assign(concatedObj, obj);
      } else if (this.requestFor == "Block For Sale") {
        let obj = {
          voucherBookId: this.blockForSaleVoucherBookId,
        };
        jsonObj = Object.assign(concatedObj, obj);
      } else if (this.requestFor == "Unhold Customer") {
        let obj = {
          voucherBookId: this.unHoldVoucherBookId,
        };
        jsonObj = Object.assign(concatedObj, obj);
      } else if (this.requestFor == "Unblock For Sale") {
        let obj = {
          voucherBookId: this.unBlockForSaleVoucherBookId,
        };
        jsonObj = Object.assign(concatedObj, obj);
      } else {
        jsonObj = concatedObj;
      }
      console.log(jsonObj);
      res = await this.httpApiService.stoneRequest(jsonObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        if (data.error.includes("Lock wait")) {
          await this.configService.showToast(
            "error",
            "There's an issue while requesting stones. Please wait we are sending request again"
          );
          this.requestStone();
        } else {
          await this.configService.showToast("error", data.error);
        }
        // await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (!!data.data && data.data.length > 0) {
          await this.configService.showToast("error", data.message);
          // let excelData = [];
          // data.data.forEach(el => {
          //   if (el.StoneId) {
          //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
          //   }
          // })
          await this.exportAsExcelService.exportAsExcelFile(
            data.data,
            "Error_Stones"
          );
        } else {
          await this.configService.showToast("error", data.error);
        }
      } else {
        if (data.data && data.data.bookSerialNumber) {
          this.deliveryChallanNo =
            data.data.shortCode + "-" + data.data.bookSerialNumber;
        }
        await this.configService.showToast("success", data.message);
        // if (this.requestFor == 'Certification' || this.requestFor == 'Memo Service' || this.requestFor == 'Memo Client' || this.requestFor == 'Customer Confirm' || this.requestFor == 'Recut' || this.requestFor == 'Observation' || this.requestFor == 'Consignment Confirm' || this.requestFor == 'Consignment In Stock') {
        //   this.flagOpenDoc = true;
        // } else {
        await this.modalCtrl.dismiss(this.data);
        // }
      }
    }
  }

  async download() {
    await this.httpApiService.downloadPackingList(this.url);
  }

  //change view

  async changeView(view) {
    this.addType = "add";
    this.csspType = "Regular";
    this.termName = null;
    this.disc1 = null;
    this.disc2 = null;
    this.countCopyArr = 0;
    this.stoneID = null;
    this.stoneWithPriceArr = [];
    this.view = view;
    if (this.view != "Form") {
      this.showGrid = false;
      if (!this.defaultPartyMasterId) {
        this.customerContactId = null;
        this.selectedCustomer = null;
      }
      // this.customerContactId = null;
      // this.selectedCustomer = null;
      this.stockController = null;
    }
    if (this.view == "Detail") {
      if (!this.defaultPartyMasterId) {
        this.getAllCompany = null;
        this.getAllCustomers = null;
        this.getAllContacts = null;
        this.selectedParty = null;
      }
      this.msgStatus = false;
      this.usersByCompany = null;
      this.getAllCompany = null;
      this.listOfLabs = null;
      this.selectedRefCustomer = null;
    }
    if (this.view == "actionList") {
      if (!this.defaultPartyMasterId) {
        this.getAllCompany = null;
        this.getAllCustomers = null;
        this.getAllContacts = null;
        this.selectedParty = null;
      }
      this.actionList.forEach((element) => {
        element.isSelected = false;
      });
      this.msgStatus = false;
      this.usersByCompany = null;

      this.listOfLabs = null;

      this.selectedRefCustomer = null;
    }
  }

  async backToCart(view) {
    this.selectedCustomer = null;
    this.view = view;
    this.flagOpenDoc = false;
    if (this.view == "Detail") {
      this.actions.forEach((element) => {
        element.isSelected = false;
      });
      this.msgStatus = false;
    }
    this.data = [];
    this.count = 0;
  }

  //Open action list for party type list

  openActionList(obj) {
    console.log(obj);
    this.actionList = [];
    this.selectedType = obj.name;
    if (obj.actionList.length > 0) {
      obj.actionList.forEach((el) => {
        el.isSelected = false;
        let index = this.actions.findIndex(
          (item) => item.actionName == el.actionName
        );
        if (index > -1) {
          this.actionList.push(el);
        }
      });
      if (this.selectedType == "Internal") {
        let index = obj.actionList.findIndex((el) => el.name == "Tags");
        this.actionList.push(obj.actionList[index]);
      }
      this.changeView("actionList");
    }
  }

  //Open form for different actions

  async selectedParameterValue(stones, index) {
    if (!this.defaultPartyMasterId) {
      this.formData = {};
      this.selectedContactList = [];
    }
    await this.configService.showLoader();
    this.formDetailList = [];
    // let formFields = stones[index].formFields
    if (
      !!stones[index].allowPermission &&
      stones[index].allowPermission.length > 0
    ) {
      stones[index].allowPermission.forEach((el) => {
        let indexOfArr = this.formFields.findIndex((item) => item.id == el);
        if (indexOfArr > -1) {
          if (this.formFields[indexOfArr].keyName == "shippingDate") {
            this.formData["shippingDate"] = moment().format("YYYY-MM-DD");
          }
          if (this.formFields[indexOfArr].keyName == "visitTime") {
            this.formData["visitTime"] = moment().format("YYYY-MM-DD HH:mm:ss");
          }
          this.formDetailList.push(this.formFields[indexOfArr]);
        }
      });
    }
    if (
      this.actionName == "Single With Me" ||
      this.actionName == "Mix With Me"
    ) {
      this.formDetailList.forEach((d) => {
        if (d.name == "Stock controller") {
          d.isRequired = false;
        }
      });
    } else {
    }

    let verified;
    if (stones[index].actionName == "Request To KAM") {
      let deptName = "KAM Sales";
      let index = this.data.findIndex((el) => el.departmentName != deptName);
      if (index > -1) {
        this.deptCheck = true;
      }
      if (this.deptCheck) {
        verified = false;
        await this.configService.showToast(
          "error",
          "You can not do this action on sales department stones"
        );
      } else {
        let userId = this.data[0].userId;
        let index = this.data.findIndex((el) => el.userId != userId);
        if (index > -1) {
          this.userCheck = true;
        }
        if (this.userCheck) {
          verified = false;
          await this.configService.showToast(
            "error",
            "Stones hold by different users."
          );
        } else {
          verified = true;
          this.fromUser = this.data[0].userId;
        }
      }
    } else {
      verified = true;
    }
    console.log(verified);
    if (verified) {
      this.customerContactId = null;
      this.stockController = null;
      this.location = "";
      this.selectedAddressIdList = [];
      this.businessTypeIdList = [];
      this.refPartyIdList = [];
      this.selectedBrokerList = [];
      this.selectedUserList = [];
      this.selectedTagList = [];
      this.selectedTransportIdList = [];
      this.selectedCustomerList = [];
      this.selectedCustomer = null;
      console.log(stones, index, this.voucherBookId);
      let listOfStoneIds = [];
      this.data.forEach((el) => {
        listOfStoneIds.push(el.id);
      });
      let res: any;
      let jsonObj;
      if (stones[index].actionName != "Tags") {
        if (stones[index].actionName == "Hold Customer") {
          jsonObj = {
            voucherBookId: this.holdVoucherBookId,
            setOfStoneIds: listOfStoneIds,
          };
        } else if (stones[index].actionName == "Unhold Customer") {
          jsonObj = {
            voucherBookId: this.unHoldVoucherBookId,
            setOfStoneIds: listOfStoneIds,
          };
        } else if (stones[index].actionName == "Unblock For Sale") {
          jsonObj = {
            voucherBookId: this.unBlockForSaleVoucherBookId,
            setOfStoneIds: listOfStoneIds,
          };
        } else if (stones[index].actionName == "Block For Sale") {
          jsonObj = {
            voucherBookId: this.blockForSaleVoucherBookId,
            setOfStoneIds: listOfStoneIds,
          };
        } else if (stones[index].actionName == "Request To KAM") {
          jsonObj = {
            voucherBookId: this.voucherBookId,
            setOfStoneIds: listOfStoneIds,
            validateStatus: false,
          };
        } else {
          jsonObj = {
            voucherBookId: this.voucherBookId,
            setOfStoneIds: listOfStoneIds,
          };
        }
        console.log(jsonObj);
        if (
          !!this.voucherBookId ||
          !!this.unHoldVoucherBookId ||
          !!this.holdVoucherBookId
        ) {
          res = await this.httpApiService.checkValidationForRequest(jsonObj);
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            if (!!data.data && data.data.length > 0) {
              await this.configService.showToast("error", data.message);
              // let excelData = [];
              // data.data.forEach(el => {
              //   if (el.StoneId) {
              //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
              //   }
              // })
              await this.exportAsExcelService.exportAsExcelFile(
                data.data,
                "Error_Stones"
              );
            } else {
              await this.configService.showToast(
                "error",
                "Error occoured while transferring stones."
              );
            }
          } else {
            this.error = "";
            this.isError = false;
            stones.forEach((element) => {
              element.isSelected = false;
            });
            stones[index].isSelected = true;
            this.requestFor = stones[index].actionName;
            await this.getAllCurrency();
            await this.getAllLocations();
            // await this.findTags(this.pageNumber = 0, this.noOfRecords, '')
            await this.getTransporterDetail("Transport");
            await this.getBrokerDetail("Broker");
            if (
              this.requestFor == "Recut" ||
              this.requestFor == "Observation" ||
              this.requestFor == "Return" ||
              this.requestFor == "Unhold Customer" ||
              this.requestFor == "Unblock For Sale"
            ) {
              //noChange
            } else {
              let dataJson = JSON.parse(this.defaultDetail[this.requestFor]);
              this.buttonTextOnIsDirect = dataJson.isDirect
                ? dataJson.isDirect
                : false;
              if (!!dataJson.partyType && dataJson.partyType.length > 0) {
                // this.customerPartyType = dataJson.partyType
                this.listOfPartyTypeFromDefaults = dataJson.partyType;
                // await this.getAllCustomer(dataJson.partyType);
              }
            }
            console.log(this.defaultDetail);
            for (var prop in this.defaultDetail) {
              if (this.requestFor == "Recut") {
                let dataJson = JSON.parse(this.defaultDetail[prop]);
                this.buttonTextOnIsDirect = dataJson.isDirect
                  ? dataJson.isDirect
                  : false;
                if (prop == "Recut") {
                  if (dataJson.enable == true) {
                    if (!!dataJson.toLocation) {
                      this.selectedCompanyList = [];
                      this.getAllCompany.forEach((item) => {
                        if (item.id == dataJson.toLocation) {
                          this.selectedCompany = dataJson.toLocation;
                          this.formData["selectedCompany"] = item.id;
                          this.selectedContactList.push({
                            id: item.id,
                            companyName: item.companyName,
                          });
                          let companyName = item.companyName;
                          this.getCompanyDetailByName(companyName);
                          this.findByAppNameAndCompanyId(this.selectedCompany);
                        }
                      });
                    }
                  }
                }
              } else if (this.requestFor == "Observation") {
                let dataJson = JSON.parse(this.defaultDetail[prop]);
                this.buttonTextOnIsDirect = dataJson.isDirect
                  ? dataJson.isDirect
                  : false;
                if (prop == "Observation") {
                  if (dataJson.enable == true) {
                    if (!!dataJson.toLocation) {
                      this.selectedCompanyList = [];
                      this.getAllCompany.forEach((item) => {
                        if (item.id == dataJson.toLocation) {
                          this.selectedCompany = dataJson.toLocation;
                          this.formData["selectedCompany"] = item.id;
                          this.selectedContactList.push({
                            id: item.id,
                            companyName: item.companyName,
                          });
                          let companyName = item.companyName;
                          this.getCompanyDetailByName(companyName);
                          this.findByAppNameAndCompanyId(this.selectedCompany);
                        }
                      });
                    }
                  }
                }
              }
            }
            this.changeView("Form");
          }
        } else {
          await this.configService.showToast("error", "VoucherBook not found");
        }
      } else {
        stones[index].isSelected = true;
        this.requestFor = stones[index].actionName;
        this.setOfTags = [];
        this.formData = {};
        this.changeView("Form");
      }
    }
    await this.loadingController.dismiss();
  }

  async getAllCustomer(pageNumber, noOfRecords, searchText) {
    let res: any;
    let json = {
      listOfType: this.listOfPartyTypeFromDefaults,
    };

    if (this.selectedType == "Client" && this.requestFor != "Block For Sale") {
      json["kyc"] = "Y";
    }
    res = await this.httpApiService.findByListOfPartyTypePagination(
      json,
      this.selectedType,
      pageNumber,
      noOfRecords,
      searchText
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      // this.getAllCustomers = data.data.content;
      let dataValueList = data.data.content;
      if (dataValueList.length > 0) {
        for (let i = 0; i < dataValueList.length; i++) {
          dataValueList[
            i
          ].partyNameWithType = `${dataValueList[i].partyName} ( ${dataValueList[i].type} )`;
        }
        this.getAllCustomers = dataValueList;
        if (this.selectedType == "House") {
          if (
            this.requestFor == "Consignment Confirm" ||
            this.requestFor == "Consignment In Stock"
          ) {
            this.getAllCustomers = this.getAllCustomers.filter((el) => {
              if (el.id != 3164) {
                return el;
              }
            });
          } else if (this.requestFor == "Memo Client") {
            this.getAllCustomers = this.getAllCustomers.filter((el) => {
              if (el.id == 3164) {
                return el;
              }
            });
          }
        } else {
        }
      } else {
        this.getAllCustomers = [];
      }
    }
  }

  async getMoreParty(event) {
    this.pageNumber++;
    await this.getAllCustomer(0, this.pageNumber * this.noOfRecords, "");
    event.component.endInfiniteScroll();
  }

  async searchParty(event: {
    component: IonicSelectableComponent;
    text: string;
  }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    if (!this.defaultPartyMasterId) {
      await this.getAllCustomer(this.pageNumber, this.noOfRecords, searchText);
    }
  }

  onChangeParty(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (!!event.value) {
      this.formData["partyMasterId"] = event.value.id;
      this.formData["shippingCost"] = event.value.shippingCost;
      this.selectedCustomer = event.value.id;
      this.lab = event.value.partyName;
      this.getAddressesByPartyId(event.value.id);
      this.getAllCustomerContact();
      this.getCustomerDetails(event.value.id);
    } else {
      delete this.formData["partyMasterId"];
      delete this.formData["shippingCost"];
      this.selectedCustomer = null;
    }
    console.log(this.formData);
  }

  // get location for recut and observation

  async getAllLocations() {
    let res: any;
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllCompany = data.data;
      // this.items = this.listOfParameter;
    }
  }

  async findByAppNameAndCompanyId(company) {
    let res: any;
    res = await this.httpApiService.findByAppNameAndCompanyId(company);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.usersByCompany = data.data;
      // this.items = this.listOfParameter;
    }
  }

  //get customer contact detail

  async getAllCustomerContact() {
    // await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getCustomerContactList(
      this.selectedCustomer
    );
    let data = res.json();
    // await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllContacts = data.data;
      if (this.getAllContacts.length == 1) {
        this.selectedContactList = [];
        this.selectedContactList.push({
          id: this.getAllContacts[0].id,
          name: this.getAllContacts[0].name,
        });
        this.customerContactId = this.getAllContacts[0].id;
        this.formData["customerContactId"] = this.getAllContacts[0].id;
        this.nameOfPersonReceivingGood = this.getAllContacts[0].name;
      } else {
        this.selectedContactList = [];
        delete this.formData["customerContactId"];
      }
      // this.items = this.listOfParameter;
    }
  }

  async findBoxByUser() {
    let res: any;
    res = await this.httpApiService.findBoxByUser();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfBox = data.data;
    }
  }

  async getStonesTags() {
    let res: any;
    res = await this.httpApiService.getStoneTags(this.stoneID);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data);
      this.listOfStoneTags = data.data;
    }
  }

  async addTags() {
    let objData;
    if (!!this.setOfTagId && this.setOfTagId.length > 0) {
      objData = {
        stoneId: this.stoneID,
        setOfTagId: this.setOfTagId,
      };
      this.configService.showLoader();
      let res = await this.httpApiService.addStoneTag(objData);
      let data = res.json();
      this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.setOfTagId = [];
        this.setOfTags = null;
        await this.configService.showToast("success", data.message);
        await this.getStonesTags();
      }
    } else {
      this.configService.showToast("error", "Please select one tag");
    }
  }

  async manageTagsOnBulkStones(actionType) {
    if (this.setOfTags.length > 0) {
      let objData;
      objData = {
        actionType: actionType,
        setOfStoneIds: this.data.map((el) => el.id),
        setOfTagId: this.setOfTags.map((el) => el.id),
      };
      let res = await this.httpApiService.manageStoneTags(objData);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss();
      }
    } else {
      await this.configService.showToast("error", "Select atleast one tag");
    }
  }

  async listAllTags() {
    let res: any;
    res = await this.httpApiService.getAllTags();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfTags = data.data;
      this.setItems();
      console.log("listOfTags", this.listOfTags);
    }
  }

  async getAllCurrency() {
    this.currencyIdList = [];
    let res: any;
    res = await this.httpApiService.getCurrencyList();
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout();
    } else if (data.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (data.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.currencyList = data.data;
      if (this.currencyList.length > 0) {
        this.currencyList.filter((el) => {
          if (el.isDefault) {
            this.formData["currencyRate"] = 0;
            this.formData["spreadRate"] = 0;
            this.currencyIdList = [];
            this.formData["currencyMasterId"] = el.id;
            this.currencyIdList.push({ id: el.id, name: el.name });
            this.defaultCurrency = el.id;
            // this.getLatestRateByCurrencyName(el.name)
          }
        });
      }
    }
  }

  async deleteTag(event) {
    console.log("event", event);
    let res = await this.httpApiService.deleteTag(event.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.getStonesTags();
      await this.configService.showToast("success", "Tag deleted successfully");
    }
  }

  setItems() {
    this.items = this.listOfTags;
  }

  async filterItems(ev: any) {
    // this.selectedTag = "";
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        return item.tagName.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocation = -1;
    } else {
      this.arrowKeyLocation = -1;
      this.setItems();
    }
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedTag = this.items[this.arrowKeyLocation].tagName;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedTag = this.items[this.arrowKeyLocation].tagName;
            break;
          } else {
            break;
          }
        }
    }
  }

  async setTag(tagName) {
    this.manageSelectedTag = tagName;
    this.items = [];
  }

  //export functionality

  async userExport() {
    let ids = [];
    if (this.userTemplateId) {
      this.stoneQtsWiseList.forEach((element) => {
        ids.push(element.id);
      });
      let jsonObj: any;
      let obj = this.exportAs.find((x) => x.id == this.userTemplateId);
      let priceArr = [];
      if (!!obj && !!obj.priceList) {
        this.displayPriceList.forEach((el) => {
          let isExist = obj.priceList.includes(el);
          if (isExist) {
            priceArr.push(el);
          }
        });
      }
      if (priceArr.length == 0) {
        priceArr = this.displayPriceList;
      }
      jsonObj = {
        setOfStoneIds: ids,
        priceListMasterIds: priceArr,
        templateId: this.userTemplateId,
        startDate: moment.utc(new Date()).local().format("YYYYMMDD_hhmm"),
      };
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.exportTemplate(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      console.log(data);
      this.userExportList = [];
      this.userTemplateId = null;
    } else {
      await this.configService.showToast("error", "Please select template");
    }
  }

  async defaultExport() {
    let ids = [];
    if (this.defaultTemplateId) {
      this.stoneQtsWiseList.forEach((element) => {
        ids.push(element.id);
      });
      let obj = this.defaultTempalateList.find(
        (x) => x.id == this.defaultTemplateId
      );
      let priceArr = [];
      if (!!obj && !!obj.priceList) {
        this.displayPriceList.forEach((el) => {
          let isExist = obj.priceList.includes(el);
          if (isExist) {
            priceArr.push(el);
          }
        });
      }
      if (priceArr.length == 0) {
        priceArr = this.displayPriceList;
      }
      let jsonObj: any;
      jsonObj = {
        setOfStoneIds: ids,
        priceListMasterIds: priceArr,
        templateId: this.defaultTemplateId,
        startDate: moment.utc(new Date()).local().format("YYYYMMDD_hhmm"),
      };
      let res: any;
      await this.configService.showLoader();
      res = await this.httpApiService.exportTemplate(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      console.log(data);
      this.defaultExportList = [];
      this.defaultTemplateId = null;
    } else {
      await this.configService.showToast("error", "Please select template");
    }
  }

  //Start Remove From Cart

  async confirmRemoveAll() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to remove all stones from cart ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteFromCart();
          },
        },
      ],
    });

    await alert.present();
  }

  async deleteFromCart() {
    this.isError = false;
    let res: any;
    res = await this.httpApiService.deleteAllFromCart();
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      this.deptCheck = false;
      this.userCheck = false;
      await this.configService.showToast("success", data.message);
      this.getAllCartData();
    }
  }

  //update price in cart

  async updateValue(event, cell, stoneId) {
    let rowIndex = await this.data.findIndex((x) => x.stoneId === stoneId);
    console.log("inline editing rowIndex", event, cell, rowIndex);
    this.data[rowIndex][cell] = parseFloat(event.target.value);

    this.data = [...this.data];
    // this.editing[rowIndex + '-' + cell] = false;
    if (cell == "size") {
      if (
        this.data[rowIndex][cell] > this.data[rowIndex]["stoneCts"] ||
        this.data[rowIndex][cell] == 0 ||
        this.data[rowIndex][cell] == ""
      ) {
        this.data[rowIndex]["errorCt"] = true;
      } else {
        this.data[rowIndex]["errorCt"] = false;
      }
      if (!this.data[rowIndex][cell]) {
        this.data[rowIndex][cell] = 0;
      }
      let flag: boolean;
      this.data.forEach((item) => {
        if (
          parseFloat(item.size) > parseFloat(item.stoneCts) ||
          parseFloat(item.size) == 0 ||
          item.size == ""
        ) {
          flag = true;
          this.isNext = false;
        }
      });
      if (flag == false || flag == undefined) {
        this.isNext = true;
      }
    } else if (cell == "requestPrice") {
      console.log(this.data[rowIndex][cell]);

      let errorPrice = "errorPrice";
      if (!!this.data[rowIndex][cell] && this.data[rowIndex][cell] > 0) {
        this.data[rowIndex][errorPrice] = false;
      } else {
        this.data[rowIndex][errorPrice] = true;
        this.data[rowIndex][cell] = 0;
      }
      if (!!this.data[rowIndex].rapPrice) {
        let offerPer =
          ((this.data[rowIndex].rapPrice -
            this.data[rowIndex]["requestPrice"]) *
            100) /
          this.data[rowIndex].rapPrice;
        this.data[rowIndex]["requestDisc"] = offerPer.toFixed(2);
      } else {
        this.data[rowIndex]["requestDisc"] = null;
      }
      // let flag: boolean;
      // if (flag == false || flag == undefined) {
      //   this.isNext = true;
      // }
      if (
        !!this.data[rowIndex]["requestPrice"] &&
        parseFloat(this.data[rowIndex]["requestPrice"]) > 0
      ) {
        this.isNext = true;
        let jsonObj = {
          requestPrice: this.data[rowIndex]["requestPrice"],
          requestDisc: !!this.data[rowIndex]["requestDisc"]
            ? Number.parseFloat(this.data[rowIndex]["requestDisc"]).toFixed(2)
            : 0,
        };
        let res = await this.httpApiService.updateCartData(
          this.data[rowIndex]["cartId"],
          jsonObj
        );
      } else {
        this.isNext = false;
      }
      await this.data.forEach((item) => {
        if (
          parseFloat(item.requestPrice) == null ||
          parseFloat(item.requestPrice) == undefined ||
          parseFloat(item.requestPrice) <= 0
        ) {
          this.isNext = false;
        }
      });
    } else if (cell == "requestDisc") {
      if (!!this.data[rowIndex].rapPrice) {
        this.data[rowIndex].requestPrice =
          this.data[rowIndex].rapPrice -
          (this.data[rowIndex].rapPrice * this.data[rowIndex].requestDisc) /
          100;
      } else {
        this.data[rowIndex].requestPrice =
          (this.data[rowIndex].price * this.data[rowIndex].requestDisc) / 100 -
          (this.data[rowIndex].price * this.data[rowIndex].requestDisc) / 100;
      }
      this.isNext = true;
      let jsonObj = {
        requestPrice: this.data[rowIndex]["requestPrice"],
        requestDisc: this.data[rowIndex]["requestDisc"],
      };
      console.log(jsonObj);
      let res = await this.httpApiService.updateCartData(
        this.data[rowIndex]["cartId"],
        jsonObj
      );
    }
    let cts = 0;
    this.totalAmount = 0;
    this.data.forEach((el) => {
      this.totalAmount += (!!el.size ? el.size : el.stoneCts) * el.requestPrice;
      cts += !!el.size ? parseFloat(el.size) : el.stoneCts;
      this.totalCts = cts.toFixed(3);
    });
    console.log(this.data);
    this.data.forEach((item) => {
      if (
        parseFloat(item.requestPrice) == null ||
        parseFloat(item.requestPrice) == undefined ||
        parseFloat(item.requestPrice) <= 0
      ) {
        this.isNext = false;
      }
    });
  }

  //stoneId

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split("/", 3).join("/").length;
      return stoneId
        .split(stoneId.substring(index + 1, stoneId.length))
        .join(
          stoneId
            .substring(index + 1, stoneId.length)
            .slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))
        );
    } else {
      return stoneId;
    }
  }

  async printPreveiw() {
    await this.configService.print();
  }

  // Return with request

  async returnWithRequest() {
    this.exgRate =
      this.formData["currencyRate"] +
      (!!this.formData["spreadRate"] ? this.formData["spreadRate"] : 0);
    // this.flagOpenDoc = true;
    // return
    let setOfStoneIds = [];
    this.requestList = [];
    let modifyForDisc1Disc2 = JSON.parse(JSON.stringify(this.data));
    if (!!this.formData["disc1"] || !!this.formData["disc2"]) {
      modifyForDisc1Disc2.forEach((el) => {
        if (!!this.formData["disc1"]) {
          el.requestPrice = Number(
            (
              el.requestPrice -
              (el.requestPrice * Number(this.formData["disc1"])) / 100
            ).toFixed(2)
          );
          el.requestAmt = Number(
            (el.requestPrice * (!!el.size ? el.size : el.stoneCts)).toFixed(2)
          );
          el.requestDisc = !!el.rapPrice
            ? Number(
              (((el.rapPrice - el.requestPrice) * 100) / el.rapPrice).toFixed(
                2
              )
            )
            : !!el.requestDisc
              ? Number(el.requestDisc).toFixed(2)
              : 0;
        }
        if (!!this.formData["disc2"]) {
          console.log(el.requestPrice);
          el.requestPrice = Number(
            (
              el.requestPrice -
              (el.requestPrice * Number(this.formData["disc2"])) / 100
            ).toFixed(2)
          );
          el.requestAmt = Number(
            (el.requestPrice * (!!el.size ? el.size : el.stoneCts)).toFixed(2)
          );
          el.requestDisc = !!el.rapPrice
            ? Number(
              (((el.rapPrice - el.requestPrice) * 100) / el.rapPrice).toFixed(
                2
              )
            )
            : !!el.requestDisc
              ? Number(el.requestDisc).toFixed(2)
              : 0;
        }
      });
    }
    modifyForDisc1Disc2.forEach((el, i) => {
      setOfStoneIds.push(el.id);
      this.requestList.push({
        stoneId: el.id.toString(),
        size: el.size ? el.size : el.stoneCts,
        requestPrice: !!el.requestPrice
          ? Number(el.requestPrice).toFixed(2)
          : !!el.price
            ? Number(el.price).toFixed(2)
            : 0,
        requestDiscount: !!el.requestDisc
          ? Number(el.requestDisc).toFixed(2)
          : 0,
        requestAmt: !!el.requestPrice
          ? Number(
            el.requestPrice * (!!el.size ? el.size : el.stoneCts)
          ).toFixed(2)
          : !!el.price
            ? Number(el.price * (!!el.size ? el.size : el.stoneCts)).toFixed(2)
            : "0",
        listOfServices: this.data[i][el.id],
        srNo: i + 1,
        stoneRemarks: !!el.stoneRemarks
          ? el.stoneRemarks
          : !!this.formData["remarks"]
            ? this.formData["remarks"]
            : null,
      });
    });

    let stoneRequestData = {};
    console.log("StoneList", this.requestList);
    let isReady = true;
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let basicObj,
        concatedObj,
        jsonObj = {};
      if (this.formData["shippingDate"]) {
        this.formData["shippingDate"] = moment(
          new Date(this.formData["shippingDate"])
        ).format("YYYY-MM-DD");
      }
      if (this.formData["visitTime"]) {
        this.formData["visitTime"] = moment(
          new Date(this.formData["visitTime"])
        ).format("YYYY-MM-DD HH:mm:ss");
      }
      let dataJson, isDirect, externalStatus;
      if (this.defaultDetail[this.requestFor]) {
        dataJson = JSON.parse(this.defaultDetail[this.requestFor]);
        isDirect = dataJson.isDirect;
        externalStatus = dataJson.externalStatus;
      }
      basicObj = {
        voucherDate: moment().format("YYYY-MM-DD"),
        voucherBookId: this.voucherBookId,
        setOfStoneParameter: this.requestList,
        fromRole: this.toRole,
        isDirect: isDirect ? isDirect : false,
        externalStatus: externalStatus ? externalStatus : null,
        requestFor: this.requestFor,
        exgRate: this.exgRate,
      };
      concatedObj = Object.assign(basicObj, this.formData);
      console.log(concatedObj);
      if (this.requestFor == "Return") {
        isReady = true;
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          setOfStoneIds: setOfStoneIds,
          voucherBookId: this.voucherBookId,
          toRole: this.toRole,
          removeFromCart: true,
          isKamTransferred: true,
          companyId: this.companyId,
        };
      } else {
        if (!!this.requestVoucherBookId) {
          isReady = true;
          if (this.requestFor == "Certification") {
            stoneRequestData = {
              voucherBookId: this.requestVoucherBookId,
              requestFor: this.requestFor,
            };
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: Object.assign(basicObj, stoneRequestData),
              companyId: this.companyId,
            };
          } else if (this.requestFor == "Recut") {
            stoneRequestData = {
              voucherBookId: this.requestVoucherBookId,
              requestFor: this.requestFor,
              userId: this.userId,
            };
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: Object.assign(basicObj, stoneRequestData),
              companyId: this.companyId,
            };
          } else if (this.requestFor == "Observation") {
            stoneRequestData = {
              voucherBookId: this.requestVoucherBookId,
              requestFor: this.requestFor,
              userId: this.userId,
            };
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: Object.assign(basicObj, stoneRequestData),
              companyId: this.companyId,
            };
          } else if (this.requestFor == "Consignment Confirm") {
            stoneRequestData = {
              voucherBookId: this.requestVoucherBookId,
              requestFor: this.requestFor,
            };
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: Object.assign(basicObj, stoneRequestData),
              companyId: this.companyId,
            };
          } else if (this.requestFor == "Customer Confirm") {
            stoneRequestData = {
              voucherBookId: this.requestVoucherBookId,
              requestFor: this.requestFor,
            };
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: Object.assign(basicObj, stoneRequestData),
              companyId: this.companyId,
            };
          } else if (this.requestFor == "Memo Client") {
            stoneRequestData = {
              voucherBookId: this.requestVoucherBookId,
              requestFor: this.requestFor,
            };
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: Object.assign(basicObj, stoneRequestData),
              companyId: this.companyId,
            };
          } else if (this.requestFor == "Sold") {
            stoneRequestData = {
              voucherBookId: this.requestVoucherBookId,
              requestFor: this.requestFor,
            };
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: Object.assign(basicObj, stoneRequestData),
              companyId: this.companyId,
            };
          } else if (this.requestFor == "Consignment In Stock") {
            stoneRequestData = {
              voucherBookId: this.requestVoucherBookId,
              requestFor: this.requestFor,
            };
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: Object.assign(basicObj, stoneRequestData),
              companyId: this.companyId,
            };
          } else if (this.requestFor == "Memo Service") {
            stoneRequestData = {
              voucherBookId: this.requestVoucherBookId,
              requestFor: this.requestFor,
            };
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: Object.assign(basicObj, stoneRequestData),
              companyId: this.companyId,
            };
          } else if (this.requestFor == "Convert To Mix") {
            stoneRequestData = {
              voucherBookId: this.requestVoucherBookId,
              requestFor: this.requestFor,
            };
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: Object.assign(basicObj, stoneRequestData),
              companyId: this.companyId,
            };
          } else if (this.requestFor == "Block For Sale") {
            stoneRequestData = {
              voucherBookId: this.requestVoucherBookId,
              requestFor: this.requestFor,
            };
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: Object.assign(basicObj, stoneRequestData),
              companyId: this.companyId,
            };
          }
        } else {
          isReady = false;
          await this.configService.showToast("error", "Voucher book not found");
          await this.configService.dismiss();
        }
      }
      console.log(jsonObj, isReady);
      if (isReady == true) {
        res = await this.httpApiService.stoneIssue(jsonObj);
        await this.configService.dismiss();
        let data = res.json();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          if (data.error.includes("Lock wait")) {
            await this.configService.showToast(
              "error",
              "There's an issue while requesting stones. Please wait we are sending request again"
            );
            this.returnWithRequest();
          } else {
            await this.configService.showToast("error", data.error);
          }
          // await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          await this.configService.showToast("error", data.error);
          if (data.data.length > 0) {
            await this.exportAsExcelService.exportAsExcelFile(
              data.data,
              "Error_Stones"
            );
          }
        } else {
          console.log(data.data);
          if (data.data && data.data.bookSerialNumber) {
            this.deliveryChallanNo =
              data.data.shortCode + "-" + data.data.bookSerialNumber;
          }
          await this.configService.showToast("success", data.message);
          // if (this.requestFor == 'Certification' || this.requestFor == 'Memo Service' || this.requestFor == 'Memo Client' || this.requestFor == 'Customer Confirm' || this.requestFor == 'Recut' || this.requestFor == 'Observation' || this.requestFor == 'Consignment Confirm' || this.requestFor == 'Consignment In Stock') {
          //   this.flagOpenDoc = true;
          // } else {
          await this.modalCtrl.dismiss(this.data);
          // }
        }
      }
    }
  }

  getArray() {
    let records = this.copyValue.split("\n");
    this.stoneWithPriceArr = [];
    if (this.type == "SS") {
      for (let i = 1; i < records.length; i++) {
        if (records[i]) {
          let rowArray = records[i].trim().split("\t");
          this.stoneWithPriceArr.push({
            stoneId: rowArray[0],
            price: rowArray[1],
            remarks: rowArray[2],
          });
        }
      }
    } else {
      for (let i = 1; i < records.length; i++) {
        if (records[i]) {
          let rowArray = records[i].trim().split("\t");
          this.stoneWithPriceArr.push({
            stoneId: rowArray[0],
            size: rowArray[1],
            price: rowArray[2],
            remarks: rowArray[3],
          });
        }
      }
      this.countCopyArr = this.stoneWithPriceArr.length;
    }
    this.countCopyArr = this.stoneWithPriceArr.length;
    console.log(this.stoneWithPriceArr);
  }

  changeType(type) {
    this.addType = type;
    this.stoneID = null;
    this.stoneWithPriceArr = [];
  }

  changeCsspType(type) {
    this.csspType = type;
    this.stoneID = null;
    this.stoneWithPriceArr = [];
  }

  async validateControls() {
    let error: Boolean = false;
    console.log(this.formData);
    for (let i = 0; i < this.formDetailList.length; i++) {
      if (this.formDetailList[i].isRequired) {
        if (
          !this.formData[this.formDetailList[i].keyName] &&
          this.formDetailList[i].keyName != "spreadRate" &&
          this.formDetailList[i].keyName != "currencyRate"
        ) {
          if (
            this.formDetailList[i].keyName == "shippingCost" &&
            this.formData[this.formDetailList[i].keyName] == 0
          ) {
          } else {
            await this.configService.showToast(
              "error",
              this.formDetailList[i].name + " is required."
            );
            error = true;
            break;
          }
        }
      }
    }
    if (error) {
      return false;
    } else {
      return true;
    }
  }

  async valueChange(event: {
    component: IonicSelectableComponent;
    value: any;
  }) {
    //
    // await this.intializeSearchPara();
    // this.selectedSavedSearch = event.value.id;
    // this.SavedSearchSelect();
  }

  onAddItem(event: { component: IonicSelectableComponent }) {
    // Clean form.
    this.tagName = "";

    // Show form.
    event.component.showAddItemTemplate();
  }

  async getMoreTags(event) {
    this.pageNumber++;
    await this.findTags(0, this.pageNumber * this.noOfRecords, "");
    event.component.endInfiniteScroll();
  }

  async searchItem(event: {
    component: IonicSelectableComponent;
    text: string;
  }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    await this.findTags(this.pageNumber, this.noOfRecords, searchText);
  }

  async createNewTag() {
    let res: any;
    let arr = [];
    arr.push(this.tagName);
    if (arr.length > 0) {
      let jsonObj = {
        setOfTags: arr,
      };
      await this.configService.showLoader();
      res = await this.httpApiService.saveTags(jsonObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        // Show list.
        this.selectableComponent.hideAddItemTemplate();
        this.findTags((this.pageNumber = 0), this.noOfRecords, "");
      }
    } else {
      await this.configService.showToast("error", "Please add tag");
    }
  }

  async findTags(pageNumber, noOfRecords, searchText) {
    let res: any;
    res = await this.httpApiService.findTagsBySearch(
      pageNumber,
      noOfRecords,
      searchText
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data);
      this.tagList = data.data.content;
    }
  }

  onChange(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (event.value.length > 0) {
      this.formData["setOfTags"] = event.value.map((el) => el.id);
    } else {
      delete this.formData["setOfTags"];
    }
  }

  //SGI mapping issue for  Recut

  async recutRequestFromKG() {
    await this.configService.showLoader();
    let dataJson = JSON.parse(this.defaultDetail["Recut"]);
    console.log(dataJson);
    let res: any;
    let jsonObj = {};
    let stoneRequestData = {
      voucherBookId: this.requestVoucherBookId,
      requestFor: "Recut",
      userId: 51,
    };
    jsonObj = {
      stoneRequestData: stoneRequestData,
      voucherDate: moment().format("YYYY-MM-DD"),
      voucherBookId: this.voucherBookId,
      toRole: this.toRole,
      jangadNo: this.jangadNo,
      jangadDate: moment(new Date(this.jangadDate)).format("DD-MMM-YYYY"),
    };
    if (this.csspType) {
      jsonObj["csspType"] = this.csspType;
    }
    console.log(jsonObj);
    res = await this.httpApiService.recutRequestFromKG(jsonObj);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      if (!!data.data && data.data.length > 0) {
        await this.configService.showToast("error", data.message);
        // let excelData = [];
        // data.data.forEach(el => {
        //   if (el.StoneId) {
        //     el.StoneId.map(item => excelData.push({ Name: item, message: el.message }))
        //   }
        // })
        await this.exportAsExcelService.exportAsExcelFile(
          data.data,
          "Error_Stones"
        );
      } else {
        await this.configService.showToast("error", data.message);
      }
    } else {
      this.jangadNo = "";
      await this.configService.showToast("success", data.message);
    }
  }

  // ion selectable for  reference party name

  // customer reference party

  async getCustomerRefParty(pageNumber, noOfRecords, searchText) {
    let res: any;
    res = await this.httpApiService.findRefCustomerPagination(
      pageNumber,
      noOfRecords,
      searchText
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.refPartyList = data.data.content;
    }
  }

  async searchRefCustomer(event: {
    component: IonicSelectableComponent;
    text: string;
  }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    await this.getCustomerRefParty(this.pageNumber, 50, searchText);
  }

  onChangeRefCustomer(event: {
    component: IonicSelectableComponent;
    value: any;
  }) {
    console.log("onChangeRefCustomer:", event.value);
    if (!!event.value) {
      this.formData["customerReferenceId"] = event.value.id;
    } else {
      delete this.formData["customerReferenceId"];
    }
  }

  onAddRefName(event: { component: IonicSelectableComponent }) {
    // Clean form.
    this.refCustomerName = "";
    this.refDescription = "";
    // Show form.
    event.component.showAddItemTemplate();
  }

  async createRefCustomer() {
    let res: any;
    if (!!this.refCustomerName) {
      let jsonObj = {
        name: this.refCustomerName,
        description: this.refDescription,
      };
      await this.configService.showLoader();
      res = await this.httpApiService.createRefCustomer(jsonObj);
      await this.configService.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.selectableRefPersonComponent.hideAddItemTemplate();
        this.getCustomerRefParty((this.pageNumber = 0), this.noOfRecords, "");
      }
    } else {
      await this.configService.showToast("error", "Please add name");
    }
  }

  // list of tags

  onTagChange(event: { component: IonicSelectableComponent; value: any }) {
    if (event.value.length > 0) {
      this.setOfTagId = event.value.map((el) => el.id);
    } else {
      this.setOfTagId = [];
    }
  }

  onRowUpdated(ev) {
    let index = this.data.findIndex((x) => x.id == ev.data.id);
    if (index > -1) {
      delete this.data[index][ev.data.id];
      console.log(ev.data.id);
      this.data[index][ev.data.id] = [ev.data.serviceList];
    }
  }

  async validateExSpread(ev) {
    if (ev && ev.detail.value < 0) {
      await this.configService.showToast("error", "Please add proper value");
    }
  }
  async validateDuration(ev) {
    if (ev && ev.detail.value <= 0) {
      await this.configService.showToast("error", "Please add proper value");
    }
  }

  async validateExRate(ev) {
    if (ev && ev.detail.value < 0) {
      this.formData["duration"] = null;
      await this.configService.showToast("error", "Please add proper value");
    }
  }

  async exportData() {
    await this.configService.showLoader();
    let fileName = "export " + moment().format("DD/MM/YYYY");

    let stoneData = [];
    for (let i = 0; i < this.data.length; i++) {
      let serviceListId = this.data[i].serviceList;
      let serviceName = this.servicesList.find(
        (x) => x.id == serviceListId
      ).service;
      let obj = {
        stoneId: this.data[i].stoneId,
        serviceName: serviceName,
      };
      stoneData.push(obj);
    }

    await this.exportAsExcelService.exportAsExcelFile(stoneData, fileName);

    await this.loadingController.dismiss();
  }

  async updateData(event) {
    readXlsxFile(event.target.files[0]).then((rows) => {
      for (let i = 0; i < rows.length; i++) {
        let el = rows[i];
        if (!!el && i > 0) {
          let stoneId = el[0];
          let serviceName = el[1];
          console.log(stoneId + "--stoneid" + serviceName);
          let obj;
          if (serviceName) {
            obj = this.servicesList.find(
              (x) => x.service.toLowerCase() == serviceName.toLowerCase()
            );
          }
          let serviceList;
          if (!!obj) {
            serviceList = [obj.id];
          }

          this.data = this.data.map((x, index) => {
            let id = x.id;
            console.log(id);
            let stId = x.stoneId;
            if (!!serviceList) {
              if (stId == stoneId) {
                x["serviceList"] = serviceList;
                this.data[index][id] = serviceList;
              }
            }
            return x;
          });
        }
      }
      this.myInputVariable.nativeElement.value = "";
    });
  }
  onFocusedCellChanged(e: any) {
    let cts = e.data.stoneCts;
    let rapPrice = e.data.rapPrice;
    if (e.columnIndex === 3) {
      let amountToBeDeducted = cts * e.data.requestPrice;
      e.data.requestPrice = (rapPrice - (rapPrice * e.value) / 100).toFixed(2);
      let amount = cts * e.data.requestPrice;
      this.totalAmount = this.totalAmount + amount - amountToBeDeducted;
      e.data.requestDisc = e.value;
    } else if (e.columnIndex === 4) {
      let amountToBeDeducted = cts * e.data.requestPrice;
      e.data.requestDisc = (((rapPrice - e.value) / rapPrice) * 100).toFixed(2);
      let amount = cts * e.value;
      this.totalAmount = this.totalAmount + amount - amountToBeDeducted;
      e.data.requestPrice = e.value;
    }
  }
}
