import { Component, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: 'app-manage-cssp-transfer-mapping',
  templateUrl: './manage-cssp-transfer-mapping.page.html',
  styleUrls: ['./manage-cssp-transfer-mapping.page.scss'],
})
export class ManageCsspTransferMappingPage implements OnInit {

  public name: any;
  public flagAddUpdate: any;
  public item: any;
  public id: any;
  public fromCts: any = Number;
  public toCts: any = Number;
  public typeList = [];
  public type: any;
  public shapeList = [];
  public clarityList = [];
  public colorList = [];
  public assortSizeList = [];
  public salesSizeList = [];
  public aShapeIdList = [];
  public aClarityIdList = [];
  public aColorIdList = [];
  public aSizeIdList = [];
  public sShapeIdList = [];
  public sClarityIdList = [];
  public sColorIdList = [];
  public sSizeIdList = [];
  public aShapeId: any;
  public aSizeId: any;
  public aColorId: any;
  public aClarityId: any;
  public sShapeId: any;
  public sSizeId: any;
  public sColorId: any;
  public sClarityId: any;
  public clearFile: any;
  public getHistoryData = [];
  public getHistoryDataSource: any = {};
  public pageNumber = 0;
  public noOfRecords = 10;
  public pageSize = 10;
  public count: Number = 0;
  public selectedFile: any;
  public columns = []
  public page = 'uploadCsspTransfer';
  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) {

  }

  async ngOnInit() {
    let data = this.navParams.get('item');
    if (data != 0) {
      this.item = data.row;
      console.log(this.item)
      this.id = this.item.id
    }
    this.flagAddUpdate = this.navParams.get('flag');
    console.log(this.flagAddUpdate)
    if (this.flagAddUpdate == 'update' || this.flagAddUpdate == 'add') {
      await this.findParametersForMixSizeMaster();
    }
    if (this.flagAddUpdate == 'update') {
      this.aShapeId = this.item.fromShape;
      this.sShapeId = this.item.toShape;
      await this.findSizeByShape(this.aShapeId, 'Assort')
      await this.findSizeByShape(this.sShapeId, 'Sale Bulk')
      this.aShapeIdList = [];
      this.aSizeIdList = [];
      this.aColorIdList = [];
      this.aClarityIdList = [];
      this.sShapeIdList = [];
      this.sSizeIdList = [];
      this.sColorIdList = [];
      this.sClarityIdList = [];
      this.aSizeId = this.item.fromSize;
      this.aColorId = this.item.fromColor;
      this.aClarityId = this.item.fromClarity;
      this.sSizeId = this.item.toSize;
      this.sColorId = this.item.toColor;
      this.sClarityId = this.item.toClarity;
      this.aShapeIdList.push({ id: this.item.fromShape, name: this.item.fromShapeCode })
      this.aSizeIdList.push({ id: this.item.fromSize, name: this.item.fromSizeName })
      this.aColorIdList.push({ id: this.item.fromColor, name: this.item.fromColorCode })
      this.aClarityIdList.push({ id: this.item.fromClarity, name: this.item.fromClarityCode })
      this.sShapeIdList.push({ id: this.item.toShape, name: this.item.toShapeCode })
      this.sSizeIdList.push({ id: this.item.toSize, name: this.item.toSizeName })
      this.sColorIdList.push({ id: this.item.toColor, name: this.item.toColorCode })
      this.sClarityIdList.push({ id: this.item.toClarity, name: this.item.toClarityCode })
      console.log(this.aShapeIdList)
    }
    if (this.flagAddUpdate == 'upload') {
      this.columns = [
        { name: "File Name", prop: "fileName" },
        { name: "Status", prop: "status" },
        { name: "By", prop: "username" },
        { name: "Date & Time", prop: "createdDate" }
      ];
      await this.getAllLabExpenseFileUploadHistory(this.pageNumber, this.noOfRecords)
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'assortShape') {
      if (idList.length > 0) {
        this.aSizeId = null;
        this.aSizeIdList = []
        this.aShapeId = idList[0];
        this.findSizeByShape(this.aShapeId, 'Assort')
      } else {
        this.aSizeId = null;
        this.aSizeIdList = []
        this.aShapeId = null;
      }
    } else if (property == 'assortSize') {
      if (idList.length > 0) {
        this.aSizeId = idList[0];
      } else {
        this.aSizeId = null;
      }
    } else if (property == 'assortColor') {
      if (idList.length > 0) {
        this.aColorId = idList[0];
      } else {
        this.aColorId = null;
      }
    } else if (property == 'assortClarity') {
      if (idList.length > 0) {
        this.aClarityId = idList[0];
      } else {
        this.aClarityId = null;
      }
    } else if (property == 'salesShape') {
      if (idList.length > 0) {
        this.sSizeId = null;
        this.sSizeIdList = []
        this.sShapeId = idList[0];
        this.findSizeByShape(this.sShapeId, 'Sale Bulk')
      } else {
        this.sSizeId = null;
        this.sSizeIdList = []
        this.sShapeId = null;
      }
    } else if (property == 'salesSize') {
      if (idList.length > 0) {
        this.sSizeId = idList[0];
      } else {
        this.sSizeId = null;
      }
    } else if (property == 'salesColor') {
      if (idList.length > 0) {
        this.sColorId = idList[0];
      } else {
        this.sColorId = null;
      }
    } else if (property == 'salesClarity') {
      if (idList.length > 0) {
        this.sClarityId = idList[0];
      } else {
        this.sClarityId = null;
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async findSizeByShape(shapeId, type) {
    let res: any;
    res = await this.httpApiService.findSizeByShape(shapeId, type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (type == 'Assort') {
        this.assortSizeList = data.data;
      } else {
        this.salesSizeList = data.data;
      }
    }
  }

  async findParametersForMixSizeMaster() {
    let res: any;
    res = await this.httpApiService.findParametersForMixSizeMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(dataValue)
      if (dataValue.shapeList) {
        let data1 = []
        dataValue.shapeList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.shapeList = data1;
        let data2 = []
        dataValue.colorList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data2.push(el);
          }
        })
        this.colorList = data2;
        let data3 = []
        dataValue.clarityList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data3.push(el);
          }
        })
        this.clarityList = data3;
      }
    }
  }

  async addTransferMapping() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        "fromShape": this.aShapeId,
        "fromColor": this.aColorId,
        "fromClarity": this.aClarityId,
        "fromSize": this.aSizeId,
        "toShape": this.sShapeId,
        "toColor": this.sColorId,
        "toClarity": this.sClarityId,
        "toSize": this.sSizeId
      }
      console.log(jsonObj)
      res = await this.httpApiService.addCsspTransferMapping(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async updateTransferMapping() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        "fromShape": this.aShapeId,
        "fromColor": this.aColorId,
        "fromClarity": this.aClarityId,
        "fromSize": this.aSizeId,
        "toShape": this.sShapeId,
        "toColor": this.sColorId,
        "toClarity": this.sClarityId,
        "toSize": this.sSizeId
      }
      console.log(jsonObj)
      res = await this.httpApiService.updateCsspTransferMapping(jsonObj, this.id);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async validateControls() {
    if (!!this.aSizeId && !!this.aShapeId && !!this.aColorId && !!this.aClarityId
      && this.sSizeId && !!this.sShapeId && !!this.sColorId && this.sClarityId
    ) {
      return true;
    } else {
      await this.configService.showToast('error', 'Please insert the details')
      return false;
    }
  }


  //file upload

  async getAllLabExpenseFileUploadHistory(pageNumber, noOfRecords) {
    // await this.configService.showLoader()
    // let res: any;
    // res = await this.httpApiService.getUploadHistory('Cssp Transfer Mapping', pageNumber, noOfRecords)
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   await this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast('error', data.error)
    // } else if (res.status == 400) {
    //   await this.configService.showToast('error', data.error)
    // } else {
    //   this.getHistoryData = data.data.content;
    //   this.count = data.data.totalElements;
    // }
    this.getHistoryDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100,500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getUploadHistory('Cssp Transfer Mapping', this.pageNumber, this.noOfRecords)
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.getHistoryData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.getHistoryData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;;
    await this.getAllLabExpenseFileUploadHistory(currentPage.offset, this.noOfRecords)

  }

  handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {

    }
  }

  fileObject(event) {
    this.clearFile = event.target.value
    this.selectedFile = <File>event.target.files[0];
    console.log(this.selectedFile);
  }

  async uploadTransferMapping() {
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.uploadCsspTransferMapping(formData);
      console.log(res.json());
      await this.configService.dismiss();
      let uploadData = res.json();
      if (uploadData.status == 400) {
        await this.configService.showToast("error", uploadData.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", uploadData.error);
      } else {
        await this.configService.showToast("success", uploadData.message);
        this.selectedFile = null;
        this.clearFile = null;
        await this.getAllLabExpenseFileUploadHistory(this.pageNumber, this.noOfRecords);
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  async transferCsspMappingDownloadFile(file) {
    console.log(file)
    let res: any;
    res = await this.httpApiService.downloadFileByType(file.id, 'Cssp Transfer Mapping');
  }
}
