import { Component, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { DragulaService } from "ng2-dragula";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-manage-term-master',
  templateUrl: './manage-term-master.page.html',
  styleUrls: ['./manage-term-master.page.scss'],
})
export class ManageTermMasterPage implements OnInit {

  public name: any;
  public termDays: any;
  public graceDays = 60;
  public percentage: any;
  public discountPremium: any;
  public item: any;
  public id: any;
  public isActive: Boolean = true;
  public flagAddUpdate: any;
  public listOfTransType = [];
  public transTypeId: any;
  public transTypeIdList = []
  public termMasterOrder: any;

  constructor(private authService: AuthenticationService,
    public dragulaService: DragulaService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) { }

  async  ngOnInit() {
    await this.getAllTransType()
    let data = this.navParams.get('item');
    if (data != 0) {
      this.item = data.row;
      console.log(this.item)

      this.id = this.item.id
    }
    this.flagAddUpdate = this.navParams.get('flag');
    if (this.flagAddUpdate == 'update') {
      this.name = this.item.name;
      this.graceDays = this.item.graceDays;
      this.termDays = this.item.termDays;
      this.percentage = this.item.percentage;
      this.discountPremium = this.item.discount;
      this.termMasterOrder = this.item.termMasterOrder;
      this.isActive = this.item.isActive
      this.transTypeIdList = []
      this.transTypeId = this.item.transTypeId
      this.listOfTransType.forEach(el => {
        if (el.id == this.transTypeId) {
          this.transTypeIdList.push({ id: el.id, name: el.name })
        }
      })
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "transType") {
      if (idList.length > 0) {
        this.transTypeId = idList[0];
      } else {
        this.transTypeId = null;
      }

    }
  }


  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh')
  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data);
      this.listOfTransType = data.data;
    }
  }


  async addTerm() {
    let validate = this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        name: this.name,
        graceDays: this.graceDays,
        termDays: this.termDays,
        transTypeId: this.transTypeId,
        termMasterOrder: !!this.termMasterOrder ? this.termMasterOrder : null,
        // discountPremium: this.discountPremium,
        percentage: this.percentage,
        isActive: this.isActive
      }
      console.log(jsonObj)
      res = await this.httpApiService.addTermMaster(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async updateTerm() {
    let validate = this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let jsonObj = {
        name: this.name,
        graceDays: this.graceDays,
        termDays: this.termDays,
        transTypeId: this.transTypeId,
        termMasterOrder: !!this.termMasterOrder ? this.termMasterOrder : null,
        // discountPremium: this.discountPremium,
        percentage: this.percentage,
        isActive: this.isActive
      }
      console.log(jsonObj)
      let res: any;
      res = await this.httpApiService.updateTermMaster(this.id, jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async validateControls() {
    if (!!this.name &&
      !!this.graceDays &&
      this.termDays != null && this.termDays != '' && this.termDays != undefined && this.termDays >= 0 &&
      !!this.percentage &&
      !!this.isActive &&
      !!this.transTypeId
    ) {
      return true;
    } else {
      await this.configService.showToast('error', 'Please select file type')
      return false;
    }
  }

  async calculateDiscPrem() {
    console.log("calculateDiscPrem ", this.termDays, this.graceDays, this.percentage);
    if (!!this.termDays && !!this.graceDays && !!this.percentage) {
      this.discountPremium = this.percentage * (this.termDays - this.graceDays) / 30;
    } else {
      this.discountPremium = null;
    }
  }
}
