import { Component, Input } from "@angular/core";
import {
  AlertController, Events, LoadingController, ModalController, NavController
} from "@ionic/angular";
import * as moment from 'moment';
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: 'app-recut-inward',
  templateUrl: './recut-inward.component.html',
  styleUrls: ['./recut-inward.component.scss']
})
export class RecutInwardComponent implements ProcessActionComponent {
  @Input() data: any;
  public columns = [];
  public page = 'recutReturnInward';
  public listOfMappingParams = [];
  public pageNumber = 0;
  public noOfRecords = 100;
  public pageSize = 100;
  public pag
  public items = []
  public count = 0;
  public totalPages: Number = 0;
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public jNo: any;
  public jDate: any;
  public recutReturnDetail = [];
  public recutReturnDetailDataSource: any = {};
  public refreshGrid: boolean = false;
  public transferStoneList = [];
  public transferObject = {};
  public selected = []
  public ledOn: boolean = false;
  public stonesIndex: any = [];
  public totalSelected: number = 0
  public currentSelected: number = 0
  public currentRowReturnCts: number = 0

  public settingsDate = {
    bigBanner: true,
    timePicker: false,
    format: 'yyyy-MM-dd',
    defaultOpen: false,
  }
  public keyFun: any = true;
  public status: any;
  // staticGridData = {
  //   data: [
  //     { 'transactionFlag': true, 'factoryOutWNo': 102, 'factoryOutWDate': '2021/10/05', 'stoneName': 'Pink Diamond', 'lotNo': 'a102', 'size': 12, 'retCts': 10, 'lossCts': 2, 'wgtDiff': 8 },
  //     { 'transactionFlag': true, 'factoryOutWNo': 103, 'factoryOutWDate': '2021/09/05', 'stoneName': 'Basalt', 'lotNo': 'a103', 'size': 38, 'retCts': 35, 'lossCts': 0, 'wgtDiff': 35 },
  //   ],
  //   status: 200,
  //   message: 'Data fetched successfully.',
  //   error: 'Error occured',
  // };

  totalSummary = [
    {
      "fieldName": "size",
      "summaryType": "sum",
      "name": "Issue Cts"
    },
    {
      "fieldName": "retCts",
      "summaryType": "sum",
      "name": "Return Cts"
    },
    {
      "fieldName": "lossCts",
      "summaryType": "sum",
      "name": "Loss Cts"
    },
    {
      "fieldName": "wgtDiff",
      "summaryType": "sum",
      "name": "WGT Diff"
    }
  ]
  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public navCtrl: NavController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events) { }

  ngOnInit() {
    // this.recutReturnDetail = [{
    //   id: 1, transactionFlag: 'Normal', factoryOutWNo: 1,transferOnRequestStoneId:1, factoryOutWDate: '2021-12-12 00:00:00', stoneName: 'N574520', newStoneName: 'N574520'
    // }, {
    //   id: 2, transactionFlag: 'Normal', factoryOutWNo: 2,transferOnRequestStoneId:2, factoryOutWDate: '2021-12-12 00:00:00', stoneName: 'N574521', newStoneName: 'N574521'
    // }]
    console.log(this.data.listOfActionPermissions)
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
    }



    this.columns = [
      { name: "Transaction Type", prop: "transactionFlag" },
      { name: "Srt J No", prop: "factoryOutWNo" },
      { name: "Srt J Date", prop: "factoryOutWDate" },
      { name: "Stone Id", prop: "stoneName" },
      { name: "Lot Name", prop: "lotNo" },
      { name: "Issue Cts", prop: "size", "displayFormat": "{0}", "showInGroupFooter": true, "summaryType": "sum", fieldName: "size" },
      { name: "Return Cts", prop: "retCts", "displayFormat": "{0}", "showInGroupFooter": true, "summaryType": "sum", fieldName: "retCts" },
      { name: "Loss Cts", prop: "lossCts", "displayFormat": "{0}", "showInGroupFooter": true, "summaryType": "sum", fieldName: "lossCts" },
      { name: "WGT Diff", prop: "wgtDiff", "displayFormat": "{0}", "showInGroupFooter": true, "summaryType": "sum", fieldName: "wgtDiff" },
      { name: "Return Stone", prop: "newStoneName" },
      { name: "Shape", prop: "shape" },
      { name: "Clarity", prop: "clarity" },
      { name: "Color", prop: "color" },
      { name: "Certi Lab", prop: "labRemark" },
      { name: "Inscription", prop: "fmInscription" },
      { name: "Lattice Voucher ID", prop: "latticeVoucherID" },
      { name: "Lattice Issue Seq ID", prop: "latticeIssueSeqID" },
    ]
    this.recutInwardData()
    this.event.subscribe('Recut Inward', (item) => {
      this.columns = [
        { name: "Transaction Type", prop: "transactionFlag" },
        { name: "Srt J No", prop: "factoryOutWNo" },
        { name: "Srt J Date", prop: "factoryOutWDate" },
        { name: "Stone Id", prop: "stoneName" },
        { name: "Lot Name", prop: "lotNo" },
        { name: "Issue Cts", prop: "size", "displayFormat": "{0}", "showInGroupFooter": true, "summaryType": "sum", fieldName: "size" },
        { name: "Return Cts", prop: "retCts", "displayFormat": "{0}", "showInGroupFooter": true, "summaryType": "sum", fieldName: "retCts" },
        { name: "Loss Cts", prop: "lossCts", "displayFormat": "{0}", "showInGroupFooter": true, "summaryType": "sum", fieldName: "lossCts" },
        { name: "WGT Diff", prop: "wgtDiff", "displayFormat": "{0}", "showInGroupFooter": true, "summaryType": "sum", fieldName: "wgtDiff" },
        { name: "Return Stone", prop: "newStoneName" },
        { name: "Shape", prop: "shape" },
        { name: "Clarity", prop: "clarity" },
        { name: "Color", prop: "color" },
        { name: "Certi Lab", prop: "labRemark" },
        { name: "Inscription", prop: "fmInscription" },
        { name: "Lattice Voucher ID", prop: "latticeVoucherID" },
        { name: "Lattice Issue Seq ID", prop: "latticeIssueSeqID" },
      ]
      this.recutInwardData()
    })
  }

  async recutReturnFromKG() {
    let res: any;
    await this.configService.showLoader()
    let jdate = moment(this.jDate).format('DD-MMM-YYYY');
    if (this.jNo != undefined && this.jNo != null) {
      res = await this.httpApiService.fetchRecutReturnStonesByUser(this.jNo, jdate);
      let data = res.json();
      await this.loadingController.dismiss()
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.recutInwardData()
        await this.configService.showToast("success", data.message);
      }
    }
  }

  async recutInwardData() {
    let res: any;
    await this.configService.showLoader()
    res = await this.httpApiService.fetchRecutInwardData();
    let data = res.json();
    // res = this.staticGridData;
    // let data = this.staticGridData;
    // console.log(data);

    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      // console.log(data.data);
      this.recutReturnDetail = data.data;
    }
  }



  async onselect(ev) {
    console.log(ev)
    this.selected = ev.selectedRowsData;
    this.transferStoneList = []
    this.transferObject = {}
    this.currentSelected = ev.currentSelectedRowKeys.length;
    this.totalSelected = ev.selectedRowsData.length;
    this.currentRowReturnCts = 0;
    if (this.selected.length === 0) {
      this.currentRowReturnCts = 0;
    }
    this.selected.forEach(el => {

      if (el.transferOnRequestStoneId) {
        let stoneId = el.transferOnRequestStoneId;
        let stoneName = el.newStoneName;
        if (!!this.transferObject[stoneId] && this.transferObject[stoneId].length > 0) {
          if (this.transferObject[stoneId].indexOf(stoneName) < 0) {
            this.transferObject[stoneId].push({
              stoneName: el.newStoneName,
              factoryOutWNo: el.factoryOutWNo,
              factorySeqNo: el.factorySeqNo,
              factoryOutWDate: el.factoryOutWDate
            })
          }
        } else {
          let array = []
          array.push({
            stoneName: el.newStoneName,
            factoryOutWNo: el.factoryOutWNo,
            factorySeqNo: el.factorySeqNo,
            factoryOutWDate: el.factoryOutWDate
          })
          this.transferObject[stoneId] = array
        }
      }
      this.currentRowReturnCts += parseFloat(el.retCts);
      this.currentRowReturnCts = Number(this.currentRowReturnCts.toFixed(3))
      // this.transferStoneList.push(el.transferOnRequestStoneId)
    });
    console.log(this.transferObject)
  }

  ngOnDestroy() {
    this.event.unsubscribe('Recut Inward');
  }

  async transfer() {
    let jsonObj = {
      "mapOfTrsStoneIdsAndStoneParams": this.transferObject,
      "onlyUpdateParameters": false
    };
    console.log(jsonObj)
    let res: any;
    await this.configService.showLoader()
    res = await this.httpApiService.recutReturn(jsonObj);
    let data = res.json();
    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.recutReturnDetail = []
      this.stonesIndex = []
      this.refreshGrid = false;
      await this.configService.showToast("success", data.message);
      this.recutInwardData()
    }
  }



  selectByVoucherNo() {
    this.stonesIndex = []
    if (!!this.jNo) {
      this.recutReturnDetail.filter((el, i) => {
        if (el.factoryOutWNo == this.jNo) {
          this.stonesIndex.push(el);
        }
      })
      this.ledOn = true;
      setTimeout(() => {
        this.ledOn = false;
      }, 1000)
    }
  }
}
