import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class SseService {
  public eventSource: any;
  constructor(private _zone: NgZone) { }

  getServerSentEvent(url: string, userId): Observable<any> {
    return Observable.create(observer => {
      this.eventSource = this.getEventSource(url);
      this.eventSource.onmessage = event => {
        this._zone.run(() => {
          observer.next(event);
        });
      };
      this.eventSource.onerror = error => {
        this._zone.run(() => {
          observer.error(error);
        });
      };
      this.eventSource.addEventListener(userId, event => {
        console.log(event);
        this._zone.run(() => {
          observer.next(event);
        });
      })
      this.eventSource.addEventListener('lock', event => {
        console.log(event);
        this._zone.run(() => {
          observer.next(event);
        });
      })
      this.eventSource.addEventListener('TemplateDownload', event => {
        console.log(event);
        this._zone.run(() => {
          observer.next(event);
        });
      })
    });
  }

  private getEventSource(url: string): EventSource {
    return new EventSource(url);
  }

  unsubscribeSSE() {
    if (this.eventSource) {
      this.eventSource.close();
      console.log('closed')
    }
  }
}
