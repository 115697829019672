import { Component, Input } from '@angular/core';
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: 'app-sold-search',
  templateUrl: './sold-search.component.html',
  styleUrls: ['./sold-search.component.scss']
})
export class SoldSearchComponent implements ProcessActionComponent {
  @Input() data: any;
  public query: any;
  public type = "Sold";
  //public department = "Sales";
  public disable: any = [];
  public cart = true;
  public default = {
    criteria: true,
    search: true,
    color: true,
    shape: true,
    carat: true,
    size: true,
    clarity: true,
    internalStatus: true,
    download: true,
    colorOverride: true,
    transType: true,
    clarityOverride: true,
    party: true,
    certiDate: false,
    inwardDate: false,
    transactionDate: false,
    soldDate: true,
    standardShapeCode: true
  };

  constructor() { }

  ngOnInit() {

  }
}
