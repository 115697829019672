import { Component, Input } from "@angular/core";
import {
  NavController,
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ExportAsExcelService } from "../../../services/export-as-excel.service";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-pricing-calc',
  templateUrl: './pricing-calc.component.html',
  styleUrls: ['./pricing-calc.component.scss']
})
export class PricingCalcComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() transType: any;
  @Input() mixData: any;

  public rowData = [];
  public listOfParameters = [];
  public dataColumns = [];
  public criteriaList = [];
  public rapPriceListId: any;
  public defaultParam = [];
  public priceList = [];
  public allPriceNameList = [];
  public typeList = [];
  public readFromExcel: any;
  public typeIdList = [];
  public isDisable = false;
  public typeName: any;
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public policyChainId = null;
  public sizeList = []
  public defaultParamSS = ['cts', 'Shape', 'Color', 'Clarity', 'Cut', 'Polish', 'Symmetry', 'Fluorescence', 'Lab', 'New Cut', 'Pav Angle', 'Cr Angle', 'Table Per', 'Tot Depth', 'Length', 'Width', 'L_W'];
  // 'Shades', 'Key to Symbols', 'Crown Open Inclusion', 'Crown Extra Facet', 'Girdle Open Inclusion', 'Graining', 'Luster', 'Pavilion Extra Facet', 'Pavilion Open Inclusion', 'Side Spot Inclusion', 'Side Black Inclusion', 'Side White Inclusion', 'Table Black Inclusion', 'Table Extra Facet', 'Table Open Inclusion', 'Table Spot Inclusion', 'Table White Inclusion'
  public defaultParamMIX = ['Shape', 'Size', 'Color', 'Clarity'];
  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public loadingController: LoadingController,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService,) { }

  async ngOnInit() {
    this.transType = !!this.transType ? this.transType : 'SS';
    if (this.transType == 'SS') {
      this.defaultParam = this.defaultParamSS;
    } else {
      this.defaultParam = this.defaultParamMIX;
      await this.findAllTypeForPricing()
    }
    let permissions = this.transType == 'SS' ? this.data.listOfActionPermissions : this.mixData.listOfActionPermissions;
    if (!!permissions) {
      permissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
    }
    await this.getDefaultSetting();
    this.event.subscribe(this.transType == 'SS' ? 'SS Pricing Calculator' : 'MIX Pricing Calculator', (item) => {
      if (this.transType == 'SS') {
        this.defaultParam = this.defaultParamSS;
      } else {
        this.defaultParam = this.defaultParamMIX;
        this.findAllTypeForPricing()
      }
      this.criteriaList = [];
      this.getDefaultSetting();
    })

  }

  ngOnDestroy() {
    this.event.unsubscribe(this.transType == 'SS' ? 'SS Pricing Calculator' : 'MIX Pricing Calculator');
  }

  dropDownSelection(ev) {
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "typeId") {
      if (idList.length > 0) {
        this.typeName = obj[0].name;
        this.getAllPolicyChain()
        this.getAllTypeFor(this.typeName)
      } else {
        this.policyChainId = null;
        this.typeName = null;
      }
    }
  }

  // GET ALL POLICIES

  async getAllPolicyChain() {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getAllPolicyChain();
    let data = res.json();
    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (this.typeName.toLowerCase() == 'sale bulk') {
        let isExist = data.data.find(item => item.name == 'Sale Bulk')
        if (isExist)
          this.policyChainId = isExist.id;
      }
      else if (this.typeName.toLowerCase() == 'sales') {
        let isExist = data.data.find(item => item.name == 'Sales')
        if (isExist)
          this.policyChainId = isExist.id;
      } else if (this.typeName.toLowerCase() == 'assort') {
        let isExist = data.data.find(item => item.name == 'Assort')
        if (isExist)
          this.policyChainId = isExist.id;
      } else {
        let isExist = data.data.find(item => item.name == 'Manufacturing')
        if (isExist)
          this.policyChainId = isExist.id;
      }
    }
  }

  // default setting

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.transType == 'MIX' ? this.mixData.actionId : this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.basePriceListName) {
          this.rapPriceListId = dataValue.map.basePriceListName;
        }
        if (!!dataValue.map.listOfPrices) {
          let displayPriceList = JSON.parse(dataValue.map.listOfPrices)
          await this.getAllPriceListName(displayPriceList);
        }
        if (!!dataValue.map.type) {
          this.isDisable = true;
          this.typeIdList = []
          this.typeList.forEach(item => {
            if (item.name == dataValue.map.type) {
              this.typeIdList.push({ id: item.id, name: item.name })
            }
          })
        }

      }
      this.findAllWithMaster();
    }
  }

  // get sizeList 
  async getAllTypeFor(name) {
    let res: any;
    res = await this.httpApiService.getAllTypeFor(name);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.sizeList = data.data;
      let index = this.dataColumns.findIndex(x => x.name == 'Size');
      if (index > -1) {
        this.dataColumns[index].dataSource = this.sizeList;
        this.dataColumns[index].entityType = 'selection';
      }
    }
  }

  // type of mix pricing

  async findAllTypeForPricing() {
    let res: any;
    res = await this.httpApiService.findAllTypeForPricing();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let tempArray = []
      for (let i = 0; i < dataValue.length; i++) {
        if (dataValue[i] != 'Manufacturing') {
          tempArray.push({ id: i + 1, name: dataValue[i] })
        }
      }
      this.typeList = tempArray;

    }
  }


  // All price list

  async getAllPriceListName(displayPriceList) {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data
      if (data.data.length > 0) {
        data.data.forEach(el => {
          let index = displayPriceList.findIndex(x => x == el.id);
          if (index > -1) {
            this.priceList.push(el)
          }
        })
      }
    }
  }

  //find all Parameters with value

  async findAllWithMaster() {
    let res: any;
    // res = await this.httpApiService.findAllWithMaster();
    res = await this.httpApiService.findAllWithMasterForSingleStone();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfParameters = data.data;
      this.defaultParam.forEach(el => {
        let index = this.listOfParameters.findIndex(x => x.paramName == el);
        if (index > -1) {
          let valueList = this.listOfParameters[index].paramValue.filter(item => {
            if ((!item.transTypeName || item.transTypeName == this.transType) && !item.isDisabled) {
              return item;
            }
          }).sort((a, b) =>
            a.priority - b.priority
          );

          this.dataColumns.push({
            name: this.listOfParameters[index].paramName,
            dataSource: valueList,
            entityType: 'selection',
            isAllow: true
          })
        } else {
          this.dataColumns.push({
            name: el,
            dataSource: '',
            entityType: 'text',
            isAllow: true
          })
        }
      })
      if (this.priceList.length > 0) {
        this.priceList.forEach(el => {
          if (el.name.toLowerCase() != 'rapaport' && this.transType == 'SS') {
            this.dataColumns.push({
              name: el.name + 'DiscPer',
              dataSource: '',
              entityType: 'text',
              isAllow: false
            })
          }
          if (this.transType == 'SS') {
            this.dataColumns.push({
              name: el.name,
              dataSource: '',
              entityType: 'text',
              isAllow: false
            })
            if (el.name.toLowerCase() != 'rapaport') {
              this.dataColumns.push({
                name: el.name + ' Amount',
                dataSource: '',
                entityType: 'text',
                isAllow: false
              })
            }
          } else {
            this.dataColumns.push({
              name: 'MIX Price',
              dataSource: '',
              entityType: 'text',
              isAllow: false
            })
          }
        })
      }

      console.log(this.dataColumns)
    }
  }

  // row insert, update, delete

  onRowInserting(etype, ev) {
  }

  // onRowInserted(ev) {
  //   if (Object.keys(ev.data).length > 1) {
  //     this.criteriaList.push(ev.data);
  //   }
  //   console.log(this.criteriaList)
  // }

  onRowRemoved(ev) {
    let index = this.criteriaList.findIndex(x => x.ID == ev.data.ID);
    if (index > -1) {
      this.criteriaList.splice(index, 1);
    }
    console.log(this.criteriaList)
  }

  onRowUpdated(ev) {
    console.log(ev)
    let index = this.criteriaList.findIndex(x => x.ID == ev.data.ID);
    if (index > -1) {
      this.criteriaList[index] = ev.data;
    }
    console.log(this.criteriaList)
  }

  logEvent(type, ev) {
    console.log(type, ev)
    if (type == 'EditingStart' && !!ev.data && ev.data.Shape && this.transType == 'MIX') {
      let index = this.dataColumns.findIndex(x => x.name == 'Size');
      if (index > -1) {
        this.dataColumns[index].dataSource = this.sizeList.filter(el => el.shapeName == ev.data.Shape);
        this.dataColumns[index].entityType = 'selection';
      }
      console.log(this.dataColumns, this.dataColumns[index].dataSource)
    }
  }


  onSelectionChanged(ev) {
  }

  //calculate price

  async calculatePrice() {
    if (this.transType == 'SS') {
      let priceName = this.allPriceNameList.map(x => x.name)
      priceName.forEach(el => {
        if (this.transType == 'SS') {
          priceName.push(el = el + 'DiscPer')
        }
        priceName.push(el = el + ' Amount')
      })
      priceName.forEach(el => {
        this.criteriaList.map(res => { delete res[el]; return res; })
      })
      console.log(priceName)

      this.defaultParam.forEach(el => {
        this.criteriaList.map(res => {
          if (!res[el]) {
            res[el] = null;
          } return res;
        })
      })
      console.log(this.criteriaList)
    }
    if (this.typeName) {
      this.criteriaList.forEach(el => {
        el.Type = this.typeName
      })
    }
    let res: any;
    await this.configService.showLoader()
    if (this.transType == 'SS') {
      res = await this.httpApiService.calculateSSPricing(this.criteriaList);
    } else {

      // this.criteriaList.filter(el => {
      //   let isExist = this.sizeList.find(item => item.name == el.Size && el.Shape == item.shapeName);
      //   if (isExist) {
      //     el.fromCts = isExist.fromCts;
      //     el.toCts = isExist.toCts;
      //   }
      // })
      res = await this.httpApiService.calculateMIXPricing(this.criteriaList, this.policyChainId, this.typeName);
    }

    let data = res.json();
    this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      // console.log('\x1b[41m%s\x1b[0m', 'data col and criteriaList ', this.dataColumns,this.criteriaList)
      if (data.data.length > 0) {
        data.data.forEach(el => {
          let index = this.criteriaList.findIndex(x => x.ID == el.ID);
          if (index > -1) {
            this.criteriaList[index] = Object.assign(this.criteriaList[index], el);
            if (this.transType == 'MIX') {
              this.criteriaList.map((item) => {
                if (item['MIX Price']) {
                  item['MIX Price'] = item['MIX Price'];
                }
              })
            } else {
              this.criteriaList.map((item) => {
                if (item['SPM']) {
                  item['SPM'] = item['SPM'];
                }
                if (item['BASE']) {
                  item['SPMDiscPer'] = item['BASE'];
                }
                return item
              })
            }
          }
        })

        if (this.priceList.length > 0) {
          this.criteriaList.forEach(el => {
            this.priceList.forEach((item, i) => {
              console.log(el, item)
              if (el.hasOwnProperty(item.name)) {
                let calcPrice = item.name + ' Amount';
                if (!!el.cts && !!el[item.name])
                  el[calcPrice] = (el.cts * el[item.name]).toFixed(2);
              }
              if (el.hasOwnProperty(item.name)) {
                if (!!el.cts && !!el[item.name])
                  el['SPMDiscPer'] = ((el['RAPAPORT'] - el['SPM']) / el['RAPAPORT'] * 100).toFixed(2);
              }
            })
          })
        }

      }

    }
  }

  fileObject(ev) {
    this.readFromExcel = []
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      this.readFromExcel = jsonData.data;
      this.readFromExcel.forEach(el => {
        el.ID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      })
    }
    reader.readAsBinaryString(file);

  }

  //export tempalate

  async exportTemplate() {
    await this.exportAsExcelService.exportAsExcelFile(this.defaultParam, 'Pricing_calc_template')
  }

  //Import excel template

  async importTemplate() {
    this.criteriaList = this.criteriaList.concat(this.readFromExcel)
  }

  //reset data 

  reset() {
    this.criteriaList = [];
  }

}
