import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-view-scan-result',
  templateUrl: './view-scan-result.page.html',
  styleUrls: ['./view-scan-result.page.scss'],
})
export class ViewScanResultPage implements OnInit {
  @Input() boxDetail: any[];
  @Input() scanCount: number = 0;
  @Input() stoneFound: number = 0;

  constructor(
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

}
