import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { IonicSelectableComponent } from "ionic-selectable";

@Component({
  selector: 'app-email-to-customer',
  templateUrl: './email-to-customer.page.html',
  styleUrls: ['./email-to-customer.page.scss'],
})
export class EmailToCustomerPage implements OnInit {
  @ViewChild('selectableComponent') selectableComponent: IonicSelectableComponent;
  @Input() showParty: boolean = true;
  public customerslist = []
  public pageNumber = 0;
  public noOfRecords = 10;
  public email: string = '';
  public setOfStoneNames = [];
  public selectedParty = '';

  constructor(private modalCtrl: ModalController,
    private navParams: NavParams,
    public configService: ConfigService,
    public authService: AuthenticationService,
    public loadingController: LoadingController,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public event: Events) { }

  ngOnInit() {
    this.setOfStoneNames = this.navParams.get('selectedRows')
    console.log(this.showParty)
    this.getAllCustomer('', this.pageNumber, this.noOfRecords);
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async getAllCustomer(name, pageNumber, noOfRecords) {
    let res: any;
    if (!!name) {
      this.pageNumber = 0;
    }
    res = await this.httpApiService.getAllCustomerBypage(name, pageNumber, noOfRecords);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerslist = data.data.content;
    }
  }

  async searchParty(event: { component: IonicSelectableComponent; text: string }) {
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    await this.getAllCustomer(searchText, this.pageNumber, this.noOfRecords);
  }

  onChangeParty(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (!!event.value) {
      this.email = event.value.email;

    } else {
      this.email = null;
    }
  }

  async sendEmail() {
    let qry =
    {
      "query": {
        "bool": {
          "should": [
            {
              "simple_query_string": {
                "query": ""
              }
            },
            {
              "bool": {
                "must": []
              }
            }
          ]
        }
      }
    }
    let boolShouldMultiple = {
      "bool": {
        "should": []
      }
    };
    for (let i = 0; i < this.setOfStoneNames.length; i++) {
      if (this.setOfStoneNames[i] != '') {
        let prefixObj: any = {};
        prefixObj =
        {
          "prefix": {
            "stoneName": this.setOfStoneNames[i].trim()
          }
        }
        boolShouldMultiple.bool.should.push(prefixObj);
      }
    }
    console.log(boolShouldMultiple.bool.should)
    qry.query.bool.should[1].bool.must.push(boolShouldMultiple);
    qry.query.bool.should[1].bool.must.push(
      {
        "match": {
          "availableForSale": 1
        }
      })
    qry.query.bool.should[1].bool.must.push(
      {
        "terms": {
          "externalStatus": [
            "Available"
          ]
        }
      })
    let res: any;
    let json = {
      query: qry,
      email: this.email
    }
    res = await this.httpApiService.emailToCustomer(json)
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.modalCtrl.dismiss()
    }
  }

}
