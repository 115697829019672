import { Component, OnInit, Input } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ModalController, LoadingController, Events, AlertController } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { ManageOfferDetailsPage } from "../../actions/manage-offer-details/manage-offer-details.page";

@Component({
  selector: 'app-website-offer',
  templateUrl: './website-offer.component.html',
  styleUrls: ['./website-offer.component.scss']
})
export class WebsiteOfferComponent implements ProcessActionComponent {
  @Input() data: any;

  public pageNumber = 0;
  public noOfRecords = 50;
  public pendingOfferList = [];
  public count: Number = 0;
  public columns = [];
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public event: Events) {
    this.event.subscribe('Website Offer', (item) => {
      this.columns = [
        { name: "Sr No", prop: "" },
        { name: "Ref Party Code", prop: "partyMasterId" },
        { name: "Party Name", prop: "partyName" },
        { name: "Person Name", prop: "contactName" },
        { name: "Stones", prop: "totalStones" },
        { name: "Cts", prop: "totalCts" },
        { name: "KAM Person", prop: "kamName" },
        { name: "Status", prop: "status" },
        { name: "Date", prop: "modifiedDate" },
        { name: "Action", prop: "" },
      ];
      this.findAllWebsiteOffer(this.pageNumber = 0, this.noOfRecords = 50)
    })
  }

  ngOnInit() {
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    this.columns = [
      { name: "Sr No", prop: "" },
      { name: "Ref Party Code", prop: "partyMasterId" },
      { name: "Party Name", prop: "partyName" },
      { name: "Person Name", prop: "contactName" },
      { name: "Stones", prop: "totalStones" },
      { name: "Cts", prop: "totalCts" },
      { name: "KAM Person", prop: "kamName" },
      { name: "Status", prop: "status" },
      { name: "Date", prop: "modifiedDate" },
      { name: "Action", prop: "" },
    ];
    this.findAllWebsiteOffer(this.pageNumber, this.noOfRecords)
  }

  ngOnDestroy() {
    this.event.unsubscribe('Website Offer');
  }

  async findAllWebsiteOffer(pageNumber, noOfRecords) {
    let res: any;
    let userData = await this.authService.userData()
    await this.configService.showLoader()
    res = await this.httpApiService.findAllWebsiteOffer(
      pageNumber,
      noOfRecords,
      userData.userId
    );
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.pendingOfferList = data.data.content
      console.log(this.pendingOfferList)
      this.count = data.data.totalElements;
    }
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.findAllWebsiteOffer(this.pageNumber, this.noOfRecords)
  }


  async openModal(item) {
    if (item.type == "mouseenter") {
      item.event.stopPropagation();
    } else if ((item.type == "click") && (item.column && item.column.name == 'Action')) {
      this.confirmRemoveOffer(item.row)
    } else {
      if (this.updatePermissionFlag == true) {
        let modal = await this.modalCtrl.create({
          component: ManageOfferDetailsPage,
          backdropDismiss: false,
          cssClass: "requestPage",
          componentProps: { item: item.row }
        })
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
    }
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      await this.findAllWebsiteOffer(this.pageNumber, this.noOfRecords)
    }
  }

  //Start Delete Offer 

  async confirmRemoveOffer(item) {
    console.log(item)
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to remove this offer Stone ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteOffer(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteOffer(offerId) {
    let res: any;
    res = await this.httpApiService.rejectOffer(offerId);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.findAllWebsiteOffer(this.pageNumber, this.noOfRecords)
    }
  }

}
