import { Component, OnInit } from "@angular/core";
import { Events, ModalController, NavParams } from "@ionic/angular";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpApiService } from "src/app/services/http-api/http-api.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormControl, FormGroup, FormsModule } from "@angular/forms";
import { UploadService } from "src/app/services/upload_service";
@Component({
  selector: "app-manage-layouts",
  templateUrl: "./manage-layouts.component.html",
  styleUrls: ["./manage-layouts.component.scss"],
})
export class ManageLayoutsComponent implements OnInit {
  public type: any;
  public role: any;
  public selectedRoleId: any;
  titleOfModal: string = "";
  optionsToSelect: any = [
    { id: 1, name: "Yes" },
    { id: 2, name: "No" },
  ];

  titleOfCategory: string = "";
  descOfCategory: string = "";
  imageUrlOfCategory: string = "";
  createdDateOfCategory: string = "";
  createdByOfCategory: string = "";
  updatedDateOfCategory: string = "";
  updatedByOfCategory: string = "";
  isActiveOfCategory: boolean;
  rootPath: string = "layouts";
  uploadedDocumentImagesUrl: any[] = [];
  uploadedDocumentImagesName: any[] = [];
  // ********************************************************

  titleOfItem: string = "";
  descOfItem: string = "";
  caratsOfItem: string = "";
  pcsOfItem: string = "";
  amountOfItem: string = "";
  colorRangeOfItem: string = "";
  sizeRangeOfItem: string = "";
  clarityRangeOfItem: string = "";
  imageUrlOfItem: string = "";
  isActiveOfItem: boolean;
  createdDateOfItem: string = "";
  createdByOfItem: string = "";
  updatedDateOfItem: string = "";
  updatedByOfItem: string = "";
  selectedCategory: any;
  layoutCategoryForm: FormGroup;
  layoutItemsForm: FormGroup;
  categoryItems: any;
  imageFileName: any;
  imageUrlAWS: any;
  imageUrlAWSforItem: any;
  rowData: any;
  testData: any;
  itemData: any;
  updateImageUrl: any;
  categoryTitle: any;
  categoryDataInModal: any;
  categoryData: any;
  updateImageUrlOfCategory: any;
  statusOfCategory: any;
  statusOfItem: any;

  constructor(
    private authService: AuthenticationService,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public event: Events,
    private s3ImageService: UploadService
  ) {
    this.layoutCategoryForm = new FormGroup({
      titleOfCategory: new FormControl(""),
      descOfCategory: new FormControl(""),
      imageUrlOfCategory: new FormControl(""),
      isActiveOfCategory: new FormControl(Boolean),
    });

    this.layoutItemsForm = new FormGroup({
      titleOfItem: new FormControl(""),
      descOfItem: new FormControl(""),
      caratsOfItem: new FormControl(""),
      pcsOfItem: new FormControl(""),
      amountOfItem: new FormControl(""),
      colorRange: new FormControl(""),
      sizeRange: new FormControl(""),
      clarityRange: new FormControl(""),
      imageUrlOfItem: new FormControl(""),
      isActiveOfItem: new FormControl(Boolean),
    });
  }

  ngOnInit() {
    if (this.type == "1") {
      this.titleOfModal = "Add New Layout Category ";
    } else if (this.type == "2") {
      this.titleOfModal = "Add Layout Category Items";
    } else if (this.type == "3") {
      this.titleOfModal = "Edit Layout Item Details";
      this.itemData = this.rowData.rowData;
      this.categoryTitle = this.rowData.titleOfCategory;
      this.layoutItemsForm.controls.titleOfItem.setValue(this.itemData.title);
      this.layoutItemsForm.controls.descOfItem.setValue(
        this.itemData.shortDescription
      );
      this.layoutItemsForm.controls.caratsOfItem.setValue(this.itemData.carats);
      this.layoutItemsForm.controls.pcsOfItem.setValue(this.itemData.pcs);
      this.layoutItemsForm.controls.amountOfItem.setValue(this.itemData.Amount);
      this.layoutItemsForm.controls.colorRange.setValue(
        this.itemData.colorRange
      );
      this.layoutItemsForm.controls.sizeRange.setValue(this.itemData.sizeRange);
      this.layoutItemsForm.controls.clarityRange.setValue(
        this.itemData.clarityRange
      );
      this.layoutItemsForm.controls.clarityRange.setValue(
        this.itemData.clarityRange
      );
      this.updateImageUrl = this.itemData.imageUrl;
      this.statusOfItem = this.itemData.isActive === 1 ? "yes" : "no";
    } else if (this.type == "4") {
      this.titleOfModal = "Update Layout Category ";
      this.categoryDataInModal = this.categoryData.categoryData;
      this.layoutCategoryForm.controls.titleOfCategory.setValue(
        this.categoryDataInModal[0].title
      );
      this.layoutCategoryForm.controls.descOfCategory.setValue(
        this.categoryDataInModal[0].shortDescription
      );
      this.updateImageUrlOfCategory = this.categoryDataInModal[0].imageUrl;
      this.layoutCategoryForm.controls.isActiveOfCategory.setValue(
        this.categoryDataInModal[0].isActive
      );
      this.statusOfCategory =
        this.categoryDataInModal[0].isActive === 1 ? "Yes" : "No";
    }
  }

  closeModal() {
    this.modalCtrl.dismiss("doNotRefresh");
  }
  async addNewCategory() {
    let payload = {
      title: this.layoutCategoryForm.value.titleOfCategory,
      shortDescription: this.layoutCategoryForm.value.descOfCategory,
      imageUrl: this.imageUrlAWS,
      isActive: this.isActiveOfCategory,
    };

    let res: any;
    res = await this.httpApiService.addNewLayoutCategory(payload);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.resetControls();
      await this.configService.showToast("success", data.message);
      await this.modalCtrl.dismiss();
    }
  }
  async resetControls() {
    // if (this.type == "1") {
    //   this.actionIdList = [];
    //   this.actionPermissionIdList = [];
    //   this.setOfActionPermissionId = [];
    // } else {
    //   this.newRoleName = "";
    //   this.description = "";
    // }
  }

  selectIfActive(ev: any) {
    if (ev.target.value == "yes") {
      this.isActiveOfCategory = true;
    } else {
      this.isActiveOfCategory = false;
    }
  }

  selectIfActiveItem(ev: any) {
    if (ev.target.value == "yes") {
      this.isActiveOfItem = true;
    } else {
      this.isActiveOfItem = false;
    }
  }

  async addNewItemsInCategory() {
    let payload = {
      layoutCategoryId: this.role.selectedRoleId,
      title: this.layoutItemsForm.value.titleOfItem,
      shortDescription: this.layoutItemsForm.value.descOfItem,
      carats: this.layoutItemsForm.value.caratsOfItem,
      pcs: parseFloat(this.layoutItemsForm.value.pcsOfItem),
      Amount: parseFloat(this.layoutItemsForm.value.amountOfItem),
      colorRange: this.layoutItemsForm.value.colorRange,
      sizeRange: this.layoutItemsForm.value.sizeRange,
      clarityRange: this.layoutItemsForm.value.clarityRange,
      imageUrl: this.imageUrlAWSforItem,
      isActive: this.isActiveOfItem,
    };

    let res: any;
    res = await this.httpApiService.addNewItemInLayoutCategory(payload);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.resetControls();
      await this.configService.showToast("success", data.message);
      await this.getLayoutItemsByCategoryId(this.role.selectedRoleId);
      await this.modalCtrl.dismiss();
    }
  }

  async getLayoutItemsByCategoryId(categoryId: any) {
    let res: any;
    res = await this.httpApiService.getLayoutItemsByCategoryId(categoryId);
    let data = res.json();

    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.categoryItems = data.data;
      this.modalCtrl.dismiss(this.categoryItems);
    }
  }

  async fileChangeEvent(event: any) {
    await this.configService.showLoader();
    this.s3ImageService
      .uploadFile(event.target.files[0], this.rootPath)
      .then((url: any) => {
        if (!!url) {
          this.uploadedDocumentImagesUrl.push(url);
          this.uploadedDocumentImagesName.push(event.target.files[0].name);
          this.imageUrlAWS = url;
          this.configService.dismiss();
        }
      });
    this.imageFileName = event.target.files[0].name;
  }

  async fileChangeEventForItem(event: any) {
    await this.configService.showLoader();

    this.s3ImageService
      .uploadFile(event.target.files[0], this.rootPath)
      .then((url: any) => {
        if (!!url) {
          this.uploadedDocumentImagesUrl.push(url);
          this.uploadedDocumentImagesName.push(event.target.files[0].name);
          this.imageUrlAWSforItem = url;
          this.configService.dismiss();
        }
      });
    this.imageFileName = event.target.files[0].name;
  }

  async updateItem() {
    let itemId = this.itemData.id;

    let payload = {
      layoutCategoryId: this.itemData.layoutCategoryId,
      title: this.layoutItemsForm.value.titleOfItem,
      shortDescription: this.layoutItemsForm.value.descOfItem,
      carats: this.layoutItemsForm.value.caratsOfItem,
      pcs: parseFloat(this.layoutItemsForm.value.pcsOfItem),
      Amount: parseFloat(this.layoutItemsForm.value.amountOfItem),
      colorRange: this.layoutItemsForm.value.colorRange,
      sizeRange: this.layoutItemsForm.value.sizeRange,
      clarityRange: this.layoutItemsForm.value.clarityRange,
      imageUrl: this.imageUrlAWSforItem,
      isActive: this.isActiveOfItem,
      createdAt: this.itemData.createdAt,
      createdBy: this.itemData.createdBy,
    };
    if (
      this.imageUrlAWSforItem == " " ||
      this.imageUrlAWSforItem == undefined
    ) {
      payload.imageUrl = this.updateImageUrl;
    }
    if (this.isActiveOfItem == undefined) {
      payload.isActive = this.statusOfItem === "Yes" ? true : false;
    }
    this.updateItemApi(itemId, payload);
  }

  async updateItemApi(itemId: any, payload: any) {
    let res: any;
    res = await this.httpApiService.updateItemInSpecificCategory(
      itemId,
      payload
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getLayoutItemsByCategoryId(payload.layoutCategoryId);
      this.resetControls();
      await this.configService.showToast("success", data.message);
    }
  }

  async updateCategoryDetails() {
    let categoryId = this.categoryDataInModal[0].id;
    let payload = {
      title: this.layoutCategoryForm.value.titleOfCategory,
      shortDescription: this.layoutCategoryForm.value.descOfCategory,
      imageUrl: this.imageUrlAWS,
      isActive: this.isActiveOfCategory,
      createdAt: this.categoryDataInModal[0].createdAt,
      createdBy: this.categoryDataInModal[0].createdBy,
    };
    if (this.imageUrlAWS == " " || this.imageUrlAWS == undefined) {
      payload.imageUrl = this.updateImageUrlOfCategory;
    }
    if (this.isActiveOfCategory == undefined) {
      payload.isActive = this.statusOfCategory === "Yes" ? true : false;
    }
    this.updateCategoryApi(categoryId, payload);
  }

  async updateCategoryApi(id: any, payload: any) {
    let res: any;
    res = await this.httpApiService.updateCategory(id, payload);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.categoryData = data.data;
      this.modalCtrl.dismiss(id);
      this.resetControls();
      await this.configService.showToast("success", data.message);
    }
  }
}
