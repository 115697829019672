import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-svcsearch',
  templateUrl: './global-svcsearch.component.html',
  styleUrls: ['./global-svcsearch.component.scss']
})
export class GlobalSVCSearchComponent implements OnInit {
  @Input() data: any;
  public query: any;
  public type = "SS";
  public hideDefaultTemplates = true;
  public department = ["Sales", "KAM Sales"];
  public disable: any = [];
  public cart = false;
  public svcDataOption = false;
  public showScannerOption = false;
  public default = {
    criteria: true,
    search: true,
    shape: true,
    carat: true,
    color: true,
    colorOverride: false,
    clarity: true,
    clarityOverride: false,
    description: true,
    finishing: true,
    fluorescence: true,
    lab: true,
    size: false,
    internalStatus: true,
    externalStatus: true,
    transType: false,
    users: true,
    location: true,
    box: true,
    department: true,
    standardShapeCode: true,
    saveSearch: true,
    download: true,
    fm: true,
    party: true,
    availableForSale: false,
    certiDate: false,
    inwardDate: false,
    transactionDate: true
  };

  constructor() { }

  ngOnInit() {
    let name = [];
    let departmentName = [];
    let internalStatus = [];
    departmentName = ["Sales", "KAM Sales"];
    // internalStatus = ["In Stock"]
    // internalStatus = ["In Stock","Hold Customer"]
    name.push(this.type);
    this.disable.push("transType");
    // this.disable.push("internalStatus")
    this.disable.push("department");
    this.query = {
      //   facetFilters: ["transType:" + [this.type], "department:" + [this.department]],
      disjunctiveFacetsRefinements: {
        transType: name,
        department: departmentName,
        // internalStatus: internalStatus
      }
    };
  }
}

