import { Component, Input, OnInit } from '@angular/core';
import { ProcessActionComponent } from '../../processAction';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-website-last-signin-detail',
  templateUrl: './website-last-signin-detail.component.html',
  styleUrls: ['./website-last-signin-detail.component.scss']
})
export class WebsiteLastSigninDetailComponent implements ProcessActionComponent {
  @Input() data: any;

  websiteDashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://mixpanel.com/p/862sBF8g3oGeGQbng8rqFX');
  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

}
