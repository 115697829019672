// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
let fromLocation = 'Mumbai'
if (
  window &&
  "location" in window &&
  "protocol" in window.location &&
  "pathname" in window.location &&
  "host" in window.location
) {
  fromLocation = window.location.hostname.split(".")[0];
}

if (fromLocation == 'localhost') {
  fromLocation = 'Mumbai'
}

export const environment = {
  production: false,
  hosts: [
    {
      host: 'search.lattice-dev.atishae.com',
      auth: 'admin:admin',
      protocol: 'https',
      port: 443
    }
  ],
  RESULTS_PER_PAGE: 100,
  INDEX: "kglattice",
  INDEX1: "kgpurchaseconsolidation",
  INDEX2: "converttomix",
  fromLocation: fromLocation || 'Mumbai',
  mode: 'Dev',
  appName: fromLocation == 'trybe' ? 'trybe' : 'lattice',
  s3Credentials: {
    accessKeyId: 'AKIASQAR4AJ62CGTU257',
    secretAccessKey: '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',
    region: 'ap-south-1',
  },
  sentryDSN: 'https://51281c757debd585756f405549983fdb@o4506653997989888.ingest.sentry.io/4506654001201152'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
