import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import {
  NavController,
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";
import { IonicSelectableComponent } from 'ionic-selectable';
import * as moment from "moment";


@Component({
  selector: 'app-purchase-bulk-uploader',
  templateUrl: './purchase-bulk-uploader.component.html',
  styleUrls: ['./purchase-bulk-uploader.component.scss']
})
export class PurchaseBulkUploaderComponent implements ProcessActionComponent {
  @ViewChild('selectableComponent') selectableComponent: IonicSelectableComponent;
  @Input() data: any;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  public listOfParties = []
  public partyFile: any;
  public partyMasterId: any;
  public partyName: any;
  public selectedParty = ''
  public pageSize: any = 50;
  public uploadDate = moment().format("YYYY-MM-DD")
  listOfStoneDataSource: any = [];
  pageNumber = 0;
  noOfRecords = 15;
  columns = [];
  public refreshGrid: Boolean = false;

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events) { }

  ngOnInit() {
    this.loadUploadHistory();
    this.columns = [
      { name: "File Name", prop: "fileName" },
      { name: "Status", prop: "status" },
      { name: "By", prop: "username" },
      { name: "Date & Time", prop: "createdDate" }
    ];
    this.event.subscribe('Party Stock Uploader', (item) => {
      this.columns = [
        { name: "File Name", prop: "fileName" },
        { name: "Status", prop: "status" },
        { name: "By", prop: "username" },
        { name: "Date & Time", prop: "createdDate" }
      ];
      this.loadUploadHistory();
      this.reset()
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Party Stock Uploader');
  }

  async getAllCustomer(pageNumber, noOfRecords, searchText) {
    let res: any;
    let json = {
      listOfType: ['Local Purchase']
    }
    res = await this.httpApiService.findByListOfPartyTypePagination(json, 'Client', pageNumber, noOfRecords, searchText, true);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValueList = data.data.content;
      if (dataValueList.length > 0) {
        for await (let item of dataValueList) {
          item.partyNameWithType = `${item.partyName} ( ${item.type} )`
        }
        this.listOfParties = dataValueList;
      } else {
        this.listOfParties = []
      }
    }
  }

  onPFileSelected(event) {
    this.partyFile = <File>event.target.files[0];
  }

  async searchParty(event: { component: IonicSelectableComponent; text: string }) {
    console.log(event)
    this.pageNumber = 0;
    let searchText = event.text.trim().toLowerCase();
    await this.getAllCustomer(this.pageNumber, this.noOfRecords, searchText);
  }

  onChangeParty(event: { component: IonicSelectableComponent; value: any }) {
    console.log("port:", event.value);
    if (!!event.value) {
      this.partyMasterId = event.value.id
      this.partyName = event.value.partyName
    } else {
      this.partyMasterId = null;
      this.partyName = null;
    }
  }

  async upload() {
    // const formData: FormData = new FormData();
    let formData = new FormData();
    formData.append("file", this.partyFile);
    formData.append("partyName", this.partyName);
    // formData.append("file", this.partyFile);
    formData.append("partyMasterId", this.partyMasterId);
    formData.append("date", moment(this.uploadDate).format("YYYY-MM-DD"));

    await this.configService.showLoader();
    console.log(formData);
    let res: any;
    res = await this.httpApiService.uploadPurchaseFile(formData);
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.reset()
      this.uploadDate = moment().format("YYYY-MM-DD")
      await this.loadUploadHistory();
    }
  }


  reset() {
    this.partyFile = null;
    this.partyMasterId = null;
    this.partyName = null;
    this.selectedParty = null;
    this.myInputVariable.nativeElement.value = "";
  }

  async loadUploadHistory() {
    this.listOfStoneDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getUploadHistory(
            'Purchase Party File',
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: [],
            totalCount: 0,
            groupCount: 0
          }
        }
      }
    });
  }

  async downloadUploadedFile(file) {
    console.log(file)
    let res: any;
    if (!!file.url) {
      window.open(file.url, '_blank')
    }
  }

}
