import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-stone-with-me-single',
  templateUrl: './stone-with-me-single.component.html',
  styleUrls: ['./stone-with-me-single.component.scss']
})
export class StoneWithMeSingleComponent implements ProcessActionComponent {
  @Input() data: any;
  public query: any;
  public type = "SS";
  public cart = true;
  public disable: any = [];
  public withMe: Boolean = true;
  public department = ["Sales", "KAM Sales"];
  public default = {
    criteria: true,
    search: true,
    shape: true,
    carat: true,
    color: true,
    colorOverride: false,
    clarity: true,
    clarityOverride: false,
    description: true,
    finishing: true,
    fluorescence: true,
    lab: true,
    size: false,
    internalStatus: true,
    externalStatus: true,
    transType: false,
    users: true,
    location: true,
    box: true,
    department: true,
    saveSearch: true,
    download: true,
    party:true,
    certiDate: false,
    inwardDate: true,
    transactionDate: true,
    standardShapeCode: true
  };

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
  ) { }

  async ngOnInit() {
    await this.getUserData();
  }

  getUserData() {
    let transType = [];
    let departmentName = [];
    transType.push(this.type)
    departmentName = ["Sales", "KAM Sales"];
    this.disable.push("username");
    this.disable.push("transType");
    this.query = {
      disjunctiveFacetsRefinements: { transType: transType, department: departmentName, }
    };
  }

}
