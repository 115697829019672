export default {
    reportNo: 'R-28',
    name: 'rapnentReport',
    url: "",
    method: "GET",
    size: 3,
    fields: [
    ],
    columns: [
    ],
    dataStudioLink: ''
  };