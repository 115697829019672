import { Component, Input, OnInit } from '@angular/core';
import { ProcessActionComponent } from '../../processAction';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.scss']
})
export class SalesDashboardComponent implements OnInit {
  @Input() data: any;

  constructor(public sanitizer: DomSanitizer) { }
  dataStudioLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://lookerstudio.google.com/embed/reporting/90d2ec41-2c44-41ae-93b6-d203a6079957/page/p_elta3opw8c');
  ngOnInit() {
  }

}
