import { Component } from '@angular/core';

import { Platform, AlertController, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './services/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from './services/theme/theme.service';
import { Storage } from '@ionic/storage';
import { SseService } from "./services/sse.service";
import { ConfigService } from "./services/config/config.service";
import { ProcessService } from "./services/process/process.service";
import { UploadService } from './services/upload_service';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from 'src/environments/environment';

const themes = {
  autumn: {
    primary: '#F78154',
    secondary: '#4D9078',
    tertiary: '#B4436C',
    light: '#FDE8DF',
    medium: '#FCD0A2',
    dark: '#B89876'
  },
  night: {
    primary: '#8CBA80',
    secondary: '#FCFF6C',
    tertiary: '#FE5F55',
    medium: '#BCC2C7',
    dark: '#F7F7FF',
    light: '#495867'
  },
  neon: {
    primary: '#39BFBD',
    secondary: '#4CE0B3',
    tertiary: '#FF5E79',
    light: '#F4EDF2',
    medium: '#B682A5',
    dark: '#34162A'
  },
  dark: {
    primary: '#eeae15',
    secondary: '#007ACC',
    medium: '#f43e00',
    light: '#bdc3c7',
    dark: '#d35400',
    tertiary: '#F39C12',
  },
  defaults: {
    primary: '#3880ff',
    secondary: '#0cd1e8',
    tertiary: '#7044ff',
    success: '#10dc60',
    warning: '#ffce00',
    danger: '#f04141',
    dark: '#222428',
    medium: '#989aa2',
    light: '#ffffff'
  }
};
const TOKEN_KEY = "auth-token";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public changePasswordPage = false;
  public baseUrl: any;
  public alertPresented: any;
  public event: Events;
  themes: Array<{ title: string, theme: string }>;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    private theme: ThemeService,
    private storage: Storage,
    public alertCtrl: AlertController,
    private sseService: SseService,
    public configService: ConfigService,
    private processService: ProcessService,
    private uploadService: UploadService
  ) {
    this.alertPresented = false;
    this.initializeApp();
    this.getBaseUrl()
    //used to change the theme of the app, commented to keep the default
    this.changeTheme('defaults');
    var thiss = this
    window.addEventListener('focus', () => {
      thiss.storage.get(TOKEN_KEY).then(res => {
        if (!res) {
          thiss.authenticationService.logout()
        } else {
          if (window.location.pathname == '/login') {
            thiss.authenticationService.authStateSet()
            // thiss.router.navigate(['app', 'dashboard']);
          }
        }
      });
    });

    this.authenticationService.authenticationState.subscribe(state => {
      console.log("App", state)
      this.changePasswordPage = false;
      if (window && "location" in window
        && "protocol" in window.location
        && "pathname" in window.location
        && "host" in window.location) {
        if (window.location.href.toString().includes('changePassword')) {
          this.changePasswordPage = true;
        }
      }
      if (state && !this.changePasswordPage) {
        this.subscribeToSSE()
        if (window.location.pathname.split('/').pop() != 'Inventory%20Manager') {
          this.router.navigate(['app', 'dashboard']);
        } else {
          this.redirectToAction()
        }
      } else if (!this.changePasswordPage) {
        this.router.navigate(['login']);
      }
    });
  }

  async redirectToAction() {
    let listOfActions = await this.storage.get("listOfActions")
    if (!!listOfActions) {
      let actionExist = listOfActions.find(action => action.actionName == 'Inventory Manager');
      if (actionExist) {
        this.processService.setActionName(
          actionExist.actionName,
          actionExist.id,
          actionExist.voucherType,
          actionExist.listOfActionPermissions,
        );
        setTimeout(() => {
          this.router.navigate(['app', 'actionsWindow'])
        }, 500)
      }
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      let s3Object = new S3(
        environment.s3Credentials
    
      )
      this.uploadService.uploadInit(s3Object)
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });


  }

  async getBaseUrl() {
    this.baseUrl = await this.configService.getBaseUrl();
  }

  changeTheme(name) {
    this.theme.setTheme(themes[name]);
  }

  changeSpeed(val) {
    this.theme.setVariable('--speed', `${val}ms`);
  }

  async subscribeToSSE() {
    let userData: any;
    let checkForScreenLock = false;
    let baseUrl = await this.configService.getBaseUrl();
    this.storage.get('userData').then(res => {
      userData = res;
      if (userData) {
        if (!!userData && userData.departmentName == 'KAM Sales') {
          checkForScreenLock = true;
        }
        this.sseService.getServerSentEvent(baseUrl + "master-service/api/sse/subscribe?userId=" + userData.userId + "&checkForScreenLock=" + checkForScreenLock, userData.userId)
          .subscribe(data => {
            console.log(data)
            if (!!data.type && data.type == 'message') {
              this.manageNotification(data)
            } else if (!!data.type && data.type == 'lock') {
              this.lockPopup(data)
            } else if (!!data.type && data.type == 'TemplateDownload') {
              if (!!data.data && !data.data.includes('.com')) {
                if (document.visibilityState == 'visible')
                  window.open(baseUrl + data.data, '')
              } else {
                if (document.visibilityState == 'visible')
                  window.open(data.data, '')
              }
              this.manageNotification(data)
            }
          })
      }
    })
  }

  async manageNotification(data) {
    let baseUrl = await this.configService.getBaseUrl();
    var option = {
      requireInteraction: true,
      tag: 'soManyNotification',
      // icon: '/home/keval/keval/Lattice/lattice/apps/desktop/lattice/src/assets/logo/symbol.jpg'
    }
    if (Notification && Notification.permission === "granted") {
      console.log(Notification, 'granted')
      var i = 0;
      var interval = window.setTimeout(function () {
        // Thanks to the tag, we should only see the "Hi! 9" notification 

        if (data.type == 'TemplateDownload') {
          var n = new Notification('Click to download latest template', option);
          n.onclick = function (event) {
            event.preventDefault();
            if (!!data.data && !data.data.includes('.com')) {
              window.open(baseUrl + data.data, '')
            } else {
              window.open(data.data, '')
            }

            n.close();
          }
        } else {
          var n = new Notification(data.data, option);
        }

        if (i++ == 9) {
          window.clearInterval(interval);
        }
      }, 200);

    } else if (Notification && Notification.permission !== "denied") {
      console.log(Notification, 'denied')
      Notification.requestPermission(function (status) {
        if (status === "granted") {
          var i = 0;
          var interval = window.setTimeout(function () {
            if (data.type == 'TemplateDownload') {
              var n = new Notification('Click to download latest template', option);
              n.onclick = function (event) {
                event.preventDefault();
                if (!!data.data && data.data.includes('.com')) {
                  window.open(baseUrl + data.data, '')
                } else {
                  window.open(data.data, '')
                }
              }
            } else {
              var n = new Notification(data.data, option);
            }
            if (i++ == 9) {
              window.clearInterval(interval);
            }
          }, 200);
        }
        else {
        }
      });
    }
    else {
      console.log(Notification, 'else')
      // alert("Hi!");
    }
  }


  async lockPopup(data) {
    let listOfActions = await this.storage.get("listOfActions");
    let actionExist = listOfActions.filter(function (action) {
      if (action.actionName == 'Inventory Manager') {
        return action;
      }
    });
    if (actionExist.length > 0) {
      if (!this.alertPresented) {
        this.alertPresented = true;
        const alert = await this.alertCtrl.create({
          header: "Warning!",
          message: "Please go to incoming tab of Inventory Manager action and receive incoming stones first.",
          backdropDismiss: true,
          cssClass: 'alertModal',
          buttons: [
            {
              text: "Go To Inventory Manager ",
              handler: () => {
                window.open(window.location.hostname + "/app/dashboard/Inventory Manager", '_blank')
                this.alertCtrl.dismiss()
                // this.router.navigate(['app', 'dashboard']);
                // this.alertPresented = false;
                // this.processService.setActionName(
                //   actionExist[0].actionName,
                //   actionExist[0].id,
                //   actionExist[0].voucherType,
                //   actionExist[0].listOfActionPermissions,
                // );
                // setTimeout(() => {
                //   this.router.navigate(['app', 'actionsWindow'])
                // }, 1000)
              }
            }
          ]
        });
        await alert.present();
      }
    }
  }
}
