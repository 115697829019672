export default {
  reportNo: 'R-17',
  url: "master-service/api/report/assortment-report-mix",
  method: "GET",
  size: 3,
  fields: [
    {
      title: ' From Date ',
      input: 'dateTime',
      key: 'fromDate',
      placeholder: 'Select From Date',
      property: 'fromDate',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
    {
      title: ' To Date ',
      input: 'dateTime',
      key: 'toDate',
      placeholder: 'Select To Date',
      property: 'toDate',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
  ],
  columns: [
    { name: "Inward Date", prop: "inwDate" },
    // { name: "Invoice No", prop: "invoiceNo" },
    { name: "FACTORY_OUTW_DATE", prop: "factoryOutWDate" },
    { name: "Yearmonth", prop: "yearmonth" },
    { name: "FACTORY_OUTW_NO", prop: "factoryOutWNo" },
    { name: "FACTORY_SEQNO", prop: "factorySeqNo" },
    { name: "Lotno", prop: "lotNo" },
    { name: "Factory Pktno", prop: "pktNo" },
    { name: "Barcode", prop: "barcode" },
    { name: "color", prop: "color" },
    { name: "Cts", prop: "cts" },
    { name: "Clarity", prop: "clarity" },
    { name: "Inward CSSP", prop: "inwardCSSP" },
    { name: "Inward Rate", prop: "inwardRate" },
    { name: "Assort Color", prop: "assortColor" },
    { name: "Assort Clarity", prop: "assortClarity" },
    { name: "Assort CSSP", prop: "assortCSSP" },
    { name: "Assort Rate", prop: "price" },
    { name: "Assort Date", prop: "assortDate" },
    { name: "Assort By", prop: "assortBy" },
    { name: "AVG_PRICE", prop: "price" },
    { name: "Assort Rate", prop: "assortRate" },
    { name: "Assort By", prop: "assortBy" },
    { name: "AVG_PRICE", prop: "avgPrice" },
    { name: "Is Locked", prop: "locked" },
  ]
};
