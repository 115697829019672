import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: 'app-rapaport-price-list-uploader',
  templateUrl: './rapaport-price-list-uploader.component.html',
  styleUrls: ['./rapaport-price-list-uploader.component.scss']
})
export class RapaportPriceListUploaderComponent implements ProcessActionComponent {
  @Input() data: any;

  public priceName = 'RAPAPORT';
  public isDisable = true;
  public listOfActionPermissions = []
  public actionName: any;

  constructor() { }

  ngOnInit() {
    this.listOfActionPermissions = this.data.listOfActionPermissions
    this.actionName = this.data.desc;
    console.log(this.actionName)
  }

}
