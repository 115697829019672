import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import {
  ModalController,
  ToastController,
  LoadingController,
  Events
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { longStackSupport } from "q";

@Component({
  selector: 'app-pd-purchase-stones',
  templateUrl: './pd-purchase-stones.component.html',
  styleUrls: ['./pd-purchase-stones.component.scss']
})
export class PdPurchaseStonesComponent implements ProcessActionComponent {
  @Input() data: any;
  public stoneList = [];
  public stoneIdList = [];
  public stoneParameter = [];
  public stoneId: any;
  public pageNumber = 0;
  public pValues: any;
  public noOfRecords: Number = 100;
  public paramList = [];
  public singleParameter: any;
  public showPopover = false;
  public selectedValuesArray = [];
  public paramIndex = 0;
  public totalParam: any;
  public pdObject = {};
  public isDisable = true;
  public SSParamList = []

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public toastCtrl: ToastController,
    public configService: ConfigService,
    public event: Events) { }

  ngOnInit() {
    this.inStockStone();
    this.singleStoneParameters();
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "stoneParam") {
      if (idList.length > 0) {
        this.stoneId = obj[0].stoneId;
        this.isDisable = true;
        this.cloneData()
        this.getPdParamsByStoneId(this.stoneId)
      } else {
        this.isDisable = true;
        this.stoneId = null;
        this.selectedValuesArray = []
      }
    }
  }

  async singleStoneParameters() {
    let res = await this.httpApiService.getAllParametersForSingleStone('SS');
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      let data1 = []
      for (let i = 0; i < dataList.length; i++) {
        if (!!dataList[i].nameGroup) {
          if (data1.length > 0) {
            let flag = data1.some(el => el.nameGroup === dataList[i].nameGroup);
            if (!flag) {
              data1.push({
                id: dataList[i].id, nameGroup: dataList[i].nameGroup, isGroup: true, companyId: dataList[i].companyId,
                downTolerance: dataList[i].companyId, masterName: dataList[i].masterName, paramMasterId: dataList[i].paramMasterId, subParameters: []
              })
            }
          } else {
            data1.push({
              id: dataList[i].id, nameGroup: dataList[i].nameGroup, isGroup: true, companyId: dataList[i].companyId,
              downTolerance: dataList[i].companyId, masterName: dataList[i].masterName, paramMasterId: dataList[i].paramMasterId, subParameters: []
            })
          }
        } else {
          let isValueGroup: any;
          let valueGroup1: any;
          if (dataList[i].parameterValues.length > 0) {
            dataList[i].parameterValues.forEach(el => {
              if (!!el.valueGroup) {
                valueGroup1 = el.valueGroup;
              }
            })
            let flag: Boolean;
            dataList[i].parameterValues.filter(function (item) {
              if (item.valueGroup != valueGroup1) {
                flag = true;
                return true;
              }
              return
            });
            if (!!flag && flag == true) {
              isValueGroup = true
            } else {
              isValueGroup = false
            }
          }
          data1.push({
            id: dataList[i].id, nameGroup: dataList[i].masterName, isValueGroup: isValueGroup, isGroup: false, companyId: dataList[i].companyId,
            downTolerance: dataList[i].companyId, masterName: dataList[i].masterName, paramMasterId: dataList[i].paramMasterId, parameterValues: dataList[i].parameterValues
          })
        }
      }


      for (let i = 0; i < data1.length; i++) {
        if (data1[i].isGroup == true) {
          data1[i].totalsubParamList = []
          dataList.forEach(el => {
            if (el.nameGroup == data1[i].nameGroup) {
              data1[i].subParameters.push(el)
            }
          })
          data1[i].subParameters.forEach((el) => {
            data1[i].totalsubParamList.push(el.masterName)
          })
        }
      }

      this.SSParamList = data1;
      this.stoneParameter = JSON.parse(JSON.stringify(this.SSParamList));
      console.log(this.stoneParameter)
    }
  }

  async getPdParamsByStoneId(stoneId) {
    let res: any;
    res = await this.httpApiService.getPdPurchaseParamsByStoneId(stoneId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data
      if (dataValue.length > 0) {
        this.isDisable = false;
        console.log(dataValue)
        dataValue.forEach(el => {
          let valueList = el.paramValueIds.split(',').map(Number);
          for (let i = 0; i < this.stoneParameter.length; i++) {
            if (this.stoneParameter[i].isGroup == true) {
              this.stoneParameter[i].subParameters.forEach(subparamEl => {
                if (subparamEl.masterName == el.paramName) {
                  let valueIdList = []
                  subparamEl.parameterValues.forEach(item => {
                    let flag = valueList.some(val => val == item.id);
                    console.log("1", valueList, item.id, item.code, flag)
                    if (flag) {
                      item.isSelected = true;
                      valueIdList.push(item.id)
                      this.pdObject[subparamEl.masterName] = valueIdList
                    } else {
                      item.isSelected = false;
                    }
                  })
                }
              })
            } else {
              if (el.paramName == this.stoneParameter[i].nameGroup) {
                let valueIdList = []
                this.stoneParameter[i].parameterValues.forEach(item => {
                  let flag = valueList.some(val => val == item.id);
                  console.log("2", valueList, item.id, item.code, flag)
                  if (flag) {
                    item.isSelected = true;
                    valueIdList.push(item.id)
                    this.pdObject[this.stoneParameter[i].masterName] = valueIdList
                  } else {
                    item.isSelected = false;
                  }
                })
              }
            }
          }
        })
        console.log(this.stoneParameter, this.pdObject)
      }
    }
  }

  cloneData() {
    this.stoneParameter = []
    this.pdObject = {}
    this.stoneParameter = JSON.parse(JSON.stringify(this.SSParamList));
  }

  async inStockStone() {
    let res: any;
    res = await this.httpApiService.getStockStone();
    let data = res.json();
    console.log(data)
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      let data1 = []
      if (dataList.length > 0) {
        dataList.forEach(el => {
          console.log
          if (el.transType == 'SS') {
            data1.push(el)
          }
        })
        console.log(data1)
        this.stoneList = data1
      }
      console.log(this.stoneList)
    }
  }

  //select sub parameter value

  async selectedPDParameterValue(parameters, sub, stones, index) {
    console.log(parameters, sub, stones, index)
    if (parameters.isGroup == true) {
      if ((!!stones[index].code && stones[index].code.toLowerCase() == 'non') || stones[index].name.toLowerCase() == 'none') {
        stones.forEach(element => {
          element.isSelected = false;
        });
        stones[index].isSelected = true;
        parameters.isValueGroup = false;
      } else {
        stones.forEach((el, i) => {
          if (!!el.code && el.code.toLowerCase() == 'non' || el.name.toLowerCase() == 'none') {
            stones[i].isSelected = false;
          }
        })
        parameters.isValueGroup = false;
        stones.forEach((el, i) => {
          if (!!el.valueGroup && el.valueGroup == stones[index].valueGroup) {
            stones[i].isSelected = false;
            stones[index].isSelected = true;
          } else if (!!el.valueGroup && el.valueGroup != stones[index].valueGroup) {
            stones[index].isSelected = true;
          }
        })
      }
    } else {
      if (parameters.isValueGroup && parameters.isValueGroup == true) {
        if ((!!stones[index].code && stones[index].code.toLowerCase() == 'non') || stones[index].name.toLowerCase() == 'none') {
          stones.forEach(element => {
            element.isSelected = false;
          });
          stones[index].isSelected = true;
        } else {
          stones.forEach((el, i) => {
            if (!!el.code && el.code.toLowerCase() == 'non' || el.name.toLowerCase() == 'none') {
              stones[i].isSelected = false;
            }
          })
          stones.forEach((el, i) => {
            if (!!el.valueGroup && el.valueGroup == stones[index].valueGroup) {
              stones[i].isSelected = false;
              stones[index].isSelected = true;
            } else if (!!el.valueGroup && el.valueGroup != stones[index].valueGroup) {
              stones[index].isSelected = true;
            }
          })
        }
      } else {
        stones.forEach(element => {
          element.isSelected = false;
        });
        stones[index].isSelected = true;
      }
    }
    if (!!parameters.isValueGroup && parameters.isValueGroup == true && parameters.isGroup == true) {
      let paramList = parameters.subParameters;
      for (let i = 0; i < paramList.length; i++) {
        let valueIdList = [];
        let valueList = paramList[i].parameterValues;
        for (let j = 0; j < valueList.length; j++) {
          if (!!valueList[j].isSelected && valueList[j].isSelected == true) {
            valueIdList.push(valueList[j].id)
          }
        }
        this.pdObject[paramList[i].masterName] = valueIdList;
      }
    } if (parameters.isValueGroup == false && parameters.isGroup == true) {
      let paramList = parameters.subParameters;
      console.log(paramList, "1")
      for (let i = 0; i < paramList.length; i++) {
        let valueIdList = [];
        let valueList = paramList[i].parameterValues;
        for (let j = 0; j < valueList.length; j++) {
          if (!!valueList[j].isSelected && valueList[j].isSelected == true) {
            valueIdList.push(valueList[j].id)
          }
        }
        this.pdObject[paramList[i].masterName] = valueIdList;
      }
    } else if (parameters.isGroup == false && parameters.isValueGroup == true) {
      let paramList = stones;
      console.log(paramList, "2")
      let valueIdList = [];
      for (let i = 0; i < paramList.length; i++) {
        if (paramList[i].isSelected == true) {
          valueIdList.push(paramList[i].id)
        }
      }
      this.pdObject[parameters.nameGroup] = valueIdList;
    } else if (parameters.isGroup == false && parameters.isValueGroup == false) {
      let paramList = stones;
      let valueIdList = [];
      for (let i = 0; i < paramList.length; i++) {
        if (paramList[i].isSelected == true) {
          valueIdList.push(paramList[i].id)
        }
      }
      this.pdObject[parameters.nameGroup] = valueIdList;
    }
    console.log(this.pdObject, this.stoneParameter);

    if (Object.keys(this.pdObject).length > 0) {
      let pdKeyArray = []
      pdKeyArray = Object.keys(this.pdObject)

      this.stoneParameter.forEach(el => {
        if (el.isGroup == true) {
          if (el.totalsubParamList.length > 0) {
            let test = true;
            el.totalsubParamList.forEach(item => {
              for (let i = 0; i < pdKeyArray.length && test; i++) {
                if (pdKeyArray[i] == item) {
                  if (this.pdObject[pdKeyArray[i]].length > 0) {
                    el.isSelected = true;
                  } else {
                    el.isSelected = false;
                    test = false
                  }
                }
              }
            })
          }
        } else {
          for (let key in this.pdObject) {
            if (el.masterName == key) {
              if (this.pdObject[key].length > 0) {
                el.isSelected = true;
              } else {
                el.isSelected = false;
              }
            }
          }
        }
      })
    }
    let list = this.stoneParameter.filter(el => {
      if (!el.isSelected && el.isSelected != true) {
        return true;
      }
    })
    if (list.length > 0) {
      this.isDisable = true
    } else {
      this.isDisable = false
    }
  }



  async savePD() {
    console.log(this.selectedValuesArray)
    await this.configService.showLoader()
    let saveStoneObj = {
      stoneId: this.stoneId,
      pdValues: this.pdObject
    };
    console.log(saveStoneObj)
    let res: any;
    res = await this.httpApiService.savePdPurchseStoneResult(saveStoneObj)
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.pdObject = {}
      this.stoneIdList = []
      this.isDisable = true
      this.stoneId = null;
      this.cloneData()
      await this.configService.showToast("success", data.message);
    }
  }

  Fetch(){
    this.getPdParamsByStoneId(this.stoneId)
  }
}