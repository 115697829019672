import { Component, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  NavParams,
  ToastController,
  LoadingController,
  Events
} from "@ionic/angular";

@Component({
  selector: 'app-manage-filter',
  templateUrl: './manage-filter.component.html',
  styleUrls: ['./manage-filter.component.scss']
})
export class ManageFilterComponent implements OnInit {

  status: any;
  statusSelected: any;
  publish: [string, string];
  publishDate: [Date, Date];
  search: string;
  form_publish: String = '';
  form_status: String = '';
  form_search: String = '';

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams
  ) {

    this.search = this.navParams.get("search");
    this.statusSelected = this.navParams.get("statusSelected");
    if (this.statusSelected) {
      this.status = [{ "id": 1, "name": "Modified" }, { "id": 2, "name": "Processing" }, { "id": 3, "name": "Ready for Use" }];
    }
    this.publish = this.navParams.get("publish");
    this.publishDate = this.navParams.get("publishDate");
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  dropDownSelection(ev) {
    this.form_status = ev.arrayList;
  }

  AddPolicy() {
    this.modalCtrl.dismiss({
      publish: this.form_publish,
      status: this.form_status,
      search: this.form_search,
      publishDate: this.publishDate
    });
  }
}
