export default {
  reportNo: 'R-18',
  url: "master-service/api/report/assortment-report-mix",
  method: "GET",
  size: 3,
  fields: [
  ],
  columns: [
  ],
  dataStudioLink: 'https://lookerstudio.google.com/embed/reporting/185d648c-0d98-4ca0-944c-f05b1323b7ad/page/T7VHD',
  newDataStudioLink: 'https://lookerstudio.google.com/embed/reporting/75a2839b-58be-41c6-a2ce-1fbf256ddf17/page/wHBkD'
};