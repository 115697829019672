import { Component, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-manage-skip-assortment',
  templateUrl: './manage-skip-assortment.page.html',
  styleUrls: ['./manage-skip-assortment.page.scss'],
})
export class ManageSkipAssortmentPage implements OnInit {

  public copy = [];
  public name: any;
  public fileType: any;
  public flagAddUpdate: any;
  public item: any;
  public id: any;
  public isActive: Boolean = true;
  public criteria = {
    fromShape: null,
    toShape: null,
    fromColor: null,
    toColor: null,
    fromCts: null,
    toCts: null,
    fromClarity: null,
    toClarity: null,
    name: null,
    transType: null,
    isActive: true
  };
  public criteriaList: any = {};
  public transTypeList = [];
  public transTypeIdList = [];

  public colorList = []
  public clarityList = []
  public shapeList = []


  fromShapeIdList = []
  toShapeIdList = []
  fromColorIdList = []
  toColorIdList = []
  fromClarityIdList = []
  toClarityIdList = []

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) { }

  async ngOnInit() {
    let data = this.navParams.get('item');
    this.getAllTransType()
    await this.findPolicyCriteriaParameters()
    this.flagAddUpdate = this.navParams.get('flag');
    if (this.flagAddUpdate == 'update') {
      this.item = data;
      this.id = this.item.id
      this.criteria.name = this.item.name;
      this.isActive = this.item.isActive
      this.criteria.fromCts = this.item.fromCts;
      this.criteria.toCts = this.item.toCts;
      this.criteria.fromColor = this.item.fromColor;
      this.criteria.toColor = this.item.toColor;
      this.criteria.fromShape = this.item.fromShape;
      this.criteria.toShape = this.item.toShape;
      this.criteria.fromClarity = this.item.fromClarity;
      this.criteria.toClarity = this.item.toClarity;
      this.criteria.transType = this.item.transType;

      this.transTypeIdList = [{ id: this.item.transType, name: this.item.transTypeCode }]
      this.fromColorIdList = [{ id: this.item.fromColor, name: this.item.fromColorName }]
      this.toColorIdList = [{ id: this.item.toColor, name: this.item.toColorName }]
      this.fromShapeIdList = [{ id: this.item.fromShape, name: this.item.fromShapeName }]
      this.toShapeIdList = [{ id: this.item.toShape, name: this.item.toShapeName }]
      this.fromClarityIdList = [{ id: this.item.fromClarity, name: this.item.fromClarityName }]
      this.toClarityIdList = [{ id: this.item.toClarity, name: this.item.toClarityName }]

      this.colorList = this.criteriaList.Color.filter(item => item.transTypeName == this.item.transTypeCode || !item.transTypeName);
          this.shapeList = this.criteriaList.Shape.filter(item => item.transTypeName == this.item.transTypeCode || !item.transTypeName);
          this.clarityList = this.criteriaList.Clarity.filter(item => item.transTypeName == this.item.transTypeCode || !item.transTypeName);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss()
  }

  dropDownSelection(ev) {
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    if (idList.length > 0) {

      this.criteria[property] = idList[0];
      if (property == 'transType') {
        if (idList.length > 0) {
          this.colorList = this.criteriaList.Color.filter(item => item.transTypeName == obj[0].name || !item.transTypeName);
          this.shapeList = this.criteriaList.Shape.filter(item => item.transTypeName == obj[0].name || !item.transTypeName);
          this.clarityList = this.criteriaList.Clarity.filter(item => item.transTypeName == obj[0].name || !item.transTypeName);
        } else {
          this.colorList = this.criteriaList.Color;
          this.shapeList = this.criteriaList.Shape;
          this.clarityList = this.criteriaList.Clarity;
        }

      } else {

      }
    } else {
      delete this.criteria[property];
    }
    console.log(this.criteria)
  }

  async findPolicyCriteriaParameters() {
    let res: any;
    res = await this.httpApiService.findPolicyCriteriaParameters();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.criteriaList = data.data;
    }
  }

  async addCriteria() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.addSkipAssortmentCriteria(this.criteria);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.transTypeList = data.data;
    }
  }

  async updateCriteria() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.updateSkipAssortmentCriteria(this.criteria, this.id);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async validateControls() {
    if (Object.keys(this.criteria).length >= 10) {
      return true;
    } else {
      await this.configService.showToast('error', 'Please enter the details')
      return false;
    }

  }

}
