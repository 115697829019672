import { Component, OnInit, Input } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-rapaport-parameter-mapping-master',
  templateUrl: './rapaport-parameter-mapping-master.page.html',
  styleUrls: ['./rapaport-parameter-mapping-master.page.scss'],
})
export class RapaportParameterMappingMasterPage implements OnInit {

  // public copy = [];
  // public name: any;
  public flagAddUpdate: any;
  public item: any;
  public id: any;
  // public isDefault: any;
  // public listOfOption = [];
  // public type: any;
  // public labId: any;
  public paramId: any;
  public paramValueId: any;
  public masterParamValue: any;
  public selectedParamName: any;
  public mappingParamValue = ""
  public listOfParamValue = [];
  public paramValueIdList = [];
  public paramIdList = []
  public mappingParamValueIdList = [];
  public rapaportSizeList = []
  public kgSizeList = []
  public mappingList = []
  public listOfStoneParameter = [];
  public masterName: any;
  public rapaportSizeIdList = [];
  public kgIdList = []

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) {
  }

  closeModal() {
    this.modalCtrl.dismiss()
  }

  async ngOnInit() {
    await this.getAllParameterOfStones();
    this.paramIdList = []

    let data = this.navParams.get('item');
    // this.isDefault = this.navParams.get('isDefault');
    console.log(data)
    if (data != 0) {
      this.item = data;
      this.id = this.item.id;
      this.paramId = this.item.masterId;
      this.mappingParamValue = this.item.mapValue;
      this.paramIdList.push({ id: !!this.item.masterNameId ? this.item.masterNameId : 100, masterName: this.item.masterName })
      this.selectedParamName = this.item.masterName
      if (this.item.masterName != 'Size') {
        await this.getAllValueByParameterId(this.item.masterNameId)
      }
      if (this.item.masterName == 'Shape') {
        this.paramValueIdList = []
        this.mappingParamValueIdList = []
        this.mappingList = [{ id: 1, name: 'BR' }, { id: 2, name: 'PS' }]
        this.listOfParamValue.filter(el => {
          if (el.id == this.item.masterId) {
            this.paramValueIdList.push({ id: el.id, name: el.name })
          }
        })
        this.mappingList.filter(el => {
          if (el.name == this.item.mapValue) {
            this.mappingParamValueIdList.push({ id: el.id, name: el.name })
          }
        })
        console.log(this.paramValueIdList, this.mappingParamValueIdList)
      } else if (this.item.masterName == 'Size') {
        this.masterName = this.item.masterValue;
        this.rapaportSizeList = [{ id: 1, name: '5-05.99' }, { id: 2, name: '10-10.99' },
        { id: 3, name: '0.01-0.03' },
        { id: 4, name: '0.04-0.07' },
        { id: 5, name: '0.08-0.14' },
        { id: 6, name: '0.15-0.17' },
        { id: 7, name: '0.18-0.22' },
        { id: 8, name: '0.23-0.29' },
        { id: 9, name: '0.3-0.39' },
        { id: 10, name: '0.4-0.49' },
        { id: 11, name: '0.5-0.69' },
        { id: 12, name: '0.7-0.89' },
        { id: 13, name: '0.9-0.99' },
        { id: 14, name: '1-1.49' },
        { id: 15, name: '1.5-1.99' },
        { id: 16, name: '2-2.99' },
        { id: 17, name: '3-3.99' },
        { id: 18, name: '4-4.99' }]
          this.kgSizeList = [{ id: 1, name: '5-9.9999' }, { id: 2, name: '10-100' }, { id: 3, name: '0.01-0.0399' },
          { id: 4, name: '0.04-0.0799' },
          { id: 5, name: '0.08-0.1499' },
          { id: 6, name: '0.15-0.1799' },
          { id: 7, name: '0.18-0.2299' },
          { id: 8, name: '0.23-0.2999' },
          { id: 9, name: '0.3-0.3999' },
          { id: 10, name: '0.4-0.4999' },
          { id: 11, name: '0.5-0.6999' },
          { id: 12, name: '0.7-0.8999' },
          { id: 13, name: '0.9-0.9999' },
          { id: 14, name: '1-1.4999' },
          { id: 15, name: '1.5-1.9999' },
          { id: 16, name: '2-2.9999' },
          { id: 17, name: '3-3.9999' },
          { id: 18, name: '4-4.9999' },
          ]
        this.rapaportSizeIdList = []
        this.kgIdList = []
        this.rapaportSizeList.filter(el => {
          if (el.name == this.item.mapValue) {
            this.rapaportSizeIdList.push({ id: el.id, name: el.name })
          }
        })
        this.kgSizeList.filter(el => {
          if (el.name == this.item.masterValue) {
            this.kgIdList.push({ id: el.id, name: el.name })
          }
        })
      } else {
        this.paramValueIdList = []
        this.mappingParamValueIdList = []
        this.listOfParamValue.filter(el => {
          if (el.id == this.item.masterId) {
            this.paramValueIdList.push({ id: el.id, name: el.name })
          }
          if (this.item.mapValue == el.name) {
            this.mappingParamValueIdList.push({ id: el.id, name: el.name })
          }
        })
      }
    } else {

    }
    this.flagAddUpdate = this.navParams.get('flag');


  }

  dropDownSelection(ev) {
    console.log(ev)
    let idList = ev.idList;
    let property = ev.property;
    let obj = ev.arrayList;
    if (property == 'paramName') {
      this.paramId = null;
      this.paramValueIdList = []
      this.mappingParamValueIdList = [];
      this.mappingParamValue = null;
      if (idList.length > 0) {
        this.masterParamValue = '';
        this.selectedParamName = obj[0].masterName
        console.log(obj)
        this.getAllValueByParameterId(idList[0])
        if (obj[0].masterName == 'Shape') {
          this.mappingList = [{ id: 1, name: 'BR' }, { id: 2, name: 'PS' }]
        } else if (obj[0].masterName == 'Size') {
          this.rapaportSizeList = [{ id: 1, name: '5-05.99' }, { id: 2, name: '10-10.99' },
          { id: 3, name: '0.01-0.03' },
          { id: 4, name: '0.04-0.07' },
          { id: 5, name: '0.08-0.14' },
          { id: 6, name: '0.15-0.17' },
          { id: 7, name: '0.18-0.22' },
          { id: 8, name: '0.23-0.29' },
          { id: 9, name: '0.3-0.39' },
          { id: 10, name: '0.4-0.49' },
          { id: 11, name: '0.5-0.69' },
          { id: 12, name: '0.7-0.89' },
          { id: 13, name: '0.9-0.99' },
          { id: 14, name: '1-1.49' },
          { id: 15, name: '1.5-1.99' },
          { id: 16, name: '2-2.99' },
          { id: 17, name: '3-3.99' },
          { id: 18, name: '4-4.99' }]
          this.kgSizeList = [{ id: 1, name: '5-9.9999' }, { id: 2, name: '10-100' }, { id: 3, name: '0.01-0.0399' },
          { id: 4, name: '0.04-0.0799' },
          { id: 5, name: '0.08-0.1499' },
          { id: 6, name: '0.15-0.1799' },
          { id: 7, name: '0.18-0.2299' },
          { id: 8, name: '0.23-0.2999' },
          { id: 9, name: '0.3-0.3999' },
          { id: 10, name: '0.4-0.4999' },
          { id: 11, name: '0.5-0.6999' },
          { id: 12, name: '0.7-0.8999' },
          { id: 13, name: '0.9-0.9999' },
          { id: 14, name: '1-1.4999' },
          { id: 15, name: '1.5-1.9999' },
          { id: 16, name: '2-2.9999' },
          { id: 17, name: '3-3.9999' },
          { id: 18, name: '4-4.9999' },
          ]
        }
      } else {
        this.mappingParamValue = null;
        this.mappingParamValueIdList = []
        this.selectedParamName = null;
      }
    } else if (property == 'paramValue') {
      if (idList.length > 0) {
        this.paramId = idList[0];
      } else {
        this.paramId = null;
        this.paramValueIdList = []
      }
    } else if (property == 'mappingValue') {
      if (idList.length > 0) {
        this.mappingParamValue = obj[0].name;
      } else {
        this.mappingParamValue = null;
      }
    } else if (property == 'rapaportValue') {
      if (idList.length > 0) {
        this.mappingParamValue = obj[0].name;
      } else {
        this.mappingParamValue = null;
      }
    } else if (property == 'kgSize') {
      if (idList.length > 0) {
        this.masterName = obj[0].name;
        console.log("**", this.masterName)
      } else {
        this.masterName = null;
      }
    }
  }

  async getAllValueByParameterId(id) {
    let res: any;
    res = await this.httpApiService.getStoneMasterDetailById(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        let dataList = []
        data.data.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'SS') {
            dataList.push(el)
          }
        })
        this.listOfParamValue = dataList;
      }
    }
  }

  async getAllParameterOfStones() {
    let res: any;
    res = await this.httpApiService.getAllStoneMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let array = ["Color", "Clarity", "Shape"];
      console.log(data.data)
      this.listOfStoneParameter = data.data.filter(map => {
        let index = array.indexOf(map.masterName);
        return index !== -1 && map.masterName.toLocaleLowerCase() == array[index].toLocaleLowerCase()
      });
      console.log(this.listOfStoneParameter)
      this.listOfStoneParameter.push({ id: 100, masterName: 'Size' })
    }

  }

  async validateControls() {

    console.log(this.mappingParamValue, this.masterName)
    if (this.selectedParamName != 'Size') {
      if (
        !!this.paramId &&
        !!this.mappingParamValue && this.mappingParamValue != ""
      ) {
        return true;
      } else {
        await this.configService.showToast(
          "error",
          "Please select/insert all the information"
        );
        return false;
      }
    } else {
      if (
        !!this.masterName &&
        !!this.mappingParamValue && this.mappingParamValue != ""
      ) {
        return true;
      } else {
        await this.configService.showToast(
          "error",
          "Please select/insert all the information"
        );
        return false;
      }
    }
  }

  async addMapping() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader()
      let res: any;
      let jsonObj = {
        masterId: this.paramId,
        masterName: this.masterName,
        mapValue: this.mappingParamValue
      }
      res = await this.httpApiService.addRapaprtMapping(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss()
      if (data.status == 401) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    }
  }

  async updateMapping() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader()
      let jsonObj = {
        masterId: this.paramId,
        masterName: this.masterName,
        mapValue: this.mappingParamValue
      };
      let res: any;
      res = await this.httpApiService.RAPAPORTMappingUpdate(jsonObj, this.id)
      let data = res.json()
      await this.loadingController.dismiss()
      if (data.status == 401) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    }
  }

}
