import { Component, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import * as moment from "moment";
import { InterBoxTransferPage } from "../../../components/actions/inter-box-transfer/inter-box-transfer.page";
import { ExportAsExcelService } from "src/app/services/export-as-excel.service";

@Component({
  selector: "app-transfer-to-sales-mix",
  templateUrl: "./transfer-to-sales-mix.component.html",
  styleUrls: ["./transfer-to-sales-mix.component.scss"]
})
export class TransferToSalesMixComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild("dataTable") public dataTable: any;

  public listOfMixStones: any;
  public stoneId = [];
  public size: number;
  public maxSize: number;
  public criteriaId: any;
  public userId: any;
  public actionId: any;
  public boxId: any;
  public toRole: any;
  public voucherBookId: any;
  public errorMsgList = [];
  public successMsg: any;
  public errorMsg: any;
  public msgStatus = false;
  public stoneIdList: any;
  public flag = "TRANSFER MIX STOCK";
  public boxName: any;
  public columns = [];
  public listOfBoxes: any = [];
  public listOfCSSP = [];
  public isSelect: Boolean = false;
  public selected = [];
  public selectedCSSPList = []
  public selectedCSSPIdList = []
  public fromRole: any;
  public baseUrl: any;
  public deptList = []
  public departmentName: any;
  public deptId: any;
  public companyId: any;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public exportAsExcelService: ExportAsExcelService,
  ) { }

  async ngOnInit() {
    this.companyId = await this.authService.getCompanyId();
    let userData = await this.authService.userData();
    this.getBaseUrl();
    this.actionId = this.data.actionId;
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "CSSP", prop: "stoneId" },
    { name: "Ct", prop: "size" },
    ];
    this.getDefaultSetting();
    await this.getAllDepartment();
    this.getAllAvailableStockBox();
    this.event.subscribe('Mix Transfer To Sales', (item) => {
      this.getDefaultSetting();
      this.getAllAvailableStockBox();
      this.getAllDepartment();
    })
  }


  async getAllDepartment() {
    let res: any;
    res = await this.httpApiService.getAllDepartment(
      this.configService.appName
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.deptList = data.data;
      console.log("deptList", this.deptList);
      let userData = await this.authService.userData();
      this.departmentName = userData.departmentName;
      let deptIdArray = this.deptList.filter(item => {
        if (item.name == 'Sales') {
          return item.name;
        }
      });
      if (deptIdArray.length > 0) {
        this.deptId = parseInt(deptIdArray[0].id);
        console.log(this.deptId)
      }
    }
  }




  async getBaseUrl() {
    this.baseUrl = await this.configService.getBaseUrl();
  }

  ngOnDestroy() {
    this.event.unsubscribe('Mix Transfer To Sales');
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "selectedStone") {
      if (idList.length > 0) {
        this.stoneId = idList;
      } else {
        this.size = 0;
      }
    }
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
        if (!!dataValue.map.fromRole) {
          this.fromRole = dataValue.map.fromRole
        }
      }
    }
  }

  async getAllAvailableStockBox() {
    await this.configService.showLoader();
    let json = {
      listOfBoxType: ["Parcel", "Program-Parcel"]
    }
    let res = await this.httpApiService.getBoxesByBoxType(json);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfBoxes = data.data;
    }
  }
  async getAllAvailableStockBoxwithoutLoader() {
    let json = {
      listOfBoxType: ["Parcel", "Program-Parcel"]
    }
    let res = await this.httpApiService.getBoxesByBoxType(json);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfBoxes = data.data;
    }
  }

  async getAllAvailableCSSPInStockBox(boxId) {
    await this.configService.showLoader();
    let res = await this.httpApiService.getAllAvailableCSSPInStockBox(boxId);
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfCSSP = data.data;
      console.log(this.listOfCSSP)
    }
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      let index = stoneId.split('/', 3).join('/').length;
      return stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_")));
    } else {
      return stoneId
    }
  }

  async changeViewType(type, item) {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
    this.selected = [];
    this.selectedCSSPList = [];
    this.selectedCSSPIdList = []
    this.isSelect = false;
    console.log(item)
    this.flag = type;
    if (item != 0) {
      this.boxId = item.id;
      this.boxName = item.label;
      await this.getAllAvailableCSSPInStockBox(this.boxId)
    }
  }

  async closeDiv() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
  }

  async issueMixStones() {
    let validate = await this.validateControls();
    let res: any;
    if (validate) {
      await this.configService.showLoader();
      let jsonObj = {
        voucherDate: moment().format("YYYY-MM-DD"),
        setOfStoneIds: this.selectedCSSPIdList,
        voucherBookId: this.voucherBookId,
        toRole: this.toRole,
        toDepartmentId: this.deptId,
        checkForMapping: true,
        fromRole: this.fromRole,
        generateCsvForMapping: true,
        transType: "MIX",
        companyId: this.companyId
      };
      console.log(jsonObj)
      res = await this.httpApiService.stoneIssue(jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (data.data) {
          await this.exportAsExcelService.exportAsExcelFile(data.data, 'Error_Stones')
        } else {
          await this.configService.showToast("error", data.message);
        }
      } else {

        this.size = 0;
        this.stoneIdList = []
        let stoneCount = data.data.content.length > 0 ? data.data.content[0].StoneId.length : ''
        await this.configService.showToast("success", `${stoneCount} stones transferred successfully`);
        if (!!data.data.url) {
          window.open(this.baseUrl + data.data.url)
        }
        this.flag = 'TRANSFER MIX STOCK';
        this.getAllAvailableStockBox();
      }
    }
  }

  async validateControls() {
    console.log(this.selectedCSSPList, this.size)
    if (this.selectedCSSPList.length > 0) {
      return true;
    } else {
      await this.configService.showToast("error", "Please select stone");
      return false;
    }
  }

  onSelect(ev) {
    this.selected = ev.selectedRowsData;
    console.log(this.selected)
    console.log(this.selected)
    this.selectedCSSPList = [];
    this.selectedCSSPIdList = []
    this.selected.forEach(el => {
      this.selectedCSSPIdList.push(parseInt(el.id));
      this.selectedCSSPList.push(el)
    })
  }


  async selectAll(item) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(this.listOfCSSP)
      this.isSelect = true;
      this.selectedCSSPList = [];
      this.selected.forEach(el => {
        this.selectedCSSPIdList.push(parseInt(el.id));
        this.selectedCSSPList.push(el)
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.selectedCSSPList = [];
      this.selectedCSSPIdList = []
    }
    console.log(this.selectedCSSPList)
  }

  async interBoxTransfer() {
    let modalPage = await this.modalCtrl.create({
      component: InterBoxTransferPage,
      backdropDismiss: false,
      componentProps: { stoneList: this.selectedCSSPList, createNew: false },
      cssClass: "requestPage"
    })
    modalPage
      .onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modalPage.present();
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      this.selected = []
      this.isSelect = false;
      await this.getAllAvailableCSSPInStockBox(this.boxId)
      await this.getAllAvailableStockBoxwithoutLoader()
    }
  }

}
