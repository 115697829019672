import { Component, OnInit, Input } from "@angular/core";
import { ModalController, LoadingController, Events, AlertController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: "app-inventory-error",
  templateUrl: "./inventory-error.component.html",
  styleUrls: ["./inventory-error.component.scss"]
})
export class InventoryErrorComponent implements OnInit {
  public page = "inventoryError";
  public columns: any;
  public fromDepartment: any;
  public count: Number = 0;
  public manageSelectedUser: any;
  public listOfErrors: any = [];
  public listOfErrorsDataSource: any = {};
  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 50;
  public refreshGrid: boolean = false;
  
  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events,
    public alertCtrl: AlertController,
  ) {
    this.deleteItem = this.deleteItem.bind(this);
  }


  async deleteItem(ev) {
    this.confirmDeleteCriteria(ev.row.data);
  }


  async confirmDeleteCriteria(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteCriteria(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteCriteria(id) {
    console.log(id)
    let res = await this.httpApiService.deleteboxScanError(
      id
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("delete role permission response", data);
      await this.configService.showToast("success", data.message);
      await this.findAllErrorByDepartmentAndUser(
        this.pageNumber,
        this.noOfRecords
      );
    }
  }

  ngOnInit() {
    this.columns = [
      { name: "Box Name", prop: "label" },
      { name: "Stone Id", prop: "stoneId" },
      { name: "RFID", prop: "rfid" },
      { name: "Error", prop: "error" },
      { name: "Time", prop: "createdDate" }
    ];
    this.getUserData();
    this.event.subscribe('InventoryError', (item) => {
      this.getUserData();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('InventoryError');
  }

  async getUserData() {
    let userData = await this.authService.userData();
    this.fromDepartment = userData.departmentId;
    await this.findAllErrorByDepartmentAndUser(
      this.pageNumber,
      this.noOfRecords
    );
  }

  async findAllErrorByDepartmentAndUser(pageNumber, noOfRecords) {
    // await this.configService.showLoader();
    // let res: any;
    // res = await this.httpApiService.findAllErrorByDepartmentAndUser(
    //   this.fromDepartment,
    //   pageNumber,
    //   noOfRecords
    // );
    // let data = res.json();
    // await this.configService.dismiss();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   this.listOfErrors = data.data.content;
    //   this.count = data.data.totalElements;
    // }

    this.listOfErrorsDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100,500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findAllErrorByDepartmentAndUser(this.fromDepartment, this.pageNumber, this.noOfRecords)
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfErrors = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfErrors,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async setInventoryErrorPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.findAllErrorByDepartmentAndUser(currentPage.offset, this.noOfRecords);
  }
}
