import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpApiService } from "src/app/services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: "app-position-report",
  templateUrl: "./position-report.component.html",
  styleUrls: ["./position-report.component.scss"],
})
export class PositionReportComponent implements ProcessActionComponent {
  @Input() data: any;
  public query: any;
  public type = "SS";
  public department = ["Sales", "KAM Sales"];
  public disable: any = [];
  public cart = true;
  public default = {
    criteria: true,
    search: true,
    shape: true,
    carat: true,
    color: true,
    colorOverride: false,
    clarity: true,
    clarityOverride: false,
    description: true,
    finishing: true,
    fluorescence: true,
    lab: true,
    size: false,
    internalStatus: true,
    externalStatus: true,
    transType: false,
    users: true,
    location: true,
    box: true,
    department: true,
    saveSearch: true,
    download: true,
    fm: true,
    party: true,
    availableForSale: true,
    certiDate: true,
    inwardDate: true,
    transactionDate: true,
    standardShapeCode: true
  };
  constructor(
    public httpApiService: HttpApiService,
    private authService: AuthenticationService,
    public configService: ConfigService
  ) {}

  ngOnInit() {
    let name = [];
    let departmentName = [];
    departmentName = ["Sales", "KAM Sales"];
    name.push(this.type);
    this.disable.push("transType");
    this.disable.push("department");
    this.query = {
      //   facetFilters: ["transType:" + [this.type], "department:" + [this.department]],
      disjunctiveFacetsRefinements: {
        transType: name,
        department: departmentName
      }
    };
  }

  async handlePOevent(event: any) {
    if (event.flag === "BGM") {
      console.log(event, "BGM in posrepo");
      let fileData = <File>event.file.target.files[0];
      const formData = new FormData();
      formData.append("round", fileData);
      console.log(formData);
      let payload = formData;

      console.log(formData, "form data here");
      this.uploadBGM(payload, "round");

      console.log(payload, "  BGM PAYLOAD");
    } else if (event.flag === "nonBGM") {
      let fileData = <File>event.file.target.files[0];
      const formData = new FormData();
      formData.append("fancy", fileData);
      console.log(formData);
      let payload = formData;

      console.log(formData, "form data here");

      this.nonbgmFileUpload(payload, "fancy");
      console.log(payload, "NON BGM PAYLOAD");
    }
  }
  async uploadBGM(payload: any, type: string) {
    let res: any;
    console.log(payload);
    res = await this.httpApiService.bgmFileUpload(payload, type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data);
      await this.configService.showToast("success", data.message);
    }
  }
  async nonbgmFileUpload(payload: any, type: string) {
    let res: any;
    console.log(payload);
    res = await this.httpApiService.nonbgmFileUpload(payload, type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data);
      await this.configService.showToast("success", data.message);
    }
  }
}
