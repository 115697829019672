import R1 from './R-1';
import R2 from './R-2';
import R3 from './R-3';
import R4 from './R-4';
import R5 from './R-5';
import R6 from './R-6';
import R7 from './R-7';
import R8 from './R-8';
import R9 from './R-9';
import R10 from './R-10';
import R11 from './R-11';
import R12 from './R-12';
import R13 from './R-13';
import R14 from './R-14';
import R15 from './R-15';
import R16 from './R-16';
import R17 from './R-17';
import R18 from './R-18';
import R19 from './R-19';
import R20 from './R-20';
import R21 from './R-21';
import R22 from './R-22';
import R23 from './R-23';
import R24 from './R-24';
import R25 from './R-25';
import R26 from './R-26';
import R27 from './R-27';
import R28 from './R-28'
import R29 from './R-29';
import R30 from './R-30';


export const reportsAll = [
    R1,
    R2,
    R3,
    R4,
    R5,
    R6,
    R7,
    R8,
    R9,
    R10,
    R11,
    R12,
    R13,
    R14,
    R15,
    R16,
    R17,
    R18,
    R19,
    R20,
    R21,
    R22,
    R23,
    R24,
    R25,
    R26,
    R27,
    R28,
    R29,
    R30

];