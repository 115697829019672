import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { HttpApiService } from '../../services/http-api/http-api.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() actionName: string;
  @Input() fileType: any = '.csv'
  @Output() callFunction: EventEmitter<any> = new EventEmitter();
  @ViewChild("inputFile") fileInputVariable: any;


  count: number = 0;
  excel: any;
  error: any;

  constructor(
    public loadingController: LoadingController,
    public httpApiService: HttpApiService) { }

  ngOnInit() {
    console.log("action name inside file upload", this.actionName);
  }

  onFileSelected(event) {
    this.callFunction.emit(event);
  }
}
