import { Component, Input, OnInit } from '@angular/core';
import { ProcessActionComponent } from '../../processAction';
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Events, LoadingController, ModalController } from '@ionic/angular';
import { HttpApiService } from 'src/app/services/http-api/http-api.service';
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";


@Component({
  selector: 'app-upload-recut-rules',
  templateUrl: './upload-recut-rules.component.html',
  styleUrls: ['./upload-recut-rules.component.scss']
})
export class UploadRecutRulesComponent implements  ProcessActionComponent {
  @Input() data: any;

  // file upload data
  selectedFile: File = null;
  public page = 'UploadPolishMasterRules';
  public file: any;
  public clearFile: any;
  columns: any;
  pageNumber = 0;
  public noOfRecords = 20;
  public pageSize = 20
  totalPages: number;
  historyData: any = [];
  public historyDataSource: any = {};
  count: number = 0;
  constructor(
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public ConfigService: ConfigService,
    public event: Events
  ) {

  }

  ngOnInit() {
    this.columns = [
      { name: "File Name", prop: "fileName" },
      { name: "Date & Time", prop: "createdDate" },
      { name: "By", prop: "username" },
      { name: "Status", prop: "status" }
    ];
    this.loadPolishRulesfileUploadHistory(this.pageNumber, this.noOfRecords)
    this.event.subscribe('Polish Group Master', (item) => {
      this.columns = [
        { name: "File Name", prop: "fileName" },
        { name: "Date & Time", prop: "createdDate" },
        { name: "By", prop: "username" },
        { name: "Status", prop: "status" }
      ];
      this.loadPolishRulesfileUploadHistory(this.pageNumber, this.noOfRecords)
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Polish Group Master');
  }

  async loadPolishRulesfileUploadHistory(pageNumber, noOfRecords, search = '') {
    this.historyDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getUploadHistory(
            "Polish Group Master",
            this.pageNumber,
            this.noOfRecords,
            search
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.ConfigService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.ConfigService.showToast("error", data.error);
          } else {
            this.historyData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.historyData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async uploadFile(){
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      await this.ConfigService.showLoader();
      let res: any;
      res = await this.httpApiService.uploadNewCutFile(formData);
      console.log(res.json());
      await this.ConfigService.dismiss();
      let uploadData = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 400) {
        await this.ConfigService.showToast("error", uploadData.message);
      } else if (!uploadData.isSuccess) {
        await this.ConfigService.showToast(
          "error",
          uploadData.data
        );
  
      } else {
        await this.ConfigService.showToast(
          "success",
          uploadData.data
        );
        this.loadPolishRulesfileUploadHistory(this.pageNumber, this.noOfRecords)
      }
    } else {
      await this.ConfigService.showToast("error", "Please select file");
    }
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  async downloadFile(file) {
    console.log(file)
    let res: any;
    //res = await this.httpApiService.downloadUploadedFile(file.id);
    res = await this.httpApiService.downloadFileByType(file.id, "Polish Group Master");
  }
}
