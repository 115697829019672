import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import * as moment from "moment";
import { LoadingController, ModalController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-stone-tracking',
  templateUrl: './stone-tracking.component.html',
  styleUrls: ['./stone-tracking.component.scss']
})
export class StoneTrackingComponent implements ProcessActionComponent {
  @Input() data: any;
  newTracking = false;
  public columnsForInwardDetail = [];
  public columnsForTransferDetail = [];
  public columnsForGradingDetail = [];
  public page = 'stoneTracking';
  public stoneValue: any;
  public transferDetail = [];
  public inwardDetail = [];
  public gradingDetail = [];
  public selected = [];
  public basePriceListMasterId: any;
  public finalPriceListMasterId: any;
  public pageSize = 50;
  dataStudioLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://lookerstudio.google.com/embed/reporting/15ebcd59-212c-4d1d-ac7f-ccdc314c3c6c/page/0yIXD');

  constructor(private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public sanitizer: DomSanitizer,
    public event: Events) {

    this.event.subscribe('Stone Tracking', (item) => {
      this.stoneValue = null;
      this.transferDetail = [];
      this.inwardDetail = [];
      this.gradingDetail = [];
      this.selected = []
      this.getDefaultSetting()
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Stone Tracking');
  }

  ngOnInit() {
    this.columnsForInwardDetail = [
      { name: "Tray", prop: "box" },
      { name: "Inward Date", prop: "inwardDate" },
      { name: "Stone Id", prop: "stoneId" },
      { name: "Inward Cts.", prop: "inwardCts" },
      { name: "Current Cts.", prop: "currentCts" },
      { name: "Entity", prop: "entityType" },
      { name: "Entity Type", prop: "entity" },
      { name: "LotNo", prop: "lotNo" },
      { name: "Pkt No", prop: "pktNo" },
      { name: "Lab PKT Series No", prop: "labPktSeriesNo" },
      { name: "Barcode", prop: "barcode" },
      { name: "FM Barcode", prop: "fmBarcode" },
      // { name: "Final Price", prop: "price" },
    ];
    this.columnsForTransferDetail = [
      // { name: "From Parent Entity Type", prop: "fromParentEntityType" },
      // { name: "To Parent Entity Type", prop: "toParentEntityType" },
      { name: "To Dept", prop: "toParentEntity" },
      { name: "To Role", prop: "toEntityType" },
      { name: "To User", prop: "toEntity" },
      { name: "Type", prop: "type" },
      { name: "Transaction Type", prop: "transactionStatus" },
      { name: "Voucher No", prop: "bookSerailNumber" },
      { name: "Trans. Date", prop: "voucherDate" },
      { name: "Stone Id", prop: "stoneId" },
      { name: "Pcs", prop: "pcs" },
      { name: "Cts.", prop: "stoneCts" },
      { name: "Remarks", prop: "remarks" },
      { name: "From Dept", prop: "fromParentEntity" },
      { name: "From Role", prop: "fromEntityType" },
      { name: "From User", prop: "fromEntity" },
      { name: "Rap Price", prop: "fluorescence" },
      { name: "Rap Amount", prop: "RAPAPORTpercarat" },
      { name: "Final Price", prop: "modifiedDate" },
    ];
    this.columnsForGradingDetail = [
      { name: "Stone Id", prop: "stoneName" },
      { name: "Stone Cts.", prop: "cts" },
      { name: "Shape", prop: "Shape" },
      { name: "Color", prop: "Color" },
      { name: "Cut", prop: "Cut" },
      { name: "Clarity", prop: "Clarity" },
      { name: "Polish", prop: "Polish" },
      { name: "Symmetry", prop: "Symmetry" },
      { name: "Fluorescence", prop: "Fluorescence" },
      // { name: "PD cat", prop: "PdCat" },
      { name: "lab", prop: "lab" },
      { name: "Lab Pkt Series No", prop: "labPktSeriesNo" },
      { name: "LabControlNo", prop: "LabControlNo" },
      { name: "Inscription", prop: "Inscription" },
      { name: "KeytoSymbols", prop: "KeytoSymbols" },
      { name: "FM String", prop: "FmStr" },
      { name: "Report No", prop: "ReportNo" },
      { name: "Report Date", prop: "ReportDt" },
      { name: "PavAngle", prop: "PavAngle" },
      { name: "PavHgt", prop: "PavHgt" },
      { name: "CrAngle", prop: "CrAngle" },
      { name: "CrHgt", prop: "CrHgt" },
      { name: "Girdle", prop: "Girdle" },
      { name: "Culets", prop: "Culets" },
      { name: "TotDepth", prop: "TotDepth" },
      { name: "TablePer", prop: "TablePer" },
    ];
    this.getDefaultSetting()
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.basePriceListName) {
          this.basePriceListMasterId = dataValue.map.basePriceListName
        }
        if (!!dataValue.map.listOfPrices) {
          let list = JSON.parse(dataValue.map.listOfPrices)
          this.finalPriceListMasterId = list[0]
        }
      }
      console.log(this.basePriceListMasterId, this.finalPriceListMasterId)
    }
  }

  async findTrackingDetail() {
    await this.configService.showLoader();
    this.inwardDetail = []
    let inward = [];
    let res: any;
    res = await this.httpApiService.getStoneTrackingDetail(this.stoneValue, this.basePriceListMasterId, this.finalPriceListMasterId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data)
      if (!!data.data) {
        inward.push(data.data.inward)
        this.inwardDetail = inward;
        this.transferDetail = data.data.transactions;
        console.log(this.inwardDetail, this.transferDetail)
      }
    }
    await this.configService.dismiss();
  }

  async findGradingDetails(ev) {
    this.gradingDetail = [];
    let obj = ev.row;
    let res: any;
    res = await this.httpApiService.getTransactionParameter(obj.id, obj.voucherDate);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (!!data.data) {
        this.gradingDetail = data.data;
        console.log(this.gradingDetail)
      }
    }
  }

  checkView(ev) {
    console.log(ev)
  }
}
