import { Component, Input } from "@angular/core";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ManageBoxMasterPage } from "../../../components/actions/manage-box-master/manage-box-master.page";
import { modelGroupProvider } from "@angular/forms/src/directives/ng_model_group";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: "app-box-master",
  templateUrl: "./box-master.component.html",
  styleUrls: ["./box-master.component.scss"]
})
export class BoxMasterComponent implements ProcessActionComponent {
  @Input() data: any;

  public items: any;
  public selectedBox = "";
  public deptList: any;
  public totalPages: any;
  public count: any;
  public flgBtn: any;
  public arrowKeyLocation = -1;
  public manageSelectedBox = "";
  public department: any;
  public RFID: any;
  public label: any;
  public boxType: any;
  public userSelect: any;
  public getAllUserData: any;
  public departmentId: number; // Assortment (id =2) Selected
  public getUser = [];
  public getAllBoxes: any = [];
  public page = "boxMaster";
  public columns: any;
  public userId: any;
  public departmentName: any;
  public boxTypeList = [];
  public remark: any;
  public position: any;
  public remarks: any;
  public pageNumber = 0;
  public noOfRecords = 50;
  public pageSize = 50;
  public boxId: any;
  public capacity: any;
  public transTypeId: any;
  public listOfCriteria = [];
  public listOfCriteriaDataSource: any = {};
  public listOfTransType = [];
  public transTypeIdList = [];
  public listOfItemsCriteria = [];
  public roleIdIdList = []
  public allRoles = [];
  public roleId: any;
  public isDisable = true;
  public typeIdList = [];
  public userIdList = [];
  public transType: any;
  public boardBaseUrl: any;
  public refreshGrid = false;

  constructor(
    public alertController: AlertController,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events
  ) {
    this.deleteItem = this.deleteItem.bind(this);
  }

  async getDepartment() {
    let userData = await this.authService.userData();
    this.departmentName = userData.departmentName;
    this.department = userData.departmentId;
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
  }

  async ngOnInit() {
    this.columns = [{ name: "Name", prop: "name" }, { name: "From Size", prop: "ctsFromSize" }, { name: "To Size", prop: "ctsToSize" }]
    await this.getDepartment()
    await this.getAllUser();
    await this.getAllDepartment();
    await this.getAllRole();
    await this.getBoxType();
    await this.getAllTransType();
    this.event.subscribe('Box Master', (item) => {
      this.getDepartment()
      this.getAllUser();
      this.getAllDepartment();
      this.getAllRole();
      this.getBoxType();
      this.getAllTransType();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Box Master')
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "user") {
      if (idList.length > 0) {
        this.userSelect = idList[0];
      } else {
        this.userSelect = null;
        this.showButton('user');
      }
    } else if (property == "boxType") {
      this.boxType = idList[0];
    } else if (property == "transTypeName") {
      this.transTypeId = idList[0];
      this.showButton('transType')
    } else if (property == "type") {
      this.boxType = idList[0];
      this.showButton('boxType')
    } else if (property == "roleForBox") {
      this.roleId = idList[0];
      this.showButton('role')
    }

  }

  async scanHere() {
    console.log("&&&", this.boardBaseUrl)
    let res;
    if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
      await this.configService.showLoader();
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      console.log(data);
      if (data.type == "error") {
        this.configService.dismiss();
        await this.configService.showToast("error", data.type);
        this.RFID = null;
      } else {
        if (data.result.length > 0) {
          if (data.result.length > 1) {
            await this.configService.showToast("error", "More than one RFID found!");
          } else {
            this.RFID = data.result;
          }
        } else {
          this.RFID = null;
        }
        this.configService.dismiss();
      }
    } else {
      await this.configService.showToast("error", "Scanner Board Url not found");
    }
  }

  async getAllRole() {
    let res: any;
    res = await this.httpApiService.getAllRoles();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allRoles = data.data;
    }
  }

  setItems() {
    this.items = this.getAllBoxes;
  }

  async getAllDepartment() {
    let res: any;
    res = await this.httpApiService.getAllDepartment(
      this.configService.appName
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.deptList = data.data;
      console.log("deptList", this.deptList);
      let userData = await this.authService.userData();
      this.departmentName = userData.departmentName;
      let deptIdArray = this.deptList.filter(item => {
        if (item.name == this.departmentName) {
          return item.name;
        }
      });
      if (deptIdArray.length > 0) {
        this.departmentId = parseInt(deptIdArray[0].id);
        console.log(this.departmentId);
        await this.findByDepartmentId(this.departmentId);
      }
    }
  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data);
      this.listOfTransType = data.data;
    }
  }

  async getAllUser() {
    let res: any;
    let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllUser(fromLocation);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllUserData = data.data;
      console.log("get All User Data", this.getAllUserData);
      let data1 = await this.authService.userData();
      this.userId = data1.userId;
      this.getUser = [];
      this.getAllUserData.forEach(element => {
        if (element.id == this.userId) {
          this.getUser.push(element);
        }
      });
      console.log("QWQW", this.getUser);
    }
  }

  async getBoxType() {
    let res;
    res = await this.httpApiService.getAllBoxType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let list = [];
      let dataValue = data.data;
      // dataValue.forEach(el => {
      //   if (this.departmentName == "Assortment") {
      //     list.push(el);
      //   } else {
      //     if (el.type == "Regular") list.push(el);
      //   }
      // });
      this.boxTypeList = dataValue;
      console.log("boxTypeList", this.boxTypeList);
    }
  }

  async findByDepartmentId(deptId) {
    let id;
    console.log("dept", deptId);
    await this.configService.showLoader();
    if (deptId.detail) {
      id = deptId.detail.value;
    } else {
      id = deptId;
    }
    console.log("id", id);
    let res: any;
    this.departmentId = id;
    res = await this.httpApiService.findByDepartmentIdAndUser(id, 'false');
    let data = res.json();
    await this.configService.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllBoxes = data.data;
      this.getAllBoxes = this.getAllBoxes.filter(el => el.type != 'Parcel' && el.type != 'Program-Parcel');
      await this.setItems();
    }
  }

  getBoxDetail(label) {
    this.searchBox(label);
  }

  async searchBox(label) {
    this.transTypeIdList = []
    this.transTypeId = null;
    this.transType = null
    this.flgBtn = "";
    this.listOfCriteria = []
    await this.configService.showLoader();
    let tmplabel = "";
    this.items = this.getAllBoxes;
    if (!!label) {
      tmplabel = label;
      this.manageSelectedBox = label;
      this.selectedBox = label;
    } else {
      this.selectedBox = this.manageSelectedBox;
      tmplabel = this.manageSelectedBox;
    }
    let selectedActionId = this.items.filter(function (search) {
      if (search.label == tmplabel) {
        return search.id;
      }
    });
    if (selectedActionId.length > 0) {
      this.boxId = selectedActionId[0].id;
      let res: any;
      this.getAllCriteriaByBoxId(this.boxId, this.pageNumber, this.noOfRecords)
      res = await this.httpApiService.findBoxById(this.boxId);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        let dataValue = data.data;
        console.log(dataValue);
        this.selectedBox = dataValue.label;
        this.label = dataValue.label;
        this.RFID = dataValue.rfid;
        this.transTypeId = dataValue.transTypeId;
        this.capacity = dataValue.maxCapacity;
        this.remarks = dataValue.remarks;

        this.position = dataValue.position;
        if (dataValue.transTypeId) {
          this.transTypeIdList = []
          this.listOfTransType.forEach(element => {
            if (element.id == dataValue.transTypeId) {
              let data = { id: element.id, name: element.name };
              this.transType = element.name;
              this.transTypeIdList.push(data);
            }
          });
        }
        if (dataValue.boxTypeId) {
          this.typeIdList = [];
          this.boxTypeList.forEach(element => {
            console.log(element.id, dataValue.boxTypeId)
            if (element.id == dataValue.boxTypeId) {
              let data = { id: element.id, type: element.type };
              this.typeIdList.push(data);
            }
          });
          console.log(this.typeIdList)
          this.boxType = parseInt(dataValue.boxTypeId);
        }
        if (dataValue.roleId) {
          this.roleIdIdList = [];
          this.allRoles.forEach(element => {
            if (element.id == dataValue.roleId) {
              let data = { id: element.id, roleName: element.roleName };
              this.roleIdIdList.push(data);
            }
          });
          console.log(this.typeIdList)
          this.roleId = parseInt(dataValue.roleId);
        }
        if (dataValue.userId) {
          this.userSelect = parseInt(dataValue.userId);
          this.userIdList = []
          this.getUser.forEach(element => {
            if (element.id == dataValue.userId) {
              let data = { id: element.id, username: element.username };
              this.userIdList.push(data);
            }
          });
        }
        let index = this.items.findIndex(
          x => x.label == selectedActionId[0].label
        );
        this.arrowKeyLocation = index;
      }
    } else {
      await this.configService.dismiss();
      this.selectedBox = "";
    }
  }

  async getAllCriteriaByBoxId(boxId, pageNumber, noOfRecords) {
    // let res: any;
    // res = await this.httpApiService.findCriteriaByBoxId(boxId, pageNumber, noOfRecords)
    // let data = res.json();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   this.listOfCriteria = data.data.content;
    //   this.count = data.data.totalElements;
    // }
    this.listOfCriteriaDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findCriteriaByBoxId(boxId, this.pageNumber, this.noOfRecords)
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfCriteria = data.data.content;
            this.setItems();
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.listOfCriteria,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async setCriteriaPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getAllCriteriaByBoxId(this.boxId, this.pageNumber, this.noOfRecords);
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedBox = this.items[this.arrowKeyLocation].label;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedBox = this.items[this.arrowKeyLocation].label;
            break;
          } else {
            break;
          }
        }
    }
  }

  async deleteBox(item) {
    console.log("items", item);
    let res: any;
    res = await this.httpApiService.deleteBox(item.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.findByDepartmentId(this.departmentId);
    }
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        // return item.label.toLowerCase().includes(val.toLowerCase());
        if (
          item.label &&
          item.label.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        }
      });
    } else {
      console.log(this.getAllBoxes);
      this.items = this.getAllBoxes;
    }
  }


  async filterActions(ev: any) {
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.listOfCriteria = await this.listOfCriteria.filter(function (item) {
        // return item.label.toLowerCase().includes(val.toLowerCase());
        if (
          item.name &&
          item.name.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        } else if (item.ctsFromSize &&
          item.ctsFromSize.toString().toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (item.ctsToSize &&
          item.ctsToSize.toString().toLowerCase().includes(val.toLowerCase())) {
          return true;
        }
      });
    } else {
      console.log(this.listOfCriteria);
      this.listOfCriteria = this.listOfCriteria;
    }
  }



  async deleteConfirm(item) {
    const alert = await this.alertController.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Box " + item.label,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteBox(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async manageBoxMaster(item, flag) {
    this.refreshGrid = false;
    if (flag == 2) {
      if ((item.type == "mouseenter") || (item.column && item.column.name == 'Delete')) {
        item.event.stopPropagation();
      } else {
        console.log(item);
        const modal = await this.modalCtrl.create({
          component: ManageBoxMasterPage,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: { item: item.row, flag: flag, boxId: this.boxId, transType: this.transType }
        });
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
    } else {
      console.log(item);
      const modal = await this.modalCtrl.create({
        component: ManageBoxMasterPage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: { item: item, flag: flag, boxId: this.boxId, transType: this.transType }
      });
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    if (d.data != 'doNotRefresh') {
      await this.findByDepartmentId(this.departmentId);
      await this.searchBox(this.label);
    }
  }

  async showButton(name) {
    if (name == "label") {
      this.flgBtn = "label";
    } else if (name == "transType") {
      this.flgBtn = "transType";
    } else if (name == "boxType") {
      this.flgBtn = "boxType";
    } else if (name == "user") {
      this.flgBtn = "user";
    } else if (name == "capacity") {
      this.flgBtn = "capacity";
    } else if (name == "position") {
      this.flgBtn = "position";
    } else if (name == "remark") {
      this.flgBtn = "remark";
    } else if (name == "role") {
      this.flgBtn = "role";
    } else if (name == "rfid") {
      this.flgBtn = "rfid";
    } else {
      this.flgBtn = "companyName";
    }
  }

  async updateBox() {
    let validate = await this.validateControls();
    console.log(validate)
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        label: this.label,
        departmentId: this.department,
        userId: this.userSelect,
        rfid: this.RFID,
        maxCapacity: this.capacity,
        roleId: this.roleId,
        position: this.position,
        remarks: this.remarks,
        transTypeId: this.transTypeId,
        boxTypeId: this.boxType
      };
      console.log(jsonObj);
      res = await this.httpApiService.updateBox(this.boxId, jsonObj);
      let data = res.json();
      await this.configService.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.flgBtn = "";
        await this.findByDepartmentId(this.departmentId);
        await this.searchBox(this.label);
        await this.configService.showToast("success", data.message);

      }
    }
  }

  async validateControls() {
    console.log(
      this.label,
      this.RFID,
      this.capacity,
      this.position,
      this.remarks,
      this.boxType
    );
    if (
      !!this.label &&
      !!this.capacity &&
      !!this.boxType
    ) {
      if (this.capacity > 0) {
        return true;
      } else {
        await this.configService.showToast("error", "Please enter valid value");
        return false;
      }
    } else {
      await this.configService.showToast("error", "Please enter details");
      return false;
    }
  }

  async deleteCriteria(id) {
    console.log(id)
    let res = await this.httpApiService.deleteCriteria(
      id
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("delete role permission response", data);
      await this.configService.showToast("success", data.message);
      this.getAllCriteriaByBoxId(this.boxId, this.pageNumber, this.noOfRecords)
    }
  }

  async confirmDeleteCriteria(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this criteria ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteCriteria(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteItem(ev) {
    this.confirmDeleteCriteria(ev.row.data);
  }
}
