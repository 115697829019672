import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import {
  ModalController,
  ToastController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ManageRolesPage } from "../manage-roles/manage-roles.page";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { PriceNameMasterPage } from "../price-name-master/price-name-master.page";
import CustomStore from "devextreme/data/custom_store";
import Query from "devextreme/data/query";

@Component({
  selector: "app-price-list-uploader",
  templateUrl: "./price-list-uploader.component.html",
  styleUrls: ["./price-list-uploader.component.scss"]
})
export class PriceListUploaderComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() priceName: any;
  @Input() isDisable: any;
  @Input() listOfActionPermissions: any;
  @Input() actionName: any;
  // file upload data
  historyData: any = [];
  public historyDataSource: any = {};
  selectedFile: File = null;
  columns: any;
  pageNumber = 0;
  public noOfRecords = 20;
  public pageSize = 20
  totalPages: number;
  page = 'priceList'
  count: number = 0;
  userId: number;
  name: any;
  voucherDate: any;
  allPriceNameList = [];
  nameId: any;
  nameIdList: any;
  searchFileName: string;
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public toastCtrl: ToastController,
    public configService: ConfigService,
    public event: Events
  ) {

    let today = new Date();
    this.voucherDate =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);
  }

  async ngOnInit() {
    this.columns = [
      { name: "File Name", prop: "fileName" },
      { name: "Price List Name", prop: "description" },
      { name: "Date & Time", prop: "createdDate" },
      { name: "By", prop: "username" },
      { name: "Status", prop: "status" }
    ];
    if (!!this.listOfActionPermissions && this.listOfActionPermissions.length > 0) {
      this.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
    } else {
      console.log(this.data.listOfActionPermissions)
      if (!!this.data.listOfActionPermissions) {
        this.data.listOfActionPermissions.filter(el => {
          if (el.permissionName == 'Read') {
            this.readPermissionFlag = true;
          } if (el.permissionName == 'Add') {
            this.addPermissionFlag = true;
          } if (el.permissionName == 'Update') {
            this.updatePermissionFlag = true;
          } if (el.permissionName == 'Delete') {
            this.deletePermissionFlag = true;
          }
        })
      }
    }

    console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    await this.getAllPriceListName();
    this.loadPriceUploadHistory(this.pageNumber, this.noOfRecords)

    if (!!this.actionName) {
      this.event.subscribe('RAPAPORT Price List Uploader', (item) => {
        this.columns = [
          { name: "File Name", prop: "fileName" },
          { name: "Price List Name", prop: "description" },
          { name: "Date & Time", prop: "createdDate" },
          { name: "By", prop: "username" },
          { name: "Status", prop: "status" }
        ];
        this.getAllPriceListName();
        this.loadPriceUploadHistory(this.pageNumber, this.noOfRecords)
      })
    } else {
      this.event.subscribe('Price List Uploader', (item) => {
        this.getAllPriceListName();
        this.loadPriceUploadHistory(this.pageNumber, this.noOfRecords)
      })
    }
    if (this.allPriceNameList.length > 0) {
      this.allPriceNameList.filter(el => {
        if (el.name == this.priceName) {
          this.nameIdList = []
          this.nameId = el.id;
          this.nameIdList.push({ id: el.id, name: el.name })
        }
      })
    }
  }

  ngOnDestroy() {
    if (!!this.actionName) {
      this.event.unsubscribe('RAPAPORT Price List Uploader');
    } else {
      this.event.unsubscribe('Price List Uploader');
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "priceName") {
      if (idList.length > 0) {
        this.nameIdList = obj;
        this.nameId = idList[0];
      } else {
        this.nameId = null;
      }
    }
  }

  async getAllPriceListName() {
    this.nameIdList = []
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
      if (this.nameId) {
        this.allPriceNameList.filter(el => {
          if (el.id == this.nameId) {
            this.nameIdList = []
            this.nameId = el.id;
            this.nameIdList.push({ id: el.id, name: el.name })
          }
        })
      }
    }
  }

  fileObject(event) {
    this.selectedFile = <File>event.target.files[0];
    console.log(this.selectedFile);
  }

  async uploadFile() {
    let validate = await this.validateControls();
    if (!validate) {
      if (this.selectedFile) {
        let myJson = {
          nameId: this.nameId,
          fromDate: this.voucherDate
        };
        console.log(myJson);
        const formData: FormData = new FormData();
        formData.append("file", this.selectedFile);
        formData.append("dataJson", JSON.stringify(myJson));
        await this.configService.showLoader();
        let res: any;
        res = await this.httpApiService.uploadPriceList(formData);
        console.log(res.json());
        await this.configService.dismiss();
        let uploadData = res.json();
        if (uploadData.status == 400) {
          await this.configService.showToast("error", uploadData.error);
        } else if (res.status == 500) {
          await this.configService.showToast("error", uploadData.error);
        } else {
          await this.configService.showToast("success", uploadData.message);
          this.selectedFile = null;
          this.nameIdList = []
          await this.loadPriceUploadHistory(this.pageNumber, this.noOfRecords);
        }
      } else {
        await this.configService.showToast("error", "Please select file");
      }
    }
  }

  async validateControls() {
    console.log(this.voucherDate, this.name);
    if (
      this.voucherDate != undefined &&
      this.nameId != undefined &&
      this.nameId != "" &&
      this.nameId != null
    ) {
      return false;
    } else {
      await this.configService.showToast("error", "Please insert the detail");
      return true;
    }
  }

  async downloadPriceListFile(file) {
    console.log(file)
    let res: any;
    res = await this.httpApiService.downloadUploadedFile(file.id);
  }

  async loadPriceUploadHistory(pageNumber, noOfRecords, search = '') {
    // await this.configService.showLoader();
    // let res: any;
    // res = await this.httpApiService.getUploadHistory(
    //   "Price List Upload",
    //   pageNumber,
    //   noOfRecords,
    //   search
    // );
    // await this.configService.dismiss();
    // let data = res.json();
    // if (res.status == 401) {
    //   this.authService.logout();
    // } else if (res.status == 500) {
    //   await this.configService.showToast("error", data.error);
    // } else if (res.status == 400) {
    //   await this.configService.showToast("error", data.error);
    // } else {
    //   let dataValue = data.data;
    //   console.log(data);
    //   this.historyData = dataValue.content;
    //   this.totalPages = dataValue.totalPages;
    //   this.count = dataValue.totalElements;
    // }
    this.historyDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100,500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getUploadHistory(
            "Price List Upload",
            this.pageNumber,
            this.noOfRecords,
            search
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.historyData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.historyData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }

  async setPage(currentPage) {
    console.log(currentPage);
    let res: any;
    this.pageNumber = currentPage.offset;
    await this.loadPriceUploadHistory(this.pageNumber, this.noOfRecords);
  }

  async openModalForAddPriceListName(isModal) {
    let item: any;
    if (this.nameIdList) {
      item = this.nameIdList[0]
    } else {
      item = ""
    }
    console.log(item)
    let modal = await this.modalCtrl.create({
      component: PriceNameMasterPage,
      componentProps: {
        item: item,
        isModal: isModal,
      },
      backdropDismiss: false,
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      this.getAllPriceListName();
      this.loadPriceUploadHistory(this.pageNumber, this.noOfRecords);
    }
  }

  searchFileNames(search) {
    if (!!search && !!search.arrayList && !!search.arrayList[0] && !!search.arrayList[0].name) {
      this.loadPriceUploadHistory(0, this.noOfRecords, search.arrayList[0].name)
    } else {
      this.loadPriceUploadHistory(0, this.noOfRecords)
    }
    // console.log(search.arrayList[0].name);
  }
}
