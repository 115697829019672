import { Component, Input, ViewChild } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { LoadingController, ModalController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageUploadStonePage } from "../manage-upload-stone/manage-upload-stone.page";
import { FileUploadComponent } from "../../fileUpload/file-upload.component";
import { ConfigService } from "../../../services/config/config.service";
import * as moment from "moment";
import Query from "devextreme/data/query";
import CustomStore from "devextreme/data/custom_store";

@Component({
  selector: 'app-lab-result-upload-for-other-office',
  templateUrl: './lab-result-upload-for-other-office.component.html',
  styleUrls: ['./lab-result-upload-for-other-office.component.scss']
})
export class LabResultUploadForOtherOfficeComponent implements ProcessActionComponent {
  // @ViewChild("inputFile") fileInputVariable: any;
  @Input() data: any;

  listOfLabs = []
  labId = null;
  public selectedFile: File = null;
  public labIdList = [];
  public historyDataSource: any = {};
  public pageNumber = 0;
  public noOfRecords = 20;
  public pageSize = 20;
  public historyData = [];
  public columns = [];
  constructor(private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events) { }

  ngOnInit() {
    this.columns = [{
      name: 'Sr No',
      cellTemplate: function (cellElement, cellInfo) {
        cellElement.append(cellInfo.row.rowIndex + 1);
      }
    },
    { name: "File Name", prop: "fileName" },
    { name: "Description", prop: "description" },
    { name: "Date & Time", prop: "createdDate" },
    { name: "By", prop: "username" },
    { name: "Status", prop: "status" }
    ];
    this.loadCertificationFileUploadHistory()

    this.event.subscribe('Lab Result Upload For Other Office Stones', (item) => {
      this.columns = [
        { name: "File Name", prop: "fileName" },
        { name: "Description", prop: "description" },
        { name: "Date & Time", prop: "createdDate" },
        { name: "By", prop: "username" },
        { name: "Status", prop: "status" }
      ];
      this.loadCertificationFileUploadHistory()

    })
    this.getAllLabs()
  }

  ngOnDestroy() {
    this.event.unsubscribe('Lab Result Upload For Other Office Stones');
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'labList') {
      if (idList.length > 0) {
        this.labId = idList[0]
      } else {
        this.labId = null;
      }
    }
  }


  async getAllLabs() {
    let json = {
      listOfType: ['Lab']
    }
    let res: any;
    res = await this.httpApiService.findByListOfPartyType(json, 'Job');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      // let labList = []
      // if (data.data) {
      //   data.data.filter(el => {
      //     if (el.partyName == 'NCSS (Non cert single stones)' || el.partyName == 'K G Mumbai Lab') {
      //       labList.push(el)
      //     }
      //   })
      // }
      this.listOfLabs = data.data;
      console.log(this.listOfLabs)
    }
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
    console.log(this.selectedFile);
  }

  async uploadReport() {
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      let selectedlab = this.listOfLabs.filter(el => el.id == this.labId)[0];
      // formData.append("myJson", JSON.stringify(jsonObj));
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.labResultUploadForOtherOfficeStone(formData, selectedlab.labReferenceId);
      console.log(res.json());
      await this.configService.dismiss();
      let uploadData = res.json();
      if (uploadData.status == 400) {
        await this.configService.showToast("error", uploadData.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", uploadData.error);
      } else {
        await this.configService.showToast("success", uploadData.message);
        this.selectedFile = null;
        this.labIdList = [];
        this.labId = null;
        this.loadCertificationFileUploadHistory()
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  async loadCertificationFileUploadHistory() {
    this.historyDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.getUploadHistory(
            'Certification Upload',
            this.pageNumber,
            this.noOfRecords
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.historyData = data.data.content;
            return {
              data: Query(data.data.content)
                .toArray(),
              totalCount: data.data.totalElements,
              groupCount: data.data.totalElements
            };
          }
        } else {
          return {
            data: this.historyData,
            totalCount: 0,
            groupCount: 0
          }
        }
      }
    });
  }

  async downloadCertificationFile(file) {
    console.log(file)
    let res: any;
    res = await this.httpApiService.downloadCertificationUploadedFile(file.id);
  }
}


