export default {
  "reportNo": "R-12",
  "url": "master-service/api/report/expenseData",
  "method": "GET",
  "size": 3,
  "fields": [
    {
      title: ' From Date ',
      input: 'dateTime',
      key: 'fromDate',
      placeholder: 'Select From Date',
      property: 'fromDate',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
    {
      title: ' To Date ',
      input: 'dateTime',
      key: 'toDate',
      placeholder: 'Select To Date',
      property: 'toDate',
      required: true,
      default: [],
      setting: {
        bigBanner: true,
        timePicker: true,
        format: 'yyyy-MM-dd',
        defaultOpen: false,
        minDate: new Date()
      },
      size: 3
    },
    {
        title: ' Party ',
        input: 'select',
        key: 'partyMasterId',
        placeholder: 'Select Party',
        property: 'partyMasterId',
        keyName: 'id',
        name: 'partyName',
        required: true,
        singleSelection: true,
        function: 'getAllPartyLab',
        default: [],
        size: 3
    },
    {
      title: ' Invoice No ',
      input: 'input',
      type: 'text',
      key: 'invoiceNo',
      placeholder: 'Select Enter Invoice No',
      property: 'invoiceNo',
    },
    {
      title: ' Stone Id ',
      input: 'input',
      type: 'text',
      key: 'stoneId',
      placeholder: 'Select Enter Stone Id',
      property: 'stoneId',
    }
  ],
  "columns": [
    { name: "Inv. No", prop: "invoiceNo" },
    { name: "Inv. Date", prop: "invoiceDate" },
    { name: "Stone id", prop: "stoneId" },
    { name: "Control No", prop: "controlNo" },
    { name: "Job No.", prop: "jobNo" },
    { name: "Item Description", prop: "itemDesc" },
    { name: "Service Description", prop: "serviceDesc" },
    { name: "Fee", prop: "fee" },
    { name: "Handling Charges", prop: "handlingCharges" },
    { name: "CGST", prop: "cgst" },
    { name: "SGST", prop: "sgst" },
    { name: "IGST", prop: "igst" },
    { name: "Total GST", prop: "tax" },
    { name: "Total Invoice amount", prop: "totalFee" }
  ]
};
