import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import {
  ModalController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ManagePendingCustomerApprovalPage } from "../../../components/actions/manage-pending-customer-approval/manage-pending-customer-approval.page";
import CustomStore from 'devextreme/data/custom_store';
import Query from "devextreme/data/query";

@Component({
  selector: 'app-update-kycapproval',
  templateUrl: './update-kycapproval.component.html',
  styleUrls: ['./update-kycapproval.component.scss']
})
export class UpdateKYCApprovalComponent implements ProcessActionComponent {
  @Input() data: any;
  public items: any = [];
  public pageNumber = 0;
  public noOfRecords = 50;
  public pendingCustomerApprovalList = [];
  public count: Number = 0;
  public columns = [];
  public columnsForIncompleteKYC = [];
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public pageSize: any = 50;
  public listOfPartyDataSource: any = {};
  public listOfIncompleteKYCusers: any = {};
  public listOfPartyData = []
  public refreshGrid= false;
  KYCcompleted:boolean = false;
  selectionView:boolean = true;
  KYCincomplete:boolean = false;
  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events) {
    this.event.subscribe('Website Customer Approval', (item) => {
      this.columns = [
        {
          name: 'Sr No',
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          }
        },
        { name: "Party Name", prop: "website_partyName" },
        { name: "Ref Party Code", prop: "partyId" },
        { name: "Person Name", prop: "website_contactName" },
        { name: "Contact No", prop: "website_contactNo" },
        { name: "KAM Person", prop: "website_kam" },
        { name: "Disctibution Center", prop: "website_distributioncenter" },
        { name: "Date & Time", prop: "createdDate" },
      ];
      // this.getPendingCustomerApprovalList()
    });
    this.event.subscribe('Website Customer Approval', (item) => {
      this.columnsForIncompleteKYC = [
        {
          name: 'Sr No',
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          }
        },
        { name: "Person Name", prop: "website_contactName" },
        { name: "Contact No", prop: "website_contactNo" },
        { name: "KAM Person", prop: "website_kam" },
        { name: "Disctibution Center", prop: "website_distributioncenter" },
        { name: "Date & Time", prop: "createdDate" },
      ];
      // this.getPendingCustomerApprovalList()
    })
  }

  ngOnInit() {
    this.items = [
      {'value':'KYC uploaded by client'},
      {'value':'Manually bind party'},
    ]


    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    // this.columns = [
    //   {
    //     name: 'Sr No',
    //     cellTemplate: function (cellElement, cellInfo) {
    //       cellElement.append(cellInfo.row.rowIndex + 1);
    //     }
    //   },
    //   { name: "Party Name", prop: "website_partyName" },
    //   { name: "Ref Party Code", prop: "partyId" },
    //   { name: "Person Name", prop: "website_contactName" },
    //   { name: "Contact No", prop: "website_contactNo" },
    //   { name: "KAM Person", prop: "website_kam" },
    //   { name: "Disctibution Center", prop: "website_distributioncenter" },
    //   { name: "Date & Time", prop: "createdDate" },
    // ];
    // this.getPendingCustomerApprovalList()

    // this.columnsForIncompleteKYC = [
    //   {
    //     name: 'Sr No',
    //     cellTemplate: function (cellElement, cellInfo) {
    //       cellElement.append(cellInfo.row.rowIndex + 1);
    //     }
    //   },
    //   { name: "Person Name", prop: "website_contactName" },
    //   { name: "Contact No", prop: "website_contactNo" },
    //   { name: "KAM Person", prop: "website_kam" },
    //   { name: "Disctibution Center", prop: "website_distributioncenter" },
    //   { name: "Date & Time", prop: "createdDate" },
    // ];
    // this.getIncompleteKYCusersList()
  }

  ngOnDestroy() {
    this.event.unsubscribe('Website Customer Approval');
  }

  async getPendingCustomerApprovalList() {
    this.listOfPartyDataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.findPendingPartyApproval(
            this.pageNumber,
            this.pageSize
          );
          let data = res.json();
          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.listOfPartyData = data.data.content;
            if (!!data.data.content) {
              data.data.content.forEach(el => {
                if (el.partyDetailsJson) {
                  let jsonData = JSON.parse(el.partyDetailsJson)
                  el.website_partyName = !!jsonData.customerDetails.name ? jsonData.customerDetails.name : '';
                  el.website_contactName = !!jsonData.general.name ? jsonData.general.name : '';
                  el.website_kam = !!jsonData.userAccount.salesperson ? jsonData.userAccount.salesperson.name : '';
                  el.website_contactNo = !!jsonData.general.phone ? jsonData.general.phone : '';
                  el.website_distributioncenter = !!jsonData.userAccount.distributioncenter.companyName ? jsonData.userAccount.distributioncenter.companyName : '';
                }
              })
              data.data.content = data.data.content.filter(el => el.website_distributioncenter.toLowerCase() == 'mumbai')
              return {
                data: Query(data.data.content)
                  .toArray(),
                totalCount: data.data.totalElements,
                groupCount: data.data.totalElements
              };
            } else {
              return {
                data: this.listOfPartyData,
                totalCount: this.count,
                groupCount: this.count
              }
            }

          }
        } else {
          return {
            data: this.listOfPartyData,
            totalCount: this.count,
            groupCount: this.count
          }
        }
      }
    });
  }
  async getIncompleteKYCusersList() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findIncompleteKYCusers();
    await this.configService.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    }else {
      let data = JSON.parse(res._body);
      this.listOfPartyData = data.data; 
      if(data.data!!){
        data.data.forEach((item:any)=>{
          if(item.parameter){
            let jsonPartyData = JSON.parse(item.parameter);
            item.website_contactName = !!jsonPartyData.general.name ?  jsonPartyData.general.name : '';
            item.website_kam = !!jsonPartyData.userAccount.salesperson ? jsonPartyData.userAccount.salesperson.name : '';
            item.website_contactNo = !!jsonPartyData.general.phone ? jsonPartyData.general.phone : '';
            item.website_distributioncenter = !!jsonPartyData.userAccount.distributioncenter.companyName ? jsonPartyData.userAccount.distributioncenter.companyName : '';
          }
          // item.created_date = item.created_date;
        });
        this.listOfIncompleteKYCusers = this.listOfPartyData;
      }
    }

  }

  async openConfirmationModal(item:any, type:string) {
    this.refreshGrid = false;
    if (this.updatePermissionFlag == true && !!item.row) {
      // console.log('\x1b[41m%s\x1b[0m', 'item: ', item);
      // console.log('\x1b[41m%s\x1b[0m', 'type: ', type);
      let modal = await this.modalCtrl.create({
        component: ManagePendingCustomerApprovalPage,
        backdropDismiss: false,
        cssClass: "requestPage",
        componentProps: { item: item.row ,type :type }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    if (d.data != 'doNotRefresh') {
      await this.getPendingCustomerApprovalList()
      await this.getIncompleteKYCusersList()
    }
  }

  loadUsersGrid(item:any){
    if(item.value === "KYC uploaded by client"){
      this.selectionView = false;
      this.KYCcompleted = true;
      this.columns = [
        {
          name: 'Sr No',
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          }
        },
        { name: "Party Name", prop: "website_partyName" },
        { name: "Ref Party Code", prop: "partyId" },
        { name: "Person Name", prop: "website_contactName" },
        { name: "Contact No", prop: "website_contactNo" },
        { name: "KAM Person", prop: "website_kam" },
        { name: "Disctibution Center", prop: "website_distributioncenter" },
        { name: "Date & Time", prop: "createdDate" },
      ];
      this.getPendingCustomerApprovalList()
    }else{
      this.selectionView = false;
      this.KYCincomplete = true;
      this.columnsForIncompleteKYC = [
        {
          name: 'Sr No',
          cellTemplate: function (cellElement, cellInfo) {
            cellElement.append(cellInfo.row.rowIndex + 1);
          }
        },
        { name: "Person Name", prop: "website_contactName", allowFilteredRow: true },
        { name: "Contact No", prop: "website_contactNo" ,allowFilteredRow: true  },
        { name: "KAM Person", prop: "website_kam"  ,allowFilteredRow: true },
        { name: "Distribution Center", prop: "website_distributioncenter" },
        { name: "Created Date", prop: "created_date" },
      ];
      this.getIncompleteKYCusersList();
      
    }
  }
  handelBackInCompleted(){
    this.selectionView = true;
    this.KYCcompleted = false;
  }
  handelBackNotcomplete(){
    this.selectionView = true;
    this.KYCincomplete = false;
  }
}
