import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import Query from "devextreme/data/query";
import CustomStore from "devextreme/data/custom_store";
import {
  ModalController,
  LoadingController,
  Events,
  AlertController
} from "@ionic/angular";
import { ReceiveincomingstonesComponent } from '../receiveincomingstones/receiveincomingstones.component';
import * as moment from "moment";
import { IonicSelectableComponent } from 'ionic-selectable';
import { ReturningpurchasestonemodalComponent } from '../returningpurchasestonemodal/returningpurchasestonemodal.component';
import { ThrowStmt } from '@angular/compiler';
// import { threadId } from 'worker_threads'

@Component({
  selector: 'app-purchaseconsolidation-return-stones',
  templateUrl: './purchaseconsolidation-return-stones.component.html',
  styleUrls: ['./purchaseconsolidation-return-stones.component.scss']
})
export class PurchaseconsolidationReturnStonesComponent implements OnInit {
  public columns = [];
  dataSource: any = [];
  public listOfStoneDataSource: any = {};
  listofstones: any = [];

  public uploadDate = moment().format("YYYY-MM-DD")
  public pageSize: any = 50;
  pageNumber = 0;
  noOfRecords = 15;
  public refreshGrid: Boolean = false;
  public partyMasterId: any;
  public partyName: any;
  public listOfStoneData = [];
  public count = 0;
  public stoneStatus = "To be returned";
  totalCount = 0
  public statusIdList = [{

    name: 'To be returned',
    id: 2

  }]
  // stonesStatus: "pending";

  public returnStoneList: any = [{
    name: 'Returned',
    id: 1
  }, {

    name: 'To be returned',
    id: 2

  }]


  public listOfParties = [];
  fromDate = moment().format('YYYY-MM-DD')
  toDate = moment().format('YYYY-MM-DD')
  public enquiryNo;
  public stonesList = [];
  public selected = [];

  public Stones = ``;
  constructor(
    public configService: ConfigService,
    public authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public event: Events
  ) { }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "StoneStatus") {
      if (idList.length > 0) {
        this.stoneStatus = obj[0].name;


      }
    } else {
      this.stoneStatus = ''
    }
  }


  async ngOnInit() {
    this.columns = [
      { name: "enquiryno", prop: "enquiryId" },
      { name: "Stoneid", prop: "stoneName" },
      { name: 'Clarity', prop: 'ClarityCode' },
      { name: 'Color', prop: 'ColorCode' },
      { name: 'Shape', prop: 'ShapeCode' },
      { name: 'Size', prop: 'cts' },
      { name: "Purchase-party", prop: "partyName" },
      { name: "Broker", prop: "brokerName" },
      { name: "Refsales-person", prop: "SalePerson" },
      { name: "Date", prop: "createdDate" },
      { name: "Status", prop: "status" },
      { name: "TransactionStatus", prop: "transtype" },
    ];
    await this.Fetch()
  }

  async return() {
    let listOfStones = this.selected.map(x => {
      let obj = {
        stoneName: x.stoneName,
        status: 'Returned',
        enquiryId: x.enquiryId,
        partyMasterId: x.partyMasterId

      }
      return obj
    })
    let obj = {
      'listOfStones': listOfStones
    }
    let res: any;
    res = await this.httpApiService.updateEnquiry(obj);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
    }
    this.stonesList = [];
    this.Stones = ''
  }


  async returnConfirm() {
    let listOfStones = this.selected.map(x => {
      let obj = {
        stoneName: x.stoneName,
        status: 'Returned',
        enquiryId: x.enquiryId,
        partyMasterId: x.partyMasterId

      }
      return obj
    })
    const Modal = await this.modalCtrl.create({
      component: ReturningpurchasestonemodalComponent,
      cssClass: 'small-modal',
      componentProps: {
        listOfStones: listOfStones
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await Modal.present();
  }

  async handleModalDismiss(d) {
    this.refreshGrid = true;
    this.stonesList = [];
    this.Stones = '';
    this.partyName = '';
    this.Fetch()
  }


  async Fetch() {
    let listOfStones = []
    if (this.Stones != '') {
      let tmp = this.Stones.split("\n");
      tmp = tmp.map(x => x.trim())

      if (tmp.length > 0) {
        listOfStones = tmp
      }
    }

    let obj = {
      'listOfStones': listOfStones,
      'searchQry': this.partyName,
      'status': this.stoneStatus,
      'transtype': this.stoneStatus,
    }
    this.dataSource = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageSize;
          } else {
            this.pageSize = loadOptions.take;
          }
          this.noOfRecords = this.pageSize;
          //loadOptions.take = this.noOfRecords;
          this.pageNumber = loadOptions.skip / loadOptions.take;
          let res: any;
          res = await this.httpApiService.fetchStonesStatusWise(obj, this.pageSize, this.pageNumber);
          let data = res.json();

          if (res.status == 401) {
            this.authService.logout();
          } else if (res.status == 500) {
            await this.configService.showToast("error", data.error);
          } else if (res.status == 400) {
            await this.configService.showToast("error", data.error);
          } else {
            this.stonesList = data.data;
            this.totalCount = data.data.count
            return {
              data: Query(data.data.data)
                .toArray(),
              totalCount: this.totalCount, //data.data.totalElements,
              groupCount: this.totalCount// data.data.totalElements
            };
          }
        } else {
          return {
            data: this.stonesList,
            totalCount: this.totalCount,
            groupCount: this.totalCount
          }
        }
      }
    });
  }

  onSelect(ev) {
    this.selected = ev.selectedRowsData;
  }

  Reset() {
    this.partyName = '';
    this.enquiryNo = '';
    this.stonesList = [];
    this.Stones = '';

  }
}
