import { Component, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-update-elastic-data',
  templateUrl: './update-elastic-data.component.html',
  styleUrls: ['./update-elastic-data.component.scss']
})

export class UpdateElasticDataComponent implements ProcessActionComponent {
  @Input() data: any;
  RFidList: string = '';
  filtered: any[] = [];

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events) { }

  ngOnInit() {
  }


  async syncDataOnElastic() {
    await this.configService.showLoader()
    let res: any;
    let filtered = this.RFidList.split("\n");
    let issueList = []
    filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    filtered = issueList.filter(function (el) {
      return el != "";
    });
    let payload = {
      setOfValues: filtered
    }
    res = await this.httpApiService.updateStonesInSearchPage(payload);
    let data = res.json();
    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
    }
  }


  async updateMFGPrice() {
    await this.configService.showLoader()
    let res: any;
    let filtered = this.RFidList.split("\n");
    let issueList = []
    filtered.forEach(el => {
      if (el.indexOf(",") > 0) {
        let data1 = el.split(',')
        let data2 = []
        data1.forEach(el => {
          data2.push(el.trim())
        })
        Array.prototype.push.apply(issueList, data2);
      } else {
        issueList.push(el)
      }
    })
    filtered = issueList.filter(function (el) {
      return el != "";
    });
    let payload = {
      setOfValues: filtered
    }
    res = await this.httpApiService.updateMFG(payload);
    let data = res.json();
    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
    }
  }


}
