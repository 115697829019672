import { Component, OnInit } from "@angular/core";

import {
  Conditions,
  PotentialBuyersService,
} from "src/app/services/potential-buyers/potential-buyers.service";

@Component({
  selector: "app-potential-buyers-data-section",
  templateUrl: "./potential-buyers-data-section.component.html",
  styleUrls: ["./potential-buyers-data-section.component.scss"],
})
export class PotentialBuyersDataSectionComponent implements OnInit {
  public potentialBuyerDetails = [];
  public selectedConditions: Conditions;

  constructor(private potentialBuyersService: PotentialBuyersService) {}

  ngOnInit() {
    this.potentialBuyersService.currSelectedConditions.subscribe(
      (conditions) => {
        this.selectedConditions = conditions;
      }
    );

    this.potentialBuyersService.currPotentialBuyerDetails.subscribe(
      (buyerDetails) => {
        this.potentialBuyerDetails = buyerDetails;
      }
    );
  }
}
