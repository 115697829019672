export default {
  reportNo: 'R-23',
  url: "master-service/api/report/assortment-report-mix",
  method: "GET",
  size: 3,
  fields: [
  ],
  columns: [
  ],
  dataStudioLink: 'https://lookerstudio.google.com/embed/reporting/1262b6d0-0962-4206-9485-268b84837607/page/kNzTD'
};