import { Component, OnInit, HostListener } from "@angular/core";
import { ModalController, Events, NavParams, LoadingController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { SearchService } from '../../../services/search/search.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sticker-printing-options',
  templateUrl: './sticker-printing-options.page.html',
  styleUrls: ['./sticker-printing-options.page.scss'],
})
export class StickerPrintingOptionsPage implements OnInit {
  public inputList: any;
  public type: any = 'stoneId';
  public stickerType: any = 'GIASticker'
  public columns: any = [];


  constructor(private es: SearchService, public navParams: NavParams,
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public event: Events,
    public configService: ConfigService,) {


  }

  public checkBoxes = [
    { val: 'GIA Sticker', isChecked: true },
    { val: 'Pkt No', isChecked: false },
    //{ val: 'Report No', isChecked: false },
  ];

  ngOnInit() {
  }

  changeType(type) {
    this.type = type;
  }

  changeStickerType(type) {
    this.stickerType = type;
  }

  async show() {
    await this.configService.showLoader()
    let inputs = this.inputList.split("\n");


    let jsonObj;
    jsonObj = {
      "paramName": this.type,
      setOfValues: inputs,
    }
    console.log(jsonObj)
    await this.configService.dismiss();
    this.searchFromElastic();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async searchFromElastic() {
    let qry: any =
    {
      "query": {
        "bool": {
          "should": [
            {
              "simple_query_string": {
                "query": ""
              }
            },
            {
              "bool": {
                "must": []
              }
            }
          ]
        }
      }
    }

    if (this.type == 'stoneId') {
      let tmp = this.inputList.split("\n");
      let boolShouldMultiple = {
        "bool": {
          "should": []
        }
      };
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i] != '') {
          let prefixObj: any = {};
          prefixObj =
          {
            "prefix": {
              "stoneName": tmp[i].trim()
            }
          }
          boolShouldMultiple.bool.should.push(prefixObj);
        }
      }
      qry.query.bool.should[1].bool.must.push(boolShouldMultiple);
    }

    let data = await this.es.getPaginatedDocuments(qry, 0, environment.INDEX, '', 100)
    data = data.hits.hits.map(d => d._source).map(res => {
      let stoneId = res.stoneName;
      if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
        let index = stoneId.split('/', 3).join('/').length;
        res['stoneName'] = String(stoneId.split(stoneId.substring(index + 1, stoneId.length)).join(stoneId.substring(index + 1, stoneId.length).slice(0, stoneId.substring(index + 1, stoneId.length).indexOf("_"))));
      } else {
        res['stoneName'] = String(res['stoneName']);
      }
      return res;
    })

    let dObj = {
      'stoneData': data,
      'stickerType': this.stickerType
    }
    this.modalCtrl.dismiss(dObj);
  }

}
