export default {
    reportNo: 'R-29',
    url: "master-service/api/report/assortment-report-mix",
    method: "GET",
    size: 3,
    fields: [
    ],
    columns: [
    ],
    dataStudioLink: 'https://lookerstudio.google.com/embed/reporting/27eb1d5b-d9f7-4c6a-ac52-acbc7d20aaab/page/Zg6vD'
  };

