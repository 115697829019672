export default {
  reportNo: 'R-24',
  url: "master-service/api/report/assortment-report-mix",
  method: "GET",
  size: 3,
  fields: [
  ],
  columns: [
  ],
  dataStudioLink: 'https://lookerstudio.google.com/embed/reporting/6366e421-03f7-486e-8e3d-b49dbf93c905/page/KRlZD'
};