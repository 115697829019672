import { Component, Input, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule ,FormsModule,FormBuilder, FormGroup, Validators, FormControl, AbstractControl  } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpApiService } from 'src/app/services/http-api/http-api.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { location } from './enum';
import { ModalController } from '@ionic/angular';

function passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const password = control.get('password');
  const verifyPassword = control.get('verifyPassword');

  if (password.value !== verifyPassword.value) {
    return { 'passwordMismatch': true };
  }

  return null;
}





@Component({
  selector: 'app-create-website-user',
  templateUrl: './create-website-user.component.html',
  styleUrls: ['./create-website-user.component.scss']
})
export class CreateWebsiteUserComponent implements OnInit {
  public typeIdList: any = [];

  registrationForm: FormGroup = new FormGroup({
    name: new FormControl('',Validators.required),
    mobile: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    email: new FormControl('',[Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    verifyPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),

  }, { validators: passwordMatchValidator });
  dialCodeDisplayed:any;
  distributionList =[];
  center: any;
  salePerson: any;
  salesPersonList= [];
  payload: {};
  allCompanyDetails: any;
  filteredCompany: any;
  salesPerson = {};
  companyLocation: any;
  finalSalesPersonToBeShown: any[];
  intlDialingCode: any;
  isSalesPersonSelected: boolean = false;
  isCompanySelected: boolean = false;



  constructor(private fb: FormBuilder,private http: HttpClient,
    public httpApiService: HttpApiService,private authService: AuthenticationService,
    public configService: ConfigService,    public modalCtrl: ModalController,
  
    ) { }

  ngOnInit() {
  this.getDistributionCenter();
  this.getSalesPerson();
 
  }
  handleDataChange(data: any) {
    // Handle the data received from the child component
    console.log(data, 'event from intl');
    this.intlDialingCode = data.target.value.dialCode;
    console.log(this.intlDialingCode, 'selected country calling code, here');
    
    
  }
 async onSubmit() {
    
    if (this.registrationForm.valid) {

      console.log(this.registrationForm.value);
      console.log(this.allCompanyDetails); //holds all the company details
      console.log(this.companyLocation); //holds the location of company selected
      if(!!this.salesPerson){
        console.log(this.salesPerson); 
        this.isSalesPersonSelected = true;
      // Add a "location" key
      this.salesPerson["location"] =   this.companyLocation ;
      
      // Remove the "id" field
      delete this.salesPerson['id'];
      
      console.log(this.salesPerson);

}
      
      this.filteredCompany = this.allCompanyDetails.find((company:any) => company.companyName === this.companyLocation);

      if (this.filteredCompany) {
        this.isCompanySelected = true;
        console.log(this.filteredCompany); //holds all info of the company selected
      } else {
        console.log('Company not found');
      }

    console.log(this.companyLocation, 'location of company from selection');

      
      this.payload = {
        "mobNumber": this.dialCodeDisplayed + this.registrationForm.value.mobile,
        "email": this.registrationForm.value.email,
        "password": this.registrationForm.value.password,
        "name": this.registrationForm.value.name,
        "distributionCenter": this.filteredCompany,
        "salesPerson": this.salesPerson,
        "companyName": "KG Diamonds"
      }
      // console.log(this.payload);

      if(this.payload['mobNumber'].includes('undefined')){
        await this.configService.showToast("error",'Please select international dialing code');
      }else{
        // console.log('\x1b[41m%s\x1b[0m', 'API: ', this.payload)
        this.submitFormData(this.payload)
        this.closeModal();
      }

    }
  }
 async getDistributionCenter(){
  let res: any;
  res = await this.httpApiService.getDistributionCenter();
  let data = res.json();
  if (res.status == 401) {
    this.authService.logout();
  } else if (res.status == 500) {
    await this.configService.showToast("error", data.error);
  } else if (res.status == 400) {
    await this.configService.showToast("error", data.error);
  } else {
    console.log(data); 

    console.log(data.data,'list') //holds the data for all the companies
      this.allCompanyDetails = data.data; //holds the data for all the companies
      if( data.data){
        this.distributionList = data.data.map(x=>{
          return{
            'id':x.id,
            'name':x.city
          }
        })
      }
    
      console.log(this.distributionList, 'center'); //holds all the locations in an object with id and name of location
    
    
    await this.configService.showToast("success", data.message);
  }
    // this.http.get('https://api-dev.kg-website.atishae.com/api/shop_customers/37/getDistributors').subscribe((data: any) => {
    //   // Handle the API response here
    //   console.log(data.data,'list') //holds the data for all the companies
    //   this.allCompanyDetails = data.data; //holds the data for all the companies
    //   if( data.data){
    //     this.distributionList = data.data.map(x=>{
    //       return{
    //         'id':x.id,
    //         'name':x.city
    //       }
    //     })
    //   }
    //   console.log(this.distributionList, 'center'); //holds all the locations in an object with id and name of location
      
      
    // });
  }
  async getSalesPerson() {
    let res: any;
    let payLoad = {
      "refCompanyId": 37
  }
    console.log(payLoad)
    res = await this.httpApiService.getSalesPerson(payLoad)
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data); //holds all sales persons with id, name, location
      if(!!data.data){
        let temp = data.data;
        this.salesPersonList  = data.data;
        for (let i = 0; i < temp.length; i++) {
          temp[i].id = i + 1;
        }
        this.salesPersonList = temp;
        console.log(this.salesPersonList, 'sales persons list with ids');//location,id, name, contains all the salespersons
        
      }
    
      
      
      await this.configService.showToast("success", data.message);
    }
  }
    
  async submitFormData(payLoad) {
    let res: any;
  //   let payLoad = {
  //     "refCompanyId": 37
  // }
    console.log(payLoad)
    res = await this.httpApiService.signupWithoutOtp(payLoad)

    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (!!JSON.parse(res._body).error) {
      
      await this.configService.showToast("error", JSON.parse(res._body).error);


    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data); //holds all sales persons with id, name, location
      await this.configService.showToast("success", data.message);
    }
  }
    
  onDistributionCenterChange(e:any){
    console.log(e, 'event of change here');
    
  }
  
  dropDownSelection(ev: any) {
    console.log(ev);
    if(ev.arrayList.length <= 0){
      this.isCompanySelected = false;
    }
    if(ev.idList.length <= 0){
      this.isSalesPersonSelected = false;
    }
    if (ev && ev.arrayList && ev.arrayList.length > 0) {
      if (ev.property == "distributionCenter") {
        this.companyLocation = ev.arrayList[0].name; //holds selected location with id and name, e.g. Hong Kong

        this.isCompanySelected = true;
        console.log(this.companyLocation);
        // return
        if (!!this.companyLocation) {
          const filteredEnum = location.filter((item) =>
            this.companyLocation.includes(item.label)
          );
          console.log(filteredEnum); //holds id, label, name, coming from enum

          const locationValues = filteredEnum.map((item) => item.value);
          console.log(locationValues); //holds value from filteredEnum, e.g. ['hk']
          this.isCompanySelected = true;
          const filteredUsers = this.salesPersonList.filter((user) =>
            locationValues.includes(user.location)
          ); //filtering from location in all salespersons list

          console.log(filteredUsers, "final"); //holds all the salespersons from that specific country, array of objects
          this.finalSalesPersonToBeShown = filteredUsers;
          console.log(this.salesPersonList);
          console.log(this.finalSalesPersonToBeShown);

          // this.salesPerson = this.salesPersonList
          // if this.distributionList is empty, then, empty this.salesPersonList
        }
      }
    } else if (
      ev.property == "distributionCenter" &&
      ev &&
      ev.arrayList &&
      ev.arrayList.length == 0
    ) {
      // this.salesPersonList = []
      this.finalSalesPersonToBeShown = [];
      this.salesPerson = {};
      this.typeIdList = [];
    }
    
    
    let property = ev.property;
    let idList = ev.idList = 1;
    let name = ev.name;

    // let salesPersonid=1;


    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "distributionCenter") {
      this.center = idList;
    }else if(property = 'salesPerson'){
      console.log(ev, 'in sales'); //holds selected salesperson
      this.isSalesPersonSelected = true;
      if(ev.property == 'salesPerson' && ev.arrayList.length > 0){
        this.salesPerson['name'] = ev.arrayList[0].name;
        // console.log(this.salePerson, 'sale');
        console.log(this.salesPerson, 'salesperson object here');
        this.isSalesPersonSelected = true;
        
      }
    // this.salesPerson = {};

      // this.salePerson = idList  ;
      
    }
  }
  closeModal() {
    this.modalCtrl.dismiss();
    console.log('close');
    
  }
  selectedCountry(e:any){
    // console.log('\x1b[41m%s\x1b[0m', ':ev ', e)
    if(e.length > 0){
      this.dialCodeDisplayed = e[0].dialCode;
    }else{
      this.dialCodeDisplayed = '';
    }
  }

}
