import { Component, OnInit } from "@angular/core";

import { ModalController } from "@ionic/angular";

import { FormatInputElemValueService } from "src/app/services/format-input-elem-value/format-input-elem-value.service";
import { PotentialBuyersService } from "src/app/services/potential-buyers/potential-buyers.service";

@Component({
  selector: "app-potential-buyers-stone-selection-popup",
  templateUrl: "./potential-buyers-stone-selection-popup.component.html",
  styleUrls: ["./potential-buyers-stone-selection-popup.component.scss"],
})
export class PotentialBuyersStoneSelectionPopupComponent implements OnInit {
  public enteredStoneId = "";
  public stoneIds = [];

  constructor(
    private potentialBuyersService: PotentialBuyersService,
    private modalCtrl: ModalController,
    private formatInputElemValueService: FormatInputElemValueService
  ) {}

  ngOnInit() {
    this.potentialBuyersService.currStoneNames.subscribe((stoneNames) => {
      this.stoneIds = stoneNames;
    });
  }

  removeStone(stoneId: string) {
    this.stoneIds = this.stoneIds.filter((stoneName) => stoneName !== stoneId);
    this.potentialBuyersService.updateStoneNames(this.stoneIds);
  }

  addStoneId() {
    let enteredstoneIds = this.formatInputElemValueService.formatString(
      this.enteredStoneId
    );

    enteredstoneIds = enteredstoneIds.filter((stone: string) => {
      if (!this.stoneIds.includes(stone)) {
        return true;
      }
      return false;
    });

    this.stoneIds.push(...enteredstoneIds);
    this.potentialBuyersService.updateStoneNames(this.stoneIds);
    this.enteredStoneId = "";
    this.closePopup();
  }

  closePopup() {
    this.modalCtrl.dismiss();
  }
}
